import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelect } from '../../../../user/state/action';
import { CanDeactivateFormComponent } from 'nc-shared';
import { AbsenceFormBuilder } from '../../../service/absence-form.builder';
import { AbsencePageAction } from '../../../state/action';
import { Absence } from '../../../model/absence';
import { CaseManagmentDetailsFactory } from '../../../service/case-managment-details.factory';
import { takeUntil } from 'rxjs';
import { EventQueueService } from 'src/app/shared/service/event-queue.service';
import { BroadcastEventType } from '../../../../shared/enumeration/broadcast-event-type.enumeration';
import { NcDocumentsService } from 'nc-documents';
import { AbsenceStatusEnumeration } from '../../../model/enumeration/absence-status.enumeration';
import { PermissionEnumeration } from '../../../../shared/enumeration/permission.enumeration';
import { EncodingService } from '../../../../shared/service/encoding.service';

@Component({
	templateUrl: './existing-absence.component.html',
})
export class ExistingAbsenceComponent extends CanDeactivateFormComponent implements OnInit {
	cmLoggedIn: boolean;
	isBranchHrUser: boolean;
	isDraftStatus: boolean;

	constructor(
		private store: Store,
		private documentService: NcDocumentsService,
		private caseManagementDetailsFactory: CaseManagmentDetailsFactory,
		private formBuilder: AbsenceFormBuilder,
		private eventQueue: EventQueueService,
		private encodingService: EncodingService
	) {
		super();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.buildExisting();
		this.store.select(UserSelect.getPermissions).subscribe((permissions) => {
			this.cmLoggedIn = permissions.includes('CASE_MANAGER');
			this.isBranchHrUser = permissions.includes(PermissionEnumeration.BRANCH_HR);
		});

		this.formGroup.controls.status.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => (this.isDraftStatus = value === AbsenceStatusEnumeration.DRAFT.code));

		this.eventQueue
			.on(BroadcastEventType.ON_SAVE)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.onSubmit());
		this.eventQueue
			.on(BroadcastEventType.DELETE_DRAFT)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.deleteDraft());

		this.eventQueue
			.on(BroadcastEventType.ON_SAVE_AND_SEND)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.saveAndSave());

		this.eventQueue
			.on(BroadcastEventType.REJECT)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.reject());
	}

	saveAndSave = (): void => {
		const data = this.formGroup.getRawValue() as Absence;
		data.documents = this.documentService.getDocuments(this.formGroup);
		data.documents = this.encodingService.encodeDocuments(data.documents);
		data.status = AbsenceStatusEnumeration.PENDING_APPROVAL.code;
		this.store.dispatch(AbsencePageAction.submitExisting({ absence: data }));
	};

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue() as Absence;
		const isCaseManagementActiveControl = this.formGroup.get('isCaseManagementActive');
		if (isCaseManagementActiveControl.value === 'yes') {
			data.caseManagementDetails = this.caseManagementDetailsFactory.create(this.formGroup);
		}
		data.documents = this.documentService.getDocuments(this.formGroup);
		data.documents = this.encodingService.encodeDocuments(data.documents);
		data.status = this.isDraftStatus ? AbsenceStatusEnumeration.DRAFT.code : AbsenceStatusEnumeration.OPEN.code;
		this.store.dispatch(AbsencePageAction.submitExisting({ absence: data }));
	};

	deleteDraft = (): void => {
		const data = this.formGroup.getRawValue() as Absence;
		this.store.dispatch(AbsencePageAction.deleteDraft({ code: data.code }));
	};

	reject = (): void => {
		const data = this.formGroup.getRawValue() as Absence;
		data.documents = this.documentService.getDocuments(this.formGroup);
		data.status = AbsenceStatusEnumeration.DRAFT.code;
		this.store.dispatch(AbsencePageAction.reject({ absence: data }));
	};
}
