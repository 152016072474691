import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Column, FilterRequest, Order, Search, TableData } from 'nc-datatable';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class AdministrationService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	private readonly FILTER_COMPANIES_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/companies/filter`;

	/**
	 * Filter companies
	 */
	filterCompanies(start: number, length: number, order: Order[], columns: Column[], globalSearch: Search): Observable<TableData> {
		const data = { start, length, order, columns, search: globalSearch } as FilterRequest;
		return this.httpClient.post<TableData>(this.FILTER_COMPANIES_URL, data);
	}
}
