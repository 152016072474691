import { Component, Input } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'nc-shared';

@Component({
	selector: 'ea-salary-bases',
	templateUrl: './salary-bases.component.html',
})
export class SalaryBasesComponent extends BaseComponent {
	@Input()
	formGroup: FormGroup;

	constructor() {
		super();
	}
}
