import { createAction, props } from '@ngrx/store';

export const getAllMilestoneSettings = createAction('[MilestoneSettings] Get all milestone settings');
export const getSingleMilestoneSettings = createAction('[MilestoneSettings] Get single milestone settings', props<{ id: number }>());
export const getDocumentsCheckList = createAction('[MilestoneSettings] Get documents checklist');
export const deleteMilestoneSetting = createAction('[MilestoneSettings] Delete milestone setting', props<{ id: number }>());
export const openDeleteConfirmationDialog = createAction('[MilestoneSettings] Open delete confirmation dialog', props<{ id: number }>());
export const saveMilestoneSetting = createAction('[MilestoneSettings] Save milestone setting', props<{ milestoneSetting: any }>());
export const clearCurrentMilestoneSetting = createAction('[MilestoneSettings] Clear current selected milestone setting');
export const recalculateStatuses = createAction('MilestoneSettings] Recalculate statuses');
