<nc-confirmation-dialog titleLabel="employerData" buttonLabel="close">
	<table class="readonly-form" *ngIf="company$ | async as company">
		<tr>
			<td>{{ 'companyId' | translate }}:</td>
			<td>{{ company.neoId }}</td>
		</tr>
		<tr>
			<td>{{ 'easyTempMandantId' | translate }}:</td>
			<td>{{ company.easyTempId }}</td>
		</tr>
		<tr>
			<td>{{ 'employer' | translate }}:</td>
			<td>{{ company.name }}</td>
		</tr>
		<tr>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>{{ 'uid' | translate }}:</td>
			<td>{{ company.uid }}</td>
		</tr>
		<tr>
			<td>{{ 'neoId' | translate }}:</td>
			<td>{{ company.neoId }}</td>
		</tr>
		<tr>
			<td>{{ 'easyTempId' | translate }}:</td>
			<td>{{ company.easyTempId }}</td>
		</tr>
		<tr>
			<td>{{ 'contractType' | translate }}:</td>
			<td>{{ company.contractType | translate }}</td>
		</tr>
		<tr>
			<td>{{ 'companyOwnerFirstName' | translate }}:</td>
			<td>{{ company.ownerFirstName }}</td>
		</tr>
		<tr>
			<td>{{ 'companyOwnerLastName' | translate }}:</td>
			<td>{{ company.ownerLastName }}</td>
		</tr>
		<tr>
			<td>&nbsp;</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td class="title">{{ 'address' | translate }}</td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>{{ 'street' | translate }}:</td>
			<td>{{ company.street }}</td>
		</tr>
		<tr>
			<td>{{ 'city' | translate }}:</td>
			<td>{{ company.city }}</td>
		</tr>
		<tr>
			<td>{{ 'zip' | translate }}:</td>
			<td>{{ company.zip }}</td>
		</tr>
		<tr>
			<td>{{ 'country' }}:</td>
			<td>{{ company.country }}</td>
		</tr>
		<tr>
			<td>{{ 'canton' | translate }}:</td>
			<td>{{ company.canton }}</td>
		</tr>
		<ng-container *ngIf="company.contactPerson">
			<tr>
				<td>&nbsp;</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="title">{{ 'contactPerson' | translate }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td>{{ 'firstName' | translate }}:</td>
				<td>{{ company.contactPerson.firstName }}</td>
			</tr>
			<tr>
				<td>{{ 'lastName' | translate }}:</td>
				<td>{{ company.contactPerson.lastName }}</td>
			</tr>
			<tr>
				<td>{{ 'email' | translate }}:</td>
				<td>{{ company.contactPerson.email }}</td>
			</tr>
			<tr>
				<td>{{ 'phoneNumberFurtherInquiries' | translate }}:</td>
				<td>{{ company.contactPerson.mobileNumber }}</td>
			</tr>
		</ng-container>
	</table>
</nc-confirmation-dialog>
