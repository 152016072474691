import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AbsencePageAction, AbsenceSelect } from '../../state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { AbsenceContextModel } from '../../../shared/model/absence-context.model';

@Component({
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss'],
})
export class HistoryComponent extends BaseComponent {
	formGroup: FormGroup;
	history$: Observable<TableData>;
	historyColumns: DatatableColumn[];
	code: string;
	filterConfig: FilterConfig;
	absenceModel$: Observable<AbsenceContextModel>;

	constructor(private store: Store, formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
		super();
		this.formGroup = formBuilder.group({});

		this.route.params.subscribe((params) => {
			if (params.code) {
				this.code = params.code;
				this.store.dispatch(AbsencePageAction.getHistory({ code: params.code }));
				this.store.dispatch(AbsencePageAction.getCurrentAbsence({ code: this.code }));
			}
		});

		this.historyColumns = [
			ColumnBuilder.create('eventDateTime', 'dateTime'),
			ColumnBuilder.create('userType', 'userType'),
			ColumnBuilder.create('userName', 'user'),
			ColumnBuilder.create('eventType', 'event'),
			ColumnBuilder.create('additionalInfo', 'additionalInfo'),
			ColumnBuilder.create('comment', 'comment'),
		];

		this.history$ = this.store.select(AbsenceSelect.getHistory);
		this.absenceModel$ = this.store.select(AbsenceSelect.getAbsenceContextModel);

		this.filterConfig = {
			filterValues: {},
		};
	}
}
