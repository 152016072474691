<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="15px">
				<ea-container title="incidentDeclaration">
					<nc-accordion>
						<nc-accordion-item title="checkAccessibility">
							<nc-button
								(click)="incidentDeclarationPing()"
								id="incidentDeclaration-sendPingRequest"
								label="sendPingRequest"
							></nc-button>
						</nc-accordion-item>
						<nc-accordion-item title="checkInteroperability">
							<ea-interoperability></ea-interoperability>
						</nc-accordion-item>
						<nc-accordion-item title="checkDeclareIncident">
							<ea-declare-incident></ea-declare-incident>
						</nc-accordion-item>
					</nc-accordion>
				</ea-container>
				<ea-container title="organizationAuthentication">
					<nc-accordion>
						<nc-accordion-item title="checkAccessibility">
							<nc-button
								(click)="organizationAuthenticationPing()"
								id="organizationAuthentication-sendPingRequest"
								label="sendPingRequest"
							></nc-button>
						</nc-accordion-item>
					</nc-accordion>
				</ea-container>
				<ea-container title="organizationAuthenticationRenew"></ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
</div>
