import { createReducer, on } from '@ngrx/store';
import { DOCUMENT_TYPE_INITIAL_STATE, DocumentTypesState } from './document-types.state';
import { DocumentTypesApiAction, DocumentTypesPageAction } from './action';

export const documentTypesReducer = createReducer<DocumentTypesState>(
	DOCUMENT_TYPE_INITIAL_STATE,
	on(DocumentTypesApiAction.getDocumentTypesSuccess, (state, action): DocumentTypesState => {
		return {
			...state,
			documentTypes: { records: action.records },
		};
	}),
	on(DocumentTypesApiAction.getDocumentTypeSuccess, (state, action): DocumentTypesState => {
		return {
			...state,
			currentDocumentType: action.documentType,
		};
	}),
	on(DocumentTypesPageAction.clearCurrentDocumentType, (state): DocumentTypesState => {
		return {
			...state,
			currentDocumentType: null,
		};
	}),
	on(DocumentTypesPageAction.setCurrentDocumentType, (state, action): DocumentTypesState => {
		return {
			...state,
			currentDocumentType: {
				...action.documentType,
			},
		};
	})
);
