import { Injectable } from '@angular/core';
import { DialogConfigurationUtils, DialogService, DialogType, ErrorDetails } from 'nc-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorAction } from './action';
import { tap } from 'rxjs/operators';
import { NcInformationDialogComponent } from 'nc-information-dialog';

@Injectable()
export class ErrorEffect {
	constructor(private dialogService: DialogService, private actions$: Actions) {}

	setErrorsEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ErrorAction.setErrors),
				tap(() => {
					this.dialogService.close(DialogType.PROCESSING);
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('checkAllData', 'info'));
				})
			);
		},
		{ dispatch: false }
	);

	submitFailedEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ErrorAction.submitFailed),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					const message = action.message != null ? action.message : this.getFirstMessage(action.errors);
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(message));
				})
			);
		},
		{ dispatch: false }
	);

	getFirstMessage = (errors: { [key: string]: ErrorDetails }) => {
		const keys = Object.keys(errors);
		return errors[keys[0]].code;
	};
}
