<form fxLayout="column" fxFill [formGroup]="formGroup">
	<ea-header-info [showCompany]="true"></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<nc-input
					*ngIf="isReadOnly; else showSelect"
					[id]="'note-type'"
					[formGroup]="formGroup"
					[formControlName]="'typeValue'"
					[isReadonly]="true"
				></nc-input>
				<ng-template #showSelect>
					<nc-select
						[id]="'note-type'"
						[searchTextId]="'note-type-search'"
						[searchButtonId]="'note-type-search-clear'"
						[clearIconId]="'note-type-clear'"
						[isReadonly]="isReadOnly"
						[formGroup]="formGroup"
						[formControlName]="isReadOnly ? 'typeValue' : 'type'"
						[data$]="noteType$"
						[enableSelectAll]="false"
						label="noteType"
						[translate]="true"
					>
					</nc-select>
				</ng-template>
				<nc-textarea id="note" [isReadonly]="isReadOnly" [formGroup]="formGroup" formControlName="text" label="note" maxLength="600">
				</nc-textarea>

				<nc-toggle-buttons
					[formGroup]="formGroup"
					formControlName="replicateForActiveAbsencesSet"
					label="createThisNoteForAllActiveAbsences"
					[options]="yesNo"
					[isDisabled]="isReadOnly"
				>
				</nc-toggle-buttons>

				<ea-documents
					[id]="'note-form-document'"
					[buttonId]="'open-dialog'"
					[downloadButtonId]="'download-documents-button'"
					[documentsFormArray]="documents"
					[isReadonly]="isReadOnly"
					[additionalColumns]="additionalColumns"
				></ea-documents>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-task-footer [isNote]="true" [showEditButton]="isReadOnly" [formGroup]="formGroup" [onSave]="onSubmit" [editNote]="editNote"></ea-task-footer>
</form>
