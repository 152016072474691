import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';

const OK = null;
const REQUIRED = { required: true };

const isReportDateFilled = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const documentType = control.get('documentType');
		const reportDate = control.get('reportDate');

		if (documentType.value === 'POWER_OF_ATTORNEY_AND_REMINDER_MEDICAL_REPORT' && reportDate.value === null) {
			FormValidation.addError(reportDate, REQUIRED);
		} else {
			FormValidation.removeError(reportDate, REQUIRED);
		}

		return OK;
	};
};
export { isReportDateFilled };
