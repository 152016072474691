import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IncapacityFormComponent } from '../incapacity-form/incapacity-form.component';
import { BaseComponent } from 'nc-shared';
import { FormGroup } from '@angular/forms';
import { DialogService, FormUtils } from 'nc-utils';
import { Subscription, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSelect } from '../../../../user/state/action';
import { AppConstants } from '../../../../app.constants';
import { IncapacityFormGuardDialogComponent } from '../incapacity-form-guard-dialog/incapacity-form-guard-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { KleSelect } from '../../../../kle/state/action';
import { takeUntil } from 'rxjs/operators';

@Component({
	templateUrl: './incapacity-dialog.component.html',
	styleUrls: ['./incapacity-dialog.component.scss'],
})
export class IncapacityDialogComponent extends BaseComponent implements OnInit, OnDestroy {
	@ViewChild(IncapacityFormComponent) form;
	isEdit = false;
	showCommentField: boolean;
	userTypeSubscription$: Subscription;
	absenceCode: string;
	isIncidentDeclared: boolean = false;
	isKleEnabled = false;
	isAccident = false;
	isLast = false;
	existsTakeWorkUpFullyAlready = false;

	constructor(
		private dialogRef: MatDialogRef<IncapacityFormComponent>,
		private store: Store,
		private route: ActivatedRoute,
		private dialogService: DialogService,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			formGroup: FormGroup;
			isKleEnabled: boolean;
			isAccident: boolean;
			isLast: boolean;
			existsTakeWorkUpFullyAlready: boolean;
		}
	) {
		super();
	}

	ngOnInit(): void {
		this.userTypeSubscription$ = this.store.select(UserSelect.getUserType).subscribe((value) => (this.showCommentField = value !== 'HR'));
		this.isEdit = this.data.formGroup.get('validFrom').value;
		this.route.params.subscribe((params) => (this.absenceCode = params.code));
		this.isKleEnabled = this.data.isKleEnabled;
		this.isAccident = this.data.isAccident;
		this.isLast = this.data.isLast;
		this.existsTakeWorkUpFullyAlready = this.data.existsTakeWorkUpFullyAlready;

		this.store
			.select(KleSelect.getIncident)
			.pipe(takeUntil(this.destroy$))
			.subscribe((incident) => {
				this.isIncidentDeclared = incident?.incidentCaseId != null;
			});
	}

	ngOnDestroy(): void {
		this.userTypeSubscription$.unsubscribe();
	}

	submit = () => {
		if (this.data.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.data.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		if (this.isIncidentDeclared) {
			this.dialogService
				.open(IncapacityFormGuardDialogComponent, { disableClose: true })
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						return this.dialogRef.close({ success: true });
					} else {
						return this.dialogRef.close({ success: false });
					}
				});
		} else {
			return this.dialogRef.close({ success: true });
		}
	};
}
