import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'nc-shared';
import { TreatmentsFormComponent } from '../treatments-form/treatments-form';
import { FormUtils } from 'nc-utils';
import { throwError } from 'rxjs';
import { AppConstants } from '../../../../app.constants';

@Component({
	selector: 'ea-treatment-dialog',
	templateUrl: './treatment-dialog.component.html',
	styleUrls: ['./treatment-dialog.component.scss'],
})
export class TreatmentDialogComponent extends BaseComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: { formGroup: FormGroup }, private dialogRef: MatDialogRef<TreatmentsFormComponent>) {
		super();
	}

	submit = () => {
		if (this.data.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.data.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		return this.dialogRef.close({ success: true });
	};
}
