import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KleState } from '../kle.state';
import { TableData } from 'nc-datatable';

export const KLE_FEATURE_STATE_NAME = 'kle';
const FEATURE_STATE = createFeatureSelector<KleState>(KLE_FEATURE_STATE_NAME);

export const getInteroperability = createSelector(FEATURE_STATE, (state) => state.interoperability);
export const getDeclareIncident = createSelector(FEATURE_STATE, (state) => state.declareIncident);
export const getPayment = createSelector(FEATURE_STATE, (state) => state.incident?.lastPayment);
export const getPersonMutated = createSelector(FEATURE_STATE, (state) => state.personMutated);
export const getIncident = createSelector(FEATURE_STATE, (state) => state.incident);
export const getLatestState = createSelector(FEATURE_STATE, (state) => state.incident?.latestState);
export const getAbsenceCode = createSelector(FEATURE_STATE, (state) => state.incident?.companyCaseId);
export const getNotifications = createSelector(FEATURE_STATE, (state) => toTableData(state.incident?.notifications));
export const getAwaitStories = createSelector(FEATURE_STATE, (state) => state.incident?.awaitStory);
export const getCrossChanelLinks = createSelector(FEATURE_STATE, (state) => toTableData(state.incident?.crossChannelLinks));
export const getIncidentResponses = createSelector(FEATURE_STATE, (state) => state.incident?.responses);
export const getIncidentRequests = createSelector(FEATURE_STATE, (state) => state.incident?.requests);
export const getCoveredDailyAllowances = createSelector(FEATURE_STATE, (state) => toTableData(state.incident?.coveredDailyAllowances));
export const getRepayments = createSelector(FEATURE_STATE, (state) => toTableData(state.incident?.repayments));
export const getEmploymentContract = createSelector(FEATURE_STATE, (state) => state.incident?.lastAdditionsPerson);
export const getOtherEmployers = createSelector(FEATURE_STATE, (state) => state.incident?.lastOtherEmployers);
export const getSpecialCoverage = createSelector(FEATURE_STATE, (state) => state.incident?.lastSpecialCoverage);
export const getAbsences = createSelector(FEATURE_STATE, (state) => state.incident?.lastAbsence);
export const getTreatment = createSelector(FEATURE_STATE, (state) => state.treatment);
export const getTreatments = createSelector(FEATURE_STATE, (state) => state.incident?.lastTreatment);
export const getAccidentDescription = createSelector(FEATURE_STATE, (state) => state.incident?.lastAccidentDescription);
export const getDialogMessages = createSelector(FEATURE_STATE, (state) => state.incident?.dialogMessages);
export const getDialogMessage = createSelector(FEATURE_STATE, (state) => state.dialogMessage);
export const getProcessControlResponse = createSelector(FEATURE_STATE, (state) => state.incident?.lastProcessControlResponse);
export const getProcessControlRequest = createSelector(FEATURE_STATE, (state) => state.incident?.lastProcessControlRequest);
export const getAgreement = createSelector(FEATURE_STATE, (state) => state.incident?.lastAgreement);
export const getSpecialCode = createSelector(FEATURE_STATE, (state) => state.incident?.lastSpecialCode);
export const getShortTimeWork = createSelector(FEATURE_STATE, (state) => state.incident?.lastShortTimeWork);
export const getAttachments = createSelector(FEATURE_STATE, (state) => toTableData(state.incident?.attachments));
export const getSettlements = createSelector(FEATURE_STATE, (state) => toTableData(state.incident?.settlements));
export const getSettlement = createSelector(FEATURE_STATE, (state) => state.settlement);
export const getCompanies = createSelector(FEATURE_STATE, (state) => state.companies);
export const getFetchedCompany = createSelector(FEATURE_STATE, (state) => state.company);
export const getCertificateRequestId = createSelector(FEATURE_STATE, (state) => state.certificateRequestId);

const toTableData = (data: any[]): TableData => {
	return data ? { records: data } : { records: [] };
};
