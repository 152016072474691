<nc-confirmation-dialog [titleLabel]="label">
	<div fxLayout="column" fxLayoutGap="10px">
		<div fxLayout="row" *ngIf="!hasErrors && isDeclareIncident">
			<nc-label id="declaredCase" [value]="'declaredCase'"></nc-label>
		</div>
		<div fxLayout="row" *ngIf="!hasErrors && isDeclareIncident">
			<nc-label [value]="idLabel"></nc-label>
			<span>: {{ id }}</span>
		</div>
		<div fxLayout="row" *ngIf="!hasErrors && !isDeclareIncident">
			<nc-label id="successfulTransmission" [value]="idLabel"></nc-label>
		</div>
		<div *ngIf="hasInformations || hasWarnings || hasErrors">
			<table>
				<thead>
					<tr>
						<th id="notification-type">{{ 'synchronization.table.type' | translate }}</th>
						<th id="notification-code">{{ 'code' | translate }}</th>
						<th id="notification-message">{{ 'message' | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let error of errors">
						<td>{{ 'error' | translate }}</td>
						<td>{{ error.descriptionCode }}</td>
						<td>{{ error.description }}</td>
					</tr>
					<tr *ngFor="let info of informations">
						<td>{{ 'info' | translate }}</td>
						<td>{{ info.descriptionCode }}</td>
						<td>{{ info.description }}</td>
					</tr>
					<tr *ngFor="let warning of warnings">
						<td>{{ 'warning' | translate }}</td>
						<td>{{ warning.descriptionCode }}</td>
						<td>{{ warning.description }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</nc-confirmation-dialog>
