import { createAction, props } from '@ngrx/store';
import { NoteModel } from '../../model/note.model';
import { TaskModel } from '../../model/task.mode';
import { SaveResponse } from 'nc-utils';
import { MilestoneModel } from '../../model/milestone.model';
import { AbsenceContextModel } from '../../../shared/model/absence-context.model';
import { Option } from 'nc-shared';

export const getOpenMilestonesSuccess = createAction('[Task] Get open milestones success', props<{ openMilestones: { [key: string]: string }[] }>());
export const getDoneMilestonesSuccess = createAction('[Task] Get done milestones success', props<{ doneMilestones: { [key: string]: string }[] }>());
export const getOpenTasksSuccess = createAction('[Task] Get open tasks success', props<{ openTasks: { [key: string]: string }[] }>());
export const getDoneTasksSuccess = createAction('[Task] Get done tasks success', props<{ doneTasks: { [key: string]: string }[] }>());
export const getNotesSuccess = createAction('[Task] Get notes success', props<{ notes: { [key: string]: string }[] }>());
export const getNoteSuccess = createAction('[Task] Get note success', props<{ note: NoteModel }>());
export const getTaskSuccess = createAction('[Task] Get task success', props<{ task: TaskModel }>());
export const saveNoteSuccess = createAction('[Task] Save note success', props<{ response: SaveResponse }>());
export const saveTaskSuccess = createAction('[Task] Save task success', props<{ response: SaveResponse }>());
export const getNumberOfDaysSuccess = createAction('[Task] Get number of days success', props<{ numberOfDays: Option[] }>());
export const getMilestoneSuccess = createAction('[Task] Get milestone success', props<{ milestone: MilestoneModel }>());
export const doneMilestoneSuccess = createAction('[Task] Done milestone success', props<{ response: SaveResponse }>());
export const undoneMilestoneSuccess = createAction('[Task] Undone milestone success', props<{ response: any }>());

export const getCurrentAbsenceSuccess = createAction('[Tasks] Get current absence success', props<{ currentAbsence: AbsenceContextModel }>());

export const getDocumentsForCheckListSuccess = createAction(
	'[Task] Get documents for check list success',
	props<{ documents: { [key: string]: string }[] }>()
);
