import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from 'nc-shared';
import { UserAdministrationService } from '../../service/user-administration.service';
import { BehaviorSubject } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { DialogConfigurationUtils, DialogService, DialogType } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { FileUtils } from '../../../shared/utils/file.utils';

@Component({
	templateUrl: './upload-user-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadUserDialogComponent extends BaseComponent implements OnInit {
	formGroup: FormGroup;
	columns: Array<DatatableColumn>;
	filterConfig: FilterConfig;
	failureItems$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	successCount = 0;
	failureCount = 0;
	showResult = false;
	fileControl: FormControl;

	constructor(
		formBuilder: FormBuilder,
		private service: UserAdministrationService,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef
	) {
		super();
		this.formGroup = formBuilder.group({
			file: [''],
		});
	}

	ngOnInit(): void {
		this.fileControl = this.formGroup.get('file') as FormControl;
		this.fileControl.valueChanges.subscribe(() => {
			this.showResult = false;
			this.cdr.detectChanges();
		});

		this.filterConfig = {
			showGlobalFilter: false,
			filterValues: {},
		};

		this.columns = this.getColumns();
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('userType', 'userType'),
			ColumnBuilder.create('firstName', 'firstName'),
			ColumnBuilder.create('lastName', 'lastName'),
			ColumnBuilder.create('username', 'username'),
			ColumnBuilder.create('email', 'email'),
			ColumnBuilder.create('errorMessage', 'validation'),
		];
	}

	send() {
		const file = this.fileControl.value.files[0] as File;

		if (file && (FileUtils.getExtension(file.name) === 'xls' || FileUtils.getExtension(file.name) === 'xlsx')) {
			this.service.insertUsersFromExcel(file).subscribe((response) => {
				this.dialogService.close(DialogType.PROCESSING);

				if (response.success) {
					this.showResult = true;
					this.successCount = response.value.success;
					this.failureCount = response.value.failed;
					this.failureItems$.next({ records: response.value.unsuccessfulRows });
					this.cdr.detectChanges();
				} else {
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(response.errors[0].label));
				}
			});
		} else {
			this.dialogService.close(DialogType.PROCESSING);
			this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error('fileFormatNotSupported'));
		}
	}
}
