import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { CanDeactivateFormComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelManagementService } from '../../service/label-management.service';
import { LabelManagementDetails } from '../../model/label-management-details';
import { LabelManagementPageAction, LabelManagementSelect } from '../../state/action';

@Component({
	templateUrl: './label-management-form.component.html',
	styleUrls: ['./label-management-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagementFormComponent extends CanDeactivateFormComponent implements OnInit {
	label$: Observable<LabelManagementDetails>;

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private labelManagementService: LabelManagementService,
		private router: Router,
		private route: ActivatedRoute
	) {
		super();
		this.route.params.subscribe((params) => {
			if (params.code) {
				this.store.dispatch(LabelManagementPageAction.getLabel({ currentLabelCode: params.code }));
			}
		});
		this.label$ = this.store.select(LabelManagementSelect.getCurrentLabel);
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({
			code: null,
			de: null,
			en: null,
			fr: null,
			it: null,
		});

		this.label$
			.pipe(
				filter((label) => label != null && label.code != null),
				takeUntil(this.destroy$)
			)
			.subscribe((label) => this.displayLabel(label));
	}

	displayLabel(label: LabelManagementDetails): void {
		this.formGroup.reset();
		this.formGroup.patchValue({
			code: label.code,
			de: label.de,
			en: label.en,
			fr: label.fr,
			it: label.it,
		});
	}

	dispatchSubmitAction = () => {
		const data = this.formGroup.getRawValue() as LabelManagementDetails;
		this.store.dispatch(LabelManagementPageAction.saveLabel({ label: data }));
	};
}
