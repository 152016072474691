<div [formGroup]="formGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<nc-select
		id="body-part"
		searchTextId="body-part-injury"
		searchButtonId="body-part-injury-search-clear"
		clearIconId="body-part-injury-clear"
		[formGroup]="formGroup"
		[data$]="bodyPart$"
		placeholder="bodyPartInjury"
		label="bodyPartInjury"
		[required]="true"
		[translate]="true"
		[removeSelected]="true"
		formControlName="bodyPart"
	>
	</nc-select>
	<nc-select
		*ngIf="!unorientedBodyPart"
		id="position-type"
		searchTextId="position-type"
		searchButtonId="position-type-search-clear"
		clearIconId="position-type-clear"
		[formGroup]="formGroup"
		[data$]="positionType$"
		[translate]="true"
		[searchable]="false"
		placeholder="positionType"
		label="positionType"
		formControlName="position"
	>
	</nc-select>
	<nc-select
		*ngIf="!formGroup.value.otherTypeOfInjury"
		id="type-of-injury"
		searchTextId="type-of-injury"
		searchButtonId="type-of-injury-search-clear"
		clearIconId="type-of-injury-clear"
		[formGroup]="formGroup"
		[data$]="typeOfInjury$"
		[translate]="true"
		[removeSelected]="true"
		placeholder="typeOfInjury"
		label="typeOfInjury"
		formControlName="typeOfInjury"
	>
	</nc-select>
	<nc-textarea
		*ngIf="!formGroup.value.typeOfInjury"
		id="other-type-of-injury"
		[formGroup]="formGroup"
		formControlName="otherTypeOfInjury"
		label="otherTypeOfInjury"
		maxLength="300"
	></nc-textarea>
	<div *ngIf="showTypeAndOtherTypeMandatoryError()" class="error-container">
		{{ 'typeOrOtherTypeOfInjury.mandatory' | translate }}
	</div>
</div>
