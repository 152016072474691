import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AbsencePageAction } from '../../../state/action';
import { FormArray } from '@angular/forms';

@Component({
	selector: 'ea-reassign-absence-footer',
	templateUrl: './reassign-absence-footer.component.html',
	styleUrls: ['./reassign-absence-footer.component.scss'],
})
export class ReassignAbsenceFooterComponent {
	@Input() code: string;
	@Input() selectFormGroup: FormArray;

	constructor(private location: Location, private store: Store) {}

	goBack = (): void => {
		this.location.back();
	};

	reassign = (): void => {
		const employeeId = this.selectFormGroup.value[0].value;
		this.store.dispatch(AbsencePageAction.reassignAbsenceSubmit({ code: this.code, employeeId: employeeId }));
	};
}
