<div id="repayment-dialog" fxLayout="column" fxLayoutGap="10px">
	<form [formGroup]="formGroup" fxLayout="column">
		<nc-input id="repayment-id" [formGroup]="formGroup" label="id" formControlName="id" [isReadonly]="true"></nc-input>
		<nc-input id="story-id" [formGroup]="formGroup" label="storyId" formControlName="storyId" [isReadonly]="true"></nc-input>
		<nc-input id="settlement-id" [formGroup]="formGroup" label="settlementId" formControlName="settlementId" [isReadonly]="true"></nc-input>
		<div fxLayout="row wrap" fxLayoutGap="10px">
			<nc-datepicker id="expiry-date" [formGroup]="formGroup" label="expiryDate" formControlName="expiryDate" [isReadonly]="true">
			</nc-datepicker>
			<nc-datepicker id="created-on" [formGroup]="formGroup" label="createdOn" formControlName="createdOn" [isReadonly]="true"></nc-datepicker>
		</div>
		<nc-input id="balance" [formGroup]="formGroup" label="balance" formControlName="balance" [isReadonly]="true"></nc-input>
		<nc-input id="reference" [formGroup]="formGroup" label="reference" formControlName="reference" [isReadonly]="true"></nc-input>
		<nc-input id="iban" [formGroup]="formGroup" label="iban" formControlName="iban" [isReadonly]="true"></nc-input>
		<nc-input id="accountNumber" [formGroup]="formGroup" label="accountNumber" formControlName="accountNumber" [isReadonly]="true"></nc-input>
	</form>
	<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
		<nc-button id="close" label="close" [mat-dialog-close]="false"></nc-button>
	</div>
</div>
