import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LdResponsibleComponent } from './component/ld-responsible.component';
import { EffectsModule } from '@ngrx/effects';
import { LdResponsibleEffect } from './state/ld-responsible.effect';
import { StoreModule } from '@ngrx/store';
import { LD_RESPONSIBLE_FEATURE_STATE_NAME } from './state/action/ld-responsible.select';
import { ldResponsibleReducer } from './state/ld-responsible.reducer';
import { LdResponsibleFormComponent } from './component/ld-responsible-form/ld-responsible-form.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([LdResponsibleEffect]),
		StoreModule.forFeature(LD_RESPONSIBLE_FEATURE_STATE_NAME, ldResponsibleReducer),
	],
	declarations: [LdResponsibleComponent, LdResponsibleFormComponent],
	exports: [LdResponsibleComponent, LdResponsibleFormComponent],
})
export class LdResponsibleModule {}
