import { Injectable } from '@angular/core';
import { DialogMessage } from '../../model/dialog-message';
import {
	BooleanFormField,
	DateFormField,
	DateTimeFormField,
	FormField,
	FormFieldTypes,
	GenericFormData,
	LabelFormField,
	NumberFormField,
	RadioFormField,
	TextFormField,
} from 'nc-form-generator';
import { DialogMessageField, FreeDialogValueTypes } from '../../model/dialog-message-field';
import { ApiDateTimeFormat, dateTimeToString, dateToString, EnumUtils, parseDate } from 'nc-utils';
import { FlagWithUnknownEnumerationConst } from '../../../shared/enumeration/flag-with-unknown.enumeration';

@Injectable({ providedIn: 'root' })
export class FreeDialogMessageService {
	getFormData(dialogMessage: DialogMessage): GenericFormData {
		return {
			id: dialogMessage.storyId,
			title: dialogMessage.title,
			inputFields: this.getFormInputsFromDialogFields(dialogMessage.description, dialogMessage.freeDialogMessage.paragraphs),
		};
	}

	getFormInputsFromDialogFields(description: string, dialogFields: DialogMessageField[]): FormField<FormFieldTypes>[] {
		let inputFields: FormField<FormFieldTypes>[] = [];
		inputFields.push(this.setDescriptionField(description));
		dialogFields.forEach((dialogField) => {
			inputFields.push(this.freeDialogToFormField(dialogField));
		});

		return inputFields;
	}

	setDescriptionField(desc: string): FormField<FormFieldTypes> {
		let formField: FormField<FormFieldTypes>;
		formField = new TextFormField();
		formField.key = 'description';
		formField.label = 'description';
		formField.value = desc;
		formField.disabled = true;
		return formField;
	}

	freeDialogToFormField(dialogField: DialogMessageField): FormField<FormFieldTypes> {
		let formField: FormField<FormFieldTypes>;
		switch (dialogField.answerType || dialogField.valueType) {
			case FreeDialogValueTypes.STRING:
				formField = new TextFormField();
				break;
			case FreeDialogValueTypes.INTEGER:
			case FreeDialogValueTypes.DOUBLE:
				formField = new NumberFormField();
				break;
			case FreeDialogValueTypes.BOOLEAN:
				formField = new BooleanFormField();
				break;
			case FreeDialogValueTypes.DATE:
				formField = new DateFormField();
				break;
			case FreeDialogValueTypes.DATE_TIME:
				formField = new DateTimeFormField();
				break;
			case FreeDialogValueTypes.YES_NO_UNKNOWN:
				formField = new RadioFormField<string>({
					options: EnumUtils.toItems(FlagWithUnknownEnumerationConst),
				});
				break;
			default:
				formField = new LabelFormField();
				break;
		}

		formField.key = dialogField.id;
		formField.label = dialogField.label;
		formField.value = this.getFieldValue(dialogField);
		formField.required = !dialogField.answerOptional;
		formField.sectionId = dialogField.sectionIDRef;
		formField.sectionLabel = dialogField.sectionHeading;
		formField.disabled = this.isFormFieldDisabled(dialogField);

		return formField;
	}

	/**
	 *  Returns free dialog value if field is uneditable or answerDefaultValue if filed can be edited
	 * 	Casts answerDefaultValue to Moment so it can be managed properly
	 */
	getFieldValue(dialogField: DialogMessageField): FormFieldTypes {
		if (dialogField.answerType === FreeDialogValueTypes.DATE || dialogField.answerType === FreeDialogValueTypes.DATE_TIME) {
			return this.getFormattedDateTime(dialogField.answerValue || dialogField.answerDefaultValue, dialogField.answerType);
		} else if (dialogField.valueType === FreeDialogValueTypes.DATE || dialogField.valueType === FreeDialogValueTypes.DATE_TIME) {
			return this.getFormattedDateTime(dialogField.value, dialogField.valueType);
		}

		return dialogField.answerValue || dialogField.answerDefaultValue || dialogField.value;
	}

	private getFormattedDateTime(fieldValue: FormFieldTypes, fieldType: FreeDialogValueTypes) {
		switch (fieldType) {
			case FreeDialogValueTypes.DATE:
				return parseDate(fieldValue?.toString());
			case FreeDialogValueTypes.DATE_TIME:
				return parseDate(fieldValue?.toString(), ApiDateTimeFormat);
			default:
				break;
		}
	}

	/**
	 *  Returns true if we don't have answerType (it means we don't provide an answer)
	 *  Or if we don't have valueType and answerType (which means we only display a label)
	 */
	isFormFieldDisabled?(dialogField: DialogMessageField): boolean {
		return !dialogField.answerType || (!dialogField.valueType && !dialogField.answerType);
	}

	applyFormValuesToDialogMessageData(dialogFields: DialogMessageField[], fieldValues: unknown[]): DialogMessageField[] {
		dialogFields = dialogFields.map((dialogField) => {
			if (dialogField.answerType) {
				switch (dialogField.answerType) {
					case FreeDialogValueTypes.DATE_TIME:
						return {
							...dialogField,
							answerValue: dateTimeToString(fieldValues[dialogField.id]),
						};
					case FreeDialogValueTypes.DATE:
						return {
							...dialogField,
							answerValue: dateToString(fieldValues[dialogField.id]),
						};

					default:
						return {
							...dialogField,
							answerValue: fieldValues[dialogField.id],
						};
				}
			} else {
				return dialogField;
			}
		});
		return dialogFields;
	}
}
