<div fxLayout="row" fxFill fxLayoutGap="15px" [formGroup]="takeWorkUpFullyFormGroup">
	<nc-datepicker
		id="take-work-up-fully"
		*ngIf="
			!takeWorkUpFullyFormGroup.value.expectedLastDayOfIncapacityToWork &&
			!takeWorkUpFullyFormGroup.value.expectedIncapacityToWork &&
			!takeWorkUpFullyFormGroup.value.noIncapacityForWork
		"
		[formGroup]="takeWorkUpFullyFormGroup"
		formControlName="takeWorkUpFullyDate"
		label="takeWorkUpFullyDate"
		[ngClass]="'margin-top-15'"
	></nc-datepicker>
	<nc-datepicker
		id="expected-last-day-of-incapacity"
		*ngIf="
			!takeWorkUpFullyFormGroup.value.takeWorkUpFullyDate &&
			!takeWorkUpFullyFormGroup.value.expectedIncapacityToWork &&
			!takeWorkUpFullyFormGroup.value.noIncapacityForWork
		"
		[formGroup]="takeWorkUpFullyFormGroup"
		formControlName="expectedLastDayOfIncapacityToWork"
		label="expectedLastDayOfIncapacityToWork"
		[ngClass]="'margin-top-15'"
	></nc-datepicker>
	<nc-select
		id="expectedIncapacityToWork"
		clearIconId="expectedIncapacityToWork"
		*ngIf="
			!takeWorkUpFullyFormGroup.value.expectedLastDayOfIncapacityToWork &&
			!takeWorkUpFullyFormGroup.value.takeWorkUpFullyDate &&
			!takeWorkUpFullyFormGroup.value.noIncapacityForWork
		"
		[formGroup]="takeWorkUpFullyFormGroup"
		[data$]="incapacityToWorkEnum$"
		placeholder="expectedIncapacityToWork"
		label="expectedIncapacityToWork"
		[removeSelected]="true"
		formControlName="expectedIncapacityToWork"
		[translate]="true"
		[searchable]="false"
		[ngClass]="'margin-top-15'"
		fxFlex="20"
	></nc-select>
	<nc-checkbox
		id="noIncapacityForWork"
		*ngIf="
			!takeWorkUpFullyFormGroup.value.expectedLastDayOfIncapacityToWork &&
			!takeWorkUpFullyFormGroup.value.expectedIncapacityToWork &&
			!takeWorkUpFullyFormGroup.value.takeWorkUpFullyDate
		"
		label="noIncapacityForWork"
		[formGroup]="takeWorkUpFullyFormGroup"
		formControlName="noIncapacityForWork"
		[ngClass]="'margin-top-30'"
	></nc-checkbox>
</div>
<div *ngIf="takeWorkUpFullyFormGroup.touched && takeWorkUpFullyFormGroup.errors" class="error-container">
	{{ 'at.least.one.filed.required.group' | translate }}
</div>
