import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	templateUrl: './absence-confirmation-dialog.component.html',
})
export class AbsenceConfirmationDialogComponent {
	code: string;
	title: string;
	message: string;

	constructor(@Inject(MAT_DIALOG_DATA) data) {
		this.code = data?.code;
		this.title = data?.title;
		this.message = data?.message;
	}
}
