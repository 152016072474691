import { Component, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { SettingsSelect } from '../../../../state/settings/action';
import { Store } from '@ngrx/store';

@Component({
	selector: 'ea-ecare-footer',
	templateUrl: './ecare-footer.component.html',
	styleUrls: ['./ecare-footer.component.scss'],
})
export class EcareFooterComponent {
	@Input() languageCode: string;
	@Input() isMobile: boolean;
}
