import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StatisticApiAction, StatisticPageAction, StatisticSelect } from './action';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { StatisticService } from '../service/statistic.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TableColumns } from '../model/table-columns';
import { DialogConfigurationUtils, DialogService, DialogType } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { StatisticRowTransformer } from '../transformer/statistic-row.transformer';
import { ChartTransformer } from '../transformer/chart.transformer';
import { FileUtils } from '../../shared/utils/file.utils';

@Injectable()
export class StatisticEffect {
	constructor(private store: Store, private actions$: Actions, private dialogService: DialogService, private statisticService: StatisticService) {}

	searchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(StatisticPageAction.search),
			switchMap((action) => this.statisticService.search(action.searchFilter)),
			withLatestFrom(this.store.select(StatisticSelect.getType)),
			map(([result, type]) => {
				const columns = TableColumns.getColumns(type);
				const data = result.statistics.map((row) => StatisticRowTransformer.transform(row));
				const totalRow = StatisticRowTransformer.transform(result.overall, true);
				const charts = ChartTransformer.transform(result);
				this.dialogService.close(DialogType.PROCESSING);

				if (data.length === 0) {
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error('cmStatisticNoDataMessage'));
				}

				return StatisticApiAction.searchSuccess({ records: data, totalRow, columns, charts });
			})
		);
	});

	exportToFileEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(StatisticPageAction.exportToFile),
				switchMap((action) => this.statisticService.exportToFile(action.searchFilter, action.exportType)),
				tap((response) => {
					this.dialogService.close(DialogType.PROCESSING);

					if (response.body.size === 0) {
						this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error('cmStatisticNoDataMessage'));
					} else {
						FileUtils.download(response);
					}
				})
			);
		},
		{ dispatch: false }
	);
}
