import { Component, Input, OnInit } from '@angular/core';
import { SettingsPageAction } from '../../../state/settings/action';
import { Store } from '@ngrx/store';
import { DialogService } from 'nc-utils';
import { UploadUserDialogComponent } from '../../../user-administration/component/upload-user-dialog/upload-user-dialog.component';

@Component({
	selector: 'ea-create-footer',
	templateUrl: './create-footer.component.html',
	styleUrls: ['./create-footer.component.scss'],
})
export class CreateFooterComponent implements OnInit {
	@Input() buttonLabel = 'create';
	@Input() url: string;
	isUserAdministration: boolean;

	constructor(private store: Store, private dialogService: DialogService) {}

	ngOnInit(): void {
		this.isUserAdministration = this.url.includes('user-administration');
	}

	buttonAction = (): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: this.url }));
	};

	uploadUsers = (): void => {
		const dialogConfig = {
			width: '900px',
		};

		this.dialogService.open(UploadUserDialogComponent, dialogConfig);
	};
}
