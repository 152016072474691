import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ReportService } from '../service/report.service';
import { ReportApiAction, ReportPageAction } from './action';
import { FileUtils } from '../../shared/utils/file.utils';

@Injectable()
export class ReportEffect {
	constructor(private actions$: Actions, private reportService: ReportService) {}

	getReportEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ReportPageAction.getReport),
			switchMap((action) => this.reportService.getReport(action.reportRequest, action.isActivityLog)),
			map((result) => ReportApiAction.getReportSuccess({ records: result }))
		);
	});

	exportToFileEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ReportPageAction.exportToFile),
				switchMap((action) => this.reportService.exportToFile(action.reportRequest, action.isActivityLog)),
				map((response) => {
					FileUtils.download(response);
				})
			);
		},
		{ dispatch: false }
	);
}
