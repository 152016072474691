import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Column, ColumnBuilder, DatatableColumn, Order, Search, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { AdministrationPageAction, AdministrationSelect } from '../../state/action';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../../user/state/action';

@Component({
	templateUrl: './administration.component.html',
	styleUrls: ['./administration.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
	administrationColumns: DatatableColumn[];
	companies$: Observable<TableData>;
	filterConfig: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	NAME_FILTER = 'nameFilter';
	DEFAULT_ASSIGNEE_FILTER = 'defaultAssigneeFilter';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store) {
		super();
		this.loggedUserSubscription$ = store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});
		this.administrationColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('defaultAssignee', 'userResponsible', true),
			ColumnBuilder.create('name', 'companyName', true),
			ColumnBuilder.create('active', 'companyActive'),
			ColumnBuilder.create('closed', 'companyClosed'),
			ColumnBuilder.create('total', 'companyTotal'),
		];
	}

	ngOnInit(): void {
		this.companies$ = this.store.select(AdministrationSelect.getCompanies);
		this.store.select(SettingsSelect.getAdministrationUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});

		this.filterConfig = {
			showFilter: true,
			showGlobalFilter: false,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
			filterValues: {
				nameFilter: this.userPreferences[this.NAME_FILTER],
				defaultAssigneeFilter: this.userPreferences[this.DEFAULT_ASSIGNEE_FILTER],
			},
		};
	}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `company/${rowData.id}` }));
	};

	filter = (start: number, length: number, order: Order[], columns: Column[], globalSearch: Search): void => {
		this.store.dispatch(AdministrationPageAction.filterCompanies({ start, length, order, columns, globalSearch }));
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			nameFilter: this.filterConfig.columnFiltersFormGroup.get(this.NAME_FILTER).value,
			defaultAssigneeFilter: this.filterConfig.columnFiltersFormGroup.get(this.DEFAULT_ASSIGNEE_FILTER).value,
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(
			SettingsPageAction.setAdministrationTableFilter({
				administrationFilters: filterObject,
				loggedUser: this.loggedUser,
			})
		);
	}
}
