<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<nc-input [id]="'name'" [formGroup]="formGroup" formControlName="name" label="name"></nc-input>
				<ea-container [title]="'companies'" *ngIf="isExisting">
					<nc-datatable [id]="'companies'" [columns]="companyColumns" [data$]="companies$" [filterConfig]="filterConfig"> </nc-datatable>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
