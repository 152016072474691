import { KleFormGroupEnum } from './enums/kle-form-group.enum';

export class AccordionCheckbox {
	checked: boolean;
	awaited: boolean;
	formControlName: KleFormGroupEnum;

	constructor(formControlName: KleFormGroupEnum) {
		this.checked = false;
		this.awaited = false;
		this.formControlName = formControlName;
	}

	reset(): void {
		this.checked = false;
		this.awaited = false;
	}
}
