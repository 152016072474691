<div class="header-content" fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="40px">
	<div *ngIf="showCode" class="code-text">{{ 'code' | translate }}: {{ code }}</div>
	<div
		*ngIf="currentAbsence$ | async as absence"
		fxLayout="row wrap"
		fxLayout.lt-md="column"
		fxLayoutGap.lt-md="15px"
		fxLayoutGap="15px"
		fxLayoutAlign="flex-start"
	>
		<div *ngIf="showAbsenceCodeLabel" class="code-text">{{ 'absenceCode' | translate }}: {{ absence.absenceCode }}</div>
		<div *ngIf="showLinks" class="links">
			<a *ngIf="showCompany" class="header-link" (click)="companyLink(absence.companyId)"
				>{{ absence.companyName }} [ {{ absence.companyNeoId }} ] -
			</a>
			<a *ngIf="showAbsenceCode" class="header-link" (click)="absenceLink(absence.absenceCode)"> {{ absence.absenceCode }} - </a>
			<a *ngIf="showInsuredPerson" class="header-link" (click)="employeeLink(absence.insuredPersonId)">{{ absence.insuredPersonName }} -</a>
			<span *ngIf="showDays"> {{ absence.duration }} {{ 'days' | translate }} - {{ 'bvg' | translate }}</span>
		</div>

		<div *ngIf="showStatus && latestState$ | async as latestState">
			<table aria-describedby="absenceStatus-table">
				<tr>
					<th>{{ 'status' | translate }}</th>
					<th>{{ 'coverage' | translate }}</th>
					<th>{{ 'smallRemark' | translate }}</th>
				</tr>
				<tr>
					<td>{{ latestState.status | translate }}</td>
					<td>{{ latestState.coverage | translate }}</td>
					<td>{{ latestState.smallRemark }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>
