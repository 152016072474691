<div fxLayout="column" fxLayout.lt-md="row">
	<div class="login-footer-title" fxLayout="column" fxLayoutAlign="center">
		<span>{{ city }}</span>
	</div>
	<a target="_blank" [href]="link">
		<div *ngIf="!isMobile">
			<div class="login-footer-text">{{ 'eCareCompanyName' | translate }}</div>
			<div class="login-footer-text">{{ address }}</div>
			<div class="login-footer-text">{{ postCode }}</div>
		</div>
		<mat-icon *ngIf="isMobile">location_on</mat-icon>
	</a>
	<a href="tel:{{ phone }}">
		<div class="login-footer-text">{{ phone }}</div>
	</a>
</div>
