import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { SettingsPageAction } from '../../../state/settings/action';
import { MilestoneSettingsPageAction } from '../../state/action';

@Component({
	selector: 'ea-milestone-settings-footer',
	templateUrl: './milestone-settings-footer.component.html',
	styleUrls: ['./milestone-settings-footer.component.scss'],
})
export class MilestoneSettingsFooterComponent {
	constructor(private location: Location, private store: Store) {}

	goBack = (): void => {
		this.location.back();
	};

	recalculateStatuses = (): void => {
		this.store.dispatch(MilestoneSettingsPageAction.recalculateStatuses());
	};

	create = (): void => {
		this.store.dispatch(MilestoneSettingsPageAction.clearCurrentMilestoneSetting());
		this.store.dispatch(SettingsPageAction.navigate({ url: `/milestone/settings/new` }));
	};
}
