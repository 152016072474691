<div [formGroup]="formGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<nc-quick-search
		[textInputId]="'treatment'"
		[resetButtonId]="'treatment-reset'"
		[undoButtonId]="'treatment-undo'"
		[formGroup]="formGroup"
		placeholder="defaultSelectPleaceholder"
		formControlName="treatmentId"
		[fetchAll]="searchMedicalInstitutions"
		label="medicalInstitution"
	>
	</nc-quick-search>
	<div>
		<nc-input id="treatmentInstitution" label="treatmentInstitution" [formGroup]="formGroup" formControlName="treatmentInstitution"></nc-input>
	</div>
	<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15px"></div>
	<h2>
		<nc-label id="address" value="address"></nc-label>
	</h2>
	<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15px">
		<nc-input id="city" label="city" [formGroup]="formGroup" formControlName="city"></nc-input>
		<nc-input id="zipCode" label="zipCode" [formGroup]="formGroup" formControlName="zipCode"></nc-input>
	</div>
</div>
