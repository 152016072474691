<form [formGroup]="agreementFormGroup" fxFill fxLayout="column">
	<div *ngIf="agreementFormGroup.controls.noOtherInsurance.value" fxLayout="row" fxLayoutGap="20px">
		<nc-select
			*ngIf="!agreementFormGroup.controls.otherInsurance.value"
			id="otherInsurancePredefined"
			label="otherInsurance"
			formControlName="otherInsurancePredefined"
			[removeSelected]="true"
			[searchable]="false"
			[formGroup]="agreementFormGroup"
			[data$]="otherInsurancePredefined$"
			[translate]="true"
			fxFlex="30"
		>
		</nc-select>
		<nc-input
			*ngIf="!agreementFormGroup.controls.otherInsurancePredefined.value"
			id="other-insurance"
			formControlName="otherInsurance"
			label="otherInsurance"
			[formGroup]="agreementFormGroup"
			fxFlex="80"
		></nc-input>
	</div>
	<nc-checkbox
		id="no-other-insurance"
		formControlName="noOtherInsurance"
		label="noOtherInsurance"
		[formGroup]="agreementFormGroup"
		[isReadonly]="agreementFormGroup.controls.otherInsurancePredefined.value || agreementFormGroup.controls.otherInsurance.value"
		(click)="clearOtherInsurance()"
	></nc-checkbox>
</form>
