import { Component, Input } from '@angular/core';

@Component({
	selector: 'ea-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {
	@Input() title: string;
	@Input() cssClass: string;
}
