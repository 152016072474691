import { createReducer, on } from '@ngrx/store';
import { REPORT_INITIAL_STATE, ReportState } from './report.state';
import { ReportApiAction, ReportPageAction } from './action';

export const reportReducer = createReducer<ReportState>(
	REPORT_INITIAL_STATE,
	on(ReportApiAction.getReportSuccess, (state, action): ReportState => {
		return {
			...state,
			report: { records: action.records },
		};
	}),
	on(ReportPageAction.clearData, (): ReportState => REPORT_INITIAL_STATE)
);
