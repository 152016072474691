<form [formGroup]="attachmentsFormGroup" fxFill fxLayout="column">
	<!--	<ea-core-banner [display]="isBannerIsVisible()" [message]="'requestIsInProgress'" [styleClass]="'attachments-banner'"></ea-core-banner>-->
	<div fxLayout="row" fxLayoutGap="20px" class="divider-containers">
		<ea-container title="sentDocuments" fxFlex="100" *ngIf="(receivedAttachment$ | async)?.records?.length">
			<nc-datatable
				id="table-sentDoc"
				[columns]="sentColumns"
				[data$]="receivedAttachment$"
				[filterConfig]="filterConfigReceived"
			></nc-datatable>
		</ea-container>
	</div>
	<!--	<div fxLayout="row" fxLayoutGap="20px" class="divider-containers">-->
	<!--		<nc-button id="fetch-time-table" label="fetchTimeTable" (click)="fetchTimeTable()" [disabled]="fetchTimeTableDisabled"></nc-button>-->
	<!--		<nc-button-->
	<!--			id="fetch-employment-contract"-->
	<!--			label="fetchEmploymentContract"-->
	<!--			(click)="fetchEmploymentContract()"-->
	<!--			[disabled]="fetchEmploymentContractDisabled"-->
	<!--		></nc-button>-->
	<!--	</div>-->
	<div fxLayout="row" fxLayoutGap="20px" class="divider-containers">
		<ea-container title="documents" fxFlex="100">
			<nc-datatable
				id="table-receivedDoc"
				[rowClick]="edit"
				[columns]="columns"
				[data$]="attachment$"
				[selectConfig]="selectConfig"
				[filterConfig]="filterConfig"
			></nc-datatable>
		</ea-container>
	</div>
</form>
