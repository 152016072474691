import * as ApplicationState from '../../state/app.state';
import { DocumentTypeTranslation } from '../model/document-type-translation';
import { TableData } from 'nc-datatable';

export interface DocumentTypesState {
	documentTypes: TableData;
	currentDocumentType: DocumentTypeTranslation[];
}

export interface State extends ApplicationState.State {
	documentType: DocumentTypesState;
}

export const DOCUMENT_TYPE_INITIAL_STATE: DocumentTypesState = {
	documentTypes: { records: [] },
	currentDocumentType: [],
};
