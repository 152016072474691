import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '../../../model/menu-item';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SettingsSelect } from '../../../../state/settings/action';

@Component({
	selector: 'ea-sub-menu',
	templateUrl: './sub-menu.component.html',
	styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit {
	@Input() icon: string;
	@Input() label: string;
	@Input() children: MenuItem[];
	collapsed: boolean;

	constructor(private store: Store) {}

	collapseItems = () => {
		this.collapsed = !this.collapsed;
	};

	ngOnInit(): void {
		this.store
			.select(SettingsSelect.getActiveMenuItem)
			.pipe(take(1))
			.subscribe((value) => (this.collapsed = this.children.filter((child) => child.url === value).length > 0));
	}
}
