import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import { UserPageAction } from '../../state/action';

@Component({
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
	formGroup: FormGroup;

	constructor(private store: Store<State>, private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({
			username: ['', [Validators.required]],
		});
	}

	onSubmit = (): void => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.forgotPasswordSubmit(data));
	};
}
