<ea-container cssClass="margin-top-15">
	<mat-tab-group mat-align-tabs="left">
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<div class="menu-icon">
					{{ 'simpleMessage' | translate }}
					<mat-icon color="primary"
						>mail_outline
						<span class="mat-badge-content mat-badge-active">{{ messageCounter.simpleMessageNumber }}</span>
					</mat-icon>
				</div>
			</ng-template>
			<div fxLayout="column" fxLayoutGap="20px">
				<div class="button-add-container">
					<nc-button id="newSimpleMessage" [disabled]="isBranchHr" (click)="newSimpleMessage()" label="newSimpleMessage"></nc-button>
				</div>
				<div>
					<ea-table-dialog-message [absenceCode]="absenceCode" [formGroup]="formGroup" [data$]="simpleData$" type="SIMPLE_MESSAGE">
					</ea-table-dialog-message>
				</div>
			</div>
		</mat-tab>
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<div class="menu-icon">
					{{ 'taskWithDeadline' | translate }}
					<mat-icon color="primary"
						>mail_outline
						<span class="mat-badge-content mat-badge-active">{{ messageCounter.taskWithDeadlineMessageNumber }}</span>
					</mat-icon>
				</div>
			</ng-template>
			<ea-table-dialog-message [data$]="taskDeadlineData$" [absenceCode]="this.absenceCode" [formGroup]="formGroup" type="TASK_WITH_DEADLINE">
			</ea-table-dialog-message>
		</mat-tab>
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<div class="menu-icon">
					{{ 'taskWithConfirmation' | translate }}
					<mat-icon color="primary"
						>mail_outline
						<span class="mat-badge-content mat-badge-active">{{ messageCounter.taskWithConfirmationMessageNumber }}</span>
					</mat-icon>
				</div>
			</ng-template>
			<ea-table-dialog-message
				[data$]="taskConfirmationData$"
				[absenceCode]="absenceCode"
				[formGroup]="formGroup"
				type="TASK_WITH_CONFIRMATION"
			>
			</ea-table-dialog-message>
		</mat-tab>
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<div class="menu-icon">
					{{ 'contactPerson' | translate }}
					<mat-icon color="primary"
						>mail_outline
						<span class="mat-badge-content mat-badge-active">{{ messageCounter.contactPersonMessageNumber }}</span></mat-icon
					>
				</div>
			</ng-template>
			<ea-table-dialog-message [data$]="contactPersonData$" [absenceCode]="absenceCode" [formGroup]="formGroup" type="CONTACT_PERSON">
			</ea-table-dialog-message>
		</mat-tab>
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<div class="menu-icon">
					{{ 'workPlaceDescription' | translate }}
					<mat-icon color="primary"
						>mail_outline <span class="mat-badge-content mat-badge-active">{{ messageCounter.workPlaceMessageNumber }}</span></mat-icon
					>
				</div>
			</ng-template>
			<ea-table-dialog-message [data$]="workplaceData$" [absenceCode]="absenceCode" [formGroup]="formGroup" type="WORKPLACE_DESCRIPTION">
			</ea-table-dialog-message>
		</mat-tab>
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<div class="menu-icon">
					{{ 'reasonForAbsence' | translate }}
					<mat-icon color="primary"
						>mail_outline
						<span class="mat-badge-content mat-badge-active">{{ messageCounter.reasonForAbsenceMessageNumber }}</span></mat-icon
					>
				</div>
			</ng-template>
			<ea-table-dialog-message [data$]="reasonData$" [absenceCode]="absenceCode" [formGroup]="formGroup" type="REASON_FOR_ABSENCE">
			</ea-table-dialog-message>
		</mat-tab>
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<div class="menu-icon">
					{{ 'freeMessage' | translate }}
					<mat-icon color="primary"
						>mail_outline <span class="mat-badge-content mat-badge-active">{{ messageCounter.freeDialogMessageNumber }}</span></mat-icon
					>
				</div>
			</ng-template>
			<ea-table-dialog-message [data$]="freeMessageData$" [absenceCode]="absenceCode" [formGroup]="formGroup" type="FREE_DIALOG_MESSAGE">
			</ea-table-dialog-message>
		</mat-tab>
	</mat-tab-group>
</ea-container>
