import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { StatisticPageAction, StatisticSelect } from '../../state/action';
import { Observable, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { DatatableColumn, TableData } from 'nc-datatable';
import { isCompanyRequired, isPersonRequired } from '../../validator/statistic.validator';
import { StatisticChartColors } from '../../model/statistic-chart-colors';
import { TranslateService } from '@ngx-translate/core';
import { ChartData } from '../../../shared/model/chart/chart-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ExportExtensionEnumeration } from '../../../shared/enumeration/export-extension.enumeration';
import { ChartTypeEnumeration } from '../../../shared/model/chart/chart-type.enumeration';
import { StatisticTypeEnumeration } from '../../enum/statistic-type.enumeration';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { DialogConfigurationUtils, DialogService, DialogType, FormUtils } from 'nc-utils';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { AppConstants } from '../../../app.constants';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	templateUrl: './excel-statistic.component.html',
	styleUrls: ['./excel-statistic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExcelStatisticComponent extends BaseComponent implements OnInit, OnDestroy {
	formGroup: FormGroup;
	tableData$: Observable<TableData>;
	columns$: Observable<DatatableColumn[]>;
	isMobile: boolean;
	isTotalVisible = false;
	chartColors = StatisticChartColors;
	chartType = ChartTypeEnumeration;

	overallChartData$: Observable<Array<ChartData>>;
	totalCmChartData$: Observable<Array<ChartData>>;
	reasonsForSuccessfulCmChartData$: Observable<Array<ChartData>>;
	reasonsForNotSuccessfulCmChartData$: Observable<Array<ChartData>>;
	averageAbsenceDurationPerIllnessChartData$: Observable<Array<ChartData>>;
	averageAbsenceDurationPerAccidentChartData$: Observable<Array<ChartData>>;
	filterConfig: FilterConfig;

	constructor(
		private store: Store,
		formBuilder: FormBuilder,
		translateService: TranslateService,
		breakpointObserver: BreakpointObserver,
		private dialogService: DialogService
	) {
		super();
		this.formGroup = formBuilder.group(
			{
				dateFrom: ['', [Validators.required]],
				dateTo: ['', [Validators.required]],
				type: ['OVERALL'],
				insuranceType: ['PKPRO'],
				company: [''],
				person: [''],
				absenceActivity: ['ALL'],
			},
			{
				validators: [isCompanyRequired(), isPersonRequired()],
			}
		);

		breakpointObserver.observe(['(max-width: 900px)']).subscribe((result) => (this.isMobile = result.matches));

		this.tableData$ = store.select(StatisticSelect.getRecords);
		this.columns$ = store.select(StatisticSelect.getColumns);
		this.overallChartData$ = store.select(StatisticSelect.getOverallChartData);
		this.totalCmChartData$ = store.select(StatisticSelect.getTotalCmChertData);
		this.reasonsForSuccessfulCmChartData$ = store.select(StatisticSelect.getReasonsForSuccessfulCmChartData);
		this.reasonsForNotSuccessfulCmChartData$ = store.select(StatisticSelect.getReasonsForNotSuccessfulCmChartData);
		this.averageAbsenceDurationPerIllnessChartData$ = store.select(StatisticSelect.getAverageAbsenceDurationPerIllnessChartData);
		this.averageAbsenceDurationPerAccidentChartData$ = store.select(StatisticSelect.getAverageAbsenceDurationPerAccidentChartData);
		this.filterConfig = {
			showFilter: false,
			filterValues: {},
		};
	}

	ngOnInit(): void {
		this.formGroup
			.get('type')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((value) => this.store.dispatch(StatisticPageAction.setType({ statisticType: value })));
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.store.dispatch(StatisticPageAction.clearData());
	}

	onSearch = (): void => {
		this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
		this.isTotalVisible = this.formGroup.get('type').value === StatisticTypeEnumeration.OVERALL.toUpperCase();
		this.store.dispatch(StatisticPageAction.clearData());
		const data = this.formGroup.getRawValue();
		if (!this.formGroup.invalid) {
			this.store.dispatch(StatisticPageAction.search({ searchFilter: data }));
		} else {
			this.dialogService.close(DialogType.PROCESSING);
			this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('checkAllData', 'info'));
		}
	};

	onExport = (): void => {
		this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
		const data = this.formGroup.getRawValue();
		if (!this.formGroup.invalid) {
			this.store.dispatch(StatisticPageAction.exportToFile({ searchFilter: data, exportType: ExportExtensionEnumeration.EXCEL }));
		} else {
			this.dialogService.close(DialogType.PROCESSING);
			this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('checkAllData', 'info'));
		}
	};
}
