import { FormGroup } from '@angular/forms';
import { StringsUtils } from 'nc-utils';
import { FormValidation } from '../../shared/form-validation';
import { REQUIRED } from '../../shared/utils/form-validation.utils';

/**
 * ValidAsOf is required if we are sending payment information about employee
 * @param formGroup FormGroup
 */
export const employeePaymentValidator = (formGroup: FormGroup) => {
	if (StringsUtils.isNotEmpty(formGroup.controls.employeeAccountOwner.value) && formGroup.controls.validAsOf.value == null) {
		FormValidation.addError(formGroup.controls.validAsOf, REQUIRED);
	} else {
		FormValidation.removeError(formGroup.controls.validAsOf, REQUIRED);
	}
};
