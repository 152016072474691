import { Component, Input } from '@angular/core';

@Component({
	selector: 'ea-company-branch',
	templateUrl: './company-branch.component.html',
	styleUrls: ['./company-branch.component.scss'],
})
export class CompanyBranchComponent {
	@Input() link: string;
	@Input() city: string;
	@Input() address: string;
	@Input() postCode: string;
	@Input() isMobile: boolean;
	@Input() isHeadquarters: boolean;
	@Input() phone: string;
}
