import { EnumItem } from 'nc-utils';

/**
 * Enumeration which represents type of absence declaration.
 * Regular - Up to 90 days
 * Premium - More than 90 days
 */
export const AbsenceDeclarationTypeEnumeration = {
	REGULAR: new EnumItem('REGULAR', 'regularAbsence'),
	PREMIUM: new EnumItem('PREMIUM', 'premiumAbsence'),
};
