import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumUtils } from 'nc-utils';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ErrorSelect } from '../../../state/error/action';
import { ValidationService } from '../../../shared/service/validation.service';
import { TaskPageAction, TaskSelect } from '../../state/action';
import { TaskModel } from '../../model/task.mode';
import { NcDocumentsService } from 'nc-documents';
import { ColumnBuilder, DatatableColumn } from 'nc-datatable';
import { EncodingService } from '../../../shared/service/encoding.service';

@Component({
	templateUrl: './task-form.component.html',
	styleUrls: ['./task-form.component.scss'],
})
export class TaskFormComponent extends CanDeactivateFormComponent implements OnInit {
	documents: FormArray;
	yesNo: Option[];
	task$: Observable<TaskModel>;
	additionalColumns: DatatableColumn[];

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef,
		private documentService: NcDocumentsService,
		private encodingService: EncodingService
	) {
		super();
		this.formGroup = this.formBuilder.group({
			id: ['', null],
			absenceId: ['', null],
			absenceCode: ['', null],
			title: ['', Validators.required],
			note: ['', null],
			done: [FlagEnumeration.no, null],
			important: [FlagEnumeration.no, null],
			status: [FlagEnumeration.no, null],
			replicateForActiveAbsencesSet: [FlagEnumeration.no, null],
			dueDate: ['', Validators.required],
			documents: this.formBuilder.array([]),
		});

		this.route.params.subscribe((params) => {
			if (params.id) {
				this.store.dispatch(TaskPageAction.getTask({ taskId: params.id }));
			}
		});

		this.store
			.select(TaskSelect.getCurrentAbsence)
			.pipe(filter((currentAbsence) => currentAbsence != null))
			.subscribe((currentAbsence) => {
				this.formGroup.get('absenceId').patchValue(currentAbsence.absenceId);
				this.formGroup.get('absenceCode').patchValue(currentAbsence.absenceCode);
			});

		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));
	}

	ngOnInit(): void {
		this.task$ = this.store.select(TaskSelect.getTask);
		this.documents = this.formGroup.get('documents') as FormArray;
		this.yesNo = EnumUtils.toItems(FlagEnumeration);
		this.task$ = this.store.select(TaskSelect.getTask);
		this.task$
			.pipe(
				filter((task) => task !== null && task.id !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((task) => this.displayTask(task));

		this.additionalColumns = [ColumnBuilder.create('uploadedBy', 'uploadedBy'), ColumnBuilder.create('uploadedOn', 'uploadedOn')];
	}

	dispatchSubmitAction = (): void => {
		const task = this.formGroup.getRawValue();
		task.documents = this.documentService.getDocuments(this.formGroup);
		task.documents = this.encodingService.encodeDocuments(task.documents);
		this.store.dispatch(TaskPageAction.saveTask({ task }));
	};

	displayTask = (task: TaskModel): void => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			id: task.id,
			absenceId: task.absenceId,
			title: task.title,
			note: task.note,
			done: task.done ? FlagEnumeration.yes : FlagEnumeration.no,
			important: task.important ? FlagEnumeration.yes : FlagEnumeration.no,
			status: task.status ? FlagEnumeration.yes : FlagEnumeration.no,
			replicateForActiveAbsencesSet: task.replicateForActiveAbsencesSet ? FlagEnumeration.yes : FlagEnumeration.no,
			dueDate: task.dueDate,
		});
		const documents = this.formGroup.get('documents') as FormArray;
		this.documentService.populateData(documents, task.documents);
	};
}
