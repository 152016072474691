import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DialogService } from 'nc-utils';
import { BehaviorSubject } from 'rxjs';
import { ManualAdditionDialogComponent } from '../component/annual-salary/components/manual-addition/manual-addition-dialog/manual-addition-dialog.component';
import { TableData } from 'nc-datatable';
import { KleKindOfSalary } from '../model/kle-kind-of-salary.model';

@Injectable({ providedIn: 'root' })
export class AnnualSalaryService {
	constructor(private formBuilder: FormBuilder, private dialogService: DialogService) {}

	/**
	 * Subscribes update table on form array value change
	 * @param formArray manualAdditions form array
	 * @param data$ manualAdditions behaviour subject that is linked to grid
	 */
	initialize(formArray: FormArray, data$: BehaviorSubject<TableData>): void {
		formArray.valueChanges.subscribe(() => {
			this.updateTable(data$, formArray);
		});
	}

	/**
	 * Initializes form array with existing manualAdditions
	 * @param formArray incapacity form array
	 * @param manualAdditions existing manualAdditions
	 */
	populateData(formArray: FormArray, manualAdditions: KleKindOfSalary[]): void {
		formArray?.clear();

		manualAdditions.forEach((manualAddition, index) => {
			formArray.push(this.createManualAdditionFormGroup(index, manualAddition));
		});
	}

	/**
	 * Deletes ManualAddition
	 * @param model model from grid
	 * @param formArray KleKindOfSalary form array
	 */
	delete(model: KleKindOfSalary, formArray: FormArray): void {
		const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
		formArray.removeAt(index);
		for (let i = 0; i < formArray.length; i++) {
			if (!formArray.at(i).get('deleted').value) {
				formArray.at(i).get('isFirst').patchValue(true);
				break;
			}
		}
	}

	/**
	 * Opens dialog for creation or edit of ManualAddition
	 * @param model KleKindOfSalary model if exists or else null
	 * @param formArray KleKindOfSalary form array
	 * @param formGroupParent parent form group
	 */
	openDialog(model: KleKindOfSalary, formArray: FormArray, formGroupParent: FormGroup): void {
		const formGroup = this.getFormGroup(formArray, model);
		const dialogConfig = {
			width: '500px',
			data: { formGroup },
		};

		const dialogRef = this.dialogService.open(ManualAdditionDialogComponent, dialogConfig);

		dialogRef
			.afterClosed()
			.pipe(filter((result) => result?.success))
			.subscribe(() => {
				formGroupParent.markAsDirty();
				if (!model) {
					formArray.push(formGroup);
				} else {
					const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
					formArray.removeAt(index);
					formArray.insert(index, formGroup);
				}
			});
	}

	/**
	 * Gets form group from formArray if model is populated or creates new form group
	 * @param formArray KleKindOfSalary form array
	 * @param model KleKindOfSalary model
	 */
	private getFormGroup = (formArray: FormArray, model?: KleKindOfSalary): FormGroup => {
		let formGroup: FormGroup;

		if (model) {
			const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
			const existing = formArray.at(index) as FormGroup;
			formGroup = this.createManualAdditionFormGroup(model.tableId, existing.getRawValue());
		} else {
			const tableId = formArray.length === 0 ? 0 : formArray.at(formArray.length - 1).get('tableId').value + 1;
			formGroup = this.createManualAdditionFormGroup(tableId);
		}

		return formGroup;
	};

	/**
	 * Updates table based on data from form array
	 * @param data$ ManualAdditionData behaviour subject that is linked to grid
	 * @param formArray incapacity form array
	 */
	private updateTable = (data$: BehaviorSubject<TableData>, formArray: FormArray) => {
		const tableData = { records: [] };
		for (let i = 0; i < formArray.length; i++) {
			const formGroup = formArray.at(i) as FormGroup;
			const rawValue = formGroup.getRawValue() as KleKindOfSalary;
			tableData.records.push(rawValue);
		}
		data$.next(tableData);
	};

	/**
	 * Creates new form group for ManualAddition
	 * @param tableId table id value for new form group
	 * @param manualAddition manualAddition for form group data
	 */
	private createManualAdditionFormGroup = (tableId: number, manualAddition?: KleKindOfSalary): FormGroup => {
		return this.formBuilder.group({
			amount: [manualAddition?.amount, Validators.required],
			kindOfSalary: [manualAddition?.kindOfSalary, Validators.required],
			statisticCode: [manualAddition?.statisticCode, Validators.required],
			tableId: [tableId],
		});
	};
}
