<div [formGroup]="reasonForAbsenceMessageGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div class="scroll-dialog-content">
		<div fxLayout="column">
			<nc-input id="title" label="title" [formGroup]="reasonForAbsenceMessageGroup" formControlName="title" [isReadonly]="true"></nc-input>
		</div>
		<div fxLayout="column">
			<h4>{{ 'info' | translate }}</h4>
			<p class="text-wrapped">{{ dialogMessage?.reasonForAbsenceMessage?.info }}</p>
		</div>
		<div fxLayout="column">
			<nc-textarea
				id="description"
				label="description"
				[formGroup]="reasonForAbsenceMessageGroup"
				formControlName="description"
				[isReadonly]="true"
			>
			</nc-textarea>
		</div>
		<div fxLayout="column">
			<h4>{{ 'listOfReasons' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<nc-checkbox id="disease" label="disease" [formGroup]="reasonForAbsenceMessageGroup" formControlName="disease"></nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox id="accident" label="accident" [formGroup]="reasonForAbsenceMessageGroup" formControlName="accident"></nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox id="maternity" label="maternity" [formGroup]="reasonForAbsenceMessageGroup" formControlName="maternity"></nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox id="militaryService" label="militaryService" [formGroup]="reasonForAbsenceMessageGroup" formControlName="militaryService">
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox id="vacation" label="vacation" [formGroup]="reasonForAbsenceMessageGroup" formControlName="vacation"></nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox id="unpaidLeave" label="unpaidLeave" [formGroup]="reasonForAbsenceMessageGroup" formControlName="unpaidLeave"></nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox id="leavingCompany" label="leavingCompany" [formGroup]="reasonForAbsenceMessageGroup" formControlName="leavingCompany">
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="activityWithAnotherEmployer"
				label="activityWithAnotherEmployer"
				[formGroup]="reasonForAbsenceMessageGroup"
				formControlName="activityWithAnotherEmployer"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column" [ngClass]="'margin-top-15'">
			<nc-textarea id="otherReason" label="otherReason" [formGroup]="reasonForAbsenceMessageGroup" formControlName="otherReason"></nc-textarea>
		</div>
	</div>
	<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-submit-button
			id="send-dialog-message"
			buttonLabel="replay"
			(buttonClick)="submit()"
			[formGroup]="reasonForAbsenceMessageGroup"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</div>
