import { EnumItem } from 'nc-utils';

export const StoryRecordEnum = {
	DialogMessageStory: new EnumItem('DialogMessageStory', 'dialogMessage'),
	ProcessCtrlResponseStory: new EnumItem('ProcessCtrlResponseStory', 'processControlResponse'),
	CoveredDailyAllowanceStory: new EnumItem('CoveredDailyAllowanceStory', 'coveredDailyAllowance'),
	CrossChannelLinkStory: new EnumItem('CrossChannelLinkStory', 'crossChannelLink'),
	SettlementStory: new EnumItem('SettlementStory', 'settlement'),
	RepaymentStory: new EnumItem('RepaymentStory', 'repayment'),
	IncapacityToWorkStory: new EnumItem('IncapacityToWorkStory', 'incapacity'),
	PaymentStory: new EnumItem('PaymentStory', 'payment'),
	AdditionsPersonStory: new EnumItem('AdditionsPersonStory', 'additionsPerson'),
	TreatmentStory: new EnumItem('TreatmentStory', 'treatments'),
	AccidentDescriptionStory: new EnumItem('AccidentDescriptionStory', 'accidentDescription'),
	AnnualSalaryStory: new EnumItem('AnnualSalaryStory', 'annualSalary'),
	OtherEmployersStory: new EnumItem('OtherEmployersStory', 'otherEmployers'),
	PersonMutatedStory: new EnumItem('PersonMutatedStory', 'personMutated'),
	ShortTimeWorkStory: new EnumItem('ShortTimeWorkStory', 'shortTimeWork'),
	SpecialCodeStory: new EnumItem('SpecialCodeStory', 'specialCode'),
	AgreementStory: new EnumItem('AgreementStory', 'agreement'),
	SpecialCoverageStory: new EnumItem('SpecialCoverageStory', 'specialCoverage'),
	AbsenceStory: new EnumItem('AbsenceStory', 'absences'),
	TakeWorkUpFullyStory: new EnumItem('TakeWorkUpFullyStory', 'takeWorkUpFully'),
	ProcessCtrlRequestStory: new EnumItem('ProcessCtrlRequestStory', 'processControl'),
	AttachmentStory: new EnumItem('AttachmentStory', 'attachments'),
};
