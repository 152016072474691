import { createAction, props } from '@ngrx/store';
import { InteroperabilityResponse } from '../../model/response/interoperability-response';
import { DeclareIncidentResponse } from '../../model/response/declare-incident.response';
import { PaymentResponse } from '../../model/response/payment.response';
import { TableData } from 'nc-datatable';
import { Settlement } from '../../model/settlement/settlement';
import { RegisterOrganization } from '../../model/register-organization';
import { GetResultResponse } from '../../model/response/get-result-response';
import { SynchronizeIncidentResponse } from '../../model/response/synchronize-incident.response';
import { PersonMutated } from '../../model/person-mutated';
import { IncidentResponse } from '../../model/response/incident.response';

export const sendPingFail = createAction('[KLE API] Send ping fail', props<{ message: string }>());
export const sendPingSuccess = createAction(
	'[KLE API] Send ping success',
	props<{
		dateTime: string;
		success: boolean;
		timeDifference: number;
	}>()
);
export const checkInteroperabilitySuccess = createAction(
	'[KLE API] Check interoperability success',
	props<{
		response: InteroperabilityResponse;
	}>()
);
export const declareIncidentTestSuccess = createAction(
	'[KLE API] Declare incident test success',
	props<{
		response: DeclareIncidentResponse;
	}>()
);

export const getPaymentForPersonSuccess = createAction(
	'[KLE API] Get payment for person success',
	props<{
		response: PaymentResponse;
	}>()
);
export const getPaymentForCompanySuccess = createAction(
	'[KLE API] Get payment for company success',
	props<{
		response: PaymentResponse;
	}>()
);
export const getPersonMutatedSuccess = createAction(
	'[KLE API] Get person mutated success',
	props<{
		response: PersonMutated;
	}>()
);

export const synchronizeIncidentSuccess = createAction(
	'[KLE API] Synchronize incident success',
	props<{
		response: SynchronizeIncidentResponse;
	}>()
);
export const getAdditionsPersonSuccess = createAction(
	'[KLE API] Get employment contract success',
	props<{
		response: any;
	}>()
);
export const getIncidentSuccess = createAction(
	'[KLE API] Get incident data success',
	props<{
		response: IncidentResponse;
	}>()
);
export const getMedicalInstitutionSuccess = createAction(
	'[KLE API] Get medical institution success',
	props<{
		response: any;
	}>()
);
export const getDialogMessageSuccess = createAction(
	'[KLE API] Get dialog message success',
	props<{
		dialogMessage: any;
	}>()
);
export const getSettlementSuccess = createAction('[KLE API] Get settlement', props<{ settlement: Settlement }>());
export const getCompaniesSuccess = createAction('[KLE API] Get companies success', props<{ companies: TableData }>());
export const fetchRegisterOrganizationModelSuccess = createAction(
	'[KLE] Fetch company success',
	props<{
		response: RegisterOrganization;
	}>()
);
export const registerOrganizationSuccess = createAction(
	'[KLE] Register organization success',
	props<{
		response: any;
	}>()
);
export const getCertificateRequestIdSuccess = createAction(
	'[KLE] Get CertificateRequestId success',
	props<{
		response: string;
	}>()
);
export const getResultSuccess = createAction(
	'[KLE] Get result for register organization success',
	props<{
		response: GetResultResponse;
	}>()
);
export const signCertificateSuccess = createAction('[KLE] Sign certificate success', props<{ response: any }>());
export const fetchTimetableSuccess = createAction('[KLE] Fetch timetable success', props<{ response: any }>());
