import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LabelManagementComponent } from './component/label-management.component';
import { LabelManagementFormComponent } from './component/label-management-form/label-management-form.component';
import { LabelManagementEffect } from './state/label-management.effect';
import { LABEL_MANAGEMENT_FEATURE_STATE_NAME } from './state/action/label-management.select';
import { labelManagementReducer } from './state/label-management.reducer';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([LabelManagementEffect]),
		StoreModule.forFeature(LABEL_MANAGEMENT_FEATURE_STATE_NAME, labelManagementReducer),
	],
	declarations: [LabelManagementComponent, LabelManagementFormComponent],
	exports: [LabelManagementComponent, LabelManagementFormComponent],
})
export class LabelManagementModule {}
