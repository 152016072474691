import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogConfigurationUtils, DialogService, DialogType } from 'nc-utils';
import { Store } from '@ngrx/store';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UserAdministrationService } from '../service/user-administration.service';
import { UserAdministrationApiAction, UserAdministrationPageAction } from './action';
import { ErrorAction } from '../../state/error/action';
import { SettingsPageAction } from '../../state/settings/action';
import { DeleteConfirmationDialogComponent } from '../../shared/component/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';

@Injectable()
export class UserAdministrationEffect {
	constructor(
		private actions$: Actions,
		private userAdministrationService: UserAdministrationService,
		private dialogService: DialogService,
		private store: Store
	) {}

	getAllUsersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationPageAction.getAllUsers),
			switchMap(() => this.userAdministrationService.getAllUsers()),
			map((result) => UserAdministrationApiAction.getAllUsersSuccess({ users: result }))
		);
	});

	getUserEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationPageAction.getUser),
			switchMap((action) => this.userAdministrationService.getUser(action.id)),
			map((result) => UserAdministrationApiAction.getUserSuccess({ user: result }))
		);
	});

	generatePasswordEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationPageAction.generatePassword),
			switchMap(() => this.userAdministrationService.generatePassword()),
			map((password) => UserAdministrationApiAction.generatePasswordSuccess({ password }))
		);
	});

	saveUserEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationPageAction.saveUser),
			mergeMap((action) => this.userAdministrationService.saveUser(action.user)),
			map((response) =>
				response.success ? UserAdministrationApiAction.saveUserSuccess() : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	saveUserSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationApiAction.saveUserSuccess),
			map(() => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.navigate({ url: '/user-administration' });
			})
		);
	});

	openConfirmDeleteDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserAdministrationPageAction.openDeleteConfirmationDialog),
				switchMap((action) => {
					const dialogConfig = { disableClose: true, data: { id: action.id } };
					return this.dialogService.open(DeleteConfirmationDialogComponent, dialogConfig).afterClosed();
				}),
				tap((dialogResult) => {
					if (dialogResult.success) {
						this.store.dispatch(UserAdministrationPageAction.deleteUser({ id: dialogResult.id }));
					}
				})
			);
		},
		{ dispatch: false }
	);

	deleteUserEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationPageAction.deleteUser),
			switchMap((action) => this.userAdministrationService.deleteUser(action.id)),
			map((response) => {
				return response.success ? UserAdministrationPageAction.getAllUsers() : ErrorAction.submitFailed({ errors: response.errors });
			})
		);
	});

	activateUserEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationPageAction.activateUser),
			switchMap((action) => {
				this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
				return this.userAdministrationService.activateUser(action.id);
			}),
			map((response) => {
				let nextAction;
				this.dialogService.close(DialogType.PROCESSING);
				if (response.success) {
					nextAction = UserAdministrationPageAction.getAllUsers();
				} else {
					nextAction = ErrorAction.submitFailed({ errors: { message: { code: response.message, parameters: null } } });
				}

				return nextAction;
			})
		);
	});

	deactivateUserEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserAdministrationPageAction.deactivateUser),
			switchMap((action) => {
				this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
				return this.userAdministrationService.deactivateUser(action.id);
			}),
			map((response) => {
				let nextAction;
				this.dialogService.close(DialogType.PROCESSING);
				if (response.success) {
					nextAction = UserAdministrationPageAction.getAllUsers();
				} else {
					nextAction = ErrorAction.submitFailed({ errors: { message: { code: response.message, parameters: null } } });
				}

				return nextAction;
			})
		);
	});
}
