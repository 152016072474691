import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { SettingsPageAction } from '../../../state/settings/action';
import { StringsUtils } from 'nc-utils';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	selector: 'ea-documents-table',
	templateUrl: './documents-table.component.html',
	styleUrls: ['./documents-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsTableComponent extends BaseComponent implements OnInit {
	@Input() documentList$: Observable<TableData>;
	@Input() showCommentRow = false;
	documentColumns: DatatableColumn[];
	filterConfig: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;

	constructor(private store: Store) {
		super();

		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: true,
			filterValues: {},
		};
	}

	ngOnInit(): void {
		this.documentColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('type', 'documentType'),
			ColumnBuilder.create('documentName', 'documentName'),
			ColumnBuilder.create('uploadedBy', 'uploadedBy'),
		];

		if (this.showCommentRow) {
			this.documentColumns.push(ColumnBuilder.create('comment', 'comment'));
		}

		this.documentColumns.push(
			ColumnBuilder.createDate('uploadedOn', 'uploadedOn'),
			ColumnBuilder.createAction('download', 'download', this.download, { defaultIcon: 'download' }, this.isDownloadVisible),
			ColumnBuilder.createAction('preview', 'preview', this.preview, { defaultIcon: 'preview' }, this.isPreviewVisible)
		);
	}

	isDownloadVisible = (rowData): boolean => {
		return StringsUtils.isNotEmpty(rowData.documentName);
	};

	isPreviewVisible = (rowData): boolean => {
		const name = rowData.documentName;
		return (
			StringsUtils.isNotEmpty(name) &&
			(name.endsWith('.pdf') || name.endsWith('.png') || name.endsWith('.jpg') || name.endsWith('.jpeg') || name.endsWith('.bmp'))
		);
	};

	download = (rowData): void => {
		this.store.dispatch(SettingsPageAction.downloadDocument({ id: rowData.documentId }));
	};

	preview = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `document/${rowData.documentName}/${rowData.documentId}` }));
	};
}
