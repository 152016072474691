<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div class="footer-navigation" fxLayout="row wrap" fxLayoutGap="0px" fxLayoutGap.lt-md="5px" fxLayoutAlign="space-between">
		<div fxLayout="row wrap" fxLayoutGap="5px" *ngIf="!isHiddenForCmUser()">
			<nc-button id="task" [onClick]="onTasks" *ngIf="!isMobile && !absence.archived" [isRaised]="false" label="tasks"></nc-button>
			<nc-button id="history" [onClick]="onHistory" [isRaised]="false" *ngIf="!isMobile" label="caseHistory"></nc-button>
			<nc-button
				id="generate-document"
				*ngIf="isGenerateButtonVisible() && !isMobile"
				[onClick]="onGenerateDocument"
				[isRaised]="false"
				label="generateDocument"
			>
			</nc-button>
			<nc-button
				id="fetch-document"
				*ngIf="isFetchButtonVisible() && !isMobile"
				[onClick]="onFetchDocument"
				[isRaised]="false"
				label="fetchDocument"
			>
			</nc-button>
			<nc-submit-button
				id="mobiliar-datasheet"
				*ngIf="isMobiliarButtonVisible() && !isMobile"
				(buttonClick)="onMobiliarDataSheet()"
				dialogLabel="processing"
				buttonLabel="mobiliarDataSheet"
			>
			</nc-submit-button>
			<nc-button *ngIf="isMobile" id="menu" label="actions" [isRaised]="false" [matMenuTriggerFor]="menu"></nc-button>

			<mat-menu #menu="matMenu" class="menu">
				<button id="tasks-mobile" class="menu-button" (click)="onTasks()" mat-menu-item>
					{{ 'tasks' | translate }}
				</button>
				<button id="history-mobile" (click)="onHistory()" class="menu-button" mat-menu-item>
					{{ 'caseHistory' | translate }}
				</button>
				<button
					id="generate-document-mobile"
					class="menu-button"
					*ngIf="isGenerateButtonVisible()"
					(click)="onGenerateDocument()"
					mat-menu-item
				>
					{{ 'generateDocument' | translate }}
				</button>
				<button id="fetch-document-mobile" class="menu-button" *ngIf="isFetchButtonVisible()" (click)="onFetchDocument()" mat-menu-item>
					{{ 'fetchDocument' | translate }}
				</button>
				<button
					id="mobiliar-datasheet-mobile"
					class="menu-button"
					*ngIf="isMobiliarButtonVisible()"
					(click)="onMobiliarDataSheet()"
					mat-menu-item
				>
					{{ 'mobiliarDataSheet' | translate }}
				</button>
			</mat-menu>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="5px" *ngIf="absence.hasEditPermission && !absence.merged && !isHiddenForCmUser()">
			<nc-button id="archive" [onClick]="onArchive" *ngIf="isArchiveVisible()" [formGroup]="formGroup" label="archive"></nc-button>
			<nc-button id="activate" *ngIf="isActivateVisible()" [onClick]="onActivate" [formGroup]="formGroup" label="activate"></nc-button>
			<nc-submit-button
				id="save"
				(buttonClick)="onSubmit()"
				*ngIf="!absence.archived"
				dialogLabel="processing"
				[formGroup]="formGroup"
				buttonLabel="save"
			>
			</nc-submit-button>
		</div>
	</div>
</div>
