import { EnumItem } from 'nc-utils';

export enum AccidentDescriptionOptionsEnum {
	ACCIDENT = 'accident',
	OCCUPATIONAL_DISEASE = 'occupationalDisease',
	CASE_OF_DEATH = 'caseOfDeath',
	RELAPSE = 'relapse',
}

export const AccidentDescriptionOptionsEnumItems = {
	ACCIDENT: new EnumItem('ACCIDENT', 'accident'),
	OCCUPATIONAL_DISEASE: new EnumItem('OCCUPATIONAL_DISEASE', 'occupationalDisease'),
	CASE_OF_DEATH: new EnumItem('CASE_OF_DEATH', 'caseOfDeath'),
	RELAPSE: new EnumItem('RELAPSE', 'relapse'),
};
