import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KlePageAction, KleSelect } from '../../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { DialogMessageService } from '../../../service/dialog-message/dialog-message.service';
import { DialogMessage } from '../../../model/dialog-message';
import { DialogMessageTypeEnum } from '../../../model/enums/dialog-message-type.enum';

@Component({
	templateUrl: './confirmation-message-form.component.html',
	styleUrls: ['./confirmation-message-form.component.scss'],
})
export class ConfirmationMessageFormComponent extends BaseComponent implements OnInit {
	readOnly = false;
	dialogMessage: DialogMessage;

	confirmationMessageGroup = this.formBuilder.group({
		storyId: '',
		title: '',
		message: '',
		creator: '',
		description: '',
		dialogMessageType: DialogMessageTypeEnum.TASK_WITH_CONFIRMATION.code,
		companyCaseId: this.data.absenceCode,
		previous: this.data.storyId,
		answerValue: [null as boolean, [Validators.required]],
	});

	constructor(
		private dialogMessageService: DialogMessageService,
		private formBuilder: FormBuilder,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) public data: { absenceCode: string; storyId: string; formGroup: FormGroup; creator: string }
	) {
		super();
	}

	ngOnInit(): void {
		if (this.data.storyId != null) {
			this.readOnly = true;
			this.store.dispatch(KlePageAction.getDialogMessage({ absenceCode: this.data.absenceCode, storyId: this.data.storyId }));
		}

		this.store
			.select(KleSelect.getDialogMessage)
			.pipe(
				takeUntil(this.destroy$),
				filter((message) => message != null)
			)
			.subscribe((message) => {
				this.dialogMessage = message;
				this.dialogMessageService.populateConfirmationMessageForm(this.confirmationMessageGroup, message);
			});
	}

	submit(): void {
		const context = this.data.formGroup.controls.incidentContext.value;
		const confirmationMessage = this.confirmationMessageGroup.getRawValue();

		const dialogMessageData = {
			...confirmationMessage,
			confirmationMessage: {
				answerValue: confirmationMessage.answerValue,
			},
		};

		const request = { incidentContext: context, dialogMessage: dialogMessageData };
		this.store.dispatch(KlePageAction.synchronizeIncident({ request }));
	}
}
