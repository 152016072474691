import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { KlePageAction } from '../../state/action';
import { KleServiceEnum } from '../../model/enums/kle-service.enum';

@Component({
	templateUrl: './kle.component.html',
	styleUrls: ['./kle.component.scss'],
})
export class KleComponent {
	constructor(private store: Store) {}

	incidentDeclarationPing(): void {
		this.store.dispatch(KlePageAction.sendPing({ serviceType: KleServiceEnum.INCIDENT_DECLARATION }));
	}

	organizationAuthenticationPing(): void {
		this.store.dispatch(KlePageAction.sendPing({ serviceType: KleServiceEnum.ORGANIZATION_AUTHENTICATION }));
	}
}
