<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="users-container">
				<nc-datatable id="users" [rowClick]="onRowClick" [columns]="usersColumn" [data$]="users$" [filterConfig]="filterConfig">
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-create-footer [url]="'user-administration/new'"></ea-create-footer>
</div>
