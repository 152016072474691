<div [formGroup]="formGroup" fxFill fxLayout="column">
	<form>
		<div id="companies-ids-container">
			<nc-select
				[id]="'company'"
				[searchTextId]="'company-search'"
				[searchButtonId]="'company-search-clear'"
				[clearIconId]="'company-clear'"
				*ngIf="companyIds.length > 1"
				label="companies"
				formControlName="companies"
				[multiple]="true"
				[enableSelectAll]="true"
				[formGroup]="formGroup"
				[data$]="companies$"
			>
			</nc-select>
		</div>
	</form>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="15px">
				<ea-container [title]="'activeCases'" id="hr-dashboard">
					<nc-datatable
						id="active-cases"
						[rowClick]="rowClicked"
						[columns]="hrDashboardColumn"
						[data$]="data$"
						[filterConfig]="filterConfig"
					>
					</nc-datatable>
				</ea-container>
				<ea-container *ngIf="!isBranchHrUser" title="casesToBeApproved">
					<nc-datatable
						id="cases-to-be-approved"
						[rowClick]="rowClicked"
						[columns]="hrDashboardColumn"
						[data$]="casesToBeApproved"
						[filterConfig]="filterCasesToBeApprovedConfig"
					>
					</nc-datatable>
				</ea-container>
				<div *ngIf="awaitStories$ | async as awaitStories">
					<ea-container
						*ngIf="hasDeclareIncidentPermission && !isBranchHrUser && awaitStories.records.length > 0"
						title="awaitStories"
						id="hr-await-stories"
					>
						<nc-datatable
							id="await-stories-table"
							[columns]="awaitStoriesColumns"
							[data$]="awaitStories$"
							[filterConfig]="filterConfigAwait"
							[rowClick]="openStory"
						>
						</nc-datatable>
					</ea-container>
				</div>
				<ea-container [title]="'statisticForLast12Months'" *ngIf="barChartData$ | async as barChartData">
					<div *ngIf="barChartData.length > 0" fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
						<ea-chart
							fxFlex="50"
							[type]="chartType.PIE"
							chartTitle="caseStatusOverview"
							[data]="pieChartData$ | async"
							[colors]="chartColors.pieChartColor"
						>
						</ea-chart>
						<ea-chart
							fxFlex="50"
							[type]="chartType.STACKED_BAR"
							chartTitle="openedClosedStatusOverview"
							[data]="barChartData$ | async"
							[colors]="chartColors.barChartColor"
						>
						</ea-chart>
					</div>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
</div>
