import { createReducer, on } from '@ngrx/store';
import { LabelManagementApiAction, LabelManagementPageAction } from './action';
import { LABEL_MANAGEMENT_INITIAL_STATE, LabelManagementState } from './label-management.state';

export const labelManagementReducer = createReducer<LabelManagementState>(
	LABEL_MANAGEMENT_INITIAL_STATE,
	on(LabelManagementApiAction.getLabelsSuccess, (state, action): LabelManagementState => {
		return {
			...state,
			labels: { records: action.records },
		};
	}),
	on(LabelManagementApiAction.getLabelSuccess, (state, action): LabelManagementState => {
		return {
			...state,
			currentLabel: action.label,
		};
	}),
	on(LabelManagementPageAction.clearCurrentLabel, (state): LabelManagementState => {
		return {
			...state,
			currentLabel: null,
		};
	}),
	on(LabelManagementPageAction.setCurrentLabel, (state, action): LabelManagementState => {
		return {
			...state,
			currentLabel: {
				...action.label,
			},
		};
	})
);
