import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportEffect } from './state/report.effect';
import { REPORT_FEATURE_STATE_NAME } from './state/action/report.select';
import { reportReducer } from './state/report.reducer';
import { ReportComponent } from './component/report.component';

@NgModule({
	imports: [SharedModule, EffectsModule.forFeature([ReportEffect]), StoreModule.forFeature(REPORT_FEATURE_STATE_NAME, reportReducer)],
	declarations: [ReportComponent],
	exports: [ReportComponent],
})
export class ReportModule {}
