import * as ApplicationState from '../../state/app.state';
import { DatatableColumn, TableData } from 'nc-datatable';
import { StatisticTypeEnumeration } from '../enum/statistic-type.enumeration';
import { ChartData } from '../../shared/model/chart/chart-data';

export interface StatisticState {
	columns: DatatableColumn[];
	type: StatisticTypeEnumeration;
	records: TableData;
	overallChartData: Array<ChartData>;
	totalCmChartData: Array<ChartData>;
	reasonsForSuccessfulCmChartData: Array<ChartData>;
	reasonsForNotSuccessfulCmChartData: Array<ChartData>;
	averageAbsenceDurationPerIllnessChartData: Array<ChartData>;
	averageAbsenceDurationPerAccidentChartData: Array<ChartData>;
}

export interface State extends ApplicationState.State {
	statistic: StatisticState;
}

export const STATISTIC_INITIAL_STATE: StatisticState = {
	records: { records: [] },
	columns: [],
	overallChartData: null,
	totalCmChartData: null,
	reasonsForSuccessfulCmChartData: null,
	reasonsForNotSuccessfulCmChartData: null,
	averageAbsenceDurationPerIllnessChartData: null,
	averageAbsenceDurationPerAccidentChartData: null,
	type: StatisticTypeEnumeration.OVERALL,
};
