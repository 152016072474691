<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div class="footer-navigation" fxLayout="row wrap" fxLayoutAlign="space-between">
		<div fxLayout="row" fxLayoutGap="10px">
			<nc-submit-button
				*ngIf="!isBranchHr"
				id="sync"
				(buttonClick)="sync()"
				dialogLabel="processing"
				[formGroup]="formGroupIncidentContext"
				buttonLabel="synchronize"
			></nc-submit-button>
			<nc-submit-button
				*ngIf="isCoverageYes"
				id="generate-report"
				buttonLabel="accidentCertificate"
				(buttonClick)="generateReport()"
				dialogLabel="processing"
			>
			</nc-submit-button>
			<nc-submit-button
				*ngIf="isCoverageYes"
				id="generate-pharmacy"
				buttonLabel="pharmacyCertificate"
				(buttonClick)="generatePharmacyReport()"
				dialogLabel="processing"
			>
			</nc-submit-button>
		</div>
		<nc-submit-button
			id="submit"
			*ngIf="!isBranchHr"
			(buttonClick)="submit()"
			dialogLabel="processing"
			[formGroup]="formGroup"
			buttonLabel="submit"
		>
		</nc-submit-button>
	</div>
</div>
