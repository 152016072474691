import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { EnumUtils, FormUtils } from 'nc-utils';
import { Option } from 'nc-shared';
import { AbsenceArchiveReasonEnumeration } from '../../../model/enumeration/absence-archive-reason.enumeration';
import { MatDialogRef } from '@angular/material/dialog';
import { isCommentRequired } from './absence-archive-dialog.validator';
import { AppConstants } from '../../../../app.constants';

@Component({
	templateUrl: './absence-archive-dialog.component.html',
	styleUrls: ['./absence-archive-dialog.component.scss'],
})
export class AbsenceArchiveDialogComponent {
	formGroup: FormGroup;
	reasons$: Observable<Option[]>;

	constructor(public dialogRef: MatDialogRef<AbsenceArchiveDialogComponent>, formBuilder: FormBuilder) {
		this.reasons$ = EnumUtils.toObservableItems(AbsenceArchiveReasonEnumeration);
		this.formGroup = formBuilder.group(
			{
				id: [''],
				comment: ['', null],
				reason: ['', [Validators.required]],
			},
			{
				validators: isCommentRequired(),
			}
		);
	}

	submit(): Observable<FormGroup> {
		if (this.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		this.dialogRef.close({ success: true, data: this.formGroup.getRawValue() });
	}
}
