import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '../../model/notification';
import { KleResultTypeEnum } from '../../model/enums/kle-result-type.enum';

@Component({
	templateUrl: './kle-result-dialog.component.html',
	styleUrls: ['./kle-result-dialog.component.scss'],
})
export class KleResultDialogComponent {
	id: string;
	errors: Notification[];
	warnings: Notification[];
	informations: Notification[];
	label: string;
	idLabel: string;
	hasErrors: boolean;
	hasWarnings: boolean;
	hasInformations: boolean;
	isDeclareIncident: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data) {
		this.hasErrors = data.errors?.length > 0;
		this.hasWarnings = data.warnings?.length > 0;
		this.hasInformations = data.informations?.length > 0;
		this.warnings = this.hasWarnings ? data.warnings : [];
		this.informations = this.hasInformations ? data.informations : [];
		this.errors = this.hasErrors ? data.errors : [];
		this.id = data.id;
		this.label = this.hasErrors ? 'error' : 'info';
		this.idLabel = data.resultType === KleResultTypeEnum.DECLARE_INCIDENT ? 'insurance_case_id' : 'successfulTransmission';
		this.isDeclareIncident = data.resultType === KleResultTypeEnum.DECLARE_INCIDENT;
	}
}
