import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MilestoneSettingsService } from '../service/milestone-settings.service';
import { MilestoneSettingsApiAction, MilestoneSettingsPageAction } from './action';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorAction } from '../../state/error/action';
import { DialogService, DialogType } from 'nc-utils';
import { SettingsPageAction, SettingsSelect } from '../../state/settings/action';
import { DeleteConfirmationDialogComponent } from '../../shared/component/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MilestoneSettingsEffect {
	constructor(
		private actions$: Actions,
		private milestoneSettingsService: MilestoneSettingsService,
		private dialogService: DialogService,
		private translateService: TranslateService,
		private store: Store
	) {}

	getDocumentsCheckListEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MilestoneSettingsPageAction.getDocumentsCheckList),
			switchMap(() => this.milestoneSettingsService.getDocumentCheckList()),
			map((result) => MilestoneSettingsApiAction.getDocumentsCheckListSuccess({ documentsCheckList: result }))
		);
	});

	getAllMilestoneSettingsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MilestoneSettingsPageAction.getAllMilestoneSettings),
			switchMap(() => this.milestoneSettingsService.getAllMilestoneSettings()),
			map((result) => MilestoneSettingsApiAction.getAllMilestoneSettingsSuccess({ milestoneSettings: result }))
		);
	});

	getSingleMilestoneSettingsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MilestoneSettingsPageAction.getSingleMilestoneSettings),
			mergeMap((action) => this.milestoneSettingsService.getSingleMilestoneSettings(action.id)),
			map((result) => MilestoneSettingsApiAction.getSingleMilestoneSettingsSuccess({ milestoneSettings: result }))
		);
	});

	openConfirmDeleteDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(MilestoneSettingsPageAction.openDeleteConfirmationDialog),
				switchMap((action) => {
					const dialogConfig = { disableClose: true, data: { id: action.id } };
					return this.dialogService.open(DeleteConfirmationDialogComponent, dialogConfig).afterClosed();
				}),
				tap((dialogResult) => {
					if (dialogResult.success) {
						this.store.dispatch(MilestoneSettingsPageAction.deleteMilestoneSetting({ id: dialogResult.id }));
					}
				})
			);
		},
		{ dispatch: false }
	);

	deleteMilestoneSettingsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MilestoneSettingsPageAction.deleteMilestoneSetting),
			mergeMap((action) => this.milestoneSettingsService.deleteMilestoneSettings(action.id)),
			map((response) =>
				response.success ? MilestoneSettingsPageAction.getAllMilestoneSettings() : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	saveMilestoneSettingsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MilestoneSettingsPageAction.saveMilestoneSetting),
			mergeMap((action) => this.milestoneSettingsService.saveMilestoneSetting(action.milestoneSetting)),
			map((resp) =>
				resp.success
					? MilestoneSettingsApiAction.saveMilestoneSettingSuccess({ response: resp })
					: ErrorAction.setErrors({ errors: resp.errors })
			)
		);
	});

	saveMilestoneSettingSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MilestoneSettingsApiAction.saveMilestoneSettingSuccess),
			withLatestFrom(this.store.select(SettingsSelect.getLanguage)),
			map(([action, language]) => {
				this.dialogService.close(DialogType.PROCESSING);
				if (language) {
					this.translateService.getTranslation(language);
				}
				return SettingsPageAction.navigate({ url: '/milestone/settings' });
			})
		);
	});

	recalculateStatusesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MilestoneSettingsPageAction.recalculateStatuses),
			mergeMap((action) => this.milestoneSettingsService.recalculateStatuses()),
			map((response) =>
				response.success ? MilestoneSettingsApiAction.recalculateStatusesSuccess() : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	recalculateStatusesSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(MilestoneSettingsApiAction.recalculateStatusesSuccess),
				map(() => {
					this.dialogService.close(DialogType.PROCESSING);
				})
			);
		},
		{ dispatch: false }
	);
}
