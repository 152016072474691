import { createAction, props } from '@ngrx/store';
import { LdResponsibleModel } from '../../model/ld-responsible.model';

export const getLdResponisbles = createAction('[LdResponsible] Get ldResponisbles');
export const openDeleteConfirmationDialog = createAction('[LdResponsible] Open delete confirmation dialog', props<{ id: number }>());
export const getLdResponisble = createAction('[LdResponsible] Get ldResponisble', props<{ id: number }>());

export const initializeUserOptions = createAction('[LdResponsible] Initialize selects');
export const saveLdResponsible = createAction('[LdResponsible] Save LdResponsible', props<{ ldResponsible: LdResponsibleModel }>());
export const deleteLdResponsible = createAction('[LdResponsible] Delete LdResponsible', props<{ id: number }>());
export const clearLdResponsible = createAction('[LdResponsible] Clear LdResponsible');
