import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent, Option } from 'nc-shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnumUtils } from 'nc-utils';
import { SpecialCoverageEnumeration } from '../../model/special-coverage.enumeration';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { KleSelect } from '../../state/action';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ea-special-coverage',
	templateUrl: './special-coverage.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialCoverageComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;

	specialCoverages: Option[];

	specialCoverageGroup = this.formBuilder.group({
		value: ['FAMILY_MEMBER', Validators.required],
	});

	constructor(private store: Store, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.specialCoverages = EnumUtils.toItems(SpecialCoverageEnumeration);
		this.formGroup.addControl(KleFormGroupEnum.SPECIAL_COVERAGE, this.specialCoverageGroup);

		this.store
			.select(KleSelect.getSpecialCoverage)
			.pipe(takeUntil(this.destroy$))
			.subscribe((specialCoverage) => this.specialCoverageGroup.patchValue(specialCoverage));
	}
}
