import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseComponent, TypedOption } from 'nc-shared';
import { EnumUtils } from 'nc-utils';
import { Observable } from 'rxjs';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { OtherInsuranceEnumeration } from '../../model/other-insurance.enumeration';
import { isOtherInsuranceRequired } from './agreement.validator';
import { KleSelect } from '../../state/action';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ea-agreement',
	templateUrl: './agreement.component.html',
	styleUrls: ['./agreement.component.scss'],
})
export class AgreementComponent extends BaseComponent implements OnInit {
	@Input() absenceCode: string;
	@Input() formGroup: FormGroup;
	@Input() formGroupName: string;

	otherInsurancePredefined$: Observable<TypedOption<string>[]>;

	agreementFormGroup = this.formBuilder.group(
		{
			otherInsurance: '',
			otherInsurancePredefined: null as string,
			noOtherInsurance: true,
		},
		{
			validators: isOtherInsuranceRequired(),
		}
	);

	constructor(private store: Store, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.otherInsurancePredefined$ = EnumUtils.toObservableItems(OtherInsuranceEnumeration);
		this.store
			.select(KleSelect.getAgreement)
			.pipe(takeUntil(this.destroy$))
			.subscribe((response) => {
				this.agreementFormGroup.patchValue(response);
			});
		this.formGroup.addControl(KleFormGroupEnum.AGREEMENT, this.agreementFormGroup);
	}

	clearOtherInsurance(): void {
		this.agreementFormGroup.controls.otherInsurance.patchValue(null);
		this.agreementFormGroup.controls.otherInsurancePredefined.patchValue(null);
	}
}
