import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'nc-shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { InteroperabilityResponse } from '../../model/response/interoperability-response';
import { KlePageAction, KleSelect } from '../../state/action';
import { InteroperabilityRequest } from '../../model/request/interoperability-request';

@Component({
	selector: 'ea-interoperability',
	templateUrl: './interoperability.component.html',
	styleUrls: ['interoperability.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteroperabilityComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input() companyId: number;
	formGroup: FormGroup;
	interoperability$: Observable<InteroperabilityResponse>;

	constructor(private store: Store, formBuilder: FormBuilder) {
		super();
		this.formGroup = formBuilder.group({
			secondOperand: [''],
		});
	}

	ngOnInit(): void {
		this.interoperability$ = this.store.select(KleSelect.getInteroperability);
	}

	onCheck = (): void => {
		const data = this.formGroup.getRawValue() as InteroperabilityRequest;
		data.companyId = this.companyId;
		this.store.dispatch(KlePageAction.checkInteroperability({ request: data }));
	};

	ngOnDestroy(): void {
		this.store.dispatch(KlePageAction.clearResponseData());
	}
}
