<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<ng-content></ng-content>
	</nc-main-content>
	<div *ngIf="currentTheme === 'tellco-theme'">
		<ea-tellco-footer [languageCode]="languageCode" [isMobile]="isMobile"></ea-tellco-footer>
	</div>
	<div *ngIf="currentTheme === 'ecare-theme'">
		<ea-ecare-footer [languageCode]="languageCode" [isMobile]="isMobile"></ea-ecare-footer>
	</div>
</form>
