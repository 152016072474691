import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IntegrationEffect } from './state/integration.effect';
import { INTEGRATION_FEATURE_STATE_NAME } from './state/action/integration.select';
import { integrationReducer } from './state/integration.reducer';
import { CompanyComponent } from './component/company/company.component';
import { CompanyFormComponent } from './component/company-form/company-form.component';
import { CompanyFooterComponent } from './component/company-footer/company-footer.component';
import { IntegrationResponseDialogComponent } from './component/integration-response-dialog/integration-response-dialog.component';
import { EmployeeDetailsComponent } from './component/employee-details/employee-details.component';
import { CompanyDetailsComponent } from './component/company-details/company-details.component';
import { CompanyDetailsFooterComponent } from './component/company-datails-footer/company-details-footer.component';
import { IntegrationResponseDialogTabComponent } from './component/integration-response-dialog/integration-response-dialog-tab/integration-response-dialog-tab.component';
import { MedicalInstitutionsComponent } from './component/medical-institutions/medical-institutions.component';
import { MedicalInstitutionsFooterComponent } from './component/medical-institutions-footer/medical-institutions-footer.component';
import { CompanyGroupsComponent } from './component/company-groups/company-groups.component';
import { CompanyGroupDetailsComponent } from './component/company-group-details/company-group-details.component';
import { NcLinkedTablesModule } from 'nc-linked-tables';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([IntegrationEffect]),
		StoreModule.forFeature(INTEGRATION_FEATURE_STATE_NAME, integrationReducer),
		NcLinkedTablesModule,
	],
	declarations: [
		CompanyComponent,
		CompanyFormComponent,
		CompanyFooterComponent,
		IntegrationResponseDialogComponent,
		EmployeeDetailsComponent,
		CompanyDetailsComponent,
		CompanyDetailsFooterComponent,
		IntegrationResponseDialogTabComponent,
		MedicalInstitutionsComponent,
		MedicalInstitutionsFooterComponent,
		CompanyGroupsComponent,
		CompanyGroupDetailsComponent,
	],
	exports: [CompanyComponent, CompanyFormComponent, IntegrationResponseDialogComponent],
})
export class IntegrationModule {}
