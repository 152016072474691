import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AbsencePageAction, AbsenceSelect } from '../../../state/action';
import { ActivatedRoute } from '@angular/router';
import { ColumnBuilder, DatatableColumn } from 'nc-datatable';
import { filter, takeUntil } from 'rxjs/operators';
import { AbsenceContextModel } from '../../../../shared/model/absence-context.model';
import { AbsenceAnnex } from '../../../model/absence-annex';
import { parseDate } from 'nc-utils';
import { NcDocumentsService } from 'nc-documents';
import { UserSelect } from '../../../../user/state/action';

@Component({
	templateUrl: './existing-absence-annex.component.html',
	styleUrls: ['existing-absence-annex.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExistingAbsenceAnnexComponent extends BaseComponent implements OnDestroy {
	formGroup: FormGroup;
	data$: Observable<AbsenceAnnex>;
	currentAbsence$: Observable<AbsenceContextModel>;
	isShareInformation = true;
	code = '';
	hasSavedConfirmationEmail: boolean;
	additionalColumns: DatatableColumn[];
	documents: FormArray;
	isHrUser: boolean;

	constructor(private store: Store, private formBuilder: FormBuilder, route: ActivatedRoute, private documentService: NcDocumentsService) {
		super();
		this.formGroup = this.formBuilder.group({
			createdOn: ['', null],
			createdBy: ['', null],
			validFrom: ['', null],
			validTo: ['', null],
			incapacityToWork: ['', null],
			comment: ['', null],
			documents: this.formBuilder.array([]),
		});

		route.url.subscribe((url) => {
			if (!url[0].path.includes('share-information')) {
				this.isShareInformation = false;
			}
		});
		this.store.select(UserSelect.getPermissions).subscribe((permissions) => (this.isHrUser = permissions.includes('HR')));

		route.params.subscribe((params) => {
			if (params.identifier) {
				this.store.dispatch(
					AbsencePageAction.getAbsenceAnnex({ identifier: params.identifier, isShareInformation: this.isShareInformation })
				);
			}
		});

		this.currentAbsence$ = this.store.select(AbsenceSelect.getAbsenceContextModel);
		this.data$ = this.store.select(AbsenceSelect.getAbsenceAnnex);

		this.data$
			.pipe(
				filter((data) => data !== null && data.id !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((data) => {
				this.hasSavedConfirmationEmail = data.hasSavedConfirmationEmail;
				this.code = data.code;
				this.displayData(data);
			});
		this.documents = this.formGroup.get('documents') as FormArray;
		this.additionalColumns = [
			ColumnBuilder.create('uploadedBy', 'uploadedBy'),
			ColumnBuilder.create('comment', 'comment'),
			ColumnBuilder.createDate('uploadedOn', 'uploadedOn'),
		];
	}

	resendConfirmationEmail = (): void => {
		this.store.dispatch(AbsencePageAction.resendAnnexConfirmationEmail({ code: this.code }));
	};

	ngOnDestroy(): void {}

	displayData = (data: AbsenceAnnex) => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			createdOn: parseDate(data.createdOn),
			createdBy: data.createdBy,
			validFrom: parseDate(data.validFrom),
			validTo: parseDate(data.validTo),
			incapacityToWork: data.percentage,
			comment: data.comment,
		});
		const documents = this.formGroup.get('documents') as FormArray;
		this.documentService.populateData(documents, data.documents);
	};
}
