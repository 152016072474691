<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div
		class="footer-navigation"
		fxLayout="row wrap"
		fxLayout.lt-md="column"
		fxLayoutGap="0px"
		fxLayoutGap.lt-md="5px"
		fxLayoutAlign="space-between"
	>
		<div fxLayout="row wrap" fxLayoutGap="5px">
			<nc-button id="back" [onClick]="goBack" [isRaised]="false" label="back"> </nc-button>
			<nc-button id="task" [onClick]="tasks" [isRaised]="false" label="tasks"> </nc-button>
		</div>
	</div>
</div>
