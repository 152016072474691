import { ErrorDetails } from 'nc-utils';

/**
 * Global state for errors
 */
export interface ErrorState {
	/**
	 * Map with errors where key is field name and value ErrorDetails
	 */
	errors: { [key: string]: ErrorDetails };

	message: string;
}

export const ERROR_INITIAL_STATE = {
	errors: null,
	message: '',
};
