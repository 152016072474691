import { BaseComponent } from 'nc-shared';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SyncResponse } from '../../../shared/model/sync-response';
import { TableData } from 'nc-datatable';
import { Observable, of } from 'rxjs';
import { SyncRecord } from '../../../shared/model/sync-record';

@Component({
	templateUrl: './integration-response-dialog.component.html',
	styleUrls: ['./integration-response-dialog.component.scss'],
})
export class IntegrationResponseDialogComponent extends BaseComponent implements OnDestroy {
	response: SyncResponse;
	neoData: SyncRecord;
	easyTempData: SyncRecord;
	neoTableData$: Observable<TableData>;
	easyTempTableData$: Observable<TableData>;

	constructor(@Inject(MAT_DIALOG_DATA) data) {
		super();

		this.response = data?.response;
		this.easyTempTableData$ = of({ records: this.response.results[0].report });
		this.easyTempData = this.response.results[0];
		if (this.response.results.length > 1) {
			this.neoTableData$ = of({ records: this.response.results[1].report });
			this.neoData = this.response.results[1];
		}
	}

	ngOnDestroy(): void {
		location.reload();
	}
}
