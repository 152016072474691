import { createAction, props } from '@ngrx/store';
import { LoginResult } from '../../model/login-result';
import { ForgotPasswordResult } from '../../model/forgot-password-result';
import { UserDetails } from '../../model/user-details';
import { RefreshTokenResponse } from '../../model/refresh-token-response';

export const verifyLoginSubmitSuccess = createAction('[Login API] Verify code success');
export const resetPasswordSubmitSuccess = createAction('[Reset password API] Reset password success');
export const verifyForgotPasswordSubmitSuccess = createAction('[Forgot password API] Verify code success');
export const loginSubmitFailed = createAction('[Login API] Submit access credentials failed', props<{ error: string }>());
export const loginSubmitSuccess = createAction('[Login API] Submit access credentials success', props<{ result: LoginResult }>());
export const forgotPasswordSubmitSuccess = createAction('[Forgot password API] Submit username success', props<{ result: ForgotPasswordResult }>());

export const getPersonalData = createAction('[Personal data] Open personal data page');
export const personalDataSubmitSuccess = createAction('[Personal data API] Personal data submit success');
export const changePasswordSubmitSuccess = createAction('[Change password API] Change password submit success');
export const getPersonalDataSuccess = createAction('[Personal data API] Get personal details success', props<{ personalData: UserDetails }>());

export const refreshTokenSuccess = createAction('[Refresh token] Refresh token success', props<{ refreshTokenResponse: RefreshTokenResponse }>());
