import { TypedAction } from '@ngrx/store/src/models';
import * as _ from 'lodash';

export class MenuItem {
	url: string;
	icon: string;
	label: string;
	hasInfo: boolean;
	infoTooltip: string;
	children: MenuItem[] = [];
	permissions: string[] = [];
	notVisibleTo: string[] = [];
	hasAllPermissions: boolean;
	action: TypedAction<any> = null;
	hasChildren: boolean;

	constructor(
		icon: string,
		label: string,
		url: string,
		permissions?: string[],
		children?: MenuItem[],
		hasAllPermissions?: boolean,
		notVisibleTo?: string[],
		action?: TypedAction<any>,
		infoTooltip?: string
	) {
		this.url = url;
		this.icon = icon;
		this.label = label;
		this.action = action;
		this.infoTooltip = infoTooltip;
		this.children = children ?? [];
		this.permissions = permissions ?? [];
		this.hasAllPermissions = hasAllPermissions;
		this.notVisibleTo = notVisibleTo;
		this.hasChildren = this.children.length > 0;
	}

	isVisible(permissions): boolean {
		let isVisible = true;
		for (const permission of this.permissions) {
			isVisible = permissions.includes(permission);
			if ((!this.hasAllPermissions && isVisible) || (this.hasAllPermissions && !isVisible)) {
				break;
			}
		}

		if (!_.isNil(this.notVisibleTo)) {
			for (const permission of this.notVisibleTo) {
				isVisible = !permissions.includes(permission);
				if (!isVisible) {
					break;
				}
			}
		}

		return isVisible;
	}
}
