<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="milestone-settings-container">
				<nc-datatable
					[id]="'milestone-settings'"
					[rowClick]="onRowClick"
					[columns]="milestoneSettingsColumns"
					[data$]="milestoneSettings$"
					[filterConfig]="filterConfig"
				>
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-milestone-settings-footer></ea-milestone-settings-footer>
</div>
