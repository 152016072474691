<form [formGroup]="absenceFormGroup" fxFill fxLayout="column">
	<ea-container cssClass="margin-top-15">
		<div fxLayout="column" fxLayoutGap="10px">
			<ng-template #tableHeader>
				<div class="button-add-container" fxLayout="row wrap">
					<nc-button id="add" icon="add" [disabled]="isBranchHr" (click)="openDialog()"></nc-button>
				</div>
			</ng-template>
			<nc-datatable
				id="absences"
				[columns]="columns"
				[data$]="absences$"
				[rowClick]="edit"
				[filterConfig]="filterConfig"
				[tableHeader]="tableHeader"
			></nc-datatable>
		</div>
	</ea-container>
</form>
