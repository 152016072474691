import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'nc-shared';
import { dateToString, parseDate } from 'nc-utils';
import { Store } from '@ngrx/store';
import { isAddressFormFilled } from './person-mutated.validators';
import { Moment } from 'moment';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { KlePageAction, KleSelect } from '../../state/action';

@Component({
	selector: 'ea-person-mutated',
	templateUrl: './person-mutated.component.html',
	styleUrls: ['./person-mutated.component.scss'],
})
export class PersonMutatedComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;

	isReadOnly = true;

	addressFormGroup = this.formBuilder.group(
		{
			complementaryLine: null as string,
			street: null as string,
			postbox: null as string,
			locality: null as string,
			zipCode: null as string,
			city: null as string,
			country: null as string,
		},
		{
			validators: [isAddressFormFilled()],
		}
	);

	personMutatedGroup = this.formBuilder.group({
		validAsOf: [parseDate(''), Validators.required],
		firstName: ['', Validators.required],
		lastName: ['', Validators.required],
		withdrawalDate: [null],
		address: this.addressFormGroup,
	});

	constructor(private formBuilder: FormBuilder, private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.formGroup.addControl(KleFormGroupEnum.PERSON_MUTATED, this.personMutatedGroup);
	}

	getDate(date: Moment | string): string {
		return date ? this.parseDateIfNotString(date) : '';
	}

	parseDateIfNotString(date: Moment | string): string {
		return typeof date === 'string' ? date : dateToString(date);
	}

	getPersonalData(): void {
		this.store.dispatch(KlePageAction.getPersonMutated({ absenceCode: this.absenceCode }));
		this.store.select(KleSelect.getPersonMutated).subscribe((value) => (value ? this.personMutatedGroup.patchValue(value) : ''));
	}
}
