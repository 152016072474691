import { EnumItem } from 'nc-utils';

export enum FlagWithUnknownEnumeration {
	YES = 'yes',
	NO = 'no',
	UNKNOWN = 'unknown',
}

export const FlagWithUnknownEnumerationConst = {
	YES: new EnumItem('yes', 'yes'),
	NO: new EnumItem('no', 'no'),
	UNKNOWN: new EnumItem('unknown', 'unknown'),
};
