import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KleEffect } from './state/kle.effect';
import { kleReducer } from './state/kle.reducer';
import { KLE_FEATURE_STATE_NAME } from './state/action/kle.select';
import { KleComponent } from './component/kle/kle.component';
import { InteroperabilityComponent } from './component/interoperability/interoperability.component';
import { DeclareIncidentComponent } from './component/declare-incident/declare-incident.component';
import { KleDetailsComponent } from './component/kle-details/kle-details.component';
import { TreatmentsFormComponent } from './component/treatments/treatments-form/treatments-form';
import { TreatmentDialogComponent } from './component/treatments/treatment-dialog/treatment-dialog.component';
import { KleIncapacityToWorkComponent } from './component/incapacity-to-work/kle-incapacity-to-work.component';
import { PaymentsComponent } from './component/payments/payments.component';
import { AbsenceModule } from '../absence/absence.module';
import { AdditionsPersonComponent } from './component/additions-person/additions-person.component';
import { AnnualSalaryComponent } from './component/annual-salary/annual-salary.component';
import { InjuryDialogComponent } from './component/accident-description/injury-dialog/injury-dialog.component';
import { InjuryFormComponent } from './component/accident-description/injury-form/injury-form.component';
import { TakeWorkUpFullyComponent } from './component/take-work-up-fully/take-work-up-fully.component';
import { ProcessControlComponent } from './component/process-control/process-control.component';
import { DialogMessageComponent } from './component/dialog-message/dialog-message.component';
import { TableDialogMessageComponent } from './component/dialog-message/tabled-dialog-message/table-dialog-message.component';
import { DeadlineMessageFormComponent } from './component/dialog-message/deadline-message-form/deadline-message-form.component';
import { ConfirmationMessageFormComponent } from './component/dialog-message/confirmation-message-form/confirmation-message-form.component';
import { SimpleMessageFormComponent } from './component/dialog-message/simple-message-form/simple-message-form.component';
import { ProcessControlResponseComponent } from './component/process-control-response/process-control-response.component';
import { ContactPersonMessageFormComponent } from './component/dialog-message/contact-person-message-form/contact-person-message-form.component';
import { WorkplaceMessageFormComponent } from './component/dialog-message/workplace-message-form/workplace-message-form.component';
import { ReasonForAbsenceFormComponent } from './component/dialog-message/reason-for-absence-message-form/reason-for-absence-form.component';
import { SettlementComponent } from './component/settlement/settlement.component';
import { CoveredDailyAllowanceComponent } from './component/covered-daily-allowance/covered-daily-allowance.component';
import { CrossChannelLinkDialogComponent } from './component/cross-channel-link/cross-channel-link-dialog/cross-channel-link-dialog.component';
import { CrossChannelLinkComponent } from './component/cross-channel-link/cross-channel-link.component';
import { OtherEmployersComponent } from './component/other-employers/other-employers.component';
import { FreeFormDialogComponent } from './component/dialog-message/free-form-dialog/free-form-dialog.component';
import { RepaymentComponent } from './component/repayment/repayment.component';
import { RepaymentDialogComponent } from './component/repayment/repayment-dialog/repayment-dialog.component';
import { AbsencesComponent } from './component/absences/absences.component';
import { AbsenceFormDialogComponent } from './component/absences/absence-form-dialog/absence-form-dialog.component';
import { SpecialCoverageComponent } from './component/special-coverage/special-coverage.component';
import { AgreementComponent } from './component/agreement/agreement.component';
import { ShortTimeWorkComponent } from './component/short-time-work/short-time-work.component';
import { SpecialCodeComponent } from './component/special-code/special-code.component';
import { RegisterOrganizationComponent } from './component/register-organization/register-organization.component';
import { RegisterOrganizationFormComponent } from './component/register-organization/form/register-organization-form.component';
import { PersonMutatedComponent } from './component/person-mutated/person-mutated.component';
import { KleDetailFooterComponent } from './component/kle-details-footer/kle-details-footer.component';
import { NcDateTimePickerModule } from 'nc-date-time-picker';
import { ReceivedSentStoriesComponent } from './component/recived-sent-stories/received-sent-stories.component';
import { AttachmentsComponent } from './component/attachments/attachments.component';
import { AttachmentsFormDialogComponent } from './component/attachments/attachments-form-dialog/attachments-form-dialog.component';
import { SignCertificateComponent } from './component/register-organization/sign-certificate/sign-certificate.component';
import { PingResultDialogComponent } from './component/kle/ping-result-dialog/ping-result-dialog.component';
import { KleResultDialogComponent } from './component/kle-result-dialog/kle-result-dialog.component';
import { ManualAdditionsTableComponent } from './component/annual-salary/components/manual-addition/manual-additions-table/manual-additions-table.component';
import { ManualAdditionDialogComponent } from './component/annual-salary/components/manual-addition/manual-addition-dialog/manual-addition-dialog.component';
import { ManualAdditionFormComponent } from './component/annual-salary/components/manual-addition/manual-addition-form/manual-addition-form.component';
import { SalaryBasesComponent } from './component/annual-salary/components/salary-bases/salary-bases.component';
import { PresentSalaryComponent } from './component/annual-salary/components/present-salary/present-salary.component';
import { PastSalaryComponent } from './component/annual-salary/components/past-salary/past-salary.component';
import { AccidentDescriptionComponent } from './component/accident-description/accident-description.component';
import { TreatmentsComponent } from './component/treatments/treatments.component';
import { UserAdministrationModule } from '../user-administration/user-administration.module';
import { FetchDocumentMessageFormComponent } from './component/dialog-message/fetch-document-message-form/fetch-document-message-form.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([KleEffect]),
		StoreModule.forFeature(KLE_FEATURE_STATE_NAME, kleReducer),
		AbsenceModule,
		NcDateTimePickerModule,
		UserAdministrationModule,
	],
	declarations: [
		KleComponent,
		InteroperabilityComponent,
		DeclareIncidentComponent,
		KleDetailsComponent,
		KleIncapacityToWorkComponent,
		TreatmentsFormComponent,
		TreatmentDialogComponent,
		TreatmentsComponent,
		PaymentsComponent,
		AdditionsPersonComponent,
		PaymentsComponent,
		AnnualSalaryComponent,
		InjuryDialogComponent,
		InjuryFormComponent,
		AccidentDescriptionComponent,
		TakeWorkUpFullyComponent,
		ProcessControlComponent,
		AgreementComponent,
		ReceivedSentStoriesComponent,
		DialogMessageComponent,
		TableDialogMessageComponent,
		DeadlineMessageFormComponent,
		ConfirmationMessageFormComponent,
		SimpleMessageFormComponent,
		ContactPersonMessageFormComponent,
		WorkplaceMessageFormComponent,
		SimpleMessageFormComponent,
		ProcessControlResponseComponent,
		ReasonForAbsenceFormComponent,
		SettlementComponent,
		CoveredDailyAllowanceComponent,
		SettlementComponent,
		CrossChannelLinkComponent,
		CrossChannelLinkDialogComponent,
		RepaymentComponent,
		RepaymentDialogComponent,
		FreeFormDialogComponent,
		AbsencesComponent,
		AbsenceFormDialogComponent,
		KleComponent,
		DeclareIncidentComponent,
		OtherEmployersComponent,
		SpecialCoverageComponent,
		ShortTimeWorkComponent,
		SpecialCodeComponent,
		RegisterOrganizationComponent,
		RegisterOrganizationFormComponent,
		PersonMutatedComponent,
		KleDetailFooterComponent,
		AttachmentsComponent,
		AttachmentsFormDialogComponent,
		SignCertificateComponent,
		PingResultDialogComponent,
		KleResultDialogComponent,
		ManualAdditionsTableComponent,
		ManualAdditionFormComponent,
		ManualAdditionDialogComponent,
		SalaryBasesComponent,
		PresentSalaryComponent,
		PastSalaryComponent,
		FetchDocumentMessageFormComponent,
	],
	exports: [KleComponent, InteroperabilityComponent, DeclareIncidentComponent, KleResultDialogComponent],
})
export class KleModule {}
