export enum NoteTypeEnumeration {
	INCOMING_PHONE = 'incomingPhone',
	OUTGOING_PHONE = 'outgoingPhone',
	INCOMING_EMAIL = 'incomingEmail',
	OUTGOING_EMAIL = 'outgoingEmail',
	VISIT = 'visit',
	RECEIPT_OF_LETTER = 'receiptOfLetter',
	OUTGOING_LETTER = 'outgoingLetter',
	OTHER = 'other',
}
