<div [formGroup]="formGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<nc-input id="amount" type="number" [formGroup]="formGroup" formControlName="amount" label="amount"></nc-input>
	<nc-input id="kind-of-salary" [formGroup]="formGroup" formControlName="kindOfSalary" label="kindOfSalary"></nc-input>
	<nc-select
		id="statistic-code"
		clearIconId="position-type-clear"
		[formGroup]="formGroup"
		[data$]="statisticCodes$"
		[translate]="true"
		placeholder="statisticCode"
		label="statisticCode"
		formControlName="statisticCode"
	>
	</nc-select>
</div>
