import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TASK_FEATURE_STATE_NAME } from './state/action/task.select';
import { taskReducer } from './state/task.reducer';
import { TaskEffect } from './state/task.effect';
import { TaskComponent } from './component/tasks/task.component';
import { TaskFooterComponent } from './component/task-footer/task-footer.component';
import { NoteFormComponent } from './component/note-form/note-form.component';
import { TaskFormComponent } from './component/task-form/task-form.component';
import { MilestoneFormComponent } from './component/milestone-form/milestone-form.component';
import { MilestoneFormGuardDialogComponent } from './component/milestone-form-guard-dialog/milestone-form-guard-dialog.component';

@NgModule({
	imports: [SharedModule, EffectsModule.forFeature([TaskEffect]), StoreModule.forFeature(TASK_FEATURE_STATE_NAME, taskReducer)],
	declarations: [
		TaskComponent,
		TaskFooterComponent,
		NoteFormComponent,
		TaskFormComponent,
		MilestoneFormComponent,
		MilestoneFormGuardDialogComponent,
	],
	exports: [TaskComponent, NoteFormComponent, TaskFormComponent, MilestoneFormComponent, MilestoneFormGuardDialogComponent],
})
export class TaskModule {}
