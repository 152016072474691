import * as ApplicationState from '../../state/app.state';
import { TableData } from 'nc-datatable';
import { Option } from 'nc-shared';
import { UserNotificationOption } from '../model/user-notification-option';

export interface NotificationsState {
	users: Option[];
	notifications: TableData;
	selectedUser: UserNotificationOption;
}

export interface State extends ApplicationState.State {
	notification: NotificationsState;
}

export const NOTIFICATIONS_INITIAL_STATE: NotificationsState = {
	users: [],
	selectedUser: null,
	notifications: { records: [] },
};
