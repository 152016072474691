import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { ColumnBuilder, DatatableColumn, SelectConfig, TableData } from 'nc-datatable';
import { KleService } from '../../service/kle.service';
import { KleAbsencesService } from '../../service/absences.service';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { AbsenceSelect } from '../../../absence/state/action';
import { Absence } from '../../../absence/model/absence';
import { filter, map } from 'rxjs/operators';
import { DocumentModel } from 'nc-documents';
import { DocumentAttachment } from '../../model/document-attachment';
import { KleSelect } from '../../state/action';
import { FetchDocumentMessageFormComponent } from '../dialog-message/fetch-document-message-form/fetch-document-message-form.component';
import { DialogConfigurationUtils, DialogService } from 'nc-utils';
import { WebSocketService } from '../../../shared/service/websocket.service';
import { NcInformationDialogComponent } from 'nc-information-dialog';

@Component({
	selector: 'ea-attachments',
	templateUrl: './attachments.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsComponent extends BaseComponent implements OnInit {
	@Input() absenceCode: string;
	@Input() formGroup: FormGroup;

	attachment$: Observable<TableData>;
	receivedAttachment$: Observable<TableData>;
	attachments: FormArray;
	absenceId: number;
	filterConfig: FilterConfig;
	filterConfigReceived: FilterConfig;
	columns: DatatableColumn[];
	sentColumns: DatatableColumn[];
	selectConfig: SelectConfig;
	fetchTimeTableDisabled = false;
	fetchEmploymentContractDisabled = false;

	generateDocumentSubscription: Subscription;

	absence$: Observable<Absence>;

	attachmentsFormGroup = this.formBuilder.group({
		selectedIds: this.formBuilder.array([]),
		attachments: null,
	});

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private kleService: KleService,
		private kleAbsenceService: KleAbsencesService,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogService: DialogService,
		private webSocketService: WebSocketService
	) {
		super();
		this.absence$ = this.store.select(AbsenceSelect.getAbsence);
	}

	ngOnInit(): void {
		this.columns = this.getColumns();
		this.sentColumns = this.getSentColumns();
		this.filterConfig = this.getFilterConfig();
		this.filterConfigReceived = this.getFilterConfig();
		this.selectConfig = this.setSelectConfig();

		this.attachment$ = this.absence$
			.pipe(filter((absence) => absence.id > 0))
			.pipe(map((a) => ({ records: this.mapDocumentsToAttachment(a.documents) } as unknown as TableData)));

		this.receivedAttachment$ = this.store.select(KleSelect.getAttachments);

		this.formGroup.addControl(KleFormGroupEnum.ATTACHMENT_STORY, this.attachmentsFormGroup);

		this.isFetchingETDocumentsButtonsVisible();
		this.connectWebSocket();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.generateDocumentSubscription.unsubscribe();
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('contentType', 'documentType'),
			ColumnBuilder.create('nameWithExtension', 'name'),
			ColumnBuilder.create('uploadedBy', 'uploadedBy'),
			ColumnBuilder.create('comment', 'comment'),
			ColumnBuilder.createDate('uploadedOn', 'uploadedOn'),
		];
	}

	getSentColumns(): Array<DatatableColumn> {
		return [ColumnBuilder.create('contentType', 'documentType'), ColumnBuilder.create('fileName', 'name')];
	}

	setSelectConfig(): SelectConfig {
		return {
			identifierColumn: 'id',
			formArray: this.attachmentsFormGroup.controls.selectedIds,
			isFirstColumn: true,
		};
	}

	getFilterConfig(): FilterConfig {
		return {
			showGlobalFilter: true,
		};
	}

	edit = (rowData): void => {
		this.kleAbsenceService.openDocumentDialog(rowData);
		this.changeDetectorRef.detectChanges();
		this.attachmentsFormGroup.markAsDirty();
	};

	mapDocumentsToAttachment(documents: DocumentModel[]): DocumentAttachment[] {
		let documentAttachments = [];
		documents.forEach((document) => {
			let doc = new DocumentAttachment();
			doc.id = document.docmanId;
			doc.comment = document.comment;
			doc.nameWithExtension = document.name;
			doc.uploadedBy = document.uploadedBy;
			doc.uploadedOn = document.uploadedOn;
			documentAttachments.push(doc);
		});
		this.attachmentsFormGroup.controls.attachments.patchValue(documentAttachments);
		return documentAttachments;
	}

	connectWebSocket() {
		const stompClient = this.webSocketService.connect();
		stompClient.connect({}, () => {
			this.generateDocumentSubscription = stompClient.subscribe('/topic/generate-documents/' + this.absenceCode, (message) => {
				const messageResponse = JSON.parse(message.body);
				const config = DialogConfigurationUtils.info(messageResponse.message, 'info');
				this.dialogService
					.open(NcInformationDialogComponent, config)
					.afterClosed()
					.subscribe(() => {
						window.location.reload();
					});
			});
		});
	}

	isFetchingETDocumentsButtonsVisible() {
		this.kleService.checkActionHistory(this.absenceCode).subscribe((data) => {
			this.fetchTimeTableDisabled = data.timetable;
			this.fetchEmploymentContractDisabled = data.employmentContract;
		});
	}

	isBannerIsVisible(): boolean {
		return this.fetchTimeTableDisabled || this.fetchEmploymentContractDisabled;
	}

	fetchTimeTable() {
		if (!this.fetchTimeTableDisabled) {
			const dialogRef = this.dialogService.open(FetchDocumentMessageFormComponent);
			return dialogRef.afterClosed().subscribe((clickedYes) => {
				if (clickedYes) {
					this.fetchTimeTableDisabled = true;
					this.changeDetectorRef.detectChanges();
					this.kleService.getEmployeeTimetableDocument(this.absenceCode).subscribe();
				} else {
					return EMPTY;
				}
			});
		}
	}

	fetchEmploymentContract() {
		if (!this.fetchEmploymentContractDisabled) {
			const dialogRef = this.dialogService.open(FetchDocumentMessageFormComponent);
			return dialogRef.afterClosed().subscribe((clickedYes) => {
				if (clickedYes) {
					this.fetchEmploymentContractDisabled = true;
					this.changeDetectorRef.detectChanges();
					this.kleService.getEmployeeContractDocument(this.absenceCode).subscribe();
				} else {
					return EMPTY;
				}
			});
		}
	}
}
