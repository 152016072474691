import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { StatisticFilter } from '../model/statistic-filter';
import { StatisticResult } from '../model/statistic-result';
import { dateToString } from 'nc-utils';
import { ExportExtensionEnumeration } from '../../shared/enumeration/export-extension.enumeration';
import { ExportRequest } from '../../shared/model/export.request';
import { ExportEnumeration } from '../../shared/enumeration/export.enumeration';
import { FlagEnumeration } from '../../shared/enumeration/flag.enumeration';
import { StatisticRequest } from '../model/statistic-request';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class StatisticService {
	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly SEARCH_URL = `${this.API_URL}/absences/case-management/statistic`;
	private readonly EXPORT_URL = `${this.API_URL}/export/statistics`;

	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Exports statistic to word or excel
	 * @param statisticFilter data used to filter statistic
	 * @param type type of export
	 */
	exportToFile(statisticFilter: StatisticFilter, type: ExportExtensionEnumeration): Observable<HttpResponse<Blob>> {
		const data = this.createStatisticRequest(statisticFilter);
		const request = new ExportRequest(ExportEnumeration.STATISTICS_REPORT, type, data);
		return this.httpClient.post(this.EXPORT_URL, request, { responseType: 'blob', observe: 'response' });
	}

	/**
	 * Performs statistic search
	 * @param statisticFilter data used to filter search result
	 */
	search(statisticFilter: StatisticFilter): Observable<StatisticResult> {
		const data = this.createStatisticRequest(statisticFilter);
		return this.httpClient.post<StatisticResult>(this.SEARCH_URL, data);
	}

	/**
	 * Creates statistic request from filter
	 * @param statisticFilter data that is used to filter statistic
	 */
	private createStatisticRequest(statisticFilter: StatisticFilter): StatisticRequest {
		const typeFilter = this.getTypeFilter(statisticFilter);
		const isShowCmData = statisticFilter.isShowCmData === FlagEnumeration.yes;
		const isCaseManagementSuccessful = statisticFilter.isCaseManagementSuccessful === FlagEnumeration.yes;

		return {
			typeFilter,
			isShowCmData,
			isCaseManagementSuccessful,
			type: statisticFilter.type,
			selectedTypes: statisticFilter.selectedTypes,
			absenceActivity: statisticFilter.absenceActivity,
			dateTo: statisticFilter.dateTo ? dateToString(statisticFilter.dateTo) : null,
			dateFrom: statisticFilter.dateFrom ? dateToString(statisticFilter.dateFrom) : null,
		};
	}

	/**
	 * Gets type filter
	 * @param statisticFilter data that is used to filter statistic
	 */
	private getTypeFilter(statisticFilter: StatisticFilter): string {
		let typeFilter: string;
		const type = statisticFilter.type;

		if (type === 'OVERALL') {
			typeFilter = statisticFilter.insuranceType;
		} else if (type === 'COMPANY') {
			typeFilter = statisticFilter.company;
		} else {
			typeFilter = statisticFilter.person;
		}

		return typeFilter;
	}
}
