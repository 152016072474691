<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="administration-container">
				<nc-datatable
					[rowClick]="onRowClick"
					[id]="'administration'"
					[columns]="administrationColumns"
					[data$]="companies$"
					[filterConfig]="filterConfig"
					[serverSideFilter]="filter"
				>
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
</div>
