import { createAction, props } from '@ngrx/store';
import { DatatableColumn } from 'nc-datatable';
import { ChartData } from '../../../shared/model/chart/chart-data';

export const searchSuccess = createAction(
	'[Statistic API] Search success',
	props<{
		records: { [key: string]: string }[];
		totalRow: { [key: string]: string };
		columns: DatatableColumn[];
		charts: {
			overallChartData: Array<ChartData>;
			totalCmChartData: Array<ChartData>;
			reasonsForSuccessfulCmChartData: Array<ChartData>;
			reasonsForNotSuccessfulCmChartData: Array<ChartData>;
			averageAbsenceDurationPerIllnessChartData: Array<ChartData>;
			averageAbsenceDurationPerAccidentChartData: Array<ChartData>;
		};
	}>()
);
