import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dateToString, DialogService, DialogType } from 'nc-utils';
import { AbsenceFormDialogComponent } from '../component/absences/absence-form-dialog/absence-form-dialog.component';
import { KleAbsences } from '../model/kle-absences';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { TableData } from 'nc-datatable';
import { AttachmentsFormDialogComponent } from '../component/attachments/attachments-form-dialog/attachments-form-dialog.component';
import { Moment } from 'moment';

@Injectable({
	providedIn: 'root',
})
export class KleAbsencesService {
	constructor(private formBuilder: FormBuilder, private dialogService: DialogService) {}

	/**
	 * Subscribes update table on form array value change
	 * @param formArray kle absences form array
	 * @param data$ kle absences behaviour subject that is linked to grid
	 */
	initialize(formArray: FormArray, data$: BehaviorSubject<TableData>): void {
		formArray.valueChanges.subscribe(() => {
			this.updateTable(data$, formArray);
		});
	}

	/**
	 * Updates table based on data from form array
	 * @param data$ kle absences behaviour subject that is linked to grid
	 * @param formArray kle absences form array
	 */
	private updateTable = (data$: BehaviorSubject<TableData>, formArray: FormArray) => {
		const tableData = { records: [] };
		for (let i = 0; i < formArray.length; i++) {
			const formGroup = formArray.at(i) as FormGroup;
			const rawValue = formGroup.getRawValue() as KleAbsences;

			tableData.records.push(this.formatDataForTable(rawValue));
		}

		data$.next(tableData);
	};

	/**
	 * Formats data for table
	 * @param model kle absences model
	 */
	formatDataForTable(model: KleAbsences) {
		return {
			...model,
			id: model.id ? model.id : '',
			from: typeof model.from === 'string' ? model.from : dateToString(model.from),
			hours: model.hours,
		};
	}

	openDialog(model: KleAbsences, formArray: FormArray, formGroupParent: FormGroup): void {
		const formGroup = this.getFormGroup(formArray, model);
		const dialogRef = this.dialogService.open(AbsenceFormDialogComponent, {
			data: {
				formGroup: formGroup,
			},
		});

		dialogRef
			.afterClosed()
			.pipe(filter((result) => result?.success))
			.subscribe(() => {
				formGroupParent.markAsDirty();
				if (!model) {
					formArray.push(formGroup);
					this.dialogService.close(DialogType.PROCESSING);
				} else {
					const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
					formArray.removeAt(index);
					formArray.insert(index, formGroup);
					formGroup.markAsDirty();
					this.dialogService.close(DialogType.PROCESSING);
				}
			});
	}

	/**
	 * Initializes form array with existing absences
	 * @param formArray absences form array
	 * @param absences existing absences
	 */
	populateData(formArray: FormArray, absences: any): void {
		formArray.clear();
		for (let i = 0; i < absences?.length; i++) {
			const absence = absences[i];
			formArray.push(this.createExistingFormGroup(absence));
		}
	}

	/**
	 * Gets form group for formArray
	 */
	private getFormGroup = (formArray: FormArray, model: KleAbsences): FormGroup => {
		let formGroup: FormGroup;

		if (model) {
			const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
			const existing = formArray.at(index) as FormGroup;
			formGroup = this.createExistingFormGroup(existing.getRawValue());
		} else {
			const tableId = formArray.length === 0 ? 0 : formArray.at(formArray.length - 1).get('tableId').value + 1;
			formGroup = this.createNewFormGroup(tableId);
		}

		return formGroup;
	};

	public createExistingFormGroup = (absence: KleAbsences) => {
		return this.formBuilder.group({
			id: absence.id,
			from: absence.from,
			hours: [absence.hours, [Validators.min(1), Validators.max(10)]],
			tableId: absence.tableId,
		});
	};

	private createNewFormGroup = (tableId: number) => {
		return this.formBuilder.group({
			id: null as number,
			from: [null as Moment, [Validators.required]],
			hours: ['' as string, [Validators.min(1), Validators.max(10), Validators.required]],
			tableId: tableId,
		});
	};

	openDocumentDialog(model: any): void {
		const formGroup = this.createDocumentFormGroup(model);
		const dialogRef = this.dialogService.open(AttachmentsFormDialogComponent, {
			width: '500px',
			data: {
				formGroup: formGroup,
			},
		});

		dialogRef
			.afterClosed()
			.pipe(filter((result) => result?.success))
			.subscribe(() => {
				model.contentType = formGroup.controls.contentType.value;
				this.dialogService.close(DialogType.PROCESSING);
			});
	}

	private createDocumentFormGroup = (model: any): FormGroup => {
		return this.formBuilder.group({
			contentType: [model.contentType, Validators.required],
			name: model.nameWithExtension,
		});
	};
}
