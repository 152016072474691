import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'ae-kle-details-footer',
	templateUrl: './kle-details-footer.component.html',
	styleUrls: ['./kle-details-footer.component.scss'],
})
export class KleDetailFooterComponent {
	@Input() formGroup: FormGroup;
	@Input() formGroupIncidentContext: FormGroup;
	@Input() isCoverageYes: boolean;
	@Input() isBranchHr: boolean;

	@Output() submitClicked = new EventEmitter();
	@Output() syncClicked = new EventEmitter();
	@Output() accidentCertificateClicked = new EventEmitter();
	@Output() pharmacyCertificateClicked = new EventEmitter();

	submit(): void {
		this.submitClicked.emit();
	}

	sync(): void {
		this.syncClicked.emit();
	}

	generateReport(): void {
		this.accidentCertificateClicked.emit();
	}

	generatePharmacyReport(): void {
		this.pharmacyCertificateClicked.emit();
	}
}
