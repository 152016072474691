<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [formGroup]="formGroup" [center]="false">
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row" fxLayoutGap="10px">
					<nc-datepicker
						[id]="'start'"
						[formGroup]="formGroup"
						formControlName="start"
						label="{{ isActivityLog ? 'startDate' : 'beginning' }}"
					></nc-datepicker>
					<nc-datepicker [id]="'to'" [formGroup]="formGroup" formControlName="to" label="to"></nc-datepicker>
				</div>
				<div fxLayout="column" fxLayoutGap="10px">
					<nc-toggle-buttons [formGroup]="formGroup" formControlName="type" label="caseType" [options]="type"></nc-toggle-buttons>
				</div>
				<div fxLayout="row" fxLayoutGap="10px">
					<nc-button
						[id]="'create'"
						[onClick]="showReport"
						icon="filter_list_alt"
						[isRaised]="true"
						label="{{ isActivityLog ? 'showActivityLog' : 'create' }}"
					>
					</nc-button>
					<nc-button [id]="'export'" [onClick]="onExport" [isRaised]="true" label="export"> </nc-button>
				</div>
			</div>
		</nc-form-content>
		<div *ngIf="report$ | async as report" id="report-container">
			<mat-card *ngIf="report.records.length > 0">
				<mat-card-content>
					<nc-datatable [id]="'report'" [columns]="reportColumns" [data$]="report$" [filterConfig]="filterConfig"> </nc-datatable>
				</mat-card-content>
			</mat-card>
		</div>
	</nc-main-content>
</div>
