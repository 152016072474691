import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CanDeactivateFormComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { DocumentTypeTranslation } from '../../model/document-type-translation';
import { DocumentTypesPageAction, DocumentTypesSelect } from '../../state/action';

@Component({
	templateUrl: './document-types-form.component.html',
	styleUrls: ['./document-types-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentTypesFormComponent extends CanDeactivateFormComponent implements OnDestroy {
	documentType$: Observable<DocumentTypeTranslation[]>;
	isCodeReadOnly: boolean;

	constructor(private store: Store, private formBuilder: FormBuilder, private route: ActivatedRoute) {
		super();
		this.route.params.subscribe((params) => {
			if (params.code) {
				this.store.dispatch(DocumentTypesPageAction.getDocumentType({ currentDocumentTypeCode: params.code }));
			}
		});

		this.documentType$ = this.store.select(DocumentTypesSelect.getCurrentDocumentType);

		this.formGroup = this.formBuilder.group({
			code: [null, Validators.required],
			de: null,
			en: null,
			fr: null,
			it: null,
		});

		this.store.select(DocumentTypesSelect.getCurrentDocumentType).subscribe((translations) => {
			this.isCodeReadOnly = translations != null && translations.length > 0;
		});

		this.documentType$
			.pipe(
				filter((documentType) => documentType != null && documentType[0]?.code != null),
				takeUntil(this.destroy$)
			)
			.subscribe((documentType) => this.displayDocumentType(documentType));
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.store.dispatch(DocumentTypesPageAction.clearCurrentDocumentType());
	}

	displayDocumentType(label: DocumentTypeTranslation[]): void {
		this.formGroup.reset();
		this.formGroup.patchValue({
			code: label[0].code,
			de: label.find((t) => t.language === 'de').value,
			en: label.find((t) => t.language === 'en').value,
			fr: label.find((t) => t.language === 'fr').value,
			it: label.find((t) => t.language === 'it').value,
		});
	}

	dispatchSubmitAction = () => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(DocumentTypesPageAction.saveDocumentType({ documentType: data }));
	};
}
