import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from '../dashboard.state';

export const DASHBOARD_FEATURE_STATE_NAME = 'dashboard';
const FEATURE_STATE = createFeatureSelector<DashboardState>(DASHBOARD_FEATURE_STATE_NAME);

export const getTasks = createSelector(FEATURE_STATE, (state) => state.tasks);
export const getMilestones = createSelector(FEATURE_STATE, (state) => state.milestones);
export const getActiveAbsences = createSelector(FEATURE_STATE, (state) => state.activeCases);
export const getNumberOfDays = createSelector(FEATURE_STATE, (state) => state.numberOfDays);
export const getSelectedUser = createSelector(FEATURE_STATE, (state) => state.selectedUser);
export const getArchivedAbsences = createSelector(FEATURE_STATE, (state) => state.archivedCases);
export const getResponsibleUsers = createSelector(FEATURE_STATE, (state) => state.responsibleUsers);
export const getHrActiveAbsence = createSelector(FEATURE_STATE, (state) => state.hrActiveAbsence);
export const casesToBeApproved = createSelector(FEATURE_STATE, (state) => state.casesToBeApproved);
export const getAwaitStories = createSelector(FEATURE_STATE, (state) => state.awaitStories);
export const getPieChartData = createSelector(FEATURE_STATE, (state) => state.pieChartData);
export const getBarChartData = createSelector(FEATURE_STATE, (state) => state.barChartData);
export const getHrCompanies = createSelector(FEATURE_STATE, (state) => state.hrCompanies);
