import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../../../shared/form-validation';

const OK = null;
const REQUIRED = { required: true };

const isCommentRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const absenceTypeControl = control.get('reason');
		const commentControl = control.get('comment');

		if (absenceTypeControl.value === 'OTHER_ARCHIVE_REASON' && commentControl.value === '') {
			FormValidation.addError(commentControl, REQUIRED);
		} else {
			FormValidation.removeError(commentControl, REQUIRED);
		}

		return OK;
	};
};
export { isCommentRequired };
