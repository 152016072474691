import { Component, Input, OnInit } from '@angular/core';
import { AbsenceTypeEnumeration } from '../../../../shared/enumeration/absence-type.enumeration';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent, Option } from 'nc-shared';
import { EnumUtils } from 'nc-utils';
import { AbsenceAccidentSubTypeEnumeration } from '../../../model/enumeration/absence-accident-sub-type.enumeration';
import { AbsenceSicknessSubTypeEnumeration } from '../../../model/enumeration/absence-sickness-sub-type.enumeration';

@Component({
	selector: 'ea-absence-type',
	styleUrls: ['./absence-type.component.css'],
	templateUrl: './absence-type.component.html',
})
export class AbsenceTypeComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() isReadonly = false;

	absenceTypeControl: FormControl<string>;
	absenceTypeOptions: Option[];
	absenceAccidentSubTypeOptions: Option[];
	absenceSicknessSubTypeOptions: Option[];

	ABSENCE_TYPE_ENUMERATION = AbsenceTypeEnumeration;

	ngOnInit(): void {
		this.absenceTypeControl = this.formGroup.controls.absenceType as FormControl<string>;

		this.absenceTypeOptions = EnumUtils.toItems(AbsenceTypeEnumeration);
		this.absenceAccidentSubTypeOptions = EnumUtils.toItems(AbsenceAccidentSubTypeEnumeration);
		this.absenceSicknessSubTypeOptions = EnumUtils.toItems(AbsenceSicknessSubTypeEnumeration);

		const absenceSubTypeControl = this.formGroup.controls.absenceSubType;
		this.absenceTypeControl.valueChanges.subscribe((type) => {
			switch (type) {
				case AbsenceTypeEnumeration.ACCIDENT.code:
					absenceSubTypeControl.patchValue(AbsenceAccidentSubTypeEnumeration.WORK.code);
					break;
				case AbsenceTypeEnumeration.SICKNESS.code:
					absenceSubTypeControl.patchValue(AbsenceSicknessSubTypeEnumeration.UNKNOWN.code);
					break;
				default:
					absenceSubTypeControl.patchValue('');
					break;
			}
		});
	}
}
