import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseComponent } from 'nc-shared';
import { KleService } from '../../../service/kle.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { parseDate } from 'nc-utils';
import { Moment } from 'moment';

@Component({
	templateUrl: './repayment-dialog.component.html',
})
export class RepaymentDialogComponent extends BaseComponent implements OnInit, OnDestroy {
	formGroup = this.formBuilder.group({
		id: null as string,
		storyId: null as string,
		iban: null as string,
		accountNumber: null as string,
		settlementId: null as string,
		expiryDate: null as Moment,
		createdOn: null as Moment,
		balance: null as number,
		reference: null as string,
	});

	constructor(@Inject(MAT_DIALOG_DATA) public data, private formBuilder: FormBuilder, private kleService: KleService) {
		super();
	}

	ngOnInit(): void {
		this.kleService.getRepayment(this.data.absenceCode, this.data.rowData.storyId).subscribe((x) =>
			this.formGroup.patchValue({
				...x,
				expiryDate: parseDate(x.expiryDate),
				createdOn: parseDate(x.createdOn),
			})
		);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.formGroup.reset();
	}
}
