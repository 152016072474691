import { ChartData } from './chart-data';

export class StackedChartData {
	name: string;
	series: ChartData[];

	constructor(name: string, series: ChartData[]) {
		this.name = name;
		this.series = series;
	}
}
