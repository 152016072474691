import { UserCompanyInfo } from '../model/user-company-info';
import { USER_PREFERENCES_INITIAL_STATE } from '../../state/settings/settings.state';

export interface UserState {
	id: number;
	token: string;
	accessToken: string;
	refreshToken: string;
	email: string;
	phone: string;
	has2Fa: boolean;
	username: string;
	language: string;
	lastName: string;
	firstName: string;
	isAuthenticated: boolean;
	forgotPasswordToken: string;
	userType: string;
	companies: UserCompanyInfo[];
	permissions: string[];
	isLogoutClicked: boolean;
	isFormPage: boolean;
	companyFilterOptions: any;
}

export const USER_INITIAL_STATE = {
	id: 0,
	token: null,
	accessToken: null,
	refreshToken: null,
	email: null,
	phone: null,
	has2Fa: false,
	username: null,
	language: null,
	lastName: null,
	firstName: null,
	isAuthenticated: false,
	forgotPasswordToken: null,
	userType: null,
	companies: [],
	permissions: [],
	isLogoutClicked: false,
	isFormPage: false,
	companyFilterOptions: [],
};

export const createUserInitialState = (userId) => {
	return {
		...USER_PREFERENCES_INITIAL_STATE,
		dashboard: {
			selectedUser: userId.toString(),
			milestoneFilter: '',
			tasksFilter: '',
			selectedNumberOfDays: [],
			activeCasesFilter: '',
			selectedCompanies: null,
			sortByColumnMilestone: '',
			orderMilestone: '',
			sortByColumnTasks: '',
			orderTasks: '',
			sortByColumnAbsence: '',
			orderAbsence: '',
		},
		archivedCases: {
			responsibleUser: userId.toString(),
			caseFilter: '',
			selectedCompanies: null,
			sortByColumn: '',
			order: '',
		},
		userAdministration: {
			neoIdFilter: '',
			roleFilter: null,
			nameFilter: '',
			companyNameFilter: '',
			usernameFilter: '',
			emailFilter: '',
			activeFilter: null,
			sortByColumn: '',
			order: '',
		},
		administration: {
			nameFilter: '',
			defaultAssigneeFilter: '',
			sortByColumn: '',
			order: '',
		},
		milestone: {
			globalFilter: '',
			sortByColumn: '',
			order: '',
		},
		labelManagement: {
			globalFilter: '',
			sortByColumn: '',
			order: '',
		},
		ldResponsible: {
			globalFilter: '',
			sortByColumn: '',
			order: '',
		},
		documentTypes: {
			globalFilter: '',
			sortByColumn: '',
			order: '',
		},
		company: {
			nameFilter: '',
			easyTempIdFilter: '',
			neoIdFilter: '',
			neoEnvironmentFilter: '',
			uidFilter: '',
			isKleEnabledFilter: '',
			isSwicaInsuredFilter: '',
			isPkProInsuredFilter: '',
			externallyUpdatedOnFilter: null,
			sortByColumn: '',
			order: '',
		},
		medicalInstitutions: {
			globalLocationNumberFilter: '',
			doctorFirstNameFilter: '',
			doctorLastNameFilter: '',
			institutionNameFilter: '',
			additionalInstitutionNameFilter: '',
			streetFilter: '',
			cityFilter: '',
			zipFilter: '',
			countryFilter: '',
			lastChangeDateFilter: null,
			sortByColumn: '',
			order: '',
		},
	};
};
