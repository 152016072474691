import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessManagementPageAction, AccessManagementSelect } from '../../state/action';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ErrorSelect } from '../../../state/error/action';
import { ValidationService } from '../../../shared/service/validation.service';
import { AuthorityModel } from '../../model/authority.model';
import { CanDeactivateFormComponent } from 'nc-shared';
import { AuthorityTypeEnumeration } from '../../model/enumeration/authority-type.enumeration';

@Component({
	templateUrl: './authority-form.component.html',
	styleUrls: ['./authority-form.component.scss'],
})
export class AuthorityFormComponent extends CanDeactivateFormComponent implements OnInit, OnDestroy {
	authority$: Observable<AuthorityModel>;
	authorityType: AuthorityTypeEnumeration;
	urlPath: string;

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();

		this.route.url.subscribe((url) => {
			this.urlPath = url[0].path;

			if (this.urlPath === 'roles') {
				this.authorityType = AuthorityTypeEnumeration.ROLE;
			} else if (this.urlPath === 'role-groups') {
				this.authorityType = AuthorityTypeEnumeration.ROLE_GROUP;
			} else if (this.urlPath === 'permissions') {
				this.authorityType = AuthorityTypeEnumeration.PERMISSION;
			}
		});

		this.route.params.subscribe((params) => {
			if (params.id && params.id !== 'new') {
				this.store.dispatch(AccessManagementPageAction.getAuthority({ authorityId: params.id, authorityType: this.authorityType }));
			}
		});

		this.authority$ = this.store.select(AccessManagementSelect.getAuthority);
		this.store.dispatch(AccessManagementPageAction.setCurrentAuthorityType({ authorityType: this.authorityType, urlPath: this.urlPath }));

		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({
			id: ['', null],
			code: ['', Validators.required],
			name: ['', Validators.required],
		});

		this.authority$
			.pipe(
				filter((authority) => authority !== null && authority.id !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((authority) => this.displayAuthority(authority));
	}

	dispatchSubmitAction = (): void => {
		const authority = this.formGroup.getRawValue() as AuthorityModel;
		authority.type = this.authorityType;
		this.store.dispatch(AccessManagementPageAction.saveAuthority({ authority }));
	};

	displayAuthority = (authority: AuthorityModel): void => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			id: authority.id,
			code: authority.code,
			name: authority.name,
		});
	};

	ngOnDestroy(): void {
		this.store.dispatch(AccessManagementPageAction.clearAuthority());
	}
}
