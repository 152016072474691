import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserApiAction, UserPageAction, UserSelect } from '../../state/action';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { EnumUtils } from 'nc-utils';
import { LanguageEnumeration } from '../../../shared/enumeration/language.enumeration';
import { UserDetails } from '../../model/user-details';
import { map } from 'rxjs/operators';

@Component({
	templateUrl: './personal-data.component.html',
	styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent extends CanDeactivateFormComponent implements OnInit {
	languages: Option[];
	user$: Observable<UserDetails>;

	constructor(private store: Store, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.store.dispatch(UserApiAction.getPersonalData());

		this.formGroup = this.formBuilder.group({
			username: [''],
			firstName: [''],
			lastName: [''],
			mobileNumber: [''],
			email: [''],
			languageCode: ['de'],
		});

		this.user$ = this.store.select(UserSelect.getUser).pipe(
			map((user) => {
				return {
					username: user.username,
					firstName: user.firstName,
					lastName: user.lastName,
					mobileNumber: user.phone,
					email: user.email,
					languageCode: user.language,
				};
			})
		);

		this.languages = EnumUtils.toItems(LanguageEnumeration);
	}

	dispatchSubmitAction = () => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.personalDataSubmit(data));
	};
}
