import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LdResponsibleService } from '../service/ld-responsible.service';
import { DialogService, DialogType } from 'nc-utils';
import { LdResponsibleApiAction, LdResponsiblePageAction } from './action';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorAction } from '../../state/error/action';
import { DeleteConfirmationDialogComponent } from '../../shared/component/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Store } from '@ngrx/store';
import { SettingsPageAction } from '../../state/settings/action';

@Injectable()
export class LdResponsibleEffect {
	constructor(
		private actions$: Actions,
		private ldResponsibleService: LdResponsibleService,
		private dialogService: DialogService,
		private store: Store
	) {}

	getLdResponsiblesEffects$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LdResponsiblePageAction.getLdResponisbles),
			switchMap(() => this.ldResponsibleService.getLdResponsibles()),
			map((result) => LdResponsibleApiAction.getLdResponsiblesSuccess({ records: result }))
		);
	});

	openConfirmDeleteDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(LdResponsiblePageAction.openDeleteConfirmationDialog),
				switchMap((action) => {
					const dialogConfig = { disableClose: true, data: { id: action.id } };
					return this.dialogService.open(DeleteConfirmationDialogComponent, dialogConfig).afterClosed();
				}),
				tap((dialogResult) => {
					if (dialogResult.success) {
						this.store.dispatch(LdResponsiblePageAction.deleteLdResponsible({ id: dialogResult.id }));
					}
				})
			);
		},
		{ dispatch: false }
	);

	deleteLdResponsibleEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LdResponsiblePageAction.deleteLdResponsible),
			switchMap((action) => this.ldResponsibleService.deleteLdResponsible(action.id)),
			map((response) => {
				return response.success ? LdResponsiblePageAction.getLdResponisbles() : ErrorAction.submitFailed({ errors: response.errors });
			})
		);
	});

	getLdResponsibleEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LdResponsiblePageAction.getLdResponisble),
			switchMap((action) => this.ldResponsibleService.getLdResponsible(action.id)),
			map((result) => LdResponsibleApiAction.getLdResponsibleSuccess({ ldResponsible: result }))
		);
	});

	initializeUserOptionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LdResponsiblePageAction.initializeUserOptions),
			switchMap(() => this.ldResponsibleService.getResponsibleLdUsers()),
			map((responsibleUserOptions) => LdResponsibleApiAction.initializeUserOptionsSuccess({ responsibleUserOptions }))
		);
	});

	saveLdResponsibleEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LdResponsiblePageAction.saveLdResponsible),
			mergeMap((action) => this.ldResponsibleService.saveLdResponsible(action.ldResponsible)),
			map((response) =>
				response.success ? LdResponsibleApiAction.saveLdResponsibleSuccess({ response }) : ErrorAction.setErrors({ errors: response.errors })
			)
		);
	});

	saveLdResponsibleSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LdResponsibleApiAction.saveLdResponsibleSuccess),
			map(() => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.navigate({ url: '/ld-responsibles' });
			})
		);
	});
}
