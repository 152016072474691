import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportRequest } from '../model/report-request';
import { ExportRequest } from '../../shared/model/export.request';
import { ExportEnumeration } from '../../shared/enumeration/export.enumeration';
import { ExportExtensionEnumeration } from '../../shared/enumeration/export-extension.enumeration';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class ReportService {
	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly REPORT_URL = `${this.API_URL}/reports`;
	private readonly EXPORT_URL = `${this.API_URL}/export`;

	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets report
	 * @param reportRequest params for searching report
	 * @param isActivityLog param for detecting activity log page or report page
	 */
	getReport(reportRequest: ReportRequest, isActivityLog: Boolean): Observable<{ [key: string]: string }[]> {
		const url = isActivityLog ? '/activity-logs' : '/cases';
		return this.httpClient.post<{ [key: string]: string }[]>(this.REPORT_URL + url, reportRequest);
	}

	/**
	 * Exports report to excel
	 * @param reportRequest data used to filter report
	 * @param isActivityLog param for detecting activity log page or report page
	 */
	exportToFile(reportRequest: ReportRequest, isActivityLog: Boolean): Observable<HttpResponse<Blob>> {
		const url = isActivityLog ? '/activity-logs' : '/cases';
		const exportEnumaration = isActivityLog ? ExportEnumeration.ACTIVITY_LOG_REPORT : ExportEnumeration.CASES_REPORT;
		const request = new ExportRequest(exportEnumaration, ExportExtensionEnumeration.EXCEL, reportRequest);
		return this.httpClient.post(this.EXPORT_URL + url, request, { responseType: 'blob', observe: 'response' });
	}
}
