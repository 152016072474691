enum FlagEnumeration {
	yes = 'yes',
	no = 'no',
}

const booleanToFlag = (value: boolean) => {
	return value ? 'yes' : 'no';
};

const booleanToFlagEnumeration = (value: boolean): FlagEnumeration => {
	return value ? FlagEnumeration.yes : FlagEnumeration.no;
};

const toBoolean = (value: string): boolean => {
	return value === FlagEnumeration.yes;
};

export { FlagEnumeration, booleanToFlag, toBoolean, booleanToFlagEnumeration };
