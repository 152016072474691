<ea-container cssClass="margin-top-15">
	<nc-button *ngIf="isHr && absenceCode" id="fetch-treatments-from-absence" label="updateFromBranch" (click)="fetchFromAbsence()"></nc-button>
	<ng-template #tableHeader>
		<div class="button-add-container" fxLayout="row wrap">
			<nc-button id="add" icon="add" [disabled]="isBranchHr" [onClick]="openDialog"></nc-button>
		</div>
	</ng-template>
	<nc-datatable
		id="treatments"
		[rowClick]="isReadonly ? null : edit"
		[tableHeader]="tableHeader"
		[columns]="getColumns()"
		[data$]="data$"
		[filterConfig]="filterConfig"
	></nc-datatable>
	<div *ngIf="isInvalid()" class="error-container">
		<ng-container *ngIf="getErrorMessage() as errorLabel">
			{{ errorLabel.label | translate : errorLabel.parameters }}
		</ng-container>
	</div>
</ea-container>
