import { Component, OnInit } from '@angular/core';
import { EcareConstants } from '../../../shared/environment-constants/ecare-constants';
import { TellcoConstants } from '../../../shared/environment-constants/tellco-constants';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	selector: 'ea-unauthenticated-form-container',
	templateUrl: './unauthenticated-form-container.component.html',
	styleUrls: ['./unauthenticated-form-container.component.scss'],
})
export class UnauthenticatedFormContainerComponent implements OnInit {
	existingUserIcon: string;

	constructor(private environmentLoader: EnvironmentLoader) {}

	ngOnInit(): void {
		this.existingUserIcon =
			this.environmentLoader.getEnvironment().theme === 'tellco-theme' ? TellcoConstants.EXISTING_USER_URI : EcareConstants.EXISTING_USER_URI;
	}
}
