import { createAction, props } from '@ngrx/store';
import { AuthorityOperationModel } from '../../model/authority-operation.model';
import { AuthorityTypeEnumeration } from '../../model/enumeration/authority-type.enumeration';
import { DocumentTypeEntityAccessEnumeration } from '../../model/enumeration/document-type-entity-access.enumeration';
import { AccessTypeEnumeration } from '../../model/enumeration/access-type.enumeration';
import { DocumentTypeModel } from '../../model/document-type.model';

export const getSelectOptions = createAction('[Access assignment] Get select options', props<{ url: string }>());
export const getAvailable = createAction('[Access assignment] Operation available', props<{ authorityOperation: AuthorityOperationModel }>());
export const getAvailableForDocs = createAction(
	'[Access assignment] Operation available for docs',
	props<{ documentTypeModel: DocumentTypeModel }>()
);
export const getAssigned = createAction('[Access assignment] Operation assigned', props<{ authorityOperation: AuthorityOperationModel }>());
export const getAssignedForDocs = createAction('[Access assignment] Operation assigned for docs', props<{ documentTypeModel: DocumentTypeModel }>());
export const assignOrRemove = createAction(
	'[Access assignment] Operation assign or remove',
	props<{ authorityOperation: AuthorityOperationModel }>()
);
export const assignOrRemoveForDocs = createAction(
	'[Access assignment] Operation assign or remove for docs',
	props<{ documentTypeModel: DocumentTypeModel }>()
);
export const clearData = createAction('[Access assignment] Clear data');
export const setCurrentEntityId = createAction('[Access assignment] Set current entityId', props<{ entityId: number }>());
export const setCurrentAuthorityType = createAction(
	'[Access assignment] Set current authority type',
	props<{ authorityType: AuthorityTypeEnumeration }>()
);
export const setCurrentEntityType = createAction(
	'[Access assignment] Set current entity type',
	props<{ entityType: DocumentTypeEntityAccessEnumeration }>()
);
export const setCurrentAccessType = createAction('[Access assignment] Set current access type', props<{ accessType: AccessTypeEnumeration }>());
