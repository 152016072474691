import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserAdministrationState } from '../user-administration.state';

export const USER_ADMINISTRATION_FEATURE_STATE_NAME = 'user-administration';
const FEATURE_STATE = createFeatureSelector<UserAdministrationState>(USER_ADMINISTRATION_FEATURE_STATE_NAME);

export const getUsers = createSelector(FEATURE_STATE, (state) => state.users);
export const getUser = createSelector(FEATURE_STATE, (state) => state.user);
export const getGeneratedPassword = createSelector(FEATURE_STATE, (state) => state.generatedPassword);
export const getCopiedCompanies = createSelector(FEATURE_STATE, (state) => state.copiedCompanies);
