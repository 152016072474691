import * as ApplicationState from '../../state/app.state';
import { AuthorityModel } from '../model/authority.model';
import { TableData } from 'nc-datatable';
import { AuthorityTypeEnumeration } from '../model/enumeration/authority-type.enumeration';

export interface AccessManagementState {
	authorities: TableData;
	authority: AuthorityModel;
	currentAuthorityType: AuthorityTypeEnumeration;
	urlPath: string;
}

export interface State extends ApplicationState.State {
	accessManagement: AccessManagementState;
}

export const ACCESS_MANAGEMENT_STATE: AccessManagementState = {
	authorities: { records: [] },
	authority: null,
	currentAuthorityType: null,
	urlPath: '',
};
