<div id="merge-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div [formGroup]="formGroup" id="reassign-absence-container">
		<nc-textarea [id]="'reason'" [formGroup]="formGroup" formControlName="reason" label="reason"></nc-textarea>
		<nc-datatable
			[id]="'merge-absence'"
			[selectConfig]="selectConfig"
			[columns]="mergeAbsenceColumns"
			[data$]="mergeAbsence$"
			[filterConfig]="filterConfig"
		>
		</nc-datatable>
	</div>
	<div mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="10px">
			<nc-button [id]="'cancel'" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
			<nc-submit-button
				[id]="'merge'"
				buttonLabel="merge"
				dialogLabel="processing"
				(click)="onMerge()"
				[disabled]="!selectConfig.formArray.value[0]"
			></nc-submit-button>
		</div>
	</div>
</div>
