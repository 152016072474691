import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../../../shared/form-validation';
import { DeclareTypeEnumeration } from '../../../../kle/model/declare-type.enumeration';
import { Moment, now } from 'moment';
import { AccidentDescriptionOptionsEnumItems } from '../../../../kle/model/enums/accident-description-options.enum';

const OK = null;
const REQUIRED = { required: true };
const EXACT_APPROX_DATE_ERROR = { exactApproxDateError: true };
const ACCIDENT_DATE_IN_FUTURE = { accidentDateInFuture: true };
const RELAPSE_DATE_ERROR = { relapseDateError: true };
const RELAPSE_DATE_IN_FUTURE = { relapseDateInFuture: true };
const INCIDENT_AFTER_INCAPACITY_VALID_FROM = {};
const exactApproxDateValidator = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const approximateDateControl = control.get('approximateDate');
		const exactDateControl = control.get('exactDate');
		const clarificationControl = control.get('clarification');

		return !clarificationControl.value && approximateDateControl.value == null && exactDateControl.value == null ? EXACT_APPROX_DATE_ERROR : OK;
	};
};

const accidentDateValidator = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const approximateDateControl = control.get('approximateDate');
		const exactDateControl = control.get('exactDate');

		if (approximateDateControl?.value && approximateDateControl?.value.isAfter(now())) {
			FormValidation.addError(approximateDateControl, ACCIDENT_DATE_IN_FUTURE);
		} else {
			FormValidation.removeError(approximateDateControl, ACCIDENT_DATE_IN_FUTURE);
		}

		if (exactDateControl?.value && exactDateControl?.value.isAfter(now())) {
			FormValidation.addError(exactDateControl, ACCIDENT_DATE_IN_FUTURE);
		} else {
			FormValidation.removeError(exactDateControl, ACCIDENT_DATE_IN_FUTURE);
		}

		return OK;
	};
};

const relapseDateValidator = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const approximate = control.value.approximateDate as Moment;
		const exactDate = control.value.exactDate as Moment;
		const relapseDate = control.value.relapseDate as Moment;
		const relapseDateControl = control.get('relapseDate');

		const isRelapse = control.value.type === AccidentDescriptionOptionsEnumItems.RELAPSE.code;
		const isRelapseDateBeforeAccidentDate = (approximate && relapseDate < approximate) || (exactDate && relapseDate < exactDate);

		if (isRelapse && !relapseDate) {
			FormValidation.addError(relapseDateControl, REQUIRED);
		} else {
			FormValidation.removeError(relapseDateControl, REQUIRED);
		}

		if (isRelapse && relapseDate && isRelapseDateBeforeAccidentDate) {
			FormValidation.addError(relapseDateControl, RELAPSE_DATE_ERROR);
		} else {
			FormValidation.removeError(relapseDateControl, RELAPSE_DATE_ERROR);
		}

		if (isRelapse && relapseDate && relapseDate.isAfter(now())) {
			FormValidation.addError(relapseDateControl, RELAPSE_DATE_IN_FUTURE);
		} else {
			FormValidation.removeError(relapseDateControl, RELAPSE_DATE_IN_FUTURE);
		}

		return OK;
	};
};

const insuranceCodeValidator = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const typeControl = control.get('type');
		const insuranceCaseIdControl = control.get('insuranceCaseId');

		if (typeControl.value === DeclareTypeEnumeration.RELAPSE.code && !insuranceCaseIdControl.value) {
			FormValidation.addError(insuranceCaseIdControl, REQUIRED);
		} else {
			FormValidation.removeError(insuranceCaseIdControl, REQUIRED);
		}

		return OK;
	};
};

const incapacityToWorkValidFromDateValidatorApproximate = (validFromDate: Moment): ValidatorFn => {
	return (control: AbstractControl<Moment | null>): ValidationErrors | null => {
		const value = control.value;
		validFromDate?.utc(true);

		if (value?.isAfter(validFromDate?.add(23, 'hours'))) {
			return INCIDENT_AFTER_INCAPACITY_VALID_FROM;
		}
		return null;
	};
};
const incapacityToWorkValidFromDateValidatorExact = (validFromDate: Moment): ValidatorFn => {
	return (control: AbstractControl<Moment | null>): ValidationErrors | null => {
		const value = control.value;

		if (value?.isAfter(validFromDate)) {
			return INCIDENT_AFTER_INCAPACITY_VALID_FROM;
		}
		return null;
	};
};
export {
	relapseDateValidator,
	exactApproxDateValidator,
	insuranceCodeValidator,
	accidentDateValidator,
	incapacityToWorkValidFromDateValidatorApproximate,
	incapacityToWorkValidFromDateValidatorExact,
};
