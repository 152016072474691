import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { UserSelect } from '../state/action';
import { map } from 'rxjs/operators';
import { UserTypeEnumeration } from '../../shared/enumeration/user-type.enumeration';
import { SettingsPageAction } from '../../state/settings/action';

@Injectable({
	providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate {
	userType: string;
	url: string;

	constructor(private router: Router, private store: Store<State>) {
		this.store.select(UserSelect.getUserType).subscribe((userType) => (this.userType = userType));
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(UserSelect.isAuthenticated).pipe(
			map((authenticated) => {
				if (authenticated) {
					this.url = this.userType.toUpperCase() === UserTypeEnumeration.ADMIN.toUpperCase() ? '/user-administration' : '/dashboard';

					this.store.dispatch(SettingsPageAction.navigate({ url: this.url }));
				}

				return !authenticated;
			})
		);
	}
}
