<ea-unauthenticated-layout [formGroup]="formGroup">
	<ea-unauthenticated-form-container>
		<div class="text">{{ 'enterNewPassword' | translate }}</div>
		<nc-password [id]="'password'" [buttonId]="'hide-password'" [formGroup]="formGroup" formControlName="password" label="password"></nc-password>
		<nc-password
			[id]="'repeat-password'"
			[buttonId]="'hide-repeat-password'"
			[formGroup]="formGroup"
			formControlName="repeatedPassword"
			label="repeatPassword"
		></nc-password>
		<div fxLayout="row wrap" fxLayoutAlign="end">
			<nc-submit-button
				[id]="'reset-password'"
				[formGroup]="formGroup"
				buttonLabel="resetPassword"
				(buttonClick)="onSubmit()"
				dialogLabel="processing"
			></nc-submit-button>
		</div>
	</ea-unauthenticated-form-container>
</ea-unauthenticated-layout>
