import { Component, Input, OnInit } from '@angular/core';
import { ChartColor } from '../../model/chart/chart-color';
import { TranslateService } from '@ngx-translate/core';
import { ChartTypeEnumeration } from '../../model/chart/chart-type.enumeration';
import { ChartData } from '../../model/chart/chart-data';
import { StackedChartData } from '../../model/chart/stacked-chart-data';

@Component({
	selector: 'ea-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
	@Input() type: ChartTypeEnumeration;
	@Input() chartTitle: string;
	@Input() data: Array<ChartData | StackedChartData>;
	@Input() colors: ChartColor;
	@Input() showLegend = true;

	chartEnumeration = ChartTypeEnumeration;

	isGradient = true;
	showLabels = false;
	isDoughnut = false;
	legendPosition = 'right';
	translatedData: Array<any>;
	legendTitle: string;

	chartOptions = { responsive: true };

	constructor(private translateService: TranslateService) {
		this.legendTitle = translateService.instant('legendTitle');
	}

	ngOnInit(): void {
		this.translatedData = this.data.map((item) => {
			return this.type === ChartTypeEnumeration.STACKED_BAR
				? this.translateStacked(item as StackedChartData)
				: this.translate(item as ChartData);
		});
	}

	translate(item: ChartData): ChartData {
		const name = this.translateService.instant(item.name);
		return {
			...item,
			name,
		};
	}

	translateStacked(item: StackedChartData): StackedChartData {
		const name = this.translateService.instant(item.name);
		return {
			name,
			series: item.series.map((x) => this.translate(x)),
		};
	}
}
