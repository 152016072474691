<div [formGroup]="formGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15px">
		<nc-datepicker
			id="valid-from"
			[formGroup]="formGroup"
			formControlName="validFrom"
			label="validFrom"
			[required]="true"
			class="margin-bottom-15"
		></nc-datepicker>
		<nc-datepicker id="valid-to" [formGroup]="formGroup" formControlName="validTo" label="validTo"></nc-datepicker>
	</div>
	<nc-input id="percentage" [formGroup]="formGroup" formControlName="percentage" label="percentage" [required]="true"></nc-input>
	<nc-datepicker
		id="take-work-up-fully"
		*ngIf="isKleEnabled && hasDeclareIncidentPermission && isAccident"
		[formGroup]="formGroup"
		formControlName="takeWorkUpFullyDate"
		label="takeWorkUpFullyDate"
		class="margin-bottom-15"
	></nc-datepicker>
	<div *ngIf="existsTakeWorkUpFullyAlready" class="error-container">
		{{ 'existsTakeWorkUpFullyAlready' | translate }}
	</div>
	<nc-textarea *ngIf="showCommentField" id="comment" [formGroup]="formGroup" formControlName="comment" label="comment"></nc-textarea>
</div>
