import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	templateUrl: './delete-confirmation-dialog.component.html',
	styleUrls: ['./delete-confirmation-dialog.component.scss'],
})
export class DeleteConfirmationDialogComponent {
	authorityId: number;
	authorityType: string;

	constructor(@Inject(MAT_DIALOG_DATA) data) {
		this.authorityId = data?.authorityId;
		this.authorityType = data?.authorityType;
	}
}
