import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AbsencePageAction } from '../../../state/action';

@Component({
	selector: 'ea-history-footer',
	templateUrl: './history-footer.component.html',
	styleUrls: ['./history-footer.component.scss'],
})
export class HistoryFooterComponent {
	@Input() code: string;

	constructor(private location: Location, private store: Store) {}

	goBack = (): void => {
		this.location.back();
	};

	tasks = (): void => {
		this.store.dispatch(AbsencePageAction.tasks({ code: this.code }));
	};
}
