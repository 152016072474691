<ea-container title="statistics" cssClass="margin-top-15">
	<div fxLayout="column" fxLayoutGap="10px" [formGroup]="formGroup">
		<div fxLayout="row" fxLayoutGap="10px" cssClass="margin-top-15">
			<nc-input [formGroup]="formGroup" type="number" formControlName="basicWages" label="basicWages" [isReadonly]="true"></nc-input>
			<nc-input
				[formGroup]="formGroup"
				type="number"
				formControlName="familyIncomeSupplement"
				label="familyIncomeSupplement"
				[isReadonly]="true"
			></nc-input>
			<nc-input
				[formGroup]="formGroup"
				type="number"
				formControlName="vacationAndPublicHolidayCompensation"
				label="vacationAndPublicHolidayCompensation"
				[isReadonly]="true"
			></nc-input>
			<nc-input
				[formGroup]="formGroup"
				type="number"
				formControlName="otherSupplements"
				label="otherSupplements"
				[isReadonly]="true"
			></nc-input>
			<nc-input [formGroup]="formGroup" type="number" formControlName="gratuity" label="gratuity" [isReadonly]="true"></nc-input>
		</div>
	</div>
</ea-container>
