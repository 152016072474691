import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent, Option } from 'nc-shared';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSelect } from '../../../../user/state/action';
import { DashboardPageAction, DashboardSelect } from '../../../state/action';
import { ChartData } from '../../../../shared/model/chart/chart-data';
import { SettingsPageAction, SettingsSelect } from '../../../../state/settings/action';
import { DashboardChartColor } from '../../../model/dashboard-chart-color';
import { ChartTypeEnumeration } from '../../../../shared/model/chart/chart-type.enumeration';
import { StackedChartData } from '../../../../shared/model/chart/stacked-chart-data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { map, takeUntil } from 'rxjs/operators';
import { CompaniesSelectFactory } from '../../../../shared/utils/companies-select-factory';
import { PermissionEnumeration } from '../../../../shared/enumeration/permission.enumeration';
import { StoryRecordEnum } from '../../../../kle/model/enums/story-record.enum';

@Component({
	selector: 'ea-hr-dashboard',
	templateUrl: './hr-dashboard.component.html',
	styleUrls: ['hr-dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HrDashboardComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	hrDashboardColumn: DatatableColumn[];
	awaitStoriesColumns: DatatableColumn[];
	data$: Observable<TableData>;
	casesToBeApproved: Observable<TableData>;
	awaitStories$: Observable<TableData>;

	companyIds: number[];
	chartColors = DashboardChartColor;
	pieChartData$: Observable<Array<ChartData>>;
	barChartData$: Observable<Array<StackedChartData>>;
	chartType = ChartTypeEnumeration;
	filterConfig: FilterConfig;
	filterCasesToBeApprovedConfig: FilterConfig;
	filterConfigAwait: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;
	userPreferencesSubscription$: Subscription;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription: any;
	companies$: Observable<Option[]>;
	companiesControl: any;
	getHrCompaniesIdsSubscription$: Subscription;
	hasDeclareIncidentPermission = false;
	isBranchHrUser = false;

	ACTIVE_CASES_FILTER = 'activeCasesFilter';
	SORT_BY_COLUMN_ABSENCE = 'sortByColumnAbsence';
	ORDER_ABSENCE = 'orderAbsence';

	constructor(private store: Store, private formBuilder: FormBuilder, private companiesSelectFactory: CompaniesSelectFactory) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(UserSelect.getPermissions)
			.pipe(takeUntil(this.destroy$))
			.subscribe((permissions) => {
				this.hasDeclareIncidentPermission = permissions.includes(PermissionEnumeration.DECLARE_INCIDENT);
				this.isBranchHrUser = permissions.includes(PermissionEnumeration.BRANCH_HR);
			});

		this.getHrCompaniesIdsSubscription$ = this.store.select(UserSelect.getUserCompanyIds).subscribe((result) => {
			this.companyIds = result;
			this.store.dispatch(DashboardPageAction.getHrCompanies({ companyIds: this.companyIds }));
		});

		this.companies$ = this.store.select(UserSelect.getCompanyFilterOptions).pipe(
			takeUntil(this.destroy$),
			map((companyFilterOption) => this.companiesSelectFactory.createCompanySelectModelWithIdsArray(companyFilterOption))
		);

		this.formGroup = this.formBuilder.group({
			companies: [],
		});

		this.companiesControl = this.formGroup.get('companies');

		this.store.dispatch(DashboardPageAction.getHrStatistic({ companyIds: this.companyIds }));
		this.loggedUserSubscription = this.store.select(UserSelect.getUsername).subscribe((value) => (this.loggedUser = value));

		this.userPreferencesSubscription$ = this.store
			.select(SettingsSelect.getDashboardUserPreferences(this.loggedUser))
			.subscribe((value) => this.setUserDashboardLocalStorageData(value));

		this.hrDashboardColumn = [
			ColumnBuilder.create('companyName', 'company'),
			ColumnBuilder.create('workplace', 'branch'),
			ColumnBuilder.create('insuredPerson', 'insuredPerson'),
			ColumnBuilder.createDate('createdOn', 'createdOn'),
			ColumnBuilder.create('code', 'code'),
			ColumnBuilder.create('state', 'state'),
			ColumnBuilder.create('type', 'type'),
			ColumnBuilder.create('subType', 'subType'),
			ColumnBuilder.create('status', 'status'),
			ColumnBuilder.createDate('absenceFrom', 'absenceFrom'),
			ColumnBuilder.createDate('absenceTo', 'absenceTo'),
			ColumnBuilder.createNumber('durationInDays', 'durationInDays'),
			ColumnBuilder.createAction(
				'shareInforamtion',
				'shareInformation',
				this.share,
				{ defaultIcon: 'share' },
				(data) => data.hasClosingAnnex === '0' && !(data.state === 'pending_approval' && this.isBranchHrUser)
			),
		];

		this.awaitStoriesColumns = [
			ColumnBuilder.create('story', 'storyType'),
			ColumnBuilder.createDate('createdOn', 'createdOn'),
			ColumnBuilder.create('companyCaseId', 'companyCase'),
			ColumnBuilder.create('incidentCaseId', 'incidentCase'),
			ColumnBuilder.create('insuranceCaseId', 'insuranceCaseId'),
			ColumnBuilder.create('insuredPerson', 'insuredPerson'),
		];

		this.data$ = this.store.select(DashboardSelect.getHrActiveAbsence);
		this.casesToBeApproved = this.store.select(DashboardSelect.casesToBeApproved);

		if (!this.isBranchHrUser) {
			this.awaitStories$ = this.store
				.select(DashboardSelect.getAwaitStories)
				.pipe(map((tableData) => ({ records: this.mapResponse(tableData) })));
		}

		this.pieChartData$ = this.store.select(DashboardSelect.getPieChartData);
		this.barChartData$ = this.store.select(DashboardSelect.getBarChartData);
		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN_ABSENCE],
			sortByAscOrDesc: this.userPreferences[this.ORDER_ABSENCE],
			filterValues: {
				globalFilter: this.userPreferences[this.ACTIVE_CASES_FILTER],
			},
		};

		this.filterConfigAwait = {
			showFilter: false,
			showGlobalFilter: true,
			filterValues: {},
		};

		this.filterCasesToBeApprovedConfig = {
			showFilter: false,
			showGlobalFilter: true,
			filterValues: {},
		};

		this.companiesControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.store.dispatch(DashboardPageAction.getHrActiveAbsence({ companyIds: value }));

			if (this.hasDeclareIncidentPermission) {
				this.store.dispatch(DashboardPageAction.getAwaitStories({ companyIds: value }));
			}

			if (!this.isBranchHrUser) {
				this.store.dispatch(DashboardPageAction.getCasesToBeApproved({ companyIds: value }));
			}
		});
	}

	rowClicked = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${rowData.code}` }));
	};

	openStory = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${rowData.companyCaseId}/kle` }));
	};

	share = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${rowData.code}/share-information` }));
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription.unsubscribe();
		this.userPreferencesSubscription$.unsubscribe();
		this.getHrCompaniesIdsSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			milestoneFilter: '',
			tasksFilter: '',
			activeCasesFilter: this.filterConfig.globalFilterFormGroup.value.globalFilter,
			sortByColumnAbsence: this.filterConfig.sortTableByColumn,
			orderAbsence: this.filterConfig.sortByAscOrDesc,
			sortByColumnMilestone: '',
			orderMilestone: '',
			sortByColumnTasks: '',
			orderTasks: '',
			selectedCompanies: this.companiesControl.value,
		};

		this.store.dispatch(SettingsPageAction.setDashboardTableFilters({ dashboardFilters: filterObject, loggedUser: this.loggedUser }));
	}

	setUserDashboardLocalStorageData(value: any): void {
		this.userPreferences = value;
		const selectedCompanies = value.selectedCompanies ? value.selectedCompanies : this.companyIds;

		this.store.dispatch(DashboardPageAction.getHrActiveAbsence({ companyIds: selectedCompanies }));
		this.companiesControl.patchValue(selectedCompanies);

		if (this.hasDeclareIncidentPermission) {
			this.store.dispatch(DashboardPageAction.getAwaitStories({ companyIds: selectedCompanies }));
		}

		if (!this.isBranchHrUser) {
			this.store.dispatch(DashboardPageAction.getCasesToBeApproved({ companyIds: this.companiesControl.value }));
		}
	}

	closing = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${rowData.code}/closed` }));
	};

	mapResponse(response: any): any {
		const awaitStories = [];
		if (response?.records.length) {
			response.records.forEach((item) => {
				const translatedType = StoryRecordEnum[item['story']].label;
				awaitStories.push(this.mapStoryName(translatedType, item));
			});
		}

		return awaitStories;
	}

	mapStoryName(translateType: string, item: any): any {
		return {
			...item,
			story: translateType,
		};
	}
}
