import * as ApplicationState from '../../state/app.state';
import { LabelManagementDetails } from '../model/label-management-details';
import { TableData } from 'nc-datatable';

export interface LabelManagementState {
	labels: TableData;
	currentLabel: LabelManagementDetails;
}

export interface State extends ApplicationState.State {
	label: LabelManagementState;
}

export const LABEL_MANAGEMENT_INITIAL_STATE: LabelManagementState = {
	labels: { records: [] },
	currentLabel: null,
};
