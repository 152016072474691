import { BaseComponent, Option } from 'nc-shared';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { LdResponsibleModel } from '../../model/ld-responsible.model';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ValidationService } from '../../../shared/service/validation.service';
import { ErrorSelect } from '../../../state/error/action';
import { LdResponsiblePageAction, LdResponsibleSelect } from '../../state/action';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
	templateUrl: './ld-responsible-form.component.html',
	styleUrls: ['./ld-responsible-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LdResponsibleFormComponent extends BaseComponent implements OnDestroy {
	formGroup: FormGroup;
	userOptions$: Observable<Option[]>;

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();

		this.store.dispatch(LdResponsiblePageAction.initializeUserOptions());
		this.userOptions$ = this.store.select(LdResponsibleSelect.getLdResponsibleUserOptions);

		this.route.params.subscribe((params) => {
			if (params.id && params.id != 'new') {
				this.store.dispatch(LdResponsiblePageAction.getLdResponisble({ id: params.id }));
			}
		});

		this.store.select(ErrorSelect.getErrors).subscribe((errors) => validationService.displayErrors(errors, this.formGroup, changeDetectorRef));

		this.formGroup = this.formBuilder.group({
			id: null,
			responsibleLdUserId: ['', Validators.required],
			letter: ['', Validators.required],
		});

		this.formGroup.get('letter').valueChanges.subscribe((event) => {
			if (this.formGroup.get('letter').value != null) {
				const regex = new RegExp('^[a-zA-ZÄÖÜäöüÀÂÉÈÊËâéèêë]');

				if (regex.test(this.formGroup.get('letter').value)) {
					this.formGroup.get('letter').setValue(event.toUpperCase(), { emitEvent: false });
				} else {
					this.formGroup.get('letter').setValue(null, { emitEvent: false });
				}
			}
		});

		this.store
			.select(LdResponsibleSelect.getLdResponsible)
			.pipe(
				filter((ldResponsible) => ldResponsible != null && ldResponsible.id != null),
				takeUntil(this.destroy$)
			)
			.subscribe((ldResponsible) => this.displayLdResponsible(ldResponsible));
	}

	displayLdResponsible(ldResponsible: LdResponsibleModel): void {
		this.formGroup.reset();
		this.formGroup.patchValue({
			id: ldResponsible.id,
			responsibleLdUserId: ldResponsible.responsibleLdUserId,
			letter: ldResponsible.letter,
		});
	}

	onSave = () => {
		const data = this.formGroup.getRawValue() as LdResponsibleModel;
		this.store.dispatch(LdResponsiblePageAction.saveLdResponsible({ ldResponsible: data }));
	};

	ngOnDestroy(): void {
		this.store.dispatch(LdResponsiblePageAction.clearLdResponsible());
	}
}
