import { Injectable } from '@angular/core';
import { TypedAction } from '@ngrx/store/src/models';
import { DialogConfigurationUtils, DialogService, DialogType, FormUtils } from 'nc-utils';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';
import { AbsencePageAction } from '../state/action';
import { Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { NcInformationDialogComponent } from 'nc-information-dialog';

@Injectable({ providedIn: 'root' })
export class AbsenceFormService {
	PROCESSING_LABEL = 'processing';

	constructor(private dialogService: DialogService, private store: Store) {}

	public checkValidityAndDispatchAction(formGroup: FormGroup, actionForDispatch: TypedAction<string>): void {
		if (formGroup.valid && !formGroup.dirty) {
			const dialogConfig = { disableClose: true, data: { label: this.PROCESSING_LABEL }, id: DialogType.PROCESSING };
			this.dialogService.open(NcProcessingDialogComponent, dialogConfig);
			this.store.dispatch(actionForDispatch);
		} else if (!formGroup.valid) {
			this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('checkAllData', 'info'));
			FormUtils.validateAllFormFields(formGroup);
		} else {
			this.store.dispatch(AbsencePageAction.openDocumentWarningDialog({ actionToDispatch: actionForDispatch }));
		}
	}
}
