<div [formGroup]="formGroup" class="flex-wrap" fxLayout="row wrap" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px">
	<nc-toggle-buttons
		label="absenceType"
		[formGroup]="formGroup"
		[options]="absenceTypeOptions"
		[isDisabled]="isReadonly"
		formControlName="absenceType"
	>
	</nc-toggle-buttons>

	<ng-container [ngSwitch]="absenceTypeControl.value">
		<nc-toggle-buttons
			*ngSwitchCase="ABSENCE_TYPE_ENUMERATION.ACCIDENT.code"
			label="absenceSubType"
			[formGroup]="formGroup"
			[isDisabled]="isReadonly"
			[options]="absenceAccidentSubTypeOptions"
			formControlName="absenceSubType"
		>
		</nc-toggle-buttons>
		<nc-toggle-buttons
			*ngSwitchCase="ABSENCE_TYPE_ENUMERATION.SICKNESS.code"
			label="absenceSubType"
			[formGroup]="formGroup"
			[isDisabled]="isReadonly"
			[options]="absenceSicknessSubTypeOptions"
			formControlName="absenceSubType"
		>
		</nc-toggle-buttons>
		<nc-datepicker
			id="pregnancy-due-date"
			*ngSwitchCase="ABSENCE_TYPE_ENUMERATION.PREGNANCY.code"
			[formGroup]="formGroup"
			[isReadonly]="isReadonly"
			formControlName="pregnancyDueDate"
			label="pregnancyDueDate"
		>
		</nc-datepicker>
	</ng-container>
</div>
