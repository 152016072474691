import { Component, Input } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { ColumnBuilder, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { TableColumns } from '../../../../report/model/table-columns';
import { SyncRecord } from '../../../../shared/model/sync-record';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	selector: 'ea-integration-response-dialog-tab',
	templateUrl: './integration-response-dialog-tab.component.html',
	styleUrls: ['./integration-response-dialog-tab.component.scss'],
})
export class IntegrationResponseDialogTabComponent extends BaseComponent {
	@Input() tableData$: Observable<TableData>;
	@Input() data: SyncRecord;
	reportColumns: TableColumns[];
	filterConfig: FilterConfig;

	constructor() {
		super();
		this.reportColumns = [
			ColumnBuilder.create('type', 'type'),
			ColumnBuilder.create('externalId', 'synchronization.table.externalId'),
			ColumnBuilder.create('status', 'status'),
			ColumnBuilder.create('message', 'synchronization.table.Message'),
		];
		this.filterConfig = {
			showFilter: false,
			filterValues: {},
		};
	}
}
