import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanDeactivateFormComponent } from 'nc-shared';
import { FormArray, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ErrorSelect } from '../../../state/error/action';
import { ValidationService } from '../../../shared/service/validation.service';
import { TaskPageAction, TaskSelect } from '../../state/action';
import { MilestoneModel } from '../../model/milestone.model';
import { DocumentModel, NcDocumentsService } from 'nc-documents';
import { ColumnBuilder, DatatableColumn } from 'nc-datatable';

@Component({
	templateUrl: './milestone-form.component.html',
	styleUrls: ['./milestone-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneFormComponent extends CanDeactivateFormComponent implements OnInit {
	milestone$: Observable<MilestoneModel>;
	documentCheckList: DocumentModel[];
	saveButtonLabel = '';
	documents: FormArray;
	additionalColumns: DatatableColumn[];

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef,
		private documentService: NcDocumentsService
	) {
		super();

		this.formGroup = this.formBuilder.group({
			id: ['', null],
			absenceId: ['', null],
			absenceCode: ['', null],
			settingsId: ['', null],
			day: ['', null],
			note: ['', null],
			title: ['', null],
			documents: this.formBuilder.array([]),
		});
		this.canDeactivateMessage = 'canLeaveMilestonePage';

		this.documents = this.formGroup.get('documents') as FormArray;

		this.route.params.subscribe((params) => {
			this.store.dispatch(TaskPageAction.getCurrentAbsence({ code: params.code }));
			if (params.id) {
				this.store.dispatch(TaskPageAction.getDoneMilestone({ milestoneId: params.id }));
			}
			if (params.code && params.milestoneSettingsId) {
				this.store.dispatch(TaskPageAction.getOpenMilestone({ code: params.code, milestoneSettingsId: params.milestoneSettingsId }));
			}
		});

		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));
	}

	ngOnInit(): void {
		this.milestone$ = this.store.select(TaskSelect.getMilestone);
		this.store
			.select(TaskSelect.getDocumentsForCheckList)
			.pipe(filter((documents) => documents.length > 0))
			.subscribe((documents) => {
				this.documentCheckList = documents;
				const documentFormArray = this.formGroup.get('documents') as FormArray;
				this.documentService.populateData(documentFormArray, documents, true);
			});

		this.milestone$
			.pipe(
				filter((milestone) => milestone !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((milestone) => {
				this.store.dispatch(TaskPageAction.getDocumentsForCheckList({ absenceId: milestone.absenceId, settingsId: milestone.settingsId }));
				this.displayMilestone(milestone);
			});

		this.additionalColumns = [ColumnBuilder.create('uploadedBy', 'uploadedBy'), ColumnBuilder.create('uploadedOn', 'uploadedOn')];
	}

	dispatchSubmitAction = (): void => {
		if (this.formGroup.get('id').value === 0) {
			const milestone = this.formGroup.getRawValue() as MilestoneModel;
			this.store.dispatch(TaskPageAction.doneMilestone({ milestone }));
		} else {
			const milestoneId = this.formGroup.get('id').value;
			this.store.dispatch(TaskPageAction.undoneMilestone({ milestoneId }));
		}
	};

	displayMilestone = (milestone: MilestoneModel): void => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			id: milestone.id,
			absenceId: milestone.absenceId,
			absenceCode: milestone.absenceCode,
			settingsId: milestone.settingsId,
			day: milestone.day,
			note: milestone.note,
			title: milestone.title,
		});
		milestone.id === 0 ? (this.saveButtonLabel = 'done') : (this.saveButtonLabel = 'undone');
		this.formGroup.markAsDirty();
	};

	onSubmit = (): void => {
		this.isSubmitted = true;
		this.dispatchSubmitAction();
		this.store.dispatch(TaskPageAction.isDoneMilestoneButtonClicked({ isClicked: true }));
	};
}
