import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';

const OK = null;
const PASSWORDS_NOT_EQUAL = { passwordsNotEqual: true };
const PASSWORDS_ARE_EQUAL = { passwordsAreEqual: true };

/**
 * Checks if passwords are equal on reset password form
 */
const arePasswordsEqual = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const passwordControl = control.get('newPassword');
		const confirmationPasswordControl = control.get('confirmationPassword');

		if (passwordControl.value !== confirmationPasswordControl.value) {
			FormValidation.addError(confirmationPasswordControl, PASSWORDS_NOT_EQUAL);
		} else {
			FormValidation.removeError(confirmationPasswordControl, PASSWORDS_NOT_EQUAL);
		}

		return OK;
	};
};

const areNewAndOldPasswordsEqual = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const passwordControl = control.get('newPassword');
		const oldPasswordControl = control.get('oldPassword');

		if (passwordControl.value === oldPasswordControl.value) {
			FormValidation.addError(passwordControl, PASSWORDS_ARE_EQUAL);
		} else {
			FormValidation.removeError(passwordControl, PASSWORDS_ARE_EQUAL);
		}

		return OK;
	};
};

export { arePasswordsEqual, areNewAndOldPasswordsEqual };
