<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div class="footer-navigation" fxLayout="row wrap" fxLayoutAlign="space-between">
		<nc-button id="back" (click)="onBack()" [isRaised]="false" label="back"></nc-button>
		<div fxLayout="end" *ngIf="isHrUser && showRegisterOrganizationButton">
			<nc-button id="register-company" (click)="onRegisterOrganization()" label="companyRegistration"> </nc-button>
		</div>
		<div fxLayout="end" *ngIf="!isHrUser">
			<nc-submit-button *ngIf="showRefreshButton" id="refresh" (buttonClick)="onRefresh()" dialogLabel="processing" buttonLabel="refresh">
			</nc-submit-button>
			<nc-submit-button id="save" (buttonClick)="onSave()" dialogLabel="processing" [formGroup]="formGroup" buttonLabel="save">
			</nc-submit-button>
		</div>
	</div>
</div>
