<form fxLayout="column" fxFill [formGroup]="formGroup">
	<ea-header-info [code]="code" [showCode]="isShareInformation"></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<ea-container [title]="'annexDetails'">
					<nc-datepicker
						[id]="'created-on'"
						[formGroup]="formGroup"
						formControlName="createdOn"
						label="createdOn"
						[isReadonly]="true"
					></nc-datepicker>
					<nc-input
						[id]="'created-by'"
						[formGroup]="formGroup"
						formControlName="createdBy"
						label="createdBy"
						[isReadonly]="true"
					></nc-input>
					<ng-container *ngIf="!isShareInformation">
						<nc-datepicker
							[id]="'valid-from'"
							[formGroup]="formGroup"
							formControlName="validFrom"
							label="validFrom"
							[isReadonly]="true"
						></nc-datepicker>
						<nc-datepicker
							[id]="'valid-to'"
							[formGroup]="formGroup"
							formControlName="validTo"
							label="validTo"
							[isReadonly]="true"
						></nc-datepicker>
						<nc-input
							[id]="'incapacity-to-work'"
							[formGroup]="formGroup"
							formControlName="incapacityToWork"
							label="incapacityToWork"
							[isReadonly]="true"
						></nc-input>
					</ng-container>
					<nc-textarea [id]="'comment'" [formGroup]="formGroup" formControlName="comment" label="comment" [isReadonly]="true"></nc-textarea>
					<div fxLayoutAlign="flex-end">
						<nc-button
							[onClick]="resendConfirmationEmail"
							*ngIf="!isHrUser"
							[id]="'resend-confirmation-email'"
							[disabled]="!hasSavedConfirmationEmail"
							matTooltip="{{ hasSavedConfirmationEmail ? null : ('caseConfirmationEmailUnavailable' | translate) }}"
							label="resendAbsenceConfirmationMail"
						></nc-button>
					</div>
					<ea-documents
						[id]="'existing-absence-document'"
						[buttonId]="'open-dialog'"
						[downloadButtonId]="'download-documents-button'"
						[documentsFormArray]="documents"
						[additionalColumns]="additionalColumns"
						[isReadonly]="true"
					></ea-documents>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [displaySaveButton]="false"></ea-footer>
</form>
