import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { TaskPageAction, TaskSelect } from '../../state/action';
import { ActivatedRoute } from '@angular/router';
import { SettingsPageAction } from '../../../state/settings/action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './task.component.html',
	styleUrls: ['./task.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskComponent extends BaseComponent implements OnInit {
	absenceCode: string;
	isMobile: boolean;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;
	taskColumns: DatatableColumn[];
	noteColumns: DatatableColumn[];
	milestoneColumns: DatatableColumn[];
	openTasks$: Observable<TableData>;
	doneTasks$: Observable<TableData>;
	openMilestones$: Observable<TableData>;
	doneMilestones$: Observable<TableData>;
	notes$: Observable<TableData>;
	numberOfDays$: Observable<Option[]>;
	filterConfigOpenMilestoneTable: FilterConfig;
	filterConfigOpenTasksTable: FilterConfig;
	filterConfigDoneMilestoneTable: FilterConfig;
	filterConfigDoneTasksTable: FilterConfig;
	filterConfigNotesTable: FilterConfig;

	constructor(private store: Store, private route: ActivatedRoute, breakpointObserver: BreakpointObserver, private formBuilder: FormBuilder) {
		super();
		this.route.params.subscribe((params) => {
			if (params.code) {
				this.absenceCode = params.code;
				this.store.dispatch(TaskPageAction.getOpenTasks({ code: this.absenceCode }));
				this.store.dispatch(TaskPageAction.getDoneTasks({ code: this.absenceCode }));
				this.store.dispatch(TaskPageAction.getNotes({ code: this.absenceCode }));
				this.store.dispatch(TaskPageAction.getCurrentAbsence({ code: this.absenceCode }));
			}
		});

		this.formGroup = formBuilder.group({
			numberOfDays: [[]],
		});

		this.store.dispatch(TaskPageAction.getNumberOfDays());
		this.numberOfDays$ = store.select(TaskSelect.getNumberOfDays);
		breakpointObserver.observe(['(max-width: 900px)']).subscribe((result) => (this.isMobile = result.matches));

		this.filterConfigOpenMilestoneTable = {
			showFilter: true,
			showGlobalFilter: true,
			filterValues: {},
		};

		this.filterConfigOpenTasksTable = {
			showFilter: true,
			showGlobalFilter: true,
			filterValues: {},
		};

		this.filterConfigDoneMilestoneTable = {
			showFilter: true,
			showGlobalFilter: true,
			filterValues: {},
		};

		this.filterConfigDoneTasksTable = {
			showFilter: true,
			showGlobalFilter: true,
			filterValues: {},
		};

		this.filterConfigNotesTable = {
			showFilter: true,
			showGlobalFilter: true,
			filterValues: {},
		};
	}

	ngOnInit(): void {
		this.milestoneColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('title', 'title'),
			ColumnBuilder.create('note', 'note'),
			ColumnBuilder.create('documents', 'documents'),
			ColumnBuilder.createDate('dueDate', 'dueDate'),
		];

		this.taskColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('title', 'title'),
			ColumnBuilder.create('createdBy', 'user'),
			ColumnBuilder.create('note', 'note'),
			ColumnBuilder.create('status', 'hasStatus'),
		];

		this.noteColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('text', 'note'),
			ColumnBuilder.create('createdBy', 'user'),
			ColumnBuilder.create('type', 'noteType'),
			ColumnBuilder.create('documents', 'documents'),
			ColumnBuilder.createDate('createdOn', 'createdOn'),
		];

		this.openTasks$ = this.store.select(TaskSelect.getOpenTasks);
		this.doneTasks$ = this.store.select(TaskSelect.getDoneTasks);
		this.openMilestones$ = this.store.select(TaskSelect.getOpenMilestones);
		this.doneMilestones$ = this.store.select(TaskSelect.getDoneMilestones);
		this.notes$ = this.store.select(TaskSelect.getNotes);

		const numberOfDaysControl = this.formGroup.get('numberOfDays');
		this.store.select(TaskSelect.getNumberOfDays).subscribe((numberOfDays) => {
			numberOfDaysControl.patchValue(numberOfDays.map((x) => x.value));
		});

		numberOfDaysControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
			if (values.length !== 0) {
				this.store.dispatch(TaskPageAction.getOpenMilestones({ code: this.absenceCode, numberOfDays: values }));
				this.store.dispatch(TaskPageAction.getDoneMilestones({ code: this.absenceCode, numberOfDays: values }));
			}
		});
	}

	taskClicked = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${this.absenceCode}/tasks/${rowData.id}` }));
	};

	openMilestoneClicked = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${this.absenceCode}/open-milestone/${rowData.milestoneSettingsId}` }));
	};

	doneMilestoneClicked = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${this.absenceCode}/milestone/${rowData.id}` }));
	};

	noteClicked = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${this.absenceCode}/note/${rowData.id}` }));
	};
}
