import * as ApplicationState from '../../state/app.state';
import { NoteModel } from '../model/note.model';
import { TaskModel } from '../model/task.mode';
import { MilestoneModel } from '../model/milestone.model';
import { AbsenceContextModel } from '../../shared/model/absence-context.model';
import { Option } from 'nc-shared';
import { TableData } from 'nc-datatable';
import { DocumentModel } from 'nc-documents';

export interface TaskState {
	openMilestones: TableData;
	doneMilestones: TableData;
	openTasks: TableData;
	doneTasks: TableData;
	notes: TableData;
	note: NoteModel;
	task: TaskModel;
	milestone: MilestoneModel;
	currentAbsence: AbsenceContextModel;
	numberOfDays: Option[];
	documentsForCheckList: DocumentModel[];
	isDoneMilestoneButtonClicked: boolean;
}

export interface State extends ApplicationState.State {
	task: TaskState;
}

export const TASK_INITIAL_STATE: TaskState = {
	openMilestones: { records: [] },
	doneMilestones: { records: [] },
	openTasks: { records: [] },
	doneTasks: { records: [] },
	notes: { records: [] },
	note: null,
	task: null,
	milestone: null,
	currentAbsence: null,
	numberOfDays: [],
	documentsForCheckList: [],
	isDoneMilestoneButtonClicked: false,
};
