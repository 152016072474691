import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IncapacityService } from '../../../absence/service/incapacity.service';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { KleService } from '../../service/kle.service';
import { KleAbsencesService } from '../../service/absences.service';
import { KleAbsences } from '../../model/kle-absences';
import { KleSelect } from '../../state/action';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ea-absences',
	templateUrl: './absences.component.html',
	styleUrls: ['./absences.component.scss'],
})
export class AbsencesComponent extends BaseComponent implements OnInit {
	@Input() absenceCode: string;
	@Input() formGroup: FormGroup;
	@Input() isBranchHr: boolean;

	absences$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	absences: FormArray;
	absenceId: number;
	filterConfig: FilterConfig;
	columns: DatatableColumn[];

	mockUpData: any;

	absenceFormGroup = this.formBuilder.group({
		absences: this.formBuilder.array([]),
	});

	constructor(
		private store: Store,
		private incapacityService: IncapacityService,
		private formBuilder: FormBuilder,
		private kleService: KleService,
		private kleAbsenceService: KleAbsencesService
	) {
		super();
	}

	ngOnInit(): void {
		this.columns = this.getColumns();
		this.filterConfig = this.getFilterConfig();
		this.absences = this.absenceFormGroup.controls.absences as FormArray;

		this.mockUpData = this.kleService.getAbsences(this.absenceCode);

		this.kleAbsenceService.initialize(this.absences, this.absences$);
		this.kleAbsenceService.populateData(this.absences, this.mockUpData);

		this.formGroup.addControl('absenceStory', this.absenceFormGroup);

		this.store
			.select(KleSelect.getAbsences)
			.pipe(takeUntil(this.destroy$))
			.subscribe((absenceStory) => {
				this.absences.clear();
				absenceStory?.absences?.forEach((absence, index) =>
					this.absences.push(this.kleAbsenceService.createExistingFormGroup({ ...absence, tableId: index }))
				);
			});
	}

	openDialog(): void {
		this.kleAbsenceService.openDialog(null, this.absences, this.absenceFormGroup);
	}

	getColumns(): DatatableColumn[] {
		return [
			ColumnBuilder.createHidden('index'),
			ColumnBuilder.create('from', 'from'),
			ColumnBuilder.create('hours', 'hours'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];
	}

	getFilterConfig(): FilterConfig {
		return {
			showGlobalFilter: true,
		};
	}

	edit = (model: KleAbsences): void => {
		this.kleAbsenceService.openDialog(model, this.absences, this.absenceFormGroup);
	};

	private delete = (model: any): void => {
		const index = this.absences.getRawValue().findIndex((x) => x.tableId === model.tableId);
		this.absences.removeAt(index);
		this.absenceFormGroup.markAsDirty();
	};
}
