import { Component, Inject, Input, OnInit } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent, LabelWithParameters } from 'nc-shared';
import { TreatmentsService } from '../../../service/treatments.service';
import { TranslateService } from '@ngx-translate/core';
import { Treatment } from '../../../model/treatment';
import { Store } from '@ngrx/store';
import { KleSelect } from '../../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { AbsenceService } from '../../../../absence/service/absence.service';
import { Treatments } from '../../../model/treatments';
import { UserSelect } from '../../../../user/state/action';
import { PermissionEnumeration } from '../../../../shared/enumeration/permission.enumeration';
import { KleFormGroupEnum } from '../../../model/enums/kle-form-group.enum';

@Component({
	selector: 'ea-treatments-table',
	templateUrl: './treatments-table.component.html',
	styleUrls: ['./treatments-table.component.scss'],
})
export class TreatmentsTableComponent extends BaseComponent implements OnInit {
	@Input() isReadonly = false;
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;
	treatmentsGroup: FormGroup;
	@Input() formArray: FormArray;
	isHr: boolean;
	@Input() isBranchHr: boolean;

	filterConfig: FilterConfig = {
		showGlobalFilter: true,
		filterValues: {},
	};

	data$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });

	constructor(
		private store: Store,
		private translateService: TranslateService,
		private treatmentsService: TreatmentsService,
		private formBuilder: FormBuilder,
		private absenceService: AbsenceService,
		@Inject('ValidationErrorMap') private errorMap: any
	) {
		super();
	}

	ngOnInit(): void {
		this.formArray = this.formBuilder.array([], Validators.required);

		this.treatmentsGroup = this.formBuilder.group({
			id: null as number,
			absenceCode: this.absenceCode,
			details: this.formArray,
		});

		this.formGroup.addControl(KleFormGroupEnum.TREATMENTS, this.treatmentsGroup);

		if (this.formGroup.controls.incidentContext != null) {
			this.store
				.select(KleSelect.getTreatments)
				.pipe(
					switchMap((treatments) => {
						return treatments == null ? this.absenceService.getTreatments(this.absenceCode) : of(treatments);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe((treatments) => this.displayTreatments(treatments));
		} else {
			this.absenceService
				.getTreatments(this.absenceCode)
				.pipe(filter((treatments) => treatments != null))
				.subscribe((treatments) => this.displayTreatments(treatments));
		}

		this.store
			.select(UserSelect.getPermissions)
			.pipe(takeUntil(this.destroy$))
			.subscribe((permissions) => {
				this.isHr = permissions.includes(PermissionEnumeration.HR);
			});

		this.formArray.valueChanges.subscribe(() => {
			const tableData = { records: [] };

			for (let i = 0; i < this.formArray.length; i++) {
				const formGroup = this.formArray.at(i) as FormGroup;
				const rawValue = { no: i === 0 ? 'inital' : i + 1, ...formGroup.getRawValue() };
				tableData.records.push(rawValue);
			}
			this.data$.next(tableData);
		});
	}

	displayTreatments(treatments: Treatments): void {
		this.treatmentsGroup.controls.id.patchValue(treatments?.id);
		this.formArray.clear();
		treatments?.details?.forEach((treatment, index) =>
			this.formArray.push(
				this.treatmentsService.createExistingFormGroup({
					...treatment,
					tableId: index,
				})
			)
		);
	}

	openDialog = (): void => {
		this.treatmentsService.openDialog(null, this.formArray, this.treatmentsGroup);
	};

	edit = (model: Treatment): void => {
		this.treatmentsService.openDialog(model, this.formArray, this.treatmentsGroup);
	};

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('no', '#'),
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('treatmentInstitution', 'medicalInstitution'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];
	}

	private delete = (model: any): void => {
		const index = this.formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
		this.formArray.removeAt(index);
		this.treatmentsGroup.markAsDirty();
	};

	isInvalid(): boolean {
		let isInvalid = false;
		const errors = this.formArray.errors;
		if (errors) {
			const errorKeys = Object.keys(errors);
			isInvalid = errorKeys.length > 0;
		}

		for (let i = 0; i < this.formArray.length; i++) {
			isInvalid ||= this.formArray.at(i).invalid;
		}

		return isInvalid;
	}

	getErrorMessage(): LabelWithParameters {
		if (!this.isInvalid()) {
			return null;
		}

		let errorLabel: LabelWithParameters = null;
		const errors = this.formArray.errors;

		if (errors) {
			const key = Object.keys(errors)[0];
			const params = errors[key];

			const error = this.errorMap[key];
			if (error) {
				const label = error.label;
				const value = error.value(params);

				errorLabel = { label, parameters: value };
			} else {
				errorLabel = { label: key, parameters: params };
			}
		}
		return errorLabel;
	}

	fetchFromAbsence() {
		this.absenceService.getTreatments(this.absenceCode).subscribe((accidentDescription) => this.displayTreatments(accidentDescription));
	}
}
