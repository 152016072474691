import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'nc-shared';
import { filter, takeUntil } from 'rxjs';
import { DialogMessage } from '../../../model/dialog-message';
import { KlePageAction, KleSelect } from '../../../state/action';
import { GenericFormData } from 'nc-form-generator';
import { FreeDialogMessageService } from '../../../service/dialog-message/free-dialog-message.service';

@Component({
	selector: 'free-form-dialog',
	templateUrl: './free-form-dialog.component.html',
	styleUrls: ['./free-form-dialog.component.scss'],
})
export class FreeFormDialogComponent extends BaseComponent implements OnInit {
	formData: GenericFormData;
	dialogMessage: DialogMessage;
	isReadonly: boolean;
	formName: string;

	constructor(
		private store: Store,
		private freeDialogMessageService: FreeDialogMessageService,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			absenceCode: string;
			storyId: string;
			formGroup: FormGroup;
			creator: string;
		}
	) {
		super();
	}

	ngOnInit(): void {
		this.formName = this.data.storyId;

		if (this.data.storyId != null) {
			this.store.dispatch(
				KlePageAction.getDialogMessage({
					absenceCode: this.data.absenceCode,
					storyId: this.data.storyId,
				})
			);
		}

		this.store
			.select(KleSelect.getDialogMessage)
			.pipe(
				takeUntil(this.destroy$),
				filter((message: DialogMessage) => message != null)
			)
			.subscribe((message: DialogMessage) => {
				this.dialogMessage = { ...message, companyCaseId: this.data.absenceCode };
				this.formData = this.freeDialogMessageService.getFormData(this.dialogMessage);
			});
	}

	submit(): void {
		if (this.data.formGroup.controls[this.formName].valid) {
			const data = this.data.formGroup.getRawValue();
			const request = {
				incidentContext: data.incidentContext,
				dialogMessage: {
					...this.dialogMessage,
					companyCaseId: this.data.absenceCode,
					previous: this.data.storyId,
					freeDialogMessage: {
						paragraphs: this.freeDialogMessageService.applyFormValuesToDialogMessageData(
							this.dialogMessage.freeDialogMessage.paragraphs,
							data[this.formName]
						),
					},
				},
			};
			this.store.dispatch(KlePageAction.synchronizeIncident({ request }));
		}
	}
}
