import { ActivatedRouteSnapshot, CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { CanDeactivateComponent } from 'nc-shared/lib/component/can-deactivate-component';
import { DialogService } from 'nc-utils';
import { MilestoneFormGuardDialogComponent } from '../../task/component/milestone-form-guard-dialog/milestone-form-guard-dialog.component';
import { TaskPageAction, TaskSelect } from '../../task/state/action';

@Injectable({
	providedIn: 'root',
})
export class CanLeaveMilestonePageGuard implements CanDeactivate<CanDeactivateComponent> {
	isDoneMilestoneButtonClicked: boolean;

	constructor(private dialogService: DialogService, private router: Router, private store: Store<State>) {
		this.store.select(TaskSelect.getIsDoneMilestoneButtonClicked).subscribe((value) => (this.isDoneMilestoneButtonClicked = value));
	}

	canDeactivate(component: CanDeactivateComponent, currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
		let returnValue;

		if (!this.isDoneMilestoneButtonClicked) {
			returnValue = this.dialogService.open(MilestoneFormGuardDialogComponent).afterClosed();
		} else {
			returnValue = of(true);
			this.store.dispatch(TaskPageAction.isDoneMilestoneButtonClicked({ isClicked: false }));
		}

		return returnValue;
	}
}
