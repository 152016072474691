import { EnumItem } from 'nc-utils';

export const BodyPartTypeEnum = {
	ABDOMEN_459: new EnumItem('ABDOMEN_459', 'abdomen459'),
	BLADDER_669: new EnumItem('BLADDER_669', 'bladder669'),
	THORACIC_SPINE_259: new EnumItem('THORACIC_SPINE_259', 'thoracicSpine259'),
	ENTIRE_BODY_SYSTEMIC_EFFECT_819: new EnumItem('ENTIRE_BODY_SYSTEMIC_EFFECT_819', 'entireBodySystemicEffect819'),
	GENITALS_449: new EnumItem('GENITALS_449', 'genitals449'),
	NECK_209: new EnumItem('NECK_209', 'neck209'),
	CERVICAL_SPINE_249: new EnumItem('CERVICAL_SPINE_249', 'cervicalSpine249'),
	HEART_619: new EnumItem('HEART_619', 'heart619'),
	OTHER_INTERNAL_INJURIES_609: new EnumItem('OTHER_INTERNAL_INJURIES_609', 'otherInternalInjuries609'),
	LIVER_639: new EnumItem('LIVER_639', 'liver639'),
	LUMBAR_SPINE_269: new EnumItem('LUMBAR_SPINE_269', 'lumbarSpine269'),
	SPLEEN_649: new EnumItem('SPLEEN_649', 'spleen649'),
	NOSE_139: new EnumItem('NOSE_139', 'nose139'),
	MULTIPLE_INJURIES_709: new EnumItem('MULTIPLE_INJURIES_709', 'multipleInjuries709'),
	SKULL_BRAIN_109: new EnumItem('SKULL_BRAIN_109', 'skullBrain109'),
	SHOCK_PSYCH_809: new EnumItem('SHOCK_PSYCH_809', 'shockPsych809'),
	COCCYX_BUTTOCKS_429: new EnumItem('COCCYX_BUTTOCKS_429', 'coccyxButtocks429'),
};
