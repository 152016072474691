import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
	isAbortedByCaseManagementCompanyDateRequired,
	isClosedByCaseManagementCompanyDateRequired,
	isIncapacityRequired,
	isSentToCaseManagementCompanyDateRequired,
	validateCaseManagementDetails,
	validatePermanentEmployment,
	validateTemporaryEmployment,
} from '../validator/absence.validator';
import { AbsenceTypeEnumeration } from '../../shared/enumeration/absence-type.enumeration';

@Injectable({
	providedIn: 'root',
})
export class AbsenceFormBuilder {
	constructor(private formBuilder: FormBuilder) {}

	/**
	 * Builds form for new absence
	 */
	buildNew = (): FormGroup => {
		return this.formBuilder.group(
			{
				documents: this.formBuilder.array([]),
				incapacities: this.formBuilder.array([]),

				companyId: [null, [Validators.required]],
				refEmployee: [null, [Validators.required]],
				isKleEnabled: [null as boolean],

				hasDailySicknessBenefitsInsurance: ['no'],
				dailySicknessBenefitsInsuranceCompany: [''],
				dailySicknessBenefitsInsuranceSinisterReference: [''],

				hasAccidentInsurance: ['no'],
				accidentInsuranceCompany: [''],
				accidentInsuranceSinisterReference: [''],

				hasChildrenUnder18OrInEducationUpTo25: ['no'],
				numberOfChildren: ['0'],

				hasReturnedToWork: ['no'],
				numberOfPossibleWorkHours: ['0'],

				absenceType: [AbsenceTypeEnumeration.SICKNESS.code],
				absenceSubType: ['UNKNOWN'],
				pregnancyDueDate: [null],
				comment: [''],

				absenceCaseType: ['NEW'],
				categoryType: ['REGULAR'],

				employmentStartDate: [null],
				employmentEndDate: [null],
				employmentPercentage: [''],
				employmentAnnualSalary: [''],

				firstDeploymentDate: [null],
				firstDeploymentRegistration: ['REGISTRATION_FROM_1ST_DAY'],
				firstDeploymentSubject: ['SUBJECT_TO_DUTY_OF_MAINTENANCE'],
				lastDeploymentDate: [null],

				medicalDiagnosisCode: [''],
				medicalDiagnosisAdditionalCode: [''],

				responsibleUserId: [''],
				responsibleLDUserId: [''],

				isSentToCaseManagementCompany: ['no'],
				sentToCaseManagementCompanyDate: [null],
				isClosedByCaseManagementCompany: ['no'],
				closedByCaseManagementCompanyDate: [null],
				isAbortedByCaseManagementCompany: ['no'],
				abortedByCaseManagementCompanyDate: [null],

				isSwicaInsured: ['no'],
				isPkproInsured: ['no'],
				companyContractType: [null],
			},
			{
				validators: [
					isIncapacityRequired(),
					isSentToCaseManagementCompanyDateRequired(),
					isClosedByCaseManagementCompanyDateRequired(),
					isAbortedByCaseManagementCompanyDateRequired(),
					validatePermanentEmployment(),
					validateTemporaryEmployment(),
				],
			}
		);
	};

	/**
	 * Builds form for existing absence
	 */
	buildExisting = (): FormGroup => {
		return this.formBuilder.group(
			{
				id: [null],
				code: [null],
				documents: this.formBuilder.array([]),
				incapacities: this.formBuilder.array([]),
				company: [null],
				isKleEnabled: [null as boolean],
				employee: [null],
				companyId: [null],
				refEmployee: [null],
				categoryType: [null],
				absenceType: [null],
				absenceSubType: [null],
				pregnancyDueDate: [null],
				createdOn: [null],
				createdBy: [null],
				isArchived: [null],
				isClosed: [null],
				comment: [''],
				statusByMilestone: [null],
				status: [null],
				medicalDiagnosisCode: [null],
				medicalDiagnosisAdditionalCode: [null],
				medicalDiagnosisValue: [null],
				medicalDiagnosisAdditionalValue: [null],
				responsibleUserId: [null],
				responsibleUserName: [null],
				responsibleLDUserId: [null],
				responsibleLDUserName: [null],
				archivedReason: [null],
				archivedComment: [''],
				isCaseManagementActive: [null],
				isSwicaInsured: [null],
				isPkproInsured: [null],
				isSentToCaseManagementCompany: [null],
				sentToCaseManagementCompanyDate: [null],
				isClosedByCaseManagementCompany: [null],
				closedByCaseManagementCompanyDate: [null],
				isAbortedByCaseManagementCompany: [null],
				abortedByCaseManagementCompanyDate: [null],
				caseManagementKTGInsurer: [null],
				caseManagementUVGInsurer: [null],
				caseManagementBranchOffice: [null],
				caseManagementBranchOfficeContactPerson: [null],
				caseManagementBranchOfficePhoneNumber: [null],
				caseManagementStartedOnDate: [null],
				caseManagementAssessmentByExpertOnDate: [null],
				caseManagementReassessmentByExpertOnDate: [null],
				caseManagementIntegrationReport: [null],
				caseManagementAssessmentReport: [null],
				caseManagementReassessmentReport: [null],
				caseManagementInterventionStatus: [null],
				caseManagementAccomplishment: [null],
				caseManagementEndedOnDate: [null],
				caseManagementSustainabilityCheckedOnDate: [null],
				caseManagementSuccessfulnessType: [null],
				hasRiskAssessment: [null],
				pkProInsuranceEntryDate: [null],
				pkProInsuranceExitDate: [null],

				hasDailySicknessBenefitsInsurance: [null],
				dailySicknessBenefitsInsuranceCompany: [null],
				dailySicknessBenefitsInsuranceSinisterReference: [null],

				hasAccidentInsurance: [null],
				accidentInsuranceCompany: [null],
				accidentInsuranceSinisterReference: [null],

				hasChildrenUnder18OrInEducationUpTo25: [null],
				numberOfChildren: [null],

				hasReturnedToWork: [null],
				numberOfPossibleWorkHours: [null],

				employmentStartDate: [null],
				employmentEndDate: [null],
				employmentPercentage: [null],
				employmentAnnualSalary: [null],

				firstDeploymentDate: [null],
				firstDeploymentRegistration: [null],
				firstDeploymentSubject: [null],
				lastDeploymentDate: [null],
				isRecoverageActive: ['no'],
				companyContractType: [null],
			},
			{
				validators: [
					isIncapacityRequired(),
					isSentToCaseManagementCompanyDateRequired(),
					isClosedByCaseManagementCompanyDateRequired(),
					isAbortedByCaseManagementCompanyDateRequired(),
					validatePermanentEmployment(),
					validateTemporaryEmployment(),
					validateCaseManagementDetails(),
				],
			}
		);
	};
}
