import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskState } from '../task.state';

export const TASK_FEATURE_STATE_NAME = 'task';
export const TASK_FEATURE_STATE = createFeatureSelector<TaskState>(TASK_FEATURE_STATE_NAME);

export const getOpenTasks = createSelector(TASK_FEATURE_STATE, (state) => state.openTasks);
export const getDoneTasks = createSelector(TASK_FEATURE_STATE, (state) => state.doneTasks);
export const getOpenMilestones = createSelector(TASK_FEATURE_STATE, (state) => state.openMilestones);
export const getDoneMilestones = createSelector(TASK_FEATURE_STATE, (state) => state.doneMilestones);
export const getNotes = createSelector(TASK_FEATURE_STATE, (state) => state.notes);
export const getNote = createSelector(TASK_FEATURE_STATE, (state) => state.note);
export const getTask = createSelector(TASK_FEATURE_STATE, (state) => state.task);
export const getMilestone = createSelector(TASK_FEATURE_STATE, (state) => state.milestone);
export const getCurrentAbsence = createSelector(TASK_FEATURE_STATE, (state) => state.currentAbsence);
export const getCurrentAbsenceCode = createSelector(TASK_FEATURE_STATE, (state) => state.currentAbsence?.absenceCode);
export const getNumberOfDays = createSelector(TASK_FEATURE_STATE, (state) => state.numberOfDays);
export const getDocumentsForCheckList = createSelector(TASK_FEATURE_STATE, (state) => state.documentsForCheckList);
export const getIsDoneMilestoneButtonClicked = createSelector(TASK_FEATURE_STATE, (state) => state.isDoneMilestoneButtonClicked);
