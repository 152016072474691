import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SettingsApiAction, SettingsPageAction } from './action';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ErrorAction } from '../error/action';
import { DocumentService } from '../../shared/service/document.service';
import { FileUtils } from '../../shared/utils/file.utils';
import { DeleteConfirmationDialogComponent } from '../../shared/component/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DialogService } from 'nc-utils';
import { Store } from '@ngrx/store';

@Injectable()
export class SettingsEffect {
	constructor(
		private router: Router,
		private actions$: Actions,
		private documentService: DocumentService,
		private dialogService: DialogService,
		private store: Store
	) {}

	openMenuItemEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SettingsPageAction.openMenuItem),
			map((action) => {
				this.router.navigateByUrl(action.menuItem);
				return ErrorAction.clearErrors();
			})
		);
	});

	setMenuItemEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SettingsPageAction.setMenuItem),
			map(() => ErrorAction.clearErrors())
		);
	});

	navigateEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(SettingsPageAction.navigate),
				tap((action) => this.router.navigateByUrl(action.url))
			);
		},
		{ dispatch: false }
	);

	downloadDocumentForPreviewEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SettingsPageAction.downloadDocumentForPreview),
			switchMap((action) => this.documentService.downloadDocument(action.id)),
			map((response) => SettingsApiAction.downloadDocumentSuccess({ document: response.body }))
		);
	});

	downloadDocumentEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(SettingsPageAction.downloadDocument),
				switchMap((action) => this.documentService.downloadDocument(action.id)),
				tap((response) => FileUtils.download(response))
			);
		},
		{ dispatch: false }
	);

	downloadMultipleDocumentsEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(SettingsPageAction.downloadMultipleDocuments),
				switchMap((action) => this.documentService.downloadMultipleDocuments(action.documentIds)),
				tap((response) => FileUtils.download(response))
			);
		},
		{ dispatch: false }
	);

	openConfirmDeleteDialogEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SettingsPageAction.openDeleteDocumentConfirmationDialog),
			switchMap((action) => {
				const dialogConfig = {
					disableClose: true,
					data: {
						id: action.document.id,
						title: 'delete',
						message1: 'deleteDocumentDialog',
						message2: ' ',
						deleteEntityName: action.document.nameWithExtension,
					},
				};
				return this.dialogService.open(DeleteConfirmationDialogComponent, dialogConfig).afterClosed();
			}),
			map((dialogResult) => {
				return dialogResult.success ? SettingsPageAction.deleteDocument({ documentId: dialogResult.id }) : SettingsPageAction.closeDialog();
			})
		);
	});

	deleteDocumentSettingsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(SettingsPageAction.deleteDocument),
			mergeMap((action) => this.documentService.deleteDocument(action.documentId)),
			map((result) => SettingsApiAction.deleteDocumentSuccess({ documentId: result.deleted[0] }))
		);
	});

	closeDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(SettingsPageAction.closeDialog),
				tap(() => this.dialogService.closeAll())
			);
		},
		{ dispatch: false }
	);
}
