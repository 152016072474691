<div id="sidebar" fxLayout="column">
	<div id="sidebar-header" fxLayout="column">
		<img class="logo-image" src="{{ logo }}" />
		<div id="full-name-container">
			<div *ngIf="activeProfile" class="activeProfile">
				<span
					><b>{{ activeProfile }}</b></span
				>
			</div>
			{{ fullName }}
			<div *ngIf="user.permissions.includes('HR') && user.companies.length === 1" class="authority">
				<a href="/company/{{ user.companies[0].id }}">{{ user.companies[0].name }}</a>
			</div>
		</div>
	</div>
	<div id="sidebar-content" fxFlex fxLayout="column">
		<cdk-virtual-scroll-viewport [itemSize]="100" fxFlex>
			<ng-container *ngFor="let menuItem of filteredMenuItems">
				<ea-menu-item
					*ngIf="!menuItem.hasChildren"
					[url]="menuItem.url"
					[icon]="menuItem.icon"
					[label]="menuItem.label"
					[action]="menuItem.action"
					[infoTooltip]="menuItem.infoTooltip"
				>
				</ea-menu-item>
				<ea-sub-menu *ngIf="menuItem.hasChildren" [icon]="menuItem.icon" [label]="menuItem.label" [children]="menuItem.children">
				</ea-sub-menu>
			</ng-container>
		</cdk-virtual-scroll-viewport>
	</div>
	<div *ngIf="user.permissions.includes('HR')" id="user-info">
		<div *ngIf="currentTheme === 'ecare-theme'">
			<div>{{ 'enovetic' | translate }}</div>
			<div>{{ 'company.branch.rotkreuz.address' | translate }}</div>
			<div>{{ 'company.branch.rotkreuz.postCode' | translate }}</div>
			<br />
			<div>
				{{ 'phone' | translate }}: <a href="tel:{{ 'hr.sidemenu.footer.phone' | translate }}">{{ 'hr.sidemenu.footer.phone' | translate }}</a>
			</div>
			<div>
				<a target="_blank" class="login-footer-text" href="{{ 'helpdeskEAbsenceURL' | translate }}">{{ 'helpdeskEAbsence' | translate }}</a>
			</div>
			<div *ngIf="currentTheme === 'tellco-theme'">
				<div>{{ 'tellcoCompanyName' | translate }}</div>
				<div>{{ 'AbsenceTemplate_tellcoStreet' | translate }}</div>
				<div>{{ 'AbsenceTemplate_tellcoZipCode' | translate }}</div>
				<div>{{ 'tellco.canton' | translate }}</div>
				<br />
				<div>
					<a href="tel:"> {{ 'tellco.phone' | translate }}</a>
				</div>
				<div>
					<a target="_blank" class="login-footer-text" href="{{ 'helpdeskEAbsenceURL' | translate }}">{{
						'helpdeskEAbsence' | translate
					}}</a>
				</div>
			</div>
			<div *ngIf="currentTheme === 'ecare-theme'" id="sidebar-footer">
				{{ 'serviceForCustomersOf_pkPRO' | translate }}
			</div>
		</div>
	</div>
</div>
