import { Component, Inject, Input, OnInit } from '@angular/core';
import { BaseComponent, LabelWithParameters, Option } from 'nc-shared';
import { AbstractControlOptions, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiDateTimeFormat, EnumUtils, parseDate, StringsUtils } from 'nc-utils';
import { ClassOfInsuranceTypeEnum, ClassOfInsuranceTypeEnumItems } from '../../../model/enums/class-of-insurance-type.enum';
import { FlagWithUnknownEnumeration, FlagWithUnknownEnumerationConst } from '../../../../shared/enumeration/flag-with-unknown.enumeration';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Store } from '@ngrx/store';
import { AccidentDescriptionService } from '../../../service/accident-description.service';
import { AccidentDescriptionOptionsEnum, AccidentDescriptionOptionsEnumItems } from '../../../model/enums/accident-description-options.enum';
import { ActivityAtTimeOfAccidentConst } from '../../../model/enums/activity-at-time-of-accident.enum';
import { KleSelect } from '../../../state/action';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { AccidentDescription } from '../../../model/accident-description';
import { KleFormGroupEnum } from '../../../model/enums/kle-form-group.enum';
import {
	accidentDateValidator,
	dateOfDeathValidator,
	exactApproxDateTimeValidator,
	injuriesValidator,
	lastWorkingDayValidator,
	leisureAccidentValidator,
	relapseDateValidator,
} from '../../../validator/accident-description.validator';
import { PositionPartTypeEnum } from '../../../model/enums/position-part-type.enum';
import { TypeOfInjuryEnum } from '../../../model/enums/type-of-injury.enum';
import { BodyPartInjury } from '../../../model/body-part-injury';
import { DeclareTypeEnumeration } from '../../../model/declare-type.enumeration';
import { AbsenceService } from '../../../../absence/service/absence.service';
import { BodyOrientedPartTypeEnum } from '../../../model/enums/body-oriented-part-type.enum';
import { BodyPartTypeEnum } from '../../../model/enums/body-part-type.enum';
import { UserSelect } from '../../../../user/state/action';
import { PermissionEnumeration } from '../../../../shared/enumeration/permission.enumeration';
import { TranslateEnumsUtils } from '../../../../shared/utils/translate-enums.utils';

@Component({
	selector: 'ea-accident-description-form',
	templateUrl: './accident-description-form.component.html',
	styleUrls: ['./accident-description-form.component.scss'],
})
export class AccidentDescriptionFormComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() relapseCaseId: string;
	@Input() absenceCode: string;
	classOfInsuranceTypeEnum: Option[];
	yesNoUnknownEnum: Option[];
	accidentDescriptionOptionsEnum: Option[];
	@Input() formArray: FormArray;
	accidentDescriptionGroup: FormGroup;
	filterConfig: FilterConfig;
	data$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	activityAtTimeOfAccidentEnum$: Observable<Option[]>;
	isHr: boolean;
	@Input() isBranchHr: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private store: Store,
		private route: ActivatedRoute,
		private absenceService: AbsenceService,
		private accidentDescriptionService: AccidentDescriptionService,
		private translateEnums: TranslateEnumsUtils,
		@Inject('ValidationErrorMap') private errorMap: any
	) {
		super();
	}

	ngOnInit(): void {
		this.formArray = this.formBuilder.array([]);
		this.accidentDescriptionGroup = this.formBuilder.group(
			{
				id: null as number,
				absenceCode: null as string,
				accidentDescriptionOptions: [AccidentDescriptionOptionsEnumItems.ACCIDENT.code],
				classOfInsuranceType: [ClassOfInsuranceTypeEnumItems.ACCIDENT_AT_WORK.code],
				caseOfDeathClassOfInsuranceType: [ClassOfInsuranceTypeEnumItems.ACCIDENT_AT_WORK.code],
				dateOfDeath: [''],
				insuranceCaseId: [''],
				relapseDate: [''],
				exactDateTime: [''],
				approximate: [''],
				incidentScene: ['', Validators.required],
				incidentDescription: ['', Validators.required],
				policeReportExists: [FlagWithUnknownEnumerationConst.UNKNOWN.code.toUpperCase()],
				existInvolvedPersons: [FlagWithUnknownEnumerationConst.UNKNOWN.code.toUpperCase()],
				involvedObject: [''],
				lastWorkingDay: [''],
				classOfInsuranceTypeDateOfDeath: [ClassOfInsuranceTypeEnumItems.ACCIDENT_AT_WORK.code],
				activityAtTheTimeOfAccident: [ActivityAtTimeOfAccidentConst.SKIING.code],
				lastWorkingDayDateOfDeath: [''],
				involvedObjectDateOfDeath: [''],
				activityAtTheTimeOfAccidentDateOfDeath: [ActivityAtTimeOfAccidentConst.SKIING.code],
				injuries: this.formArray,
			},
			{
				validators: [
					exactApproxDateTimeValidator,
					leisureAccidentValidator,
					lastWorkingDayValidator(),
					dateOfDeathValidator(),
					relapseDateValidator(),
					accidentDateValidator(),
					injuriesValidator(),
				],
			} as AbstractControlOptions
		);
		this.accidentDescriptionGroup.controls.absenceCode.patchValue(this.absenceCode);
		this.filterConfig = {
			showGlobalFilter: true,
			filterValues: {},
		};
		this.store
			.select(KleSelect.getIncident)
			.pipe(filter((incident) => incident != null))
			.subscribe((incident) => {
				if (incident.relapseCaseId) {
					const approximateOrExactDateOnRelapse = incident.approximateOrExactDateOnRelapse;
					if (incident.exact) {
						this.accidentDescriptionGroup.controls.exactDateTime.patchValue(
							parseDate(approximateOrExactDateOnRelapse, ApiDateTimeFormat)
						);
					} else {
						this.accidentDescriptionGroup.controls.approximate.patchValue(parseDate(approximateOrExactDateOnRelapse.split(' ')[0]));
					}
				} else {
					let accidentDate = incident.accidentDate;
					if (incident.exact) {
						this.accidentDescriptionGroup.controls.exactDateTime.patchValue(parseDate(accidentDate, ApiDateTimeFormat));
					} else {
						this.accidentDescriptionGroup.controls.approximate.patchValue(parseDate(accidentDate.split(' ')[0]));
					}
				}
			});
		this.classOfInsuranceTypeEnum = EnumUtils.toItems(ClassOfInsuranceTypeEnum);
		this.yesNoUnknownEnum = EnumUtils.toItems(FlagWithUnknownEnumeration);
		this.accidentDescriptionOptionsEnum = EnumUtils.toItems(AccidentDescriptionOptionsEnum);

		this.activityAtTimeOfAccidentEnum$ = of(this.translateEnums.getTranslatedValues(ActivityAtTimeOfAccidentConst));
		this.formGroup.addControl(KleFormGroupEnum.ACCIDENT_DESCRIPTION, this.accidentDescriptionGroup);

		this.formArray.valueChanges.subscribe(() => {
			const tableData = { records: [] };

			for (let i = 0; i < this.formArray.length; i++) {
				const formGroup = this.formArray.at(i) as FormGroup;
				const rawValue = {
					...formGroup.getRawValue(),
					position: formGroup.value.position ? PositionPartTypeEnum[formGroup.value.position].label : null,
					bodyPart: formGroup.value.part
						? BodyOrientedPartTypeEnum[formGroup.value.part]?.label
						: BodyPartTypeEnum[formGroup.value.unassignedPart]?.label,
					typeOfInjury: formGroup.value.typeOfInjury ? TypeOfInjuryEnum[formGroup.value.typeOfInjury].label : null,
				};
				tableData.records.push(rawValue);
			}
			this.data$.next(tableData);
		});

		this.store
			.select(UserSelect.getPermissions)
			.pipe(takeUntil(this.destroy$))
			.subscribe((permissions) => {
				this.isHr = permissions.includes(PermissionEnumeration.HR);
			});

		if (this.formGroup.controls.incidentContext != null) {
			this.store
				.select(KleSelect.getAccidentDescription)
				.pipe(
					switchMap((accidentDescription) => {
						return accidentDescription == null ? this.absenceService.getAccidentDescription(this.absenceCode) : of(accidentDescription);
					}),
					filter((accidentDescription) => accidentDescription != null),
					takeUntil(this.destroy$)
				)
				.subscribe((accidentDescription) => this.displayAccidentDescription(accidentDescription));
		} else {
			this.absenceService
				.getAccidentDescription(this.absenceCode)
				.pipe(filter((accidentDescription) => accidentDescription != null))
				.subscribe((accidentDescription) => this.displayAccidentDescription(accidentDescription));
		}

		this.accidentDescriptionOptionsValueChanges();

		this.accidentDateValueChanges();
	}

	showExactAproxDateTimeError() {
		return (
			this.accidentDescriptionGroup.errors?.exactApproxDateTimeError &&
			(this.accidentDescriptionGroup.controls.approximate.touched || this.accidentDescriptionGroup.controls.exactDateTime.touched)
		);
	}

	displayAccidentDescription(accidentDescription: AccidentDescription): void {
		this.accidentDescriptionGroup.reset();
		this.accidentDescriptionGroup.patchValue(accidentDescription);
		this.formArray.clear();

		for (let i = 0; i < accidentDescription.injuries.length; i++) {
			let injury = {
				id: accidentDescription.injuries[i].id,
				position: accidentDescription.injuries[i].position,
				part: accidentDescription.injuries[i].part,
				unassignedPart: accidentDescription.injuries[i].unassignedPart,
				typeOfInjury: accidentDescription.injuries[i].typeOfInjury,
				otherTypeOfInjury: accidentDescription.injuries[i].otherTypeOfInjury,
				tableId: i,
			};
			this.formArray.push(this.formBuilder.group(injury));
		}
	}

	openDialog = (): void => {
		this.accidentDescriptionService.openDialog(null, this.formArray, this.accidentDescriptionGroup);
	};

	editBodyPart = (model: BodyPartInjury): void => {
		this.accidentDescriptionService.openDialog(model, this.formArray, this.formGroup);
	};

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('bodyPart', 'bodyPartInjury'),
			ColumnBuilder.create('position', 'positionType'),
			ColumnBuilder.create('typeOfInjury', 'typeOfInjury'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];
	}

	private delete = (model: any): void => {
		const index = this.formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
		this.formArray.removeAt(index);
		this.accidentDescriptionGroup.markAsDirty();
	};

	getErrorMessage(): LabelWithParameters {
		if (!this.formArray.invalid) {
			return null;
		}

		let errorLabel: LabelWithParameters = null;
		const errors = this.formArray.errors;

		if (errors) {
			const key = Object.keys(errors)[0];
			const params = errors[key];

			const error = this.errorMap[key];
			if (error) {
				const label = error.label;
				const value = error.value(params);

				errorLabel = { label, parameters: value };
			} else {
				errorLabel = { label: key, parameters: params };
			}
		}
		return errorLabel;
	}

	accidentDescriptionOptionsValueChanges(): void {
		this.accidentDescriptionGroup.controls.accidentDescriptionOptions.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			if (value === DeclareTypeEnumeration.RELAPSE.code && StringsUtils.isNotEmpty(this.relapseCaseId)) {
				this.accidentDescriptionGroup.controls.insuranceCaseId.patchValue(this.relapseCaseId);
			} else {
				this.accidentDescriptionGroup.controls.insuranceCaseId.patchValue(null);
			}
		});
	}

	accidentDateValueChanges() {
		this.accidentDescriptionGroup.controls.approximate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			const annualSalaryGroup = this.formGroup.controls.annualSalary as FormGroup;
			if (annualSalaryGroup?.controls && value) {
				annualSalaryGroup.controls.accidentStartDate.patchValue(value);
			}
		});

		this.accidentDescriptionGroup.controls.exactDateTime.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			const annualSalaryGroup = this.formGroup.controls.annualSalary as FormGroup;
			if (annualSalaryGroup?.controls && value) {
				annualSalaryGroup.controls.accidentStartDate.patchValue(value);
			}
		});
	}

	fetchFromAbsence() {
		this.absenceService
			.getAccidentDescription(this.absenceCode)
			.subscribe((accidentDescription) => this.displayAccidentDescription(accidentDescription));
	}
}
