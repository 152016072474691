<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content>
			<div fxLayout="column" fxLayoutGap="10px">
				<nc-select
					[id]="'responsible-user-ld'"
					[searchTextId]="'responsible-user-ld-search'"
					[searchButtonId]="'responsible-user-ld-search-clear'"
					[clearIconId]="'responsible-user-ld-clear'"
					[formGroup]="formGroup"
					[data$]="userOptions$"
					placeholder="defaultSelectPleaceholder"
					label="responsibleLDUser"
					formControlName="responsibleLdUserId"
				>
				</nc-select>
				<nc-input [id]="'letter'" [formGroup]="formGroup" [mask]="{ mask: 'a' }" formControlName="letter" label="letter"> </nc-input>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSave"></ea-footer>
</form>
