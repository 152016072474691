<nc-confirmation-dialog titleLabel="employeeDetails" buttonLabel="close">
	<table class="readonly-form" *ngIf="employee$ | async as employee">
		<ng-container>
			<tr>
				<td class="title">{{ 'personalInfo' | translate }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td>{{ 'firstName' | translate }}</td>
				<td class="value-cell">{{ employee.firstName }}</td>
			</tr>
			<tr>
				<td>{{ 'lastName' | translate }}</td>
				<td class="value-cell">{{ employee.lastName }}</td>
			</tr>
			<tr>
				<td>{{ 'socialSecurityNumber' | translate }}</td>
				<td class="value-cell">{{ employee.socialSecurityNumber }}</td>
			</tr>
			<tr>
				<td>{{ 'gender' | translate }}</td>
				<td class="value-cell">{{ employee.gender }}</td>
			</tr>
			<tr>
				<td>{{ 'maritalStatus' | translate }}</td>
				<td class="value-cell">{{ employee.civilStatus }}</td>
			</tr>
			<tr>
				<td>{{ 'language' | translate }}</td>
				<td class="value-cell">{{ employee.language }}</td>
			</tr>
			<tr>
				<td>{{ 'birthDate' | translate }}</td>
				<td class="value-cell">{{ employee.birthDate | date : 'dd.MM.yyyy' }}</td>
			</tr>
			<tr>
				<td>{{ 'neoId' | translate }}</td>
				<td class="value-cell">{{ employee.neoId }}</td>
			</tr>
			<tr>
				<td>{{ 'pkProInsuranceEntryDate' | translate }}</td>
				<td class="value-cell">{{ employee.pkProInsuranceEntryDate | date : 'dd.MM.yyyy' }}</td>
			</tr>
			<tr>
				<td>{{ 'pkProInsuranceExitDate' | translate }}</td>
				<td class="value-cell">{{ employee.pkProInsuranceExitDate | date : 'dd.MM.yyyy' }}</td>
			</tr>
			<tr>
				<td>{{ 'neoStatus' | translate }}</td>
				<td class="value-cell">{{ employee.neoStatus }}</td>
			</tr>
			<tr>
				<td>{{ 'neoCategoryName' | translate }}</td>
				<td class="value-cell">{{ employee.neoCategoryId }}</td>
			</tr>
			<tr>
				<td>{{ 'easyTempId' | translate }}</td>
				<td class="value-cell">{{ employee.easyTempId }}</td>
			</tr>
			<tr>
				<td>{{ 'numberOfChildren' | translate }}</td>
				<td class="value-cell">{{ employee.numberOfChildren }}</td>
			</tr>
		</ng-container>
		<ng-container>
			<tr>
				<td>&nbsp;</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="title">{{ 'contactData' | translate }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td>{{ 'email' | translate }}</td>
				<td class="value-cell">{{ employee.email }}</td>
			</tr>
			<tr>
				<td>{{ 'phoneNumber' | translate }}</td>
				<td class="value-cell">{{ employee.homePhone }}</td>
			</tr>
			<tr>
				<td>{{ 'mobilePhone' | translate }}</td>
				<td class="value-cell">{{ employee.mobilePhone }}</td>
			</tr>
		</ng-container>
		<ng-container>
			<tr>
				<td>&nbsp;</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="title">{{ 'address' | translate }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td>{{ 'street' | translate }}</td>
				<td class="value-cell">{{ employee.street }}</td>
			</tr>
			<tr>
				<td>{{ 'zip' | translate }}</td>
				<td class="value-cell">{{ employee.zip }}</td>
			</tr>
			<tr>
				<td>{{ 'city' | translate }}</td>
				<td class="value-cell">{{ employee.city }}</td>
			</tr>
			<tr>
				<td>{{ 'canton' | translate }}</td>
				<td class="value-cell">
					<div fxLayout="row">
						<div *ngIf="employee.country == 'CH'">
							<img class="flag-img" src="/assets/images/cantons/{{ employee.residenceCanton }}.png" />
						</div>
						<div class="flag-text">{{ employee.residenceCanton }}</div>
					</div>
				</td>
			</tr>
			<tr>
				<td>{{ 'country' | translate }}</td>
				<td class="value-cell">
					<div fxLayout="row">
						<div *ngIf="employee.country !== ''"><img class="flag-img" src="/assets/images/countries/{{ employee.country }}.png" /></div>
						<div class="flag-text">{{ employee.country }}</div>
					</div>
				</td>
			</tr>
		</ng-container>
	</table>
</nc-confirmation-dialog>
