import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Option } from 'nc-shared';
import { AbsenceSelect } from '../../../state/action';
import { Observable, throwError } from 'rxjs';
import { FormUtils } from 'nc-utils';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConstants } from '../../../../app.constants';

@Component({
	templateUrl: './fetch-document-dialog.component.html',
	styleUrls: ['./fetch-document-dialog.component.scss'],
})
export class FetchDocumentDialogComponent {
	formGroup: FormGroup;
	documentTypes$: Observable<Option[]>;

	constructor(store: Store, formBuilder: FormBuilder, public dialogRef: MatDialogRef<FetchDocumentDialogComponent>) {
		this.documentTypes$ = store.select(AbsenceSelect.getFetchDocumentTypes);
		this.formGroup = formBuilder.group({
			documentType: [null, [Validators.required]],
		});
	}

	fetch(): any {
		if (this.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		this.dialogRef.close({ success: true, data: this.formGroup.getRawValue() });
	}
}
