import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelect } from '../../../user/state/action';

@Component({
	templateUrl: './active-dashboard.component.html',
})
export class ActiveDashboardComponent {
	cmLoggedIn: boolean;
	hrLoggedIn: boolean;
	ldLoggedIn: boolean;

	constructor(private store: Store) {
		this.store.select(UserSelect.getUserType).subscribe((result) => {
			this.cmLoggedIn = result === 'CASE_MANAGER';
			this.ldLoggedIn = result === 'LD';
			this.hrLoggedIn = result === 'HR';
		});
	}
}
