import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { FormBuilder } from '@angular/forms';
import { EnumUtils } from 'nc-utils';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { ContractTypeEnumeration } from '../../../user/model/contract-type.enumeration';
import { IntegrationPageAction } from '../../state/action';
import { CompanyModelFactory } from '../../service/company-model.factory';
import { ErrorSelect } from '../../../state/error/action';
import { ValidationService } from '../../../shared/service/validation.service';
import { FrontendStyleEnumeration } from '../../model/enumeration/frontend-style.enumeration';
import { Observable } from 'rxjs';
import { UserSelect } from '../../../user/state/action';
import { PermissionEnumeration } from '../../../shared/enumeration/permission.enumeration';
import { AppConstants } from '../../../app.constants';

@Component({
	templateUrl: './company-form.component.html',
})
export class CompanyFormComponent extends CanDeactivateFormComponent implements OnInit {
	yesNo: Option[];
	contractType: Option[];
	frontendStyleOption$: Observable<Option[]>;
	isAdmin: boolean;

	formGroup = this.formBuilder.group({
		id: null as string,
		easyTempId: null as number,
		neoId: null as number,
		contractType: AppConstants.CONTRACT_TYPE_TEMPORARY as string,
		neoEnvironment: AppConstants.NEO_ENV_PKPRO as string,
		isSwicaInsured: FlagEnumeration.no as string,
		isPkProInsured: FlagEnumeration.no as string,
		isKleEnabled: FlagEnumeration.no as string,
		frontendStyle: AppConstants.STYLE_ENV_ECARE as string,
	});

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private factory: CompanyModelFactory,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit(): void {
		this.yesNo = EnumUtils.toItems(FlagEnumeration);
		this.contractType = EnumUtils.toItems(ContractTypeEnumeration);
		this.frontendStyleOption$ = EnumUtils.toObservableItems(FrontendStyleEnumeration);

		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));

		this.checkPermission();
	}

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue();
		const company = this.factory.createNew(data);
		this.store.dispatch(IntegrationPageAction.saveCompany({ company }));
	};

	checkPermission(): void {
		this.store.select(UserSelect.getPermissions).subscribe((permission) => {
			this.isAdmin = permission.includes(PermissionEnumeration.ADMIN);
		});
	}
}
