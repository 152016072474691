import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDetails } from 'nc-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	templateUrl: './kle-validation-dialog.component.html',
	styleUrls: ['./kle-validation-dialog.component.scss'],
})
export class KleValidationDialogComponent implements OnInit {
	errors: { [key: string]: ErrorDetails };
	validations = [];

	constructor(@Inject(MAT_DIALOG_DATA) data, private translateService: TranslateService) {
		this.errors = data;
	}

	ngOnInit(): void {
		Object.keys(this.errors).forEach((key) => {
			let paramValue = this.errors[key]?.parameters[0] != null ? this.errors[key].parameters[0]['value'] : '';
			let paramName = this.errors[key]?.parameters[0] != null ? this.errors[key].parameters[0]['name'] : '';
			this.validations.push(this.translateService.instant(this.errors[key].code, { [paramName]: paramValue }));
		});
	}
}
