import * as ApplicationState from '../../state/app.state';
import { TableData } from 'nc-datatable';

export interface ReportState {
	report: TableData;
}

export interface State extends ApplicationState.State {
	report: ReportState;
}

export const REPORT_INITIAL_STATE: ReportState = {
	report: { records: [] },
};
