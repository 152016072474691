import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Observable } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'nc-shared';
import { KleService } from '../../service/kle.service';
import { DialogService } from 'nc-utils';
import { CrossChannelLinkDialogComponent } from './cross-channel-link-dialog/cross-channel-link-dialog.component';
import { KleSelect } from '../../state/action';

@Component({
	selector: 'ea-cross-chanel-link',
	templateUrl: './cross-channel-link.component.html',
	styleUrls: ['./cross-channel-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossChannelLinkComponent extends BaseComponent implements OnInit {
	@Input() absenceCode: string;

	columns: Array<DatatableColumn>;
	filterConfig: FilterConfig;
	data$: Observable<TableData>;

	constructor(private store: Store, private kleService: KleService, private dialogService: DialogService) {
		super();
	}

	ngOnInit(): void {
		this.filterConfig = {
			showGlobalFilter: true,
			filterValues: {},
		};

		this.data$ = this.store.select(KleSelect.getCrossChanelLinks);
		this.columns = this.getColumns();
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('storyId'),
			ColumnBuilder.create('url', 'crossChannelLinkUrl'),
			ColumnBuilder.createDate('expiryDate', 'crossChannelLinkExpiryDate'),
			ColumnBuilder.createDate('creationDate', 'crossChanelLinkCreationDate'),
		];
	}

	openDialog = (rowData: TableData): void => {
		const data = {
			rowData: rowData,
			absenceCode: this.absenceCode,
		};

		this.dialogService.open(CrossChannelLinkDialogComponent, { data });
	};
}
