<ea-header-info [showCompany]="true" [showStatus]="true"></ea-header-info>
<div fxFill fxLayout="column" [formGroup]="formGroup" class="kle-details">
	<nc-main-content>
		<nc-form-content [center]="false">
			<h2 fxLayout="row wrap" fxLayoutAlign="space-between">
				<div fxLayout="row">
					<nc-label id="insurance-id" value="insurance_case_id"></nc-label>
					<div>: {{ incident?.insuranceCaseId }}</div>
				</div>
				<div fxLayout="row">
					<nc-label id="last-sync" value="lastSync"></nc-label>
					<div>: {{ incident?.lastSync }}</div>
				</div>
			</h2>
			<nc-container [title]="'notifications' | translate" *ngIf="incident?.notifications?.length > 0">
				<nc-datatable [columns]="notificationColumns" [data$]="notifications$" [filterConfig]="filterConfig"></nc-datatable>
			</nc-container>
			<ea-received-sent-stories></ea-received-sent-stories>
			<h2>
				<nc-label id="stories" value="stories"></nc-label>
			</h2>
			<div class="divider-accordions"></div>
			<ea-container title="awaitStory">
				<nc-datatable id="awaitStoryTable" [columns]="awaitColumns" [data$]="awaitStories$" [filterConfig]="filterConfig"></nc-datatable>
			</ea-container>
			<div class="divider-accordions"></div>
			<ea-container title="receivedStories">
				<nc-accordion [allowMultipleOpenedItems]="true">
					<nc-accordion-item
						title="processControlResponse"
						id="processControlResponse"
						class="kle-accordion"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-process-control-response [formGroup]="formGroup" [absenceCode]="absenceCode"></ea-process-control-response>
					</nc-accordion-item>
					<nc-accordion-item
						title="settlement"
						id="settlement"
						class="kle-accordion"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-settlement [absenceCode]="absenceCode" [readOnly]="hasReadOnlyAccess"></ea-settlement>
					</nc-accordion-item>
					<nc-accordion-item
						title="crossChannelLink"
						id="crossChannelLink"
						class="kle-accordion"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-cross-chanel-link [absenceCode]="absenceCode"></ea-cross-chanel-link>
					</nc-accordion-item>
					<nc-accordion-item
						title="coveredDailyAllowance"
						id="coveredDailyAllowance"
						class="kle-accordion"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-cover-daily-allowance [absenceCode]="absenceCode"></ea-cover-daily-allowance>
					</nc-accordion-item>
					<nc-accordion-item
						title="repayment"
						id="repayment"
						class="kle-accordion"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-repayment [absenceCode]="absenceCode"></ea-repayment>
					</nc-accordion-item>
				</nc-accordion>
			</ea-container>
			<div class="divider-accordions"></div>
			<ea-container title="sentStories">
				<nc-accordion [allowMultipleOpenedItems]="true">
					<nc-accordion-item
						title="incapacity"
						id="incapacitiesToWork"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.incapacity.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.incapacity),
							'await-story-style': accordionCheckboxMap.incapacity.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.incapacity)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.incapacity)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-kle-incapacity-to-work [formGroup]="formGroup"></ea-kle-incapacity-to-work>
					</nc-accordion-item>
					<nc-accordion-item
						title="accidentDescription"
						id="accidentDescription"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.accidentDescription.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.accidentDescription),
							'await-story-style': accordionCheckboxMap.accidentDescription.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.accidentDescription)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.accidentDescription)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-accident-description-form
							[formGroup]="formGroup"
							[relapseCaseId]="incident?.relapseCaseId"
							[absenceCode]="absenceCode"
							[isBranchHr]="hasReadOnlyAccess"
						></ea-accident-description-form>
					</nc-accordion-item>
					<nc-accordion-item
						title="treatments"
						id="treatments"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.treatments.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.treatments),
							'await-story-style': accordionCheckboxMap.treatments.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.treatments)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.treatments)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-treatments-table
							[formGroup]="formGroup"
							[isBranchHr]="hasReadOnlyAccess"
							[absenceCode]="absenceCode"
						></ea-treatments-table>
					</nc-accordion-item>
					<nc-accordion-item
						title="additionsPerson"
						id="additionsPerson"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.additionsPerson.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.additionsPerson),
							'await-story-style': accordionCheckboxMap.additionsPerson.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.additionsPerson)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.additionsPerson)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-additions-person [formGroup]="formGroup"></ea-additions-person>
					</nc-accordion-item>
					<nc-accordion-item
						title="annualSalary"
						id="annualSalary"
						accordionCheckboxLabel="submit"
						class="kle-accordion"
						[accordionCheckboxValue]="accordionCheckboxMap.annualSalary.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.annualSalary),
							'await-story-style': accordionCheckboxMap.annualSalary.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.annualSalary)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.annualSalary)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-annual-salary [formGroup]="formGroup" [isBranchHr]="hasReadOnlyAccess"></ea-annual-salary>
					</nc-accordion-item>
					<nc-accordion-item
						*ngIf="accordionCheckboxMap.takeWorkUpFully.awaited"
						title="takeWorkUpFully"
						id="takeWorkUpFully"
						accordionCheckboxLabel="submit"
						class="kle-accordion"
						[accordionCheckboxValue]="accordionCheckboxMap.takeWorkUpFully.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.takeWorkUpFully),
							'await-story-style': accordionCheckboxMap.takeWorkUpFully.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.takeWorkUpFully)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.takeWorkUpFully)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-take-work-up-fully [formGroup]="formGroup"></ea-take-work-up-fully>
					</nc-accordion-item>
					<nc-accordion-item
						title="attachments"
						id="attachmentStory"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[accordionCheckboxValue]="accordionCheckboxMap.attachmentStory.checked"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.attachmentStory)"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.attachmentStory),
							'await-story-style': accordionCheckboxMap.attachmentStory.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.attachmentStory)
						}"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-attachments [formGroup]="formGroup" [absenceCode]="absenceCode"></ea-attachments>
					</nc-accordion-item>
					<nc-accordion-item
						title="otherEmployers"
						id="otherEmployers"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.otherEmployers.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.otherEmployers),
							'await-story-style': accordionCheckboxMap.otherEmployers.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.otherEmployers)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.otherEmployers)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-other-employers [formGroup]="formGroup"></ea-other-employers>
					</nc-accordion-item>
					<nc-accordion-item
						title="payment"
						id="payment"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.payment.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.payment),
							'await-story-style': accordionCheckboxMap.payment.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.payment)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.payment)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-payment [formGroup]="formGroup"></ea-payment>
					</nc-accordion-item>
					<nc-accordion-item
						title="specialCoverage"
						id="specialCoverage"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.specialCoverage.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.specialCoverage),
							'await-story-style': accordionCheckboxMap.specialCoverage.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.specialCoverage)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.specialCoverage)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-special-coverage [formGroup]="formGroup"></ea-special-coverage>
					</nc-accordion-item>
					<nc-accordion-item
						title="agreement"
						id="agreement"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.agreement.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.agreement)"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.agreement),
							'await-story-style': accordionCheckboxMap.agreement.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.agreement)
						}"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-agreement [absenceCode]="absenceCode" [formGroup]="formGroup"></ea-agreement>
					</nc-accordion-item>
					<nc-accordion-item
						title="shortTimeWork"
						id="shortTimeWork"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.shortTimeWork.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.shortTimeWork)"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.shortTimeWork),
							'await-story-style': accordionCheckboxMap.shortTimeWork.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.shortTimeWork)
						}"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-short-time-work [formGroup]="formGroup"></ea-short-time-work>
					</nc-accordion-item>
					<nc-accordion-item
						title="absences"
						id="absenceStory"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.absence.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.absence)"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.absence),
							'await-story-style': accordionCheckboxMap.absence.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.absence)
						}"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-absences [formGroup]="formGroup" [absenceCode]="absenceCode" [isBranchHr]="hasReadOnlyAccess"></ea-absences>
					</nc-accordion-item>
					<nc-accordion-item
						title="personMutated"
						id="personMutated"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.personMutated.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.personMutated)"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.personMutated),
							'await-story-style': accordionCheckboxMap.personMutated.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.personMutated)
						}"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-person-mutated [formGroup]="formGroup" [absenceCode]="absenceCode"></ea-person-mutated>
					</nc-accordion-item>
					<nc-accordion-item
						title="specialCode"
						id="specialCode"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.specialCode.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.specialCode)"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.specialCode),
							'await-story-style': accordionCheckboxMap.specialCode.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.specialCode)
						}"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-special-code [formGroup]="formGroup"></ea-special-code>
					</nc-accordion-item>
					<nc-accordion-item
						title="processControl"
						id="processControl"
						class="kle-accordion"
						accordionCheckboxLabel="submit"
						[accordionCheckboxValue]="accordionCheckboxMap.processControl.checked"
						[showAccordionCheckbox]="!hasReadOnlyAccess"
						[ngClass]="{
							invalid: isAccordionInvalid(accordionCheckboxMap.processControl),
							'await-story-style': accordionCheckboxMap.processControl.awaited,
							'edited-story-style': isAccordionDirty(accordionCheckboxMap.processControl)
						}"
						(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.processControl)"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-process-control [formGroup]="formGroup" [absenceCode]="absenceCode"></ea-process-control>
					</nc-accordion-item>
				</nc-accordion>
			</ea-container>
			<div class="divider-accordions"></div>
			<ea-container title="synchronization.table.Message">
				<nc-accordion [allowMultipleOpenedItems]="true">
					<nc-accordion-item
						title="dialogMessage"
						id="dialogMessage"
						class="kle-accordion"
						[accordionItemClass]="'ea-accordion-item-style'"
						[accordionItemBodyClass]="'ea-accordion-body-style'"
					>
						<ea-dialog-message [formGroup]="formGroup" [absenceCode]="absenceCode" [isBranchHr]="hasReadOnlyAccess"></ea-dialog-message>
					</nc-accordion-item>
				</nc-accordion>
			</ea-container>
		</nc-form-content>
	</nc-main-content>

	<ae-kle-details-footer
		[formGroup]="formGroupForSync"
		[formGroupIncidentContext]="formGroupIncidentContext"
		[isCoverageYes]="isCoverageYes"
		[isBranchHr]="hasReadOnlyAccess"
		(submitClicked)="submit()"
		(syncClicked)="sync()"
		(accidentCertificateClicked)="generateReport()"
		(pharmacyCertificateClicked)="generatePharmacyReport()"
	></ae-kle-details-footer>
</div>
