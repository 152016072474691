import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AbsenceTypeEnumeration } from 'src/app/shared/enumeration/absence-type.enumeration';
import { FormValidation } from '../../shared/form-validation';
import { AbsenceDeclarationTypeEnumeration } from '../model/enumeration/absence-declaration-type.enumeration';

const OK = null;
const REQUIRED = { required: true };
const INCAPACITY_REQUIRED = { incapacityRequired: true };
const EMPLOYMENT_END_DATE_AFTER_START_DATE = { employmentEndDateAfterStartDate: true };
const INVALID_EMPLOYMENT_PERCENTAGE = { invalidEmploymentPercentage0to100: true };
const LAST_DEPLOYMENT_DATE_AFTER_FIRST_DEPLOYMENT_DATE = { lastDeploymentAfterFirst: true };
const BEGIN_INTAKE_IS_MANDATORY = { caseManagementDetailsStartedOnDateRequired: true };
const END_DATE_IS_MANDATORY = { caseManagementDetailsEndedOnDateRequired: true };
const PLEASE_SELECT_CONCLUSION = { caseManagementDetailsConclusionRequired: true };

/**
 * Checks if sent to case management company date is required
 */
const isSentToCaseManagementCompanyDateRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const isSentToCaseManagementCompanyControl = control.get('isSentToCaseManagementCompany');
		const sentToCaseManagementCompanyDateControl = control.get('sentToCaseManagementCompanyDate');

		if (isSentToCaseManagementCompanyControl.value === 'yes' && sentToCaseManagementCompanyDateControl.value === null) {
			FormValidation.addError(sentToCaseManagementCompanyDateControl, REQUIRED);
		} else {
			FormValidation.removeError(sentToCaseManagementCompanyDateControl, REQUIRED);
		}

		return OK;
	};
};

/**
 * Checks if closed by case management company date is required
 */
const isClosedByCaseManagementCompanyDateRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const isClosedByCaseManagementCompanyControl = control.get('isClosedByCaseManagementCompany');
		const closedByCaseManagementCompanyDateControl = control.get('closedByCaseManagementCompanyDate');

		if (isClosedByCaseManagementCompanyControl.value === 'yes' && !closedByCaseManagementCompanyDateControl.value) {
			FormValidation.addError(closedByCaseManagementCompanyDateControl, REQUIRED);
		} else {
			FormValidation.removeError(closedByCaseManagementCompanyDateControl, REQUIRED);
		}

		return OK;
	};
};

/**
 * Checks if aborted by case management company date is required
 */
const isAbortedByCaseManagementCompanyDateRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const isAbortedByCaseManagementCompanyControl = control.get('isAbortedByCaseManagementCompany');
		const abortedByCaseManagementCompanyDateControl = control.get('abortedByCaseManagementCompanyDate');

		if (isAbortedByCaseManagementCompanyControl.value === 'yes' && abortedByCaseManagementCompanyDateControl.value === null) {
			FormValidation.addError(abortedByCaseManagementCompanyDateControl, REQUIRED);
		} else {
			FormValidation.removeError(abortedByCaseManagementCompanyDateControl, REQUIRED);
		}

		return OK;
	};
};

const isIncapacityRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const incapacitiesArray = control.get('incapacities') as FormArray;
		const absenceType = control.value.absenceType;
		const isKleEnabled = control.value.isKleEnabled;
		if (
			incapacitiesArray.getRawValue().filter((x) => !x.deleted).length === 0 &&
			(absenceType != AbsenceTypeEnumeration.ACCIDENT.code || !isKleEnabled)
		) {
			FormValidation.addError(incapacitiesArray, INCAPACITY_REQUIRED);
		} else {
			FormValidation.removeError(incapacitiesArray, INCAPACITY_REQUIRED);
		}

		return OK;
	};
};

const validatePermanentEmployment = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const employmentStartDateControl = control.get('employmentStartDate');
		const categoryTypeControl = control.get('categoryType');
		const employmentEndDateControl = control.get('employmentEndDate');
		const employmentPercentageControl = control.get('employmentPercentage');
		const companyContractTypeControl = control.get('companyContractType');

		if (categoryTypeControl.value === AbsenceDeclarationTypeEnumeration.PREMIUM.code && companyContractTypeControl.value === 'PERMANENT') {
			if (employmentStartDateControl.value) {
				FormValidation.removeError(employmentStartDateControl, REQUIRED);
			} else {
				FormValidation.addError(employmentStartDateControl, REQUIRED);
			}

			if (employmentEndDateControl.value && employmentEndDateControl.value.isBefore(employmentStartDateControl.value)) {
				FormValidation.addError(employmentStartDateControl, EMPLOYMENT_END_DATE_AFTER_START_DATE);
			} else {
				FormValidation.removeError(employmentStartDateControl, EMPLOYMENT_END_DATE_AFTER_START_DATE);
			}

			if (employmentPercentageControl.value) {
				FormValidation.removeError(employmentPercentageControl, REQUIRED);
			} else {
				FormValidation.addError(employmentPercentageControl, REQUIRED);
			}

			if (employmentPercentageControl.value < 0 || employmentPercentageControl.value > 100) {
				FormValidation.addError(employmentPercentageControl, INVALID_EMPLOYMENT_PERCENTAGE);
			} else {
				FormValidation.removeError(employmentPercentageControl, INVALID_EMPLOYMENT_PERCENTAGE);
			}
		}

		return OK;
	};
};

const validateTemporaryEmployment = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const firstDeploymentControl = control.get('firstDeploymentDate');
		const lastDeployment = control.get('lastDeploymentDate');
		const companyContractTypeControl = control.get('companyContractType');
		const categoryTypeControl = control.get('categoryType');

		if (categoryTypeControl.value === AbsenceDeclarationTypeEnumeration.PREMIUM.code && companyContractTypeControl.value === 'TEMPORARY') {
			if (firstDeploymentControl.value) {
				FormValidation.removeError(firstDeploymentControl, REQUIRED);
			} else {
				FormValidation.addError(firstDeploymentControl, REQUIRED);
			}

			if (lastDeployment.value && lastDeployment.value.isBefore(firstDeploymentControl.value)) {
				FormValidation.addError(firstDeploymentControl, LAST_DEPLOYMENT_DATE_AFTER_FIRST_DEPLOYMENT_DATE);
			} else {
				FormValidation.removeError(firstDeploymentControl, LAST_DEPLOYMENT_DATE_AFTER_FIRST_DEPLOYMENT_DATE);
			}
		}

		return OK;
	};
};

const validateCaseManagementDetails = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const isCaseManagementActiveControl = control.get('isCaseManagementActive');
		const caseManagementStartedOnDateControl = control.get('caseManagementStartedOnDate');
		const caseManagementEndedOnDateControl = control.get('caseManagementEndedOnDate');
		const caseManagementAccomplishmentControl = control.get('caseManagementAccomplishment');
		const caseManagementSuccessfulnessTypeControl = control.get('caseManagementSuccessfulnessType');

		if (isCaseManagementActiveControl.value === 'yes') {
			if (caseManagementStartedOnDateControl.value == null) {
				FormValidation.addError(caseManagementStartedOnDateControl, BEGIN_INTAKE_IS_MANDATORY);
			} else {
				FormValidation.removeError(caseManagementStartedOnDateControl, BEGIN_INTAKE_IS_MANDATORY);
			}

			if (caseManagementAccomplishmentControl.value !== 'UNKNOWN' && caseManagementEndedOnDateControl.value == null) {
				FormValidation.addError(caseManagementEndedOnDateControl, END_DATE_IS_MANDATORY);
			} else {
				FormValidation.removeError(caseManagementEndedOnDateControl, END_DATE_IS_MANDATORY);
			}

			if (caseManagementAccomplishmentControl.value !== 'UNKNOWN' && caseManagementSuccessfulnessTypeControl.value == null) {
				FormValidation.addError(caseManagementSuccessfulnessTypeControl, PLEASE_SELECT_CONCLUSION);
			} else {
				FormValidation.removeError(caseManagementSuccessfulnessTypeControl, PLEASE_SELECT_CONCLUSION);
			}
		}

		return OK;
	};
};

export {
	isIncapacityRequired,
	isSentToCaseManagementCompanyDateRequired,
	isClosedByCaseManagementCompanyDateRequired,
	isAbortedByCaseManagementCompanyDateRequired,
	validatePermanentEmployment,
	validateTemporaryEmployment,
	validateCaseManagementDetails,
};
