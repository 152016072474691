import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { IntegrationPageAction, IntegrationSelect } from '../../state/action';
import { SettingsPageAction } from '../../../state/settings/action';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './company-groups.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyGroupsComponent extends BaseComponent {
	companyGroupColumns: DatatableColumn[];
	companyGroups$: Observable<TableData>;
	filterConfig: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;

	constructor(private store: Store) {
		super();

		this.store.dispatch(IntegrationPageAction.getCompanyGroups());
		this.companyGroups$ = this.store.select(IntegrationSelect.getCompanyGroups);
		this.companyGroupColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('name', 'name'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];
		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: false,
			filterValues: {},
		};
	}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `integration/company/groups/${rowData.id}` }));
	};

	delete = (rowData): void => {
		this.store.dispatch(IntegrationPageAction.openDeleteConfirmationDialog({ id: rowData.id }));
	};
}
