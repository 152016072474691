import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Observable } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Store } from '@ngrx/store';
import { DeadlineMessageFormComponent } from '../deadline-message-form/deadline-message-form.component';
import { SimpleMessageFormComponent } from '../simple-message-form/simple-message-form.component';
import { ConfirmationMessageFormComponent } from '../confirmation-message-form/confirmation-message-form.component';
import { ContactPersonMessageFormComponent } from '../contact-person-message-form/contact-person-message-form.component';
import { WorkplaceMessageFormComponent } from '../workplace-message-form/workplace-message-form.component';
import { ReasonForAbsenceFormComponent } from '../reason-for-absence-message-form/reason-for-absence-form.component';
import { FreeFormDialogComponent } from '../free-form-dialog/free-form-dialog.component';
import { DialogService } from 'nc-utils';
import { DialogMessageTypeEnum } from '../../../model/enums/dialog-message-type.enum';
import { KlePageAction } from '../../../state/action';

@Component({
	selector: 'ea-table-dialog-message',
	templateUrl: './table-dialog-message.component.html',
	styleUrls: ['./table-dialog-message.component.scss'],
})
export class TableDialogMessageComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;
	@Input() data$: Observable<TableData>;
	@Input() type: string;

	filterConfig: FilterConfig;

	constructor(private formBuilder: FormBuilder, private store: Store, private dialogService: DialogService) {
		super();
	}

	ngOnInit(): void {
		this.filterConfig = {
			showFilter: false,
			filterValues: {
				showFilter: false,
				showGlobalFilter: true,
			},
		};
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('title', 'title'),
			ColumnBuilder.createDate('createdBy', 'creator'),
			ColumnBuilder.create('createdOn', 'creationDate'),
		];
	}

	preview = (rowData): void => {
		const dialogConfig = {
			width: '700px',
			maxHeight: '90vh',
			data: { absenceCode: this.absenceCode, storyId: rowData.storyId, formGroup: this.formGroup, creator: rowData.createdBy },
		};

		let dialogRef;

		switch (this.type) {
			case DialogMessageTypeEnum.SIMPLE_MESSAGE.code:
				dialogRef = this.dialogService.open(SimpleMessageFormComponent, dialogConfig);
				break;
			case DialogMessageTypeEnum.TASK_WITH_DEADLINE.code:
				dialogRef = this.dialogService.open(DeadlineMessageFormComponent, dialogConfig);
				break;
			case DialogMessageTypeEnum.TASK_WITH_CONFIRMATION.code:
				dialogRef = this.dialogService.open(ConfirmationMessageFormComponent, dialogConfig);
				break;
			case DialogMessageTypeEnum.CONTACT_PERSON.code:
				dialogRef = this.dialogService.open(ContactPersonMessageFormComponent, dialogConfig);
				break;
			case DialogMessageTypeEnum.WORKPLACE_DESCRIPTION.code:
				dialogRef = this.dialogService.open(WorkplaceMessageFormComponent, dialogConfig);
				break;
			case DialogMessageTypeEnum.REASON_FOR_ABSENCE.code:
				dialogRef = this.dialogService.open(ReasonForAbsenceFormComponent, dialogConfig);
				break;
			case DialogMessageTypeEnum.FREE_DIALOG_MESSAGE.code:
				dialogRef = this.dialogService.open(FreeFormDialogComponent, dialogConfig);
				break;
		}

		dialogRef.afterClosed().subscribe(() => {
			this.store.dispatch(KlePageAction.clearDialogMessage());
		});
	};
}
