<div fxLayout="column" fxLayoutGap="10px" [formGroup]="annualSalaryGroup">
	<div fxLayout="row" fxLayoutGap="10px">
		<nc-datepicker [formGroup]="annualSalaryGroup" formControlName="accidentStartDate" label="accidentStartDate" [isReadonly]="true">
		</nc-datepicker>
		<nc-submit-button
			id="get-salary"
			buttonLabel="getSalary"
			(buttonClick)="getSalary()"
			dialogLabel="processing"
			class="margin-top-10"
			[disabled]="!annualSalaryGroup.controls.accidentStartDate.value"
		></nc-submit-button>
	</div>
	<div fxLayout="row" fxLayoutGap="10px">
		<nc-input [formGroup]="annualSalaryGroup" formControlName="incidentMonth" label="incidentMonth" [isReadonly]="true"></nc-input>
		<nc-datepicker [formGroup]="annualSalaryGroup" formControlName="contractValidAsOf" label="contractValidAsOf" [isReadonly]="true">
		</nc-datepicker>
		<nc-input [formGroup]="annualSalaryGroup" formControlName="annualSalaryTotal" label="annualSalaryTotal" [isReadonly]="true"></nc-input>
	</div>

	<ea-manual-additions-table
		[formGroup]="annualSalaryGroup"
		[formArray]="annualSalaryGroup.controls.manualAdditions"
		[manualAdditions$]="manualAdditions$"
		[isBranchHr]="isBranchHr"
	></ea-manual-additions-table>

	<ea-salary-bases [formGroup]="salaryBaseGroup"></ea-salary-bases>
	<ea-present-salary [formGroup]="hourlyPayGroup" [data$]="presentDetail$"></ea-present-salary>
	<ea-past-salary *ngIf="pastGroup.controls.pastAnnualTotal.value" [formGroup]="pastGroup"></ea-past-salary>
</div>
