import { FormGroup } from '@angular/forms';
import { StringsUtils } from 'nc-utils';
import { OK } from '../../shared/utils/form-validation.utils';

const CATEGORY_MANDATORY_ERROR = { categoryMandatoryError: true };
const EMPLOYMENT_CONTRACT_MANDATORY_FIELDS = { employmentContractMandatoryFields: true };
/**
 * If we have information about tax, taxAtSourceCode or categoryOpen or categoryPredefined must be populated (only one of them)
 * @param formGroup formGroup
 */
export const categoryValidator = (formGroup: FormGroup) => {
	const taxAtSourceCantonEmpty = StringsUtils.isEmpty(formGroup.controls.taxAtSourceCanton.value);
	const taxAtSourceMunicipalityIdEmpty = StringsUtils.isEmpty(formGroup.controls.taxAtSourceMunicipalityId.value);
	const categoryOpenEmpty = StringsUtils.isEmpty(formGroup.controls.categoryOpen.value);
	const categoryPredefinedEmpty = StringsUtils.isEmpty(formGroup.controls.categoryPredefined.value);
	const taxAtSourceCodeEmpty = StringsUtils.isEmpty(formGroup.controls.taxAtSourceCode.value);

	if (
		(!taxAtSourceCantonEmpty || !taxAtSourceMunicipalityIdEmpty) &&
		((categoryOpenEmpty && categoryPredefinedEmpty && taxAtSourceCodeEmpty) ||
			(!categoryOpenEmpty && !categoryPredefinedEmpty && taxAtSourceCodeEmpty) ||
			(categoryOpenEmpty && !categoryPredefinedEmpty && !taxAtSourceCodeEmpty) ||
			(!categoryOpenEmpty && categoryPredefinedEmpty && !taxAtSourceCodeEmpty) ||
			(!categoryOpenEmpty && !categoryPredefinedEmpty && !taxAtSourceCodeEmpty))
	) {
		return CATEGORY_MANDATORY_ERROR;
	}

	return OK;
};

export const employmentContractMandatoryFields = (formGroup: FormGroup) => {
	const jobTitleEmpty = StringsUtils.isEmpty(formGroup.controls.jobTitle.value);
	const employmentContractTypeEmpty = StringsUtils.isEmpty(formGroup.controls.employmentContractType.value);

	if (jobTitleEmpty || employmentContractTypeEmpty) {
		return EMPLOYMENT_CONTRACT_MANDATORY_FIELDS;
	}

	return OK;
};
