import { AbstractControl } from '@angular/forms';
import { FormValidation } from '../form-validation';

export interface AbstractControlObject {
	control: AbstractControl;
	validator: { [key: string]: boolean };
}

export const OK = null;
export const REQUIRED = { required: true };
export const ONE_FIELD_IS_REQUIRED = { 'at.least.one.filed.required.group': true };
export const removeValidationErrors = (controls: Array<AbstractControlObject>) => {
	for (let data of controls) {
		FormValidation.removeError(data.control, data.validator);
	}
};
