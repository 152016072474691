import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanDeactivateFormComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { AbsencePageAction, AbsenceSelect } from '../../../state/action';
import { ActivatedRoute } from '@angular/router';
import { ErrorSelect } from '../../../../state/error/action';
import { ValidationService } from '../../../../shared/service/validation.service';
import { Observable } from 'rxjs';
import { AbsenceContextModel } from '../../../../shared/model/absence-context.model';
import { filter } from 'rxjs/operators';
import { validFromGreaterThanValidTo } from '../../../validator/absence-annex.validator';
import { NcDocumentsService } from 'nc-documents';
import { StringsUtils } from 'nc-utils';
import { EncodingService } from '../../../../shared/service/encoding.service';

@Component({
	templateUrl: './new-absence-annex-form.component.html',
	styleUrls: ['./new-absence-annex-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewAbsenceAnnexFormComponent extends CanDeactivateFormComponent implements OnInit {
	documents: FormArray;
	currentAbsence$: Observable<AbsenceContextModel>;
	isShareInformation = true;

	constructor(
		private store: Store,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef,
		private formBuilder: FormBuilder,
		private documentService: NcDocumentsService,
		private encodingService: EncodingService,
		route: ActivatedRoute
	) {
		super();

		this.formGroup = this.formBuilder.group(
			{
				id: ['', null],
				absenceCode: ['', Validators.required],
				insuredPersonName: ['', null],
				comment: ['', null],
				validFrom: ['', null],
				validTo: ['', null],
				percentage: ['', null],
				type: ['', null],
				documents: this.formBuilder.array([]),
			},
			{
				validators: [validFromGreaterThanValidTo()],
			}
		);
		this.documents = this.formGroup.get('documents') as FormArray;

		route.url.subscribe((url) => {
			if (url[2].path === 'prolongation') {
				this.formGroup.get('type').patchValue('PROLONGATION');
				this.isShareInformation = false;
			} else if (url[2].path === 'closed') {
				this.formGroup.get('type').patchValue('CLOSED');
				this.isShareInformation = false;
			}
		});

		route.params.subscribe((params) => {
			if (params.code) {
				this.formGroup.get('absenceCode').patchValue(params.code);
				this.store.dispatch(AbsencePageAction.getCurrentAbsence({ code: params.code }));
			}
		});

		this.currentAbsence$ = this.store.select(AbsenceSelect.getAbsenceContextModel);
		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));
	}

	ngOnInit(): void {
		this.currentAbsence$
			.pipe(filter((absence) => absence !== null && absence.absenceId !== null))
			.subscribe((absence) => this.formGroup.get('insuredPersonName').patchValue(absence.insuredPersonName));
	}

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue();
		data.documents = this.documentService.getDocuments(this.formGroup);
		data.documents = this.encodingService.encodeDocuments(data.documents);
		this.store.dispatch(AbsencePageAction.saveAbsenceAnnex({ data, isShareInformation: this.isShareInformation }));
	};

	showExactValidationError() {
		return StringsUtils.isEmpty(this.formGroup.value.comment) && this.formGroup.value.documents.length === 0;
	}

	isCommentFilled(): boolean {
		return StringsUtils.isEmpty(this.formGroup.value.comment);
	}
}
