import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KleService } from '../../../service/kle.service';
import { BaseComponent } from 'nc-shared';
import { FormBuilder } from '@angular/forms';

@Component({
	templateUrl: './cross-channel-link-dialog.component.html',
	styleUrls: ['./cross-channel-link-dialog.component.scss'],
})
export class CrossChannelLinkDialogComponent extends BaseComponent implements OnDestroy {
	crossChannelLinkFormGroup = this.formBuilder.group({
		storyId: this.data.rowData.storyId,
		url: this.data.rowData.url,
		webService: this.data.rowData.webService,
		linkDescription: this.data.rowData.linkDescription,
		creationDate: this.data.rowData.creationDate,
		expiryDate: this.data.rowData.expiryDate,
		key: this.data.rowData.key,
		password: this.data.rowData.password,
	});

	isReadOnly = true;

	constructor(@Inject(MAT_DIALOG_DATA) public data, private kleService: KleService, private formBuilder: FormBuilder) {
		super();
	}

	ngOnDestroy(): void {
		this.crossChannelLinkFormGroup.reset();
	}
}
