import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatisticState } from '../statistic.state';

export const STATISTIC_FEATURE_STATE_NAME = 'statistic';
const FEATURE_SELECTOR = createFeatureSelector<StatisticState>(STATISTIC_FEATURE_STATE_NAME);

export const getType = createSelector(FEATURE_SELECTOR, (state) => state.type);
export const getRecords = createSelector(FEATURE_SELECTOR, (state) => state.records);
export const getColumns = createSelector(FEATURE_SELECTOR, (state) => state.columns);
export const getOverallChartData = createSelector(FEATURE_SELECTOR, (state) => state.overallChartData);
export const getTotalCmChertData = createSelector(FEATURE_SELECTOR, (state) => state.totalCmChartData);
export const getReasonsForSuccessfulCmChartData = createSelector(FEATURE_SELECTOR, (state) => state.reasonsForSuccessfulCmChartData);
export const getReasonsForNotSuccessfulCmChartData = createSelector(FEATURE_SELECTOR, (state) => state.reasonsForNotSuccessfulCmChartData);

export const getAverageAbsenceDurationPerIllnessChartData = createSelector(
	FEATURE_SELECTOR,
	(state) => state.averageAbsenceDurationPerIllnessChartData
);

export const getAverageAbsenceDurationPerAccidentChartData = createSelector(
	FEATURE_SELECTOR,
	(state) => state.averageAbsenceDurationPerAccidentChartData
);
