import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IntegrationApiAction, IntegrationPageAction, IntegrationSelect } from './action';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorAction } from '../../state/error/action';
import { DialogConfigurationUtils, DialogService, DialogType } from 'nc-utils';
import { IntegrationService } from '../service/integration.service';
import { IntegrationResponseDialogComponent } from '../component/integration-response-dialog/integration-response-dialog.component';
import { SettingsPageAction } from '../../state/settings/action';
import { Store } from '@ngrx/store';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { DeleteConfirmationDialogComponent } from '../../shared/component/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { OperationTypeEnumeration } from '../../access-assignment/model/enumeration/operation-type.enumeration';
import { ChangeFrontendStyleModel } from '../model/change-frontend-style.model';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';

@Injectable()
export class IntegrationEffect {
	constructor(
		private actions$: Actions,
		private integrationService: IntegrationService,
		private dialogService: DialogService,
		private store: Store
	) {}

	saveCompanyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.saveCompany),
			mergeMap((action) => this.integrationService.save(action.company)),
			map((response) =>
				response.success ? IntegrationApiAction.saveCompanySuccess({ response }) : ErrorAction.setErrors({ errors: response.errors })
			)
		);
	});

	saveCompanySuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationApiAction.saveCompanySuccess),
			map(() => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.navigate({ url: 'integration/companies' });
			})
		);
	});

	filterCompaniesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.filterCompanies),
			switchMap((action) =>
				this.integrationService.filterCompanies(action.start, action.length, action.order, action.columns, action.globalSearch)
			),
			map((result) => IntegrationApiAction.filterCompaniesSuccess({ companies: result }))
		);
	});

	filterMedicalInstituionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.filterMedicalInstitutions),
			switchMap((action) =>
				this.integrationService.filterMedicalInstitutions(action.start, action.length, action.order, action.columns, action.globalSearch)
			),
			map((result) => IntegrationApiAction.filterMedicalInstitutionsSuccess({ medicalInstitutions: result }))
		);
	});

	refreshCompanyEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(IntegrationPageAction.refreshCompany),
				switchMap((action) => this.integrationService.refreshCompany(action.companyIds, action.byModificationDate)),
				tap((response) => {
					this.dialogService.close(DialogType.PROCESSING);
					const dialogConfig = { data: { response }, disableClose: true, width: '800px' };
					this.dialogService.open(IntegrationResponseDialogComponent, dialogConfig);
				})
			);
		},
		{ dispatch: false }
	);

	refreshMedicalInstitutionsEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(IntegrationPageAction.refreshMedicalInstitutions),
				switchMap((action) =>
					this.integrationService.refreshMedicalInstitutions(action.term, action.searchType, action.partnerType, action.providerType)
				),
				tap((response) => {
					this.dialogService.close(DialogType.PROCESSING);
					const dialogConfig = { data: { response }, disableClose: true, width: '800px' };
					this.dialogService.open(IntegrationResponseDialogComponent, dialogConfig);
				})
			);
		},
		{ dispatch: false }
	);

	getCompanyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.getCompany),
			switchMap((action) => this.integrationService.getCompany(action.companyId)),
			map((response) => IntegrationApiAction.getCompanySuccess({ response }))
		);
	});

	getEmployeesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.getEmployees),
			switchMap((action) => this.integrationService.getEmployees(action.companyId)),
			map((result) => IntegrationApiAction.getEmployeesSuccess({ employees: result }))
		);
	});

	getEmployeeEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.getEmployee),
			switchMap((action) => this.integrationService.getEmployee(action.employeeId)),
			map((result) => IntegrationApiAction.getEmployeeSuccess({ employee: result }))
		);
	});

	updateCompanyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.updateCompany),
			mergeMap((action) => this.integrationService.update(action.company)),
			map((response) =>
				response.success ? IntegrationApiAction.updateCompanySuccess({ response }) : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	updateCompanySuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(IntegrationApiAction.updateCompanySuccess),
				tap(() => {
					this.dialogService.close(DialogType.PROCESSING);
					const dialogConfig = DialogConfigurationUtils.info('companySuccessMessage', 'save');
					this.dialogService.open(NcInformationDialogComponent, dialogConfig);
				})
			);
		},
		{ dispatch: false }
	);

	getCompanyGroupsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.getCompanyGroups),
			switchMap(() => this.integrationService.getAllCompanyGroups()),
			map((result) => IntegrationApiAction.getCompanyGroupsSuccess({ companyGroups: result }))
		);
	});

	getCompanyGroupEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.getCompanyGroup),
			switchMap((action) => this.integrationService.getCompanyGroup(action.id)),
			map((result) => IntegrationApiAction.getCompanyGroupSuccess({ companyGroup: result }))
		);
	});

	saveCompanyGroupEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.saveCompanyGroup),
			mergeMap((action) => this.integrationService.saveCompanyGroup(action.data)),
			map((response) =>
				response.success ? IntegrationApiAction.saveCompanyGroupSuccess({ response }) : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	saveCompanyGroupSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationApiAction.saveCompanyGroupSuccess),
			map(() => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.navigate({ url: 'integration/company/groups' });
			})
		);
	});

	openConfirmDeleteDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(IntegrationPageAction.openDeleteConfirmationDialog),
				switchMap((action) => {
					const dialogConfig = { disableClose: true, data: { id: action.id } };
					return this.dialogService.open(DeleteConfirmationDialogComponent, dialogConfig).afterClosed();
				}),
				tap((dialogResult) => {
					if (dialogResult.success) {
						this.store.dispatch(IntegrationPageAction.deleteCompanyGroup({ id: dialogResult.id }));
					}
				})
			);
		},
		{ dispatch: false }
	);

	deleteCompanyGroupEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.deleteCompanyGroup),
			switchMap((action) => {
				this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
				return this.integrationService.deleteCompanyGroup(action.id);
			}),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return response.success
					? IntegrationPageAction.getCompanyGroups()
					: ErrorAction.submitFailed({
							errors: { message: { code: response.errors.warning.code, parameters: null } },
					  });
			})
		);
	});

	getAvailableEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.getAvailable),
			mergeMap((action) => this.integrationService.changeFrontendOperations(action.requestModel)),
			map((result) => IntegrationApiAction.getAvailableSuccess({ records: result.records }))
		);
	});

	getAssignedEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.getAssigned),
			mergeMap((action) => this.integrationService.changeFrontendOperations(action.requestModel)),
			map((result) => IntegrationApiAction.getAssignedSuccess({ records: result.records }))
		);
	});

	assignOrRemoveEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationPageAction.assignOrRemove),
			mergeMap((action) => this.integrationService.changeFrontendOperations(action.requestModel)),
			map((response) => IntegrationApiAction.assignOrRemoveSuccess())
		);
	});

	assignOrRemoveSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IntegrationApiAction.assignOrRemoveSuccess),
			withLatestFrom(this.store.select(IntegrationSelect.getFrontendStyle)),
			mergeMap(([response, frontendStyle]) => {
				this.dialogService.close(DialogType.PROCESSING);
				const companyAssigned = {
					ids: [],
					type: frontendStyle,
					operation: OperationTypeEnumeration.GET_ASSIGNED,
				} as ChangeFrontendStyleModel;
				const companyAvailable = {
					ids: [],
					type: frontendStyle,
					operation: OperationTypeEnumeration.GET_AVAILABLE,
				} as ChangeFrontendStyleModel;

				return [
					IntegrationPageAction.getAssigned({ requestModel: companyAssigned }),
					IntegrationPageAction.getAvailable({ requestModel: companyAvailable }),
				];
			})
		);
	});
}
