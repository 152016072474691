import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'nc-utils';
import { BodyPartInjury } from '../model/body-part-injury';
import { InjuryDialogComponent } from '../component/accident-description/injury-dialog/injury-dialog.component';
import {
	otherTypeOfInjuryValidator,
	partOfBodyOrUnassignedPartOfBodyMandatory,
	positionValidator,
} from '../validator/accident-description.validator';

@Injectable({ providedIn: 'root' })
export class AccidentDescriptionService {
	constructor(private httpClient: HttpClient, private formBuilder: FormBuilder, private dialogService: DialogService) {}

	/**
	 * Opens dialog for creation or edit of body part injury
	 * @param model body part injury model if exists or else null
	 * @param formArray body part injury form array
	 * @param formGroupParent parent form group
	 */
	openDialog(model: BodyPartInjury, formArray: FormArray, formGroupParent: FormGroup): void {
		const formGroup = this.getFormGroup(formArray, model);
		const dialogConfig = {
			width: '500px',
			data: { formGroup },
		};

		const dialogRef = this.dialogService.open(InjuryDialogComponent, dialogConfig);

		dialogRef
			.afterClosed()
			.pipe(filter((result) => result?.success))
			.subscribe(() => {
				formGroupParent.markAsDirty();
				if (!model) {
					formArray.push(formGroup);
				} else {
					const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
					formArray.removeAt(index);
					formArray.insert(index, formGroup);
				}
			});
	}

	/**
	 * Gets form group from formArray if model is populated or creates new form group
	 * @param formArray Body part injury form array
	 * @param model BodyPartInjury model
	 */
	private getFormGroup = (formArray: FormArray, model?: BodyPartInjury): FormGroup => {
		let formGroup: FormGroup;

		if (model) {
			const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
			const existing = formArray.at(index) as FormGroup;
			formGroup = this.createNewFormGroup(model.tableId, existing.getRawValue());
		} else {
			const tableId = formArray.length === 0 ? 0 : formArray.at(formArray.length - 1).get('tableId').value + 1;
			formGroup = this.createNewFormGroup(tableId);
		}

		return formGroup;
	};

	/**
	 * Creates new form group for body type injury
	 * @param tableId table id value for new form group
	 * @param bodyPartInjury bodyPartInjury for form group data
	 */
	private createNewFormGroup = (tableId: number, bodyPartInjury?: BodyPartInjury): FormGroup => {
		return this.formBuilder.group(
			{
				id: bodyPartInjury?.id,
				tableId: tableId,
				part: bodyPartInjury?.part,
				unassignedPart: bodyPartInjury?.unassignedPart,
				bodyPart: bodyPartInjury?.bodyPart,
				position: bodyPartInjury?.position,
				typeOfInjury: bodyPartInjury?.typeOfInjury,
				otherTypeOfInjury: bodyPartInjury ? bodyPartInjury?.otherTypeOfInjury : '',
			},
			{
				validators: [partOfBodyOrUnassignedPartOfBodyMandatory(), positionValidator(), otherTypeOfInjuryValidator],
			}
		);
	};
}
