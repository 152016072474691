import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelect } from '../../user/state/action';
import { EnvironmentLoader } from '../../core/config/environment-loader';

const SockJs = require('sockjs-client');
const Stomp = require('stompjs');

@Injectable()
export class WebSocketService {
	username: string;
	sessionId: string;

	constructor(private store: Store, private environmentLoader: EnvironmentLoader) {
		this.store.select(UserSelect.getUsername).subscribe((username) => (this.username = username));
	}

	public connect() {
		const socket = new SockJs(this.environmentLoader.getEnvironment().WEBSOCKET_URL, [], {
			sessionId: () => {
				this.sessionId = this.username.replace('.', '_') + '-' + this.makeRandom();
				return this.sessionId;
			},
		});
		return Stomp.over(socket);
	}

	public getSessionId() {
		return this.sessionId;
	}

	makeRandom() {
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
		const lengthOfCode = 10;
		let text = '';
		for (let i = 0; i < lengthOfCode; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}
}
