import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CmDashboardComponent } from './component/active-dashboard/cm-dashboard/cm-dashboard.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { dashboardReducer } from './state/dashboard.reducer';
import { DashboardEffect } from './state/dashboard.effect';
import { DASHBOARD_FEATURE_STATE_NAME } from './state/action/dashboard.select';
import { ArchivedDashboardComponent } from './component/archived-dashboard/archived-dashboard.component';
import { HrDashboardComponent } from './component/active-dashboard/hr-dashboard/hr-dashboard.component';
import { StatisticModule } from '../statistic/statistic.module';
import { ActiveDashboardComponent } from './component/active-dashboard/active-dashboard.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([DashboardEffect]),
		StoreModule.forFeature(DASHBOARD_FEATURE_STATE_NAME, dashboardReducer),
		StatisticModule,
	],
	declarations: [CmDashboardComponent, ArchivedDashboardComponent, HrDashboardComponent, ActiveDashboardComponent],
	exports: [ActiveDashboardComponent, ArchivedDashboardComponent],
})
export class DashboardModule {}
