import { EnumItem } from 'nc-utils';

export const EmploymentContractTypeEnumeration = {
	INDEFINITE_SALARY_MTH: new EnumItem('INDEFINITE_SALARY_MTH', 'indefiniteSalaryMth'),
	INDEFINITE_SALARY_MTH_AWT: new EnumItem('INDEFINITE_SALARY_MTH_AWT', 'indefiniteSalaryMthAWT'),
	INDEFINITE_SALARY_HRS: new EnumItem('INDEFINITE_SALARY_HRS', 'indefiniteSalaryHrs'),
	INDEFINITE_SALARY_NO_TIME_CONSTRAINT: new EnumItem('INDEFINITE_SALARY_NO_TIME_CONSTRAINT', 'indefiniteSalaryNoTimeConstraint'),
	FIXED_SALARY_MTH: new EnumItem('FIXED_SALARY_MTH', 'fixedSalaryMth'),
	FIXED_SALARY_HRS: new EnumItem('FIXED_SALARY_HRS', 'fixedSalaryHrs'),
	FIXED_SALARY_NO_TIME_CONSTRAINT: new EnumItem('FIXED_SALARY_NO_TIME_CONSTRAINT', 'fixedSalaryNoTimeConstraint'),
	APPRENTICE: new EnumItem('APPRENTICE', 'apprentice'),
	INTERNSHIP_CONTRACT: new EnumItem('INTERNSHIP_CONTRACT', 'internshipContract'),
};
