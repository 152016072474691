import * as ApplicationState from '../../state/app.state';
import { Employee } from '../../shared/model/employee';
import { Company } from '../../shared/model/company';
import { TableData } from 'nc-datatable';
import { Option } from 'nc-shared';
import { CompanyGroup } from '../model/company-group';
import { FrontendStyleEnumeration } from '../model/enumeration/frontend-style.enumeration';

export interface IntegrationState {
	companies: TableData;
	medicalInstitutions: TableData;
	companyGroups: TableData;
	company: Company;
	companyGroup: CompanyGroup;
	employees: TableData;
	employee: Employee;
	companyGroupOptions: Option[];
	responsibleUsers: Option[];
	companiesAssigned: TableData;
	companiesAvailable: TableData;
	frontendStyle: FrontendStyleEnumeration;
}

export interface State extends ApplicationState.State {
	integration: IntegrationState;
}

export const INTEGRATION_STATE: IntegrationState = {
	companies: { records: [] },
	medicalInstitutions: { records: [] },
	companyGroups: { records: [] },
	company: null,
	companyGroup: { assignedCompanies: { records: [] } },
	employees: { records: [] },
	employee: null,
	companyGroupOptions: [],
	responsibleUsers: [],
	companiesAssigned: { records: [] },
	companiesAvailable: { records: [] },
	frontendStyle: null,
};
