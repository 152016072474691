import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'ea-statistic-footer',
	templateUrl: './statistic-footer.component.html',
	styleUrls: ['./statistic-footer.component.scss'],
})
export class StatisticFooterComponent {
	@Input() formGroup: FormGroup;
	@Input() onSearch: () => void;
	@Input() onExport: () => void;
	@Input() isSearchVisible = false;
}
