<form [formGroup]="formGroup" fxFill fxLayout="column">
	<div fxLayout="row" fxLayoutGap="10px">
		<nc-select [id]="'example'" [formGroup]="formGroup" [data$]="exampleList$" formControlName="example" label="examples"> </nc-select>
		<nc-submit-button
			[id]="'declareIncident'"
			buttonLabel="declareIncident"
			(buttonClick)="onCheck()"
			[formGroup]="formGroup"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</form>
<div *ngIf="declareIncident$ | async as declareIncident">
	<div *ngIf="declareIncident.success">
		<table class="result-table">
			<tr>
				<td>{{ 'success' | translate }}:</td>
				<td>{{ declareIncident.success | translate }}</td>
			</tr>
			<tr>
				<td>{{ 'incidentCaseId' | translate }}:</td>
				<td>{{ declareIncident.incidentCaseId }}</td>
			</tr>
		</table>
	</div>
	<div *ngIf="!declareIncident.success">
		<table class="result-table">
			<tr>
				<td>{{ 'result' | translate }}:</td>
				<td class="error-message">{{ declareIncident.message | translate }}</td>
			</tr>
		</table>
	</div>
</div>
