import { createReducer, on } from '@ngrx/store';
import { STATISTIC_INITIAL_STATE, StatisticState } from './statistic.state';
import { StatisticApiAction, StatisticPageAction } from './action';

export const statisticReducer = createReducer<StatisticState>(
	STATISTIC_INITIAL_STATE,
	on(StatisticPageAction.setType, (state, action): StatisticState => {
		return {
			...state,
			type: action.statisticType,
		};
	}),
	on(StatisticApiAction.searchSuccess, (state, action): StatisticState => {
		return {
			...state,
			columns: action.columns,
			records: { records: action.records, total: action.totalRow },
			overallChartData: action.charts.overallChartData,
			totalCmChartData: action.charts.totalCmChartData,
			reasonsForSuccessfulCmChartData: action.charts.reasonsForSuccessfulCmChartData,
			reasonsForNotSuccessfulCmChartData: action.charts.reasonsForNotSuccessfulCmChartData,
			averageAbsenceDurationPerIllnessChartData: action.charts.averageAbsenceDurationPerIllnessChartData,
			averageAbsenceDurationPerAccidentChartData: action.charts.averageAbsenceDurationPerAccidentChartData,
		};
	}),
	on(StatisticPageAction.clearData, (): StatisticState => STATISTIC_INITIAL_STATE)
);
