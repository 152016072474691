<h4 mat-dialog-title>{{ 'past' | translate }}</h4>
<ea-container>
	<div fxLayout="column" fxLayoutGap="10px" [formGroup]="formGroup">
		<div fxLayout="row">
			<nc-input [formGroup]="formGroup" type="number" formControlName="pastAnnualTotal" label="pastAnnualTotal" [isReadonly]="true"></nc-input>
		</div>
		<nc-datatable
			*ngIf="data$"
			id="past-salaries-details"
			[columns]="getSaleriesDetailsColumns()"
			[data$]="data$"
			[filterConfig]="filterConfig"
			[rowClick]="openSalaryDetails"
		></nc-datatable>
		<ea-container [title]="selectedMonth.month">
			<nc-datatable
				id="past-salary-details"
				[columns]="getSaleryDetailsColumns()"
				[data$]="salaryTableData$"
				[filterConfig]="filterConfig"
			></nc-datatable>
		</ea-container>
	</div>
</ea-container>
