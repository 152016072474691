import * as ApplicationState from '../../state/app.state';

import { TableData } from 'nc-datatable';
import { LdResponsibleModel } from '../model/ld-responsible.model';
import { Option } from 'nc-shared';

export interface LdResponsibleState {
	ldResponsibles: TableData;
	currentLdResponsible: LdResponsibleModel;
	userOptions: Option[];
}

export interface State extends ApplicationState.State {
	ldResponsible: LdResponsibleState;
}

export const LD_RESPONSIBLE_INITIAL_STATE: LdResponsibleState = {
	ldResponsibles: { records: [] },
	currentLdResponsible: null,
	userOptions: [],
};
