<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div
		class="footer-navigation"
		fxLayout="row wrap"
		fxLayout.lt-md="column"
		fxLayoutGap="0px"
		fxLayoutGap.lt-md="5px"
		fxLayoutAlign="space-between"
	>
		<div fxLayout="row wrap" fxLayoutGap="5px">
			<nc-button [id]="'back'" [onClick]="goBack" [isRaised]="false" label="back"> </nc-button>
			<nc-button [id]="'history'" *ngIf="!isNote" [onClick]="history" [isRaised]="false" label="caseHistory"> </nc-button>
			<nc-button *ngIf="!isTask && !isNote" [id]="'tasks'" [onClick]="tasks" [isRaised]="false" label="tasks"> </nc-button>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="5px">
			<nc-button *ngIf="isTask" [id]="'add-note'" [onClick]="addNote" label="addNote"> </nc-button>
			<nc-button *ngIf="isTask" [id]="'add-task'" [onClick]="addTask" label="addTask"> </nc-button>
			<nc-submit-button
				*ngIf="!showEditButton && !isTask"
				[id]="'submit'"
				(buttonClick)="onSave()"
				dialogLabel="processing"
				[formGroup]="formGroup"
				[buttonLabel]="saveButtonLabel"
			>
			</nc-submit-button>
			<nc-button *ngIf="showEditButton" [id]="'edit-note'" [onClick]="editNote" [isRaised]="true" label="editNote"> </nc-button>
		</div>
	</div>
</div>
