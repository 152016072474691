<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<div fxLayout="row">
					<ea-container title="userType">
						<div fxLayout="row wrap" fxLayoutGap="5px" class="button-group">
							<nc-toggle-buttons
								[isDisabled]="formGroup.controls.id.value != null"
								[formGroup]="formGroup"
								class="margin-top-4"
								formControlName="type"
								[required]="true"
								[options]="userTypeEnum"
							>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								*ngIf="formGroup.value.type === permissionHR"
								[formGroup]="formGroup"
								label="isBranchUser"
								formControlName="isBranch"
								[options]="yesNo"
							>
							</nc-toggle-buttons>
						</div>
					</ea-container>
				</div>

				<ea-container title="company" *ngIf="formGroup.value.type === permissionHR">
					<nc-chips-input
						[formGroup]="formGroup"
						id="companies"
						class="margin-top-10"
						[serverSideFilter]="searchCompanies"
						placeholder="defaultSelectPleaceholder"
						formControlName="companies"
						[fetchSelected]="fetchCompanies"
						label="companyName"
						(paste)="onPaste($event)"
						(copy)="onCopy($event)"
						(focusout)="focusoutCompanies()"
					>
					</nc-chips-input>
					<div *ngIf="showCompanyError()" class="error-message">
						{{ 'mandatory.value' | translate }}
					</div>
				</ea-container>
				<ea-container title="accountData">
					<nc-toggle-buttons
						[formGroup]="formGroup"
						formControlName="hasTwoFactorAuthentication"
						label="requiredTwoFactorAuthentication"
						[options]="yesNo"
						class="margin-top-10"
					></nc-toggle-buttons>
					<nc-toggle-buttons
						*ngIf="formGroup.value.type === permissionHR"
						[formGroup]="formGroup"
						[options]="yesNo"
						class="margin-top-10"
						formControlName="hasNoReplyEmail"
						label="noReplyEmail"
					></nc-toggle-buttons>
					<nc-chips-input
						*ngIf="formGroup.value.type === permissionHR && formGroup.value.hasNoReplyEmail === yesConstants"
						[formGroup]="formGroup"
						id="companiesNoReplyEmail"
						[serverSideFilter]="searchCompanies"
						placeholder="defaultSelectPleaceholder"
						formControlName="companiesNoReplyEmail"
						[fetchSelected]="fetchCompanies"
						class="margin-top-10"
						label="companiesForNoReplyEmail"
					>
					</nc-chips-input>

					<nc-input id="first-name" [formGroup]="formGroup" formControlName="firstName" label="firstName"></nc-input>
					<nc-input id="last-name" [formGroup]="formGroup" formControlName="lastName" label="lastName"></nc-input>
					<nc-input id="username" [formGroup]="formGroup" formControlName="username" label="username"></nc-input>
					<nc-toggle-buttons [formGroup]="formGroup" formControlName="language" label="language" [options]="language"></nc-toggle-buttons>
					<nc-input id="neo-id" [formGroup]="formGroup" formControlName="neoId" label="neoId"></nc-input>
					<nc-input id="email" [formGroup]="formGroup" formControlName="email" label="email"></nc-input>
					<nc-input
						id="mobile-number"
						[formGroup]="formGroup"
						formControlName="mobileNumber"
						[isPhoneNumber]="true"
						label="phoneNumber"
					></nc-input>
					<div class="new-password" fxLayout="row" fxLayoutGap="5px">
						<nc-password
							id="password"
							buttonId="hide-password"
							fxFlex="95"
							[formGroup]="formGroup"
							formControlName="newPassword"
							label="password"
						></nc-password>
						<nc-button id="generate-password" [onClick]="generatePassword" [icon]="'key'"></nc-button>
					</div>
					<nc-password
						id="repeat-password"
						buttonId="hide-repeat-password"
						[formGroup]="formGroup"
						formControlName="repeatedPassword"
						label="repeatPassword"
					></nc-password>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
