import { createAction, props } from '@ngrx/store';
import { Absence } from '../../model/absence';
import { Moment } from 'moment';
import { MergeAbsenceRequest } from '../../model/request/merge-absence-request';
import { AbsenceAnnex } from '../../model/absence-annex';
import { TypedAction } from '@ngrx/store/src/models';
import { AccidentDescription } from '../../../kle/model/accident-description';
import { Treatments } from '../../../kle/model/treatments';
import { SaveResponse } from 'nc-utils';

export const initializeSelects = createAction('[Absence] Initialize selects');
export const submitNew = createAction('[Absence] Submit new absence', props<{ absence: Absence; isBranchOrHqHr?: boolean }>());
export const submitExisting = createAction('[Absence] Submit existing absence', props<{ absence: Absence }>());
export const deleteDraft = createAction('[Absence] Delete draft absence', props<{ code: string }>());
export const reject = createAction('[Absence] Reject absence', props<{ absence: Absence }>());
export const shouldActivate = createAction('[Absence] Should activate');
export const activate = createAction('[Absence] Activate');
export const canArchive = createAction('[Absence] Can archive', props<{ id: number }>());
export const archive = createAction('[Absence] Archive', props<{ id: number; comment: string; reason: string }>());
export const mobiliarDataSheet = createAction('[Absence] Mobiliar data sheet', props<{ code: string }>());
export const openGenerateDocumentDialog = createAction('[Absence] Open generate document dialog');
export const openFetchDocumentDialog = createAction('[Absence] Open fetch document dialog');
export const generateDocument = createAction(
	'[Absence] Generate document',
	props<{
		documentType: string;
		language: string;
		reportDate: Moment;
	}>()
);
export const fetchDocument = createAction('[Absence] Fetch document', props<{ documentType: string }>());
export const history = createAction('[Absence] History', props<{ code: string }>());
export const tasks = createAction('[Absence] Tasks', props<{ code: string }>());
export const fetchCompany = createAction('[Absence] Fetch company', props<{ id: string }>());
export const fetchAbsence = createAction('[Absence] Fetch absence', props<{ code: string }>());
export const fetchEmployee = createAction('[Absence] Fetch employee', props<{ id: string }>());
export const fetchEmployees = createAction('[Absence] Fetch employees for new case', props<{ companyId: string }>());
export const clearEmployees = createAction('[Absence] Clear employees');
export const getHistory = createAction('[Absence] Get history', props<{ code: string }>());
export const resendConfirmationEmail = createAction('[Absence] Resend confirmation email', props<{ code: string }>());
export const resendAnnexConfirmationEmail = createAction(
	'[Absence] Resend annex confirmation email',
	props<{
		code: string;
	}>()
);
export const reassignAbsence = createAction('[Absence] Reassign absence', props<{ code: string }>());
export const getReassignAbsenceEmployees = createAction(
	'[Absence] Get reassign absence employees',
	props<{
		code: string;
		showAll: boolean;
	}>()
);
export const reassignAbsenceSubmit = createAction(
	'[Absence] Reassign absence submit',
	props<{
		code: string;
		employeeId: number;
	}>()
);
export const mergeAbsence = createAction(
	'[Absence] Merge absence',
	props<{
		mergeAbsenceRequest: MergeAbsenceRequest;
	}>()
);
export const openUnmergeConfirmationDialog = createAction(
	'[Absence] Open unmerge confirmation dialog',
	props<{
		code: string;
	}>()
);
export const unmergeAbsence = createAction('[Absence] Unmerge absence', props<{ code: string }>());
export const getCurrentAbsence = createAction('[Absence] Get current absence', props<{ code: string }>());
export const getAbsenceAnnex = createAction(
	'[Absence] Get absence annex',
	props<{
		identifier: string;
		isShareInformation: boolean;
	}>()
);
export const saveAbsenceAnnex = createAction(
	'[Absence] Save absence annex',
	props<{
		data: AbsenceAnnex;
		isShareInformation: boolean;
	}>()
);
export const declareIncident = createAction(
	'[Absence] Declare incident',
	props<{ absenceCode: string; sendWithoutContract: boolean; incident: any; relapse: any }>()
);
export const openDocumentWarningDialog = createAction(
	'[Absence] Open document warning dialog',
	props<{
		actionToDispatch: TypedAction<string>;
	}>()
);
export const absenceSavedSuccessfully = createAction(
	'[Absence] Existing absence saved successfully',
	props<{
		absenceSavedSuccessfully: boolean;
	}>()
);

export const restartAbsence = createAction('[Absence] Restart absence');
export const getIncidentContext = createAction('[Absence] Get incident context', props<{ absenceCode: string }>());
export const saveAccidentDescription = createAction(
	'[Absence] Save accident description',
	props<{
		data: AccidentDescription;
	}>()
);

export const saveTreatments = createAction(
	'[Absence] Save treatments',
	props<{
		data: Treatments;
	}>()
);
export const setAbsenceCode = createAction('[Absence] Set absence code', props<{ code: string }>());

export const openAbsenceExistingDialog = createAction('[Absence] Existing dialog', props<{ absence: any }>());
