import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IntegrationState } from '../integration.state';

export const INTEGRATION_FEATURE_STATE_NAME = 'integration';
const FEATURE_STATE = createFeatureSelector<IntegrationState>(INTEGRATION_FEATURE_STATE_NAME);

export const getCompanies = createSelector(FEATURE_STATE, (state) => state.companies);
export const getMedicalInstitutions = createSelector(FEATURE_STATE, (state) => state.medicalInstitutions);
export const getCompany = createSelector(FEATURE_STATE, (state) => state.company);
export const getEmployees = createSelector(FEATURE_STATE, (state) => state.employees);
export const getEmployee = createSelector(FEATURE_STATE, (state) => state.employee);
export const getCompanyGroupOptions = createSelector(FEATURE_STATE, (state) => state.companyGroupOptions);
export const getResponsibleUsers = createSelector(FEATURE_STATE, (state) => state.responsibleUsers);
export const getCompanyGroups = createSelector(FEATURE_STATE, (state) => state.companyGroups);
export const getCompanyGroup = createSelector(FEATURE_STATE, (state) => state.companyGroup);
export const getCompanyGroupId = createSelector(FEATURE_STATE, (state) => state.companyGroup?.id);
export const getCompanyGroupCompanies = createSelector(FEATURE_STATE, (state) => state.companyGroup?.assignedCompanies);
export const getCompaniesAssigned = createSelector(FEATURE_STATE, (state) => state.companiesAssigned);
export const getCompaniesAvailable = createSelector(FEATURE_STATE, (state) => state.companiesAvailable);
export const getFrontendStyle = createSelector(FEATURE_STATE, (state) => state.frontendStyle);
