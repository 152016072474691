import { ExportEnumeration } from '../enumeration/export.enumeration';
import { ExportExtensionEnumeration } from '../enumeration/export-extension.enumeration';

export class ExportRequest {
	type: ExportEnumeration;
	extension: ExportExtensionEnumeration;
	filter: any;

	constructor(type: ExportEnumeration, extension: ExportExtensionEnumeration, filter: any) {
		this.type = type;
		this.extension = extension;
		this.filter = filter;
	}
}
