import { Injectable } from '@angular/core';
import { dateToString, FormUtils } from 'nc-utils';
import { MilestoneModel } from '../model/milestone.model';

@Injectable({
	providedIn: 'root',
})
export class SaveRequestFactory {
	createTask(task: any): FormData {
		return FormUtils.objectToFormData({
			documents: task.documents,
			model: {
				...task,
				done: task.done === 'yes',
				important: task.important === 'yes',
				status: task.status === 'yes',
				dueDate: dateToString(task.dueDate),
				replicateForActiveAbsencesSet: task.replicateForActiveAbsencesSet === 'yes',
			},
		});
	}

	createNote(note: any): FormData {
		return FormUtils.objectToFormData({
			documents: note.documents,
			model: {
				...note,
				replicateForActiveAbsencesSet: note.replicateForActiveAbsencesSet === 'yes',
			},
		});
	}

	createDoneMilestoneModel(milestone: MilestoneModel): any {
		return {
			model: {
				absenceId: milestone.absenceId,
				milestoneSettingsId: milestone.settingsId,
			},
		};
	}
}
