import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserSelect } from '../../../user/state/action';
import { SafeUrl } from '@angular/platform-browser';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	templateUrl: './preview-document.component.html',
	styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentComponent extends BaseComponent implements OnInit {
	pdfUrl: string;
	language: string;
	fileName: string;
	token: string;
	isPdf: boolean;
	isImage: boolean;
	imageUrl: SafeUrl;

	constructor(
		private store: Store,
		private activatedRoute: ActivatedRoute,
		private environmentLoader: EnvironmentLoader,
		private renderer: Renderer2,
		private el: ElementRef
	) {
		super();
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params) => {
			const fileName = params.filename;
			this.language = 'en-US';
			this.isPdf = fileName.endsWith('.pdf');
			this.isImage = fileName.endsWith('.png') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.bmp');
			this.fileName = `${fileName}_${this.language}`;
			this.pdfUrl = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/documents/${params.documentId}`;

			if (!this.isPdf) {
				this.store.dispatch(SettingsPageAction.downloadDocumentForPreview({ id: params.documentId }));
			}

			this.store
				.select(SettingsSelect.getDocument)
				.pipe(
					takeUntil(this.destroy$),
					filter((document) => document != null && this.isImage)
				)
				.subscribe((document) => {
					const unsafeImageUrl = URL.createObjectURL(document);
					const imgElement = this.renderer.selectRootElement('#imageId', true);
					this.renderer.setAttribute(imgElement, 'src', unsafeImageUrl);
				});
		});

		this.store.select(UserSelect.getAccessToken).subscribe((token) => (this.token = token != null ? `Bearer ${token}` : token));
	}
}
