import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../shared/service/search.service';
import { Store } from '@ngrx/store';
import { KlePageAction, KleSelect } from '../../../state/action';
import { BaseComponent } from 'nc-shared';

@Component({
	selector: 'ea-treatments-form',
	templateUrl: './treatments-form.html',
	styleUrls: ['./treatments-form.scss'],
})
export class TreatmentsFormComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input() formGroup: FormGroup;

	treatmentsSubscription$: Subscription;

	constructor(private searchService: SearchService, private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.treatmentsSubscription$ = this.store.select(KleSelect.getTreatment).subscribe((treatment) => {
			const tableId = this.formGroup.value.tableId;
			this.formGroup.patchValue(treatment);
			this.formGroup.controls.tableId.patchValue(tableId);
		});

		this.treatmentsValueChanges();
	}

	searchMedicalInstitutions = (term) => of(term).pipe(switchMap((query) => this.searchService.getMedicalInstitutions(query)));

	ngOnDestroy(): void {
		this.store.dispatch(KlePageAction.clearTreatmentsForm());
		this.treatmentsSubscription$.unsubscribe();
	}

	treatmentsValueChanges(): void {
		this.formGroup.controls.treatmentId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (id) {
				this.store.dispatch(KlePageAction.getMedicalInstitution({ id: Number(id) }));
			}
		});
	}
}
