import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatatableColumn, TableData } from 'nc-datatable';
import { Observable, of, Subscription } from 'rxjs';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { DashboardPageAction, DashboardSelect } from '../../state/action';
import { takeUntil } from 'rxjs/operators';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';
import { UserSelect } from '../../../user/state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { TableColumns } from '../../model/table-columns';
import { CompaniesSelectFactory } from '../../../shared/utils/companies-select-factory';

@Component({
	templateUrl: './archived-dashboard.component.html',
	styleUrls: ['./archived-dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchivedDashboardComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	formGroup: FormGroup;
	filterConfig: FilterConfig;
	globalFormGroup: FormGroup;

	responsibleUsers$: Observable<Option[]>;
	archivedAbsences$: Observable<TableData>;
	archivedAbsencesColumns: DatatableColumn[];
	loggedUser: string;
	loggedUserSubscription$: any;
	userPreferences: {};
	caseFilter: string;
	responsibleUserControl: any;
	userPreferencesSubscription$: Subscription;
	userPermissionsSubscription$: any;
	companiesIds: number[];
	companies$: Observable<Option[]>;
	companiesControl: any;
	isHrUser: boolean;
	getHrCompaniesIdsSubscription$: Subscription;
	companiesSubscription: Subscription;

	CASE_FILTER = 'caseFilter';
	HR_USER_TYPE = 'HR';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store, formBuilder: FormBuilder, private companiesSelectFactory: CompaniesSelectFactory) {
		super();

		this.loggedUserSubscription$ = this.store.select(UserSelect.getUsername).subscribe((value) => (this.loggedUser = value));
		this.archivedAbsences$ = this.store.select(DashboardSelect.getArchivedAbsences);

		this.userPermissionsSubscription$ = this.store
			.select(UserSelect.getUserType)
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => (this.isHrUser = value === this.HR_USER_TYPE));

		this.formGroup = formBuilder.group({
			responsibleUser: [''],
			companies: [],
		});

		this.isHrUser ? this.initialiseHrUserArchivedPageData() : this.initialiseCmUserArchivedPageData();

		this.userPreferencesSubscription$ = this.store
			.select(SettingsSelect.getArchivedCasesLocalStorageData(this.loggedUser))
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				this.userPreferences = value;
				this.isHrUser ? this.setHrUserLocalStorageData(value) : this.setCmLocalStorageData(value);
			});

		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
			filterValues: {
				globalFilter: this.userPreferences[this.CASE_FILTER],
			},
		};
	}

	ngOnInit(): void {
		this.caseFilter = this.userPreferences[this.CASE_FILTER];

		if (!this.isHrUser) {
			this.responsibleUserControl.valueChanges
				.pipe(takeUntil(this.destroy$))
				.subscribe((value) => this.store.dispatch(DashboardPageAction.getArchivedAbsences({ selectedUser: value.toString() })));
		} else {
			this.companiesControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
				this.store.dispatch(
					DashboardPageAction.getHrArchivedCases({
						companyIds: this.companiesControl.value.map((id) => {
							if (id.length > 1) {
								return id.split(',').map(Number);
							} else {
								return Number(id);
							}
						}),
					})
				)
			);
		}
	}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${rowData.code}` }));
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
		this.userPermissionsSubscription$.unsubscribe();
		this.isHrUser && this.companiesSubscription.unsubscribe();

		this.filterConfig.globalFilterFormGroup.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => (this.caseFilter = value.globalFilter));
		this.isHrUser ? this.getHrCompaniesIdsSubscription$.unsubscribe() : '';
	}

	ngOnDestroy(): void {
		this.userPreferencesSubscription$.unsubscribe();
		const storageObject = {
			caseFilter: this.caseFilter,
			responsibleUser: !this.isHrUser ? this.responsibleUserControl.value : '',
			selectedCompanies: this.isHrUser ? this.companiesControl.value : '',
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(SettingsPageAction.setArchivedCasesValues({ data: storageObject, username: this.loggedUser }));
	}

	initialiseCmUserArchivedPageData(): void {
		this.archivedAbsencesColumns = TableColumns.ARCHIVED_ABSENCES_COLUMNS;
		this.store.dispatch(DashboardPageAction.openArchivedDashboard());
		this.responsibleUserControl = this.formGroup.get('responsibleUser');
		this.responsibleUsers$ = this.store.select(DashboardSelect.getResponsibleUsers);
	}

	initialiseHrUserArchivedPageData(): void {
		this.archivedAbsencesColumns = TableColumns.HR_ARCHIVED_ABSENCES_COLUMNS;
		this.companiesControl = this.formGroup.get('companies');
		this.getHrCompaniesIdsSubscription$ = this.store.select(UserSelect.getUserCompanyIds).subscribe((value) => {
			this.companiesIds = value;
			this.store.dispatch(DashboardPageAction.getHrCompanies({ companyIds: this.companiesIds }));
		});
		this.companiesSubscription = this.store
			.select(UserSelect.getCompanyFilterOptions)
			.subscribe(
				(companyFilterOption) => (this.companies$ = of(this.companiesSelectFactory.createCompanySelectModelWithIdsArray(companyFilterOption)))
			);
	}

	setCmLocalStorageData(value: any): void {
		this.responsibleUserControl.patchValue(Number.parseInt(value.responsibleUser, 10));
		this.store.dispatch(DashboardPageAction.getArchivedAbsences({ selectedUser: value.responsibleUser }));
	}

	setHrUserLocalStorageData(value: any): void {
		if (value.selectedCompanies) {
			this.store.dispatch(DashboardPageAction.getHrArchivedCases({ companyIds: value.selectedCompanies }));
			this.companiesControl.patchValue(value.selectedCompanies);
		} else {
			this.store.dispatch(DashboardPageAction.getHrArchivedCases({ companyIds: this.companiesIds }));
			this.companiesControl.patchValue(this.companiesIds);
		}
	}
}
