import { Injectable } from '@angular/core';
import { dateTimeToString, dateToString } from 'nc-utils';
import { SynchronizeIncidentRequest } from '../model/request/synchronize-incident-request';
import { AdditionsPerson } from '../model/additions-person';
import { AnnualSalary } from '../model/annual-salary';
import { PersonMutatedServerData } from '../model/person-mutated';
import { Moment } from 'moment';
import { FlagEnumeration, toBoolean } from 'src/app/shared/enumeration/flag.enumeration';
import { AccidentDescription } from '../model/accident-description';
import { IncapacitiesToWork } from '../model/incapacities-to-work';
import { OtherEmployers } from '../model/other-employers';
import { Payment } from '../model/payment';
import { ShortTimeWork } from '../model/short-time-work';
import { TakeWorkUpFully } from '../model/take-work-up-fully';
import { KleAbsenceStory } from '../model/kle-absence-story';
import { KleAttachmentStory } from '../model/kle-attachment-story';
import { ProcessControl } from '../model/process-control';
import { IncapacityToWork } from '../model/incapacity-to-work';
import { Agreement } from '../model/agreement';

@Injectable({ providedIn: 'root' })
export class RequestFactoryService {
	generateSyncIncidentRequest(data): SynchronizeIncidentRequest {
		return {
			incidentContext: data.incidentContext,
			agreement: this.getAgreement(data.agreement),
			specialCoverage: data.specialCoverage,
			otherEmployers: this.getOtherEmployersBooleanValue(data.otherEmployers?.value),
			processCtrlRequest: this.getProcessControlValue(data.processControl),
			takeWorkUpFully: this.getTakeWorkUpFullyRequestValue(data.takeWorkUpFully),
			additionsPerson: this.getAdditionsPersonValue(data.additionsPerson),
			incapacitiesToWork: this.getIncapacitiesToWorkValue(data.incapacity),
			shortTimeWork: this.getShortTimeWorkBooleanValue(data.shortTimeWork?.value),
			specialCode: data.specialCode,
			payment: this.getPaymentValue(data.payment),
			treatments: data.treatments,
			accidentDescription: this.getAccidentDescriptionValue(data.accidentDescription),
			annualSalary: this.getAnnualSalaryValue(data.annualSalary),
			absenceStory: this.getAbsenceStory(data.absenceStory),
			personMutated: this.getPersonMutatedValue(data.personMutated),
			attachmentStory: this.getAttachmentStory(data.attachmentStory),
		};
	}

	getPersonMutatedValue(personMutated): PersonMutatedServerData {
		if (personMutated) {
			return {
				...personMutated,
				validAsOf: this.getDate(personMutated.validAsOf),
				withdrawalDate: this.getDate(personMutated.withdrawalDate),
			};
		}
	}

	getDate(date: Moment | string): string {
		return date ? this.parseDateIfNotString(date) : '';
	}

	parseDateIfNotString(date: Moment | string): string {
		return typeof date === 'string' ? date : dateToString(date);
	}

	getAnnualSalaryValue(annualSalary): AnnualSalary {
		if (annualSalary) {
			return {
				...annualSalary,
				contractValidAsOf: dateToString(annualSalary.contractValidAsOf),
				accidentStartDate: dateToString(annualSalary.accidentStartDate),
			};
		}
	}

	getAbsenceStory(absenceStory): KleAbsenceStory {
		if (absenceStory) {
			for (let item of absenceStory.absences) {
				item['from'] = typeof item['from'] === 'string' ? item['from'] : dateToString(item['from']);
				item['until'] = typeof item['until'] === 'string' ? item['until'] : dateToString(item['until']);
			}
			return { absences: absenceStory?.absences };
		}
	}

	getAccidentDescriptionValue(accidentDescription): AccidentDescription {
		if (accidentDescription) {
			return {
				...accidentDescription,
				lastWorkingDay: typeof accidentDescription.lastWorkingDay !== 'string' ? dateToString(accidentDescription.lastWorkingDay) : '',
				lastWorkingDayDateOfDeath:
					typeof accidentDescription.lastWorkingDayDateOfDeath !== 'string'
						? dateToString(accidentDescription.lastWorkingDayDateOfDeath)
						: '',
				dateOfDeath: typeof accidentDescription.dateOfDeath !== 'string' ? dateToString(accidentDescription.dateOfDeath) : '',
				relapseDate: typeof accidentDescription.relapseDate !== 'string' ? dateToString(accidentDescription.relapseDate) : '',
				exactDateTime:
					typeof accidentDescription.exactDateTime !== 'string' && accidentDescription.exactDateTime
						? accidentDescription.exactDateTime.format('YYYY-MM-DDTHH:mm:ss')
						: '',
				approximate: typeof accidentDescription.approximate !== 'string' ? dateToString(accidentDescription.approximate) : '',
			};
		}
	}

	getPaymentValue(payment): Payment {
		if (payment) {
			return {
				...payment,
				validAsOf: dateToString(payment.validAsOf),
			};
		}
	}

	getProcessControlValue(processControl): ProcessControl {
		if (processControl) {
			return {
				...processControl,
				changeIdentitiesValidAsOf: dateTimeToString(processControl.changeIdentitiesValidAsOf),
			};
		}
	}

	getShortTimeWorkBooleanValue(shortTimeWork: FlagEnumeration): ShortTimeWork {
		if (shortTimeWork) {
			return {
				value: toBoolean(shortTimeWork),
			};
		}
	}

	getIncapacitiesToWorkValue(incapacitiesToWork): IncapacitiesToWork {
		if (incapacitiesToWork) {
			let incapacities = !toBoolean(incapacitiesToWork.withoutIncapacity) ? this.mapIncapacities(incapacitiesToWork.incapacities) : [];
			return {
				withoutIncapacity: toBoolean(incapacitiesToWork.withoutIncapacity),
				details: incapacities,
			};
		}
	}

	mapIncapacities(incapacities): IncapacityToWork[] {
		return incapacities.map((x) => {
			return {
				...x,
				validFrom: dateToString(x.validFrom),
				validTo: dateToString(x.validTo),
				takeWorkUpFullyDate: dateToString(x.takeWorkUpFullyDate),
			};
		});
	}

	getTakeWorkUpFullyRequestValue(takeWorkUpFullyFormData): TakeWorkUpFully {
		if (takeWorkUpFullyFormData) {
			return {
				...takeWorkUpFullyFormData,
				takeWorkUpFullyDate: takeWorkUpFullyFormData.takeWorkUpFullyDate ? dateToString(takeWorkUpFullyFormData.takeWorkUpFullyDate) : '',
				expectedLastDayOfIncapacityToWork: takeWorkUpFullyFormData.expectedLastDayOfIncapacityToWork
					? dateToString(takeWorkUpFullyFormData.expectedLastDayOfIncapacityToWork)
					: '',
				expectedIncapacityToWork: takeWorkUpFullyFormData.expectedIncapacityToWork,
				noIncapacityForWork: takeWorkUpFullyFormData.noIncapacityForWork,
			};
		}
	}

	getAdditionsPersonValue(additionsPerson): AdditionsPerson {
		if (additionsPerson) {
			return {
				...additionsPerson,
				validAsOf: dateToString(additionsPerson.validAsOf),
				contractFixedUntil: dateToString(additionsPerson.contractFixedUntil),
				contractDissolveFrom: dateToString(additionsPerson.contractDissolveFrom),
			};
		}
	}

	getOtherEmployersBooleanValue(otherEmployers: FlagEnumeration): OtherEmployers {
		if (otherEmployers) {
			return {
				value: toBoolean(otherEmployers),
			};
		}
	}

	getAttachmentStory(attachmentStory): KleAttachmentStory {
		let story = new KleAttachmentStory();
		story.attachments = [];
		if (attachmentStory) {
			for (let selected of attachmentStory.selectedIds) {
				const selectedDoc = attachmentStory.attachments.find((el) => el.id === selected.value && el.contentType);
				if (selectedDoc) {
					story.attachments.push(selectedDoc);
				}
			}
			return { attachments: story.attachments };
		}
	}
	getAgreement(agreement?: Agreement): Agreement | undefined {
		if (agreement) {
			return {
				...agreement,
				noOtherInsurance: !agreement.noOtherInsurance,
			};
		}
		return agreement;
	}
}
