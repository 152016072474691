import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormGroup } from '@angular/forms';
import { SettingsSelect } from '../../../state/settings/action';
import { Store } from '@ngrx/store';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	selector: 'ea-unauthenticated-layout',
	templateUrl: './unauthenticated-layout.component.html',
	styleUrls: ['./unauthenticated-layout.component.scss'],
})
export class UnauthenticatedLayoutComponent implements OnInit {
	@Input() formGroup: FormGroup;

	isMobile: boolean;
	languageCode: string;
	currentTheme: string;

	constructor(private breakpointObserver: BreakpointObserver, private store: Store, private environmentLoader: EnvironmentLoader) {}

	ngOnInit(): void {
		this.currentTheme = this.environmentLoader.getEnvironment().theme;
		this.breakpointObserver.observe(['(max-width: 900px)']).subscribe((result) => (this.isMobile = result.matches));
		this.store.select(SettingsSelect.getLanguage).subscribe((language) => (this.languageCode = language === 'de' ? '' : `${language}/`));
	}
}
