<form [formGroup]="formGroup" fxFill fxLayout="column">
	<div fxLayout="row" fxLayoutGap="10px">
		<nc-input
			type="number"
			[id]="'secondOperand'"
			ncAutofocus
			[shouldAutofocus]="true"
			[formGroup]="formGroup"
			formControlName="secondOperand"
			label="secondOperand"
		></nc-input>
		<nc-submit-button
			[id]="'checkInteroperability'"
			buttonLabel="checkInteroperability"
			(buttonClick)="onCheck()"
			[formGroup]="formGroup"
			[disabled]="!formGroup.controls['secondOperand'].value"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</form>
<div *ngIf="interoperability$ | async as interoperability">
	<div *ngIf="interoperability.success">
		<table class="result-table">
			<tr>
				<td>{{ 'result' | translate }}:</td>
				<td>{{ interoperability.message | translate }}</td>
			</tr>
			<tr>
				<td>{{ 'umlautStringIsCorrect' | translate }}:</td>
				<td>{{ interoperability.umlautStringIsCorrect }}</td>
			</tr>
			<tr>
				<td>{{ 'firstOperandIsCorrect' | translate }}:</td>
				<td>{{ interoperability.firstOperandIsCorrect }}</td>
			</tr>
			<tr>
				<td>{{ 'isResponseUmlautStringCorrect' | translate }}:</td>
				<td>{{ interoperability.responseUmlautStringCorrect }}</td>
			</tr>
			<tr>
				<td>{{ 'isAdditionResultCorrect' | translate }}:</td>
				<td>{{ interoperability.additionCorrect }}</td>
			</tr>
			<tr>
				<td>{{ 'isSubtractionResultCorrect' | translate }}:</td>
				<td>{{ interoperability.subtractionCorrect }}</td>
			</tr>
			<tr>
				<td>{{ 'umlautString' | translate }}:</td>
				<td>{{ interoperability.umlautString }}</td>
			</tr>
			<tr>
				<td>{{ 'additionResult' | translate }}:</td>
				<td>{{ interoperability.additionResult }}</td>
			</tr>
			<tr>
				<td>{{ 'subtractionResult' | translate }}:</td>
				<td>{{ interoperability.subtractionResult }}</td>
			</tr>
			<tr>
				<td>{{ 'systemDateTime' | translate }}:</td>
				<td>{{ interoperability.dateTime }}</td>
			</tr>
		</table>
	</div>
	<div *ngIf="!interoperability.success">
		<table class="result-table">
			<tr>
				<td>{{ 'result' | translate }}:</td>
				<td class="error-message">{{ interoperability.message | translate }}</td>
			</tr>
		</table>
	</div>
</div>
