import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccessManagementEffect } from './state/access-management.effect';
import { ACCESS_MANAGEMENT_FEATURE_STATE_NAME } from './state/action/access-management.select';
import { accessManagementReducer } from './state/access-management.reducer';
import { AuthorityComponent } from './component/authority/authority.component';
import { AuthorityFormComponent } from './component/authority-form/authority-form.component';
import { AuthorityFooterComponent } from './component/footer/authority-footer.component';
import { DeleteConfirmationDialogComponent } from './component/delete-confirmation-dialog/delete-confirmation-dialog.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([AccessManagementEffect]),
		StoreModule.forFeature(ACCESS_MANAGEMENT_FEATURE_STATE_NAME, accessManagementReducer),
	],
	declarations: [AuthorityComponent, AuthorityFormComponent, AuthorityFooterComponent, DeleteConfirmationDialogComponent],
	exports: [AuthorityComponent, AuthorityFormComponent],
})
export class AccessManagementModule {}
