<div id="milestone-settings-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div mat-dialog-title>
		<mat-icon class="dialog-title-icon">delete</mat-icon>
		<span class="dialog-title-text">{{ title | translate }}</span>
	</div>
	<mat-dialog-content id="milestone-settings-dialog-content" fxLayout="column" fxLayoutGap="10px">
		{{ message1 | translate : { deleteEntityName: deleteEntityName } }} {{ message2 | translate }}
	</mat-dialog-content>
	<mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
			<nc-button [id]="'yes'" label="yes" [mat-dialog-close]="{ success: true, id: id }"></nc-button>
			<nc-button [id]="'no'" label="no" [mat-dialog-close]="{ success: false }"></nc-button>
		</div>
	</mat-dialog-actions>
</div>
