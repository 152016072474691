import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { KlePageAction, KleSelect } from '../../state/action';
import { BehaviorSubject } from 'rxjs';
import { TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { DialogService } from 'nc-utils';
import { SimpleMessageFormComponent } from './simple-message-form/simple-message-form.component';
import { takeUntil } from 'rxjs/operators';
import { MessageCounter } from '../../model/message-counter';
import { DialogMessageTypeEnum } from '../../model/enums/dialog-message-type.enum';
import { DialogMessageOverview } from '../../model/dialog-message-overview';

@Component({
	selector: 'ea-dialog-message',
	templateUrl: './dialog-message.component.html',
})
export class DialogMessageComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;
	@Input() isBranchHr: boolean;

	simpleData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	taskDeadlineData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	taskConfirmationData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	contactPersonData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	workplaceData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	reasonData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	freeMessageData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	messageCounter: MessageCounter = new MessageCounter();

	filterConfig: FilterConfig = {
		showFilter: false,
		filterValues: {},
	};

	constructor(private formBuilder: FormBuilder, private store: Store, private dialogService: DialogService) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(KleSelect.getDialogMessages)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				if (data?.length) {
					this.messageCounter = this.countMessages(data);
					this.filterData(data);
				}
			});
	}

	newSimpleMessage(): void {
		const dialogConfig = {
			width: '700px',
			data: { absenceCode: this.absenceCode, formGroup: this.formGroup },
		};

		const dialogRef = this.dialogService.open(SimpleMessageFormComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(() => {
			this.store.dispatch(KlePageAction.clearDialogMessage());
		});
	}

	countMessages(data: DialogMessageOverview[]): MessageCounter {
		let messageCounter = new MessageCounter();
		if (data?.length) {
			data.forEach((record) => this.setCounterForMessageType(record.dialogMessageType, messageCounter));
		}
		return messageCounter;
	}

	setCounterForMessageType(type: string, messageCounter: MessageCounter): void {
		switch (type) {
			case DialogMessageTypeEnum.SIMPLE_MESSAGE.code:
				messageCounter.simpleMessageNumber++;
				break;
			case DialogMessageTypeEnum.TASK_WITH_DEADLINE.code:
				messageCounter.taskWithDeadlineMessageNumber++;
				break;
			case DialogMessageTypeEnum.TASK_WITH_CONFIRMATION.code:
				messageCounter.taskWithConfirmationMessageNumber++;
				break;
			case DialogMessageTypeEnum.CONTACT_PERSON.code:
				messageCounter.contactPersonMessageNumber++;
				break;
			case DialogMessageTypeEnum.WORKPLACE_DESCRIPTION.code:
				messageCounter.workPlaceMessageNumber++;
				break;
			case DialogMessageTypeEnum.REASON_FOR_ABSENCE.code:
				messageCounter.reasonForAbsenceMessageNumber++;
				break;
			case DialogMessageTypeEnum.FREE_DIALOG_MESSAGE.code:
				messageCounter.freeDialogMessageNumber++;
				break;
		}
	}

	filterData(data: any[]) {
		this.simpleData$.next({
			records: data.filter((record) => record.dialogMessageType === DialogMessageTypeEnum.SIMPLE_MESSAGE.code),
		});
		this.taskDeadlineData$.next({
			records: data.filter((record) => record.dialogMessageType === DialogMessageTypeEnum.TASK_WITH_DEADLINE.code),
		});
		this.taskConfirmationData$.next({
			records: data.filter((record) => record.dialogMessageType === DialogMessageTypeEnum.TASK_WITH_CONFIRMATION.code),
		});
		this.reasonData$.next({
			records: data.filter((record) => record.dialogMessageType === DialogMessageTypeEnum.REASON_FOR_ABSENCE.code),
		});
		this.workplaceData$.next({
			records: data.filter((record) => record.dialogMessageType === DialogMessageTypeEnum.WORKPLACE_DESCRIPTION.code),
		});
		this.contactPersonData$.next({
			records: data.filter((record) => record.dialogMessageType === DialogMessageTypeEnum.CONTACT_PERSON.code),
		});
		this.freeMessageData$.next({
			records: data.filter((record) => record.dialogMessageType === DialogMessageTypeEnum.FREE_DIALOG_MESSAGE.code),
		});
	}
}
