import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompaniesSelectModel } from '../../shared/model/companies-select.model';
import { map } from 'rxjs/operators';
import { CompaniesSelectFactory } from '../../shared/utils/companies-select-factory';
import { Option } from 'nc-shared';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class CompaniesSelectService {
	private readonly GET_HR_COMPANIES = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/companies/company-options`;

	constructor(private httpClient: HttpClient, private factory: CompaniesSelectFactory, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Get list of companies
	 * @param companyIds List of company identifiers
	 */
	getCompanyOptions(companyIds: number[]): Observable<Option[]> {
		return this.httpClient
			.get<CompaniesSelectModel[]>(`${this.GET_HR_COMPANIES}/${companyIds}`)
			.pipe(map((result) => this.factory.createCompanySelectModel(result)));
	}
}
