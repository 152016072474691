import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent, Option } from 'nc-shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumUtils } from 'nc-utils';
import { booleanToFlagEnumeration, FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { Store } from '@ngrx/store';
import { KleSelect } from '../../state/action';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ea-short-time-work',
	templateUrl: './short-time-work.component.html',
})
export class ShortTimeWorkComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;

	yesNo: Option[];

	shortTimeWorkGroup = this.formBuilder.group({
		value: [FlagEnumeration.yes as FlagEnumeration],
	});

	constructor(private store: Store, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.yesNo = EnumUtils.toItems(FlagEnumeration);
		this.formGroup.addControl(KleFormGroupEnum.SHORT_TIME_WORK, this.shortTimeWorkGroup);

		this.store
			.select(KleSelect.getShortTimeWork)
			.pipe(takeUntil(this.destroy$))
			.subscribe((shortTimeWork) => this.shortTimeWorkGroup.controls.value.patchValue(booleanToFlagEnumeration(shortTimeWork?.value)));
	}
}
