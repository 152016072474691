export class AppConstants {
	static readonly CONTRACT_TYPE_TEMPORARY = 'TEMPORARY';
	static readonly NEO_ENV_PKPRO = 'PKPRO';
	static readonly STYLE_ENV_ECARE = 'ECARE';

	static readonly TELLCO_THEME = 'tellco-theme';

	static readonly FORM_NOT_VALID = 'FORM_NOT_VALID';

	static readonly SWISSDEC_CREATOR = 'swissdec';
}
