import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService, EnumUtils } from 'nc-utils';
import { booleanToFlag, FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { ContractTypeEnumeration } from '../../../user/model/contract-type.enumeration';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { UserSelect } from '../../../user/state/action';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Company } from '../../../shared/model/company';
import { EmployeeDetailsComponent } from '../employee-details/employee-details.component';
import { CompanyModelFactory } from '../../service/company-model.factory';
import { NeoEnvironmentEnumeration } from '../../../shared/enumeration/neo-environment.enumeration';
import { IntegrationPageAction, IntegrationSelect } from '../../state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { FrontendStyleEnumeration } from '../../model/enumeration/frontend-style.enumeration';
import { TranslateService } from '@ngx-translate/core';
import { PermissionEnumeration } from '../../../shared/enumeration/permission.enumeration';

@Component({
	templateUrl: './company-details.component.html',
	styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent extends CanDeactivateFormComponent implements OnInit, AfterViewInit {
	filterConfig: FilterConfig;
	globalFormGroup: FormGroup;
	yesNo: Option[];
	contractType: Option[];
	neoEnvironment$: Observable<Option[]>;
	employeeColumns: DatatableColumn[];
	employees$: Observable<TableData>;
	company$: Observable<Company>;
	companyGroups$: Observable<Option[]>;
	responsibleUsers$: Observable<Option[]>;
	isCmAdmin: boolean;
	isAdmin: boolean;
	isHrUser: boolean;
	isReadOnly: boolean;
	frontendStyleOptions$: Observable<Option[]>;
	yesNoEnum = FlagEnumeration;

	formGroup = this.formBuilder.group({
		id: null as string,
		name: null as string,
		uid: null as string,
		easyTempId: null as number,
		neoId: null as number,
		neoEnvironment: null as string,
		neoGroupId: null as number,
		isMaster: null as string,
		contractType: null as string,
		companyGroupId: null as string,
		defaultAssigneeId: null as string,
		defaultAssigneeName: null as string,
		ownerFirstName: null as string,
		ownerLastName: null as string,
		city: null as string,
		street: null as string,
		postbox: null as string,
		zip: null as string,
		country: null as string,
		canton: null as string,
		companyWorkplaces: null as string,
		isSwicaInsured: null as string,
		isKleEnabled: null as string,
		isPkProInsured: null as string,
		frontendStyle: null as string,
	});

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private dialogService: DialogService,
		private factory: CompanyModelFactory,
		private translateService: TranslateService,
		private cdRef: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this.store.dispatch(IntegrationPageAction.getEmployees({ companyId: params.id }));
			this.store.dispatch(IntegrationPageAction.getCompany({ companyId: params.id }));
		});
		this.frontendStyleOptions$ = EnumUtils.toObservableItems(FrontendStyleEnumeration);
		this.companyGroups$ = this.store.select(IntegrationSelect.getCompanyGroupOptions);
		this.responsibleUsers$ = this.store.select(IntegrationSelect.getResponsibleUsers);
		this.company$ = this.store.select(IntegrationSelect.getCompany);
		this.employees$ = this.store.select(IntegrationSelect.getEmployees);
		this.yesNo = EnumUtils.toItems(FlagEnumeration);
		this.contractType = EnumUtils.toItems(ContractTypeEnumeration);
		this.neoEnvironment$ = EnumUtils.toObservableItems(NeoEnvironmentEnumeration);
		this.filterConfig = this.createFilterConfig();
		this.employeeColumns = this.createColumns();
		this.checkPermission();

		this.company$
			.pipe(
				filter((company) => company != null),
				takeUntil(this.destroy$)
			)
			.subscribe((company) => this.displayCompany(company));
	}

	ngAfterViewInit(): void {
		this.cdRef.detectChanges();
	}

	createColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('firstName', 'firstName'),
			ColumnBuilder.create('lastName', 'lastName'),
			ColumnBuilder.create('easyTempId', 'easyTempId'),
			ColumnBuilder.create('neoId', 'neoId'),
			ColumnBuilder.create('neoCategoryName', 'neoCategoryName'),
			ColumnBuilder.createDate('birthDate', 'birthDate'),
			ColumnBuilder.create('socialSecurityNumber', 'socialSecurityNumber'),
			ColumnBuilder.create('email', 'email'),
			ColumnBuilder.createDate('externallyUpdatedOnNeo', 'neoLastChangeDate'),
			ColumnBuilder.createDate('externallyUpdatedOnEasyTemp', 'easyTempLastChangeDate'),
		];
	}

	createFilterConfig(): FilterConfig {
		return {
			showFilter: false,
			showGlobalFilter: true,
			filterValues: {},
		};
	}

	checkPermission(): void {
		this.store.select(UserSelect.getPermissions).subscribe((permission) => {
			this.isCmAdmin = permission.includes(PermissionEnumeration.CM_ADMIN);
			this.isAdmin = permission.includes(PermissionEnumeration.ADMIN);
			this.isHrUser = permission.includes(PermissionEnumeration.HR);
			this.isReadOnly = !permission.includes(PermissionEnumeration.INTEGRATION_UPDATE_COMPANY);
		});
	}

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue();
		const company = this.factory.createExisting(data);
		this.store.dispatch(IntegrationPageAction.updateCompany({ company }));
	};

	onRefresh = (): void => {
		this.store.dispatch(IntegrationPageAction.refreshCompany({ companyIds: [this.formGroup.controls.id.value], byModificationDate: false }));
	};

	onRowClick = (rowData): void => {
		const dialogConfig = { data: { insuredPersonId: rowData.id } };
		this.dialogService.open(EmployeeDetailsComponent, dialogConfig);
	};

	displayCompany = (company: any): void => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			...company,
			isSwicaInsured: booleanToFlag(company.isSwicaInsured),
			isPkProInsured: booleanToFlag(company.isPkProInsured),
			isMaster: booleanToFlag(company.isMaster),
			isKleEnabled: booleanToFlag(company.isKleEnabled),
		});
	};
}
