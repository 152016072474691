<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<nc-input
					[id]="'id'"
					*ngIf="formGroup.get('id').value"
					[formGroup]="formGroup"
					formControlName="id"
					label="id"
					isReadonly="true"
				></nc-input>
				<nc-input [mask]="{ mask: '000000' }" [id]="'day'" [formGroup]="formGroup" formControlName="day" label="day"></nc-input>
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutAlign="center">
					<ea-container [title]="'general'" fxFlex="30">
						<div fxLayout="column" fxLayoutGap="10px" class="button-group">
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="pkProInsured"
								label="pkpro"
								[options]="yesNoNone"
							></nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="swicaInsured"
								label="swica"
								[options]="yesNoNone"
							></nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="forCMUser"
								label="forCMUser"
								[options]="yesNoNone"
							></nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="forLDUser"
								label="forLDUser"
								[options]="yesNoNone"
							></nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="forCaseManagementActive"
								label="forCaseManagementActive"
								[options]="yesNoNone"
							></nc-toggle-buttons>
						</div>
					</ea-container>
					<ea-container [title]="'absence'" fxFlex="30">
						<div fxLayout="column" fxLayoutGap="10px" class="button-group">
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="important"
								label="important"
								[options]="yesNo"
							></nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="forAbsenceTypeSickness"
								label="forAbsenceTypeSickness"
								[options]="yesNo"
							></nc-toggle-buttons>

							<div
								*ngIf="formGroup.get('forAbsenceTypeSickness').value === 'yes'"
								class="sub-element"
								fxLayout.lt-md="column"
								fxLayout="column"
							>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									formControlName="sicknessTypeMentalIllness"
									label="mentalIllness"
									[options]="yesNoNone"
								></nc-toggle-buttons>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									formControlName="sicknessTypeBackProblem"
									label="backProblems"
									[options]="yesNoNone"
								></nc-toggle-buttons>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									formControlName="sicknessTypeUnknown"
									label="unknown"
									[options]="yesNoNone"
								></nc-toggle-buttons>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									formControlName="sicknessTypeOther"
									label="other"
									[options]="yesNoNone"
								></nc-toggle-buttons>
							</div>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="forAbsenceTypeAccident"
								label="forAbsenceTypeAccident"
								[options]="yesNo"
							></nc-toggle-buttons>

							<div
								*ngIf="formGroup.get('forAbsenceTypeAccident').value === 'yes'"
								class="sub-element"
								fxLayoutGap="15px"
								fxLayout="column"
							>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									formControlName="accidentTypeWork"
									label="work"
									[options]="yesNoNone"
								></nc-toggle-buttons>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									formControlName="accidentTypeFreeTime"
									label="freeTime"
									[options]="yesNoNone"
								></nc-toggle-buttons>
							</div>

							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="forAbsenceTypePregnancy"
								label="forAbsenceTypePregnancy"
								[options]="yesNo"
							></nc-toggle-buttons>
						</div>
					</ea-container>
				</div>
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
					<ea-container [title]="'title'" fxFlex="49">
						<nc-input [id]="'german'" [formGroup]="formGroup" formControlName="titleDe" label="german"></nc-input>
						<nc-input [id]="'english'" [formGroup]="formGroup" formControlName="titleEn" label="english"></nc-input>
						<nc-input [id]="'french'" [formGroup]="formGroup" formControlName="titleFr" label="french"></nc-input>
						<nc-input [id]="'italian'" [formGroup]="formGroup" formControlName="titleIt" label="italian"></nc-input>
					</ea-container>
					<ea-container [title]="'note'" fxFlex="49">
						<nc-textarea [id]="'note-german'" [formGroup]="formGroup" formControlName="noteDe" label="german"></nc-textarea>
						<nc-textarea [id]="'note-english'" [formGroup]="formGroup" formControlName="noteEn" label="english"></nc-textarea>
						<nc-textarea [id]="'note-french'" [formGroup]="formGroup" formControlName="noteFr" label="french"></nc-textarea>
						<nc-textarea [id]="'note-italian'" [formGroup]="formGroup" formControlName="noteIt" label="italian"></nc-textarea>
					</ea-container>
				</div>
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
					<ea-container [title]="'cmStatuses'" fxFlex="49">
						<nc-textarea [id]="'cm-status-german'" [formGroup]="formGroup" formControlName="cmStatusesDe" label="german"></nc-textarea>
						<nc-textarea [id]="'cm-status-english'" [formGroup]="formGroup" formControlName="cmStatusesEn" label="english"></nc-textarea>
						<nc-textarea [id]="'cm-status-french'" [formGroup]="formGroup" formControlName="cmStatusesFr" label="french"></nc-textarea>
						<nc-textarea [id]="'cm-status-italian'" [formGroup]="formGroup" formControlName="cmStatusesIt" label="italian"></nc-textarea>
					</ea-container>
					<ea-container [title]="'hrStatuses'" fxFlex="49">
						<nc-textarea [id]="'hr-status-german'" [formGroup]="formGroup" formControlName="hrStatusesDe" label="german"></nc-textarea>
						<nc-textarea [id]="'hr-status-english'" [formGroup]="formGroup" formControlName="hrStatusesEn" label="english"></nc-textarea>
						<nc-textarea [id]="'hr-status-french'" [formGroup]="formGroup" formControlName="hrStatusesFr" label="french"></nc-textarea>
						<nc-textarea [id]="'hr-status-italian'" [formGroup]="formGroup" formControlName="hrStatusesIt" label="italian"></nc-textarea>
					</ea-container>
				</div>
				<ea-container [title]="'documentsChecklist'">
					<nc-datatable
						[id]="'documents-check-list'"
						[columns]="columns"
						[hasPaginator]="false"
						[data$]="documentTypes$"
						[selectConfig]="selectConfig"
						[filterConfig]="filterConfig"
					>
					</nc-datatable>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
