<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content>
			<ea-statistic-filter [formGroup]="formGroup">
				<div>
					<nc-toggle-buttons
						[multiple]="true"
						[formGroup]="formGroup"
						formControlName="selectedTypes"
						label="show"
						[options]="absenceTypeOptions"
					>
					</nc-toggle-buttons>
				</div>
				<div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
					<nc-toggle-buttons [formGroup]="formGroup" formControlName="isShowCmData" label="showCmData" [options]="flagOptions">
					</nc-toggle-buttons>
					<nc-toggle-buttons
						*ngIf="formGroup.get('isShowCmData').value === 'yes'"
						[formGroup]="formGroup"
						formControlName="isCaseManagementSuccessful"
						label="isCaseManagementSuccessful"
						[options]="flagOptions"
					>
					</nc-toggle-buttons>
				</div>
			</ea-statistic-filter>
		</nc-form-content>
	</nc-main-content>
	<ea-statistic-footer [onExport]="onExport" [formGroup]="formGroup"></ea-statistic-footer>
</div>
