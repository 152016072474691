<div id="unmerge-absence-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div mat-dialog-title>
		<span class="dialog-title-text">{{ 'unmerge' | translate }}</span>
	</div>
	<p innerHTML="{{ 'unmergeAbsenceContent' | translate }}"></p>
	<mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
			<nc-button [id]="'unmerge'" label="unmerge" [mat-dialog-close]="{ success: true, code: code }"></nc-button>
			<nc-button [id]="'close'" label="close" [mat-dialog-close]="{ success: false }"></nc-button>
		</div>
	</mat-dialog-actions>
</div>
