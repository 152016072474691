import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserDetails } from '../model/user-details';
import { ActionResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { ResponseModel } from '../../shared/model/response.model';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly PERSONAL_DATA_URL = `${this.API_URL}/settings`;
	private readonly CHANGE_PASSWORD_URL = `${this.API_URL}/settings/password`;

	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets personal details for user
	 * @param username name of user
	 */
	getPersonalDetails(username: string): Observable<UserDetails> {
		return this.httpClient.get<ResponseModel<UserDetails>>(this.PERSONAL_DATA_URL + '/' + username).pipe(map((x) => x.value));
	}

	/**
	 * Saves personal data
	 * @param personalData personal data of user
	 */
	savePersonalData(personalData: UserDetails): Observable<ActionResponse> {
		return this.httpClient.post<ActionResponse>(this.PERSONAL_DATA_URL, personalData);
	}

	/**
	 * Changes password
	 * @param oldPassword old password
	 * @param newPassword new password
	 * @param confirmPassword repeated new password
	 */
	changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Observable<ActionResponse> {
		const request = { oldPassword, newPassword, confirmPassword };
		return this.httpClient.post<ActionResponse>(this.CHANGE_PASSWORD_URL, request);
	}
}
