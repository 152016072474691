import { Injectable } from '@angular/core';
import { DialogMessage } from '../../model/dialog-message';

@Injectable({
	providedIn: 'root',
})
export class ReasonForAbsenceMessageRequestFactory {
	/**
	 *
	 * @param reasonForAbsenceData
	 * @param dialogMessage
	 */
	create(reasonForAbsenceData: any, dialogMessage: DialogMessage): any {
		return {
			...reasonForAbsenceData,
			reasonForAbsenceMessage: {
				...dialogMessage.reasonForAbsenceMessage,
				disease: {
					...dialogMessage.reasonForAbsenceMessage.disease,
					answerValue: reasonForAbsenceData.disease,
				},
				accident: {
					...dialogMessage.reasonForAbsenceMessage.accident,
					answerValue: reasonForAbsenceData.accident,
				},
				maternity: {
					...dialogMessage.reasonForAbsenceMessage.maternity,
					answerValue: reasonForAbsenceData.maternity,
				},
				militaryService: {
					...dialogMessage.reasonForAbsenceMessage.militaryService,
					answerValue: reasonForAbsenceData.militaryService,
				},
				vacation: {
					...dialogMessage.reasonForAbsenceMessage.vacation,
					answerValue: reasonForAbsenceData.vacation,
				},
				unpaidLeave: {
					...dialogMessage.reasonForAbsenceMessage.unpaidLeave,
					answerValue: reasonForAbsenceData.unpaidLeave,
				},
				leavingCompany: {
					...dialogMessage.reasonForAbsenceMessage.leavingCompany,
					answerValue: reasonForAbsenceData.leavingCompany,
				},
				activityWithAnotherEmployer: {
					...dialogMessage.reasonForAbsenceMessage.activityWithAnotherEmployer,
					answerValue: reasonForAbsenceData.activityWithAnotherEmployer,
				},
				otherReason: {
					...dialogMessage.reasonForAbsenceMessage.otherReason,
					answerValue: reasonForAbsenceData.otherReason,
				},
			},
		};
	}
}
