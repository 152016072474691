<div fxFill fxLayout="column">
	<nc-main-content>
		<div [formGroup]="formGroup" id="reassign-absence-container">
			<nc-checkbox [id]="'show-all'" [formGroup]="formGroup" formControlName="showAll" label="showAll"></nc-checkbox>
			<nc-datatable
				[id]="'reassign-absence-employees'"
				[selectConfig]="selectConfig"
				[columns]="reassignAbsenceEmployeesColumns"
				[data$]="reassignAbsenceEmployees$"
				[filterConfig]="filterConfig"
			>
			</nc-datatable>
		</div>
	</nc-main-content>
	<ea-reassign-absence-footer [selectFormGroup]="selectConfig.formArray" [code]="code"></ea-reassign-absence-footer>
</div>
