<form ncConnectForm fxLayout="column" fxFill [formGroup]="formGroup" [connectForm]="user$ | async">
	<nc-main-content>
		<nc-form-content>
			<nc-form-title label="personalInfo"> </nc-form-title>
			<nc-input [id]="'username'" [formGroup]="formGroup" formControlName="username" [isVisible]="false"></nc-input>
			<nc-input [id]="'first-name'" [formGroup]="formGroup" formControlName="firstName" label="firstName" [isReadonly]="true"></nc-input>
			<nc-input [id]="'last-name'" [formGroup]="formGroup" formControlName="lastName" label="lastName" [isReadonly]="true"></nc-input>
			<nc-input [id]="'phone'" [formGroup]="formGroup" formControlName="mobileNumber" label="phone" [isReadonly]="true"></nc-input>
			<nc-input [id]="'email'" [formGroup]="formGroup" formControlName="email" label="email" [isReadonly]="true"></nc-input>
			<nc-form-title label="changeLanguage"> </nc-form-title>
			<nc-toggle-buttons [formGroup]="formGroup" formControlName="languageCode" [options]="languages"> </nc-toggle-buttons>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
