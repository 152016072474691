<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content>
			<nc-password
				[id]="'old-password'"
				[buttonId]="'old-hide-password'"
				[formGroup]="formGroup"
				formControlName="oldPassword"
				label="oldPassword"
			></nc-password>
			<nc-password
				[id]="'new-password'"
				[buttonId]="'new-hide-password'"
				[formGroup]="formGroup"
				formControlName="newPassword"
				label="newPassword"
			></nc-password>
			<nc-password
				[id]="'confirmation-password'"
				[buttonId]="'confirmation-hide-password'"
				[formGroup]="formGroup"
				formControlName="confirmationPassword"
				label="confirmedPassword"
			></nc-password>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
