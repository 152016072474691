import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorityModel } from '../model/authority.model';
import { AuthorityTypeEnumeration } from '../model/enumeration/authority-type.enumeration';
import { SaveResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class AccessManagementService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	private readonly AUTHORITY_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/authorities`;

	/**
	 * Get authorities by type
	 */
	getAuthorities(type: AuthorityTypeEnumeration): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(this.AUTHORITY_URL + '/' + type);
	}

	/**
	 * Get role by id
	 * @param id Unique identifier for authority
	 * @param type Authority type
	 */
	getAuthority(id: number, type: AuthorityTypeEnumeration): Observable<AuthorityModel> {
		return this.httpClient.get<AuthorityModel>(`${this.AUTHORITY_URL}/${id}/${type}`);
	}

	/**
	 * Save authority
	 * @param authority Authority form data
	 */
	saveAuthority(authority: AuthorityModel): Observable<SaveResponse> {
		return this.httpClient.post<SaveResponse>(this.AUTHORITY_URL, authority);
	}

	/**
	 * Delete authority
	 * @param id Unique identifier for authority
	 * @param type Authority type
	 */
	deleteAuthority(id: number, type: AuthorityTypeEnumeration): Observable<any> {
		const data = { ids: [id], type };
		return this.httpClient.request('delete', this.AUTHORITY_URL, { body: data });
	}
}
