import { FormFieldTypes } from 'nc-form-generator/lib/models/generic-form-data.model';

export class DialogMessageField {
	/**
	 * Unique identifier
	 */
	id: string;
	/**
	 * Text description of field
	 */
	label: string;
	/**
	 * Unique identifier of group which field belongs
	 */
	sectionIDRef: string;
	/**
	 * Section heading
	 */
	sectionHeading: string;
	/**
	 * Textual description of section
	 */
	sectionDescription: string;
	/**
	 * Value which need to be shown in readOnly module
	 */
	value: FormFieldTypes;
	/**
	 * Type of field (string, boolean, integer, double, date, dateTime, yesNoUnknown enum)
	 */
	valueType: FreeDialogValueTypes;
	/**
	 * Type of field (string, boolean, integer, double, date, dateTime, yesNoUnknown enum)
	 */
	answerType: FreeDialogValueTypes;
	/**
	 * Answer value
	 */
	answerValue: FormFieldTypes;
	/**
	 * Default value of answer
	 */
	answerDefaultValue: FormFieldTypes;

	answerOptional: boolean;
}

export enum FreeDialogValueTypes {
	STRING = 'STRING',
	BOOLEAN = 'BOOLEAN',
	INTEGER = 'INTEGER',
	DOUBLE = 'DOUBLE',
	DATE = 'DATE',
	DATE_TIME = 'DATE_TIME',
	YES_NO_UNKNOWN = 'YES_NO_UNKNOWN',
}
