<form fxLayout="column" fxFill [formGroup]="formGroup">
	<ea-header-info [showCompany]="true"></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<nc-input [id]="'title'" [formGroup]="formGroup" formControlName="title" label="title" maxLength="100"></nc-input>
				<nc-textarea [id]="'note'" [formGroup]="formGroup" formControlName="note" label="note" maxLength="600"></nc-textarea>
				<nc-datepicker [id]="'due-date'" [formGroup]="formGroup" formControlName="dueDate" label="dueDate"></nc-datepicker>
				<nc-toggle-buttons [formGroup]="formGroup" formControlName="important" label="important" [options]="yesNo"></nc-toggle-buttons>
				<nc-toggle-buttons [formGroup]="formGroup" formControlName="done" label="done" [options]="yesNo"></nc-toggle-buttons>
				<nc-toggle-buttons [formGroup]="formGroup" formControlName="status" label="createStatus" [options]="yesNo"></nc-toggle-buttons>
				<nc-toggle-buttons
					[formGroup]="formGroup"
					formControlName="replicateForActiveAbsencesSet"
					label="createThisTaskForAllActiveAbsences"
					[options]="yesNo"
				></nc-toggle-buttons>
				<ea-documents
					[id]="'task-form-document'"
					[buttonId]="'open-dialog'"
					[downloadButtonId]="'download-documents-button'"
					[documentsFormArray]="documents"
					[additionalColumns]="additionalColumns"
				></ea-documents>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-task-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-task-footer>
</form>
