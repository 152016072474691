import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MilestoneSettingsComponent } from './component/milestone-settings/milestone-settings.component';
import { MilestoneSettingsEffect } from './state/milestone-settings.effect';
import { MILESTONE_SETTINGS_FEATURE_STATE_NAME } from './state/action/milestone-settings.select';
import { milestoneSettingsReducer } from './state/milestone-settings.reducer';
import { MilestoneSettingsFooterComponent } from './component/footer/milestone-settings-footer.component';
import { MilestoneSettingsFormComponent } from './component/milestone-settings-form/milestone-settings-form.component';
import { DeleteConfirmationDialogComponent } from '../shared/component/delete-confirmation-dialog/delete-confirmation-dialog.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([MilestoneSettingsEffect]),
		StoreModule.forFeature(MILESTONE_SETTINGS_FEATURE_STATE_NAME, milestoneSettingsReducer),
	],
	declarations: [MilestoneSettingsComponent, MilestoneSettingsFooterComponent, MilestoneSettingsFormComponent, DeleteConfirmationDialogComponent],
	exports: [MilestoneSettingsComponent, MilestoneSettingsFormComponent],
})
export class MilestoneSettingsModule {}
