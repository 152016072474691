import { ABSENCE_INITIAL_STATE, AbsenceState } from './absence.state';
import { createReducer, on } from '@ngrx/store';
import { AbsenceApiAction, AbsencePageAction } from './action';
import { booleanToFlag } from '../../shared/enumeration/flag.enumeration';
import { parseDate } from 'nc-utils';

export const absenceReducer = createReducer<AbsenceState>(
	ABSENCE_INITIAL_STATE,
	on(AbsenceApiAction.fetchEmployeeSuccess, (state, action): AbsenceState => {
		return {
			...state,
			employee: action.employee,
			absence: {
				...state.absence,
				refEmployee: action.employee.id,
			},
		};
	}),
	on(AbsenceApiAction.fetchEmployeesSuccess, (state, action): AbsenceState => {
		return {
			...state,
			employees: action.employees,
		};
	}),
	on(AbsencePageAction.clearEmployees, (state): AbsenceState => {
		return {
			...state,
			employees: [],
		};
	}),
	on(AbsenceApiAction.fetchCompanySuccess, (state, action): AbsenceState => {
		return {
			...state,
			company: action.companyResponse.company,
			responsibleUsers: action.companyResponse.users,
			absence: {
				...state.absence,
				companyId: action.companyResponse.company.id,
			},
		};
	}),
	on(AbsenceApiAction.initializeSelectsSuccess, (state, action): AbsenceState => {
		return {
			...state,
			responsibleLDUsers: action.responsibleLDUsers,
		};
	}),
	on(AbsenceApiAction.fetchAbsenceSuccess, (state, action): AbsenceState => {
		return {
			...state,
			responsibleUsers: action.response.responsibleUser.options,
			responsibleLDUsers: action.response.responsibleLDUser.options,
			company: {
				...state.company,
				name: action.response.companyName,
				id: action.response.companyId,
				canton: action.response.companyCanton,
				city: action.response.companyCity,
				country: action.response.companyCountry,
				contractType: action.response.companyContractType,
				zip: action.response.companyZip,
				postbox: action.response.companyPostalCode,
				street: action.response.companyStreet,
				uid: action.response.companyUid,
				ownerFirstName: action.response.companyOwnerFirstName,
				ownerLastName: action.response.companyOwnerLastName,
				neoId: action.response.companyNeoId,
				easyTempId: action.response.companyEasyTempId,
				isKleEnabled: action.response.companyIsKleEnabled,
				isPkProInsured: action.response.companyIsPkProInsured,
				isSwicaInsured: action.response.companyIsSwicaInsured,
				contactPerson: action.response.contactPerson,
			},
			employee: {
				...state.employee,
				firstName: action.response.fullName.split(' ')[0],
				lastName: action.response.fullName.split(' ').slice(1).join(' '),
				pkProInsuranceEntryDate: parseDate(action.response.pkProInsuranceEntryDate),
				pkProInsuranceExitDate: parseDate(action.response.pkProInsuranceExitDate),
				id: action.response.employeeId.toString(),
				gender: action.response.gender.toLocaleLowerCase(),
				language: action.response.language,
				civilStatus: action.response.civilStatus.toLocaleLowerCase(),
				email: action.response.email,
				mobilePhone: action.response.phoneNumber,
				street: action.response.street,
				zip: action.response.zip,
				city: action.response.city,
				country: action.response.country,
				residenceCanton: action.response.residenceCanton,
				birthDate: action.response.birthDate,
				socialSecurityNumber: action.response.socialSecurityNumber,
				easyTempId: action.response.easyTempEmployeeId,
				neoId: action.response.employeeNeoId,
			},
			absence: {
				...action.response,
				status: action.response.status,
				deleted: action.response.delete,
				linkedAbsences: { records: action.response.linkedAbsences },
				mergedCases: { records: action.response.mergedCases },
				shareInformation: { records: action.response.shareInformation },
				comment: action.response.comment ?? '',
				isValidToPopulated: action.response.validToPopulated,
				createdOn: parseDate(action.response.createdOn),
				pregnancyDueDate: parseDate(action.response.pregnancyDueDate),
				lastDeploymentDate: parseDate(action.response.lastDeploymentDate),
				firstDeploymentDate: parseDate(action.response.firstDeploymentDate),
				employmentEndDate: parseDate(action.response.employmentEndDate),
				employmentStartDate: parseDate(action.response.employmentStartDate),
				sentToCaseManagementCompanyDate: parseDate(action.response.sentToCaseManagementCompanyDate),
				closedByCaseManagementCompanyDate: parseDate(action.response.closedByCaseManagementCompanyDate),
				abortedByCaseManagementCompanyDate: parseDate(action.response.abortedByCaseManagementCompanyDate),
				id: action.response.absenceId,
				refEmployee: action.response.employeeId?.toString(),
				categoryType: action.response.declarationType,
				responsibleUserId: action.response.responsibleUser.id,
				responsibleUserName: action.response.responsibleUser.name,
				responsibleLDUserId: action.response.responsibleLDUser.id,
				responsibleLDUserName: action.response.responsibleLDUser.name,
				isCaseManagementActive: booleanToFlag(action.response.caseManagementActive),
				hasChildrenUnder18OrInEducationUpTo25: booleanToFlag(action.response.hasChildrenUnder18),
				hasReturnedToWork: booleanToFlag(action.response.hasReturnedToWork),
				isPkproInsured: booleanToFlag(action.response.pkProInsured),
				isSwicaInsured: booleanToFlag(action.response.swicaInsured),
				hasAccidentInsurance: booleanToFlag(action.response.hasAccidentInsurance),
				hasDailySicknessBenefitsInsurance: booleanToFlag(action.response.hasDailySicknessBenefitsInsurance),
				isSentToCaseManagementCompany: booleanToFlag(action.response.sentToCaseManagementCompany),
				isClosedByCaseManagementCompany: booleanToFlag(action.response.closedByCaseManagementCompany),
				isAbortedByCaseManagementCompany: booleanToFlag(action.response.abortedByCaseManagementCompany),
				medicalDiagnosisCode: action.response.medicalDiagnosis.code,
				medicalDiagnosisValue: action.response.medicalDiagnosis.value,
				medicalDiagnosisAdditionalCode: action.response.medicalDiagnosisAdditional.code,
				medicalDiagnosisAdditionalValue: action.response.medicalDiagnosisAdditional.value,
				employmentPercentage: action.response.employmentPercentage?.toString(),
				caseWasOpen: action.response.caseWasOpen,
				isRecoverageActive: booleanToFlag(action.response.recoverageActive),
				caseManagementDetails: {
					uvgInsurer: action.response.caseManagementDetails.uvgInsurer,
					ktgInsurer: action.response.caseManagementDetails.ktgInsurer,
					office: {
						name: action.response.caseManagementDetails.office.name,
						contactPerson: action.response.caseManagementDetails.office.contactPerson,
						phoneNumber: action.response.caseManagementDetails.office.phoneNumber,
					},
					reports: {
						startedOn: parseDate(action.response.caseManagementDetails.reports.startedOn),
						interventionStatus: action.response.caseManagementDetails.reports.interventionStatus,
						integrationReport: action.response.caseManagementDetails.reports.integrationReport,
						assessment: {
							date: parseDate(action.response.caseManagementDetails.reports.assessment.date),
							status: action.response.caseManagementDetails.reports.assessment.status,
						},
						reassessment: {
							date: parseDate(action.response.caseManagementDetails.reports.reassessment.date),
							status: action.response.caseManagementDetails.reports.reassessment.status,
						},
					},
					conclusionDetails: {
						endedOn: parseDate(action.response.caseManagementDetails.conclusionDetails.endedOn),
						sustainabilityCheckOn: parseDate(action.response.caseManagementDetails.conclusionDetails.sustainabilityCheckOn),
						accomplishment: action.response.caseManagementDetails.conclusionDetails.accomplishment,
						conclusion: action.response.caseManagementDetails.conclusionDetails.conclusion,
					},
				},
				documentTypes: action.response.documentTypes.map((x) => {
					return {
						value: x.code,
						text: x.text,
					};
				}),
				fetchDocumentTypes: action.response.fetchDocumentTypes.map((x) => {
					return {
						value: x.code,
						text: x.text,
					};
				}),
				generateDocumentTypes: action.response.generateDocumentTypes.map((x) => {
					return {
						value: x.code,
						text: x.text,
					};
				}),
				documents: action.response.documents.map((x) => {
					const lastDotIndex = x.documentName.lastIndexOf('.');
					return {
						id: Number(x.id),
						nameWithExtension: x.documentName,
						docmanId: Number(x.documentId),
						tableId: 0,
						documentTypeTranslation: x.type,
						documentType: x.typeCode,
						name: x.documentName.slice(0, x.documentName.lastIndexOf('.')),
						extension: x.documentName.slice(lastDotIndex + 1),
						file: null,
						uploadedBy: x.uploadedBy,
						uploadedOn: x.uploadedOn,
						comment: x.comment,
					};
				}),
				contactPerson: {
					firstName: action.response.contactPerson.firstName,
					lastName: action.response.contactPerson.lastName,
					email: action.response.contactPerson.email,
					phone: action.response.contactPerson.mobileNumber,
					function: '',
					title: '',
				},
				forcedNew: null,
			},
			absenceCode: action.response.code,
			documentsDetails: { records: action.response.documents },
		};
	}),
	on(AbsenceApiAction.getHistorySuccess, (state, action): AbsenceState => {
		return {
			...state,
			history: { records: action.records },
		};
	}),
	on(AbsenceApiAction.getReassignAbsenceEmployeesSuccess, (state, action): AbsenceState => {
		return {
			...state,
			reassignAbsenceEmployees: { records: action.records },
		};
	}),
	on(AbsenceApiAction.getCurrentAbsenceSuccess, (state, action): AbsenceState => {
		return {
			...state,
			absenceContextModel: action.currentAbsence,
			absenceCode: action.currentAbsence.absenceCode,
		};
	}),
	on(AbsenceApiAction.getAbsenceAnnexSuccess, (state, action): AbsenceState => {
		return {
			...state,
			absenceAnnex: {
				...action.absenceAnnexResponse.model,
				documents: action.absenceAnnexResponse.model.documents.map((x) => {
					return {
						id: Number(x.id),
						nameWithExtension: x.documentName,
						docmanId: Number(x.documentId),
						tableId: 0,
						documentType: x.typeCode,
						name: x.documentName.split('.')[0],
						extension: x.documentName.split('.')[1],
						file: null,
						uploadedBy: x.uploadedBy,
						uploadedOn: x.uploadedOn,
						comment: x.comment,
					};
				}),
			},
			absenceContextModel: action.absenceAnnexResponse.context,
		};
	}),
	on(AbsencePageAction.absenceSavedSuccessfully, (state, action): AbsenceState => {
		return {
			...state,
			absenceSavedSuccessfully: action.absenceSavedSuccessfully,
		};
	}),
	on(AbsencePageAction.restartAbsence, (state): AbsenceState => {
		return {
			...state,
			absence: ABSENCE_INITIAL_STATE.absence,
		};
	}),
	on(AbsenceApiAction.getIncidentContextSuccess, (state, action): AbsenceState => {
		return {
			...state,
			incidentContext: action.response,
		};
	}),
	on(AbsencePageAction.setAbsenceCode, (state, action): AbsenceState => {
		return {
			...state,
			absenceCode: action.code,
		};
	})
);
