import { Component, Input, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { KleSelect } from '../../state/action';
import { ActivatedRoute } from '@angular/router';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { employeePaymentValidator } from '../../validator/payment.validator';
import { DialogService, DialogType } from 'nc-utils';
import { KleService } from '../../service/kle.service';

@Component({
	selector: 'ea-payment',
	templateUrl: './payments.component.html',
})
export class PaymentsComponent implements OnInit {
	@Input() formGroup: FormGroup;
	paymentGroup = this.formBuilder.group(
		{
			companyAccountOwner: [''],
			companyIban: [''],
			employeeAccountOwner: [''],
			employeeIban: [''],
			validAsOf: [null],
			companyFinancialInstitution: [''],
			employeeFinancialInstitution: [''],
		},
		{
			validators: [employeePaymentValidator],
		} as AbstractControlOptions
	);

	absenceCode: string;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private kleService: KleService,
		private dialogService: DialogService
	) {
		this.route.params.subscribe((params) => (this.absenceCode = params.code));
	}

	ngOnInit(): void {
		this.formGroup.addControl(KleFormGroupEnum.PAYMENT, this.paymentGroup);

		this.store.select(KleSelect.getPayment).subscribe((payment) => {
			this.paymentGroup.patchValue(payment);
		});
	}

	getPaymentForPerson() {
		this.kleService.getPayment(this.absenceCode).subscribe((payment) => {
			this.paymentGroup.controls.employeeIban.patchValue(payment.employeeIban);
			this.paymentGroup.controls.employeeAccountOwner.patchValue(payment.employeeAccountOwner);
			this.paymentGroup.controls.employeeFinancialInstitution.patchValue(payment.employeeFinancialInstitution);
			this.dialogService.close(DialogType.PROCESSING);
		});
	}

	getPaymentForCompany() {
		this.kleService.getPayment(this.absenceCode).subscribe((payment) => {
			this.paymentGroup.controls.companyIban.patchValue(payment.companyIban);
			this.paymentGroup.controls.companyAccountOwner.patchValue(payment.companyAccountOwner);
			this.paymentGroup.controls.companyFinancialInstitution.patchValue(payment.companyFinancialInstitution);
			this.dialogService.close(DialogType.PROCESSING);
		});
	}
}
