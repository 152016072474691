<div class="chart-wrapper">
	<div #containerRef class="chart-container">
		<nc-form-title *ngIf="chartTitle" [label]="chartTitle"></nc-form-title>
		<ng-container [ngSwitch]="type">
			<ngx-charts-pie-chart
				*ngSwitchCase="chartEnumeration.PIE"
				[view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
				[scheme]="colors"
				[legend]="showLegend"
				[legendTitle]="legendTitle"
				[labels]="showLabels"
				[doughnut]="isDoughnut"
				[gradient]="isGradient"
				[legendPosition]="legendPosition"
				[results]="translatedData"
			>
			</ngx-charts-pie-chart>
			<ngx-charts-bar-vertical
				*ngSwitchCase="chartEnumeration.BAR"
				[scheme]="colors"
				[legendTitle]="legendTitle"
				[legend]="showLegend"
				[view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
				[xAxis]="true"
				[yAxis]="true"
				[showXAxisLabel]="true"
				[showYAxisLabel]="true"
				[gradient]="isGradient"
				[legendPosition]="legendPosition"
				[results]="translatedData"
			>
			</ngx-charts-bar-vertical>
			<ngx-charts-bar-vertical-2d
				*ngSwitchCase="chartEnumeration.STACKED_BAR"
				[scheme]="colors"
				[legendTitle]="legendTitle"
				[legend]="showLegend"
				[view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
				[xAxis]="true"
				[yAxis]="true"
				[showXAxisLabel]="true"
				[showYAxisLabel]="true"
				[gradient]="isGradient"
				[legendPosition]="legendPosition"
				[results]="translatedData"
			>
			</ngx-charts-bar-vertical-2d>
		</ng-container>
	</div>
</div>
