import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import { UserPageAction } from '../../state/action';

@Component({
	selector: 'ea-forgot-password-verification',
	templateUrl: './forgot-password-verification.component.html',
	styleUrls: ['./forgot-password-verification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordVerificationComponent implements OnInit {
	formGroup: FormGroup;

	constructor(private store: Store<State>, private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({
			code: ['', [Validators.required]],
		});
	}

	onSubmit = (): void => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.verifyForgotPasswordSubmit(data));
	};
}
