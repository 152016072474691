<div [formGroup]="contractPersonMessageGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div class="scroll-dialog-content">
		<div fxLayout="column">
			<nc-input id="title" label="title" [formGroup]="contractPersonMessageGroup" formControlName="title" [isReadonly]="true"></nc-input>
		</div>
		<div fxLayout="column">
			<nc-textarea
				class="text-wrapped"
				id="message"
				label="synchronization.table.Message"
				[formGroup]="contractPersonMessageGroup"
				formControlName="message"
				[isReadonly]="true"
			>
			</nc-textarea>
		</div>
		<div fxLayout="column">
			<nc-textarea
				id="description"
				label="description"
				[formGroup]="contractPersonMessageGroup"
				formControlName="description"
				[isReadonly]="true"
			>
			</nc-textarea>
		</div>
		<div fxLayout="column">
			<nc-input
				id="firstname"
				[formGroup]="contractPersonMessageGroup"
				formControlName="firstName"
				label="firstName"
				[required]="!dialogMessage?.contactPersonMessage?.firstName.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="lastname"
				[formGroup]="contractPersonMessageGroup"
				formControlName="lastName"
				label="lastName"
				[required]="!dialogMessage?.contactPersonMessage?.lastName.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="phone"
				[formGroup]="contractPersonMessageGroup"
				formControlName="phone"
				label="phone"
				[required]="!dialogMessage?.contactPersonMessage?.phone.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="function"
				[formGroup]="contractPersonMessageGroup"
				formControlName="function"
				label="function"
				[required]="!dialogMessage?.contactPersonMessage?.function.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="email"
				[formGroup]="contractPersonMessageGroup"
				formControlName="email"
				label="email"
				[required]="!dialogMessage?.contactPersonMessage?.email.answerOptional"
			>
			</nc-input>
		</div>
	</div>
	<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-submit-button
			id="send-dialog-message"
			buttonLabel="replay"
			(buttonClick)="submit()"
			[formGroup]="contractPersonMessageGroup"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</div>
