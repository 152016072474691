import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseComponent, Option } from 'nc-shared';
import { EnumUtils } from 'nc-utils';
import { IncidentForCompanyEnum } from '../../model/enums/incident-for-company.enum';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { takeUntil } from 'rxjs/operators';
import { KleSelect } from '../../state/action';

@Component({
	selector: 'ea-process-control',
	templateUrl: './process-control.component.html',
	styleUrls: ['./process-control.component.scss'],
})
export class ProcessControlComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;

	incidentForCompanyEnum: Option[];

	processControlFormGroup = this.formBuilder.group({
		closeType: IncidentForCompanyEnum.NORMAL.code,
		cancel: '',
		companyOldUidBfs: null as string,
		companyNewUidBfs: null as string,
		institutionOldCustomerIdentity: null as string,
		institutionOldContractIdentity: null as string,
		institutionNewCustomerIdentity: null as string,
		institutionNewContractIdentity: null as string,
		changeIdentitiesValidAsOf: null,
	});

	constructor(private store: Store, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.incidentForCompanyEnum = EnumUtils.toItems(IncidentForCompanyEnum);
		this.formGroup.addControl(KleFormGroupEnum.PROCESS_CONTROL, this.processControlFormGroup);

		this.store
			.select(KleSelect.getProcessControlRequest)
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => this.processControlFormGroup.patchValue(value));
	}

	isCloseTypeCancel(): boolean {
		return this.processControlFormGroup.controls.closeType.value === IncidentForCompanyEnum.CANCEL.code;
	}
}
