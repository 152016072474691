<ea-unauthenticated-layout [formGroup]="formGroup">
	<ea-unauthenticated-form-container>
		<div class="text">{{ 'enterCode' | translate }}</div>
		<nc-input
			[id]="'sms-placeholder'"
			ncAutofocus
			[shouldAutofocus]="true"
			[formGroup]="formGroup"
			textAlign="center"
			formControlName="code"
			[mask]="{ mask: '* * * * * *', lazy: false }"
			label="code"
			placeholder="smsPlaceholder6digitSMSCode"
		>
		</nc-input>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="column" fxLayoutAlign="space-around">
				<a routerLink="/" class="link">{{ 'backToLogin' | translate }}</a>
			</div>
			<nc-submit-button
				[id]="'submit'"
				[formGroup]="formGroup"
				buttonLabel="resetPassword"
				(buttonClick)="onSubmit()"
				dialogLabel="processing"
			></nc-submit-button>
		</div>
	</ea-unauthenticated-form-container>
</ea-unauthenticated-layout>
