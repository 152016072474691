<ea-unauthenticated-layout [formGroup]="formGroup">
	<div *ngIf="theme === 'tellco-theme'" class="redirect">
		<div fxLayout="column wrap" fxLayoutAlign="center" fxLayoutGap="15px" class="redirect-info">
			<div class="logo">
				<img src="{{ LOGO_URL }}" alt="eAbsence Logo" class="logo-image" />
			</div>
			<div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
				<div class="text">
					<b>{{ 'newURL' | translate }}</b>
				</div>
				<div class="text">{{ 'newURLSpan' | translate }}</div>
			</div>
			<div fxLayout="row" fxLayoutAlign="start center">
				<button class="redirect-button" (click)="redirect()">{{ 'openAbsence' | translate }}</button>
			</div>
		</div>
	</div>
	<div *ngIf="theme !== 'tellco-theme'">
		<ea-unauthenticated-form-container>
			<nc-input
				[id]="'username'"
				ncAutofocus
				[shouldAutofocus]="true"
				[formGroup]="formGroup"
				formControlName="username"
				label="username"
			></nc-input>
			<nc-password
				[id]="'password'"
				[buttonId]="'hide-password'"
				[formGroup]="formGroup"
				formControlName="password"
				label="password"
			></nc-password>
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
				<div fxLayout="column" fxLayoutAlign="space-around">
					<a routerLink="/forgot/password" class="link">{{ 'forgotPassword' | translate }}</a>
				</div>
				<nc-submit-button
					[id]="'login'"
					[formGroup]="formGroup"
					buttonLabel="login"
					(buttonClick)="onSubmit()"
					dialogLabel="processing"
				></nc-submit-button>
			</div>
		</ea-unauthenticated-form-container>
	</div>
</ea-unauthenticated-layout>
