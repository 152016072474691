<div id="authority-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div mat-dialog-title>
		<mat-icon class="dialog-title-icon">delete</mat-icon>
		<span class="dialog-title-text">{{ 'delete' | translate }}</span>
	</div>
	<mat-dialog-content id="authority-dialog-content" fxLayout="column" fxLayoutGap="10px">
		{{ 'deleteConfirmation' | translate }} {{ 'deleteConfirmationNote' | translate }}
	</mat-dialog-content>
	<mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
			<nc-button
				[id]="'ok'"
				label="ok"
				[mat-dialog-close]="{ success: true, authorityId: authorityId, authorityType: authorityType }"
			></nc-button>
			<nc-button [id]="'close'" label="close" [mat-dialog-close]="{ success: false }"></nc-button>
		</div>
	</mat-dialog-actions>
</div>
