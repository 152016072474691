import { Injectable } from '@angular/core';
import { Absence } from '../model/absence';
import { dateToString, FormUtils, StringsUtils } from 'nc-utils';
import { AccidentDescription } from '../../kle/model/accident-description';

@Injectable({
	providedIn: 'root',
})
export class RequestModelFactory {
	/**
	 * Creates request model from absence
	 * @param absence absence model from form
	 * @param forceNew
	 */
	create(absence: Absence, forceNew?: boolean): FormData {
		return FormUtils.objectToFormData({
			documents: absence.documents,
			model: {
				...absence,
				incapacities: absence.incapacities.map(this.formatIncapacityDates),
				documents: [],
				forcedNew: !!forceNew,
				createdBy: null,
				isSwicaInsured: absence.isSwicaInsured === 'yes',
				isPkproInsured: absence.isPkproInsured === 'yes',
				caseManagementActive: absence.isCaseManagementActive === 'yes',
				hasAccidentInsurance: absence.hasAccidentInsurance === 'yes',
				sentToCaseManagementCompany: absence.isSentToCaseManagementCompany === 'yes',
				closedByCaseManagementCompany: absence.isClosedByCaseManagementCompany === 'yes',
				abortedByCaseManagementCompany: absence.isAbortedByCaseManagementCompany === 'yes',
				hasDailySicknessBenefitsInsurance: absence.hasDailySicknessBenefitsInsurance === 'yes',
				hasChildrenUnder18orInEducationUpTo25: absence.hasChildrenUnder18OrInEducationUpTo25 === 'yes',
				hasReturnedToWork: absence.hasReturnedToWork === 'yes',

				pregnancyDueDate: dateToString(absence.pregnancyDueDate),
				employmentEndDate: dateToString(absence.employmentEndDate),
				lastDeploymentDate: dateToString(absence.lastDeploymentDate),
				firstDeploymentDate: dateToString(absence.firstDeploymentDate),
				employmentStartDate: dateToString(absence.employmentStartDate),
				sentToCaseManagementCompanyDate: dateToString(absence.sentToCaseManagementCompanyDate),
				closedByCaseManagementCompanyDate: dateToString(absence.closedByCaseManagementCompanyDate),
				abortedByCaseManagementCompanyDate: dateToString(absence.abortedByCaseManagementCompanyDate),
				caseManagementDetails: this.formatCaseManagementDates(absence.caseManagementDetails),
				responsibleLDUserId: absence.responsibleLDUserId ? absence.responsibleLDUserId : 0,
				accidentDescription: absence.accidentDescription ? this.formatAccidentDescriptionDates(absence.accidentDescription) : null,
			},
		});
	}

	/**
	 * Formats case management part of absence
	 * @param caseManagementDetails case management details from absence
	 */
	private formatCaseManagementDates = (caseManagementDetails) => {
		return {
			...caseManagementDetails,
			reports: {
				...caseManagementDetails?.reports,
				startedOn: dateToString(caseManagementDetails?.reports?.startedOn),
				assessment: {
					...caseManagementDetails?.reports?.assessment,
					date: dateToString(caseManagementDetails?.reports?.assessment?.date),
				},
				reassessment: {
					...caseManagementDetails?.reports?.reassessment,
					date: dateToString(caseManagementDetails?.reports?.reassessment?.date),
				},
			},
			conclusionDetails: {
				...caseManagementDetails?.conclusionDetails,
				endedOn: dateToString(caseManagementDetails?.conclusionDetails?.endedOn),
				sustainabilityCheckOn: dateToString(caseManagementDetails?.conclusionDetails?.sustainabilityCheckOn),
			},
		};
	};

	/**
	 * Formats incapacity from absence
	 * @param incapacity incapacity model
	 */
	private formatIncapacityDates = (incapacity) => {
		return {
			...incapacity,
			validFrom: dateToString(incapacity.validFrom),
			validTo: dateToString(incapacity.validTo),
			takeWorkUpFullyDate: dateToString(incapacity.takeWorkUpFullyDate),
		};
	};

	createAbsenceAnnexData(absenceAnnex: any): FormData {
		return FormUtils.objectToFormData({
			documents: absenceAnnex.documents,
			model: {
				absenceCode: absenceAnnex.absenceCode,
				type: absenceAnnex.type,
				validFrom: absenceAnnex.validFrom !== '' ? dateToString(absenceAnnex.validFrom) : null,
				validTo: absenceAnnex.validTo !== '' ? dateToString(absenceAnnex.validTo) : null,
				incapacityToWork: absenceAnnex.percentage !== '' ? Number(absenceAnnex.percentage) : null,
				comment: absenceAnnex.comment,
			},
		});
	}

	createShareInformationData(shareInformation: any): FormData {
		return FormUtils.objectToFormData({
			documents: shareInformation.documents,
			model: {
				absenceCode: shareInformation.absenceCode,
				comment: shareInformation.comment,
			},
		});
	}

	formatAccidentDescriptionDates(accidentDescription: AccidentDescription) {
		return {
			...accidentDescription,
			exactDateTime: StringsUtils.isNotEmpty(accidentDescription.exactDateTime?.toString())
				? accidentDescription.exactDateTime.format('YYYY-MM-DDTHH:mm:ss')
				: '',
			approximate: StringsUtils.isNotEmpty(accidentDescription.approximate?.toString()) ? dateToString(accidentDescription.approximate) : '',
			dateOfDeath: StringsUtils.isNotEmpty(accidentDescription.dateOfDeath?.toString()) ? dateToString(accidentDescription.dateOfDeath) : '',
			relapseDate: StringsUtils.isNotEmpty(accidentDescription.relapseDate?.toString()) ? dateToString(accidentDescription.relapseDate) : '',
			lastWorkingDay: StringsUtils.isNotEmpty(accidentDescription.lastWorkingDay?.toString())
				? dateToString(accidentDescription.lastWorkingDay)
				: '',
			lastWorkingDayDateOfDeath: StringsUtils.isNotEmpty(accidentDescription.lastWorkingDayDateOfDeath?.toString())
				? dateToString(accidentDescription.lastWorkingDayDateOfDeath)
				: '',
		};
	}
}
