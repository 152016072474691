import { createAction, props } from '@ngrx/store';
import { NoteModel } from '../../model/note.model';
import { TaskModel } from '../../model/task.mode';
import { MilestoneModel } from '../../model/milestone.model';

export const getOpenMilestones = createAction('[Tasks] Get open milestones', props<{ code: string; numberOfDays: number[] }>());
export const getDoneMilestones = createAction('[Tasks] Get done milestones', props<{ code: string; numberOfDays: number[] }>());
export const getOpenTasks = createAction('[Tasks] Get open tasks', props<{ code: string }>());
export const getDoneTasks = createAction('[Tasks] Get done tasks', props<{ code: string }>());
export const getNotes = createAction('[Tasks] Get notes', props<{ code: string }>());
export const getNote = createAction('[Tasks] Get note', props<{ noteId: number }>());
export const clearNote = createAction('[Tasks] Clear note');
export const getTask = createAction('[Tasks] Get task', props<{ taskId: number }>());
export const clearTask = createAction('[Tasks] Clear task');
export const getDoneMilestone = createAction('[Tasks] Get done milestone', props<{ milestoneId: number }>());
export const getOpenMilestone = createAction('[Tasks] Get open milestone', props<{ code: string; milestoneSettingsId: number }>());
export const saveNote = createAction('[Tasks] Save note', props<{ note: NoteModel }>());
export const saveTask = createAction('[Tasks] save task', props<{ task: TaskModel }>());
export const getNumberOfDays = createAction('[Tasks] Get number of days');
export const doneMilestone = createAction('[Tasks] Done milestone', props<{ milestone: MilestoneModel }>());
export const undoneMilestone = createAction('[Tasks] Undone milestone', props<{ milestoneId: number }>());
export const getCurrentAbsence = createAction('[Tasks] Get current absence', props<{ code: string }>());
export const getDocumentsForCheckList = createAction('[Tasks] Get documents for check list', props<{ absenceId: number; settingsId: number }>());
export const isDoneMilestoneButtonClicked = createAction('[Task] Is done milestone button clicked', props<{ isClicked: boolean }>());
