<ea-unauthenticated-layout [formGroup]="formGroup">
	<ea-unauthenticated-form-container>
		<div class="text">{{ 'loginSMSVerificationNote' | translate }}</div>
		<nc-input
			[id]="'sms-palceholder'"
			ncAutofocus
			[shouldAutofocus]="true"
			[formGroup]="formGroup"
			textAlign="center"
			[mask]="{ mask: '0 0 0 0 0 0', lazy: false }"
			formControlName="code"
			label="code"
			placeholder="smsPlaceholder6digitSMSCode"
		>
		</nc-input>
		<div fxLayout="row wrap" fxLayoutAlign="center">
			<nc-button [id]="'resend'" [onClick]="onResend" [isRaised]="false" color="primary" label="resendSMSCode"></nc-button>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<nc-button [id]="'back'" [onClick]="onBack" label="back"></nc-button>
			<nc-submit-button
				[id]="'continue'"
				[formGroup]="formGroup"
				buttonLabel="continue"
				(buttonClick)="onSubmit()"
				dialogLabel="processing"
			></nc-submit-button>
		</div>
	</ea-unauthenticated-form-container>
</ea-unauthenticated-layout>
