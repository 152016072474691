<form [formGroup]="formGroup" fxFill fxLayout="column">
	<ea-header-info [showAbsenceCode]="true" [showCompany]="true"></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
					<div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
						<div fxLayout="row" fxLayoutGap="5px">
							<nc-input
								id="insured-person"
								fxFlex="95"
								[isReadonly]="true"
								[formGroup]="formGroup"
								formControlName="employee"
								label="insuredPerson"
							>
							</nc-input>
							<mat-icon class="more-details-action" (click)="openEmployee()">info</mat-icon>
						</div>
						<div fxLayout="row" fxLayoutGap="5px">
							<nc-input
								id="company"
								fxFlex="95"
								[isReadonly]="true"
								[formGroup]="formGroup"
								formControlName="company"
								label="company"
							></nc-input>
							<mat-icon class="more-details-action" (click)="openCompany()">info</mat-icon>
						</div>
						<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px">
							<nc-button [onClick]="reassignAbsence" id="reassign-absence" [disabled]="isReadonly" label="reassignCaseToOtherEmployee">
							</nc-button>
							<nc-button
								[onClick]="resendConfirmationEmail"
								id="resend-confirmation-email"
								[disabled]="!hasSavedConfirmationEmail || isReadonly"
								matTooltip="{{ hasSavedConfirmationEmail ? null : ('caseConfirmationEmailUnavailable' | translate) }}"
								label="resendAbsenceConfirmationMail"
							></nc-button>
						</div>

						<ea-absence-type [formGroup]="formGroup" [isReadonly]="isReadonly"></ea-absence-type>
						<nc-datepicker id="created-on" [formGroup]="formGroup" [isReadonly]="true" formControlName="createdOn" label="createdOn">
						</nc-datepicker>
						<nc-textarea
							id="comment"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							formControlName="comment"
							label="comment"
							maxLength="600"
						></nc-textarea>
						<nc-quick-search
							textInputId="medical-diagnosis"
							resetButtonId="medical-diagnosis-reset"
							undoButtonId="medical-diagnosis-undo"
							[formGroup]="formGroup"
							[isReadonly]="isReadonly"
							label="medicalDiagnosis"
							formControlName="medicalDiagnosisCode"
							[fetchSingle]="getMedicalDiagnosis"
							[fetchAll]="searchMedicalDiagnosis"
						>
						</nc-quick-search>

						<nc-quick-search
							textInputId="medical-diagnosis-additional"
							resetButtonId="medical-diagnosis-additional-reset"
							undoButtonId="medical-diagnosis-additional-undo"
							[formGroup]="formGroup"
							[isReadonly]="isReadonly"
							label="medicalDiagnosisAdditional"
							formControlName="medicalDiagnosisAdditionalCode"
							[fetchSingle]="getMedicalDiagnosis"
							[fetchAll]="searchMedicalDiagnosis"
						>
						</nc-quick-search>
						<nc-select
							id="responsible-user"
							searchTextId="responsible-user-search"
							searchButtonId="responsible-user-search-clear"
							clearIconId="responsible-user-clear"
							*ngIf="permissions.includes('CM_CASE_ASSIGN')"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							[data$]="responsibleUsers$"
							placeholder="defaultSelectPleaceholder"
							label="responsibleUser"
							[removeSelected]="true"
							formControlName="responsibleUserId"
						>
						</nc-select>

						<nc-select
							id="responsible-ld-user"
							searchTextId="responsible-ld-user-search"
							searchButtonId="responsible-ld-user-search-clear"
							clearIconId="responsible-ld-user-clear"
							*ngIf="permissions.includes('LD_CASE_ASSIGN')"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							[data$]="responsibleLDUsers$"
							placeholder="defaultSelectPleaceholder"
							label="responsibleLDUser"
							[removeSelected]="true"
							formControlName="{{ isReadonly ? 'responsibleLDUserName' : 'responsibleLDUserId' }}"
						>
						</nc-select>

						<div fxLayout="column" fxLayoutGap="10px" *ngIf="otherAbsences?.length > 0">
							<div>
								<nc-label id="other-active-absence-cases" value="otherActiveAbsenceCases"></nc-label>
								<div fxLayout="row wrap" fxLayoutGap="10px">
									<a *ngFor="let absence of otherAbsences" [routerLink]="['/absence', absence.code]">{{ absence.code }}</a>
								</div>
							</div>
							<nc-button
								matTooltip="{{ !isValidToPopulated ? ('lastValidToNeedsToBePopulated' | translate) : null }}"
								id="merge-existing-absence"
								*ngIf="!isReadonly"
								[disabled]="!isValidToPopulated"
								[onClick]="mergeExistingAbsence"
								label="mergeExistingAbsence"
							>
							</nc-button>
						</div>

						<div *ngIf="isArchived">
							<nc-input
								id="archived-reason"
								[isReadonly]="true"
								[formGroup]="formGroup"
								formControlName="archivedReason"
								label="archivingReason"
							></nc-input>
							<nc-textarea
								id="archived-comment"
								[isReadonly]="true"
								[formGroup]="formGroup"
								formControlName="archivedComment"
								label="archivingComment"
							></nc-textarea>
						</div>

						<div class="case-management-flags" fxLayout="column" fxLayoutGap="10px">
							<nc-toggle-buttons
								[formGroup]="formGroup"
								[isDisabled]="isReadonly"
								formControlName="isSentToCaseManagementCompany"
								label="sentToCaseManagementCompany"
								[options]="flagOptions"
							>
								<nc-datepicker
									id="sent-to-case-managment"
									[formGroup]="formGroup"
									[isReadonly]="isReadonly"
									*ngIf="formGroup.get('isSentToCaseManagementCompany')?.value === 'yes'"
									formControlName="sentToCaseManagementCompanyDate"
								>
								</nc-datepicker>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								[isDisabled]="isReadonly"
								formControlName="isClosedByCaseManagementCompany"
								label="closedByCaseManagementCompany"
								[options]="flagOptions"
							>
								<nc-datepicker
									id="closed-by-case-manager"
									[formGroup]="formGroup"
									[isReadonly]="isReadonly"
									*ngIf="formGroup.get('isClosedByCaseManagementCompany')?.value === 'yes'"
									formControlName="closedByCaseManagementCompanyDate"
								>
								</nc-datepicker>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								[isDisabled]="isReadonly"
								formControlName="isAbortedByCaseManagementCompany"
								label="abortedByCaseManagementCompany"
								[options]="flagOptions"
							>
								<nc-datepicker
									id="aborted-by-case-manager"
									[formGroup]="formGroup"
									[isReadonly]="isReadonly"
									*ngIf="formGroup.get('isAbortedByCaseManagementCompany')?.value === 'yes'"
									formControlName="abortedByCaseManagementCompanyDate"
								>
								</nc-datepicker>
							</nc-toggle-buttons>
						</div>
						<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px">
							<nc-toggle-buttons
								[formGroup]="formGroup"
								[isDisabled]="isReadonly"
								formControlName="isSwicaInsured"
								label="isSwicaInsured"
								[options]="flagOptions"
							>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								[isDisabled]="isReadonly"
								formControlName="isPkproInsured"
								label="isPkProInsured"
								[options]="flagOptions"
							>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								[isDisabled]="isReadonly"
								formControlName="isCaseManagementActive"
								label="caseManagementActive"
								[options]="flagOptions"
							>
							</nc-toggle-buttons>
						</div>
						<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px">
							<nc-toggle-buttons
								*ngIf="displayRecoverageButton"
								[formGroup]="formGroup"
								[isDisabled]="isReadonly"
								formControlName="isRecoverageActive"
								label="recoverageActive"
								[options]="flagOptions"
							>
							</nc-toggle-buttons>
						</div>
						<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px">
							<nc-toggle-buttons
								[formGroup]="formGroup"
								[isDisabled]="true"
								formControlName="hasRiskAssessment"
								label="riskAssessment"
								[options]="flagOptions"
							>
							</nc-toggle-buttons>
							<nc-datepicker
								id="pk-pro-insurance-entry-date"
								[formGroup]="formGroup"
								[isReadonly]="true"
								formControlName="pkProInsuranceEntryDate"
								label="pkProInsuranceEntryDate"
							>
							</nc-datepicker>
							<nc-datepicker
								id="pk-pro-insurance-exit-date"
								[formGroup]="formGroup"
								[isReadonly]="true"
								formControlName="pkProInsuranceExitDate"
								label="pkProInsuranceExitDate"
							>
							</nc-datepicker>
						</div>
					</div>
					<div fxLayout="column" fxLayoutGap="15px" fxFlex="50">
						<ea-container title="caseManagement" *ngIf="formGroup.get('isCaseManagementActive')?.value === 'yes'">
							<div fxLayout="column" fxLayoutGap="10px">
								<nc-input
									id="case-management-ktg-insurer"
									[isReadonly]="isReadonly"
									[formGroup]="formGroup"
									formControlName="caseManagementKTGInsurer"
									label="ktgInsurer"
								></nc-input>
								<nc-input
									id="case-management-uvg-insurer"
									[isReadonly]="isReadonly"
									[formGroup]="formGroup"
									formControlName="caseManagementUVGInsurer"
									label="uvgInsurer"
								></nc-input>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									[isDisabled]="isReadonly"
									formControlName="caseManagementBranchOffice"
									label="insurerBranchOffice"
									[options]="branchOfficeOptions"
								>
								</nc-toggle-buttons>
								<nc-input
									id="case-management-branch-office-person"
									[isReadonly]="isReadonly"
									[formGroup]="formGroup"
									formControlName="caseManagementBranchOfficeContactPerson"
									label="caseManagerContactPerson"
								></nc-input>
								<nc-input
									id="case-management-branch-office-phone"
									[isReadonly]="isReadonly"
									[formGroup]="formGroup"
									formControlName="caseManagementBranchOfficePhoneNumber"
									label="caseManagerPhoneNumber"
								></nc-input>
								<nc-datepicker
									id="case-management-started-on"
									[isReadonly]="isReadonly"
									[formGroup]="formGroup"
									formControlName="caseManagementStartedOnDate"
									label="begin"
								></nc-datepicker>
								<nc-datepicker
									id="case-management-assessment"
									[isReadonly]="isReadonly"
									[formGroup]="formGroup"
									formControlName="caseManagementAssessmentByExpertOnDate"
									label="assessment"
								></nc-datepicker>
								<nc-datepicker
									id="case-management-reassessment"
									[isReadonly]="isReadonly"
									[formGroup]="formGroup"
									formControlName="caseManagementReassessmentByExpertOnDate"
									label="reassessment"
								></nc-datepicker>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									[isDisabled]="isReadonly"
									formControlName="caseManagementIntegrationReport"
									label="integrationPlan"
									[options]="availableOptions"
								>
								</nc-toggle-buttons>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									[isDisabled]="isReadonly"
									formControlName="caseManagementAssessmentReport"
									label="assessmentAvailable"
									[options]="availableOptions"
								>
								</nc-toggle-buttons>
								<nc-toggle-buttons
									[formGroup]="formGroup"
									[isDisabled]="isReadonly"
									formControlName="caseManagementReassessmentReport"
									label="reassessmentAvailable"
									[options]="availableOptions"
								>
								</nc-toggle-buttons>
								<nc-textarea
									id="intervention-status"
									[formGroup]="formGroup"
									[isReadonly]="isReadonly"
									formControlName="caseManagementInterventionStatus"
									label="interventionStatus"
									maxLength="600"
								></nc-textarea>
								<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px">
									<nc-toggle-buttons
										[formGroup]="formGroup"
										[isDisabled]="isReadonly"
										formControlName="caseManagementAccomplishment"
										label="caseAccomplishment"
										[options]="flagWithUnknownOptions"
									>
									</nc-toggle-buttons>
									<ng-container [ngSwitch]="formGroup.get('caseManagementAccomplishment')?.value">
										<nc-toggle-buttons
											*ngSwitchCase="'YES'"
											label="pleaseSelectReason"
											[formGroup]="formGroup"
											[isDisabled]="isReadonly"
											[options]="yesAccomplishmentOptions"
											formControlName="caseManagementSuccessfulnessType"
										>
										</nc-toggle-buttons>
										<nc-toggle-buttons
											*ngSwitchCase="'NO'"
											label="pleaseSelectReason"
											[formGroup]="formGroup"
											[isDisabled]="isReadonly"
											[options]="noAccomplishmentOptions"
											formControlName="caseManagementSuccessfulnessType"
										>
										</nc-toggle-buttons>
									</ng-container>
								</div>
								<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px">
									<nc-datepicker
										id="case-management-ended-on-date"
										[formGroup]="formGroup"
										[isReadonly]="isReadonly"
										formControlName="caseManagementEndedOnDate"
										label="caseEnded"
									>
									</nc-datepicker>
									<nc-datepicker
										id="case-management-sustainability-checked-on"
										[formGroup]="formGroup"
										[isReadonly]="isReadonly"
										formControlName="caseManagementSustainabilityCheckedOnDate"
										label="sustainabilityControl"
									>
									</nc-datepicker>
								</div>
							</div>
						</ea-container>
						<ea-premium-details
							*ngIf="categoryType === ABSENCE_DECLARATION_TYPE.PREMIUM.code"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							[company]="company$ | async"
						>
						</ea-premium-details>
					</div>
				</div>
				<ea-incapacity-table [formArray]="incapacities" [isReadonly]="isReadonly"></ea-incapacity-table>
				<ea-container title="shareInformationTableTitle" *ngIf="(shareInformation$ | async)?.records?.length > 0" cssClass="margin-top-15">
					<div class="margin-top-10">
						<nc-datatable
							id="share-information"
							[data$]="shareInformation$"
							[columns]="columns.SHARE_INFORMATION_COLUMNS"
							[filterConfig]="shareInformationFilterConfig"
							[rowClick]="openAbsenceMessage"
						></nc-datatable>
					</div>
				</ea-container>
				<ea-documents
					id="cm-absence-document"
					buttonId="open-dialog"
					downloadButtonId="download-documents-button"
					[documentsFormArray]="documents"
					[isReadonly]="isReadonly"
					[additionalColumns]="additionalColumns"
				>
				</ea-documents>
				<ea-container title="mergedAbsences" *ngIf="(mergedAbsence$ | async)?.records?.length > 0" cssClass="margin-top-15">
					<div class="margin-top-10">
						<nc-datatable
							id="merge-absence"
							[data$]="mergedAbsence$"
							[columns]="mergedAbsenceColumns"
							[filterConfig]="mergeAbsenceFilterConfig"
						></nc-datatable>
					</div>
				</ea-container>
				<ea-container title="linkedRequests" *ngIf="(linkedAbsence$ | async)?.records?.length > 0" cssClass="margin-top-15">
					<div class="margin-top-10">
						<nc-datatable
							id="linked-absence"
							[data$]="linkedAbsence$"
							[columns]="columns.LINKED_ABSENCE_COLUMNS"
							[filterConfig]="linkedAbsenceFilterConfig"
							[rowClick]="openAbsenceMessage"
						></nc-datatable>
					</div>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-absence-footer
		[formGroup]="formGroup"
		[absence]="absence$ | async"
		[absenceCode]="code"
		[company]="company$ | async"
		[employee]="employee$ | async"
		[permissions]="permissions"
	>
	</ea-absence-footer>
</form>
