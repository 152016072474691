import { createReducer, on } from '@ngrx/store';
import { LD_RESPONSIBLE_INITIAL_STATE, LdResponsibleState } from './ld-responsible.state';
import { LdResponsibleApiAction, LdResponsiblePageAction } from './action';

export const ldResponsibleReducer = createReducer<LdResponsibleState>(
	LD_RESPONSIBLE_INITIAL_STATE,
	on(LdResponsibleApiAction.getLdResponsiblesSuccess, (state, action): LdResponsibleState => {
		return {
			...state,
			ldResponsibles: { records: action.records },
		};
	}),
	on(LdResponsibleApiAction.getLdResponsibleSuccess, (state, action): LdResponsibleState => {
		return {
			...state,
			currentLdResponsible: action.ldResponsible,
		};
	}),
	on(LdResponsibleApiAction.initializeUserOptionsSuccess, (state, action): LdResponsibleState => {
		return {
			...state,
			userOptions: action.responsibleUserOptions,
		};
	}),
	on(LdResponsiblePageAction.clearLdResponsible, (state): LdResponsibleState => {
		return {
			...state,
			currentLdResponsible: null,
		};
	})
);
