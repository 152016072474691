import { FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from 'nc-shared';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { KlePageAction } from '../../../state/action';

@Component({
	selector: 'ea-sign-certificate',
	templateUrl: './sign-certificate.component.html',
})
export class SignCertificateComponent extends BaseComponent {
	@Input() companyId: number;

	formGroup = this.formBuilder.group({
		password: ['', Validators.required],
	});

	constructor(private formBuilder: FormBuilder, private store: Store) {
		super();
	}

	submit(): void {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(KlePageAction.signCertificate({ companyId: this.companyId, password: data.password }));
	}
}
