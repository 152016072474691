import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';
import { Moment } from 'moment';
import { StringsUtils } from 'nc-utils';

const OK = null;
const REQUIRED = { required: true };
const INVALID_FIRST_INCAPACITY_PERCENTAGE = { invalidIncapacityPercentage: true };
const VALID_FROM_GREATER_THAN_VALID_TO = { invalidValidFromValidToRange: true };
const TAKE_WORK_FULLY_GREATER_THAN_VALID_TO = { invalidTakeWorkUpFullyAfterValidTo: true };
const INVALID_DIFFERENCE_BETWEEN_INCAPACITIES = { invalidDifferenceBetweenIncapacities: true };
const PREVIOUS_DATE_INVALID = { invalidPreviousDate: true };
const INVALID_INCAPACITY_PERCENTAGE = { invalidIncapacityPercentage0To100: true };

/**
 * Checks if valid from is greater than valid to
 */
const isValidFromGreaterThanValidTo = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const validFromControl = control.get('validFrom');
		const validToControl = control.get('validTo');
		const validFrom = validFromControl.value as Moment;
		const validTo = validToControl.value as Moment;
		const deletedValue = control.get('deleted').value;

		if (!deletedValue && validFrom != null && validTo != null && validFrom.isAfter(validTo)) {
			FormValidation.addError(validFromControl, VALID_FROM_GREATER_THAN_VALID_TO);
		} else {
			FormValidation.removeError(validFromControl, VALID_FROM_GREATER_THAN_VALID_TO);
		}

		return OK;
	};
};

/**
 * Checks if takeworkupfully is greater than valid to
 */
const isTakeWorkUpFullyGreaterThanValidTo = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const validToControl = control.get('validTo');
		const takeWorkFullyControl = control.get('takeWorkUpFullyDate');
		const takeWorkFully = takeWorkFullyControl.value as Moment;
		const validTo = validToControl.value as Moment;
		const deletedValue = control.get('deleted').value;

		if (!deletedValue && validTo != null && takeWorkFully != null && takeWorkFully.diff(validTo, 'days') !== 1) {
			FormValidation.addError(takeWorkFullyControl, TAKE_WORK_FULLY_GREATER_THAN_VALID_TO);
		} else {
			FormValidation.removeError(takeWorkFullyControl, TAKE_WORK_FULLY_GREATER_THAN_VALID_TO);
		}

		return OK;
	};
};

/**
 * Checks if there is invalid difference between previous and current incapacity
 */
const isInvalidDifferenceBetweenIncapacities = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const validFromControl = control.get('validFrom');
		const previousValidToControl = control.get('previousValidTo');
		const validFrom = validFromControl.value as Moment;
		const previousValidTo = previousValidToControl.value as Moment;
		const deletedValue = control.get('deleted').value;

		if (!deletedValue && previousValidTo != null && validFrom != null && validFrom.diff(previousValidTo, 'days') !== 1) {
			FormValidation.addError(validFromControl, INVALID_DIFFERENCE_BETWEEN_INCAPACITIES);
		} else {
			FormValidation.removeError(validFromControl, INVALID_DIFFERENCE_BETWEEN_INCAPACITIES);
		}

		return OK;
	};
};

/**
 * Check if previous date is set before setting new from date
 */
const isPreviousDateSet = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const previousValidToControl = control.get('previousValidTo');
		const validFromControl = control.get('validFrom');
		const isFirstControl = control.get('isFirst');
		const deletedValue = control.get('deleted').value;

		if (!deletedValue && previousValidToControl.value == null && !isFirstControl.value) {
			FormValidation.addError(validFromControl, PREVIOUS_DATE_INVALID);
		} else {
			FormValidation.removeError(validFromControl, PREVIOUS_DATE_INVALID);
		}

		return OK;
	};
};

/**
 * Validates if incapacity to work percentage is in the correct range
 */
const incapacityPercentageRange = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const percentageControl = control.get('percentage');
		const isFirstControl = control.get('isFirst');
		const deletedValue = control.get('deleted').value;

		if (StringsUtils.isNotEmpty(percentageControl.value) && !deletedValue) {
			FormValidation.removeError(percentageControl, REQUIRED);
			const numberValue = Number(percentageControl.value);
			if (!(numberValue >= 10 && numberValue <= 100) && isFirstControl.value) {
				FormValidation.addError(percentageControl, INVALID_FIRST_INCAPACITY_PERCENTAGE);
			} else {
				FormValidation.removeError(percentageControl, INVALID_FIRST_INCAPACITY_PERCENTAGE);
			}

			if (!(numberValue >= 0 && numberValue <= 100) && !isFirstControl.value) {
				FormValidation.addError(percentageControl, INVALID_INCAPACITY_PERCENTAGE);
			} else {
				FormValidation.removeError(percentageControl, INVALID_INCAPACITY_PERCENTAGE);
			}
		} else if (!deletedValue) {
			FormValidation.addError(percentageControl, REQUIRED);
		} else {
			FormValidation.removeError(percentageControl, REQUIRED);
			FormValidation.removeError(percentageControl, INVALID_FIRST_INCAPACITY_PERCENTAGE);
			FormValidation.removeError(percentageControl, INVALID_INCAPACITY_PERCENTAGE);
		}

		return OK;
	};
};

export {
	incapacityPercentageRange,
	isValidFromGreaterThanValidTo,
	isInvalidDifferenceBetweenIncapacities,
	isPreviousDateSet,
	isTakeWorkUpFullyGreaterThanValidTo,
};
