import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdministrationApiAction, AdministrationPageAction } from './action';
import { map, switchMap } from 'rxjs/operators';
import { AdministrationService } from '../service/administration.service';
import { DialogService } from 'nc-utils';

@Injectable()
export class AdministrationEffect {
	constructor(private actions$: Actions, private administrationService: AdministrationService, private dialogService: DialogService) {}

	filterCompaniesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AdministrationPageAction.filterCompanies),
			switchMap((action) =>
				this.administrationService.filterCompanies(action.start, action.length, action.order, action.columns, action.globalSearch)
			),
			map((result) => AdministrationApiAction.filterCompaniesSuccess({ companies: result }))
		);
	});
}
