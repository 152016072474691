import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryResponse } from '../model/query-response';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Option } from 'nc-shared';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly GET_PERSONS_URL = `${this.API_URL}/search/employee/`;
	private readonly GET_COMPANIES_URL = `${this.API_URL}/search/company`;
	private readonly GET_MEDICAL_DIAGNOSIS_URL = `${this.API_URL}/search/medical-diagnosis/`;
	private readonly GET_MEDICAL_INSTITUTIONS_URL = `${this.API_URL}/search/medical-institution`;

	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets companies for quick search
	 * @param query search term
	 */
	getCompanies(query: string): Observable<Option[]> {
		return this.httpClient.post<QueryResponse>(this.GET_COMPANIES_URL, { query }).pipe(map((result) => result.records.map(this.mapOption)));
	}

	/**
	 * Gets persons for quick search
	 * @param query search term
	 * @param companyId company unique identifier
	 */
	getPersons(query: string, companyId: string): Observable<Option[]> {
		return this.httpClient
			.post<QueryResponse>(this.GET_PERSONS_URL + companyId, { query })
			.pipe(map((result) => result.records.map(this.mapOption)));
	}

	/**
	 * Gets persons for quick search
	 * @param query search term
	 * @param absenceType type of absence
	 */
	getMedicalDiagnosis(query: string, absenceType: string): Observable<Option[]> {
		return this.httpClient
			.post<QueryResponse>(this.GET_MEDICAL_DIAGNOSIS_URL + absenceType, { query })
			.pipe(map((result) => result.records.map(this.mapOption)));
	}

	mapOption = (record): Option => {
		return {
			value: record.id,
			text: record.text,
		};
	};

	/**
	 * Gets treatments for quick search
	 * @param query search term
	 * Note API for medical institutions doesn't exist, this url needs to be changed after API is created
	 */
	getMedicalInstitutions(query: string): Observable<Option[]> {
		return this.httpClient
			.post<QueryResponse>(this.GET_MEDICAL_INSTITUTIONS_URL, { query })
			.pipe(map((result) => result.records.map(this.mapOption)));
	}
}
