import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { KleApiAction, KlePageAction, KleSelect } from './action';
import { KleService } from '../service/kle.service';
import { DialogConfigurationUtils, DialogService, DialogType, StringsUtils } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';
import { ErrorAction } from '../../state/error/action';
import { TreatmentsService } from '../service/treatments.service';
import { FileUtils } from '../../shared/utils/file.utils';
import { PingResultDialogComponent } from '../component/kle/ping-result-dialog/ping-result-dialog.component';
import { KleResultDialogComponent } from '../component/kle-result-dialog/kle-result-dialog.component';
import { KleResultTypeEnum } from '../model/enums/kle-result-type.enum';
import { Store } from '@ngrx/store';
import { EventQueueService } from '../../shared/service/event-queue.service';
import { BroadcastEventType } from '../../shared/enumeration/broadcast-event-type.enumeration';
import { AppEvent } from '../../shared/model/app-event.model';

@Injectable()
export class KleEffect {
	constructor(
		private store: Store,
		private actions$: Actions,
		private service: KleService,
		private treatmentsService: TreatmentsService,
		private dialogService: DialogService,
		private eventQueue: EventQueueService
	) {}

	sendPingEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.sendPing),
			switchMap((action) => {
				this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
				return this.service.ping(action.serviceType);
			}),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return response.dateTime ? KleApiAction.sendPingSuccess(response) : KleApiAction.sendPingFail({ message: response.message });
			})
		);
	});

	sendPingSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KleApiAction.sendPingSuccess),
				tap((action) => {
					const config = { data: action };
					this.dialogService.open(PingResultDialogComponent, config);
				})
			);
		},
		{ dispatch: false }
	);

	sendPingFailEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KleApiAction.sendPingFail),
				tap((action) => {
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(action.message));
				})
			);
		},
		{ dispatch: false }
	);

	checkInteroperabilityEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.checkInteroperability),
			switchMap((action) => this.service.checkInteroperability(action.request)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return KleApiAction.checkInteroperabilitySuccess({ response });
			})
		);
	});

	declareIncidentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.declareIncident),
			switchMap((action) => this.service.declareIncidentTest(action.request)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return KleApiAction.declareIncidentTestSuccess({ response });
			})
		);
	});

	getPaymentForPersonEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getPaymentForPerson),
			switchMap((action) => this.service.getPayment(action.absenceCode)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return KleApiAction.getPaymentForPersonSuccess({ response });
			})
		);
	});

	getPaymentForCompanyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getPaymentForCompany),
			switchMap((action) => this.service.getPayment(action.absenceCode)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return KleApiAction.getPaymentForCompanySuccess({ response });
			})
		);
	});

	getPersonMutatedEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getPersonMutated),
			switchMap((action) => this.service.getPersonMutated(action.absenceCode)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return KleApiAction.getPersonMutatedSuccess({ response });
			})
		);
	});

	synchronizeIncidentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.synchronizeIncident),
			switchMap((action) => this.service.synchronizeIncident(action.request)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				let nextAction;

				if (response.success && response.value.success) {
					nextAction = KleApiAction.synchronizeIncidentSuccess({ response: response.value });
				} else {
					nextAction = !response.success
						? ErrorAction.submitFailed({
								errors: response.errors[''] == null ? response.errors : null,
								message: response.errors[''] != null ? response.errors['']['message'] : null,
						  })
						: ErrorAction.submitFailed({ message: response.value.message });
				}
				return nextAction;
			})
		);
	});

	synchronizeIncidentSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KleApiAction.synchronizeIncidentSuccess),
			withLatestFrom(this.store.select(KleSelect.getAbsenceCode)),
			map(([action, absenceCode]) => {
				let config = {
					data: {
						...action.response,
						id: action.response.responseId,
						resultType: KleResultTypeEnum.SYNCHRONIZE_INCIDENT,
					},
				};
				this.dialogService
					.open(KleResultDialogComponent, config)
					.afterClosed()
					.subscribe(() => {
						this.dialogService.closeAll();
						this.eventQueue.dispatch(new AppEvent(BroadcastEventType.SYNCHRONIZE_INCIDENT_SUCCESS));
					});
				return KlePageAction.getIncident({ absenceCode });
			})
		);
	});

	getIncidentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getIncident),
			switchMap((action) => this.service.getIncident(action.absenceCode)),
			map((response) => KleApiAction.getIncidentSuccess({ response }))
		);
	});

	getMedicalInstitutionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getMedicalInstitution),
			switchMap((action) => this.treatmentsService.getMedicalInstitution(action.id)),
			map((response) => KleApiAction.getMedicalInstitutionSuccess({ response }))
		);
	});

	getEmploymentContractEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getAdditionsPerson),
			switchMap((action) => this.service.getAdditionsPerson(action.absenceCode)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return KleApiAction.getAdditionsPersonSuccess({ response });
			})
		);
	});

	getDialogMessageEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getDialogMessage),
			switchMap((action) => this.service.getDialogMessage(action.absenceCode, action.storyId)),
			map((response) => KleApiAction.getDialogMessageSuccess({ dialogMessage: response }))
		);
	});

	getSettlementEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getSettlement),
			switchMap((action) => this.service.getSettlement(action.absenceCode, action.settlementId)),
			map((response) => KleApiAction.getSettlementSuccess({ settlement: response }))
		);
	});

	sendSettlementsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.sendSettlements),
			switchMap((action) => this.service.sendSettlements(action.absenceCode)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				const config = response.success
					? DialogConfigurationUtils.info('successSendingSettlements', 'info')
					: DialogConfigurationUtils.error('error');
				this.dialogService.open(NcInformationDialogComponent, config);
				return KlePageAction.getIncident({ absenceCode: response.value });
			})
		);
	});

	generatePDFEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KlePageAction.generatePDF),
				switchMap((action) => this.service.generatePDF(action.absenceCode, action.settlementId)),
				map((response) => {
					this.dialogService.close(DialogType.PROCESSING);
					FileUtils.download(response);
				})
			);
		},
		{ dispatch: false }
	);

	getCompaniesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getCompanies),
			switchMap((action) => this.service.getCompanies(action.companyIds)),
			map((response) => KleApiAction.getCompaniesSuccess({ companies: response }))
		);
	});

	getFetchedCompanyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.fetchRegisterOrganizationModel),
			switchMap((action) => this.service.fetchRegisterOrganizationData(action.companyId)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return KleApiAction.fetchRegisterOrganizationModelSuccess({ response });
			})
		);
	});

	registerOrganizationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.registerOrganization),
			switchMap((action) => this.service.registerOrganization(action.companyId)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);

				return response.success
					? KleApiAction.registerOrganizationSuccess({ response: response })
					: ErrorAction.submitFailed({
							errors: response.errors[''] == null ? response.errors : null,
							message: response.errors[''] != null ? response.errors['']['message'] : null,
					  });
			})
		);
	});

	registerOrganizationSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KleApiAction.registerOrganizationSuccess),
				tap((action) => {
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info(action.response.value, 'info'));
				})
			);
		},
		{ dispatch: false }
	);

	getCertificateRequestIdEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getCertificateRequestId),
			switchMap((action) => this.service.getCertificateRequestId(action.companyId)),
			map((response) => KleApiAction.getCertificateRequestIdSuccess({ response }))
		);
	});

	getResultEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.getResult),
			switchMap((action) => this.service.getResult(action.companyId)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);

				return response.success
					? KleApiAction.getResultSuccess({ response: response })
					: ErrorAction.submitFailed({ message: response.errors['']['message'] });
			})
		);
	});

	getResultSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KleApiAction.getResultSuccess),
				tap((action) => {
					if (action.response.password) {
						const message = StringsUtils.isEmpty(action.response.password) ? '' : 'Password: ' + action.response.password;
						this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info(message, 'success'));
					} else {
						this.dialogService.open(
							NcInformationDialogComponent,
							DialogConfigurationUtils.info('Expected availability: ' + action.response.expectedAvailability, 'processing')
						);
					}
				})
			);
		},
		{ dispatch: false }
	);

	signCertificateEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.signCertificate),
			switchMap((action) => this.service.signCertificate(action.companyId, action.password)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);

				return response.success
					? KleApiAction.signCertificateSuccess({ response })
					: ErrorAction.submitFailed({ message: response.errors['']['message'] });
			})
		);
	});

	renewCertificateEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(KlePageAction.renewCertificate),
			switchMap((action) => this.service.renewCertificate(action.companyId)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);

				return response.success
					? KleApiAction.signCertificateSuccess({ response })
					: ErrorAction.submitFailed({ message: response.errors['']['message'] });
			})
		);
	});

	signCertificateSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KleApiAction.signCertificateSuccess),
				tap((action) => {
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info(action.response.value, 'info'));
				})
			);
		},
		{ dispatch: false }
	);

	generateAccidentCertificateEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KlePageAction.generateAccidentCertificate),
				switchMap((action) => this.service.generateAccidentCertificate(action.absenceCode)),
				map((response) => {
					this.dialogService.close(DialogType.PROCESSING);
					FileUtils.download(response);
				})
			);
		},
		{ dispatch: false }
	);

	generatePharmacyCertificateEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(KlePageAction.generatePharmacyCertificate),
				switchMap((action) => this.service.generatePharmacyCertificate(action.absenceCode)),
				map((response) => {
					this.dialogService.close(DialogType.PROCESSING);
					FileUtils.download(response);
				})
			);
		},
		{ dispatch: false }
	);
}
