import { NgModule } from '@angular/core';
import { NcLanguagePickerModule } from 'nc-language-picker';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NcSplashScreenModule } from 'nc-splash-screen';
import { NcSharedModule } from 'nc-shared';
import { DateFormats, DateTimeFormats, NcUtilsModule } from 'nc-utils';
import { NcConfirmationDialogModule } from 'nc-confirmation-dialog';
import { NcButtonModule } from 'nc-button';
import { NcDatatableModule } from 'nc-datatable';
import { NcDatepickerModule } from 'nc-datepicker';
import { NcDecisionDialogModule } from 'nc-decision-dialog';
import { NcLabelModule } from 'nc-label';
import { NcProcessingDialogModule } from 'nc-processing-dialog';
import { NcCheckboxModule } from 'nc-checkbox';
import { NcDocumentsModule } from 'nc-documents';
import { NcInputModule } from 'nc-input';
import { NcFormModule } from 'nc-form';
import { NcMainContentModule } from 'nc-main-content';
import { NcTextareaModule } from 'nc-textarea';
import { NcQuickSearchModule } from 'nc-quick-search';
import { NcSubmitButtonModule } from 'nc-submit-button';
import { NcFileUploadModule } from 'nc-file-upload';
import { NcPdfViewerModule } from 'nc-pdf-viewer';
import { NcToggleButtonsModule } from 'nc-toggle-buttons';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UnauthorizedAccessDialogComponent } from './component/dialog/unauthorized-access-dialog/unauthorized-access-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from './component/error/not-found/not-found.component';
import { UnavailableComponent } from './component/error/unavailable/unavailable.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NcPasswordInputModule } from 'nc-password-input';
import { NcInformationDialogModule } from 'nc-information-dialog';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MenuItemComponent } from './component/sidebar/menu-item/menu-item.component';
import { BrowserModule } from '@angular/platform-browser';
import { SubMenuComponent } from './component/sidebar/sub-menu/sub-menu.component';
import { FooterComponent } from './component/footer/footer.component';
import { NcSelectModule } from 'nc-select';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { State } from '../state/app.state';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { SettingsSelect } from '../state/settings/action';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { DocumentsComponent } from './component/documents/documents.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ContainerComponent } from './component/container/container.component';
import { VersionComponent } from './component/version/version.component';
import { PreviewDocumentComponent } from './component/preview-document/preview-document.component';
import { CreateFooterComponent } from './component/create-footer/create-footer.component';
import { DocumentsTableComponent } from './component/documents-table/documents-table.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderInfoComponent } from './component/header-info/header-info.component';
import { MatBadgeModule } from '@angular/material/badge';
import { WebSocketService } from './service/websocket.service';
import { NcAccordionModule } from 'nc-accordion';
import { KleValidationDialogComponent } from './component/kle-validation-dialog/kle-validation-dialog.component';
import { NcFormGeneratorModule } from 'nc-form-generator';
import { NcDateTimePickerModule } from 'nc-date-time-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NcContainerModule } from 'nc-container';
import { AccidentDescriptionFormComponent } from '../kle/component/accident-description/accident-description-form/accident-description-form.component';
import { TreatmentsTableComponent } from '../kle/component/treatments/treatments-table/treatments-table.component';

@NgModule({
	imports: [
		RouterModule,
		BrowserModule,
		ScrollingModule,
		TranslateModule,
		NgxChartsModule,
		FlexLayoutModule,
		ReactiveFormsModule,

		MatCardModule,
		MatTabsModule,
		MatIconModule,
		MatButtonModule,
		MatSidenavModule,
		MatTooltipModule,
		MatToolbarModule,
		MatNativeDateModule,
		MatMomentDateModule,
		NgxMatMomentModule,
		MatMenuModule,

		NcFormModule,
		NcUtilsModule,
		NcLabelModule,
		NcInputModule,
		NcFormGeneratorModule,
		NcSharedModule,
		NcSelectModule,
		NcButtonModule,
		NcTextareaModule,
		NcCheckboxModule,
		NcContainerModule,
		NcDocumentsModule,
		NcDatatableModule,
		NcPdfViewerModule,
		NcDatepickerModule,
		NcDateTimePickerModule,
		NcFileUploadModule,
		NcQuickSearchModule,
		NcMainContentModule,
		NcSubmitButtonModule,
		NcSplashScreenModule,
		NcPasswordInputModule,
		NcToggleButtonsModule,
		NcDecisionDialogModule,
		NcLanguagePickerModule,
		NcProcessingDialogModule,
		NcInformationDialogModule,
		NcConfirmationDialogModule,
		MatBadgeModule,
		NcAccordionModule,
	],
	declarations: [
		FooterComponent,
		SidebarComponent,
		SubMenuComponent,
		VersionComponent,
		MenuItemComponent,
		NotFoundComponent,
		DocumentsComponent,
		ContainerComponent,
		UnavailableComponent,
		UnauthorizedAccessDialogComponent,
		PreviewDocumentComponent,
		CreateFooterComponent,
		DocumentsTableComponent,
		HeaderInfoComponent,
		KleValidationDialogComponent,
		AccidentDescriptionFormComponent,
		TreatmentsTableComponent,
	],
	exports: [
		CommonModule,
		RouterModule,
		BrowserModule,
		TranslateModule,
		ScrollingModule,
		NgxChartsModule,
		FlexLayoutModule,
		ReactiveFormsModule,

		MatCardModule,
		MatTabsModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		MatToolbarModule,
		MatSidenavModule,
		MatTooltipModule,
		MatNativeDateModule,
		MatMomentDateModule,
		NgxMatMomentModule,
		MatMenuModule,

		NcFormModule,
		NcUtilsModule,
		NcLabelModule,
		NcInputModule,
		NcFormGeneratorModule,
		NcSharedModule,
		NcButtonModule,
		NcSelectModule,
		NcTextareaModule,
		NcCheckboxModule,
		NcContainerModule,
		NcDocumentsModule,
		NcDatatableModule,
		NcPdfViewerModule,
		NcDatepickerModule,
		NcFileUploadModule,
		NcQuickSearchModule,
		NcMainContentModule,
		NcSubmitButtonModule,
		NcSplashScreenModule,
		NcPasswordInputModule,
		NcToggleButtonsModule,
		NcDecisionDialogModule,
		NcLanguagePickerModule,
		NcProcessingDialogModule,
		NcInformationDialogModule,
		NcConfirmationDialogModule,
		NcAccordionModule,

		FooterComponent,
		SidebarComponent,
		VersionComponent,
		NotFoundComponent,
		DocumentsComponent,
		ContainerComponent,
		UnavailableComponent,
		UnauthorizedAccessDialogComponent,
		PreviewDocumentComponent,
		CreateFooterComponent,
		DocumentsTableComponent,
		HeaderInfoComponent,
		KleValidationDialogComponent,
		AccidentDescriptionFormComponent,
		TreatmentsTableComponent,
	],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: DateFormats },
		{ provide: NGX_MAT_DATE_FORMATS, useValue: DateTimeFormats },
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		WebSocketService,
	],
})
export class SharedModule {
	constructor(private store: Store<State>, private dateAdapter: DateAdapter<any>) {
		store.select(SettingsSelect.getLanguage).subscribe((language) => {
			this.dateAdapter.setLocale(language);
		});
	}
}
