import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogService, DialogType } from 'nc-utils';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { TaskService } from '../service/task.service';
import { TaskApiAction, TaskPageAction, TaskSelect } from './action';
import { ErrorAction } from '../../state/error/action';
import { Store } from '@ngrx/store';
import { SettingsPageAction, SettingsSelect } from '../../state/settings/action';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TaskEffect {
	constructor(
		private actions$: Actions,
		private taskService: TaskService,
		private dialogService: DialogService,
		private store: Store,
		private translateService: TranslateService
	) {}

	getOpenMilestonesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getOpenMilestones),
			switchMap((action) => this.taskService.getOpenMilestones(action.code, action.numberOfDays)),
			map((result) => TaskApiAction.getOpenMilestonesSuccess({ openMilestones: result }))
		);
	});

	getDoneMilestonesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getDoneMilestones),
			switchMap((action) => this.taskService.getDoneMilestones(action.code, action.numberOfDays)),
			map((result) => TaskApiAction.getDoneMilestonesSuccess({ doneMilestones: result }))
		);
	});

	getOpenTasksEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getOpenTasks),
			mergeMap((action) => this.taskService.getOpenTasks(action.code)),
			map((result) => TaskApiAction.getOpenTasksSuccess({ openTasks: result }))
		);
	});

	getDoneTasksEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getDoneTasks),
			mergeMap((action) => this.taskService.getDoneTasks(action.code)),
			map((result) => TaskApiAction.getDoneTasksSuccess({ doneTasks: result }))
		);
	});

	getNotesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getNotes),
			mergeMap((action) => this.taskService.getNotes(action.code)),
			map((result) => TaskApiAction.getNotesSuccess({ notes: result }))
		);
	});

	getNoteEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getNote),
			mergeMap((action) => this.taskService.getNote(action.noteId)),
			map((result) => TaskApiAction.getNoteSuccess({ note: result }))
		);
	});

	getTaskEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getTask),
			mergeMap((action) => this.taskService.getTask(action.taskId)),
			map((result) => TaskApiAction.getTaskSuccess({ task: result }))
		);
	});

	saveNoteEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.saveNote),
			mergeMap((action) => this.taskService.saveNote(action.note)),
			map((response) =>
				response.success ? TaskApiAction.saveNoteSuccess({ response }) : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	saveTaskEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.saveTask),
			mergeMap((action) => this.taskService.saveTask(action.task)),
			map((response) =>
				response.success ? TaskApiAction.saveTaskSuccess({ response }) : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	getNumberOfDaysEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getNumberOfDays),
			mergeMap(() => this.taskService.getNumberOfDays()),
			map((result) => TaskApiAction.getNumberOfDaysSuccess({ numberOfDays: result }))
		);
	});

	getOpenMilestoneEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getOpenMilestone),
			mergeMap((action) => this.taskService.getOpenMilestone(action.milestoneSettingsId, action.code)),
			map((result) => TaskApiAction.getMilestoneSuccess({ milestone: result }))
		);
	});

	getDoneMilestoneEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getDoneMilestone),
			mergeMap((action) => this.taskService.getDoneMilestone(action.milestoneId)),
			map((result) => TaskApiAction.getMilestoneSuccess({ milestone: result }))
		);
	});

	doneMilestoneEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.doneMilestone),
			switchMap((action) => this.taskService.doneMilestone(action.milestone)),
			map((response) =>
				response.success ? TaskApiAction.doneMilestoneSuccess({ response }) : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	submitSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				TaskApiAction.doneMilestoneSuccess,
				TaskApiAction.undoneMilestoneSuccess,
				TaskApiAction.saveNoteSuccess,
				TaskApiAction.saveTaskSuccess
			),
			withLatestFrom(this.store.select(TaskSelect.getCurrentAbsenceCode), this.store.select(SettingsSelect.getLanguage)),
			map(([action, absenceCode, language]) => {
				this.dialogService.close(DialogType.PROCESSING);
				if (language) {
					this.translateService.getTranslation(language);
				}
				return SettingsPageAction.navigate({ url: `absence/${absenceCode}/tasks` });
			})
		);
	});

	undoneMilestoneEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.undoneMilestone),
			switchMap((action) => this.taskService.undoneMilestone(action.milestoneId)),
			map((response) =>
				response.delete.success
					? TaskApiAction.undoneMilestoneSuccess({ response })
					: ErrorAction.submitFailed({ errors: response.delete.errors })
			)
		);
	});

	getDocumentsForCheckListEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getDocumentsForCheckList),
			mergeMap((action) => this.taskService.getDocumentsForCheckList(action.absenceId, action.settingsId)),
			map((result) => TaskApiAction.getDocumentsForCheckListSuccess({ documents: result }))
		);
	});

	getCurrentAbsenceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TaskPageAction.getCurrentAbsence),
			mergeMap((action) => this.taskService.getCurrentAbsence(action.code)),
			map((result) => TaskApiAction.getCurrentAbsenceSuccess({ currentAbsence: result }))
		);
	});
}
