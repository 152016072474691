import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SelectModel } from '../../shared/model/select.model';
import { TableData } from 'nc-datatable';
import { ResponseModel } from '../../shared/model/response.model';
import { map } from 'rxjs/operators';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class DashboardService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	private readonly ABCMAN_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly GET_MILESTONES_URL = `${this.ABCMAN_URL}/dashboard/milestones/`;
	private readonly GET_TASKS_URL = `${this.ABCMAN_URL}/tasks/user/{id}/open`;
	private readonly GET_ACTIVE_ABSENCES_URL = `${this.ABCMAN_URL}/dashboard/active-cases/`;
	private readonly GET_NUMBER_OF_DAYS_URL = `${this.ABCMAN_URL}/dashboard/number-of-days/`;
	private readonly GET_ARCHIVED_ABSENCES_URL = `${this.ABCMAN_URL}/dashboard/archived-cases/`;
	private readonly GET_RESPONSIBLE_USERS_URL = `${this.ABCMAN_URL}/dashboard/responsible-users`;
	private readonly GET_ABSENCE_URL = `${this.ABCMAN_URL}/absences/`;
	private readonly GET_AWAIT_STORY = `${this.ABCMAN_URL}/kle/stories/await`;

	/**
	 * Gets milestones for grid
	 * @param userId user unique identifier
	 * @param numberOfDays milestone number of days
	 */
	getMilestones(userId: string, numberOfDays: number[]): Observable<{ [key: string]: string }[]> {
		const days = numberOfDays.length != 0 ? '/' + numberOfDays.join(',') : '';
		const url = this.GET_MILESTONES_URL + userId + days;
		return this.httpClient.get<{ [key: string]: string }[]>(url);
	}

	/**
	 * Gets tasks for grid
	 * @param userId user unique identifier
	 */
	getTasks(userId: string): Observable<{ [key: string]: string }[]> {
		const url = this.GET_TASKS_URL.replace('{id}', userId);
		return this.httpClient.get<TableData>(url).pipe(map((response) => response.records));
	}

	/**
	 * Gets active cases for grid
	 * @param userId user unique identifier
	 */
	getActiveCases(userId: string): Observable<{ [key: string]: string }[]> {
		const url = this.GET_ACTIVE_ABSENCES_URL + userId;
		return this.httpClient.get<{ [key: string]: string }[]>(url);
	}

	/**
	 * Gets archived cases for grid
	 * @param userId user unique identifier
	 */
	getArchivedCases(userId: string): Observable<{ [key: string]: string }[]> {
		const url = this.GET_ARCHIVED_ABSENCES_URL + userId;
		return this.httpClient.get<{ [key: string]: string }[]>(url);
	}

	/**
	 * Gets all number of days for select
	 */
	getNumberOfDays(): Observable<number[]> {
		const url = this.GET_NUMBER_OF_DAYS_URL + true;
		return this.httpClient.get<number[]>(url);
	}

	/**
	 * Gets responsible users for select
	 */
	getResponsibleUsers(): Observable<SelectModel> {
		return this.httpClient.get<SelectModel>(this.GET_RESPONSIBLE_USERS_URL);
	}

	/**
	 * Get active absence for HR Dashboard
	 * @param companyIds List of company identifiers
	 */
	getHrActiveAbsence(companyIds: number[]): Observable<TableData> {
		companyIds = companyIds.filter((value) => value !== 0);
		const url = `${this.GET_ABSENCE_URL}active/${companyIds}`;
		return companyIds.length > 0 ? this.httpClient.get<TableData>(url).pipe(map((response) => response)) : of({ records: [] });
	}

	/**
	 * Get absences for HR Dashboard which should be approved by HQ HR - status PENDING_APPROVAL
	 * @param companyIds List of company identifiers
	 */
	getCasesToBeApproved(companyIds: number[]): Observable<TableData> {
		companyIds = companyIds.filter((value) => value);
		const url = `${this.GET_ABSENCE_URL}cases-to-be-approved/${companyIds}`;
		return companyIds.length > 0 ? this.httpClient.get<TableData>(url).pipe(map((response) => response)) : of({ records: [] });
	}

	/**
	 * Get statistic for HR Dashboard
	 * @param companyIds List of company identifiers
	 */
	getHrStatistic(companyIds: number[]): Observable<any> {
		return this.httpClient.get<any>(`${this.GET_ABSENCE_URL}statistics/${companyIds}`);
	}

	/**
	 * Get archived cases for hr user
	 * @param companyIds List of company identifiers
	 */
	getHrArchivedCases(companyIds: number[]): Observable<TableData> {
		const url = `${this.GET_ABSENCE_URL}archived/${companyIds}`;
		return companyIds.length > 0 ? this.httpClient.get<TableData>(url).pipe(map((response) => response)) : of({ records: [] });
	}

	/**
	 * Gets await stories for grid for all incidents
	 * * @param companyIds List of company identifiers
	 */
	getAwaitStories(companyIds: number[]): Observable<TableData> {
		companyIds = companyIds.filter((value) => value);
		return companyIds.length > 0
			? this.httpClient.get<ResponseModel<TableData>>(`${this.GET_AWAIT_STORY}/${companyIds}`).pipe(map((response) => response.value))
			: of({ records: [] });
	}
}
