import { createReducer, on } from '@ngrx/store';
import { KLE_INITIAL_STATE, KleState } from './kle.state';
import { KleApiAction, KlePageAction } from './action';
import { parseDate } from 'nc-utils';
import { PositionTypeEnumeration } from '../model/position-type.enumeration';
import { AccidentDescriptionResponse } from '../model/response/accident-description.response';
import { AccidentDescription } from '../model/accident-description';
import { AdditionsPersonResponse } from '../model/response/additions-person.response';
import { AdditionsPerson } from '../model/additions-person';
import { KleAbsenceStoryResponse } from '../model/response/kle-absence-story.response';
import { KleAbsenceStory } from '../model/kle-absence-story';
import { Payment } from '../model/payment';
import { PaymentResponse } from '../model/response/payment.response';
import { EmploymentContractTypeEnumeration } from '../model/employment-contract-type.enumeration';

export const kleReducer = createReducer<KleState>(
	KLE_INITIAL_STATE,
	on(KleApiAction.checkInteroperabilitySuccess, (state, action): KleState => {
		return {
			...state,
			interoperability: action.response,
		};
	}),
	on(KlePageAction.clearResponseData, (state): KleState => {
		return {
			...state,
			interoperability: null,
			declareIncident: null,
		};
	}),
	on(KleApiAction.declareIncidentTestSuccess, (state, action): KleState => {
		return {
			...state,
			declareIncident: action.response,
		};
	}),
	on(KleApiAction.getIncidentSuccess, (state, action): KleState => {
		return {
			...state,
			incident: {
				...action.response,
				lastPayment: toPayment(action.response.lastPayment),
				lastAbsence: toAbsence(action.response.lastAbsence),
				lastAccidentDescription: toAccidentDescription(action.response.lastAccidentDescription),
				lastAdditionsPerson: toAdditionsPerson(action.response.lastAdditionsPerson),
			},
		};
	}),
	on(KleApiAction.getMedicalInstitutionSuccess, (state, action): KleState => {
		return {
			...state,
			treatment: {
				id: action.response.id,
				institutionId: action.response.institutionId,
				tableId: null,
				zsrNumber: action.response.zsrNumber,
				uidBfs: action.response.uidBfs,
				glnNumber: action.response.glnNumber,
				treatmentInstitution: action.response.institutionName,
				emailAddress: action.response.email,
				phoneNumber: action.response.homePhone,
				mobilePhoneNumber: action.response.mobilePhone,
				complementaryLine: action.response.addressComplementaryLine,
				street: action.response.street,
				postbox: action.response.postbox,
				locality: action.response.locality,
				zipCode: action.response.zip,
				city: action.response.city,
				country: action.response.country,
			},
		};
	}),
	on(KlePageAction.clearTreatmentsForm, (state): KleState => {
		return {
			...state,
			treatment: null,
		};
	}),
	on(KleApiAction.getAdditionsPersonSuccess, (state, action): KleState => {
		return {
			...state,
			incident: {
				...state.incident,
				lastAdditionsPerson: {
					...action.response,
					employmentContractType: action.response.positionType
						? EmploymentContractTypeEnumeration[action.response.employmentContractType].code
						: null,
					positionType: action.response.positionType ? PositionTypeEnumeration[action.response.positionType].code : null,
					validAsOf: parseDate(action.response.validAsOf),
					contractDissolveFrom: parseDate(action.response.contractDissolveFrom),
				},
			},
		};
	}),
	on(KleApiAction.getPersonMutatedSuccess, (state, action): KleState => {
		return {
			...state,
			personMutated: {
				...action.response,
			},
		};
	}),
	on(KleApiAction.getDialogMessageSuccess, (state, action): KleState => {
		return {
			...state,
			dialogMessage: action.dialogMessage.value,
		};
	}),
	on(KlePageAction.clearDialogMessage, (state): KleState => {
		return {
			...state,
			dialogMessage: null,
		};
	}),
	on(KleApiAction.getSettlementSuccess, (state, action): KleState => {
		return {
			...state,
			settlement: action.settlement,
		};
	}),
	on(KleApiAction.getCompaniesSuccess, (state, action): KleState => {
		return {
			...state,
			companies: action.companies,
		};
	}),
	on(KleApiAction.fetchRegisterOrganizationModelSuccess, (state, action): KleState => {
		return {
			...state,
			company: action.response,
		};
	}),
	on(KleApiAction.getCertificateRequestIdSuccess, (state, action): KleState => {
		return {
			...state,
			certificateRequestId: action.response,
		};
	}),
	on(KlePageAction.restartKleState, (state, action): KleState => {
		return KLE_INITIAL_STATE;
	})
);

const toAccidentDescription = (accidentDescriptionResponse: AccidentDescriptionResponse): AccidentDescription => {
	let result = null;

	if (accidentDescriptionResponse) {
		result = {
			...accidentDescriptionResponse,
			dateOfDeath: parseDate(accidentDescriptionResponse?.dateOfDeath),
			relapseDate: parseDate(accidentDescriptionResponse?.relapseDate),
			exactDateTime: parseDate(accidentDescriptionResponse?.exactDateTime, 'YYYY-MM-DDTHH:mm:ss'),
			approximate: parseDate(accidentDescriptionResponse?.approximate),
			lastWorkingDay: parseDate(accidentDescriptionResponse?.lastWorkingDay),
			lastWorkingDayDateOfDeath: parseDate(accidentDescriptionResponse?.lastWorkingDayDateOfDeath),
		};
	}

	return result;
};

const toAdditionsPerson = (additionsPersonResponse: AdditionsPersonResponse): AdditionsPerson => {
	let result = null;

	if (additionsPersonResponse) {
		result = {
			...additionsPersonResponse,
			validAsOf: parseDate(additionsPersonResponse?.validAsOf),
			contractDissolveFrom: parseDate(additionsPersonResponse?.contractDissolveFrom),
		};
	}

	return result;
};

const toAbsence = (absenceResponse: KleAbsenceStoryResponse): KleAbsenceStory => {
	let result = null;

	if (absenceResponse) {
		result = {
			absences: absenceResponse.absences?.map((absence) => {
				return {
					...absence,
					from: parseDate(absence.from),
				};
			}),
		};
	}

	return result;
};

const toPayment = (paymentResponse: PaymentResponse): Payment => {
	let result = null;

	if (paymentResponse) {
		result = {
			...paymentResponse,
			validAsOf: parseDate(paymentResponse?.validAsOf),
		};
	}

	return result;
};
