<form [formGroup]="formGroup" fxFill fxLayout="column">
	<ea-header-info [showCompany]="true"></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="30px">
				<div fxLayout="row wrap" fxLayoutAlign="center">
					<nc-toggle-buttons
						*ngIf="!isMobile"
						[multiple]="true"
						[options]="numberOfDays$ | async"
						[formGroup]="formGroup"
						formControlName="numberOfDays"
					>
					</nc-toggle-buttons>
				</div>
				<div class="multi-select">
					<nc-select
						[id]="'number-od-days'"
						[searchTextId]="'number-of-days-search'"
						[searchButtonId]="'number-of-days-search-clear'"
						[clearIconId]="'number-of-days-clear'"
						*ngIf="isMobile"
						label="numberOfDays"
						formControlName="numberOfDays"
						[formGroup]="formGroup"
						[multiple]="true"
						[data$]="numberOfDays$"
					>
					</nc-select>
				</div>
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
					<ea-container [title]="'openMilestones'" fxFlex="50">
						<div id="open-milestones">
							<nc-datatable
								[id]="'open-milestones'"
								[rowClick]="openMilestoneClicked"
								[columns]="milestoneColumns"
								[data$]="openMilestones$"
								[filterConfig]="filterConfigOpenMilestoneTable"
							>
							</nc-datatable>
						</div>
					</ea-container>
					<ea-container [title]="'doneMilestones'" fxFlex="50">
						<div id="done-milestones">
							<nc-datatable
								[id]="'done-milestones'"
								[rowClick]="doneMilestoneClicked"
								[columns]="milestoneColumns"
								[data$]="doneMilestones$"
								[filterConfig]="filterConfigDoneMilestoneTable"
							>
							</nc-datatable>
						</div>
					</ea-container>
				</div>
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
					<ea-container [title]="'openTasks'" fxFlex="50">
						<div id="open-task">
							<nc-datatable
								[id]="'open-task'"
								[rowClick]="taskClicked"
								[columns]="taskColumns"
								[data$]="openTasks$"
								[filterConfig]="filterConfigOpenTasksTable"
							>
							</nc-datatable>
						</div>
					</ea-container>
					<ea-container [title]="'doneTasks'" fxFlex="50">
						<div id="done-task">
							<nc-datatable
								[id]="'done-task'"
								[rowClick]="taskClicked"
								[columns]="taskColumns"
								[data$]="doneTasks$"
								[filterConfig]="filterConfigDoneTasksTable"
							>
							</nc-datatable>
						</div>
					</ea-container>
				</div>
				<div>
					<ea-container [title]="'notes'">
						<div id="notes">
							<nc-datatable
								[id]="'notes'"
								[rowClick]="noteClicked"
								[columns]="noteColumns"
								[data$]="notes$"
								[filterConfig]="filterConfigNotesTable"
							>
							</nc-datatable>
						</div>
					</ea-container>
				</div>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-task-footer [isTask]="true"></ea-task-footer>
</form>
