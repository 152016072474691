import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { FormBuilder, Validators } from '@angular/forms';
import { KlePageAction, KleSelect } from '../../../state/action';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	templateUrl: './register-organization-form.component.html',
})
export class RegisterOrganizationFormComponent extends BaseComponent implements OnInit {
	companyId: number;
	certificateRequestId: string;

	formGroup = this.formBuilder.group({
		companyId: null as number,
		companyName: ['', Validators.required],
		complementaryLine: '',
		uid: ['', Validators.required],
		contactName: ['', Validators.required],
		emailAddress: ['', Validators.required],
		phoneNumber: '',
		mobilePhoneNumber: '',
		insuranceId: ['', Validators.required],
		insuranceCompanyName: ['', Validators.required],
		customerIdentity: ['', Validators.required],
		contractIdentity: ['', Validators.required],
	});

	constructor(private formBuilder: FormBuilder, private store: Store, private route: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this.companyId = params.companyId;
		});

		this.store.dispatch(KlePageAction.getCertificateRequestId({ companyId: this.companyId }));

		this.store.select(KleSelect.getCertificateRequestId).subscribe((certificateRequestId) => (this.certificateRequestId = certificateRequestId));

		this.store
			.select(KleSelect.getFetchedCompany)
			.pipe(filter((company) => company != null))
			.subscribe((company) => {
				this.formGroup.patchValue(company);
			});
	}

	fetch(): void {
		this.store.dispatch(KlePageAction.fetchRegisterOrganizationModel({ companyId: this.companyId }));
	}

	submit(): void {
		const formData = this.formGroup.getRawValue();
		const registerOrganization = {
			...formData,
			companyId: this.companyId,
		};

		this.store.dispatch(KlePageAction.registerOrganization({ companyId: this.companyId }));
	}

	getResult(): void {
		this.store.dispatch(KlePageAction.getResult({ companyId: this.companyId }));
	}

	renewCertificate(): void {
		this.store.dispatch(KlePageAction.renewCertificate({ companyId: this.companyId }));
	}
}
