import { createAction, props } from '@ngrx/store';

export const logout = createAction('[Logout] Logout');
export const resend = createAction('[Verify login] Resend verification code');
export const checkLogout = createAction('[Logout] Check logout');
export const setLogoutClicked = createAction('[Logout] Set logout clicked', props<{ logoutClicked: boolean }>());
export const allowLogout = createAction('[Logout] Allow logout', props<{ allowLogout: boolean }>());
export const setFormPage = createAction('[Logout] Set form page', props<{ isFormPage: boolean }>());
export const verifyLogin = createAction('[Verify login] Navigate to verify login page');
export const resetPassword = createAction('[Reset password] Navigate to reset password page');
export const verifyLoginSubmit = createAction('[Verify login] Submit', props<{ code: string }>());
export const loginSubmit = createAction('[Login] Submit', props<{ username: string; password: string }>());
export const forgotPasswordSubmit = createAction('[Forgot password] Submit', props<{ username: string }>());
export const verifyForgotPasswordSubmit = createAction('[Verify forgot password] Submit', props<{ code: string }>());
export const resetPasswordSubmit = createAction('[Reset password] Submit', props<{ password: string; repeatedPassword: string }>());

export const changePasswordSubmit = createAction(
	'[Change password] Submit',
	props<{ oldPassword: string; newPassword: string; confirmationPassword: string }>()
);

export const personalDataSubmit = createAction(
	'[Personal data] Submit',
	props<{ firstName: string; lastName: string; email: string; mobileNumber: string; username: string; languageCode: string }>()
);

export const refreshToken = createAction('[Refresh token] Refresh token');
