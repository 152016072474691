import { createReducer, on } from '@ngrx/store';
import { ADMINISTRATION_STATE, AdministrationState } from './administration.state';
import { AdministrationApiAction } from './action';

export const administrationReducer = createReducer<AdministrationState>(
	ADMINISTRATION_STATE,
	on(AdministrationApiAction.filterCompaniesSuccess, (state, action): AdministrationState => {
		return {
			...state,
			companies: action.companies,
		};
	})
);
