import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DatatableColumn, TableData } from 'nc-datatable';
import { TableColumns } from '../../../model/table-columns';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { DashboardPageAction, DashboardSelect } from '../../../state/action';
import { BaseComponent, Option } from 'nc-shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { SettingsPageAction, SettingsSelect } from '../../../../state/settings/action';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserSelect } from '../../../../user/state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	selector: 'ea-cm-dashboard',
	templateUrl: './cm-dashboard.component.html',
	styleUrls: ['cm-dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmDashboardComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	isMobile: boolean;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;

	tasksColumns: DatatableColumn[] = TableColumns.TASKS_COLUMNS;
	milestonesColumns: DatatableColumn[] = TableColumns.MILESTONES_COLUMNS;
	activeAbsencesColumns: DatatableColumn[] = TableColumns.ACTIVE_ABSENCES_COLUMNS;

	numberOfDays$: Observable<Option[]>;
	responsibleUsers$: Observable<Option[]>;

	tasks$: Observable<TableData>;
	milestones$: Observable<TableData>;
	activeAbsences$: Observable<TableData>;
	filterConfigMilestoneTable: FilterConfig;
	filterConfigTasksTable: FilterConfig;
	filterConfigAbsenceTable: FilterConfig;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	MILESTONE_FILTER = 'milestoneFilter';
	TASK_FILTER = 'tasksFilter';
	ACTIVE_CASES_FILTER = 'activeCasesFilter';
	GLOBAL_FILTER = 'globalFilter';
	SELECTED_USER = 'selectedUser';
	SELECTED_NUMBER_OF_DAYS = 'selectedNumberOfDays';
	SORT_BY_COLUMN_MILESTONE = 'sortByColumnMilestone';
	ORDER_MILESTONE = 'orderMilestone';
	SORT_BY_COLUMN_TASKS = 'sortByColumnTasks';
	ORDER_TASKS = 'orderTasks';
	SORT_BY_COLUMN_ABSENCE = 'sortByColumnAbsence';
	ORDER_ABSENCE = 'orderAbsence';

	constructor(private store: Store, formBuilder: FormBuilder, breakpointObserver: BreakpointObserver) {
		super();
		this.loggedUserSubscription$ = store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});

		this.tasks$ = store.select(DashboardSelect.getTasks);
		this.milestones$ = store.select(DashboardSelect.getMilestones);
		this.activeAbsences$ = store.select(DashboardSelect.getActiveAbsences);
		this.numberOfDays$ = store.select(DashboardSelect.getNumberOfDays);
		this.responsibleUsers$ = store.select(DashboardSelect.getResponsibleUsers);

		this.formGroup = formBuilder.group({
			responsibleUser: [''],
			numberOfDays: [[]],
		});
		this.globalFormGroup = formBuilder.group({
			value: [''],
		});

		this.store.dispatch(DashboardPageAction.openActiveDashboard());
		breakpointObserver.observe(['(max-width: 900px)']).subscribe((result) => (this.isMobile = result.matches));
	}

	ngOnInit(): void {
		this.store.select(SettingsSelect.getDashboardUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});
		this.filterConfigMilestoneTable = {
			showFilter: false,
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN_MILESTONE],
			sortByAscOrDesc: this.userPreferences[this.ORDER_MILESTONE],
			filterValues: {
				globalFilter: this.userPreferences[this.MILESTONE_FILTER],
			},
		};
		this.filterConfigTasksTable = {
			showFilter: false,
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN_TASKS],
			sortByAscOrDesc: this.userPreferences[this.ORDER_TASKS],
			filterValues: {
				globalFilter: this.userPreferences[this.TASK_FILTER],
			},
		};
		this.filterConfigAbsenceTable = {
			showFilter: false,
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN_ABSENCE],
			sortByAscOrDesc: this.userPreferences[this.ORDER_ABSENCE],
			filterValues: {
				globalFilter: this.userPreferences[this.ACTIVE_CASES_FILTER],
			},
		};

		const responsibleUserControl = this.formGroup.get('responsibleUser');
		const numberOfDaysControl = this.formGroup.get('numberOfDays');

		responsibleUserControl.patchValue(Number.parseInt(this.userPreferences[this.SELECTED_USER], 10));
		numberOfDaysControl.patchValue(this.userPreferences[this.SELECTED_NUMBER_OF_DAYS]);

		this.store.dispatch(DashboardPageAction.getTasks({ selectedUser: responsibleUserControl.value }));
		this.store.dispatch(DashboardPageAction.getActiveAbsences({ selectedUser: responsibleUserControl.value }));
		this.store.dispatch(
			DashboardPageAction.getMilestones({
				numberOfDays: numberOfDaysControl.value,
				selectedUser: responsibleUserControl.value,
			})
		);

		responsibleUserControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			if (value) {
				this.store.dispatch(
					DashboardPageAction.saveSelectedUser({
						selectedUser: value.toString(),
						loggedUser: this.loggedUser,
					})
				);
				this.store.dispatch(DashboardPageAction.getTasks({ selectedUser: value.toString() }));
				this.store.dispatch(DashboardPageAction.getActiveAbsences({ selectedUser: value.toString() }));
				this.store.dispatch(
					DashboardPageAction.getMilestones({
						numberOfDays: numberOfDaysControl.value,
						selectedUser: value.toString(),
					})
				);
			}
		});

		numberOfDaysControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
			this.store.dispatch(
				SettingsPageAction.setSelectedNumberOfDays({
					selectedNumberOfDays: values,
					loggedUser: this.loggedUser,
				})
			);
			this.store.dispatch(
				DashboardPageAction.getMilestones({
					numberOfDays: values,
					selectedUser: responsibleUserControl.value,
				})
			);
		});
	}

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			milestoneFilter: this.filterConfigMilestoneTable.globalFilterFormGroup.get(this.GLOBAL_FILTER).value,
			tasksFilter: this.filterConfigTasksTable.globalFilterFormGroup.get(this.GLOBAL_FILTER).value,
			activeCasesFilter: this.filterConfigAbsenceTable.globalFilterFormGroup.get(this.GLOBAL_FILTER).value,
			sortByColumnMilestone: this.filterConfigMilestoneTable.sortTableByColumn,
			orderMilestone: this.filterConfigMilestoneTable.sortByAscOrDesc,
			sortByColumnTasks: this.filterConfigTasksTable.sortTableByColumn,
			orderTasks: this.filterConfigTasksTable.sortByAscOrDesc,
			sortByColumnAbsence: this.filterConfigAbsenceTable.sortTableByColumn,
			orderAbsence: this.filterConfigAbsenceTable.sortByAscOrDesc,
			selectedCompanies: [],
		};
		this.store.dispatch(SettingsPageAction.setDashboardTableFilters({ dashboardFilters: filterObject, loggedUser: this.loggedUser }));
	}

	onAbsenceRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${rowData.code}` }));
	};

	onMilestoneRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${rowData.code}/open-milestone/${rowData.milestoneSettingsId}` }));
	};

	onTaskRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${rowData.code}/tasks/${rowData.id}` }));
	};
}
