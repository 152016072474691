import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnumUtils, FormUtils } from 'nc-utils';
import { Observable, throwError } from 'rxjs';
import { Option } from 'nc-shared';
import { AttachmentsContentTypeEnumeration } from '../../../model/enums/attachments-content-type.enum';
import { AppConstants } from '../../../../app.constants';

@Component({
	templateUrl: './attachments-form-dialog.component.html',
})
export class AttachmentsFormDialogComponent implements OnInit {
	documentType$: Observable<Option[]>;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { formGroup: FormGroup },
		private dialogRef: MatDialogRef<any>
	) {}

	ngOnInit() {
		this.documentType$ = EnumUtils.toObservableItems(AttachmentsContentTypeEnumeration);
	}

	submit = () => {
		if (this.data.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.data.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		return this.dialogRef.close({ success: true });
	};
}
