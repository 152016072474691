import { Injectable } from '@angular/core';
import { DialogMessage } from '../../model/dialog-message';

@Injectable({
	providedIn: 'root',
})
export class WorkplaceMessageRequestFactory {
	/**
	 *
	 * @param workplaceData
	 * @param dialogMessage
	 */
	create(workplaceData: any, dialogMessage: DialogMessage): any {
		return {
			...workplaceData,
			workplaceMessage: {
				firstName: {
					...dialogMessage.workplaceMessage.firstName,
					answerValue: workplaceData.firstName,
				},
				lastName: {
					...dialogMessage.workplaceMessage.lastName,
					answerValue: workplaceData.lastName,
				},
				phone: {
					...dialogMessage.workplaceMessage.phone,
					answerValue: workplaceData.phone,
				},
				function: {
					...dialogMessage.workplaceMessage.function,
					answerValue: workplaceData.function,
				},
				email: {
					...dialogMessage.workplaceMessage.email,
					answerValue: workplaceData.email,
				},
				professionalTraining: {
					...dialogMessage.workplaceMessage.professionalTraining,
					answerValue: workplaceData.professionalTraining,
				},
				mainActivity: {
					...dialogMessage.workplaceMessage.mainActivity,
					answerValue: workplaceData.mainActivity,
				},
				workingHours: {
					...dialogMessage.workplaceMessage.workingHours,
					answerValue: workplaceData.workingHours,
				},
				employmentLevel: {
					...dialogMessage.workplaceMessage.employmentLevel,
					answerValue: workplaceData.employmentLevel,
				},
				fullWorkingHours: {
					...dialogMessage.workplaceMessage.fullWorkingHours,
					answerValue: workplaceData.fullWorkingHours,
				},
				normalFullWorkingHours: {
					...dialogMessage.workplaceMessage.normalFullWorkingHours,
					answerValue: workplaceData.normalFullWorkingHours,
				},
				comment: {
					...dialogMessage.workplaceMessage.comment,
					answerValue: workplaceData.comment,
				},
				seatedWorkplace: {
					...dialogMessage.workplaceMessage.seatedWorkplace,
					answerValue: workplaceData.seatedWorkplace,
				},
				standingWorkstation: {
					...dialogMessage.workplaceMessage.standingWorkstation,
					answerValue: workplaceData.standingWorkstation,
				},
				sitStandWorkstation: {
					...dialogMessage.workplaceMessage.sitStandWorkstation,
					answerValue: workplaceData.sitStandWorkstation,
				},
				computerWorkstation: {
					...dialogMessage.workplaceMessage.computerWorkstation,
					answerValue: workplaceData.computerWorkstation,
				},
				visualControlActivity: {
					...dialogMessage.workplaceMessage.visualControlActivity,
					answerValue: workplaceData.visualControlActivity,
				},
				customerContact: {
					...dialogMessage.workplaceMessage.customerContact,
					answerValue: workplaceData.customerContact,
				},
				teamwork: {
					...dialogMessage.workplaceMessage.teamwork,
					answerValue: workplaceData.teamwork,
				},
				noise: {
					...dialogMessage.workplaceMessage.noise,
					answerValue: workplaceData.noise,
				},
				heatColdVapors: {
					...dialogMessage.workplaceMessage.heatColdVapors,
					answerValue: workplaceData.heatColdVapors,
				},
				smell: {
					...dialogMessage.workplaceMessage.smell,
					answerValue: workplaceData.smell,
				},
				dustExposure: {
					...dialogMessage.workplaceMessage.dustExposure,
					answerValue: workplaceData.dustExposure,
				},
				frequentPostureInTwistedPosition: {
					...dialogMessage.workplaceMessage.frequentPostureInTwistedPosition,
					answerValue: workplaceData.frequentPostureInTwistedPosition,
				},
				preciseUseOfHands: {
					...dialogMessage.workplaceMessage.preciseUseOfHands,
					answerValue: workplaceData.preciseUseOfHands,
				},
				increasedRiskOfAccidents: {
					...dialogMessage.workplaceMessage.increasedRiskOfAccidents,
					answerValue: workplaceData.increasedRiskOfAccidents,
				},
				liftLessThen5kg: {
					...dialogMessage.workplaceMessage.liftLessThen5kg,
					answerValue: workplaceData.liftLessThen5kg,
				},
				liftMoreThen5kg: {
					...dialogMessage.workplaceMessage.liftMoreThen5kg,
					answerValue: workplaceData.liftMoreThen5kg,
				},
				liftMoreThen10kg: {
					...dialogMessage.workplaceMessage.liftMoreThen10kg,
					answerValue: workplaceData.liftMoreThen10kg,
				},
				liftMoreThen15kg: {
					...dialogMessage.workplaceMessage.liftMoreThen15kg,
					answerValue: workplaceData.liftMoreThen15kg,
				},
				otherSpecialRequirement: {
					...dialogMessage.workplaceMessage.otherSpecialRequirement,
					answerValue: workplaceData.otherSpecialRequirement,
				},
				dailyOperationFixed: {
					...dialogMessage.workplaceMessage.dailyOperationFixed,
					answerValue: workplaceData.dailyOperationFixed,
				},
				daytimeOperationGliding: {
					...dialogMessage.workplaceMessage.daytimeOperationGliding,
					answerValue: workplaceData.daytimeOperationGliding,
				},
				nightShiftsNecessary: {
					...dialogMessage.workplaceMessage.nightShiftsNecessary,
					answerValue: workplaceData.nightShiftsNecessary,
				},
				exclusivelyNightShifts: {
					...dialogMessage.workplaceMessage.exclusivelyNightShifts,
					answerValue: workplaceData.exclusivelyNightShifts,
				},
				otherSpecialWorkingHours: {
					...dialogMessage.workplaceMessage.otherSpecialWorkingHours,
					answerValue: workplaceData.otherSpecialWorkingHours,
				},
				available: {
					...dialogMessage.workplaceMessage.available,
					answerValue: workplaceData.available,
				},
				onlyTemporaryPossible: {
					...dialogMessage.workplaceMessage.onlyTemporaryPossible,
					answerValue: workplaceData.onlyTemporaryPossible,
				},
				notAvailable: {
					...dialogMessage.workplaceMessage.notAvailable,
					answerValue: workplaceData.notAvailable,
				},
				descriptionOfThePossibleGentleWorkstation: {
					...dialogMessage.workplaceMessage.descriptionOfThePossibleGentleWorkstation,
					answerValue: workplaceData.descriptionOfThePossibleGentleWorkstation,
				},
				desiredByTheDoctor: {
					...dialogMessage.workplaceMessage.desiredByTheDoctor,
					answerValue: workplaceData.desiredByTheDoctor,
				},
				withInsuranceTakesPlaceOn: {
					...dialogMessage.workplaceMessage.withInsuranceTakesPlaceOn,
					answerValue: workplaceData.withInsuranceTakesPlaceOn,
				},
				sendPictureDate: {
					...dialogMessage.workplaceMessage.sendPictureDate,
					answerValue: workplaceData.sendPictureDate,
				},
				comments: {
					...dialogMessage.workplaceMessage.comments,
					answerValue: workplaceData.comments,
				},
			},
		};
	}
}
