import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AbsenceApiAction, AbsencePageAction, AbsenceSelect } from './action';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AbsenceService } from '../service/absence.service';
import { ErrorAction } from '../../state/error/action';
import { SettingsPageAction } from '../../state/settings/action';
import { DialogConfigurationUtils, DialogService, DialogType, StringsUtils } from 'nc-utils';
import { FetchDocumentDialogComponent } from '../component/dialog/fetch-document-dialog/fetch-document-dialog.component';
import { GenerateDocumentDialogComponent } from '../component/dialog/generate-document-dialog/generate-document-dialog.component';
import { AbsenceConfirmationDialogComponent } from '../component/dialog/absence-confirmation-dialog/absence-confirmation-dialog.component';
import { AbsenceArchiveDialogComponent } from '../component/dialog/absence-archive-dialog/absence-archive-dialog.component';
import { AbsenceActivateDialogComponent } from '../component/dialog/absence-activate-dialog/absence-activate-dialog.component';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';
import { UnmergeAbsenceConfirmationDialogComponent } from '../component/dialog/unmerge-absence-confirmation-dialog/unmerge-absence-confirmation-dialog.component';
import { KleValidationDialogComponent } from '../../shared/component/kle-validation-dialog/kle-validation-dialog.component';
import { DocumentWarningDialogComponent } from '../component/absence/document-warning-dialog/document-warning-dialog.component';
import { TypedAction } from '@ngrx/store/src/models';
import { KleResultDialogComponent } from '../../kle/component/kle-result-dialog/kle-result-dialog.component';
import { KleResultTypeEnum } from '../../kle/model/enums/kle-result-type.enum';
import { AbsenceExistingDialogComponent } from '../component/dialog/absence-existing-dialog/absence-existing-dialog.component';
import { EMPTY, of } from 'rxjs';
import { AbsenceTypeEnumeration } from '../../shared/enumeration/absence-type.enumeration';
import { AbsenceWarningEnumeration } from '../../shared/enumeration/absence-warning.enumeration';

@Injectable()
export class AbsenceEffect {
	constructor(private actions$: Actions, private store: Store, private absenceService: AbsenceService, private dialogService: DialogService) {}

	fetchEmployeeEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.fetchEmployee),
			switchMap((action) => this.absenceService.getEmployee(action.id)),
			map((result) => AbsenceApiAction.fetchEmployeeSuccess({ employee: result }))
		);
	});

	fetchEmployeesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.fetchEmployees),
			switchMap((action) => this.absenceService.searchEmployees(action.companyId)),
			map((result) => AbsenceApiAction.fetchEmployeesSuccess({ employees: result }))
		);
	});

	fetchCompanyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.fetchCompany),
			switchMap((action) => this.absenceService.getCompany(action.id)),
			map((result) => AbsenceApiAction.fetchCompanySuccess({ companyResponse: result }))
		);
	});

	initializeSelectsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.initializeSelects),
			switchMap(() => this.absenceService.getResponsibleLDUsers()),
			map((responsibleLDUsers) => AbsenceApiAction.initializeSelectsSuccess({ responsibleLDUsers }))
		);
	});

	submitNewEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.submitNew),
			switchMap((action) => {
				const absence = action.absence;
				const isBranchOrHqHr = action.isBranchOrHqHr;

				return this.absenceService
					.submit(absence)
					.pipe(
						map((response) =>
							response.success
								? AbsenceApiAction.submitNewSuccess({ code: response.data.code })
								: AbsenceApiAction.submitNewFailed({ response: response, absence: absence, isBranchOrHqHr: isBranchOrHqHr })
						)
					);
			})
		);
	});

	submitNewSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.submitNewSuccess),
			switchMap((action) => {
				this.dialogService.close(DialogType.PROCESSING);
				const dialogConfig = {
					disableClose: true,
					data: {
						code: action.code,
						title: 'newCaseSuccessTitle',
						message: 'newCaseSuccessMessage',
					},
				};
				const dialogRef = this.dialogService.open(AbsenceConfirmationDialogComponent, dialogConfig);
				return dialogRef.afterClosed();
			}),
			map((result) => {
				return result
					? SettingsPageAction.navigate({ url: `/absence/${result}` })
					: SettingsPageAction.openMenuItem({ menuItem: '/dashboard' });
			})
		);
	});

	submitNewFailedEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.submitNewFailed),
			map((action) => {
				const errors = action.response.errors;

				return errors.warning?.code === AbsenceWarningEnumeration.ALREADY_ACTIVE_TYPE_WARNING &&
					action.absence.absenceType === AbsenceTypeEnumeration.ACCIDENT.code &&
					action.isBranchOrHqHr
					? AbsencePageAction.openAbsenceExistingDialog(action)
					: ErrorAction.submitFailed({ errors: action.response.errors });
			})
		);
	});

	openAbsenceExistingDialogEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.openAbsenceExistingDialog),
			switchMap((action) => {
				const dialogConfig = { disableClose: true };
				const dialogRef = this.dialogService.open(AbsenceExistingDialogComponent, dialogConfig);
				return dialogRef.afterClosed().pipe(
					tap((result) => {
						if (!result) {
							this.dialogService.close(DialogType.PROCESSING);
						}
					}),
					switchMap((result) => {
						// Only proceed with submitting again if the result is truthy.
						return result ? of(AbsenceApiAction.submitNewAgain({ absence: action.absence })) : EMPTY;
					})
				);
			})
		);
	});

	submitNewAgainEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.submitNewAgain),
			switchMap((action) => {
				return this.absenceService
					.submit(action.absence, true)
					.pipe(
						map((response) =>
							response.success
								? AbsenceApiAction.submitNewSuccess({ code: response.data.code })
								: ErrorAction.submitFailed({ errors: response.errors })
						)
					);
			})
		);
	});

	submitExistingEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.submitExisting),
			switchMap((action) => this.absenceService.submit(action.absence)),
			map((response) =>
				response.success
					? AbsenceApiAction.submitExistingSuccess({ code: response.data.code })
					: ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	deleteDraftEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.deleteDraft),
			switchMap((action) => this.absenceService.deleteDraft(action.code)),
			map((response) => {
				if (response.success) {
					return AbsenceApiAction.deleteDraftSuccess({ code: response.value });
				} else {
					return ErrorAction.submitFailed({ errors: response.errors });
				}
			})
		);
	});

	deleteDraftSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.deleteDraftSuccess),
			switchMap((action) => {
				this.dialogService.close(DialogType.PROCESSING);
				const dialogConfig = { disableClose: true, data: { code: action.code, title: 'info', message: 'confirmationDeleteDraft' } };
				const dialogRef = this.dialogService.open(AbsenceConfirmationDialogComponent, dialogConfig);
				return dialogRef.afterClosed();
			}),
			map(() => {
				return SettingsPageAction.navigate({ url: '/dashboard' });
			})
		);
	});

	updateAbsenceSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.submitExistingSuccess),
			map((action) => {
				this.dialogService.close(DialogType.PROCESSING);
				const dialogConfig = DialogConfigurationUtils.info('caseUpdated', 'info');
				this.dialogService.open(NcInformationDialogComponent, dialogConfig);
				this.store.dispatch(AbsencePageAction.absenceSavedSuccessfully({ absenceSavedSuccessfully: true }));
				return AbsencePageAction.fetchAbsence({ code: action.code });
			})
		);
	});

	rejectAbsenceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.reject),
			switchMap((action) => this.absenceService.submit(action.absence)),
			map((response) => (response.success ? AbsenceApiAction.rejectSuccess() : ErrorAction.submitFailed({ errors: response.errors })))
		);
	});

	rejectSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.rejectSuccess),
			switchMap(() => {
				this.dialogService.close(DialogType.PROCESSING);
				const dialogConfig = DialogConfigurationUtils.info('caseUpdated', 'info');
				const dialogRef = this.dialogService.open(NcInformationDialogComponent, dialogConfig);
				return dialogRef.afterClosed();
			}),
			map(() => {
				return SettingsPageAction.navigate({ url: '/dashboard' });
			})
		);
	});

	fetchAbsenceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.fetchAbsence),
			switchMap((action) => this.absenceService.getAbsence(action.code)),
			map((result) => AbsenceApiAction.fetchAbsenceSuccess({ response: result }))
		);
	});

	mobiliarDataSheetEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.mobiliarDataSheet),
			switchMap((action) => this.absenceService.mobiliarDataSheet(action.code)),
			map((response) =>
				response.success
					? AbsenceApiAction.mobiliarDataSheetSuccess()
					: ErrorAction.submitFailed({ errors: { message: { code: response.message, parameters: null } } })
			)
		);
	});

	openFetchDocumentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.openFetchDocumentDialog),
			switchMap(() => this.dialogService.open(FetchDocumentDialogComponent, { disableClose: true }).afterClosed()),
			map((result) =>
				result.success ? AbsencePageAction.fetchDocument({ documentType: result.data.documentType }) : SettingsPageAction.closeDialog()
			)
		);
	});

	fetchDocumentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.fetchDocument),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			switchMap(([action, code]) => this.absenceService.fetchDocuments(code, action.documentType)),
			switchMap((result) => {
				this.dialogService.close(DialogType.PROCESSING);
				const firstResult = result.results[0];
				const message = StringsUtils.isEmpty(firstResult.message) ? 'successDocumentFetch' : firstResult.message;
				const dialogConfig = firstResult.success
					? DialogConfigurationUtils.info(message, 'info')
					: DialogConfigurationUtils.error(firstResult.message);

				return this.dialogService.open(NcInformationDialogComponent, dialogConfig).afterClosed();
			}),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([result, code]) => AbsencePageAction.fetchAbsence({ code }))
		);
	});

	openGenerateDocumentDialogEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.openGenerateDocumentDialog),
			switchMap(() => this.dialogService.open(GenerateDocumentDialogComponent, { disableClose: true }).afterClosed()),
			map((result) =>
				result.success
					? AbsencePageAction.generateDocument({
							documentType: result.data.documentType,
							language: result.data.language,
							reportDate: result.data.reportDate,
					  })
					: SettingsPageAction.closeDialog()
			)
		);
	});

	openDocumentWarningDialogEffect$ = createEffect(
		() => {
			let actionToDispatch: TypedAction<string>;

			return this.actions$.pipe(
				ofType(AbsencePageAction.openDocumentWarningDialog),
				switchMap((action) => {
					actionToDispatch = action.actionToDispatch;
					return this.dialogService.open(DocumentWarningDialogComponent, { disableClose: true }).afterClosed();
				}),
				map((result) => {
					if (result) {
						this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
						this.store.dispatch(actionToDispatch);
					}
				})
			);
		},
		{ dispatch: false }
	);

	generateDocumentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.generateDocument),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			switchMap(([action, code]) => this.absenceService.generateDocument(code, action.documentType, action.language, action.reportDate)),
			switchMap((result) => {
				this.dialogService.close(DialogType.PROCESSING);
				const dialogConfig = result.success
					? DialogConfigurationUtils.info('successDocumentGeneration', 'info')
					: DialogConfigurationUtils.error(result.message);

				return this.dialogService.open(NcInformationDialogComponent, dialogConfig).afterClosed();
			}),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([result, code]) => AbsencePageAction.fetchAbsence({ code }))
		);
	});

	historyEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.history),
			map((action) => SettingsPageAction.navigate({ url: `/absence/${action.code}/history` }))
		);
	});

	tasksEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.tasks),
			map((action) => SettingsPageAction.navigate({ url: `/absence/${action.code}/tasks` }))
		);
	});

	canArchiveEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.canArchive),
			switchMap((action) => this.absenceService.canArchive(action.id)),
			map((response) => {
				return response.success
					? AbsenceApiAction.canArchiveSuccess()
					: ErrorAction.submitFailed({ errors: { message: { code: response.message, parameters: null } } });
			})
		);
	});

	canArchiveSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.canArchiveSuccess),
			switchMap(() => {
				return this.dialogService.open(AbsenceArchiveDialogComponent, { disableClose: true }).afterClosed();
			}),
			withLatestFrom(this.store.select(AbsenceSelect.getId)),
			map(([result, id]) =>
				result.success
					? AbsencePageAction.archive({ id, comment: result.data.comment, reason: result.data.reason })
					: SettingsPageAction.closeDialog()
			)
		);
	});

	archiveEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.archive),
			switchMap((action) => this.absenceService.archive(action.id, action.comment, action.reason)),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([response, code]) => {
				this.dialogService.close(DialogType.PROCESSING);
				return response.success ? AbsencePageAction.fetchAbsence({ code }) : ErrorAction.submitFailed({ errors: response.errors });
			})
		);
	});

	shouldActivateEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.shouldActivate),
			switchMap(() => this.dialogService.open(AbsenceActivateDialogComponent, { disableClose: true }).afterClosed()),
			map((result) => (result ? AbsencePageAction.activate() : SettingsPageAction.closeDialog()))
		);
	});

	activateEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.activate),
			withLatestFrom(this.store.select(AbsenceSelect.getId)),
			switchMap(([action, id]) => {
				return this.absenceService.activate(id);
			}),
			map((response) => {
				return response.success ? AbsenceApiAction.activateSuccess() : ErrorAction.submitFailed({ errors: response.errors });
			})
		);
	});

	activateSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.activateSuccess),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([action, code]) => {
				this.dialogService.close(DialogType.PROCESSING);
				return AbsencePageAction.fetchAbsence({ code });
			})
		);
	});

	getHistoryEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.getHistory),
			switchMap((action) => this.absenceService.getHistory(action.code)),
			map((result) => AbsenceApiAction.getHistorySuccess({ records: result }))
		);
	});

	sendConfirmationEmailEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.resendConfirmationEmail),
			switchMap((action) => {
				this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
				return this.absenceService.resendConfirmationEmail(action.code);
			}),
			map((response) => {
				return response.success
					? AbsenceApiAction.resendConfirmationEmailSuccess()
					: ErrorAction.submitFailed({ errors: { message: { code: response.message, parameters: null } } });
			})
		);
	});

	resendConfirmationEmailSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AbsenceApiAction.resendConfirmationEmailSuccess),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					const config = DialogConfigurationUtils.info('confirmationEmailSent', 'info');
					this.dialogService.open(NcInformationDialogComponent, config);
				})
			);
		},
		{ dispatch: false }
	);

	resendAnnexConfirmationEmailEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.resendAnnexConfirmationEmail),
			switchMap((action) => {
				this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
				return this.absenceService.resendAnnexConfirmationEmail(action.code);
			}),
			map((response) => {
				return response.success
					? AbsenceApiAction.resendAnnexConfirmationEmailSuccess()
					: ErrorAction.submitFailed({ errors: { message: { code: response.message, parameters: null } } });
			})
		);
	});

	resendAnnexConfirmationEmailSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AbsenceApiAction.resendAnnexConfirmationEmailSuccess),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					const config = DialogConfigurationUtils.info('confirmationEmailSent', 'info');
					this.dialogService.open(NcInformationDialogComponent, config);
				})
			);
		},
		{ dispatch: false }
	);

	reassignAbsenceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.reassignAbsence),
			map((action) => SettingsPageAction.navigate({ url: `/absence/${action.code}/reassign` }))
		);
	});

	getReassignAbsenceEmployeesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.getReassignAbsenceEmployees),
			switchMap((action) => this.absenceService.getReassignAbsenceEmployees(action.code, action.showAll)),
			map((result) => AbsenceApiAction.getReassignAbsenceEmployeesSuccess({ records: result }))
		);
	});

	reassignAbsenceSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.reassignAbsenceSubmit),
			switchMap((action) => this.absenceService.reassignAbsenceSubmit(action.code, action.employeeId)),
			map((response) =>
				response.success ? AbsenceApiAction.reassignAbsenceSubmitSuccess() : ErrorAction.submitFailed({ message: response.message })
			)
		);
	});

	reassignAbsenceSubmitSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.reassignAbsenceSubmitSuccess),
			switchMap(() => {
				this.dialogService.close(DialogType.PROCESSING);
				const dialogConfig = DialogConfigurationUtils.info('absenceSuccessfullyReassigned', 'save');
				return this.dialogService.open(NcInformationDialogComponent, dialogConfig).afterClosed();
			}),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([dialogResult, code]) => SettingsPageAction.navigate({ url: `/absence/${code}` }))
		);
	});

	mergeAbsenceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.mergeAbsence),
			switchMap((action) => this.absenceService.mergeAbsence(action.mergeAbsenceRequest)),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([response, code]) => {
				let action;

				if (response.success) {
					this.dialogService.close(DialogType.PROCESSING);
					this.dialogService.close('MergeAbsenceDialog');
					action = AbsencePageAction.fetchAbsence({ code });
				} else {
					action = ErrorAction.submitFailed({ errors: { message: { code: response.message, parameters: null } } });
				}

				return action;
			})
		);
	});

	openUnmergeConfirmationDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AbsencePageAction.openUnmergeConfirmationDialog),
				switchMap((action) => {
					const dialogConfig = { disableClose: true, data: { code: action.code } };
					return this.dialogService.open(UnmergeAbsenceConfirmationDialogComponent, dialogConfig).afterClosed();
				}),
				tap((dialogResult) => {
					if (dialogResult.success) {
						this.store.dispatch(AbsencePageAction.unmergeAbsence({ code: dialogResult.code }));
					}
				})
			);
		},
		{ dispatch: false }
	);

	unmergeAbsenceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.unmergeAbsence),
			switchMap((action) => this.absenceService.unmergeAbsence(action.code)),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([response, code]) => {
				return response.success ? AbsencePageAction.fetchAbsence({ code }) : ErrorAction.submitFailed({ errors: response.errors });
			})
		);
	});

	getContextAbsenceModelEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.getCurrentAbsence),
			switchMap((action) => this.absenceService.getCurrentAbsence(action.code)),
			map((result) => AbsenceApiAction.getCurrentAbsenceSuccess({ currentAbsence: result }))
		);
	});

	getAbsenceAnnexEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.getAbsenceAnnex),
			switchMap((action) => this.absenceService.getAbsenceAnnex(action.identifier, action.isShareInformation)),
			map((result) => AbsenceApiAction.getAbsenceAnnexSuccess({ absenceAnnexResponse: result }))
		);
	});

	saveAbsenceAnnexEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.saveAbsenceAnnex),
			switchMap((action) => this.absenceService.saveAbsenceAnnex(action.data, action.isShareInformation)),
			map((response) => {
				return response.success ? AbsenceApiAction.saveAbsenceAnnexSuccess() : ErrorAction.submitFailed({ errors: response.errors });
			})
		);
	});

	saveAbsenceAnnexSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.saveAbsenceAnnexSuccess),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([response, code]) => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.navigate({ url: `/absence/${code}` });
			})
		);
	});

	declareIncidentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.declareIncident),
			switchMap((action) =>
				this.absenceService.declareIncident(action.absenceCode, action.sendWithoutContract, action.incident, action.relapse)
			),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return response.success && response.value.success
					? AbsenceApiAction.declareIncidentSuccess({ response: response.value })
					: AbsenceApiAction.declareIncidentFail({ response });
			})
		);
	});

	declareIncidentSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.declareIncidentSuccess),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([action, code]) => {
				const dialogConfig = {
					data: {
						...action.response,
						id: action.response.insuranceCaseId,
						resultType: KleResultTypeEnum.DECLARE_INCIDENT,
					},
				};
				this.dialogService.open(KleResultDialogComponent, dialogConfig);
				this.store.dispatch(AbsencePageAction.fetchAbsence({ code: code }));
				return AbsencePageAction.getIncidentContext({ absenceCode: code });
			})
		);
	});

	getIncidentContextEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.getIncidentContext),
			switchMap((action) => this.absenceService.getIncidentContext(action.absenceCode)),
			map((response) => AbsenceApiAction.getIncidentContextSuccess({ response }))
		);
	});

	declareIncidentFailEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AbsenceApiAction.declareIncidentFail),
				tap((action) => {
					if (action.response.value?.errors) {
						let dialogConfig = { data: action.response.value };
						this.dialogService.open(KleResultDialogComponent, dialogConfig);
					} else {
						action.response.value?.message != null
							? this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(action.response.value.message))
							: this.dialogService.open(KleValidationDialogComponent, { data: action.response.errors });
					}
				})
			);
		},
		{ dispatch: false }
	);

	saveAccidentDescriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.saveAccidentDescription),
			switchMap((action) => this.absenceService.saveAccidentDescription(action.data)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return response.success ? AbsenceApiAction.saveAccidentDescriptionSuccess() : ErrorAction.setErrors({ errors: response.errors });
			})
		);
	});

	saveAccidentDescriptionSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.saveAccidentDescriptionSuccess),
			switchMap(() =>
				this.dialogService
					.open(NcInformationDialogComponent, DialogConfigurationUtils.info('kle.information.success', 'success'))
					.afterClosed()
			),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([result, code]) => SettingsPageAction.navigate({ url: `/absence/${code}` }))
		);
	});

	saveTreatmentsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsencePageAction.saveTreatments),
			switchMap((action) => this.absenceService.saveTreatments(action.data)),
			map((response) => {
				this.dialogService.close(DialogType.PROCESSING);
				return response.success ? AbsenceApiAction.saveTreatmentsSuccess() : ErrorAction.setErrors({ errors: response.errors });
			})
		);
	});

	saveTreatmentsSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AbsenceApiAction.saveTreatmentsSuccess),
			switchMap(() =>
				this.dialogService
					.open(NcInformationDialogComponent, DialogConfigurationUtils.info('kle.information.success', 'success'))
					.afterClosed()
			),
			withLatestFrom(this.store.select(AbsenceSelect.getCode)),
			map(([result, code]) => SettingsPageAction.navigate({ url: `/absence/${code}` }))
		);
	});
}
