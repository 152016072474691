import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KlePageAction, KleSelect } from '../../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { DialogMessageService } from '../../../service/dialog-message/dialog-message.service';
import { DialogMessage } from '../../../model/dialog-message';
import { WorkplaceMessageRequestFactory } from '../../../service/dialog-message/workplace-message-request.factory';
import { DialogMessageTypeEnum } from '../../../model/enums/dialog-message-type.enum';
import { DialogService } from 'nc-utils';

@Component({
	templateUrl: './workplace-message-form.component.html',
	styleUrls: ['./workplace-message-form.component.scss'],
})
export class WorkplaceMessageFormComponent extends BaseComponent implements OnInit {
	readOnly = false;
	dialogMessage: DialogMessage;

	workplaceMessageGroup = this.formBuilder.group({
		title: '',
		message: '',
		creator: '',
		description: '',
		dialogMessageType: DialogMessageTypeEnum.WORKPLACE_DESCRIPTION.code,
		companyCaseId: this.data.absenceCode,
		previous: this.data.storyId,
		firstName: '',
		lastName: '',
		phone: '',
		function: '',
		email: '',
		professionalTraining: '',
		mainActivity: '',
		workingHours: '',
		employmentLevel: '',
		fullWorkingHours: '',
		normalFullWorkingHours: '',
		comment: '',
		seatedWorkplace: '',
		standingWorkstation: '',
		sitStandWorkstation: '',
		computerWorkstation: '',
		visualControlActivity: '',
		customerContact: '',
		teamwork: '',
		noise: '',
		heatColdVapors: '',
		smell: '',
		dustExposure: '',
		frequentPostureInTwistedPosition: '',
		preciseUseOfHands: '',
		increasedRiskOfAccidents: '',
		liftLessThen5kg: '',
		liftMoreThen5kg: '',
		liftMoreThen10kg: '',
		liftMoreThen15kg: '',
		otherSpecialRequirement: '',
		dailyOperationFixed: '',
		daytimeOperationGliding: '',
		nightShiftsNecessary: '',
		exclusivelyNightShifts: '',
		otherSpecialWorkingHours: '',
		available: '',
		onlyTemporaryPossible: '',
		notAvailable: '',
		descriptionOfThePossibleGentleWorkstation: '',
		desiredByTheDoctor: '',
		withInsuranceTakesPlaceOn: '',
		sendPictureDate: '',
		comments: '',
	});

	constructor(
		private dialogMessageService: DialogMessageService,
		private dialogService: DialogService,
		private formBuilder: FormBuilder,
		private store: Store,
		private requestFactory: WorkplaceMessageRequestFactory,
		@Inject(MAT_DIALOG_DATA) public data: { absenceCode: string; storyId: string; formGroup: FormGroup; creator: string }
	) {
		super();
	}

	ngOnInit(): void {
		if (this.data.storyId != null) {
			this.readOnly = true;
			this.store.dispatch(KlePageAction.getDialogMessage({ absenceCode: this.data.absenceCode, storyId: this.data.storyId }));
		}

		this.store
			.select(KleSelect.getDialogMessage)
			.pipe(
				takeUntil(this.destroy$),
				filter((message) => message != null)
			)
			.subscribe((message) => {
				this.dialogMessage = message;
				this.dialogMessageService.populateWorkplaceMessageForm(this.workplaceMessageGroup, message, message.workplaceMessage);
			});
	}

	submit(): void {
		const context = this.data.formGroup.controls.incidentContext.value;
		const workplaceData = this.workplaceMessageGroup.getRawValue();

		const dialogMessageData = this.requestFactory.create(workplaceData, this.dialogMessage);

		const request = { incidentContext: context, dialogMessage: dialogMessageData };
		this.store.dispatch(KlePageAction.synchronizeIncident({ request }));
	}
}
