import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	templateUrl: './unmerge-absence-confirmation-dialog.component.html',
})
export class UnmergeAbsenceConfirmationDialogComponent {
	code: string;

	constructor(@Inject(MAT_DIALOG_DATA) data) {
		this.code = data?.code;
	}
}
