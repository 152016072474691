import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../../shared/form-validation';
import * as formValidationConst from '../../../shared/utils/form-validation.utils';
import { AbstractControlObject } from '../../../shared/utils/form-validation.utils';

let controlsArray: Array<AbstractControlObject>;
/**
 * Checks if any field is filled on address form and if it is set zipCode and city fields as required
 */

const isAddressFormFilled = (): ValidatorFn => {
	return (formGroup: FormGroup): ValidationErrors | null => {
		const zipCodeControl = formGroup.controls.zipCode;
		const cityControl = formGroup.controls.city;
		controlsArray = makeControlsArray(['zipCode', 'city'], formGroup);

		if (checkFormControlsValues(formGroup)) {
			checkForZipCodeOrCityValue(zipCodeControl, cityControl);
		} else {
			formValidationConst.removeValidationErrors(controlsArray);
		}
		return formValidationConst.OK;
	};
};

const makeControlsArray = (controlsKey: Array<string>, formGroup: FormGroup): Array<AbstractControlObject> => {
	const controlsArray: AbstractControlObject[] = [];

	for (let key of controlsKey) {
		controlsArray.push({
			control: formGroup.controls[key],
			validator: formValidationConst.REQUIRED,
		});
	}

	return controlsArray;
};

const checkFormControlsValues = (formGroup: FormGroup): boolean => {
	return Object.keys(formGroup.controls).some((key) => !!formGroup.value[key]);
};

const checkForZipCodeOrCityValue = (zipCodeControl: AbstractControl, cityControl: AbstractControl): void => {
	if (!zipCodeControl.value) {
		FormValidation.addError(zipCodeControl, formValidationConst.REQUIRED);
	} else if (!cityControl.value) {
		FormValidation.addError(cityControl, formValidationConst.REQUIRED);
	} else {
		formValidationConst.removeValidationErrors(controlsArray);
	}
};

export { isAddressFormFilled };
