import { createReducer, on } from '@ngrx/store';
import { USER_INITIAL_STATE, UserState } from './user.state';
import { UserApiAction, UserPageAction } from './action';
import { PermissionEnumeration } from '../../shared/enumeration/permission.enumeration';

export const userReducer = createReducer<UserState>(
	USER_INITIAL_STATE,
	on(UserApiAction.loginSubmitSuccess, (state, action): UserState => {
		return {
			...state,
			id: action.result.id,
			token: action.result.token,
			accessToken: action.result.accessToken,
			refreshToken: action.result.refreshToken,
			email: action.result.email,
			has2Fa: action.result.has2Fa,
			username: action.result.username,
			language: action.result.language,
			lastName: action.result.lastName,
			firstName: action.result.firstName,
			companies: action.result.companies,
			permissions: action.result.permissions,
			isAuthenticated: !action.result.has2Fa,
			userType: action.result.userType,
			companyFilterOptions: action.result.companyFilterOptions,
		};
	}),
	on(UserApiAction.forgotPasswordSubmitSuccess, (state, action): UserState => {
		return {
			...state,
			forgotPasswordToken: action.result.value,
		};
	}),
	on(UserApiAction.verifyLoginSubmitSuccess, (state, action): UserState => {
		return {
			...state,
			isAuthenticated: true,
		};
	}),
	on(UserApiAction.resetPasswordSubmitSuccess, (state): UserState => {
		return {
			...state,
			permissions: state.permissions.filter((value) => value !== PermissionEnumeration.FORCING_PASSWORD_CHANGE),
		};
	}),
	on(UserPageAction.personalDataSubmit, (state, action): UserState => {
		return {
			...state,
			language: action.languageCode,
		};
	}),
	on(UserApiAction.getPersonalDataSuccess, (state, action): UserState => {
		return {
			...state,
			firstName: action.personalData.firstName,
			lastName: action.personalData.lastName,
			email: action.personalData.email,
			phone: action.personalData.mobileNumber,
			language: action.personalData.languageCode,
		};
	}),
	on(UserPageAction.logout, (): UserState => USER_INITIAL_STATE),
	on(UserApiAction.refreshTokenSuccess, (state, action): UserState => {
		return {
			...state,
			accessToken: action.refreshTokenResponse.accessToken,
			refreshToken: action.refreshTokenResponse.refreshToken,
		};
	}),
	on(UserPageAction.setLogoutClicked, (state, action): UserState => {
		return {
			...state,
			isLogoutClicked: action.logoutClicked,
		};
	}),
	on(UserPageAction.setFormPage, (state, action): UserState => {
		return {
			...state,
			isFormPage: action.isFormPage,
		};
	})
);
