import { createReducer, on } from '@ngrx/store';
import { AccessManagementApiAction, AccessManagementPageAction } from './action';
import { ACCESS_MANAGEMENT_STATE, AccessManagementState } from './acess-management.state';

export const accessManagementReducer = createReducer<AccessManagementState>(
	ACCESS_MANAGEMENT_STATE,
	on(AccessManagementApiAction.getAuthoritiesSuccess, (state, action): AccessManagementState => {
		return {
			...state,
			authorities: { records: action.authorities },
		};
	}),
	on(AccessManagementApiAction.getAuthoritySuccess, (state, action): AccessManagementState => {
		return {
			...state,
			authority: action.authority,
		};
	}),
	on(AccessManagementPageAction.clearAuthority, (state): AccessManagementState => {
		return {
			...state,
			authority: null,
			currentAuthorityType: null,
		};
	}),
	on(AccessManagementPageAction.setCurrentAuthorityType, (state, action): AccessManagementState => {
		return {
			...state,
			currentAuthorityType: action.authorityType,
			urlPath: action.urlPath,
		};
	})
);
