import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseComponent, Option } from 'nc-shared';
import { EnumUtils } from 'nc-utils';
import { ExpectedIncapacityToWorkEnum } from '../../../../kle/model/enums/expected-incapacity-to-work.enum';
import { UserSelect } from '../../../../user/state/action';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PermissionEnumeration } from '../../../../shared/enumeration/permission.enumeration';

@Component({
	selector: 'ea-incapacity-form',
	templateUrl: './incapacity-form.component.html',
	styleUrls: ['./incapacity-form.component.scss'],
})
export class IncapacityFormComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() showCommentField = true;
	@Input() isKleEnabled = false;
	@Input() isAccident = false;
	@Input() isLast = false;
	@Input() existsTakeWorkUpFullyAlready = false;

	hasDeclareIncidentPermission: boolean = false;

	incapacityToWorkEnum$: Observable<Option[]>;

	constructor(private store: Store) {
		super();
	}

	ngOnInit() {
		this.incapacityToWorkEnum$ = EnumUtils.toObservableItems(ExpectedIncapacityToWorkEnum);

		this.store
			.select(UserSelect.getPermissions)
			.pipe(takeUntil(this.destroy$))
			.subscribe((permissions) => (this.hasDeclareIncidentPermission = permissions.includes(PermissionEnumeration.DECLARE_INCIDENT)));
	}
}
