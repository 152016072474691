import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MilestoneSettingsModel } from '../model/milestone-settings';
import { ActionResponse, SaveResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class MilestoneSettingsService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly SEARCH_DOCUMENTS_CHECKLIST_URL = `${this.API_URL}/document-types/user`;
	private readonly MILESTONE_SETTINGS_URL = `${this.API_URL}/milestone-settings`;
	private readonly ABSENCE_URL = `${this.API_URL}/absences/`;

	/**
	 * Get all document types for table select
	 */
	getDocumentCheckList(): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(this.SEARCH_DOCUMENTS_CHECKLIST_URL);
	}

	/**
	 * Get all milestone settings
	 */
	getAllMilestoneSettings(): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(this.MILESTONE_SETTINGS_URL);
	}

	/**
	 * Gel single milestone settings
	 * @param id Unique milestone setting identifier
	 */
	getSingleMilestoneSettings(id: number): Observable<MilestoneSettingsModel> {
		return this.httpClient.get<MilestoneSettingsModel>(this.MILESTONE_SETTINGS_URL + '/' + id);
	}

	/**
	 * Delete selected milestone settings
	 * @param id Unique milestone setting identifier
	 */
	deleteMilestoneSettings(id: number): Observable<any> {
		return this.httpClient.delete<any>(this.MILESTONE_SETTINGS_URL + '/' + id);
	}

	/**
	 * Save milestone settings
	 * @param data Milestone settings model from form data
	 */
	saveMilestoneSetting(data: MilestoneSettingsModel): Observable<SaveResponse> {
		return this.httpClient.post<SaveResponse>(this.MILESTONE_SETTINGS_URL, data);
	}

	/**
	 * Recalculate statuses for all milestone settings
	 */
	recalculateStatuses(): Observable<ActionResponse> {
		return this.httpClient.post<ActionResponse>(this.ABSENCE_URL + 'recalculate-statuses', null);
	}
}
