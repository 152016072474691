import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { SettingsPageAction } from '../../../state/settings/action';
import { IntegrationPageAction } from '../../state/action';

@Component({
	selector: 'ea-company-footer',
	templateUrl: './company-footer.component.html',
	styleUrls: ['./company-footer.component.scss'],
})
export class CompanyFooterComponent {
	constructor(private location: Location, private store: Store) {}

	goBack = (): void => {
		this.location.back();
	};

	refresh = (): void => {
		this.store.dispatch(IntegrationPageAction.refreshCompany({ companyIds: [], byModificationDate: true }));
	};

	create = (): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/integration/companies/new` }));
	};
}
