import { AlignColumnsEnumeration, ColumnBuilder, DatatableColumn } from 'nc-datatable';

export class TableColumns {
	public static TASKS_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('insuredPerson', 'insuredPerson'),
		ColumnBuilder.create('company', 'company'),
		ColumnBuilder.create('code', 'code'),
		ColumnBuilder.create('type', 'type'),
		ColumnBuilder.createDate('dueDate', 'dueDate'),
		ColumnBuilder.create('hasRiskAssessment', 'riskAssessment', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('isPkPro', 'bvg', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('isSwica', 'swica', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('title', 'title'),
		ColumnBuilder.createHidden('id'),
	];

	public static MILESTONES_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('insuredPerson', 'insuredPerson'),
		ColumnBuilder.create('company', 'company'),
		ColumnBuilder.create('code', 'code'),
		ColumnBuilder.create('type', 'type'),
		ColumnBuilder.createDate('dueDate', 'dueDate'),
		ColumnBuilder.create('hasRiskAssessment', 'riskAssessment', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('isPkPro', 'bvg', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('isSwica', 'swica', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('title', 'title'),
		ColumnBuilder.create('documents', 'documents'),
		ColumnBuilder.createHidden('milestoneSettingsId'),
	];

	public static ACTIVE_ABSENCES_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('insuredPerson', 'insuredPerson'),
		ColumnBuilder.create('company', 'company'),
		ColumnBuilder.create('workplace', 'branch'),
		ColumnBuilder.createDate('createdOn', 'createdOn'),
		ColumnBuilder.create('code', 'code'),
		ColumnBuilder.create('state', 'state'),
		ColumnBuilder.create('type', 'type'),
		ColumnBuilder.create('subType', 'subType'),
		ColumnBuilder.createDate('validFrom', 'absenceFrom'),
		ColumnBuilder.createDate('validTo', 'absenceTo'),
		ColumnBuilder.createNumber('durationDays', 'durationInDays'),
		ColumnBuilder.create('status', 'status'),
		ColumnBuilder.create('contactPerson', 'contactPerson'),
		ColumnBuilder.create('contactEmail', 'contactEmail'),
		ColumnBuilder.create('modifiedBy', 'modifiedBy'),
		ColumnBuilder.createDate('modifiedOn', 'modifiedOn'),
		ColumnBuilder.create('riskAssessment', 'riskAssessment', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('isPkPro', 'bvg', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('isSwica', 'swica', null, AlignColumnsEnumeration.ALIGN_CENTER),
	];

	public static ARCHIVED_ABSENCES_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('insuredPerson', 'insuredPerson'),
		ColumnBuilder.create('company', 'company'),
		ColumnBuilder.create('workplace', 'branch'),
		ColumnBuilder.create('code', 'code'),
		ColumnBuilder.create('state', 'state'),
		ColumnBuilder.create('type', 'type'),
		ColumnBuilder.create('subType', 'subType'),
		ColumnBuilder.createDate('validFrom', 'absenceFrom', null),
		ColumnBuilder.createDate('validTo', 'absenceTo', null),
		ColumnBuilder.createNumber('durationDays', 'durationInDays'),
		ColumnBuilder.create('status', 'status'),
		ColumnBuilder.create('archivedReason', 'archivingReason'),
		ColumnBuilder.create('archivedComment', 'comment'),
		ColumnBuilder.create('isPkPro', 'bvg', null, AlignColumnsEnumeration.ALIGN_CENTER),
		ColumnBuilder.create('isSwica', 'swica', null, AlignColumnsEnumeration.ALIGN_CENTER),
	];

	public static HR_ARCHIVED_ABSENCES_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('companyName', 'company'),
		ColumnBuilder.create('workplace', 'branch'),
		ColumnBuilder.create('insuredPerson', 'insuredPerson'),
		ColumnBuilder.create('code', 'code'),
		ColumnBuilder.create('state', 'state'),
		ColumnBuilder.create('type', 'type'),
		ColumnBuilder.create('subType', 'subType'),
		ColumnBuilder.create('status', 'status'),
		ColumnBuilder.createDate('absenceFrom', 'absenceFrom'),
		ColumnBuilder.createDate('absenceTo', 'absenceTo'),
		ColumnBuilder.create('durationInDays', 'durationInDays'),
		ColumnBuilder.create('archivedReason', 'archivingReason'),
		ColumnBuilder.create('archivedComment', 'comment'),
	];
}
