import { Component, Input, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, FilterConfig, TableData } from 'nc-datatable';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { KleInsuredSalaryPast } from 'src/app/kle/model/kle-insured-salary-past';

@Component({
	selector: 'ea-past-salary',
	templateUrl: './past-salary.component.html',
})
export class PastSalaryComponent implements OnInit {
	@Input()
	formGroup: FormGroup;

	data$: Observable<TableData>;
	selectedMonth: KleInsuredSalaryPast;

	salaryTableData$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });

	filterConfig: FilterConfig = {
		showGlobalFilter: false,
		filterValues: {},
	};

	ngOnInit() {
		const tableData = { records: [] };
		this.formGroup.value.salary.forEach((salary) => {
			tableData.records.push(salary);
		});
		this.data$ = of(tableData);
		this.openSalaryDetails(this.formGroup.value.salary[0]);
	}

	getSaleriesDetailsColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('month', 'month'),
			ColumnBuilder.create('total', 'total'),
			ColumnBuilder.create('hoursPerMonth', 'hoursPerMonth'),
		];
	}

	getSaleryDetailsColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('amount', 'amount'),
			ColumnBuilder.create('kindOfSalary', 'kindOfSalary'),
			ColumnBuilder.create('statisticCode', 'statisticCode'),
		];
	}

	openSalaryDetails = (model: KleInsuredSalaryPast) => {
		this.selectedMonth = model;
		const tableData = { records: [] };
		this.selectedMonth.kindOfSalaries.forEach((salary) => {
			tableData.records.push(salary);
		});
		this.salaryTableData$.next(tableData);
	};
}
