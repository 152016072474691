<form fxLayout="column" fxFill [formGroup]="formGroup">
	<ea-header-info [showCompany]="true"></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<nc-input [id]="'day'" [formGroup]="formGroup" formControlName="day" label="day" [isReadonly]="true"></nc-input>
				<nc-input [id]="'title'" [formGroup]="formGroup" formControlName="title" label="title" [isReadonly]="true"></nc-input>
				<nc-textarea [id]="'note'" [formGroup]="formGroup" formControlName="note" label="note" [isReadonly]="true"></nc-textarea>
				<ea-documents
					[id]="'milestone-form'"
					[buttonId]="'open-dialog'"
					[downloadButtonId]="'download-documents-button'"
					[title]="'documentsChecklist'"
					[documentsFormArray]="documents"
					[additionalColumns]="additionalColumns"
					[isReadonly]="true"
				>
				</ea-documents>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-task-footer [saveButtonLabel]="saveButtonLabel" [onSave]="onSubmit" [formGroup]="formGroup"></ea-task-footer>
</form>
