import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { DocumentModel } from 'nc-documents';

@Injectable({
	providedIn: 'root',
})
export class EncodingService {
	encodeDocuments(documents: DocumentModel[]): DocumentModel[] {
		documents
			.filter((document) => document.id === 0)
			.forEach((document) => {
				document.file = this.changeFileName(document.file);
			});

		return documents;
	}

	changeFileName(originalFile: File): File {
		return new File([originalFile], this.encode(originalFile.name), {
			type: originalFile.type,
			lastModified: originalFile.lastModified,
		});
	}

	encode(value: string): string {
		let encoded = Buffer.from(value).toString('base64');
		encoded = encoded.replace(/\+/g, '-').replace(/\//g, '_').split('=')[0];

		return encoded;
	}
}
