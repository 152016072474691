import { Component, Input } from '@angular/core';
import { Employee } from '../../../shared/model/employee';

@Component({
	selector: 'ea-insurance-period',
	templateUrl: './insurance-period.component.html',
	styleUrls: ['./insurance-period.component.scss'],
})
export class InsurancePeriodComponent {
	@Input() employee: Employee;
}
