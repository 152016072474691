import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KlePageAction, KleSelect } from '../../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { DialogMessageService } from '../../../service/dialog-message/dialog-message.service';
import { DialogMessage } from '../../../model/dialog-message';
import { ContactPersonMessageRequestFactory } from '../../../service/dialog-message/contact-person-message-request.factory';
import { DialogMessageTypeEnum } from '../../../model/enums/dialog-message-type.enum';

@Component({
	templateUrl: './contact-person-message-form.component.html',
	styleUrls: ['./contact-person-message-form.component.scss'],
})
export class ContactPersonMessageFormComponent extends BaseComponent implements OnInit {
	readOnly = false;
	dialogMessage: DialogMessage;

	contractPersonMessageGroup = this.formBuilder.group({
		title: '',
		message: '',
		description: '',
		creator: '',
		dialogMessageType: DialogMessageTypeEnum.CONTACT_PERSON.code,
		companyCaseId: this.data.absenceCode,
		previous: this.data.storyId,
		firstName: '',
		lastName: '',
		phone: '',
		function: '',
		email: '',
	});

	constructor(
		private dialogMessageService: DialogMessageService,
		private formBuilder: FormBuilder,
		private store: Store,
		private requestFactory: ContactPersonMessageRequestFactory,
		@Inject(MAT_DIALOG_DATA) public data: { absenceCode: string; storyId: string; formGroup: FormGroup; creator: string }
	) {
		super();
	}

	ngOnInit(): void {
		if (this.data.storyId != null) {
			this.readOnly = true;
			this.store.dispatch(KlePageAction.getDialogMessage({ absenceCode: this.data.absenceCode, storyId: this.data.storyId }));
		}

		this.store
			.select(KleSelect.getDialogMessage)
			.pipe(
				takeUntil(this.destroy$),
				filter((message) => message != null)
			)
			.subscribe((message) => {
				this.dialogMessage = message;
				this.dialogMessageService.populateContactPersonMessageForm(this.contractPersonMessageGroup, message);
			});
	}

	submit(): void {
		const context = this.data.formGroup.controls.incidentContext.value;
		const contactPersonData = this.contractPersonMessageGroup.getRawValue();

		const dialogMessageData = this.requestFactory.create(contactPersonData, this.dialogMessage);

		const request = { incidentContext: context, dialogMessage: dialogMessageData };
		this.store.dispatch(KlePageAction.synchronizeIncident({ request }));
	}
}
