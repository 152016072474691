<form [formGroup]="processControlResponseFormGroup" fxFill fxLayout="column">
	<div fxLayout="row" fxLayoutGap="20px" class="divider-containers">
		<ea-container title="changeReference" fxFlex="40">
			<div fxLayout="row" fxLayoutGap="10px">
				<nc-input label="oldId" formControlName="oldId" [formGroup]="processControlResponseFormGroup"></nc-input>
				<nc-input label="newId" formControlName="newId" [formGroup]="processControlResponseFormGroup"></nc-input>
			</div>
		</ea-container>
		<ea-container title="institutionAddress" fxFlex="60">
			<div fxLayout="column" fxLayoutGap="10px" [ngClass]="'margin-top-10'">
				<nc-input label="name" formControlName="name" [formGroup]="processControlResponseFormGroup"></nc-input>
			</div>
		</ea-container>
	</div>
	<div fxLayout="row" fxLayoutGap="20px" class="divider-containers">
		<ea-container title="contactInsurance" fxFlex="40">
			<div fxLayout="row" fxLayoutGap="10px" [ngClass]="'margin-top-10'">
				<nc-input
					label="insuranceName"
					formControlName="insuranceName"
					[formGroup]="processControlResponseFormGroup"
					[isReadonly]="true"
				></nc-input>
				<nc-input
					label="insuranceEmail"
					formControlName="insuranceEmail"
					[formGroup]="processControlResponseFormGroup"
					[isReadonly]="true"
				></nc-input>
			</div>
			<div fxLayout="row" fxLayoutGap="10px" [ngClass]="'margin-top-10'">
				<nc-input
					label="insurancePhoneNumber"
					formControlName="insurancePhoneNumber"
					[formGroup]="processControlResponseFormGroup"
					[isReadonly]="true"
				></nc-input>
				<nc-input
					label="insuranceMobileNumber"
					formControlName="insuranceMobileNumber"
					[formGroup]="processControlResponseFormGroup"
					[isReadonly]="true"
				></nc-input>
			</div>
		</ea-container>
		<ea-container title="address" fxFlex="60">
			<div fxLayout="row" fxLayoutGap="10px" [ngClass]="'margin-top-10'">
				<nc-input
					label="complementaryLine"
					formControlName="complementaryLine"
					[formGroup]="processControlResponseFormGroup"
					[isReadonly]="true"
				></nc-input>
				<nc-input label="street" formControlName="street" [formGroup]="processControlResponseFormGroup" [isReadonly]="true"></nc-input>
			</div>
			<div fxLayout="row" fxLayoutGap="10px">
				<nc-input label="postbox" formControlName="postbox" [formGroup]="processControlResponseFormGroup" [isReadonly]="true"></nc-input>
				<nc-input label="locality" formControlName="locality" [formGroup]="processControlResponseFormGroup" [isReadonly]="true"></nc-input>
			</div>
			<div fxLayout="row" fxLayoutGap="10px">
				<nc-input label="zipCode" formControlName="zipCode" [formGroup]="processControlResponseFormGroup" [isReadonly]="true"></nc-input>
				<nc-input label="city" formControlName="city" [formGroup]="processControlResponseFormGroup" [isReadonly]="true"></nc-input>
			</div>
			<div fxLayout="row" fxLayoutGap="10px">
				<nc-input label="country" formControlName="country" [formGroup]="processControlResponseFormGroup" [isReadonly]="true"></nc-input>
			</div>
		</ea-container>
	</div>
</form>
