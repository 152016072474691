import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AdministrationComponent } from './component/administration/administration.component';
import { EffectsModule } from '@ngrx/effects';
import { AdministrationEffect } from './state/administration.effect';
import { StoreModule } from '@ngrx/store';
import { administrationReducer } from './state/administration.reducer';
import { ADMINISTRATION_FEATURE_STATE_NAME } from './state/action/administration.select';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([AdministrationEffect]),
		StoreModule.forFeature(ADMINISTRATION_FEATURE_STATE_NAME, administrationReducer),
	],
	declarations: [AdministrationComponent],
	exports: [AdministrationComponent],
})
export class AdministrationModule {}
