import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AccessManagementPageAction, AccessManagementSelect } from '../../state/action';
import { AuthorityTypeEnumeration } from '../../model/enumeration/authority-type.enumeration';
import { ActivatedRoute } from '@angular/router';
import { SettingsPageAction } from '../../../state/settings/action';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './authority.component.html',
	styleUrls: ['./authority.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorityComponent implements OnInit {
	authorities$: Observable<TableData>;
	authorityColumns: DatatableColumn[];
	authorityType: AuthorityTypeEnumeration;
	urlPath: string;
	filterConfig: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;

	constructor(private store: Store, private route: ActivatedRoute) {
		this.route.url.subscribe((url) => {
			this.urlPath = url[0].path;

			if (this.urlPath === 'roles') {
				this.authorityType = AuthorityTypeEnumeration.ROLE;
			} else if (this.urlPath === 'role-groups') {
				this.authorityType = AuthorityTypeEnumeration.ROLE_GROUP;
			} else if (this.urlPath === 'permissions') {
				this.authorityType = AuthorityTypeEnumeration.PERMISSION;
			}
		});

		this.store.dispatch(AccessManagementPageAction.getAuthorities({ authorityType: this.authorityType }));
		this.store.dispatch(AccessManagementPageAction.setCurrentAuthorityType({ authorityType: this.authorityType, urlPath: this.urlPath }));

		this.authorityColumns = [
			ColumnBuilder.create('id', 'id'),
			ColumnBuilder.create('code', 'code'),
			ColumnBuilder.create('name', 'description'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];

		this.authorities$ = this.store.select(AccessManagementSelect.getAuthorities);
		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: true,
			filterValues: {},
		};
	}

	ngOnInit(): void {}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `${this.urlPath}/${rowData.id}` }));
	};

	delete = (rowData): void => {
		const data = { authorityId: rowData.id, authorityType: this.authorityType };
		this.store.dispatch(AccessManagementPageAction.openDeleteConfirmationDialog(data));
	};
}
