import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { NotificationsPageAction, NotificationsSelect } from '../state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../user/state/action';
import { distinct, filter } from 'rxjs/operators';
import { UserNotificationOption } from '../model/user-notification-option';

@Component({
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent extends BaseComponent implements OnDestroy {
	formGroup: FormGroup;
	userControl: FormControl;
	filterConfig: FilterConfig = { showGlobalFilter: true, filterValues: {} };
	notificationsColumns: DatatableColumn[];

	users$: Observable<Option[]>;
	notifications$: Observable<TableData>;

	userSubscription$: Subscription;
	userControlSubscription$: Subscription;
	selectedUserSubscription$: Subscription;

	selectedUser: UserNotificationOption;
	loggedInUser: UserNotificationOption;
	hasViewAllUsersNotificationPermission = false;

	constructor(private store: Store, formBuilder: FormBuilder, private router: Router) {
		super();
		this.store.dispatch(NotificationsPageAction.getUsers());
		this.formGroup = formBuilder.group({ user: null });
		this.userControl = this.formGroup.get('user') as FormControl;

		this.notificationsColumns = [
			ColumnBuilder.createHidden('notificationId'),
			ColumnBuilder.create('companyName', 'company'),
			ColumnBuilder.create('absenceCode', 'absenceCode'),
			ColumnBuilder.create('notificationType', 'notificationType'),
			ColumnBuilder.create('notificationInfo', 'notificationInfo'),
			ColumnBuilder.create('insuredPerson', 'insuredPerson'),
			ColumnBuilder.create('notificationDateTime', 'notificationDateTime'),
		];

		this.users$ = this.store.select(NotificationsSelect.getUsers);
		this.notifications$ = this.store.select(NotificationsSelect.getNotifications);

		this.userControlSubscription$ = this.userControl.valueChanges.pipe(filter((user) => user != null)).subscribe((user) => {
			this.selectedUser = user;
			if (this.selectedUser.id != 0) {
				this.store.dispatch(NotificationsPageAction.getNotifications({ userId: this.selectedUser.id }));
			}
		});

		this.userSubscription$ = this.store
			.select(UserSelect.getUser)
			.pipe(
				filter((user) => user !== null),
				distinct((user) => user.id)
			)
			.subscribe((user) => {
				this.loggedInUser = { id: user.id, type: user.userType };
				this.userControl.patchValue(this.loggedInUser);
				this.hasViewAllUsersNotificationPermission = user.permissions.includes('SEE_NOTIFICATIONS_OF_ALL_CM_AND_LD_USERS');
			});

		this.selectedUserSubscription$ = this.store
			.select(NotificationsSelect.getSelectedUser)
			.pipe(filter((user) => user != null))
			.subscribe((user) => this.userControl.patchValue(user));
	}

	onRowClick = (notification): void => {
		if (this.loggedInUser?.type === this.selectedUser?.type) {
			this.store.dispatch(NotificationsPageAction.deleteNotification({ notificationId: notification.notificationId }));
		}

		this.router.navigateByUrl(`/absence/${notification.absenceCode}`);
	};

	comparisonFunction = (option, value): boolean => {
		return option?.id === value?.id;
	};

	ngOnDestroy(): void {
		this.userSubscription$.unsubscribe();
		this.userControlSubscription$.unsubscribe();
		this.selectedUserSubscription$.unsubscribe();
		this.store.dispatch(NotificationsPageAction.setSelectedUser(null));
	}
}
