import { Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';
import { AppEvent } from '../model/app-event.model';
import { BroadcastEventType } from '../enumeration/broadcast-event-type.enumeration';

@Injectable({
	providedIn: 'root',
})
export class EventQueueService {
	private eventBrocker = new Subject<AppEvent<any>>();

	on(eventType: BroadcastEventType): Observable<AppEvent<any>> {
		return this.eventBrocker.pipe(filter((event) => event.type === eventType));
	}

	dispatch<T>(event: AppEvent<T>): void {
		this.eventBrocker.next(event);
	}
}
