import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent, Option } from 'nc-shared';
import { AbsencePageAction, AbsenceSelect } from '../../../../state/action';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DialogService, EnumUtils } from 'nc-utils';
import { FlagEnumeration } from '../../../../../shared/enumeration/flag.enumeration';
import { Observable, of, pairwise } from 'rxjs';
import { filter, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { SearchService } from '../../../../../shared/service/search.service';
import { Company } from '../../../../../shared/model/company';
import { Employee } from '../../../../../shared/model/employee';
import { UserSelect } from '../../../../../user/state/action';
import { ErrorSelect } from '../../../../../state/error/action';
import { ValidationService } from '../../../../../shared/service/validation.service';
import { Absence } from '../../../../model/absence';
import { AbsenceFormBuilder } from '../../../../service/absence-form.builder';
import { AbsenceTableColumns } from '../../../../model/absence-table-columns';
import { AvailableEnumeration } from '../../../../../shared/enumeration/available.enumeration';
import { CaseManagementBranchOfficeEnumeration } from '../../../../model/enumeration/case-management-branch-office.enumeration';
import { FlagWithUnknownEnumeration } from '../../../../../shared/enumeration/flag-with-unknown.enumeration';
import { AccomplishmentYesEnumeration } from '../../../../model/enumeration/accomplishment-yes.enumeration';
import { AccomplishmentNoEnumeration } from '../../../../model/enumeration/accomplishment-no.enumeration';
import { CompanyDialogComponent } from '../../../dialog/company-dialog/company-dialog.component';
import { ExistingAbsenceService } from '../../../../service/existing-absence.service';
import { ActiveAbsence } from '../../../../model/active-absence';
import { AbsenceService } from '../../../../service/absence.service';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { MergeAbsenceDialogComponent } from '../../../dialog/merge-absence/merge-absence-dialog.component';
import { AbsenceContextModel } from '../../../../../shared/model/absence-context.model';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { SettingsPageAction } from '../../../../../state/settings/action';
import { EmployeeDetailsComponent } from '../../../../../integration/component/employee-details/employee-details.component';
import { AbsenceDeclarationTypeEnumeration } from '../../../../model/enumeration/absence-declaration-type.enumeration';
import { AbsenceStatusEnumeration } from '../../../../model/enumeration/absence-status.enumeration';

@Component({
	selector: 'ea-cm-absence',
	templateUrl: './cm-absence.component.html',
	styleUrls: ['./cm-absence.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmAbsenceComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input() formGroup: FormGroup;
	code: string;
	permissions: string[];
	categoryType: string;
	isReadonly: boolean;
	isArchived: boolean;
	medicalDiagnosis: string;
	medicalDiagnosisAdditional: string;
	isValidToPopulated: boolean;
	hasSavedConfirmationEmail: boolean;
	otherAbsences: ActiveAbsence[];
	columns = AbsenceTableColumns;
	mergedAbsenceColumns: DatatableColumn[];

	absence$: Observable<Absence>;
	company$: Observable<Company>;
	employee$: Observable<Employee>;
	responsibleUsers$: Observable<Option[]>;
	responsibleLDUsers$: Observable<Option[]>;

	mergedAbsence$: Observable<TableData>;
	linkedAbsence$: Observable<TableData>;
	shareInformation$: Observable<TableData>;

	flagOptions: Option[];
	availableOptions: Option[];
	branchOfficeOptions: Option[];
	flagWithUnknownOptions: Option[];
	yesAccomplishmentOptions: Option[];
	noAccomplishmentOptions: Option[];

	documents: FormArray;
	incapacities: FormArray;
	absenceType: FormControl;
	absenceContextModel$: Observable<AbsenceContextModel>;
	additionalColumns: DatatableColumn[];
	shareInformationFilterConfig: FilterConfig;
	mergeAbsenceFilterConfig: FilterConfig;
	linkedAbsenceFilterConfig: FilterConfig;
	employeeId: number;
	displayRecoverageButton = false;
	draftOrPendingApprovalCase = false;

	EMPLOYEEE_ID = 'employeeId';
	ABSENCE_DECLARATION_TYPE = AbsenceDeclarationTypeEnumeration;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private searchService: SearchService,
		private dialogService: DialogService,
		private absenceService: AbsenceService,
		private changeDetectorRef: ChangeDetectorRef,
		private validationService: ValidationService,
		private absenceFormBuilder: AbsenceFormBuilder,
		private existingAbsenceService: ExistingAbsenceService
	) {
		super();

		this.flagOptions = EnumUtils.toItems(FlagEnumeration);
		this.availableOptions = EnumUtils.toItems(AvailableEnumeration);
		this.flagWithUnknownOptions = EnumUtils.toItems(FlagWithUnknownEnumeration);
		this.branchOfficeOptions = EnumUtils.toItems(CaseManagementBranchOfficeEnumeration);
		this.yesAccomplishmentOptions = EnumUtils.toItems(AccomplishmentYesEnumeration);
		this.noAccomplishmentOptions = EnumUtils.toItems(AccomplishmentNoEnumeration);

		this.company$ = this.store.select(AbsenceSelect.getCompany);
		this.absence$ = this.store.select(AbsenceSelect.getAbsence);
		this.employee$ = this.store.select(AbsenceSelect.getEmployee);
		this.responsibleUsers$ = this.store.select(AbsenceSelect.getResponsibleUsers);
		this.responsibleLDUsers$ = this.store.select(AbsenceSelect.getResponsibleLDUsers);
		this.absenceContextModel$ = this.store.select(AbsenceSelect.getAbsenceContextModel);
		this.store.dispatch(AbsencePageAction.absenceSavedSuccessfully({ absenceSavedSuccessfully: false }));

		this.store.select(UserSelect.getPermissions).subscribe((permissions) => (this.permissions = permissions));
		this.store.select(ErrorSelect.getErrors).subscribe((errors) => validationService.displayErrors(errors, this.formGroup, changeDetectorRef));

		this.shareInformationFilterConfig = {
			showFilter: false,
			filterValues: {},
		};
		this.mergeAbsenceFilterConfig = {
			showFilter: false,
			filterValues: {},
		};
		this.linkedAbsenceFilterConfig = {
			showFilter: false,
			filterValues: {},
		};
	}

	ngOnInit(): void {
		this.documents = this.formGroup.get('documents') as FormArray;
		this.incapacities = this.formGroup.get('incapacities') as FormArray;
		this.absenceType = this.formGroup.get('absenceType') as FormControl;

		this.store
			.select(AbsenceSelect.getIsAbsenceSavedSuccessfully)
			.pipe(takeUntil(this.destroy$))
			.subscribe((absenceSavedSuccessfully) => {
				if (absenceSavedSuccessfully) {
					this.formGroup.markAsPristine();
				}
			});

		this.route.params.subscribe((params) => {
			this.code = params.code;
			this.store.dispatch(AbsencePageAction.fetchAbsence({ code: this.code }));
			this.store.dispatch(AbsencePageAction.getCurrentAbsence({ code: this.code }));
		});

		this.linkedAbsence$ = this.store.select(AbsenceSelect.getLinkedAbsence);
		this.mergedAbsence$ = this.store.select(AbsenceSelect.getMergedAbsence);
		this.shareInformation$ = this.store.select(AbsenceSelect.getShareInformation);

		this.absence$
			.pipe(
				filter((absence) => absence.id > 0),
				withLatestFrom(this.employee$, this.company$)
			)
			.subscribe(([absence, employee, company]) => {
				this.draftOrPendingApprovalCase =
					absence.status === AbsenceStatusEnumeration.DRAFT.code || absence.status === AbsenceStatusEnumeration.PENDING_APPROVAL.code;
				this.isReadonly = absence.readonly || this.draftOrPendingApprovalCase;
				this.isArchived = absence.archived;
				this.hasSavedConfirmationEmail = absence.hasSavedConfirmationEmail;
				this.isValidToPopulated = absence.isValidToPopulated;
				this.categoryType = absence.categoryType;
				this.otherAbsences = absence.activeCases;
				this.medicalDiagnosis = absence.medicalDiagnosisValue;
				this.medicalDiagnosisAdditional = absence.medicalDiagnosisAdditionalValue;
				this.employeeId = absence[this.EMPLOYEEE_ID];
				this.displayRecoverageButton = employee.neoId && !employee.easyTempId && this.permissions.includes('RECOVERAGE_ACTIVE');

				this.existingAbsenceService.initializeForm(this.formGroup, absence, employee, company);
			});

		this.mergedAbsenceColumns = [
			ColumnBuilder.create('mergedAbsenceCode', 'code'),
			ColumnBuilder.create('reason', 'reason'),
			ColumnBuilder.createDate('validFrom', 'validFrom'),
			ColumnBuilder.createDate('validTo', 'validTo'),
			ColumnBuilder.create('comment', 'comment'),
			ColumnBuilder.create('createdBy', 'createdBy'),
			ColumnBuilder.createDate('createdOn', 'createdOn'),
			ColumnBuilder.createAction('unmerge', 'unmerge', this.unmerge, { defaultIcon: 'expand' }),
		];

		this.additionalColumns = [
			ColumnBuilder.create('uploadedBy', 'uploadedBy'),
			ColumnBuilder.create('comment', 'comment'),
			ColumnBuilder.createDate('uploadedOn', 'uploadedOn'),
		];

		const caseManagementSuccessfulnessTypeControl = this.formGroup.get('caseManagementSuccessfulnessType');
		this.formGroup.get('caseManagementAccomplishment').valueChanges.subscribe((type) => {
			switch (type) {
				case 'YES':
					caseManagementSuccessfulnessTypeControl.patchValue('COORDINATION_ALV');
					break;
				case 'NO':
					caseManagementSuccessfulnessTypeControl.patchValue('NOT_WANTED');
					break;
				default:
					caseManagementSuccessfulnessTypeControl.patchValue('');
					break;
			}
		});

		this.responsibleUserValueChanges();
	}

	responsibleUserValueChanges() {
		this.formGroup.controls.responsibleUserId.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
			if (prev != next) {
				this.formGroup.markAsDirty();
			}
		});

		this.formGroup.controls.responsibleLDUserId.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
			if (prev != next) {
				this.formGroup.markAsDirty();
			}
		});
	}

	ngOnDestroy(): void {
		this.formGroup.reset();
		this.store.dispatch(AbsencePageAction.restartAbsence());
	}

	searchMedicalDiagnosis = (term) => of(term).pipe(switchMap((query) => this.searchService.getMedicalDiagnosis(query, this.absenceType.value)));

	getMedicalDiagnosis = (value) => of(value).pipe(switchMap((code) => this.absenceService.getMedicalDiagnosis(code)));

	openEmployee = (): void => {
		const dialogConfig = { data: { insuredPersonId: this.employeeId } };
		this.dialogService.open(EmployeeDetailsComponent, dialogConfig);
	};

	openCompany = (): void => {
		this.dialogService.open(CompanyDialogComponent);
	};

	resendConfirmationEmail = (): void => {
		this.store.dispatch(AbsencePageAction.resendConfirmationEmail({ code: this.code }));
	};

	reassignAbsence = (): void => {
		this.store.dispatch(AbsencePageAction.reassignAbsence({ code: this.code }));
	};

	mergeExistingAbsence = (): void => {
		const dialogConfig = {
			id: 'MergeAbsenceDialog',
			data: {
				otherAbsences: this.otherAbsences,
				code: this.code,
			},
		};
		this.dialogService.open(MergeAbsenceDialogComponent, dialogConfig);
	};

	unmerge = (rowData): void => {
		this.store.dispatch(AbsencePageAction.openUnmergeConfirmationDialog({ code: rowData.mergedAbsenceCode }));
	};

	openAbsenceMessage = (rowData): void => {
		const redirectUrl = rowData.messageType ? `/annex/${rowData.messageType.toLowerCase()}/${rowData.code}` : `/share-information/${rowData.id}`;
		this.store.dispatch(SettingsPageAction.navigate({ url: redirectUrl }));
	};
}
