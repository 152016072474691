import { createAction, props } from '@ngrx/store';
import { Employee } from '../../../shared/model/employee';
import { CompanyResponse } from '../../model/response/company.response';
import { Option } from 'nc-shared';
import { AbsenceResponse } from '../../model/response/absence.response';
import { AbsenceContextModel } from '../../../shared/model/absence-context.model';
import { AbsenceAnnexResponse } from '../../model/response/absence-annex.response';
import { DeclareIncidentResponse } from '../../../kle/model/response/declare-incident.response';
import { IncidentContext } from '../../../kle/model/incident-context';
import { ResponseModel } from '../../../shared/model/response.model';
import { SaveResponse } from 'nc-utils';
import { Absence } from '../../model/absence';

export const archiveSuccess = createAction('[Absence API] Archive success');
export const activateSuccess = createAction('[Absence API] Activate success');
export const canArchiveSuccess = createAction('[Absence API] Can archive success');
export const mobiliarDataSheetSuccess = createAction('[Absence API] Mobiliar data sheet success');
export const submitNewSuccess = createAction('[Absence API] Submit success', props<{ code: string }>());
export const submitNewFailed = createAction(
	'[Absence API] Create absence failed',
	props<{ response: SaveResponse; absence: any; isBranchOrHqHr?: boolean }>()
);
export const submitExistingSuccess = createAction('[Absence API] Submit existing success', props<{ code: string }>());
export const deleteDraftSuccess = createAction('[Absence API] Delete draft success', props<{ code: string }>());
export const fetchAbsenceSuccess = createAction(
	'[Absence API] Fetch absence success',
	props<{
		response: AbsenceResponse;
	}>()
);
export const fetchEmployeeSuccess = createAction(
	'[Absence API] Fetch employees success',
	props<{
		employee: Employee;
	}>()
);
export const fetchEmployeesSuccess = createAction(
	'[Absence API] Fetch employees success for new case',
	props<{
		employees: Option[];
	}>()
);
export const fetchCompanySuccess = createAction(
	'[Absence API] Fetch company success',
	props<{
		companyResponse: CompanyResponse;
	}>()
);
export const getHistorySuccess = createAction(
	'[History] Get History  success',
	props<{
		records: { [key: string]: string }[];
	}>()
);
export const resendConfirmationEmailSuccess = createAction('[Absence API] Resend confirmation email success');
export const resendAnnexConfirmationEmailSuccess = createAction('[Absence API] Resend annex confirmation email success');
export const reassignAbsenceSubmitSuccess = createAction('[Absence API] Submit reassign absence');
export const mergeAbsenceSuccess = createAction('[Absence API] Merge absence success');
export const saveAbsenceAnnexSuccess = createAction('[Absence] Save absence annex success');

export const getAbsenceAnnexSuccess = createAction(
	'[Absence] Get absence annex success',
	props<{
		absenceAnnexResponse: AbsenceAnnexResponse;
	}>()
);

export const getCurrentAbsenceSuccess = createAction(
	'[Absence] Get current absence success',
	props<{
		currentAbsence: AbsenceContextModel;
	}>()
);

export const getReassignAbsenceEmployeesSuccess = createAction(
	'[History] Get Reassign absence employees success',
	props<{ records: { [key: string]: string }[] }>()
);

export const initializeSelectsSuccess = createAction(
	'[Absence API] Initialize selects success',
	props<{
		responsibleLDUsers: Option[];
	}>()
);
export const declareIncidentSuccess = createAction(
	'[Absence KLE] Declare incident success',
	props<{
		response: DeclareIncidentResponse;
	}>()
);
export const declareIncidentFail = createAction(
	'[Absence KLE] Declare incident fail',
	props<{
		response: ResponseModel<DeclareIncidentResponse>;
	}>()
);
export const getIncidentContextSuccess = createAction(
	'[Absence API] Get incident context success',
	props<{
		response: IncidentContext;
	}>()
);

export const saveAccidentDescriptionSuccess = createAction('[Absence API] Save accident description success');
export const saveTreatmentsSuccess = createAction('[Absence API] Save treatments success');
export const rejectSuccess = createAction('[Absence API] Reject absence success');
export const submitNewAgain = createAction('[Absence Page] Submit New Again', props<{ absence: Absence }>());
