import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../../user/state/action';
import { KlePageAction, KleSelect } from '../../state/action';
import { SettingsPageAction } from '../../../state/settings/action';

@Component({
	templateUrl: './register-organization.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterOrganizationComponent extends BaseComponent implements OnInit, OnDestroy {
	data$: Observable<TableData>;
	filterConfig: FilterConfig;
	companyIds: number[];

	companiesSubscription: Subscription;
	isAuthenticated = false;

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.store.select(UserSelect.isAuthenticated).subscribe((result) => (this.isAuthenticated = result));

		this.companiesSubscription = this.store.select(UserSelect.getUserCompanyIds).subscribe((result) => {
			this.companyIds = result;
			if (this.isAuthenticated) {
				this.store.dispatch(KlePageAction.getCompanies({ companyIds: this.companyIds }));
			}
		});

		this.data$ = this.store.select(KleSelect.getCompanies);

		this.filterConfig = {
			showFilter: false,
			filterValues: {
				showFilter: false,
				showGlobalFilter: true,
				globalFilter: null,
			},
		};
	}

	ngOnDestroy(): void {
		this.companiesSubscription.unsubscribe();
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('name', 'name'),
			ColumnBuilder.create('neoId', 'neoId'),
			ColumnBuilder.create('easyTempId', 'easyTempId'),
			ColumnBuilder.create('contractType', 'contractType'),
		];
	}

	selectCompany = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/company/${rowData.id}` }));
	};
}
