<ea-container title="manualAdditions" cssClass="margin-top-15">
	<div fxLayout="row wrap" fxLayoutAlign="end">
		<nc-label id="totalAmount" value="totalAmount"></nc-label>
		<span>: {{ getTotalAmount() }}</span>
	</div>
	<ng-template #tableHeader>
		<div class="button-add-container" fxLayout="row wrap">
			<nc-button id="add" icon="add" disabled="!isBranchHr" [onClick]="openDialog"></nc-button>
		</div>
	</ng-template>
	<nc-datatable
		id="manual-additions"
		[tableHeader]="tableHeader"
		[columns]="getColumns()"
		[data$]="manualAdditions$"
		[filterConfig]="filterConfig"
		[rowClick]="edit"
	></nc-datatable>
</ea-container>
