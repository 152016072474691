import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
import { Store } from '@ngrx/store';
import { SettingsSelect } from './state/settings/action';
import { Injectable } from '@angular/core';
import { EnvironmentLoader } from './core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
	accountUrl = 'ecareag.zendesk.com';

	constructor(private store: Store, private environmentLoader: EnvironmentLoader) {
		super();
	}

	callback(zendesk) {
		this.store.select(SettingsSelect.getLanguage).subscribe((language) => zendesk.setLocale(language));
		zendesk('webWidget', 'updateSettings', {
			webWidget: {
				position: { horizontal: 'right', vertical: 'top' },
				color: { theme: '#2c7250' },
				offset: { horizontal: '150px', vertical: '12px' },
			},
		});

		if (this.environmentLoader.getEnvironment().theme !== 'ecare-theme') {
			zendesk.hide();
		}
	}
}
