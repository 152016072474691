import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../../shared/service/search.service';
import { DialogService, EnumUtils, StringsUtils } from 'nc-utils';
import { BaseComponent, Option } from 'nc-shared';
import { AbsencePageAction, AbsenceSelect } from '../../../../state/action';
import { FlagEnumeration } from '../../../../../shared/enumeration/flag.enumeration';
import { AbsenceDeclarationTypeEnumeration } from '../../../../model/enumeration/absence-declaration-type.enumeration';
import { Company } from '../../../../../shared/model/company';
import { UserSelect } from '../../../../../user/state/action';
import { ErrorSelect } from '../../../../../state/error/action';
import { ValidationService } from '../../../../../shared/service/validation.service';
import { Employee } from '../../../../../shared/model/employee';
import { EmployeeDetailsComponent } from '../../../../../integration/component/employee-details/employee-details.component';
import { CompanyDialogComponent } from '../../../dialog/company-dialog/company-dialog.component';
import { AbsenceService } from '../../../../service/absence.service';

@Component({
	templateUrl: './cm-new-absence.component.html',
	styleUrls: ['./cm-new-absence.component.scss'],
	selector: 'ea-new-absence',
})
export class CmNewAbsenceComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input() formGroup: FormGroup;
	permissions: string[];

	company$: Observable<Company>;
	employee$: Observable<Employee>;
	employees$: Observable<Option[]>;
	responsibleUsers$: Observable<Option[]>;
	responsibleLDUsers$: Observable<Option[]>;

	flagOptions: Option[];
	declarationTypeOptions: Option[];

	documents: FormArray;
	incapacities: FormArray;
	company: FormControl;
	employee: FormControl;
	absenceType: FormControl;
	declarationType: FormControl;
	companyContractType: FormControl;
	employeeId: number;

	ABSENCE_DECLARATION_TYPE = AbsenceDeclarationTypeEnumeration;

	constructor(
		private store: Store,
		private searchService: SearchService,
		private dialogService: DialogService,
		private changeDetectorRef: ChangeDetectorRef,
		private validationService: ValidationService,
		private absenceService: AbsenceService
	) {
		super();

		this.company$ = this.store.select(AbsenceSelect.getCompany);
		this.employee$ = this.store.select(AbsenceSelect.getEmployee);
		this.employees$ = this.store.select(AbsenceSelect.getEmployees);
		this.responsibleUsers$ = this.store.select(AbsenceSelect.getResponsibleUsers);
		this.responsibleLDUsers$ = this.store.select(AbsenceSelect.getResponsibleLDUsers);

		this.flagOptions = EnumUtils.toItems(FlagEnumeration);
		this.declarationTypeOptions = EnumUtils.toItems(AbsenceDeclarationTypeEnumeration);

		this.store.select(UserSelect.getPermissions).subscribe((permissions) => (this.permissions = permissions));
		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));

		this.store.dispatch(AbsencePageAction.initializeSelects());
	}

	ngOnInit(): void {
		this.documents = this.formGroup.get('documents') as FormArray;
		this.incapacities = this.formGroup.get('incapacities') as FormArray;

		this.company = this.formGroup.get('companyId') as FormControl;
		this.employee = this.formGroup.get('refEmployee') as FormControl;
		this.absenceType = this.formGroup.get('absenceType') as FormControl;
		this.declarationType = this.formGroup.get('categoryType') as FormControl;
		this.companyContractType = this.formGroup.get('companyContractType') as FormControl;

		this.employee.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (StringsUtils.isNotEmpty(id)) {
				this.store.dispatch(AbsencePageAction.fetchEmployee({ id }));
				this.employeeId = id;
				this.formGroup.updateValueAndValidity();
			}
		});
		this.company$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.formGroup.controls.isKleEnabled.patchValue(value.isKleEnabled);
			this.companyContractType.patchValue(value.contractType);
		});

		this.company.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (StringsUtils.isNotEmpty(id)) {
				this.store.dispatch(AbsencePageAction.fetchCompany({ id }));
				this.store.dispatch(AbsencePageAction.fetchEmployees({ companyId: id }));
			} else {
				this.employee.patchValue('');
				this.store.dispatch(AbsencePageAction.clearEmployees());
			}
		});
	}

	ngOnDestroy() {
		this.store.dispatch(AbsencePageAction.clearEmployees());
	}

	openEmployee(): void {
		const dialogConfig = { data: { insuredPersonId: this.employeeId } };
		this.dialogService.open(EmployeeDetailsComponent, dialogConfig);
	}

	isCompanyPopulated = (): boolean => {
		return StringsUtils.isNotEmpty(this.company.value);
	};

	searchCompanies = (term) => of(term).pipe(switchMap((query) => this.searchService.getCompanies(query)));

	searchMedicalDiagnosis = (term) => of(term).pipe(switchMap((query) => this.searchService.getMedicalDiagnosis(query, this.absenceType.value)));

	openCompany(): void {
		this.dialogService.open(CompanyDialogComponent);
	}

	filterItems = (term) => {
		if (term.length > 1) {
			return this.absenceService.filterItems(term, this.employees$);
		}
	};
}
