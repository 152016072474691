import { EnumItem } from 'nc-utils';

export const ActivityAtTimeOfAccidentConst = {
	SKIING: new EnumItem('SKIING', 'skiing'),
	FOOTBALL_ORSOCCER: new EnumItem('FOOTBALL_ORSOCCER', 'footballOrsoccer'),
	PARAGLIDING: new EnumItem('PARAGLIDING', 'paragliding'),
	SNOWBOARDING: new EnumItem('SNOWBOARDING', 'snowboarding'),
	IN_LINE_SKATING: new EnumItem('IN_LINE_SKATING', 'in-lineSkating'),
	ICE_HOCKEY: new EnumItem('ICE_HOCKEY', 'iceHockey'),
	OTHER_BALL_GAMES: new EnumItem('OTHER_BALL_GAMES', 'otherBallGames'),
	OTHER_SPORT: new EnumItem('OTHER_SPORT', 'otherSport'),
	CAR_DRIVING: new EnumItem('CAR_DRIVING', 'carDriving'),
	MOTORBIKING: new EnumItem('MOTORBIKING', 'motorbiking'),
	SCOOTER_OR_SMALL_MOPED: new EnumItem('SCOOTER_OR_SMALL_MOPED', 'scooterOrSmallMoped'),
	BICYCLE_OR_MBT: new EnumItem('BICYCLE_OR_MBT', 'bicycleOrMBT'),
	RUNED_OVER_AS_PEDESTRIAN: new EnumItem('RUNED_OVER_AS_PEDESTRIAN', 'skirunedOverAsPedestrianing'),
	OTHER_ROAD_TRAFFIC: new EnumItem('OTHER_ROAD_TRAFFIC', 'otherRoadTraffic'),
	HOUSEKEEPING_WORK: new EnumItem('HOUSEKEEPING_WORK', 'housekeepingWork'),
	DO_IT_YOURSELF_OR_MAINTENANCE_WORK: new EnumItem('DO_IT_YOURSELF_OR_MAINTENANCE_WORK', 'doItYourselfOrMaintenanceWork'),
	GARDENING_OR_FOREST_AND_AGRICULTURE_WORK: new EnumItem('GARDENING_OR_FOREST_AND_AGRICULTURE_WORK', 'gardeningOrForestAndAgricultureWork'),
	OTHER_ACTIVITIES_AT_HOME_AND_IN_THE_YARD: new EnumItem('OTHER_ACTIVITIES_AT_HOME_AND_IN_THE_YARD', 'otherActivitiesAtHomeAndInTheYard'),
	HIKING_OR_WALKING: new EnumItem('HIKING_OR_WALKING', 'hikingOrWalking'),
	OTHER_ACTIVITIES: new EnumItem('OTHER_ACTIVITIES', 'otherActivities'),
};
