export enum ExportEnumeration {
	/**
	 * Report which holds created public or internal cases for specific period.
	 */
	CASES_REPORT,
	/**
	 * Report which holds user activities of public or internal user for specific period.
	 */
	ACTIVITY_LOG_REPORT,

	/**
	 * Report which contains data from result of statistics search
	 */
	STATISTICS_REPORT,
}
