import { Injectable } from '@angular/core';
import { LabelManagementDetails } from '../model/label-management-details';

@Injectable({
	providedIn: 'root',
})
export class RequestModelFactory {
	create(label: LabelManagementDetails): any {
		return {
			code: label.code,
			de: label.de,
			en: label.en,
			fr: label.fr,
			it: label.it,
		};
	}
}
