import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class DocumentService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	private readonly GET_DOCUMENT_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/documents`;

	/**
	 * Download document
	 * @param documentId Unique identifier for document on Docman
	 */
	downloadDocument(documentId: string): Observable<HttpResponse<Blob>> {
		const url = this.GET_DOCUMENT_URL + '/' + documentId;
		return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
	}

	downloadMultipleDocuments(documentsIds: number[]): Observable<HttpResponse<Blob>> {
		const url = `${this.GET_DOCUMENT_URL}/download/${documentsIds}`;
		return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
	}

	/**
	 * Delete document from case. Logical delete.
	 * @param documentId Unique identifier for document.
	 */
	deleteDocument(documentId: string): Observable<any> {
		const data = { id: documentId };
		return this.httpClient.request('delete', this.GET_DOCUMENT_URL, { body: data });
	}
}
