import { createAction, props } from '@ngrx/store';
import { InteroperabilityRequest } from '../../model/request/interoperability-request';
import { DeclareIncidentTestRequest } from '../../model/request/declare-incident-test-request';
import { SynchronizeIncidentRequest } from '../../model/request/synchronize-incident-request';
import { KleServiceEnum } from '../../model/enums/kle-service.enum';

export const sendPing = createAction('[KLE] Send ping', props<{ serviceType: KleServiceEnum }>());
export const checkInteroperability = createAction('[KLE] Check interoperability', props<{ request: InteroperabilityRequest }>());
export const clearResponseData = createAction('[KLE] Clear results from kle');
export const declareIncident = createAction('[KLE] Declare incident', props<{ request: DeclareIncidentTestRequest }>());

export const getPaymentForCompany = createAction('[KLE] Get payment for company', props<{ absenceCode: string }>());
export const getPaymentForPerson = createAction('[KLE] Get payment for person', props<{ absenceCode: string }>());
export const getPersonMutated = createAction('[KLE] Get person mutated', props<{ absenceCode: string }>());
export const getIncident = createAction('[KLE] Get incident data', props<{ absenceCode: string }>());
export const synchronizeIncident = createAction('[KLE] Synchronize incident', props<{ request: SynchronizeIncidentRequest }>());
export const getAdditionsPerson = createAction('[KLE] Get additions person', props<{ absenceCode: string }>());
export const clearAdditionsPerson = createAction('[KLE] Clear additions person from store');
export const getMedicalInstitution = createAction('[KLE] Get medical institution', props<{ id: number }>());
export const clearTreatmentsForm = createAction('[KLE] Clear treatments form');
export const getDialogMessage = createAction('[KLE] Get dialog message', props<{ absenceCode: string; storyId: string }>());
export const clearDialogMessage = createAction('[KLE] Clear dialog message');
export const getSettlement = createAction('[KLE] Get settlement', props<{ absenceCode: string; settlementId: string }>());
export const sendSettlements = createAction('[KLE] Send settlements to EasyTemp', props<{ absenceCode: string }>());
export const generatePDF = createAction('[KLE] Generate pdf settlement', props<{ absenceCode: string; settlementId: string }>());
export const getCompanies = createAction('[KLE] Get companies for hr user', props<{ companyIds: number[] }>());
export const fetchRegisterOrganizationModel = createAction('[KLE] Fetch company registration model', props<{ companyId: number }>());
export const registerOrganization = createAction('[KLE] Register organization', props<{ companyId: number }>());
export const getCertificateRequestId = createAction('[KLE] Get CertificateRequestId', props<{ companyId: number }>());
export const getResult = createAction('[KLE] Get result for register organization', props<{ companyId: number }>());
export const signCertificate = createAction('[KLE] Sign certificate', props<{ companyId: number; password: string }>());
export const renewCertificate = createAction('[KLE] Renew certificate', props<{ companyId: number }>());
export const generateAccidentCertificate = createAction('[KLE] Generate accident certificate', props<{ absenceCode: string }>());
export const generatePharmacyCertificate = createAction('[KLE] Generate pharmacy certificate', props<{ absenceCode: string }>());
export const shouldFetchTimetable = createAction('[KLE] Should fetch timetable', props<{ absenceCode: string }>());
export const fetchTimetable = createAction('[KLE] Fetch timetable', props<{ absenceCode: string }>());

export const restartKleState = createAction('[KLE] Restart kle state');
