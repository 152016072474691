import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { AnnualSalaryService } from '../../service/annual-salary.service';
import { TableData } from 'nc-datatable';
import { BehaviorSubject, Observable, of, takeUntil } from 'rxjs';
import { KleKindOfSalary } from '../../model/kle-kind-of-salary.model';
import { KleInsuredSalaryPast } from '../../model/kle-insured-salary-past';
import { BaseComponent } from 'nc-shared';
import { KleService } from '../../service/kle.service';
import { ApiDateTimeFormat, dateToString, DialogService, DialogType, parseDate } from 'nc-utils';
import { Store } from '@ngrx/store';
import { KleSelect } from '../../state/action';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'ea-annual-salary',
	templateUrl: './annual-salary.component.html',
})
export class AnnualSalaryComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() isBranchHr: boolean;

	annualSalaryGroup = this.formBuilder.group({
		accidentStartDate: [null as moment.Moment, Validators.required],
		incidentMonth: ['', Validators.required],
		contractValidAsOf: [null as moment.Moment, Validators.required],
		annualSalaryTotal: [null as number, Validators.required],
		manualAdditions: this.formBuilder.array([] as KleKindOfSalary[]),
	});
	salaryBaseGroup = this.formBuilder.group({
		basicWages: [null as number],
		familyIncomeSupplement: [null as number],
		vacationAndPublicHolidayCompensation: [null as number],
		otherSupplements: [null as number],
		gratuity: [null as number],
	});
	hourlyPayGroup = this.formBuilder.group({
		earnings13Th: [null as number],
		hourlySalary: [null as number],
		vacationAndPublicHolidayCompensation: [null as number],
	});

	pastGroup = this.formBuilder.group({
		pastAnnualTotal: [null as number],
		salary: [this.formBuilder.array([] as KleInsuredSalaryPast[])],
	});

	manualAdditions$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	presentDetail$: Observable<TableData>;

	absenceCode: string;

	constructor(
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private annualSalaryService: AnnualSalaryService,
		private kleService: KleService,
		private dialogService: DialogService,
		private store: Store
	) {
		super();
		this.route.params.subscribe((params) => (this.absenceCode = params.code));
	}

	ngOnInit(): void {
		this.annualSalaryService.initialize(this.annualSalaryGroup.controls.manualAdditions as FormArray, this.manualAdditions$);
		this.formGroup.addControl(KleFormGroupEnum.ANNUAL_SALARY, this.annualSalaryGroup);

		this.store
			.select(KleSelect.getIncident)
			.pipe(filter((incident) => incident != null))
			.subscribe((incident) => {
				if (incident.relapseCaseId) {
					const approximateOrExactDateOnRelapse = incident.approximateOrExactDateOnRelapse;
					if (incident.exact) {
						this.annualSalaryGroup.controls.accidentStartDate.patchValue(parseDate(approximateOrExactDateOnRelapse, ApiDateTimeFormat));
					} else {
						this.annualSalaryGroup.controls.accidentStartDate.patchValue(parseDate(approximateOrExactDateOnRelapse.split(' ')[0]));
					}
				} else {
					let accidentDate = incident.accidentDate;
					if (incident.exact) {
						this.annualSalaryGroup.controls.accidentStartDate.patchValue(parseDate(accidentDate, ApiDateTimeFormat));
					} else {
						this.annualSalaryGroup.controls.accidentStartDate.patchValue(parseDate(accidentDate.split(' ')[0]));
					}
				}
			});
	}

	getSalary = () => {
		this.kleService
			.getAnnualSalary(this.absenceCode, dateToString(this.annualSalaryGroup.controls.accidentStartDate.value))
			.pipe(takeUntil(this.destroy$))
			.subscribe((salary) => {
				if (salary) {
					if (salary.manualAdditions) {
						this.annualSalaryService.populateData(this.annualSalaryGroup.controls.manualAdditions as FormArray, salary.manualAdditions);
					}
					this.annualSalaryGroup.patchValue(salary);
					this.hourlyPayGroup.patchValue(salary.present?.hourlyPay);
					this.salaryBaseGroup.patchValue(salary.statistic);
					const presentDetailsTableData = { records: [] };
					salary.present?.detail?.forEach((lindOfSalary) => {
						presentDetailsTableData.records.push(lindOfSalary);
					});
					this.presentDetail$ = of(presentDetailsTableData);
					this.pastGroup.patchValue(salary.past);
				}
				this.dialogService.close(DialogType.PROCESSING);
			});
	};
}
