import { EnumItem } from 'nc-utils';

export const OtherInsuranceEnumeration = {
	SUVA: new EnumItem('SUVA', 'suva'),
	OTHER_COMPULSORY_ACCIDENT_INSURANCE: new EnumItem('OTHER_COMPULSORY_ACCIDENT_INSURANCE', 'otherCompulsoryAccidentInsurance'),
	KTG_AMC: new EnumItem('KTG_AMC', 'ktgAmc'),
	IV_AI: new EnumItem('IV_AI', 'ivAi'),
	AHV_AVS: new EnumItem('AHV_AVS', 'ahvAvs'),
	OCCUPATIONAL_PREVENTION_ESTABLISHMENT: new EnumItem('OCCUPATIONAL_PREVENTION_ESTABLISHMENT', 'occupationalPreventionEstablishment'),
	MILITARY_INSURANCE: new EnumItem('MILITARY_INSURANCE', 'militaryInsurance'),
	UNEMPLOYMENT_INSURANCE: new EnumItem('UNEMPLOYMENT_INSURANCE', 'unemploymentInsurance'),
	MATERNETY_IDEMNITY: new EnumItem('MATERNETY_IDEMNITY', 'maternetyIdemnity'),
};
