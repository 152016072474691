import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { UserSelect } from '../state/action';
import { map } from 'rxjs/operators';
import { StringsUtils } from 'nc-utils';

@Injectable({
	providedIn: 'root',
})
export class LoginGuard implements CanActivate {
	constructor(private router: Router, private store: Store<State>) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(UserSelect.getAccessToken).pipe(
			map((token) => {
				const hasToken = StringsUtils.isNotEmpty(token);

				if (!hasToken) {
					this.router.navigateByUrl('/');
				}

				return hasToken;
			})
		);
	}
}
