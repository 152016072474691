import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	templateUrl: './delete-confirmation-dialog.component.html',
	styleUrls: ['./delete-confirmation-dialog.component.scss'],
})
export class DeleteConfirmationDialogComponent {
	id: string;
	title = 'delete';
	message1 = 'deleteConfirmation';
	message2 = 'deleteConfirmationNote';
	deleteEntityName: string;

	constructor(@Inject(MAT_DIALOG_DATA) data) {
		this.id = data?.id;
		this.title = data.title ? data.title : this.title;
		this.message1 = data.message1 ? data.message1 : this.message1;
		this.message2 = data.message2 ? data.message2 : this.message2;
		this.deleteEntityName = data.deleteEntityName ? data.deleteEntityName : '';
	}
}
