<form [formGroup]="processControlFormGroup" fxFill fxLayout="column">
	<div fxLayout="row" fxLayoutGap="20px">
		<nc-toggle-buttons
			label="incidentForCompany"
			[formGroup]="processControlFormGroup"
			[options]="incidentForCompanyEnum"
			formControlName="closeType"
		>
		</nc-toggle-buttons>
	</div>
	<div fxLayout="row" fxLayoutGap="20px" *ngIf="isCloseTypeCancel()">
		<nc-textarea
			[required]="true"
			id="cancel-reason"
			[formGroup]="processControlFormGroup"
			formControlName="cancel"
			label="cancelReason"
			maxLength="300"
			[ngClass]="'margin-top-10'"
			fxFlex="100"
		>
		</nc-textarea>
	</div>
	<div class="divider-containers"></div>
	<h3>
		<nc-label id="changeIdentities" value="changeIdentities"></nc-label>
	</h3>
	<div fxLayout="row" fxLayoutGap="20px">
		<nc-date-time-picker
			[formGroup]="processControlFormGroup"
			formControlName="changeIdentitiesValidAsOf"
			label="validAsOf"
		></nc-date-time-picker>
	</div>
	<div fxLayout="row" fxLayoutGap="20px">
		<ea-container title="company" fxFlex="50">
			<div fxLayout="row" fxLayoutGap="20px">
				<h3 fxFlex="50">
					<nc-label id="companyOld" value="old"></nc-label>
				</h3>
				<h3 fxFlex="50">
					<nc-label id="companyNew" value="new"></nc-label>
				</h3>
			</div>
			<div fxLayout="row" fxLayoutGap="20px">
				<nc-input
					label="uIDBFS"
					formControlName="companyOldUidBfs"
					[formGroup]="processControlFormGroup"
					fxFlex="50"
					[isReadonly]="true"
				></nc-input>
				<nc-input label="uIDBFS" formControlName="companyNewUidBfs" [formGroup]="processControlFormGroup" fxFlex="50"></nc-input>
			</div>
		</ea-container>
		<ea-container title="institution" fxFlex="50">
			<h3>
				<nc-label id="institutionOld" value="old"></nc-label>
			</h3>
			<div fxLayout="row" fxLayoutGap="20px">
				<nc-input
					label="customerIdentity"
					formControlName="institutionOldCustomerIdentity"
					[formGroup]="processControlFormGroup"
					fxFlex="50"
					[isReadonly]="true"
				></nc-input>
				<nc-input
					label="contractIdentity"
					formControlName="institutionOldContractIdentity"
					[formGroup]="processControlFormGroup"
					fxFlex="50"
					[isReadonly]="true"
				></nc-input>
			</div>
			<h3>
				<nc-label id="institutionNew" value="new"></nc-label>
			</h3>
			<div fxLayout="row" fxLayoutGap="20px">
				<nc-input
					label="customerIdentity"
					formControlName="institutionNewCustomerIdentity"
					[formGroup]="processControlFormGroup"
					fxFlex="50"
				></nc-input>
				<nc-input
					label="contractIdentity"
					formControlName="institutionNewContractIdentity"
					[formGroup]="processControlFormGroup"
					fxFlex="50"
				></nc-input>
			</div>
		</ea-container>
	</div>
</form>
