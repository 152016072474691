import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { EventQueueService } from '../../service/event-queue.service';
import { AppEvent } from '../../model/app-event.model';
import { BroadcastEventType } from '../../enumeration/broadcast-event-type.enumeration';

@Component({
	selector: 'ea-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	@Input() formGroup: FormGroup;
	@Input() saveButtonLabel = 'save';
	@Input() backButtonLabel = 'back';
	@Input() saveAndSendButtonLabel = 'saveAndSend';
	@Input() deleteDraftLabel = 'deleteDraft';
	@Input() displaySaveButton = true;
	@Input() displayBackButton = true;
	@Input() displaySaveAndSendButton = false;
	@Input() onSave: () => void;
	@Input() onBack: () => void;
	@Input() onSaveAndSend: () => void;

	constructor(private location: Location, private eventQueue: EventQueueService) {
		this.onBack = this.onBack ?? this.back;
	}

	save(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.ON_SAVE));
		// TODO: - implement BroadcastEventType.ON_SAVE subscription to all components that are using ae-footer and delete onSave input
		this.onSave ? this.onSave() : null;
	}

	saveAndSend(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.ON_SAVE_AND_SEND));
		this.onSaveAndSend ? this.onSaveAndSend() : null;
	}
	deleteDraft(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.DELETE_DRAFT));
		this.deleteDraft ? this.deleteDraft() : null;
	}

	private back(): void {
		this.location.back();
	}
}
