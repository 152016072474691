<form [formGroup]="personMutatedGroup" fxFill fxLayout="column">
	<div fxLayout="row" fxLayoutGap="10px">
		<nc-submit-button
			id="getPersonalData"
			buttonLabel="fetch"
			(buttonClick)="getPersonalData()"
			dialogLabel="processing"
			[ngClass]="'margin-bottom-15'"
		></nc-submit-button>
	</div>
	<div fxLayout="row" fxLayoutGap="20px">
		<ea-container title="personalData" fxFlex="50">
			<div fxLayout="row" fxLayoutGap="20px">
				<nc-input
					[isReadonly]="isReadOnly"
					id="first-name"
					[formGroup]="personMutatedGroup"
					formControlName="firstName"
					label="firstName"
					[ngClass]="'margin-top-15'"
				></nc-input>
				<nc-input
					[isReadonly]="isReadOnly"
					id="last-name"
					[formGroup]="personMutatedGroup"
					formControlName="lastName"
					label="lastName"
					[ngClass]="'margin-top-15'"
				></nc-input>
			</div>
			<div fxLayout="row" fxLayoutGap="20px">
				<nc-datepicker
					[isReadonly]="isReadOnly"
					id="valid-as-of-date"
					[formGroup]="personMutatedGroup"
					formControlName="validAsOf"
					label="validAsOf"
				>
				</nc-datepicker>
				<nc-datepicker
					[isReadonly]="isReadOnly"
					id="withdrawal-date"
					[formGroup]="personMutatedGroup"
					formControlName="withdrawalDate"
					label="withdrawalDate"
				>
				</nc-datepicker>
			</div>
		</ea-container>
		<ea-container title="address" fxFlex="50">
			<div formGroupName="address">
				<div fxLayout="row" fxLayoutGap="20px">
					<nc-input
						[isReadonly]="isReadOnly"
						id="complementary-line"
						[formGroup]="personMutatedGroup.controls.address"
						formControlName="complementaryLine"
						label="complementaryLine"
						[ngClass]="'margin-top-15'"
					></nc-input>
					<nc-input
						[isReadonly]="isReadOnly"
						id="street"
						[formGroup]="personMutatedGroup.controls.address"
						formControlName="street"
						label="street"
						[ngClass]="'margin-top-15'"
					></nc-input>
				</div>
				<div fxLayout="row" fxLayoutGap="20px">
					<nc-input
						[isReadonly]="isReadOnly"
						id="postbox"
						[formGroup]="personMutatedGroup.controls.address"
						formControlName="postbox"
						label="postbox"
					></nc-input>
					<nc-input
						[isReadonly]="isReadOnly"
						id="locality"
						[formGroup]="personMutatedGroup.controls.address"
						formControlName="locality"
						label="locality"
					></nc-input>
				</div>
				<div fxLayout="row" fxLayoutGap="20px">
					<nc-input
						[isReadonly]="isReadOnly"
						id="zip-code"
						[formGroup]="personMutatedGroup.controls.address"
						formControlName="zipCode"
						label="zipCode"
					></nc-input>
					<nc-input
						[isReadonly]="isReadOnly"
						id="city"
						[formGroup]="personMutatedGroup.controls.address"
						formControlName="city"
						label="city"
					></nc-input>
				</div>
				<div fxLayout="row" fxLayoutGap="20px">
					<nc-input
						[isReadonly]="isReadOnly"
						id="country"
						[formGroup]="personMutatedGroup.controls.address"
						formControlName="country"
						label="country"
					></nc-input>
				</div>
			</div>
		</ea-container>
	</div>
</form>
