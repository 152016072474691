import { Injectable } from '@angular/core';
import { Environment } from './environment';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentLoader {
	private environment: Environment;

	private readonly OK = 200;
	private readonly GET = 'GET';
	private readonly CONFIG_URL = 'app/assets/app-config.json';

	private load() {
		const request = new XMLHttpRequest();
		request.open(this.GET, this.CONFIG_URL, false);
		request.send();

		this.environment = request.status === this.OK ? this.parse(request.response) : new Environment();
	}

	private parse(response: string): Environment {
		const result = JSON.parse(response) as Environment;
		result.production = typeof result.production === 'string' ? result.production === 'true' : result.production;
		return result;
	}

	getEnvironment(): Environment {
		if (!this.environment) {
			this.load();
		}

		return this.environment;
	}
}
