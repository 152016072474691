import { createAction, props } from '@ngrx/store';
import { AuthorityTypeEnumeration } from '../../model/enumeration/authority-type.enumeration';
import { AuthorityModel } from '../../model/authority.model';

export const saveAuthority = createAction('[Authority] Save authority', props<{ authority: AuthorityModel }>());
export const getAuthorities = createAction('[Authority] Get authorities', props<{ authorityType: AuthorityTypeEnumeration }>());
export const clearAuthority = createAction('[Authority] Clear authority');
export const setCurrentAuthorityType = createAction(
	'[Authority] Set current authority type',
	props<{ authorityType: AuthorityTypeEnumeration; urlPath: string }>()
);
export const openDeleteConfirmationDialog = createAction(
	'[Authority] Open delete confirmation dialog',
	props<{ authorityId: number; authorityType: AuthorityTypeEnumeration }>()
);
export const deleteAuthority = createAction(
	'[Authority] Delete authority',
	props<{ authorityId: number; authorityType: AuthorityTypeEnumeration }>()
);
export const getAuthority = createAction('[Authority] Get authority', props<{ authorityId: number; authorityType: AuthorityTypeEnumeration }>());
