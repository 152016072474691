import { Component, Inject } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { FormUtils } from 'nc-utils';
import { throwError } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { AppConstants } from 'src/app/app.constants';
import { ManualAdditionFormComponent } from '../manual-addition-form/manual-addition-form.component';

@Component({
	selector: 'ea-manual-addition-dialog',
	templateUrl: './manual-addition-dialog.component.html',
})
export class ManualAdditionDialogComponent extends BaseComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: { formGroup: FormGroup }, private dialogRef: MatDialogRef<ManualAdditionFormComponent>) {
		super();
	}

	submit() {
		if (this.data.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.data.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		return this.dialogRef.close({ success: true, data: this.data.formGroup });
	}
}
