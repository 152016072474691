import { createAction, props } from '@ngrx/store';

export const openActiveDashboard = createAction('[Dashboard] Open dashboard page');
export const openArchivedDashboard = createAction('[Dashboard] Open archived cases page');
export const setSelectedUser = createAction('[Dashboard] Set selected user', props<{ selectedUser: string }>());
export const saveSelectedUser = createAction(
	'[Dashboard] Save selected user to local storage',
	props<{ selectedUser: string; loggedUser: string }>()
);

export const getTasks = createAction('[Dashboard] Get tasks', props<{ selectedUser: string }>());
export const getActiveAbsences = createAction('[Dashboard] Get active absences', props<{ selectedUser: string }>());
export const getArchivedAbsences = createAction('[Dashboard] Get archived absences', props<{ selectedUser: string }>());
export const getMilestones = createAction('[Dashboard] Get milestones', props<{ numberOfDays: number[]; selectedUser: string }>());
export const getHrActiveAbsence = createAction('[Dashboard] Get HR active absence', props<{ companyIds: number[] }>());
export const getCasesToBeApproved = createAction('[Dashboard] Get HR absences to be approved', props<{ companyIds: number[] }>());
export const getAwaitStories = createAction('[Dashboard] Get await stories', props<{ companyIds: number[] }>());
export const getHrStatistic = createAction('[Dashboard] Get HR statistic', props<{ companyIds: number[] }>());
export const getHrCompanies = createAction('[Dashboard] Get HR companies', props<{ companyIds: number[] }>());
export const getHrArchivedCases = createAction('[Dashboard] Get HR archived cases', props<{ companyIds: number[] }>());
