import { OperationTypeEnumeration } from './enumeration/operation-type.enumeration';
import { DocumentTypeEntityAccessEnumeration } from './enumeration/document-type-entity-access.enumeration';
import { AccessTypeEnumeration } from './enumeration/access-type.enumeration';

export class DocumentTypeModel {
	id: number;
	codes?: Array<string>;
	accessType?: AccessTypeEnumeration;
	entityType: DocumentTypeEntityAccessEnumeration;
	operation: OperationTypeEnumeration;
}
