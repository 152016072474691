import { Injectable } from '@angular/core';
import { DialogMessage } from '../../model/dialog-message';

@Injectable({
	providedIn: 'root',
})
export class ContactPersonMessageRequestFactory {
	create(contactPersonData: any, dialogMessage: DialogMessage): any {
		return {
			...contactPersonData,
			contactPersonMessage: {
				firstName: {
					...dialogMessage.contactPersonMessage.firstName,
					answerValue: contactPersonData.firstName,
				},
				lastName: {
					...dialogMessage.contactPersonMessage.lastName,
					answerValue: contactPersonData.lastName,
				},
				phone: {
					...dialogMessage.contactPersonMessage.phone,
					answerValue: contactPersonData.phone,
				},
				function: {
					...dialogMessage.contactPersonMessage.function,
					answerValue: contactPersonData.function,
				},
				email: {
					...dialogMessage.contactPersonMessage.email,
					answerValue: contactPersonData.email,
				},
			},
		};
	}
}
