<div fxFill fxLayout="column">
	<ea-header-info [showCompany]="true" [showAbsenceCode]="true"></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<nc-datatable [id]="'history'" [columns]="historyColumns" [data$]="history$" [hasPaginator]="false" [filterConfig]="filterConfig">
			</nc-datatable>
		</nc-form-content>
	</nc-main-content>
	<ea-history-footer [code]="code"></ea-history-footer>
</div>
