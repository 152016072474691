import { createAction, props } from '@ngrx/store';
import { DocumentTypeTranslation } from '../../model/document-type-translation';

export const getDocumentTypes = createAction('[DocumentTypes] Get document types');
export const getDocumentType = createAction('[DocumentTypes] Get document type', props<{ currentDocumentTypeCode: string }>());
export const setCurrentDocumentType = createAction(
	'[DocumentTypes] Set selected document type',
	props<{ documentType: DocumentTypeTranslation[] }>()
);
export const clearCurrentDocumentType = createAction('[DocumentTypes] Clear selected document type');
export const saveDocumentType = createAction('[DocumentTypes] Save document type', props<{ documentType: any }>());
