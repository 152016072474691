<div id="login-footer" fxLayout="row" fxLayout.lt-md="column">
	<div fxFlex="50" fxFlex.lt-md="40" fxLayout="column">
		<div class="login-footer-title">{{ 'usefulLinks' | translate }}</div>
		<div fxLayout.lt-md="row" fxLayoutAlign.lt-md="space-between">
			<div><a target="_blank" class="login-footer-text" href="https://tellco.ch/{{ languageCode }}tellco-pk">Tellco pk</a></div>
		</div>
	</div>
	<div fxFlex="50" fxLayout="column" class="company-info" fxLayoutGap="20" fxLayoutAlign.lt-md="space-between">
		<div>
			<a
				target="_blank"
				[href]="
					'https://www.google.com/maps/place/Tellco+pkPRO/@47.0213194,8.648995,17z/data=!3m1!4b1!4m5!3m4!1s0x47854e3f03425fd1:0x4107c8d95220f585!8m2!3d47.021312!4d8.6511817'
				"
			>
				<div *ngIf="!isMobile">
					<div class="login-footer-text">{{ 'tellcoCompanyName' | translate }}</div>
					<div class="login-footer-text">{{ 'AbsenceTemplate_tellcoStreet' | translate }}</div>
					<div class="login-footer-text">{{ 'tellco.canton' | translate }}</div>
				</div>
				<mat-icon *ngIf="isMobile">location_on</mat-icon>
			</a>
		</div>
		<br />
		<div>
			<a href="tel: +41 68 443 10 00">
				<div class="login-footer-text">{{ 'tellco.phone' | translate }}</div>
			</a>
			<a href="mailto: info@tellcopk.ch">
				<div class="login-footer-text">{{ 'tellco.email' | translate }}</div>
			</a>
		</div>
	</div>
</div>
