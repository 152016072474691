import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetUserNotificationOptionsResponse } from '../model/response/get-user-notification-options.response';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	private readonly NOTIFICATIONS_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/notifications`;

	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets notifications for selected user
	 */
	getNotifications(userId: number): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(`${this.NOTIFICATIONS_URL}/user/${userId}`);
	}

	/**
	 * Gets number of notifications
	 */
	getNumberOfNotificationsForLoggedInUser(): Observable<any> {
		return this.httpClient.get<any>(`${this.NOTIFICATIONS_URL}/getNumberOfNotificationsForLoggedInUser`);
	}

	/**
	 * Delete notification
	 * @param id notification id
	 */
	deleteNotification(id: number): Observable<any> {
		return this.httpClient.delete<any>(`${this.NOTIFICATIONS_URL}/${id}`);
	}

	/**
	 * Mark all notifications as read
	 */
	markAllAsRead(userId: number): Observable<any> {
		return this.httpClient.delete<any>(`${this.NOTIFICATIONS_URL}/user/${userId}`);
	}

	/**
	 * Gets all cm and ld users with notifications
	 */
	getUsers(): Observable<GetUserNotificationOptionsResponse> {
		return this.httpClient.get<GetUserNotificationOptionsResponse>(`${this.NOTIFICATIONS_URL}/users`);
	}
}
