import { StatisticResult } from '../model/statistic-result';
import { ChartData } from '../../shared/model/chart/chart-data';

export class ChartTransformer {
	public static transform = (statistic: StatisticResult) => {
		return {
			overallChartData: [
				new ChartData('illness', statistic.overall.sickness.count),
				new ChartData('accident', statistic.overall.accident.count),
				new ChartData('pregnancy', statistic.overall.pregnancy.count),
			],
			totalCmChartData: [
				new ChartData('caseManagementSuccessful', statistic.overall.cmSuccessful.count),
				new ChartData('caseManagementNotSuccessful', statistic.overall.cmFailed.count),
			],
			reasonsForSuccessfulCmChartData: [
				new ChartData('coordinationALV', statistic.overall.cmSuccessfulCoordinationAVL.count),
				new ChartData('reintegration', statistic.overall.cmSuccessfulReintegration.count),
				new ChartData('retirement', statistic.overall.cmSuccessfulRetirement.count),
			],
			reasonsForNotSuccessfulCmChartData: [
				new ChartData('notWanted', statistic.overall.cmFailedNotWanted.count),
				new ChartData('notAvailable', statistic.overall.cmFailedNotAvailable.count),
				new ChartData('notUseful', statistic.overall.cmFailedNotUseful.count),
			],
			averageAbsenceDurationPerIllnessChartData: [
				new ChartData('mentalIllness', statistic.overall.sicknessPsychic.averageDuration),
				new ChartData('backProblems', statistic.overall.sicknessBack.averageDuration),
				new ChartData('cardiovascular', statistic.overall.sicknessCardiovascular.averageDuration),
				new ChartData('nervousSystem', statistic.overall.sicknessNervousSystem.averageDuration),
				new ChartData('otherOrUnknown', statistic.overall.sicknessOtherOrUnknown.averageDuration),
				new ChartData('notDiagnosed', statistic.overall.sicknessNotDiagnosed.averageDuration),
			],
			averageAbsenceDurationPerAccidentChartData: [
				new ChartData('work', statistic.overall.accidentWork.averageDuration),
				new ChartData('freeTime', statistic.overall.accidentFreeTime.averageDuration),
			],
		};
	};
}
