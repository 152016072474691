<div id="login-footer" fxLayout="row" fxLayout.lt-md="column">
	<div fxFlex="50" fxFlex.lt-md="40" fxLayout="column">
		<div class="login-footer-title">{{ 'usefulLinks' | translate }}</div>
		<div fxLayout.lt-md="row" fxLayoutAlign.lt-md="space-between">
			<div>
				<a target="_blank" class="login-footer-text" href="https://www.enovetic.ch/">{{ 'enovetic ag' | translate }}</a>
			</div>
			<div><a target="_blank" class="login-footer-text" href="https://tellco.ch/{{ languageCode }}tellco-pk">Tellco pk</a></div>
			<div>
				<a target="_blank" class="login-footer-text" href="https://www.swica.ch/{{ languageCode }}">{{ 'swica' | translate }}</a>
			</div>
			<div>
				<a target="_blank" class="login-footer-text" href="{{ 'helpdeskEAbsenceURL' | translate }}">{{ 'helpdeskEAbsence' | translate }}</a>
			</div>
		</div>
	</div>
	<div fxFlex="50" fxFlex.lt-md="60" fxLayout="column">
		<div fxLayoutAlign="center" fxLayoutAlign.lt-md="left" class="login-footer-title">
			{{ 'headquartersAndBranches' | translate }}
		</div>
		<div fxLayout="row" fxLayoutAlign="space-around" fxLayout.lt-md="column">
			<div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-around" fxLayoutAlign.lt-md="space-between">
				<ea-company-branch
					city="{{ 'company.branch.rotkreuz' | translate }}"
					postCode="{{ 'company.branch.rotkreuz.postCode' | translate }}"
					isHeadquarters="true"
					[isMobile]="isMobile"
					address="{{ 'company.branch.rotkreuz.address' | translate }}"
					link="https://www.google.com/maps/place/Blegi+5,+6343+Risch-Rotkreuz/@47.1469513,8.436867,17z/data=!3m1!4b1!4m6!3m5!1s0x4790017f67a0471f:0x9e533bcf8ab4041d!8m2!3d47.1469477!4d8.4394419!16s%2Fg%2F11l6l3j259"
					phone="t: {{ 'hr.sidemenu.footer.phone' | translate }}"
				>
				</ea-company-branch>
			</div>
			<div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-around" fxLayoutAlign.lt-md="space-between">
				<ea-company-branch
					city="{{ 'company.branch.lausanne' | translate }}"
					postCode="{{ 'company.branch.lausanne.postCode' | translate }}"
					[isMobile]="isMobile"
					address="{{ 'company.branch.lausanne.address' | translate }}"
					link="https://www.google.com/maps/place/Av.+William-Fraisse+3,+1006+Lausanne,+Switzerland/@46.5158959,6.6236464,17z/data=!3m1!4b1!4m5!3m4!1s0x478c2fd31c4686e9:0x43b5821b32d4332e!8m2!3d46.5158922!4d6.6258351"
				>
				</ea-company-branch>
			</div>
		</div>
	</div>
</div>
