import { createAction, props } from '@ngrx/store';
import { SaveResponse } from 'nc-utils';
import { MilestoneSettingsModel } from '../../model/milestone-settings';

export const getAllMilestoneSettingsSuccess = createAction(
	'[MilestoneSettings] Get all milestone settings success',
	props<{ milestoneSettings: { [key: string]: string }[] }>()
);
export const getSingleMilestoneSettingsSuccess = createAction(
	'[MilestoneSettings] Get single milestone settings success',
	props<{ milestoneSettings: MilestoneSettingsModel }>()
);
export const getDocumentsCheckListSuccess = createAction(
	'[MilestoneSettings] Get documents check list success',
	props<{ documentsCheckList: { [key: string]: string }[] }>()
);
export const saveMilestoneSettingSuccess = createAction('[MilestoneSettings] Save muilestone setting', props<{ response: SaveResponse }>());
export const recalculateStatusesSuccess = createAction('[MilestoneSettings] Recalculate statuses');
