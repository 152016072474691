import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';
import { StringsUtils } from 'nc-utils';

const OK = null;

const REQUIRED = { required: true };

/**
 * Checks if passwords are equal on reset password form
 */
const isCompanyRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const companyControl = control.get('company');
		const typeControl = control.get('type');

		if (typeControl.value !== 'OVERALL' && StringsUtils.isEmpty(companyControl.value)) {
			FormValidation.addError(companyControl, REQUIRED);
		} else {
			FormValidation.removeError(companyControl, REQUIRED);
		}

		return OK;
	};
};

const isPersonRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const personControl = control.get('person');
		const typeControl = control.get('type');

		if (typeControl.value === 'EMPLOYEE' && StringsUtils.isEmpty(personControl.value)) {
			FormValidation.addError(personControl, REQUIRED);
		} else {
			FormValidation.removeError(personControl, REQUIRED);
		}

		return OK;
	};
};

export { isCompanyRequired, isPersonRequired };
