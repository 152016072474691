<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div
		class="footer-navigation"
		fxLayout="row wrap"
		fxLayout.lt-md="column"
		fxLayoutGap="0px"
		fxLayoutGap.lt-md="5px"
		fxLayoutAlign="space-between"
	>
		<div fxLayout="row wrap" fxLayoutGap="5px">
			<nc-button [id]="'back'" [onClick]="goBack" [isRaised]="false" label="back"> </nc-button>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="5px">
			<nc-submit-button
				[id]="'tasks'"
				(buttonClick)="reassign()"
				[disabled]="!selectFormGroup.value[0]"
				dialogLabel="processing"
				buttonLabel="reassign"
			>
			</nc-submit-button>
		</div>
	</div>
</div>
