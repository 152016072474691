import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DocumentTypesComponent } from './component/document-types.component';
import { DocumentTypesFormComponent } from './component/document-types-form/document-types-form.component';
import { DocumentTypesEffect } from './state/document-types.effect';
import { DOCUMENT_TYPES_FEATURE_STATE_NAME } from './state/action/document-types.select';
import { documentTypesReducer } from './state/document-types.reducer';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([DocumentTypesEffect]),
		StoreModule.forFeature(DOCUMENT_TYPES_FEATURE_STATE_NAME, documentTypesReducer),
	],
	declarations: [DocumentTypesComponent, DocumentTypesFormComponent],
	exports: [DocumentTypesComponent, DocumentTypesFormComponent],
})
export class DocumentTypesModule {}
