import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Option } from 'nc-shared';
import { EnumUtils } from 'nc-utils';
import { Company } from '../../../../shared/model/company';
import { FlagEnumeration } from '../../../../shared/enumeration/flag.enumeration';
import { EmploymentSubjectEnumeration } from '../../../model/enumeration/employment-subject.enumeration';
import { EmploymentRegistrationEnumeration } from '../../../model/enumeration/employment-registration.enumeration';
import { AbsenceTypeEnumeration } from '../../../../shared/enumeration/absence-type.enumeration';

@Component({
	selector: 'ea-premium-details',
	templateUrl: './premium-details.component.html',
	styleUrls: ['./premium-details.component.scss'],
})
export class PremiumDetailsComponent implements OnInit, OnDestroy {
	@Input() formGroup: FormGroup;
	@Input() company: Company;
	@Input() isReadonly: boolean;

	flags: Option[];
	employmentSubject: Option[];
	employmentRegistration: Option[];

	numberMask = {
		mask: Number,
		scale: 2,
		signed: false,
		thousandsSeparator: '',
		padFractionalZeros: false,
		normalizeZeros: true,
		radix: '.',
		mapToRadix: ['.'],
		min: 0,
		max: 1000000000,
	};

	ABSENCE_TYPE_ENUMERATION = AbsenceTypeEnumeration;

	constructor() {
		this.flags = EnumUtils.toItems(FlagEnumeration);
		this.employmentSubject = EnumUtils.toItems(EmploymentSubjectEnumeration);
		this.employmentRegistration = EnumUtils.toItems(EmploymentRegistrationEnumeration);
	}

	ngOnInit(): void {
		const numberOfChildren = this.formGroup.get('numberOfChildren');
		const hasChildren = this.formGroup.get('hasChildrenUnder18OrInEducationUpTo25');
		const numberOfPossibleWorkHours = this.formGroup.get('numberOfPossibleWorkHours');
		const hasReturnedToWork = this.formGroup.get('hasReturnedToWork');
		const absenceType = this.formGroup.get('absenceType');
		const hasAccidentInsurer = this.formGroup.get('hasAccidentInsurance');
		const accidentCompany = this.formGroup.get('accidentInsuranceCompany');
		const accidentClaimNumber = this.formGroup.get('accidentInsuranceSinisterReference');

		const hasSicknessInsurer = this.formGroup.get('hasDailySicknessBenefitsInsurance');
		const sicknessCompany = this.formGroup.get('dailySicknessBenefitsInsuranceCompany');
		const sicknessClaimNumber = this.formGroup.get('dailySicknessBenefitsInsuranceSinisterReference');

		hasChildren.valueChanges.subscribe((answer) => {
			if (answer === 'no') {
				numberOfChildren.patchValue('0');
			}
		});

		hasReturnedToWork.valueChanges.subscribe((answer) => {
			if (answer === 'no') {
				numberOfPossibleWorkHours.patchValue('0');
			}
		});

		hasAccidentInsurer.valueChanges.subscribe((answer) => {
			if (answer === 'no') {
				accidentCompany.patchValue('');
				accidentClaimNumber.patchValue('');
			}
		});

		hasSicknessInsurer.valueChanges.subscribe((answer) => {
			if (answer === 'no') {
				sicknessCompany.patchValue('');
				sicknessClaimNumber.patchValue('');
			} else {
				if (this.company.isSwicaInsured) {
					sicknessCompany.patchValue('SWICA');
				}
			}
		});

		absenceType.valueChanges.subscribe(() => {
			hasAccidentInsurer.patchValue('no');
			hasSicknessInsurer.patchValue('no');
		});
	}

	ngOnDestroy(): void {
		this.formGroup.patchValue({
			hasDailySicknessBenefitsInsurance: 'no',
			dailySicknessBenefitsInsuranceCompany: '',
			dailySicknessBenefitsInsuranceSinisterReference: '',

			hasAccidentInsurance: 'no',
			accidentInsuranceCompany: '',
			accidentInsuranceSinisterReference: '',

			employmentStartDate: null,
			employmentEndDate: null,
			employmentPercentage: '',
			employmentAnnualSalary: '',

			firstDeploymentDate: null,
			firstDeploymentRegistration: 'REGISTRATION_FROM_1ST_DAY',
			firstDeploymentSubject: 'SUBJECT_TO_DUTY_OF_MAINTENANCE',
			lastDeploymentDate: null,
		});
	}
}
