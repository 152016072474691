import { MenuItem } from './menu-item';
import { UserPageAction } from '../../user/state/action';

export const MenuItems: MenuItem[] = [
	new MenuItem('people', 'userAdministration', '/user-administration', ['ADMIN', 'USER_MANAGEMENT']),
	new MenuItem('watch_later', 'activityLog', '/activity-log', ['ACTIVITY_LOG_REPORT']),
	new MenuItem('local_offer', 'labelManagement', '/label/management', ['ADMIN']),
	new MenuItem('local_offer', 'ldResponsible', '/ld-responsibles', ['ADMIN']),
	new MenuItem('description', 'documentTypes', '/document-types', ['ADMIN']),
	new MenuItem(
		'security',
		'accessManagement',
		null,
		['ADMIN'],
		[
			new MenuItem('settings', 'roles', '/roles'),
			new MenuItem('settings', 'roleGroups', '/role-groups'),
			new MenuItem('settings', 'permissions', '/permissions'),
		]
	),
	new MenuItem(
		'sync_alt',
		'accessAssignment',
		null,
		['ADMIN'],
		[
			new MenuItem('settings', 'userRole', '/user/roles'),
			new MenuItem('settings', 'userRoleGroup', '/user/role-groups'),
			new MenuItem('settings', 'rolePermision', '/role/permissions'),
			new MenuItem('settings', 'roleDocumentType', '/role/document-types'),
			new MenuItem('settings', 'roleGroupRole', '/role-group/roles'),
			new MenuItem('settings', 'userAllowPermission', '/user/allow-permissions'),
			new MenuItem('settings', 'userDenyPermission', '/user/deny-permissions'),
			new MenuItem('settings', 'userAllowDocumentType', '/user/allow-document-types'),
			new MenuItem('settings', 'userDenyDocumentType', '/user/deny-document-types'),
		]
	),
	new MenuItem(
		'account_tree',
		'integration',
		null,
		['ADMIN'],
		[
			new MenuItem('refresh', 'companies', '/integration/companies'),
			new MenuItem('refresh', 'companyGroups', '/integration/company/groups'),
			new MenuItem('refresh', 'medicalInstitutions', '/integration/medical-institutions'),
		]
	),
	new MenuItem('account_tree', 'kle', '/kle', ['ADMIN']),
	new MenuItem('file_copy', 'report', '/report', ['CASES_REPORT']),
	new MenuItem('bar_chart', 'dashboard', '/dashboard', ['CASE_MANAGER', 'HR', 'BRANCH_HR']),
	new MenuItem('notifications', 'notifications', '/notifications', ['CASE_MANAGER'], null, null, null, null, 'notifications_tool_tip_text'),
	new MenuItem('add', 'newCase', '/absence/new', ['CM_CASE_EDIT', 'HR'], null, false, ['ADMIN']),
	new MenuItem('history', 'history', '/history', ['CASE_MANAGER', 'HR', 'BRANCH_HR']),
	new MenuItem('factory', 'companies', '/kle/companies', ['HR']),
	new MenuItem(
		'pie_chart',
		'statistic',
		null,
		['CASE_MANAGER', 'CM_STATISTIC'],
		[new MenuItem('pie_chart', 'excel', '/statistic/excel'), new MenuItem('pie_chart', 'word', '/statistic/word')],
		true
	),
	new MenuItem('local_offer', 'administration', '/administration', ['CASE_MANAGER', 'CM_ADMIN'], null, true),
	new MenuItem('tune', 'milestoneSettings', '/milestone/settings', ['CASE_MANAGER', 'MILESTONE_SETTINGS'], null, true),
	new MenuItem(
		'settings',
		'settings',
		null,
		[],
		[new MenuItem('settings', 'personalData', '/settings/personal-data'), new MenuItem('settings', 'changePassword', '/settings/change-password')]
	),
	new MenuItem('logout', 'logout', null, null, null, false, null, UserPageAction.checkLogout()),
];
