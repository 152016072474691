import { IncidentStatusEnumeration } from './incident-status.enumeration';
import { FlagWithUnknownEnumerationConst } from '../../shared/enumeration/flag-with-unknown.enumeration';

export class IncidentStatus {
	status: string;
	coverage: string;
	smallRemark: string;

	constructor(state: IncidentStatus) {
		this.status = IncidentStatusEnumeration[state.status].label;
		this.coverage = this.getCoverageEnumValue(state.coverage);
		this.smallRemark = state.smallRemark;
	}

	getCoverageEnumValue(coverage: string): string {
		return coverage ? FlagWithUnknownEnumerationConst[coverage].label : null;
	}
}
