import * as ApplicationState from '../../state/app.state';
import { InteroperabilityResponse } from '../model/response/interoperability-response';
import { DeclareIncidentResponse } from '../model/response/declare-incident.response';
import { IncidentContext } from '../model/incident-context';
import { AnnualSalary } from '../model/annual-salary';
import { TableData } from 'nc-datatable';
import { DialogMessage } from '../model/dialog-message';
import { Settlement } from '../model/settlement/settlement';
import { RegisterOrganization } from '../model/register-organization';
import { PersonMutated } from '../model/person-mutated';
import { Incident } from '../model/incident';
import { Treatment } from '../model/treatment';

export interface KleState {
	test: string;
	incidentContext: IncidentContext;
	incident: Incident;
	interoperability: InteroperabilityResponse;
	declareIncident: DeclareIncidentResponse;
	annualSalary: AnnualSalary;
	treatment: Treatment;
	dialogMessage: DialogMessage;
	settlement: Settlement;
	companies: TableData;
	company: RegisterOrganization;
	personMutated: PersonMutated;
	certificateRequestId: string;
}

export interface State extends ApplicationState.State {
	kle: KleState;
}

export const KLE_INITIAL_STATE: KleState = {
	test: '',
	incidentContext: null,
	incident: null,
	interoperability: null,
	declareIncident: null,
	treatment: null,
	annualSalary: null,
	dialogMessage: null,
	settlement: null,
	companies: { records: [] },
	company: null,
	certificateRequestId: null,
	personMutated: null,
};
