import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'nc-shared';
import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KlePageAction, KleSelect } from '../../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogMessageTypeEnum } from '../../../model/enums/dialog-message-type.enum';
import { AppConstants } from '../../../../app.constants';

@Component({
	templateUrl: './simple-message-form.component.html',
	styleUrls: ['./simple-message-form.component.scss'],
})
export class SimpleMessageFormComponent extends BaseComponent implements OnInit {
	isSwissdec = false;
	isReadOnly = true;

	simpleMessageGroup = this.formBuilder.group({
		title: ['', Validators.required],
		message: ['', Validators.required],
		dialogMessageType: DialogMessageTypeEnum.SIMPLE_MESSAGE.code,
		companyCaseId: this.data.absenceCode,
		previous: this.data.storyId,
	});

	constructor(
		private formBuilder: FormBuilder,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) public data: { absenceCode: string; storyId: string; formGroup: FormGroup; creator: string }
	) {
		super();
	}

	ngOnInit(): void {
		this.isSwissdec = this.data.creator === AppConstants.SWISSDEC_CREATOR;

		if (this.data.storyId != null) {
			this.store.dispatch(KlePageAction.getDialogMessage({ absenceCode: this.data.absenceCode, storyId: this.data.storyId }));
			this.store
				.select(KleSelect.getDialogMessage)
				.pipe(
					filter((message) => message != null),
					takeUntil(this.destroy$)
				)
				.subscribe((message) => {
					this.simpleMessageGroup.controls.title.patchValue(message.title);
					this.simpleMessageGroup.controls.message.patchValue(message.message);
				});
		}
	}

	submit(): void {
		const context = this.data.formGroup.controls.incidentContext.value;
		const request = { incidentContext: context, dialogMessage: this.simpleMessageGroup.getRawValue() };
		this.store.dispatch(KlePageAction.synchronizeIncident({ request }));
	}

	replay(): void {
		this.simpleMessageGroup.controls.title.reset();
		this.simpleMessageGroup.controls.message.reset();
		this.isReadOnly = false;
	}
}
