import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { NotificationsPageAction } from '../../state/action';
import { Router } from '@angular/router';

@Component({
	selector: 'ea-notifications-footer',
	templateUrl: './notifications-footer.component.html',
	styleUrls: ['./notifications-footer.component.scss'],
})
export class NotificationsFooterComponent {
	@Input() displayButton: boolean;
	@Input() selectedUser;

	constructor(private location: Location, private router: Router, private store: Store) {}

	goBack = (): void => {
		this.location.back();
	};

	markAllAsRead = (): void => {
		this.store.dispatch(NotificationsPageAction.markAllAsRead({ userId: this.selectedUser.id }));
	};
}
