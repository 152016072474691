import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormUtils } from 'nc-utils';
import { throwError } from 'rxjs';
import { AppConstants } from '../../../../app.constants';

@Component({
	templateUrl: './absence-form-dialog.component.html',
	styleUrls: ['./absence-form-dialog.component.scss'],
})
export class AbsenceFormDialogComponent implements OnInit {
	btnLabel = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: { formGroup: FormGroup }, private dialogRef: MatDialogRef<any>) {}

	ngOnInit() {
		this.btnLabel = this.data.formGroup.controls.id?.value ? 'change' : 'newAbsence';
	}

	submit = () => {
		if (this.data.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.data.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		return this.dialogRef.close({ success: true });
	};
}
