import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import { UserPageAction } from '../../state/action';
import { arePasswordsEqual } from '../../validator/reset-password.validator';

@Component({
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	formGroup: FormGroup;

	constructor(private store: Store<State>, private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group(
			{
				password: ['', [Validators.required]],
				repeatedPassword: ['', [Validators.required]],
			},
			{
				validators: [arePasswordsEqual()],
			}
		);
	}

	onSubmit = (): void => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.resetPasswordSubmit(data));
	};
}
