import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './component/login/login.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserEffect } from './state/user.effect';
import { USER_FEATURE_STATE_NAME } from './state/action/user.select';
import { userReducer } from './state/user.reducer';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { UnauthenticatedLayoutComponent } from './component/unauthenticated-layout/unauthenticated-layout.component';
import { UnauthenticatedFormContainerComponent } from './component/unauthenticated-form-container/unauthenticated-form-container.component';
import { ForgotPasswordVerificationComponent } from './component/forgot-password-verification/forgot-password-verification.component';
import { LoginVerificationComponent } from './component/login-verification/login-verification.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { CompanyBranchComponent } from './component/company-branch/company-branch.component';
import { PersonalDataComponent } from './component/personal-data/personal-data.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { EcareFooterComponent } from './component/unauthenticated-layout/ecare-footer/ecare-footer.component';
import { TellcoFooterComponent } from './component/unauthenticated-layout/tellco-footer/tellco-footer.component';
import { BannerComponent } from '../shared/component/banner/banner.component';
import { UserAdministrationModule } from '../user-administration/user-administration.module';

@NgModule({
	declarations: [
		LoginComponent,
		PersonalDataComponent,
		CompanyBranchComponent,
		ResetPasswordComponent,
		ChangePasswordComponent,
		ForgotPasswordComponent,
		LoginVerificationComponent,
		UnauthenticatedLayoutComponent,
		UnauthenticatedFormContainerComponent,
		ForgotPasswordVerificationComponent,
		EcareFooterComponent,
		TellcoFooterComponent,
		BannerComponent,
	],
	imports: [
		SharedModule,
		EffectsModule.forFeature([UserEffect]),
		StoreModule.forFeature(USER_FEATURE_STATE_NAME, userReducer),
		UserAdministrationModule,
	],
	exports: [
		LoginComponent,
		PersonalDataComponent,
		ResetPasswordComponent,
		ChangePasswordComponent,
		ForgotPasswordComponent,
		LoginVerificationComponent,
		UnauthenticatedLayoutComponent,
		UnauthenticatedFormContainerComponent,
		ForgotPasswordVerificationComponent,
		EcareFooterComponent,
		BannerComponent,
	],
})
export class UserModule {}
