import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	templateUrl: './ping-result-dialog.component.html',
})
export class PingResultDialogComponent {
	dateTime: string;
	timeDifference: number;
	label: string;

	constructor(@Inject(MAT_DIALOG_DATA) data) {
		this.dateTime = data.dateTime;
		this.timeDifference = data.timeDifference;
		this.label = data.success ? 'info' : 'error';
	}
}
