import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent, Option } from 'nc-shared';
import { EnumUtils, StringsUtils } from 'nc-utils';
import { AbsenceActivityOptionEnumeration } from '../../enum/absence-activity-option.enumeration';
import { InsuranceTypeEnumeration } from '../../enum/insurance-type.enumeration';
import { StatisticTypeEnumeration } from '../../enum/statistic-type.enumeration';
import { of, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SearchService } from '../../../shared/service/search.service';

@Component({
	selector: 'ea-statistic-filter',
	templateUrl: './statistic-filter.component.html',
	styleUrls: ['./statistic-filter.component.scss'],
})
export class StatisticFilterComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;

	insuranceTypes: Option[];
	statisticTypes: Option[];
	absenceActivityOptions: Option[];

	constructor(private store: Store, private searchService: SearchService) {
		super();
		this.insuranceTypes = EnumUtils.toItems(InsuranceTypeEnumeration);
		this.statisticTypes = EnumUtils.toItems(StatisticTypeEnumeration);
		this.absenceActivityOptions = EnumUtils.toItems(AbsenceActivityOptionEnumeration);
	}
	ngOnInit(): void {
		this.companyValueChanges();
		this.personValueChanges();
	}

	private companyValueChanges() {
		return this.formGroup.controls.company.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.formGroup.updateValueAndValidity();
		});
	}

	private personValueChanges() {
		return this.formGroup.controls.person.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.formGroup.updateValueAndValidity();
		});
	}
	isCompanyPopulated = (): boolean => {
		return StringsUtils.isNotEmpty(this.formGroup.get('company').value);
	};

	searchCompanies = (term) => of(term).pipe(switchMap((query) => this.searchService.getCompanies(query)));

	searchPersons = (term) => of(term).pipe(switchMap((query) => this.searchService.getPersons(query, this.formGroup.get('company').value)));
}
