import { ColumnBuilder, DatatableColumn } from 'nc-datatable';

export class TableColumns {
	public static OVERALL_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('rank', 'hitlist.rank'),
		ColumnBuilder.create('companyName', 'company'),
		ColumnBuilder.create('total', 'hitlist.total'),
		ColumnBuilder.create('sickness', 'hitlist.sickness'),
		ColumnBuilder.create('accident', 'hitlist.accident'),
		ColumnBuilder.create('pregnancy', 'hitlist.pregnancy'),
		ColumnBuilder.create('sicknessPsychic', 'hitlist.sicknessPsychic'),
		ColumnBuilder.create('sicknessBack', 'hitlist.sicknessBack'),
		ColumnBuilder.create('sicknessCardiovascular', 'hitlist.sicknessCardiovascular'),
		ColumnBuilder.create('sicknessNervousSystem', 'hitlist.sicknessNervousSystem'),
		ColumnBuilder.create('sicknessOtherOrUnknown', 'hitlist.sicknessOtherOrUnknown'),
		ColumnBuilder.create('sicknessNotDiagnosed', 'hitlist.sicknessNotDiagnosed'),
		ColumnBuilder.create('accidentWork', 'hitlist.accidentWork'),
		ColumnBuilder.create('accidentFreeTime', 'hitlist.accidentFreeTime'),
		ColumnBuilder.create('cmActive', 'hitlist.cmActive'),
		ColumnBuilder.create('cmArchived', 'hitlist.cmArchived'),
		ColumnBuilder.create('totalCmSuccessful', 'hitlist.cmSuccessful'),
		ColumnBuilder.create('totalCmFailed', 'hitlist.cmFailed'),
		ColumnBuilder.create('averageDuration', 'hitlist.averageDuration'),
	];

	public static COMPANY_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('total', 'hitlist.total'),
		ColumnBuilder.create('sickness', 'hitlist.sickness'),
		ColumnBuilder.create('accident', 'hitlist.accident'),
		ColumnBuilder.create('pregnancy', 'hitlist.pregnancy'),
		ColumnBuilder.create('sicknessPsychic', 'hitlist.sicknessPsychic'),
		ColumnBuilder.create('sicknessBack', 'hitlist.sicknessBack'),
		ColumnBuilder.create('sicknessCardiovascular', 'hitlist.sicknessCardiovascular'),
		ColumnBuilder.create('sicknessNervousSystem', 'hitlist.sicknessNervousSystem'),
		ColumnBuilder.create('sicknessOtherOrUnknown', 'hitlist.sicknessOtherOrUnknown'),
		ColumnBuilder.create('sicknessNotDiagnosed', 'hitlist.sicknessNotDiagnosed'),
		ColumnBuilder.create('accidentWork', 'hitlist.accidentWork'),
		ColumnBuilder.create('accidentFreeTime', 'hitlist.accidentWork'),
		ColumnBuilder.create('cmActive', 'hitlist.cmActive'),
		ColumnBuilder.create('cmArchived', 'hitlist.cmArchived'),
		ColumnBuilder.create('totalCmSuccessful', 'hitlist.cmSuccessful'),
		ColumnBuilder.create('totalCmFailed', 'hitlist.cmFailed'),
		ColumnBuilder.create('averageDuration', 'hitlist.averageDuration'),
	];

	public static PERSON_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('companyName', 'company'),
		ColumnBuilder.create('total', 'hitlist.total'),
		ColumnBuilder.create('sickness', 'hitlist.sickness'),
		ColumnBuilder.create('accident', 'hitlist.accident'),
		ColumnBuilder.create('pregnancy', 'hitlist.pregnancy'),
		ColumnBuilder.create('sicknessPsychic', 'hitlist.sicknessPsychic'),
		ColumnBuilder.create('sicknessBack', 'hitlist.sicknessBack'),
		ColumnBuilder.create('sicknessCardiovascular', 'hitlist.sicknessCardiovascular'),
		ColumnBuilder.create('sicknessNervousSystem', 'hitlist.sicknessNervousSystem'),
		ColumnBuilder.create('sicknessOtherOrUnknown', 'hitlist.sicknessOtherOrUnknown'),
		ColumnBuilder.create('sicknessNotDiagnosed', 'hitlist.sicknessNotDiagnosed'),
		ColumnBuilder.create('accidentWork', 'hitlist.accidentWork'),
		ColumnBuilder.create('accidentFreeTime', 'hitlist.accidentFreeTime'),
		ColumnBuilder.create('cmActive', 'hitlist.cmActive'),
		ColumnBuilder.create('cmArchived', 'hitlist.cmArchived'),
		ColumnBuilder.create('totalCmSuccessful', 'hitlist.cmSuccessful'),
		ColumnBuilder.create('totalCmFailed', 'hitlist.cmFailed'),
		ColumnBuilder.create('averageDuration', 'hitlist.averageDuration'),
	];

	public static getColumns = (type: string): DatatableColumn[] => {
		let columns: DatatableColumn[];
		const typeUpperCase = type.toUpperCase();

		if (typeUpperCase === 'OVERALL') {
			columns = TableColumns.OVERALL_COLUMNS;
		} else if (typeUpperCase === 'COMPANY') {
			columns = TableColumns.COMPANY_COLUMNS;
		} else if (typeUpperCase === 'EMPLOYEE') {
			columns = TableColumns.PERSON_COLUMNS;
		} else {
			columns = [];
		}

		return columns;
	};
}
