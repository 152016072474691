import { Component, OnInit } from '@angular/core';
import { TellcoConstants } from '../../../environment-constants/tellco-constants';
import { EcareConstants } from '../../../environment-constants/ecare-constants';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { SettingsSelect } from '../../../../state/settings/action';
import { EnvironmentLoader } from '../../../../core/config/environment-loader';

@Component({
	templateUrl: './unavailable.component.html',
	styleUrls: ['./unavailable.component.scss'],
})
export class UnavailableComponent implements OnInit {
	unavailableMessage: string;
	selectedLanguage: string;
	buttonLabel: string;
	logo: string;

	UNAVAILABLE_MESSAGE = 'unavailableMessage';
	RETRY = 'retry';

	constructor(private http: HttpClient, private store: Store, private environmentLoader: EnvironmentLoader) {}

	ngOnInit(): void {
		this.logo = this.environmentLoader.getEnvironment().theme === 'tellco-theme' ? TellcoConstants.PK_PRO_USER_URI : EcareConstants.LOGO_URI;

		this.store.select(SettingsSelect.getLanguage).subscribe((value) => {
			this.selectedLanguage = value;
			this.getJSON();
		});
	}

	public getJSON(): void {
		this.http.get(`/assets/unavailable/i18n/${this.selectedLanguage}.json`).subscribe((value) => {
			this.unavailableMessage = value[this.UNAVAILABLE_MESSAGE];
			this.buttonLabel = value[this.RETRY];
		});
	}

	onRetry = (): void => {
		window.location.href = '/';
	};
}
