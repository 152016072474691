import { CanDeactivateFormComponent } from 'nc-shared';
import { Component, OnInit } from '@angular/core';
import { UserSelect } from '../../../../user/state/action';
import { Store } from '@ngrx/store';
import { AbsencePageAction } from '../../../state/action';
import { NcDocumentsService } from 'nc-documents';
import { AbsenceFormBuilder } from '../../../service/absence-form.builder';
import { EventQueueService } from 'src/app/shared/service/event-queue.service';
import { takeUntil } from 'rxjs';
import { BroadcastEventType } from '../../../../shared/enumeration/broadcast-event-type.enumeration';
import { AbsenceStatusEnumeration } from '../../../model/enumeration/absence-status.enumeration';
import { PermissionEnumeration } from '../../../../shared/enumeration/permission.enumeration';
import { EncodingService } from '../../../../shared/service/encoding.service';

@Component({
	templateUrl: './new-absence.component.html',
	styleUrls: ['./new-absence.component.scss'],
})
export class NewAbsenceComponent extends CanDeactivateFormComponent implements OnInit {
	permissions: string[];
	isBranchHrUser = false;
	isBranchOrHqHr = false;

	constructor(
		private store: Store,
		private documentService: NcDocumentsService,
		formBuilder: AbsenceFormBuilder,
		private eventQueue: EventQueueService,
		private encodingService: EncodingService
	) {
		super();
		this.store.select(UserSelect.getPermissions).subscribe((permissions) => {
			this.permissions = permissions;
			this.isBranchHrUser = permissions.includes(PermissionEnumeration.BRANCH_HR);
			this.isBranchOrHqHr = this.permissions.includes(PermissionEnumeration.HR) || this.permissions.includes(PermissionEnumeration.BRANCH_HR);
			this.formGroup = formBuilder.buildNew();
		});
	}

	ngOnInit(): void {
		this.eventQueue
			.on(BroadcastEventType.ON_SAVE)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.onSubmit());
		this.eventQueue
			.on(BroadcastEventType.ON_SAVE_AND_SEND)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.onSaveAndSend());
	}

	onSaveAndSend = (): void => {
		const data = this.formGroup.getRawValue();
		data.documents = this.documentService.getDocuments(this.formGroup);
		data.documents = this.encodingService.encodeDocuments(data.documents);
		data.status = AbsenceStatusEnumeration.PENDING_APPROVAL.code;
		this.store.dispatch(AbsencePageAction.submitNew({ absence: data, isBranchOrHqHr: this.isBranchOrHqHr }));
	};

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue();
		data.documents = this.documentService.getDocuments(this.formGroup);
		data.documents = this.encodingService.encodeDocuments(data.documents);
		data.status = this.isBranchHrUser ? AbsenceStatusEnumeration.DRAFT.code : AbsenceStatusEnumeration.OPEN.code;
		this.store.dispatch(AbsencePageAction.submitNew({ absence: data, isBranchOrHqHr: this.isBranchOrHqHr }));
	};
}
