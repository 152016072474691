import { Component, Input } from '@angular/core';

@Component({
	selector: 'ea-core-banner',
	templateUrl: './nc-banner.component.html',
	styleUrls: ['./nc-banner.component.scss'],
})
export class NcBannerComponent {
	@Input() message = '';
	@Input() styleClass = '';
	@Input() display = false;
}
