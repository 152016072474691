import { Component, Input } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, FilterConfig, TableData } from 'nc-datatable';
import { BaseComponent } from 'nc-shared';
import { Observable } from 'rxjs';

@Component({
	selector: 'ea-present-salary',
	templateUrl: './present-salary.component.html',
})
export class PresentSalaryComponent extends BaseComponent {
	@Input()
	formGroup: FormGroup;

	@Input()
	data$: Observable<TableData>;

	filterConfig: FilterConfig = {
		showGlobalFilter: false,
		filterValues: {},
	};

	constructor() {
		super();
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('factor', 'factor'),
			ColumnBuilder.create('amount', 'amount'),
			ColumnBuilder.create('kindOfSalary', 'kindOfSalary'),
			ColumnBuilder.create('statisticCode', 'statisticCode'),
		];
	}
}
