import { EnumItem } from 'nc-utils';

export const BodyOrientedPartTypeEnum = {
	EYE_12: new EnumItem('EYE_12', 'eye12'),
	PELVIS_40: new EnumItem('PELVIS_40', 'pelvis40'),
	ELBOW_32: new EnumItem('ELBOW_32', 'elbow32'),
	FINGER_36: new EnumItem('FINGER_36', 'finger36'),
	METATARSUS_WITHOUT_TOES_54: new EnumItem('METATARSUS_WITHOUT_TOES_54', 'metatarsusWithoutToes54'),
	ANKLE_53: new EnumItem('ANKLE_53', 'ankle53'),
	FACE_11: new EnumItem('FACE_11', 'face11'),
	METACARPUS_WITHOUT_FINGERS_35: new EnumItem('METACARPUS_WITHOUT_FINGERS_35', 'metacarpusWithoutFingers35'),
	WRIST_34: new EnumItem('WRIST_34', 'wrist34'),
	HIP_JOINT_41: new EnumItem('HIP_JOINT_41', 'hipJoint41'),
	JAW_16: new EnumItem('JAW_16', 'jaw16'),
	KNEE_51: new EnumItem('KNEE_51', 'knee51'),
	GROIN_43: new EnumItem('GROIN_43', 'groin43'),
	LUNGS_INCLUSIVE_AIRWAYS_62: new EnumItem('LUNGS_INCLUSIVE_AIRWAYS_62', 'lungsInclusiveAirways62'),
	MULTIPLE_AREAS_UPPER_EXTREMITIES_37: new EnumItem('MULTIPLE_AREAS_UPPER_EXTREMITIES_37', 'multipleAreasUpperExtremities37'),
	MULTIPLE_AREAS_LOWER_EXTREMITIES_56: new EnumItem('MULTIPLE_AREAS_LOWER_EXTREMITIES_56', 'multipleAreasLowerExtremities56'),
	KIDNEY_65: new EnumItem('KIDNEY_65', 'kidney65'),
	UPPER_ARM_31: new EnumItem('UPPER_ARM_31', 'upperArm31'),
	THIGH_50: new EnumItem('THIGH_50', 'thigh50'),
	EAR_HEARING_14: new EnumItem('EAR_HEARING_14', 'earHearing14'),
	REAR_21: new EnumItem('REAR_21', 'rear21'),
	SHOULDER_30: new EnumItem('SHOULDER_30', 'shoulder30'),
	THORAX_23: new EnumItem('THORAX_23', 'thorax23'),
	LOWER_ARM_33: new EnumItem('LOWER_ARM_33', 'lowerArm33'),
	LOWER_THIGH_52: new EnumItem('LOWER_THIGH_52', 'lowerThigh52'),
	TEETH_15: new EnumItem('TEETH_15', 'teeth15'),
	TOES_55: new EnumItem('TOES_55', 'toes55'),
};
