import { Injectable } from '@angular/core';
import { FormUtils } from 'nc-utils';
import { AuthorityOperationModel } from './authority-operation.model';
import { AuthorityTypeEnumeration } from './enumeration/authority-type.enumeration';
import { OperationTypeEnumeration } from './enumeration/operation-type.enumeration';
import { AccessTypeEnumeration } from './enumeration/access-type.enumeration';
import { DocumentTypeEntityAccessEnumeration } from './enumeration/document-type-entity-access.enumeration';
import { DocumentTypeModel } from './document-type.model';

@Injectable({
	providedIn: 'root',
})
export class RequestModelFactory {
	/**
	 * Creates request model
	 * @param authorityOperation authority operation model
	 */
	create(authorityOperation: AuthorityOperationModel): FormData {
		return FormUtils.objectToFormData({
			model: {
				...authorityOperation,
				access: authorityOperation.access,
				operation: authorityOperation.operation,
				id: authorityOperation.id,
				ids: authorityOperation.ids,
				authority: authorityOperation.authority,
			},
		});
	}

	/**
	 * Creates authority operation model
	 * @param id
	 * @param authority AuthorityTypeEnumeration
	 * @param operation OperationTypeEnumeration
	 * @param access AccessTypeEnumeration
	 * @param ids list of ids for removing or adding
	 */
	createOperationModel(
		id: number,
		authority: AuthorityTypeEnumeration,
		operation: OperationTypeEnumeration,
		ids?: number[],
		access?: AccessTypeEnumeration
	): AuthorityOperationModel {
		var authorityOperationModel = new AuthorityOperationModel();
		authorityOperationModel.id = id;
		authorityOperationModel.ids = ids;
		authorityOperationModel.authority = authority;
		authorityOperationModel.operation = operation;
		authorityOperationModel.access = access;
		return authorityOperationModel;
	}

	/**
	 * Creates authority operation model
	 * @param id
	 * @param entityType DocumentTypeEntityAccessEnumeration
	 * @param operation OperationTypeEnumeration
	 * @param access AccessTypeEnumeration
	 * @param codes list of document codes for removing or adding
	 */
	createDocumentTypeModel(
		id: number,
		entityType: DocumentTypeEntityAccessEnumeration,
		operation: OperationTypeEnumeration,
		access?: AccessTypeEnumeration,
		codes?: []
	): DocumentTypeModel {
		var documentTypeModel = new DocumentTypeModel();
		documentTypeModel.id = id;
		documentTypeModel.codes = codes;
		documentTypeModel.accessType = access;
		documentTypeModel.operation = operation;
		documentTypeModel.entityType = entityType;
		return documentTypeModel;
	}
}
