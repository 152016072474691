<div [formGroup]="workplaceMessageGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div class="scroll-dialog-content">
		<div fxLayout="column">
			<nc-input id="title" label="title" [formGroup]="workplaceMessageGroup" formControlName="title" [isReadonly]="true"></nc-input>
		</div>
		<div fxLayout="column">
			<nc-textarea
				class="text-wrapped"
				id="message"
				label="synchronization.table.Message"
				[formGroup]="workplaceMessageGroup"
				formControlName="message"
				[isReadonly]="true"
			>
			</nc-textarea>
		</div>
		<div fxLayout="column">
			<nc-textarea id="description" label="description" [formGroup]="workplaceMessageGroup" formControlName="description" [isReadonly]="true">
			</nc-textarea>
		</div>
		<hr />
		<div fxLayout="column">
			<h4>{{ 'contactPersonInCompany' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<nc-input
				id="firstname"
				[formGroup]="workplaceMessageGroup"
				formControlName="firstName"
				label="firstName"
				[required]="!dialogMessage?.workplaceMessage?.firstName?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="lastname"
				[formGroup]="workplaceMessageGroup"
				formControlName="lastName"
				label="lastName"
				[required]="!dialogMessage?.workplaceMessage?.lastName?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="phone"
				[formGroup]="workplaceMessageGroup"
				formControlName="phone"
				label="phone"
				[required]="!dialogMessage?.workplaceMessage?.phone?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="function"
				[formGroup]="workplaceMessageGroup"
				formControlName="function"
				label="function"
				[required]="!dialogMessage?.workplaceMessage?.function?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="email"
				[formGroup]="workplaceMessageGroup"
				formControlName="email"
				label="email"
				[required]="!dialogMessage?.workplaceMessage?.email?.answerOptional"
			>
			</nc-input>
		</div>
		<hr />
		<div fxLayout="column">
			<h4>{{ 'jobDescription' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<nc-input
				id="professionalTraining"
				[formGroup]="workplaceMessageGroup"
				formControlName="professionalTraining"
				label="professionalTraining"
				[required]="!dialogMessage?.workplaceMessage?.professionalTraining?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="mainActivity"
				[formGroup]="workplaceMessageGroup"
				formControlName="mainActivity"
				label="mainActivity"
				[required]="!dialogMessage?.workplaceMessage?.mainActivity?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="workingHours"
				[formGroup]="workplaceMessageGroup"
				formControlName="workingHours"
				label="workingHours"
				[required]="!dialogMessage?.workplaceMessage?.workingHours?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="employmentLevel"
				[formGroup]="workplaceMessageGroup"
				formControlName="employmentLevel"
				label="employmentLevel"
				[required]="!dialogMessage?.workplaceMessage?.employmentLevel?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="fullWorkingHours"
				[formGroup]="workplaceMessageGroup"
				formControlName="fullWorkingHours"
				label="fullWorkingHours"
				[required]="!dialogMessage?.workplaceMessage?.fullWorkingHours?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="normalFullWorkingHours"
				[formGroup]="workplaceMessageGroup"
				formControlName="normalFullWorkingHours"
				label="normalFullWorkingHours"
				[required]="!dialogMessage?.workplaceMessage?.normalFullWorkingHours?.answerOptional"
			>
			</nc-input>
		</div>
		<div fxLayout="column">
			<nc-input
				id="comment"
				[formGroup]="workplaceMessageGroup"
				formControlName="comment"
				label="comment"
				[required]="!dialogMessage?.workplaceMessage?.comment?.answerOptional"
			>
			</nc-input>
		</div>
		<hr />
		<div fxLayout="column">
			<h4>{{ 'specialRequirementsFrameworkConditions' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="seatedWorkplace"
				[formGroup]="workplaceMessageGroup"
				formControlName="seatedWorkplace"
				label="seatedWorkplace"
				[required]="!dialogMessage?.workplaceMessage?.seatedWorkplace?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="standingWorkstation"
				[formGroup]="workplaceMessageGroup"
				formControlName="standingWorkstation"
				label="standingWorkstation"
				[required]="!dialogMessage?.workplaceMessage?.standingWorkstation?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="sitStandWorkstation"
				[formGroup]="workplaceMessageGroup"
				formControlName="sitStandWorkstation"
				label="sitStandWorkstation"
				[required]="!dialogMessage?.workplaceMessage?.sitStandWorkstation?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="computerWorkstation"
				[formGroup]="workplaceMessageGroup"
				formControlName="computerWorkstation"
				label="computerWorkstation"
				[required]="!dialogMessage?.workplaceMessage?.computerWorkstation?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="visualControlActivity"
				[formGroup]="workplaceMessageGroup"
				formControlName="visualControlActivity"
				label="visualControlActivity"
				[required]="!dialogMessage?.workplaceMessage?.visualControlActivity?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="customerContact"
				[formGroup]="workplaceMessageGroup"
				formControlName="customerContact"
				label="customerContact"
				[required]="!dialogMessage?.workplaceMessage?.customerContact?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="teamwork"
				[formGroup]="workplaceMessageGroup"
				formControlName="teamwork"
				label="teamwork"
				[required]="!dialogMessage?.workplaceMessage?.teamwork?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="noise"
				[formGroup]="workplaceMessageGroup"
				formControlName="noise"
				label="noise"
				[required]="!dialogMessage?.workplaceMessage?.noise?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="heatColdVapors"
				[formGroup]="workplaceMessageGroup"
				formControlName="heatColdVapors"
				label="heatColdVapors"
				[required]="!dialogMessage?.workplaceMessage?.heatColdVapors?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="smell"
				[formGroup]="workplaceMessageGroup"
				formControlName="smell"
				label="smell"
				[required]="!dialogMessage?.workplaceMessage?.smell?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="dustExposure"
				[formGroup]="workplaceMessageGroup"
				formControlName="dustExposure"
				label="dustExposure"
				[required]="!dialogMessage?.workplaceMessage?.dustExposure?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="frequentPostureInTwistedPosition"
				[formGroup]="workplaceMessageGroup"
				formControlName="frequentPostureInTwistedPosition"
				label="frequentPostureInTwistedPosition"
				[required]="!dialogMessage?.workplaceMessage?.frequentPostureInTwistedPosition?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="preciseUseOfHands"
				[formGroup]="workplaceMessageGroup"
				formControlName="preciseUseOfHands"
				label="preciseUseOfHands"
				[required]="!dialogMessage?.workplaceMessage?.preciseUseOfHands?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="increasedRiskOfAccidents"
				[formGroup]="workplaceMessageGroup"
				formControlName="increasedRiskOfAccidents"
				label="increasedRiskOfAccidents"
				[required]="!dialogMessage?.workplaceMessage?.increasedRiskOfAccidents?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="liftLessThen5kg"
				[formGroup]="workplaceMessageGroup"
				formControlName="liftLessThen5kg"
				label="liftLessThen5kg"
				[required]="!dialogMessage?.workplaceMessage?.liftLessThen5kg?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="liftMoreThen5kg"
				[formGroup]="workplaceMessageGroup"
				formControlName="liftMoreThen5kg"
				label="liftMoreThen5kg"
				[required]="!dialogMessage?.workplaceMessage?.liftMoreThen5kg?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="liftMoreThen10kg"
				[formGroup]="workplaceMessageGroup"
				formControlName="liftMoreThen10kg"
				label="liftMoreThen10kg"
				[required]="!dialogMessage?.workplaceMessage?.liftMoreThen10kg?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="liftMoreThen15kg"
				[formGroup]="workplaceMessageGroup"
				formControlName="liftMoreThen15kg"
				label="liftMoreThen15kg"
				[required]="!dialogMessage?.workplaceMessage?.liftMoreThen15kg?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-input
				id="otherSpecialRequirement"
				[formGroup]="workplaceMessageGroup"
				formControlName="otherSpecialRequirement"
				label="otherSpecialRequirement"
				[required]="!dialogMessage?.workplaceMessage?.otherSpecialRequirement?.answerOptional"
			>
			</nc-input>
		</div>
		<hr />
		<div fxLayout="column">
			<h4>{{ 'workingTime' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="dailyOperationFixed"
				[formGroup]="workplaceMessageGroup"
				formControlName="dailyOperationFixed"
				label="dailyOperationFixed"
				[required]="!dialogMessage?.workplaceMessage?.dailyOperationFixed?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="daytimeOperationGliding"
				[formGroup]="workplaceMessageGroup"
				formControlName="daytimeOperationGliding"
				label="daytimeOperationGliding"
				[required]="!dialogMessage?.workplaceMessage?.daytimeOperationGliding?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="nightShiftsNecessary"
				[formGroup]="workplaceMessageGroup"
				formControlName="nightShiftsNecessary"
				label="nightShiftsNecessary"
				[required]="!dialogMessage?.workplaceMessage?.nightShiftsNecessary?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="exclusivelyNightShifts"
				[formGroup]="workplaceMessageGroup"
				formControlName="exclusivelyNightShifts"
				label="exclusivelyNightShifts"
				[required]="!dialogMessage?.workplaceMessage?.exclusivelyNightShifts?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-input
				id="otherSpecialWorkingHours"
				[formGroup]="workplaceMessageGroup"
				formControlName="otherSpecialWorkingHours"
				label="otherSpecialWorkingHours"
				[required]="!dialogMessage?.workplaceMessage?.otherSpecialWorkingHours?.answerOptional"
			>
			</nc-input>
		</div>
		<hr />
		<div fxLayout="column">
			<h4>{{ 'gentleWorkstation' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="available"
				[formGroup]="workplaceMessageGroup"
				formControlName="available"
				label="available"
				[required]="!dialogMessage?.workplaceMessage?.available?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="onlyTemporaryPossible"
				[formGroup]="workplaceMessageGroup"
				formControlName="onlyTemporaryPossible"
				label="onlyTemporaryPossible"
				[required]="!dialogMessage?.workplaceMessage?.onlyTemporaryPossible?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="notAvailable"
				[formGroup]="workplaceMessageGroup"
				formControlName="notAvailable"
				label="notAvailable"
				[required]="!dialogMessage?.workplaceMessage?.notAvailable?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-input
				id="descriptionOfThePossibleGentleWorkstation"
				[formGroup]="workplaceMessageGroup"
				formControlName="descriptionOfThePossibleGentleWorkstation"
				label="descriptionOfThePossibleGentleWorkstation"
				[required]="!dialogMessage?.workplaceMessage?.descriptionOfThePossibleGentleWorkstation?.answerOptional"
			>
			</nc-input>
		</div>
		<hr />
		<div fxLayout="column">
			<h4>{{ 'contactData' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<nc-checkbox
				id="desiredByTheDoctor"
				[formGroup]="workplaceMessageGroup"
				formControlName="desiredByTheDoctor"
				label="desiredByTheDoctor"
				[required]="!dialogMessage?.workplaceMessage?.desiredByTheDoctor?.answerOptional"
			>
			</nc-checkbox>
		</div>
		<div fxLayout="column">
			<nc-datepicker
				id="withInsuranceTakesPlaceOn"
				[formGroup]="workplaceMessageGroup"
				formControlName="withInsuranceTakesPlaceOn"
				label="withInsuranceTakesPlaceOn"
				[required]="!dialogMessage?.workplaceMessage?.withInsuranceTakesPlaceOn?.answerOptional"
			>
			</nc-datepicker>
		</div>
		<hr />
		<div fxLayout="column">
			<h4>{{ 'moreInfo' | translate }}</h4>
		</div>
		<div fxLayout="column">
			<p>{{ dialogMessage?.workplaceMessage?.sendPictureDate?.label }}: {{ dialogMessage?.workplaceMessage?.sendPictureDate?.value }}</p>
		</div>
		<div fxLayout="column">
			<nc-input
				id="comments"
				[formGroup]="workplaceMessageGroup"
				formControlName="comments"
				label="comments"
				[required]="!dialogMessage?.workplaceMessage?.comments?.answerOptional"
			>
			</nc-input>
		</div>
	</div>
	<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-submit-button
			id="send-dialog-message"
			buttonLabel="replay"
			(buttonClick)="submit()"
			[formGroup]="workplaceMessageGroup"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</div>
