import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KleSelect } from '../../state/action';
import { Store } from '@ngrx/store';
import { ProcessControlResponse } from '../../model/process-control-response';

@Component({
	selector: 'ea-process-control-response',
	templateUrl: './process-control-response.component.html',
})
export class ProcessControlResponseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;

	processControlResponseFormGroup = this.formBuilder.group({
		oldId: null as string,
		newId: null as string,
		name: null as string,
		complementaryLine: null as string,
		street: null as string,
		postbox: null as string,
		locality: null as string,
		zipCode: null as string,
		city: null as string,
		country: null as string,
		insuranceName: null as string,
		insuranceEmail: null as string,
		insurancePhoneNumber: null as string,
		insuranceMobileNumber: null as string,
	});

	constructor(private formBuilder: FormBuilder, private store: Store) {}

	ngOnInit(): void {
		this.store.select(KleSelect.getProcessControlResponse).subscribe((value) => (value ? this.setFormData(value) : ''));
		this.formGroup.addControl('processControlResponse', this.processControlResponseFormGroup);
	}

	setFormData(data: ProcessControlResponse): void {
		if (data?.institutionAddress) {
			this.processControlResponseFormGroup.controls.name.patchValue(data.institutionAddress.institutionName);
			this.processControlResponseFormGroup.controls.complementaryLine.patchValue(data.institutionAddress.complementaryLine);
			this.processControlResponseFormGroup.controls.street.patchValue(data.institutionAddress.street);
			this.processControlResponseFormGroup.controls.postbox.patchValue(data.institutionAddress.postbox);
			this.processControlResponseFormGroup.controls.locality.patchValue(data.institutionAddress.locality);
			this.processControlResponseFormGroup.controls.zipCode.patchValue(data.institutionAddress.zipCode);
			this.processControlResponseFormGroup.controls.city.patchValue(data.institutionAddress.city);
			this.processControlResponseFormGroup.controls.country.patchValue(data.institutionAddress.country);
		}
		if (data.contactInsurance) {
			this.processControlResponseFormGroup.controls.insuranceName.patchValue(data.contactInsurance.name);
			this.processControlResponseFormGroup.controls.insuranceEmail.patchValue(data.contactInsurance.emailAddress);
			this.processControlResponseFormGroup.controls.insurancePhoneNumber.patchValue(data.contactInsurance.phoneNumber);
			this.processControlResponseFormGroup.controls.insuranceMobileNumber.patchValue(data.contactInsurance.mobilePhoneNumber);
		}
		if (data.changeInsuranceCaseId) {
			this.processControlResponseFormGroup.controls.oldId.patchValue(data.changeInsuranceCaseId.oldId);
			this.processControlResponseFormGroup.controls.newId.patchValue(data.changeInsuranceCaseId.newId);
		}
	}
}
