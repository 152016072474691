import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DocumentTypesService } from '../service/document-types.service';
import { DialogService, DialogType } from 'nc-utils';
import { ErrorAction } from '../../state/error/action';
import { DocumentTypesApiAction, DocumentTypesPageAction } from './action';
import { SettingsPageAction } from '../../state/settings/action';

@Injectable()
export class DocumentTypesEffect {
	constructor(private actions$: Actions, private documentTypesService: DocumentTypesService, private dialogService: DialogService) {}

	getDocumentTypesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DocumentTypesPageAction.getDocumentTypes),
			switchMap(() => this.documentTypesService.getDocumentTypes()),
			map((result) => DocumentTypesApiAction.getDocumentTypesSuccess({ records: result }))
		);
	});

	getDocumentTypeEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DocumentTypesPageAction.getDocumentType),
			switchMap((action) => this.documentTypesService.getDocumentType(action.currentDocumentTypeCode)),
			map((result) => DocumentTypesApiAction.getDocumentTypeSuccess({ documentType: result }))
		);
	});

	saveDocumentTypeEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DocumentTypesPageAction.saveDocumentType),
			mergeMap((action) => this.documentTypesService.saveDocumentType(action.documentType)),
			map((response) =>
				response.success
					? DocumentTypesApiAction.saveDocumentTypeSuccess({ response })
					: ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	saveDocumentTypesSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DocumentTypesApiAction.saveDocumentTypeSuccess),
			map(() => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.navigate({ url: '/document-types' });
			})
		);
	});
}
