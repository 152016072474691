import { Injectable } from '@angular/core';
import { Absence } from '../model/absence';
import { Employee } from '../../shared/model/employee';
import { Company } from '../../shared/model/company';
import { FormArray, FormGroup } from '@angular/forms';
import { NcDocumentsService } from 'nc-documents';
import { IncapacityService } from './incapacity.service';
import { booleanToFlag } from '../../shared/enumeration/flag.enumeration';

@Injectable({
	providedIn: 'root',
})
export class ExistingAbsenceService {
	constructor(private documentService: NcDocumentsService, private incapacityService: IncapacityService) {}

	/**
	 * Initializes existing absence form
	 * @param formGroup form group that will be initialized
	 * @param absence absence data
	 * @param employee employee data
	 * @param company company data
	 */
	initializeForm = (formGroup: FormGroup, absence: Absence, employee: Employee, company: Company): void => {
		const documents = formGroup.controls.documents as FormArray;
		const incapacities = formGroup.controls.incapacities as FormArray;

		formGroup.patchValue({
			id: absence.id,
			code: absence.code,
			company: company.name,
			companyId: company.id,
			employee: `${employee.firstName} ${employee.lastName}`,
			refEmployee: employee.id,
			categoryType: absence.categoryType,
			absenceType: absence.absenceType,
			absenceSubType: absence.absenceSubType,
			pregnancyDueDate: absence.pregnancyDueDate,
			createdOn: absence.createdOn,
			createdBy: absence.createdBy,
			isArchived: absence.archived,
			isClosed: absence.closed,
			comment: absence.comment,
			status: absence.status,
			statusByMilestone: absence.milestoneStatuses.join(';'),
			medicalDiagnosisCode: absence.medicalDiagnosisCode,
			medicalDiagnosisAdditionalCode: absence.medicalDiagnosisAdditionalCode,
			medicalDiagnosisValue: absence.medicalDiagnosisValue,
			medicalDiagnosisAdditionalValue: absence.medicalDiagnosisAdditionalValue,
			responsibleUserId: absence.responsibleUserId,
			responsibleUserName: absence.responsibleUserName,
			responsibleLDUserId: absence.responsibleLDUserId,
			responsibleLDUserName: absence.responsibleLDUserName,
			isCaseManagementActive: absence.isCaseManagementActive,
			isSwicaInsured: absence.isSwicaInsured,
			isPkproInsured: absence.isPkproInsured,
			isSentToCaseManagementCompany: absence.isSentToCaseManagementCompany,
			sentToCaseManagementCompanyDate: absence.sentToCaseManagementCompanyDate,
			isClosedByCaseManagementCompany: absence.isClosedByCaseManagementCompany,
			closedByCaseManagementCompanyDate: absence.closedByCaseManagementCompanyDate,
			isAbortedByCaseManagementCompany: absence.isAbortedByCaseManagementCompany,
			abortedByCaseManagementCompanyDate: absence.abortedByCaseManagementCompanyDate,
			caseManagementKTGInsurer: absence.caseManagementDetails?.ktgInsurer,
			caseManagementUVGInsurer: absence.caseManagementDetails?.uvgInsurer,
			caseManagementBranchOffice: absence.caseManagementDetails?.office?.name,
			caseManagementBranchOfficeContactPerson: absence.caseManagementDetails?.office?.contactPerson,
			caseManagementBranchOfficePhoneNumber: absence.caseManagementDetails?.office?.phoneNumber,
			caseManagementStartedOnDate: absence.caseManagementDetails?.reports?.startedOn,
			caseManagementAssessmentByExpertOnDate: absence.caseManagementDetails?.reports?.assessment?.date,
			caseManagementReassessmentByExpertOnDate: absence.caseManagementDetails?.reports?.reassessment?.date,
			caseManagementIntegrationReport: absence.caseManagementDetails?.reports?.integrationReport,
			caseManagementAssessmentReport: absence.caseManagementDetails?.reports?.assessment?.status,
			caseManagementReassessmentReport: absence.caseManagementDetails?.reports?.reassessment?.status,
			caseManagementInterventionStatus: absence.caseManagementDetails?.reports?.interventionStatus,
			caseManagementAccomplishment: absence.caseManagementDetails?.conclusionDetails?.accomplishment,
			caseManagementEndedOnDate: absence.caseManagementDetails?.conclusionDetails?.endedOn,
			caseManagementSustainabilityCheckedOnDate: absence.caseManagementDetails?.conclusionDetails?.sustainabilityCheckOn,
			caseManagementSuccessfulnessType: absence.caseManagementDetails?.conclusionDetails?.conclusion,
			hasRiskAssessment: booleanToFlag(absence?.hasRiskAssessment),
			pkProInsuranceEntryDate: employee.pkProInsuranceEntryDate,
			pkProInsuranceExitDate: employee.pkProInsuranceExitDate,
			hasDailySicknessBenefitsInsurance: absence.hasDailySicknessBenefitsInsurance,
			dailySicknessBenefitsInsuranceCompany: absence.dailySicknessBenefitsInsuranceCompany,
			dailySicknessBenefitsInsuranceSinisterReference: absence.dailySicknessBenefitsInsuranceSinisterReference,
			hasAccidentInsurance: absence.hasAccidentInsurance,
			accidentInsuranceCompany: absence.accidentInsuranceCompany,
			accidentInsuranceSinisterReference: absence.accidentInsuranceSinisterReference,
			hasChildrenUnder18OrInEducationUpTo25: absence.hasChildrenUnder18OrInEducationUpTo25,
			numberOfChildren: absence.numberOfChildren.toString(),
			hasReturnedToWork: absence.hasReturnedToWork,
			numberOfPossibleWorkHours: absence.numberOfPossibleWorkHours.toString(),
			employmentStartDate: absence.employmentStartDate,
			employmentEndDate: absence.employmentEndDate,
			employmentPercentage: absence.employmentPercentage,
			employmentAnnualSalary: absence.employmentAnnualSalary,
			firstDeploymentDate: absence.firstDeploymentDate,
			firstDeploymentRegistration: absence.firstDeploymentRegistration,
			firstDeploymentSubject: absence.firstDeploymentSubject,
			lastDeploymentDate: absence.lastDeploymentDate,
			archivedReason: absence.archivedReason,
			archivedComment: absence.archivedComment,
			isRecoverageActive: absence.isRecoverageActive,
			isKleEnabled: absence.companyIsKleEnabled,
		});

		this.documentService.populateData(documents, absence.documents);
		this.incapacityService.populateData(incapacities, absence.incapacities);
	};
}
