<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="company-group-container">
				<nc-datatable
					[rowClick]="onRowClick"
					[id]="'company-group'"
					[columns]="companyGroupColumns"
					[data$]="companyGroups$"
					[filterConfig]="filterConfig"
				>
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-create-footer url="integration/company/groups/new"></ea-create-footer>
</div>
