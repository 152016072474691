<div class="app-container" [class.app-is-mobile]="mobile$ | async" *ngIf="loading$ | async; else loading">
	<mat-sidenav-container class="app-sidenav-container" autosize *ngIf="language$ | async as language">
		<mat-sidenav
			#sidenav
			*ngIf="isAuthenticated && isReady"
			[opened]="!(mobile$ | async)"
			[fixedInViewport]="mobile$ | async"
			[mode]="(mobile$ | async) || !isAuthenticated ? 'over' : 'side'"
		>
			<ea-sidebar></ea-sidebar>
		</mat-sidenav>
		<mat-sidenav-content id="SidebarContent">
			<mat-toolbar *ngIf="isReady" class="app-toolbar toolbar-line" id="Toolbar">
				<ea-banner></ea-banner>
				<ng-container *ngIf="isAuthenticated">
					<button mat-icon-button (click)="sidenav.toggle()">
						<mat-icon>menu</mat-icon>
					</button>
					<span class="app-title">{{ title$ | async }}</span>
				</ng-container>
				<ng-container *ngIf="!isAuthenticated">
					<img class="header-logo" src="{{ logo }}" />
					<span class="app-spacer"></span>
					<nc-language-picker
						[id]="'language-selector-menu'"
						[active]="language"
						[onChange]="onLanguageChange"
						[languages]="['de', 'en', 'fr', 'it']"
					>
					</nc-language-picker>
				</ng-container>
			</mat-toolbar>
			<router-outlet #outlet="outlet"></router-outlet>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>

<ng-template #loading>
	<nc-splash-screen image="{{ logo }}"></nc-splash-screen>
</ng-template>
