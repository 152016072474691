import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LabelManagementService } from '../service/label-management.service';
import { DialogService, DialogType } from 'nc-utils';
import { ErrorAction } from '../../state/error/action';
import { Router } from '@angular/router';
import { LabelManagementApiAction, LabelManagementPageAction } from './action';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SettingsSelect } from '../../state/settings/action';

@Injectable()
export class LabelManagementEffect {
	constructor(
		private actions$: Actions,
		private labelManagementService: LabelManagementService,
		private dialogService: DialogService,
		private router: Router,
		private store: Store,
		private translateService: TranslateService
	) {}

	getLabelsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LabelManagementPageAction.getLabels),
			switchMap(() => this.labelManagementService.getLabels()),
			map((result) => LabelManagementApiAction.getLabelsSuccess({ records: result }))
		);
	});

	getLabelEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LabelManagementPageAction.getLabel),
			switchMap((action) => this.labelManagementService.getLabel(action.currentLabelCode)),
			map((result) => LabelManagementApiAction.getLabelSuccess({ label: result.value }))
		);
	});

	saveLabelEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LabelManagementPageAction.saveLabel),
			mergeMap((action) => this.labelManagementService.saveLabel(action.label)),
			map((response) =>
				response.success ? LabelManagementApiAction.saveLabelSuccess({ response }) : ErrorAction.submitFailed({ errors: response.errors })
			)
		);
	});

	saveLabelSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(LabelManagementApiAction.saveLabelSuccess),
				withLatestFrom(this.store.select(SettingsSelect.getLanguage)),
				map(([action, language]) => {
					this.dialogService.close(DialogType.PROCESSING);
					if (language) {
						this.translateService.getTranslation(language);
					}
					this.router.navigateByUrl('/label/management');
				})
			);
		},
		{ dispatch: false }
	);
}
