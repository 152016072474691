<form [formGroup]="formGroup" fxLayout="column" fxLayoutGap="10px">
	<div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column">
		<nc-datepicker
			[id]="'date-from'"
			ncAutofocus
			label="from"
			[required]="true"
			className="datepicker"
			[shouldAutofocus]="true"
			[formGroup]="formGroup"
			formControlName="dateFrom"
		>
		</nc-datepicker>

		<nc-datepicker [id]="'date-to'" className="datepicker" [formGroup]="formGroup" formControlName="dateTo" label="to" [required]="true">
		</nc-datepicker>
	</div>
	<div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.lt-md="column">
		<nc-toggle-buttons [formGroup]="formGroup" [required]="true" formControlName="type" label="clientType" [options]="statisticTypes">
		</nc-toggle-buttons>

		<nc-toggle-buttons
			[formGroup]="formGroup"
			[required]="true"
			formControlName="absenceActivity"
			label="absenceActivityOption"
			[options]="absenceActivityOptions"
		>
		</nc-toggle-buttons>
	</div>

	<nc-toggle-buttons
		*ngIf="formGroup.get('type').value === 'OVERALL'"
		[formGroup]="formGroup"
		formControlName="insuranceType"
		label="insuranceType"
		[required]="true"
		[options]="insuranceTypes"
	>
	</nc-toggle-buttons>

	<nc-quick-search
		[textInputId]="'company'"
		[resetButtonId]="'company-reset'"
		[undoButtonId]="'company-undo'"
		*ngIf="formGroup.get('type').value !== 'OVERALL'"
		[formGroup]="formGroup"
		formControlName="company"
		[required]="true"
		[fetchAll]="searchCompanies"
		placeholder="defaultSelectPleaceholder"
		label="company"
	>
	</nc-quick-search>

	<nc-quick-search
		[textInputId]="'person'"
		[resetButtonId]="'person-reset'"
		[undoButtonId]="'person-undo'"
		*ngIf="formGroup.get('type').value === 'EMPLOYEE' && isCompanyPopulated()"
		[formGroup]="formGroup"
		[required]="true"
		formControlName="person"
		[fetchAll]="searchPersons"
		placeholder="defaultSelectPleaceholder"
		label="person"
	>
		<ng-template let-item>
			<span class="mat-option-title">{{ item.text | split : ';' : 0 }}</span
			><br />
			<span class="mat-option-footer">{{ item.text | split : ';' : 1 }}</span>
		</ng-template>
	</nc-quick-search>

	<ng-content></ng-content>
</form>
