import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, ActivationStart, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { State } from './state/app.state';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { SettingsPageAction, SettingsSelect } from './state/settings/action';
import { BaseComponent, CanDeactivateFormComponent } from 'nc-shared';
import { RouteChange } from './shared/route-change';
import { UserPageAction, UserSelect } from './user/state/action';
import { MatSidenav } from '@angular/material/sidenav';
import { EcareConstants } from './shared/environment-constants/ecare-constants';
import { TellcoConstants } from './shared/environment-constants/tellco-constants';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { AppConstants } from './app.constants';
import { EnvironmentLoader } from './core/config/environment-loader';

@Component({
	selector: 'ea-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
	@ViewChild('sidenav') public sidenav: MatSidenav;
	title$: Observable<any>;
	loading$: Observable<any>;
	mobile$: Observable<boolean>;
	language$: Observable<string>;
	isAuthenticated$: Observable<boolean>;
	isAuthenticated: boolean;
	isReady$: Observable<boolean>;
	isReady: boolean;
	logo = '';
	customTheme: string;
	previousTitle: string;
	allowLogout = false;
	isLogoutClicked: boolean;

	PROTOTYPE = 'prototype';

	constructor(
		private title: Title,
		private router: Router,
		private store: Store<State>,
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private environmentLoader: EnvironmentLoader,
		private breakpointObserver: BreakpointObserver,
		private zendeskService: NgxZendeskWebwidgetService
	) {
		super();
	}

	ngOnInit(): void {
		this.customTheme = this.environmentLoader.getEnvironment().theme;
		this.language$ = this.store.select(SettingsSelect.getLanguage);
		this.isAuthenticated$ = this.store.select(UserSelect.isAuthenticated);
		this.title$ = RouteChange.getTitle$(this.router, this.activatedRoute, this.translateService);
		this.loading$ = this.language$.pipe(switchMap((language) => this.translateService.use(language)));
		this.mobile$ = this.breakpointObserver.observe(['(max-width: 900px)']).pipe(map((state) => state.matches));
		this.isReady$ = this.store.select(SettingsSelect.isReady);
		this.title$.subscribe((newTitle) => this.title.setTitle(newTitle));
		this.isAuthenticated$.subscribe((result) => (this.isAuthenticated = result));
		this.isReady$.subscribe((result) => (this.isReady = result));

		this.store
			.select(UserSelect.getIsLogoutClicked)
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => (this.isLogoutClicked = value));

		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			this.previousTitle = document.title;

			if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
				history.pushState(null, null, this.router.url);
				this.setMenuItem(event.url);

				if (event.url === '/') {
					const canNavigate = confirm(this.translateService.instant('aboutToLogOut'));

					canNavigate ? this.store.dispatch(UserPageAction.checkLogout()) : this.router.navigateByUrl(this.router.url);
					this.setTitle();
					this.setMenuItem(this.router.url);
				}
			} else if (event instanceof NavigationCancel) {
				this.setTitle();
				this.setMenuItem(this.router.url);
				this.store.dispatch(UserPageAction.setLogoutClicked({ logoutClicked: false }));
			} else if (event instanceof ActivationStart) {
				this.setLogoutIsAllowed();
			} else if (event instanceof NavigationEnd) {
				this.setMenuItem(event.url);
				this.checkIfPageHaveForm();
			}
		});

		this.applyTheme();
		this.zendeskService.initZendesk();
	}

	setMenuItem(url: string): void {
		this.store.dispatch(SettingsPageAction.setMenuItem({ menuItem: url }));
	}

	setTitle(): void {
		document.getElementsByTagName('title')[0].innerText = this.previousTitle;
	}

	onLanguageChange = (language: string): void => this.store.dispatch(SettingsPageAction.setLanguage({ language }));

	// Set logo image, favicon, body class depending on theme from configuration.
	applyTheme = (): void => {
		this.logo = this.customTheme === AppConstants.TELLCO_THEME ? TellcoConstants.LOGO_URI : EcareConstants.LOGO_URI;
		document.body.classList.add(this.customTheme);
		if (this.customTheme === AppConstants.TELLCO_THEME) {
			document.getElementById('favicon').setAttribute('href', TellcoConstants.FAVICON_URI);
		}
	};

	checkIfPageHaveForm(): void {
		const isFormPage = this.activatedRoute.firstChild.component[this.PROTOTYPE] instanceof CanDeactivateFormComponent;
		this.store.dispatch(UserPageAction.setFormPage({ isFormPage }));
	}

	setLogoutIsAllowed(): void {
		if (this.isLogoutClicked) {
			this.store.dispatch(UserPageAction.allowLogout({ allowLogout: true }));
		}
	}
}
