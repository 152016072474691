import { StatisticRow } from '../model/statistic-row';
import { StatisticItem } from '../model/statistic-item';

export class StatisticRowTransformer {
	public static transform = (row: StatisticRow, isTotal?: boolean): { [key: string]: string } => {
		return {
			rank: row.rank,
			companyName: row.companyName,
			total: StatisticRowTransformer.formatCell(row.total, isTotal),
			sickness: StatisticRowTransformer.formatCell(row.sickness, isTotal),
			accident: StatisticRowTransformer.formatCell(row.accident, isTotal),
			pregnancy: StatisticRowTransformer.formatCell(row.pregnancy, isTotal),
			sicknessPsychic: StatisticRowTransformer.formatCell(row.sicknessPsychic, isTotal),
			sicknessBack: StatisticRowTransformer.formatCell(row.sicknessBack, isTotal),
			sicknessCardiovascular: StatisticRowTransformer.formatCell(row.sicknessCardiovascular, isTotal),
			sicknessNervousSystem: StatisticRowTransformer.formatCell(row.sicknessNervousSystem, isTotal),
			sicknessOtherOrUnknown: StatisticRowTransformer.formatCell(row.sicknessOtherOrUnknown, isTotal),
			sicknessNotDiagnosed: StatisticRowTransformer.formatCell(row.sicknessNotDiagnosed, isTotal),
			accidentWork: StatisticRowTransformer.formatCell(row.accidentWork, isTotal),
			accidentFreeTime: StatisticRowTransformer.formatCell(row.accidentFreeTime, isTotal),
			cmActive: StatisticRowTransformer.formatCell(row.cmActive, isTotal),
			cmArchived: StatisticRowTransformer.formatCell(row.cmArchived, isTotal),
			totalCmSuccessful: row.cmSuccessful.count.toString(),
			totalCmFailed: row.cmFailed.count.toString(),
			averageDuration: (Math.round((row.total.averageDuration + Number.EPSILON) * 100) / 100).toString(),
		};
	};

	public static formatCell = (item: StatisticItem, isTotal: boolean): string => {
		const percentage = Math.round((item.contribution + Number.EPSILON) * 100) / 100;
		return isTotal ? `${item.count}` : `${item.count} (${percentage}%)`;
	};
}
