import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DialogMessage } from '../../../model/dialog-message';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KlePageAction, KleSelect } from '../../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';

@Component({
	selector: 'ea-deadline-message',
	templateUrl: './deadline-message-form.component.html',
	styleUrls: ['./deadline-message-form.component.scss'],
})
export class DeadlineMessageFormComponent extends BaseComponent implements OnInit {
	dialogMessage: DialogMessage;

	taskWithDeadlineGroup = this.formBuilder.group({
		title: '',
		message: ['', Validators.required],
	});

	constructor(
		private formBuilder: FormBuilder,
		private store: Store,
		@Inject(MAT_DIALOG_DATA) public data: { absenceCode: string; storyId: string; formGroup: FormGroup }
	) {
		super();
	}

	ngOnInit(): void {
		if (this.data.storyId != null) {
			this.store.dispatch(KlePageAction.getDialogMessage({ absenceCode: this.data.absenceCode, storyId: this.data.storyId }));
		}

		this.store
			.select(KleSelect.getDialogMessage)
			.pipe(
				takeUntil(this.destroy$),
				filter((message) => message != null)
			)
			.subscribe((message) => {
				this.taskWithDeadlineGroup.controls.title.patchValue(message.title);
				this.taskWithDeadlineGroup.controls.message.patchValue(message.message);
			});
	}
}
