<h4 mat-dialog-title>{{ 'present' | translate }}</h4>
<ea-container title="hourlyPay" cssClass="margin-top-15">
	<div fxLayout="column" fxLayoutGap="10px" [formGroup]="formGroup">
		<div fxLayout="row" fxLayoutGap="10px" cssClass="margin-top-15">
			<nc-input [formGroup]="formGroup" type="number" formControlName="earnings13Th" label="earnings13Th" [isReadonly]="true"></nc-input>
			<nc-input [formGroup]="formGroup" type="number" formControlName="hourlySalary" label="hourlySalary" [isReadonly]="true"></nc-input>
			<nc-input
				[formGroup]="formGroup"
				type="number"
				formControlName="vacationAndPublicHolidayCompensation"
				label="vacationAndPublicHolidayCompensation"
				[isReadonly]="true"
			></nc-input>
		</div>
	</div>
</ea-container>

<ea-container title="details" cssClass="margin-top-15">
	<nc-datatable *ngIf="data$" id="present-details" [columns]="getColumns()" [data$]="data$" [filterConfig]="filterConfig"></nc-datatable>
</ea-container>
