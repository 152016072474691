<h2 mat-dialog-title>
	<nc-label id="treatment" value="treatment"></nc-label>
</h2>
<mat-dialog-content class="mat-typography">
	<ea-treatments-form [formGroup]="data.formGroup"></ea-treatments-form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px">
	<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
	<nc-button id="save" label="save" (click)="submit()"></nc-button>
</mat-dialog-actions>
