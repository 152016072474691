import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccessAssignmentApiAction, AccessAssignmentPageAction, AccessAssignmentSelect } from './action';
import { AccessAssignmentService } from '../service/access-assignment.service';
import { RequestModelFactory } from '../model/request-model.factory';
import { Store } from '@ngrx/store';
import { OperationTypeEnumeration } from '../model/enumeration/operation-type.enumeration';
import { DialogService, DialogType } from 'nc-utils';

@Injectable()
export class AccessAssignmentEffect {
	constructor(
		private actions$: Actions,
		private accessAssignmentService: AccessAssignmentService,
		private requestModelFactory: RequestModelFactory,
		private dialogService: DialogService,
		private store: Store
	) {}

	getSelectOptionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentPageAction.getSelectOptions),
			switchMap((action) => this.accessAssignmentService.getSelectOptions(action.url)),
			map((selectOptions) => AccessAssignmentApiAction.getSelectOptionsSuccess({ selectOptions }))
		);
	});

	getAvailableEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentPageAction.getAvailable),
			mergeMap((action) => this.accessAssignmentService.operation(action.authorityOperation)),
			map((result) => AccessAssignmentApiAction.getAvailableSuccess({ records: result.records }))
		);
	});

	getAvailableForDocsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentPageAction.getAvailableForDocs),
			mergeMap((action) => this.accessAssignmentService.operationForDocs(action.documentTypeModel)),
			map((result) => AccessAssignmentApiAction.getAvailableForDocsSuccess({ records: result.records }))
		);
	});

	getAssignedEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentPageAction.getAssigned),
			mergeMap((action) => this.accessAssignmentService.operation(action.authorityOperation)),
			map((result) => AccessAssignmentApiAction.getAssignedSuccess({ records: result.records }))
		);
	});

	getAssignedForDocsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentPageAction.getAssignedForDocs),
			mergeMap((action) => this.accessAssignmentService.operationForDocs(action.documentTypeModel)),
			map((result) => AccessAssignmentApiAction.getAssignedForDocsSuccess({ records: result.records }))
		);
	});

	assignOrRemoveEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentPageAction.assignOrRemove),
			mergeMap((action) => this.accessAssignmentService.operation(action.authorityOperation)),
			map((response) => AccessAssignmentApiAction.assignOrRemoveSuccess())
		);
	});

	assignOrRemoveSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentApiAction.assignOrRemoveSuccess),
			withLatestFrom(
				this.store.select(AccessAssignmentSelect.getAuthorityType),
				this.store.select(AccessAssignmentSelect.getEntityId),
				this.store.select(AccessAssignmentSelect.getAccessType)
			),
			mergeMap(([response, authorityType, entityId, accessType]) => {
				const authorityOperationAssigned = this.requestModelFactory.createOperationModel(
					entityId,
					authorityType,
					OperationTypeEnumeration.GET_ASSIGNED,
					[],
					accessType
				);
				const authorityOperationAvailable = this.requestModelFactory.createOperationModel(
					entityId,
					authorityType,
					OperationTypeEnumeration.GET_AVAILABLE,
					[],
					accessType
				);
				this.dialogService.close(DialogType.PROCESSING);
				return [
					AccessAssignmentPageAction.getAssigned({ authorityOperation: authorityOperationAssigned }),
					AccessAssignmentPageAction.getAvailable({ authorityOperation: authorityOperationAvailable }),
				];
			})
		);
	});

	assignOrRemoveForDocsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentPageAction.assignOrRemoveForDocs),
			mergeMap((action) => this.accessAssignmentService.operationForDocs(action.documentTypeModel)),
			map((response) => AccessAssignmentApiAction.assignOrRemoveForDocsSuccess({ response }))
		);
	});

	assignOrRemoveForDocsSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessAssignmentApiAction.assignOrRemoveForDocsSuccess),
			withLatestFrom(
				this.store.select(AccessAssignmentSelect.getEntityType),
				this.store.select(AccessAssignmentSelect.getEntityId),
				this.store.select(AccessAssignmentSelect.getAccessType)
			),
			mergeMap(([response, entityType, entityId, accessType]) => {
				const documentTypeModelAssigned = this.requestModelFactory.createDocumentTypeModel(
					entityId,
					entityType,
					OperationTypeEnumeration.GET_ASSIGNED,
					accessType
				);
				const documentTypeModelAssignedAvailable = this.requestModelFactory.createDocumentTypeModel(
					entityId,
					entityType,
					OperationTypeEnumeration.GET_AVAILABLE,
					accessType
				);
				this.dialogService.close(DialogType.PROCESSING);
				return [
					AccessAssignmentPageAction.getAssignedForDocs({ documentTypeModel: documentTypeModelAssigned }),
					AccessAssignmentPageAction.getAvailableForDocs({ documentTypeModel: documentTypeModelAssignedAvailable }),
				];
			})
		);
	});
}
