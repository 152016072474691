<div>
	<div [id]="'menu-item-' + label" class="menu-item" (click)="collapseItems()" fxLayout="row wrap">
		<div fxLayout="column" fxLayoutAlign="center" class="menu-icon">
			<mat-icon>{{ icon }}</mat-icon>
		</div>
		<div fxLayout="column" fxLayoutAlign="center">{{ label | translate }}</div>
		<div fxLayout="column" fxLayoutAlign="center" class="menu-icon">
			<mat-icon *ngIf="collapsed">arrow_drop_up</mat-icon>
			<mat-icon *ngIf="!collapsed">arrow_drop_down</mat-icon>
		</div>
	</div>
	<div *ngIf="collapsed" class="menu-items">
		<ng-container *ngFor="let child of children">
			<ea-menu-item [icon]="child.icon" [label]="child.label" [action]="child.action" [url]="child.url" [isChild]="true"> </ea-menu-item>
		</ng-container>
	</div>
</div>
