import { createAction, props } from '@ngrx/store';
import { StatisticFilter } from '../../model/statistic-filter';
import { StatisticTypeEnumeration } from '../../enum/statistic-type.enumeration';
import { ExportExtensionEnumeration } from '../../../shared/enumeration/export-extension.enumeration';

export const clearData = createAction('[Statistic] Clear search data');
export const search = createAction('[Statistic] Search', props<{ searchFilter: StatisticFilter }>());
export const setType = createAction('[Statistic] Set type', props<{ statisticType: StatisticTypeEnumeration }>());

export const exportToFile = createAction(
	'[Statistic] Export to file',
	props<{ searchFilter: StatisticFilter; exportType: ExportExtensionEnumeration }>()
);
