import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../user.state';
import { PermissionEnumeration } from '../../../shared/enumeration/permission.enumeration';

export const USER_FEATURE_STATE_NAME = 'user';
const FEATURE_STATE = createFeatureSelector<UserState>(USER_FEATURE_STATE_NAME);

export const getId = createSelector(FEATURE_STATE, (state) => state.id);
export const getToken = createSelector(FEATURE_STATE, (state) => state.token);
export const getLanguage = createSelector(FEATURE_STATE, (state) => state.language);
export const getUsername = createSelector(FEATURE_STATE, (state) => state.username);
export const getPermissions = createSelector(FEATURE_STATE, (state) => state.permissions);
export const isAuthenticated = createSelector(
	FEATURE_STATE,
	(state) => state.isAuthenticated && !state.permissions.includes(PermissionEnumeration.FORCING_PASSWORD_CHANGE)
);
export const getForgotPasswordToken = createSelector(FEATURE_STATE, (state) => state.forgotPasswordToken);
export const getFullName = createSelector(FEATURE_STATE, (state) => state.firstName + ' ' + state.lastName);
export const getUser = createSelector(FEATURE_STATE, (state) => state);
export const getUserType = createSelector(FEATURE_STATE, (state) => state.userType);
export const getUserCompanyIds = createSelector(FEATURE_STATE, (state) => state.companies.map((company) => company.id));
export const getCompanies = createSelector(FEATURE_STATE, (state) => state.companies);
export const getRefreshToken = createSelector(FEATURE_STATE, (state) => state.refreshToken);
export const getAccessToken = createSelector(FEATURE_STATE, (state) => state.accessToken);
export const getIsLogoutClicked = createSelector(FEATURE_STATE, (state) => state.isLogoutClicked);
export const getIsFormPage = createSelector(FEATURE_STATE, (state) => state.isFormPage);
export const getCompanyFilterOptions = createSelector(FEATURE_STATE, (state) => state.companyFilterOptions);
export const getUserIdAndType = createSelector(FEATURE_STATE, (state) => {
	return { id: state.id, type: state.userType };
});
