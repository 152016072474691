<ea-container title="premiumAbsence" [formGroup]="formGroup">
	<div fxLayout="column" class="sub-section-no-border" fxLayoutGap="5px">
		<div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">
			<div class="toggle-button-side-label">{{ 'hasReturnedToWork' | translate }}</div>
			<nc-toggle-buttons [formGroup]="formGroup" [options]="flags" [isDisabled]="isReadonly" formControlName="hasReturnedToWork">
			</nc-toggle-buttons>
		</div>
		<nc-input
			id="number-of-hours"
			*ngIf="formGroup.controls.hasReturnedToWork?.value === 'yes'"
			[isReadonly]="isReadonly"
			[mask]="{ mask: '00' }"
			[formGroup]="formGroup"
			formControlName="numberOfPossibleWorkHours"
			label="howManyHours"
		>
		</nc-input>
	</div>

	<div
		fxLayout="column"
		class="sub-section-no-border"
		fxLayoutGap="5px"
		*ngIf="formGroup.get('hasChildrenUnder18OrInEducationUpTo25').value === 'yes'"
	>
		<div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">
			<div class="toggle-button-side-label">{{ 'hasChildrenUnder18' | translate }}</div>
			<nc-toggle-buttons
				[formGroup]="formGroup"
				[options]="flags"
				[isDisabled]="isReadonly"
				formControlName="hasChildrenUnder18OrInEducationUpTo25"
			>
			</nc-toggle-buttons>
		</div>
		<nc-input
			id="number-of-children"
			[isReadonly]="isReadonly"
			[mask]="{ mask: '00' }"
			[formGroup]="formGroup"
			formControlName="numberOfChildren"
			label="howMany"
		>
		</nc-input>
	</div>

	<div class="sub-section" fxLayout="column">
		<span class="sub-section-title">{{ 'employmentRelationship' | translate }}</span>

		<div fxLayout="column" *ngIf="company.contractType !== 'TEMPORARY'">
			<div fxLayout="row wrap" fxLayoutGap="10px" class="flex-wrap">
				<nc-datepicker
					id="employment-start-date"
					[formGroup]="formGroup"
					formControlName="employmentStartDate"
					[required]="true"
					[isReadonly]="isReadonly"
					label="beginning"
				>
				</nc-datepicker>
				<nc-datepicker
					id="employment-end-date"
					[formGroup]="formGroup"
					[isReadonly]="isReadonly"
					formControlName="employmentEndDate"
					label="end"
				>
				</nc-datepicker>
			</div>
			<nc-input
				id="percentage-of-employment"
				[mask]="{ mask: '000' }"
				[formGroup]="formGroup"
				[isReadonly]="isReadonly"
				formControlName="employmentPercentage"
				[required]="true"
				label="percentageOfEmployment"
			>
			</nc-input>
			<nc-input
				id="employmentAnnualSalary"
				[mask]="numberMask"
				[isReadonly]="isReadonly"
				[formGroup]="formGroup"
				formControlName="employmentAnnualSalary"
				label="annualSalaryInYearOfInsuredEvent"
			>
			</nc-input>
		</div>
		<div fxLayout="column" fxLayoutGap="10px" *ngIf="company.contractType === 'TEMPORARY'" class="margin-top-10">
			<div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.sm="column" fxLayout.xs="column" class="flex-wrap">
				<nc-toggle-buttons
					[formGroup]="formGroup"
					[options]="employmentRegistration"
					[isDisabled]="isReadonly"
					label="firstDeploymentRegistration"
					formControlName="firstDeploymentRegistration"
				>
				</nc-toggle-buttons>
				<nc-toggle-buttons
					[formGroup]="formGroup"
					[isDisabled]="isReadonly"
					[options]="employmentSubject"
					label="firstDeploymentSubject"
					formControlName="firstDeploymentSubject"
				>
				</nc-toggle-buttons>
			</div>

			<div fxLayout="row wrap" fxLayoutGap="10px" class="flex-wrap">
				<nc-datepicker
					id="first-deployment"
					[formGroup]="formGroup"
					[isReadonly]="isReadonly"
					formControlName="firstDeploymentDate"
					label="firstDeployment"
				>
				</nc-datepicker>
				<nc-datepicker
					id="last-deployment"
					[formGroup]="formGroup"
					[isReadonly]="isReadonly"
					formControlName="lastDeploymentDate"
					label="lastDeployment"
				>
				</nc-datepicker>
			</div>
		</div>
	</div>
	<div class="sub-section">
		<ng-container *ngIf="formGroup.controls.absenceType.value !== ABSENCE_TYPE_ENUMERATION.ACCIDENT.code">
			<span class="sub-section-title">{{ 'notificationSentTo' | translate }}</span>
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">
					<div class="toggle-button-side-label">{{ 'dailySicknessBenefitsInsurance' | translate }}</div>
					<nc-toggle-buttons
						[formGroup]="formGroup"
						[options]="flags"
						[isDisabled]="isReadonly"
						formControlName="hasDailySicknessBenefitsInsurance"
					>
					</nc-toggle-buttons>
				</div>
				<ng-container *ngIf="formGroup.get('hasDailySicknessBenefitsInsurance').value === 'yes'">
					<div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="10px" fxLayoutGap.lt-xl="0px">
						<nc-input
							id="sickness-insurer"
							fxFlex="50"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							label="sicknessInsurer"
							formControlName="dailySicknessBenefitsInsuranceCompany"
						>
						</nc-input>
						<nc-input
							id="daily-sickness-sinister-reference"
							fxFlex="50"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							label="sinisterReference"
							formControlName="dailySicknessBenefitsInsuranceSinisterReference"
						>
						</nc-input>
					</div>
				</ng-container>
			</div>
		</ng-container>
		<ng-container *ngIf="formGroup.controls.absenceType.value === ABSENCE_TYPE_ENUMERATION.ACCIDENT.code">
			<span class="sub-section-title">{{ 'notificationSentTo' | translate }}</span>
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column">
					<div class="toggle-button-side-label">{{ 'accidentInsurance' | translate }}</div>
					<nc-toggle-buttons [formGroup]="formGroup" [isDisabled]="isReadonly" [options]="flags" formControlName="hasAccidentInsurance">
					</nc-toggle-buttons>
				</div>
				<ng-container *ngIf="formGroup.get('hasAccidentInsurance').value === 'yes'">
					<div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="10px" fxLayoutGap.lt-xl="0px">
						<nc-input
							id="accident-insurer"
							fxFlex="50"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							label="accidentInsurer"
							formControlName="accidentInsuranceCompany"
						>
						</nc-input>
						<nc-input
							id="accident-insurance-sinister-reference"
							fxFlex="50"
							[isReadonly]="isReadonly"
							[formGroup]="formGroup"
							label="sinisterReference"
							formControlName="accidentInsuranceSinisterReference"
						>
						</nc-input>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>
</ea-container>
