import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent, Option } from 'nc-shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { KlePageAction, KleSelect } from '../../state/action';
import { DeclareIncidentResponse } from '../../model/response/declare-incident.response';
import { DeclareIncidentTestRequest } from '../../model/request/declare-incident-test-request';

@Component({
	selector: 'ea-declare-incident',
	templateUrl: './declare-incident.component.html',
	styleUrls: ['declare-incident.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeclareIncidentComponent extends BaseComponent implements OnDestroy {
	formGroup: FormGroup;
	exampleList$: Observable<Option[]>;

	declareIncident$: Observable<DeclareIncidentResponse>;

	constructor(private store: Store, formBuilder: FormBuilder) {
		super();
		this.formGroup = formBuilder.group({
			example: [1],
		});

		this.exampleList$ = of([
			{ text: 'TF01', value: 1 },
			{ text: 'TF02', value: 2 },
			{ text: 'TF03', value: 3 },
			{ text: 'TF04', value: 4 },
			{ text: 'TF05', value: 5 },
			{ text: 'TF06', value: 6 },
		]);
		this.declareIncident$ = this.store.select(KleSelect.getDeclareIncident);
	}

	onCheck = (): void => {
		const data = this.formGroup.getRawValue() as DeclareIncidentTestRequest;
		this.store.dispatch(KlePageAction.declareIncident({ request: data }));
	};

	ngOnDestroy(): void {
		this.store.dispatch(KlePageAction.clearResponseData());
	}
}
