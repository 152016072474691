<form fxLayout="column" fxFill [formGroup]="formGroup">
	<div class="header-content" fxLayout="column" fxLayoutAlign="center">
		<span>{{ 'absenceCode' | translate }}: {{ absenceCode }}</span>
	</div>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<div fxLayout="row" fxLayoutGap="10px">
					<div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
						<div fxLayout="row" fxLayoutGap="5px">
							<nc-input
								id="insured-person"
								fxFlex="95"
								[isReadonly]="true"
								[formGroup]="formGroup"
								formControlName="employee"
								label="insuredPerson"
							>
							</nc-input>
							<mat-icon class="more-details-action" (click)="employeeDetails()">info</mat-icon>
						</div>
						<ea-absence-type [formGroup]="formGroup" [isReadonly]="true"></ea-absence-type>
						<nc-datepicker id="created-on" [formGroup]="formGroup" [isReadonly]="true" formControlName="createdOn" label="createdOn">
						</nc-datepicker>
						<nc-input id="created-by" [formGroup]="formGroup" formControlName="createdBy" label="createdBy" [isReadonly]="true">
						</nc-input>
						<nc-textarea id="comment" [formGroup]="formGroup" formControlName="comment" label="comment" [isReadonly]="isReadOnly">
						</nc-textarea>
						<div *ngIf="otherAbsences?.length > 0">
							<nc-label id="other-active-absence-cases" value="otherActiveAbsenceCases"></nc-label>
							<div fxLayout="row wrap" fxLayoutGap="10px">
								<a *ngFor="let absence of otherAbsences" [routerLink]="['/absence', absence.code]">{{ absence.code }}</a>
							</div>
						</div>

						<nc-input
							id="status-1"
							[formGroup]="formGroup"
							formControlName="statusByMilestone"
							label="status"
							[isReadonly]="true"
						></nc-input>

						<ng-container *ngIf="formGroup.controls?.isArchived?.value">
							<nc-input
								id="archiving-reason"
								[isReadonly]="true"
								[formGroup]="formGroup"
								formControlName="archivedReason"
								label="archivingReason"
							>
							</nc-input>
							<nc-textarea
								id="archived-comment"
								[isReadonly]="true"
								[formGroup]="formGroup"
								formControlName="archivedComment"
								label="archivingComment"
							>
							</nc-textarea>
						</ng-container>
					</div>
					<div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
						<ea-premium-details
							*ngIf="formGroup.controls?.categoryType?.value === ABSENCE_DECLARATION_TYPE.PREMIUM.code"
							[isReadonly]="isReadOnly"
							[formGroup]="formGroup"
							[company]="company$ | async"
						>
						</ea-premium-details>
					</div>
				</div>

				<ea-incapacity-table
					[formArray]="incapacities"
					[isReadonly]="isReadOnly"
					[isAccident]="formGroup.controls?.absenceType.value === this.ABSENCE_TYPE_ENUMERATION.ACCIDENT.code"
					[isKleEnabled]="isKleEnabled"
					[absenceState]="absenceState"
					[branchHrLoggedIn]="isBranchHr"
					[caseWasOpen]="caseWasOpen"
				></ea-incapacity-table>

				<ea-container title="shareInformationTableTitle" *ngIf="(shareInformation$ | async)?.records?.length > 0" cssClass="margin-top-15">
					<div class="margin-top-10">
						<nc-datatable
							id="share-information"
							[rowClick]="shareInformation"
							[data$]="shareInformation$"
							[columns]="columns.SHARE_INFORMATION_COLUMNS"
							[filterConfig]="shareInformationFilterConfig"
						>
						</nc-datatable>
					</div>
				</ea-container>
				<ea-documents
					id="hr-absence-document"
					buttonId="open-dialog"
					downloadButtonId="download-documents-button"
					[isReadonly]="isReadOnly"
					[documentsFormArray]="documents"
					[additionalColumns]="additionalColumns"
					[hideDelete]="true"
				>
				</ea-documents>

				<ea-container title="linkedRequests" *ngIf="(linkedAbsence$ | async)?.records?.length > 0" cssClass="margin-top-15">
					<div class="margin-top-10">
						<nc-datatable
							id="linked-request"
							[rowClick]="messageClicked"
							[data$]="linkedAbsence$"
							[columns]="columns.LINKED_ABSENCE_COLUMNS"
							[filterConfig]="linkedRequestFilterConfig"
						></nc-datatable>
					</div>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-hr-absence-footer
		[formGroup]="formGroup"
		[isReadOnly]="isReadOnly"
		[code]="absenceCode"
		[absence]="absence$ | async"
		[company]="company$ | async"
	></ea-hr-absence-footer>
</form>
