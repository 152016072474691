import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { SettingsPageAction, SettingsSelect } from '../../../../state/settings/action';
import { NotificationsService } from '../../../../notifications/service/notifications.service';
import { WebSocketService } from '../../../service/websocket.service';
import { UserSelect } from '../../../../user/state/action';
import { DialogConfigurationUtils, DialogService } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'ea-menu-item',
	templateUrl: './menu-item.component.html',
	styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit, AfterViewInit {
	@Input() url: string;
	@Input() icon: string;
	@Input() label: string;
	@Input() infoTooltip: string;
	@Input() action: TypedAction<any>;
	@Input() isChild = false;
	activeMenuItem$: Observable<string>;
	idText: string;

	numberOfNotifications = '0';
	username: string;

	constructor(
		private store: Store,
		private notificationService: NotificationsService,
		private webSocketService: WebSocketService,
		private dialogService: DialogService,
		private router: Router
	) {
		this.activeMenuItem$ = store.select(SettingsSelect.getActiveMenuItem);
		this.store.select(UserSelect.getUsername).subscribe((username) => (this.username = username));
	}

	ngAfterViewInit(): void {
		if (this.icon === 'notifications') {
			const stompClient = this.webSocketService.connect();
			stompClient.connect({}, () => {
				stompClient.subscribe('/topic/notification/' + this.webSocketService.getSessionId(), (notifications) => {
					this.numberOfNotifications = notifications.body;
				});
			});
			this.notificationService.getNumberOfNotificationsForLoggedInUser().subscribe((data) => {
				if (data) {
					this.numberOfNotifications = data.count;
				}
			});
		}

		if (this.icon === 'add') {
			const stompClient = this.webSocketService.connect();
			stompClient.connect({}, () => {
				stompClient.subscribe('/topic/generate-documents-for-user/' + this.username, (message) => {
					if (!this.isKlePageOpened()) {
						const config = DialogConfigurationUtils.info(message.body, 'info');
						this.dialogService.open(NcInformationDialogComponent, config);
					}
				});
			});
		}
	}

	ngOnInit(): void {
		this.idText = this.isChild ? 'sub-menu-item-' : 'menu-item-';
	}

	onClick = () => {
		if (this.action) {
			this.store.dispatch(this.action);
		} else {
			this.store.dispatch(SettingsPageAction.openMenuItem({ menuItem: this.url }));
		}
	};

	isKlePageOpened(): boolean {
		return this.router.url.includes('absence') && this.router.url.includes('kle');
	}
}
