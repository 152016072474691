import * as ApplicationState from '../../state/app.state';
import { TableData } from 'nc-datatable';

export interface UserAdministrationState {
	users: TableData;
	user: any;
	generatedPassword: string;
	copiedCompanies: string[];
}

export interface State extends ApplicationState.State {
	user_administration: UserAdministrationState;
}

export const USER_ADMINISTRATION_INITIAL_STATE: UserAdministrationState = {
	users: { records: [] },
	user: null,
	generatedPassword: '',
	copiedCompanies: [],
};
