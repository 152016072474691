import { createAction, props } from '@ngrx/store';
import { DocumentModel } from 'nc-documents';
import { UserTypeEnumeration } from '../../../shared/enumeration/user-type.enumeration';
import { UserStatusEnumeration } from '../../../shared/enumeration/user-status.enumeration';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';

export const setReady = createAction('[Settings] Set ready', props<{ ready: boolean }>());
export const setLanguage = createAction('[Settings] Set language', props<{ language: string }>());
export const openMenuItem = createAction('[Settings] Open menu item', props<{ menuItem: string }>());
export const setMenuItem = createAction('[Settings] Set menu item', props<{ menuItem: string }>());
export const navigate = createAction('[Settings] Navigates to url', props<{ url: string }>());
export const downloadDocumentForPreview = createAction('[Settings] Download document for preview', props<{ id: string }>());
export const downloadDocument = createAction('[Settings] Download document', props<{ id: string }>());
export const deleteDocument = createAction('[Settings] Delete document', props<{ documentId: string }>());
export const openDeleteDocumentConfirmationDialog = createAction('[Settings] Open dialog', props<{ document: DocumentModel }>());
export const downloadMultipleDocuments = createAction('[Settings] Download multiple documents', props<{ documentIds: number[] }>());
export const setSelectedNumberOfDays = createAction(
	'[Settings] Set selected number of days',
	props<{ selectedNumberOfDays: number[]; loggedUser: string }>()
);
export const setDashboardTableFilters = createAction(
	'[Dashboard Filter] Set filter value for milestone table',
	props<{
		dashboardFilters: {
			milestoneFilter: string;
			tasksFilter: string;
			activeCasesFilter: string;
			selectedCompanies: number[];
			sortByColumnMilestone: string;
			orderMilestone: string;
			sortByColumnTasks: string;
			orderTasks: string;
			sortByColumnAbsence: string;
			orderAbsence: string;
		};
		loggedUser: string;
	}>()
);
export const setLocalStorageInitialData = createAction(
	'[Local Storage] Set initial data for logged user',
	props<{ username: string; userPreferences: any }>()
);
export const setArchivedCasesValues = createAction(
	'[Archived Cases] Set filter value for archived cases',
	props<{
		data: {
			responsibleUser: string;
			caseFilter: string;
			selectedCompanies: number[];
			sortByColumn: string;
			order: string;
		};
		username: string;
	}>()
);
export const closeDialog = createAction('[Settings] Close dialog');
export const setUserAdministrationTableFilter = createAction(
	'[User administration Filter] Set filter value for user administration table',
	props<{
		userAdministrationFilters: {
			neoIdFilter: string;
			roleFilter: UserTypeEnumeration;
			nameFilter: string;
			companyNameFilter: string;
			usernameFilter: string;
			emailFilter: string;
			activeFilter: UserStatusEnumeration;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
export const setAdministrationTableFilter = createAction(
	'[Administration Filter] Set filter value for administration table',
	props<{
		administrationFilters: {
			nameFilter: string;
			defaultAssigneeFilter: string;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
export const setMilestoneTableFilter = createAction(
	'[Milestone Filter] Set filter value for milestone table',
	props<{
		milestoneFilters: {
			globalFilter: string;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
export const setLabelManagementTableFilter = createAction(
	'[Label Management Filter] Set filter value for label management table',
	props<{
		labelManagementFilters: {
			globalFilter: string;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
export const setLdResponsibleTableFilter = createAction(
	'[Ld responsible Filter] Set filter value for ld responsible table',
	props<{
		ldResponsibleFilters: {
			globalFilter: string;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
export const setDocumentTypesTableFilter = createAction(
	'[Document types Filter] Set filter value for document types table',
	props<{
		documentTypesFilters: {
			globalFilter: string;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
export const setCompanyTableFilter = createAction(
	'[Company Filter] Set filter value for company filter table',
	props<{
		companyFilters: {
			nameFilter: string;
			easyTempIdFilter: string;
			neoIdFilter: string;
			neoEnvironmentFilter: string;
			uidFilter: string;
			isKleEnabledFilter: FlagEnumeration;
			isSwicaInsuredFilter: FlagEnumeration;
			isPkProInsuredFilter: FlagEnumeration;
			externallyUpdatedOnFilter: string;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
export const setMedicalInstitutionsTableFilter = createAction(
	'[Medical institutions Filter] Set filter value for medical institutions filter table',
	props<{
		medicalInstitutionsFilters: {
			globalLocationNumberFilter: string;
			doctorFirstNameFilter: string;
			doctorLastNameFilter: string;
			institutionNameFilter: string;
			additionalInstitutionNameFilter: string;
			streetFilter: string;
			cityFilter: string;
			zipFilter: string;
			countryFilter: string;
			lastChangeDateFilter: string;
			sortByColumn: string;
			order: string;
		};
		loggedUser: string;
	}>()
);
