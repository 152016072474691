import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogMessage } from '../../model/dialog-message';
import { WorkplaceMessage } from '../../model/workplace-message';
import { ReasonForAbsenceMessage } from '../../model/reason-for-absence-message';
import { DialogMessageField } from '../../model/dialog-message-field';
import { FormFieldTypes } from 'nc-form-generator/lib/models/generic-form-data.model';

@Injectable({ providedIn: 'root' })
export class DialogMessageService {
	/**
	 * Populate form with default data from message
	 * @param formGroup
	 * @param message Data from backend
	 */
	public populateConfirmationMessageForm = (formGroup: FormGroup, message: DialogMessage): void => {
		const answerValue =
			message.confirmationMessage.answerValue != null
				? message.confirmationMessage.answerValue
				: message.confirmationMessage.answerDefaultValue;
		formGroup.controls.answerValue.patchValue(answerValue);
		formGroup.controls.title.patchValue(message.title);
		formGroup.controls.message.patchValue(message.message);
	};

	public populateContactPersonMessageForm = (formGroup: FormGroup, message: DialogMessage): void => {
		formGroup.controls.title.patchValue(message.title);
		formGroup.controls.message.patchValue(message.message);
		formGroup.controls.creator.patchValue(message.creator);
		formGroup.controls.description.patchValue(message.description);
		formGroup.controls.firstName.patchValue(this.getAnswerValue(message.contactPersonMessage.firstName));
		formGroup.controls.lastName.patchValue(this.getAnswerValue(message.contactPersonMessage.lastName));
		formGroup.controls.phone.patchValue(this.getAnswerValue(message.contactPersonMessage.phone));
		formGroup.controls.function.patchValue(this.getAnswerValue(message.contactPersonMessage.function));
		formGroup.controls.email.patchValue(this.getAnswerValue(message.contactPersonMessage.email));
	};

	public populateWorkplaceMessageForm = (formGroup: FormGroup, message: DialogMessage, workplaceMessage: WorkplaceMessage): void => {
		formGroup.controls.title.patchValue(message.title);
		formGroup.controls.message.patchValue(message.message);
		formGroup.controls.creator.patchValue(message.creator);
		formGroup.controls.description.patchValue(message.description);
		formGroup.controls.sendPictureDate.patchValue(workplaceMessage.sendPictureDate.value);
		formGroup.controls.firstName.patchValue(this.getAnswerValue(workplaceMessage.firstName));
		formGroup.controls.lastName.patchValue(this.getAnswerValue(workplaceMessage.lastName));
		formGroup.controls.phone.patchValue(this.getAnswerValue(workplaceMessage.phone));
		formGroup.controls.function.patchValue(this.getAnswerValue(workplaceMessage.function));
		formGroup.controls.email.patchValue(this.getAnswerValue(workplaceMessage.email));
		formGroup.controls.professionalTraining.patchValue(this.getAnswerValue(workplaceMessage.professionalTraining));
		formGroup.controls.mainActivity.patchValue(this.getAnswerValue(workplaceMessage.mainActivity));
		formGroup.controls.workingHours.patchValue(this.getAnswerValue(workplaceMessage.workingHours));
		formGroup.controls.employmentLevel.patchValue(this.getAnswerValue(workplaceMessage.employmentLevel));
		formGroup.controls.fullWorkingHours.patchValue(this.getAnswerValue(workplaceMessage.fullWorkingHours));
		formGroup.controls.normalFullWorkingHours.patchValue(this.getAnswerValue(workplaceMessage.normalFullWorkingHours));
		formGroup.controls.comment.patchValue(this.getAnswerValue(workplaceMessage.comment));
		formGroup.controls.seatedWorkplace.patchValue(this.getAnswerValue(workplaceMessage.seatedWorkplace));
		formGroup.controls.standingWorkstation.patchValue(this.getAnswerValue(workplaceMessage.standingWorkstation));
		formGroup.controls.sitStandWorkstation.patchValue(this.getAnswerValue(workplaceMessage.sitStandWorkstation));
		formGroup.controls.computerWorkstation.patchValue(this.getAnswerValue(workplaceMessage.computerWorkstation));
		formGroup.controls.visualControlActivity.patchValue(this.getAnswerValue(workplaceMessage.visualControlActivity));
		formGroup.controls.customerContact.patchValue(this.getAnswerValue(workplaceMessage.customerContact));
		formGroup.controls.teamwork.patchValue(this.getAnswerValue(workplaceMessage.teamwork));
		formGroup.controls.noise.patchValue(this.getAnswerValue(workplaceMessage.noise));
		formGroup.controls.heatColdVapors.patchValue(this.getAnswerValue(workplaceMessage.heatColdVapors));
		formGroup.controls.smell.patchValue(this.getAnswerValue(workplaceMessage.smell));
		formGroup.controls.dustExposure.patchValue(this.getAnswerValue(workplaceMessage.dustExposure));
		formGroup.controls.frequentPostureInTwistedPosition.patchValue(this.getAnswerValue(workplaceMessage.frequentPostureInTwistedPosition));
		formGroup.controls.preciseUseOfHands.patchValue(this.getAnswerValue(workplaceMessage.preciseUseOfHands));
		formGroup.controls.increasedRiskOfAccidents.patchValue(this.getAnswerValue(workplaceMessage.increasedRiskOfAccidents));
		formGroup.controls.liftLessThen5kg.patchValue(this.getAnswerValue(workplaceMessage.liftLessThen5kg));
		formGroup.controls.liftMoreThen5kg.patchValue(this.getAnswerValue(workplaceMessage.liftMoreThen5kg));
		formGroup.controls.liftMoreThen10kg.patchValue(this.getAnswerValue(workplaceMessage.liftMoreThen10kg));
		formGroup.controls.liftMoreThen15kg.patchValue(this.getAnswerValue(workplaceMessage.liftMoreThen15kg));
		formGroup.controls.otherSpecialRequirement.patchValue(this.getAnswerValue(workplaceMessage.otherSpecialRequirement));
		formGroup.controls.dailyOperationFixed.patchValue(this.getAnswerValue(workplaceMessage.dailyOperationFixed));
		formGroup.controls.daytimeOperationGliding.patchValue(this.getAnswerValue(workplaceMessage.daytimeOperationGliding));
		formGroup.controls.nightShiftsNecessary.patchValue(this.getAnswerValue(workplaceMessage.nightShiftsNecessary));
		formGroup.controls.exclusivelyNightShifts.patchValue(this.getAnswerValue(workplaceMessage.exclusivelyNightShifts));
		formGroup.controls.otherSpecialWorkingHours.patchValue(this.getAnswerValue(workplaceMessage.otherSpecialWorkingHours));
		formGroup.controls.available.patchValue(this.getAnswerValue(workplaceMessage.available));
		formGroup.controls.onlyTemporaryPossible.patchValue(this.getAnswerValue(workplaceMessage.onlyTemporaryPossible));
		formGroup.controls.notAvailable.patchValue(this.getAnswerValue(workplaceMessage.notAvailable));
		formGroup.controls.descriptionOfThePossibleGentleWorkstation.patchValue(
			this.getAnswerValue(workplaceMessage.descriptionOfThePossibleGentleWorkstation)
		);
		formGroup.controls.desiredByTheDoctor.patchValue(this.getAnswerValue(workplaceMessage.desiredByTheDoctor));
		formGroup.controls.withInsuranceTakesPlaceOn.patchValue(this.getAnswerValue(workplaceMessage.withInsuranceTakesPlaceOn));
		formGroup.controls.comments.patchValue(this.getAnswerValue(workplaceMessage.comments));
	};

	public populateReasonForAbsenceMessageForm = (
		formGroup: FormGroup,
		message: DialogMessage,
		reasonForAbsenceMessage: ReasonForAbsenceMessage
	): void => {
		let otherReason = reasonForAbsenceMessage.otherReason.answerValue
			? reasonForAbsenceMessage.otherReason.answerValue
			: reasonForAbsenceMessage.otherReason.answerDefaultValue;

		formGroup.controls.title.patchValue(message.title);
		formGroup.controls.creator.patchValue(message.creator);
		formGroup.controls.description.patchValue(message.description);
		formGroup.controls.info.patchValue(reasonForAbsenceMessage.info);
		formGroup.controls.disease.patchValue(this.getAnswerValue(reasonForAbsenceMessage.disease));
		formGroup.controls.accident.patchValue(this.getAnswerValue(reasonForAbsenceMessage.accident));
		formGroup.controls.maternity.patchValue(this.getAnswerValue(reasonForAbsenceMessage.maternity));
		formGroup.controls.militaryService.patchValue(this.getAnswerValue(reasonForAbsenceMessage.militaryService));
		formGroup.controls.vacation.patchValue(this.getAnswerValue(reasonForAbsenceMessage.vacation));
		formGroup.controls.unpaidLeave.patchValue(this.getAnswerValue(reasonForAbsenceMessage.unpaidLeave));
		formGroup.controls.leavingCompany.patchValue(this.getAnswerValue(reasonForAbsenceMessage.leavingCompany));
		formGroup.controls.activityWithAnotherEmployer.patchValue(this.getAnswerValue(reasonForAbsenceMessage.activityWithAnotherEmployer));
		formGroup.controls.otherReason.patchValue(otherReason);
	};

	getAnswerValue = (dialogMessageField: DialogMessageField): FormFieldTypes => {
		if (dialogMessageField.answerType === 'BOOLEAN') {
			return dialogMessageField.answerValue === 'true' || dialogMessageField.answerDefaultValue === 'true';
		} else {
			return dialogMessageField.answerValue || dialogMessageField.answerDefaultValue;
		}
	};
}
