import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { State } from '../../../state/app.state';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'nc-shared';
import { UserPageAction } from '../../state/action';
import { EnvironmentLoader } from '../../../core/config/environment-loader';
import { EcareConstants } from '../../../shared/environment-constants/ecare-constants';

@Component({
	selector: 'ea-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
	formGroup: FormGroup;
	languageCode: string;
	theme: string;
	LOGO_URL = EcareConstants.LOGO_URI;

	constructor(private store: Store<State>, private formBuilder: FormBuilder, private environmentLoader: EnvironmentLoader) {
		super();
	}

	ngOnInit(): void {
		this.theme = this.environmentLoader.getEnvironment().theme;

		this.formGroup = this.formBuilder.group({
			username: ['', [Validators.required]],
			password: ['', [Validators.required]],
		});
	}

	onSubmit = (): void => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.loginSubmit(data));
	};

	redirect(): void {
		window.location.href = this.environmentLoader.getEnvironment().HR_UI_URL;
	}
}
