<div id="archive-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div mat-dialog-title>
		<span class="dialog-title-text">{{ 'declareIncident' | translate }}</span>
	</div>
	<mat-dialog-content id="archive-dialog-content" [formGroup]="formGroup" fxLayout="column" fxLayoutGap="10px">
		<div fxLayout="row" fxLayoutGap="10px">
			<nc-toggle-buttons [formGroup]="formGroup" [options]="types" formControlName="type"></nc-toggle-buttons>
		</div>
		<div fxLayout="row" fxLayoutGap="10px" *ngIf="formGroup.controls.type.value === declareEnum.RELAPSE.code">
			<nc-datepicker id="relapseDate" [formGroup]="formGroup" formControlName="relapseDate" label="relapseDate"></nc-datepicker>
		</div>
		<div fxLayout="row" fxLayoutGap="10px">
			<nc-date-time-picker
				id="exactDate"
				*ngIf="!formGroup.value.approximateDate && !formGroup.value.clarification"
				[formGroup]="formGroup"
				formControlName="exactDate"
				label="storyExactDate"
				[max]="data.firstIncapacityValidFrom"
			>
			</nc-date-time-picker>
			<nc-datepicker
				id="approximateDate"
				*ngIf="!formGroup.value.exactDate && !formGroup.value.clarification"
				[formGroup]="formGroup"
				formControlName="approximateDate"
				label="storyApproxDate"
				[max]="data.firstIncapacityValidFrom"
			>
			</nc-datepicker>
			<nc-checkbox
				*ngIf="!formGroup.value.approximateDate && !formGroup.value.exactDate"
				id="clarification"
				[formGroup]="formGroup"
				formControlName="clarification"
				label="clarification"
				[ngClass]="'margin-top-15'"
			>
			</nc-checkbox>
		</div>
		<div *ngIf="showExactAproximateDateError()" class="error-container">
			{{ 'incidentDateAnyMandatory' | translate }}
		</div>
		<div fxLayout="row" fxLayoutGap="10px" [ngClass]="{ 'margin-top-25': isDateErrorMessagesLong() }">
			<nc-input id="insuranceCaseId" [formGroup]="formGroup" formControlName="insuranceCaseId" label="insuranceCaseId" fxFlex="70"></nc-input>
			<nc-checkbox
				id="sendWithoutContract"
				[formGroup]="formGroup"
				formControlName="sendWithoutContract"
				label="sendWithoutContract"
				[ngClass]="'margin-top-15'"
			>
			</nc-checkbox>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
			<nc-button id="close" label="close" [mat-dialog-close]="{ success: false }"></nc-button>
			<nc-button id="declare" label="declareIncident" [onClick]="submit"></nc-button>
		</div>
	</mat-dialog-actions>
</div>
