import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { FormArray, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumUtils } from 'nc-utils';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { FlagWithNoneEnumeration } from '../../../shared/enumeration/flag-with-none.enumeration';
import { MilestoneSettingsPageAction, MilestoneSettingsSelect } from '../../state/action';
import { Observable } from 'rxjs';
import { MilestoneSettingsModel } from '../../model/milestone-settings';
import { filter, takeUntil } from 'rxjs/operators';
import {
	dayGreaterThanZero,
	mandatoryAbsenceType,
	noteAtLeastOneLanguage,
	titleAtLeastOneLanguage,
} from '../../validator/milestone-settings.validator';
import { MilestoneSettingsModelFactory } from '../../service/milestone-settings-model.factory';
import { ColumnBuilder, DatatableColumn, SelectConfig, TableData } from 'nc-datatable';
import { ErrorSelect } from '../../../state/error/action';
import { ValidationService } from '../../../shared/service/validation.service';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './milestone-settings-form.component.html',
	styleUrls: ['./milestone-settings-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneSettingsFormComponent extends CanDeactivateFormComponent implements OnInit {
	yesNoNone: Option[];
	yesNo: Option[];
	milestoneSetting$: Observable<MilestoneSettingsModel>;
	documentTypes$: Observable<TableData>;
	columns: DatatableColumn[] = [ColumnBuilder.create('code', 'code'), ColumnBuilder.create('name', 'name')];
	documentTypes: FormArray;
	selectConfig: SelectConfig;
	filterConfig: FilterConfig;

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private factory: MilestoneSettingsModelFactory,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();

		this.route.params.subscribe((params) => {
			if (params.id) {
				this.store.dispatch(MilestoneSettingsPageAction.getSingleMilestoneSettings({ id: params.id }));
			}
		});

		this.milestoneSetting$ = this.store.select(MilestoneSettingsSelect.getCurrentMilestoneSetting);
		this.documentTypes$ = this.store.select(MilestoneSettingsSelect.getDocumentsCheckList);

		this.store.dispatch(MilestoneSettingsPageAction.getDocumentsCheckList());
		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));
		this.filterConfig = {
			filterValues: {},
		};
	}

	ngOnInit(): void {
		this.yesNoNone = EnumUtils.toItems(FlagWithNoneEnumeration);
		this.yesNo = EnumUtils.toItems(FlagEnumeration);

		this.formGroup = this.formBuilder.group(
			{
				id: ['', null],
				day: ['', null],
				pkProInsured: ['NONE', null],
				swicaInsured: ['NONE', null],
				forCMUser: ['NONE', null],
				forLDUser: ['NONE', null],
				forCaseManagementActive: ['NONE', null],
				important: ['no', null],
				forAbsenceTypeSickness: ['no', null],
				forAbsenceTypeAccident: ['no', null],
				forAbsenceTypePregnancy: ['no', null],
				sicknessTypeMentalIllness: ['NONE', null],
				sicknessTypeBackProblem: ['NONE', null],
				sicknessTypeUnknown: ['NONE', null],
				sicknessTypeOther: ['NONE', null],
				accidentTypeWork: ['NONE', null],
				accidentTypeFreeTime: ['NONE', null],
				titleDe: ['', null],
				titleEn: ['', null],
				titleFr: ['', null],
				titleIt: ['', null],
				noteDe: ['', null],
				noteEn: ['', null],
				noteFr: ['', null],
				noteIt: ['', null],
				cmStatusesDe: ['', null],
				cmStatusesEn: ['', null],
				cmStatusesFr: ['', null],
				cmStatusesIt: ['', null],
				hrStatusesDe: ['', null],
				hrStatusesEn: ['', null],
				hrStatusesFr: ['', null],
				hrStatusesIt: ['', null],
				documentTypes: this.formBuilder.array([]),
			},
			{
				validator: [mandatoryAbsenceType(), dayGreaterThanZero(), noteAtLeastOneLanguage(), titleAtLeastOneLanguage()],
			}
		);

		this.selectConfig = {
			identifierColumn: 'code',
			formArray: this.formGroup.get('documentTypes') as FormArray,
		};

		this.milestoneSetting$
			.pipe(
				filter((milestoneSetting) => milestoneSetting !== null && milestoneSetting.id !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((milestoneSetting) => this.displayMilestoneSetting(milestoneSetting));
	}

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue();
		const milestoneSettings = this.factory.create(data);
		this.store.dispatch(MilestoneSettingsPageAction.saveMilestoneSetting({ milestoneSetting: milestoneSettings }));
	};

	displayMilestoneSetting = (milestoneSettings: MilestoneSettingsModel): void => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			id: milestoneSettings.id,
			day: milestoneSettings.day.toString(),
			pkProInsured: milestoneSettings.pkProInsured,
			swicaInsured: milestoneSettings.swicaInsured,
			forCMUser: milestoneSettings.forCMUser,
			forLDUser: milestoneSettings.forLDUser,
			forCaseManagementActive: milestoneSettings.forCaseManagementActive,
			important: milestoneSettings.important ? 'yes' : 'no',
			forAbsenceTypeSickness: milestoneSettings.forAbsenceTypeSickness ? 'yes' : 'no',
			forAbsenceTypeAccident: milestoneSettings.forAbsenceTypeAccident ? 'yes' : 'no',
			forAbsenceTypePregnancy: milestoneSettings.forAbsenceTypePregnancy ? 'yes' : 'no',
			sicknessTypeMentalIllness: milestoneSettings.sicknessTypeMentalIllness,
			sicknessTypeBackProblem: milestoneSettings.sicknessTypeBackProblem,
			sicknessTypeUnknown: milestoneSettings.sicknessTypeUnknown,
			sicknessTypeOther: milestoneSettings.sicknessTypeOther,
			accidentTypeWork: milestoneSettings.accidentTypeWork,
			accidentTypeFreeTime: milestoneSettings.accidentTypeFreeTime,
			titleDe: milestoneSettings.title.de,
			titleEn: milestoneSettings.title.en,
			titleFr: milestoneSettings.title.fr,
			titleIt: milestoneSettings.title.it,
			noteDe: milestoneSettings.note.de,
			noteEn: milestoneSettings.note.en,
			noteFr: milestoneSettings.note.fr,
			noteIt: milestoneSettings.note.it,
			cmStatusesDe: milestoneSettings.cmStatus ? milestoneSettings.cmStatus.de : '',
			cmStatusesEn: milestoneSettings.cmStatus ? milestoneSettings.cmStatus.en : '',
			cmStatusesFr: milestoneSettings.cmStatus ? milestoneSettings.cmStatus.fr : '',
			cmStatusesIt: milestoneSettings.cmStatus ? milestoneSettings.cmStatus.it : '',
			hrStatusesDe: milestoneSettings.hrStatus ? milestoneSettings.hrStatus.de : '',
			hrStatusesEn: milestoneSettings.hrStatus ? milestoneSettings.hrStatus.en : '',
			hrStatusesFr: milestoneSettings.hrStatus ? milestoneSettings.hrStatus.fr : '',
			hrStatusesIt: milestoneSettings.hrStatus ? milestoneSettings.hrStatus.it : '',
		});

		this.selectConfig.formArray.clear();
		milestoneSettings.documentTypes.forEach((type) => this.selectConfig.formArray.push(this.formBuilder.group({ value: [type] })));
	};
}
