import { createAction, props } from '@ngrx/store';
import { LdResponsibleModel } from '../../model/ld-responsible.model';
import { Option } from 'nc-shared';
import { ActionResponse, SaveResponse } from 'nc-utils';

export const getLdResponsiblesSuccess = createAction(
	'[LdResponsible API] Get ldResponisbles success',
	props<{ records: { [key: number]: string }[] }>()
);

export const getLdResponsibleSuccess = createAction('[LdResponsible API] Get LdResponsible success', props<{ ldResponsible: LdResponsibleModel }>());

export const initializeUserOptionsSuccess = createAction(
	'[LdResponsible API] Initialize select success',
	props<{ responsibleUserOptions: Option[] }>()
);

export const saveLdResponsibleSuccess = createAction('[LdResponsible API] Save LdResponsible success', props<{ response: ActionResponse }>());
