import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UserPageAction } from '../../state/action';
import { ErrorSelect } from '../../../state/error/action';
import { areNewAndOldPasswordsEqual, arePasswordsEqual } from '../../validator/change-password.validator';
import { ValidationService } from '../../../shared/service/validation.service';
import { CanDeactivateFormComponent } from 'nc-shared';

@Component({
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent extends CanDeactivateFormComponent {
	constructor(private store: Store, formBuilder: FormBuilder, changeDetectorRef: ChangeDetectorRef, private validationService: ValidationService) {
		super();
		this.formGroup = formBuilder.group(
			{
				oldPassword: ['', Validators.required],
				newPassword: ['', Validators.required],
				confirmationPassword: ['', Validators.required],
			},
			{
				validators: [arePasswordsEqual(), areNewAndOldPasswordsEqual()],
			}
		);

		this.store.select(ErrorSelect.getErrors).subscribe((errors) => validationService.displayErrors(errors, this.formGroup, changeDetectorRef));
	}

	dispatchSubmitAction = () => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.changePasswordSubmit(data));
	};
}
