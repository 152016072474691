import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AbsencePageAction } from '../../../absence/state/action';
import { BroadcastEventType } from '../../../shared/enumeration/broadcast-event-type.enumeration';
import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { EventQueueService } from '../../../shared/service/event-queue.service';
import { CanDeactivateFormComponent } from 'nc-shared';
import { Treatments } from '../../model/treatments';

@Component({
	templateUrl: './treatments.component.html',
})
export class TreatmentsComponent extends CanDeactivateFormComponent implements OnInit {
	absenceCode: string;
	formGroup: FormGroup;

	constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private store: Store, private eventQueue: EventQueueService) {
		super();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({});
		this.route.params.subscribe((params) => {
			this.absenceCode = params.code;
			this.store.dispatch(AbsencePageAction.setAbsenceCode({ code: this.absenceCode }));
		});

		this.eventQueue
			.on(BroadcastEventType.ON_SAVE)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.onSubmit());
	}

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.controls.treatments.value as Treatments;
		this.store.dispatch(AbsencePageAction.saveTreatments({ data: data }));
	};
}
