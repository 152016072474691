import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Column, ColumnBuilder, DatatableColumn, Order, Search, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { IntegrationPageAction, IntegrationSelect } from '../../state/action';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../../user/state/action';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';

@Component({
	templateUrl: './medical-institutions.component.html',
	styleUrls: ['./medical-institutions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalInstitutionsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	medicalInstitutionColumns: DatatableColumn[];
	medicalInstitutions$: Observable<TableData>;
	filterConfig: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	GLOBAL_lOCATION_NUMBER_FILTER = 'globalLocationNumberFilter';
	DOCTOR_FIRST_NAME_FILTER = 'doctorFirstNameFilter';
	DOCTOR_LAST_NAME_FILTER = 'doctorLastNameFilter';
	INSTITUTION_NAME_FILTER = 'institutionNameFilter';
	ADDITIONAL_INSTITUTION_NAME_FILTER = 'additionalInstitutionNameFilter';
	STREET_FILTER = 'streetFilter';
	CITY_FILTER = 'cityFilter';
	ZIP_FILTER = 'zipFilter';
	COUNTRY_FILTER = 'countryFilter';
	LAST_CHANGE_DATE_FILTER = 'lastChangeDateFilter';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store) {
		super();
		this.loggedUserSubscription$ = store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});

		this.medicalInstitutionColumns = [
			ColumnBuilder.create('globalLocationNumber', 'globalLocationNumber', true),
			ColumnBuilder.create('doctorFirstName', 'doctorFirstName', true),
			ColumnBuilder.create('doctorLastName', 'doctorLastName', true),
			ColumnBuilder.create('institutionName', 'institutionName', true),
			ColumnBuilder.create('additionalInstitutionName', 'additionalInstitutionName', true),
			ColumnBuilder.create('street', 'street', true),
			ColumnBuilder.create('city', 'city', true),
			ColumnBuilder.create('zip', 'zip', true),
			ColumnBuilder.create('country', 'country', true),
			ColumnBuilder.createDate('lastChangeDate', 'lastChangeDate', true),
		];

		this.medicalInstitutions$ = this.store.select(IntegrationSelect.getMedicalInstitutions);

		this.filterConfig = {
			showFilter: true,
			showGlobalFilter: false,
			filterValues: {},
		};
	}

	ngOnInit() {
		this.store.select(SettingsSelect.getMedicalInstitutionsUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});
		this.filterConfig = {
			showFilter: true,
			showGlobalFilter: false,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
			filterValues: {
				globalLocationNumberFilter: this.userPreferences[this.GLOBAL_lOCATION_NUMBER_FILTER],
				doctorFirstNameFilter: this.userPreferences[this.DOCTOR_FIRST_NAME_FILTER],
				doctorLastNameFilter: this.userPreferences[this.DOCTOR_LAST_NAME_FILTER],
				institutionNameFilter: this.userPreferences[this.INSTITUTION_NAME_FILTER],
				additionalInstitutionNameFilter: this.userPreferences[this.ADDITIONAL_INSTITUTION_NAME_FILTER],
				streetFilter: this.userPreferences[this.STREET_FILTER],
				cityFilter: this.userPreferences[this.CITY_FILTER],
				zipFilter: this.userPreferences[this.ZIP_FILTER],
				countryFilter: this.userPreferences[this.COUNTRY_FILTER],
				lastChangeDateFilter: this.userPreferences[this.LAST_CHANGE_DATE_FILTER],
			},
		};
	}

	filter = (start: number, length: number, order: Order[], columns: Column[], globalSearch: Search): void => {
		this.store.dispatch(IntegrationPageAction.filterMedicalInstitutions({ start, length, order, columns, globalSearch }));
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			globalLocationNumberFilter: this.filterConfig.columnFiltersFormGroup.get(this.GLOBAL_lOCATION_NUMBER_FILTER).value,
			doctorFirstNameFilter: this.filterConfig.columnFiltersFormGroup.get(this.DOCTOR_FIRST_NAME_FILTER).value,
			doctorLastNameFilter: this.filterConfig.columnFiltersFormGroup.get(this.DOCTOR_LAST_NAME_FILTER).value,
			institutionNameFilter: this.filterConfig.columnFiltersFormGroup.get(this.INSTITUTION_NAME_FILTER).value,
			additionalInstitutionNameFilter: this.filterConfig.columnFiltersFormGroup.get(this.ADDITIONAL_INSTITUTION_NAME_FILTER).value,
			streetFilter: this.filterConfig.columnFiltersFormGroup.get(this.STREET_FILTER).value,
			cityFilter: this.filterConfig.columnFiltersFormGroup.get(this.CITY_FILTER).value,
			zipFilter: this.filterConfig.columnFiltersFormGroup.get(this.ZIP_FILTER).value,
			countryFilter: this.filterConfig.columnFiltersFormGroup.get(this.COUNTRY_FILTER).value,
			lastChangeDateFilter: this.filterConfig.columnFiltersFormGroup.get(this.LAST_CHANGE_DATE_FILTER).value,
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(
			SettingsPageAction.setMedicalInstitutionsTableFilter({
				medicalInstitutionsFilters: filterObject,
				loggedUser: this.loggedUser,
			})
		);
	}
}
