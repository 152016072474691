import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Employee } from '../../../../shared/model/employee';
import { AbsenceSelect } from '../../../state/action';
import { UserSelect } from '../../../../user/state/action';
import { dateToString } from 'nc-utils';

@Component({
	templateUrl: './insured-person-dialog.component.html',
	styleUrls: ['./insured-person-dialog.component.scss'],
})
export class InsuredPersonDialogComponent implements OnInit {
	employee$: Observable<Employee>;
	permissions: string[];

	pkProExitDate: string;
	pkProEntryDate: string;

	constructor(private store: Store) {
		store.select(UserSelect.getPermissions).subscribe((permissions) => (this.permissions = permissions));
	}

	ngOnInit(): void {
		this.employee$ = this.store.select(AbsenceSelect.getEmployee);
		this.employee$.subscribe((employee) => {
			this.pkProExitDate = dateToString(employee.pkProInsuranceEntryDate);
			this.pkProEntryDate = dateToString(employee.pkProInsuranceEntryDate);
		});
	}
}
