<form [formGroup]="formGroup" fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
					<div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
						<nc-select
							id="companies"
							searchTextId="companies-search"
							searchButtonId="companies-search-clear"
							clearIconId="companies-clear"
							[formGroup]="formGroup"
							placeholder="defaultSelectPleaceholder"
							formControlName="companyId"
							[required]="true"
							*ngIf="isMultipleCompanies"
							[removeSelected]="true"
							[data$]="companies$"
							label="company"
						>
						</nc-select>
						<div fxLayout="row wrap" fxLayoutGap="5px" *ngIf="company.value">
							<nc-quick-search
								textInputId="employee"
								resetButtonId="employee-reset"
								undoButtonId="employee-undo"
								fxFlex="95"
								[formGroup]="formGroup"
								[required]="true"
								formControlName="refEmployee"
								[fetchAll]="filterItems"
								placeholder="defaultSelectPleaceholder"
								label="employee"
							>
								<ng-template let-item>
									<span class="mat-option-title">{{ item.text | split : ';' : 0 }}</span
									><br />
									<span class="mat-option-footer">{{ item.text | split : ';' : 1 }}</span>
								</ng-template>
							</nc-quick-search>
							<ng-container *ngIf="employee.value">
								<mat-icon class="more-details-action" (click)="openEmployee()">info</mat-icon>
							</ng-container>
						</div>
						<ng-container *ngIf="employee.value">
							<ea-insurance-period fxLayoutGap="5px" [employee]="employee$ | async"></ea-insurance-period>
						</ng-container>
						<nc-toggle-buttons
							[formGroup]="formGroup"
							[options]="declarationTypeOptions"
							label="absenceDeclaration"
							formControlName="categoryType"
						>
						</nc-toggle-buttons>

						<ea-absence-type [formGroup]="formGroup"></ea-absence-type>
						<nc-textarea id="comment" [formGroup]="formGroup" formControlName="comment" label="comment" maxLength="300"></nc-textarea>
					</div>
					<div fxFlex="50" *ngIf="declarationType.value === ABSENCE_DECLARATION_TYPE.PREMIUM.code">
						<ea-premium-details [formGroup]="formGroup" [company]="company$ | async"></ea-premium-details>
					</div>
				</div>
				<ea-incapacity-table
					[formArray]="incapacities"
					[isAccident]="formGroup.controls?.absenceType.value === this.ABSENCE_TYPE_ENUMERATION.ACCIDENT.code"
					[isKleEnabled]="isKleEnabled"
				></ea-incapacity-table>
				<ea-documents
					id="hr-new-absence-document"
					buttonId="open-dialog"
					downloadButtonId="download-documents-button"
					[documentsFormArray]="documents"
					[hidePreview]="true"
					[hideDelete]="true"
					[hideMultiDownload]="true"
				>
				</ea-documents>
				<ea-container
					*ngIf="
						hasDeclareIncidentPermission &&
						isBranchHr &&
						formGroup.controls?.absenceType.value === this.ABSENCE_TYPE_ENUMERATION.ACCIDENT.code
					"
				>
					<nc-accordion [allowMultipleOpenedItems]="true">
						<nc-accordion-item
							title="accidentDescription"
							id="accidentDescription"
							class="kle-accordion"
							accordionCheckboxLabel="submit"
							[showAccordionCheckbox]="true"
							[ngClass]="{
								invalid: isAccordionInvalid(accordionCheckboxMap.accidentDescription, 'accidentDescription'),
								'await-story-style': accordionCheckboxMap.accidentDescription.awaited,
								'edited-story-style': isAccordionDirty(accordionCheckboxMap.accidentDescription, 'accidentDescription')
							}"
							(accordionCheckboxClicked)="
								handleCheckboxValueChange($event, accordionCheckboxMap.accidentDescription, accidentDescriptionFormGroup)
							"
							[accordionItemClass]="'ea-accordion-item-style'"
							[accordionItemBodyClass]="'ea-accordion-body-style'"
						>
							<ea-accident-description-form
								[formGroup]="accidentDescriptionFormGroup"
								[formArray]="accidentDescription"
							></ea-accident-description-form>
						</nc-accordion-item>
						<nc-accordion-item
							title="treatments"
							id="treatments"
							class="kle-accordion"
							accordionCheckboxLabel="submit"
							[showAccordionCheckbox]="true"
							[ngClass]="{
								invalid: isAccordionInvalid(accordionCheckboxMap.treatments, 'treatments'),
								'await-story-style': accordionCheckboxMap.treatments.awaited,
								'edited-story-style': isAccordionDirty(accordionCheckboxMap.treatments, 'treatments')
							}"
							(accordionCheckboxClicked)="handleCheckboxValueChange($event, accordionCheckboxMap.treatments, treatmentsFormGroup)"
							[accordionItemClass]="'ea-accordion-item-style'"
							[accordionItemBodyClass]="'ea-accordion-body-style'"
						>
							<ea-treatments-table [formGroup]="treatmentsFormGroup" [formArray]="treatments"></ea-treatments-table>
						</nc-accordion-item>
					</nc-accordion>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [displaySaveAndSendButton]="isBranchHrUser" [saveButtonLabel]="isBranchHrUser ? 'saveDraft' : 'save'">
	</ea-footer>
</form>
