import { createReducer, on } from '@ngrx/store';
import { DASHBOARD_INITIAL_STATE, DashboardState } from './dashboard.state';
import { DashboardApiAction, DashboardPageAction } from './action';

export const dashboardReducer = createReducer<DashboardState>(
	DASHBOARD_INITIAL_STATE,
	on(DashboardApiAction.openActiveDashboardSuccess, (state, action): DashboardState => {
		return {
			...state,
			numberOfDays: action.numberOfDays,
			selectedUser: action.responsibleUsers.selected.toString(),
			responsibleUsers: action.responsibleUsers.options,
		};
	}),
	on(DashboardApiAction.openArchivedDashboardSuccess, (state, action): DashboardState => {
		return {
			...state,
			selectedUser: action.responsibleUsers.selected.toString(),
			responsibleUsers: action.responsibleUsers.options,
		};
	}),
	on(DashboardPageAction.setSelectedUser, (state, action): DashboardState => {
		return {
			...state,
			selectedUser: action.selectedUser,
		};
	}),
	on(DashboardApiAction.getTasksSuccess, (state, action): DashboardState => {
		return {
			...state,
			tasks: { records: action.tasks },
		};
	}),
	on(DashboardApiAction.getMilestonesSuccess, (state, action): DashboardState => {
		return {
			...state,
			milestones: { records: action.milestones },
		};
	}),
	on(DashboardApiAction.getActiveAbsencesSuccess, (state, action): DashboardState => {
		return {
			...state,
			activeCases: { records: action.activeCases },
		};
	}),
	on(DashboardApiAction.getArchivedAbsencesSuccess, (state, action): DashboardState => {
		return {
			...state,
			archivedCases: { records: action.archivedCases },
		};
	}),
	on(DashboardApiAction.getHrActiveAbsenceSuccess, (state, action): DashboardState => {
		return {
			...state,
			hrActiveAbsence: action.hrActiveAbsence,
		};
	}),
	on(DashboardApiAction.getCasesToBeApprovedSuccess, (state, action): DashboardState => {
		return {
			...state,
			casesToBeApproved: action.casesToBeApproved,
		};
	}),
	on(DashboardApiAction.getAwaitStoriesSuccess, (state, action): DashboardState => {
		return {
			...state,
			awaitStories: action.awaitStories,
		};
	}),
	on(DashboardApiAction.getHrStatisticSuccess, (state, action): DashboardState => {
		return {
			...state,
			pieChartData: action.charts.pieChartData,
			barChartData: action.charts.barChartData,
		};
	}),
	on(DashboardApiAction.getHrCompaniesSuccess, (state, action): DashboardState => {
		return {
			...state,
			hrCompanies: action.companies,
		};
	}),
	on(DashboardApiAction.getHrArchivedCasesSuccess, (state, action): DashboardState => {
		return {
			...state,
			archivedCases: action.archivedCases,
		};
	})
);
