import { KleFormGroupEnum } from './enums/kle-form-group.enum';
import { AccordionCheckbox } from './accordion-checkbox';

export class AccordionCheckboxMap {
	agreement: AccordionCheckbox;
	specialCoverage: AccordionCheckbox;
	otherEmployers: AccordionCheckbox;
	processControl: AccordionCheckbox;
	takeWorkUpFully: AccordionCheckbox;
	additionsPerson: AccordionCheckbox;
	incapacity: AccordionCheckbox;
	shortTimeWork: AccordionCheckbox;
	specialCode: AccordionCheckbox;
	payment: AccordionCheckbox;
	treatments: AccordionCheckbox;
	accidentDescription: AccordionCheckbox;
	annualSalary: AccordionCheckbox;
	absence: AccordionCheckbox;
	personMutated: AccordionCheckbox;
	attachmentStory: AccordionCheckbox;

	constructor() {
		this.agreement = new AccordionCheckbox(KleFormGroupEnum.AGREEMENT);
		this.specialCoverage = new AccordionCheckbox(KleFormGroupEnum.SPECIAL_COVERAGE);
		this.otherEmployers = new AccordionCheckbox(KleFormGroupEnum.OTHER_EMPLOYERS);
		this.processControl = new AccordionCheckbox(KleFormGroupEnum.PROCESS_CONTROL);
		this.takeWorkUpFully = new AccordionCheckbox(KleFormGroupEnum.TAKE_WORK_UP_FULLY);
		this.additionsPerson = new AccordionCheckbox(KleFormGroupEnum.ADDITIONS_PERSON);
		this.incapacity = new AccordionCheckbox(KleFormGroupEnum.INCAPACITIES_TO_WORK);
		this.shortTimeWork = new AccordionCheckbox(KleFormGroupEnum.SHORT_TIME_WORK);
		this.specialCode = new AccordionCheckbox(KleFormGroupEnum.SPECIAL_CODE);
		this.payment = new AccordionCheckbox(KleFormGroupEnum.PAYMENT);
		this.treatments = new AccordionCheckbox(KleFormGroupEnum.TREATMENTS);
		this.accidentDescription = new AccordionCheckbox(KleFormGroupEnum.ACCIDENT_DESCRIPTION);
		this.annualSalary = new AccordionCheckbox(KleFormGroupEnum.ANNUAL_SALARY);
		this.absence = new AccordionCheckbox(KleFormGroupEnum.ABSENCE_STORY);
		this.personMutated = new AccordionCheckbox(KleFormGroupEnum.PERSON_MUTATED);
		this.attachmentStory = new AccordionCheckbox(KleFormGroupEnum.ATTACHMENT_STORY);
	}

	reset(): void {
		this.agreement.reset();
		this.specialCoverage.reset();
		this.otherEmployers.reset();
		this.processControl.reset();
		this.takeWorkUpFully.reset();
		this.additionsPerson.reset();
		this.incapacity.reset();
		this.shortTimeWork.reset();
		this.specialCode.reset();
		this.payment.reset();
		this.treatments.reset();
		this.accidentDescription.reset();
		this.annualSalary.reset();
		this.absence.reset();
		this.personMutated.reset();
		this.attachmentStory.reset();
	}
}
