import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CmNewAbsenceComponent } from './component/absence/new-absence/cm-new-absence/cm-new-absence.component';
import { InsuredPersonDialogComponent } from './component/dialog/insured-person-dialog/insured-person-dialog.component';
import { EffectsModule } from '@ngrx/effects';
import { AbsenceEffect } from './state/absence.effect';
import { StoreModule } from '@ngrx/store';
import { ABSENCE_FEATURE_STATE_NAME } from './state/action/absence.select';
import { absenceReducer } from './state/absence.reducer';
import { InsurancePeriodComponent } from './component/insurance-period/insurance-period.component';
import { PremiumDetailsComponent } from './component/absence/premium-details/premium-details.component';
import { IncapacityTableComponent } from './component/incapacity-table/incapacity-table.component';
import { IncapacityFormComponent } from './component/incapacity-table/incapacity-form/incapacity-form.component';
import { IncapacityDialogComponent } from './component/incapacity-table/incapacity-dialog/incapacity-dialog.component';
import { AbsenceFooterComponent } from './component/absence/absence-footer/absence-footer.component';
import { CmAbsenceComponent } from './component/absence/existing-absence/cm-absence/cm-absence.component';
import { CompanyDialogComponent } from './component/dialog/company-dialog/company-dialog.component';
import { GenerateDocumentDialogComponent } from './component/dialog/generate-document-dialog/generate-document-dialog.component';
import { FetchDocumentDialogComponent } from './component/dialog/fetch-document-dialog/fetch-document-dialog.component';
import { AbsenceConfirmationDialogComponent } from './component/dialog/absence-confirmation-dialog/absence-confirmation-dialog.component';
import { AbsenceArchiveDialogComponent } from './component/dialog/absence-archive-dialog/absence-archive-dialog.component';
import { AbsenceActivateDialogComponent } from './component/dialog/absence-activate-dialog/absence-activate-dialog.component';
import { HistoryComponent } from './component/history/history.component';
import { HistoryFooterComponent } from './component/history/history-footer/history-footer.component';
import { ReassignAbsenceComponent } from './component/reassign-absence/reassign-absence.component';
import { ReassignAbsenceFooterComponent } from './component/reassign-absence/reassign-absence-footer/reassign-absence-footer.component';
import { MergeAbsenceDialogComponent } from './component/dialog/merge-absence/merge-absence-dialog.component';
import { UnmergeAbsenceConfirmationDialogComponent } from './component/dialog/unmerge-absence-confirmation-dialog/unmerge-absence-confirmation-dialog.component';
import { HrAbsenceComponent } from './component/absence/existing-absence/hr-absence/hr-absence.component';
import { HrAbsenceFooterComponent } from './component/absence/hr-absence-foorer/hr-absence-footer.component';
import { ExistingAbsenceComponent } from './component/absence/existing-absence/existing-absence.component';
import { HrNewAbsenceComponent } from './component/absence/new-absence/hr-new-absence/hr-new-absence.component';
import { NewAbsenceComponent } from './component/absence/new-absence/new-absence.component';
import { NewAbsenceAnnexFormComponent } from './component/absence-anex/absence-annex-form/new-absence-annex-form.component';
import { ExistingAbsenceAnnexComponent } from './component/absence-anex/existing-absence-annex/existing-absence-annex.component';
import { DocumentWarningDialogComponent } from './component/absence/document-warning-dialog/document-warning-dialog.component';
import { AbsenceTypeComponent } from './component/absence/absence-type/absence-type.component';
import { IncapacityFormGuardDialogComponent } from './component/incapacity-table/incapacity-form-guard-dialog/incapacity-form-guard-dialog.component';
import { IncidentDeclarationDialogComponent } from './component/dialog/incident-declaration/incident-declaration-dialog.component';
import { NcDateTimePickerModule } from 'nc-date-time-picker';
import { MatSelectModule } from '@angular/material/select';
import { AbsenceExistingDialogComponent } from './component/dialog/absence-existing-dialog/absence-existing-dialog.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([AbsenceEffect]),
		StoreModule.forFeature(ABSENCE_FEATURE_STATE_NAME, absenceReducer),
		NcDateTimePickerModule,
		MatSelectModule,
	],
	declarations: [
		CmNewAbsenceComponent,
		AbsenceFooterComponent,
		CompanyDialogComponent,
		PremiumDetailsComponent,
		IncapacityFormComponent,
		CmAbsenceComponent,
		AbsenceTypeComponent,
		IncapacityTableComponent,
		InsurancePeriodComponent,
		IncapacityDialogComponent,
		InsuredPersonDialogComponent,
		FetchDocumentDialogComponent,
		AbsenceArchiveDialogComponent,
		AbsenceActivateDialogComponent,
		AbsenceExistingDialogComponent,
		GenerateDocumentDialogComponent,
		AbsenceConfirmationDialogComponent,
		HistoryComponent,
		HistoryFooterComponent,
		ReassignAbsenceComponent,
		ReassignAbsenceFooterComponent,
		MergeAbsenceDialogComponent,
		UnmergeAbsenceConfirmationDialogComponent,
		HrAbsenceComponent,
		HrAbsenceFooterComponent,
		ExistingAbsenceComponent,
		UnmergeAbsenceConfirmationDialogComponent,
		HrNewAbsenceComponent,
		NewAbsenceComponent,
		NewAbsenceAnnexFormComponent,
		ExistingAbsenceAnnexComponent,
		DocumentWarningDialogComponent,
		IncapacityFormGuardDialogComponent,
		IncidentDeclarationDialogComponent,
	],
	exports: [
		CmNewAbsenceComponent,
		ExistingAbsenceComponent,
		InsuredPersonDialogComponent,
		HrNewAbsenceComponent,
		NewAbsenceComponent,
		NewAbsenceAnnexFormComponent,
		ExistingAbsenceAnnexComponent,
		IncapacityTableComponent,
		IncidentDeclarationDialogComponent,
	],
})
export class AbsenceModule {}
