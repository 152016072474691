import { createReducer, on } from '@ngrx/store';
import { TASK_INITIAL_STATE, TaskState } from './task.state';
import { TaskApiAction, TaskPageAction } from './action';
import { parseDate } from 'nc-utils';

export const taskReducer = createReducer<TaskState>(
	TASK_INITIAL_STATE,
	on(TaskApiAction.getOpenTasksSuccess, (state, action): TaskState => {
		return {
			...state,
			openTasks: { records: action.openTasks },
		};
	}),
	on(TaskApiAction.getDoneTasksSuccess, (state, action): TaskState => {
		return {
			...state,
			doneTasks: { records: action.doneTasks },
		};
	}),
	on(TaskApiAction.getOpenMilestonesSuccess, (state, action): TaskState => {
		return {
			...state,
			openMilestones: { records: action.openMilestones },
		};
	}),
	on(TaskApiAction.getDoneMilestonesSuccess, (state, action): TaskState => {
		return {
			...state,
			doneMilestones: { records: action.doneMilestones },
		};
	}),
	on(TaskApiAction.getNotesSuccess, (state, action): TaskState => {
		return {
			...state,
			notes: { records: action.notes },
		};
	}),
	on(TaskApiAction.getNoteSuccess, (state, action): TaskState => {
		return {
			...state,
			note: {
				...action.note,
				documents: action.note.existingDocuments.map((x) => {
					return {
						id: x.id,
						nameWithExtension: x.documentName,
						docmanId: x.documentId,
						tableId: 0,
						documentTypeTranslation: x.type,
						documentType: x.typeCode,
						name: x.documentName.split('.')[0],
						extension: x.documentName.split('.')[1],
						file: null,
						uploadedOn: x.uploadedOn,
						uploadedBy: x.uploadedBy,
						comment: x.comment,
					};
				}),
			},
		};
	}),
	on(TaskApiAction.getTaskSuccess, (state, action): TaskState => {
		return {
			...state,
			task: {
				...action.task,
				dueDate: parseDate(action.task.dueDate.toString()),
				documents: action.task.existingDocuments.map((x) => {
					return {
						id: x.id,
						nameWithExtension: x.documentName,
						docmanId: x.documentId,
						tableId: 0,
						documentTypeTranslation: x.type,
						documentType: x.typeCode,
						name: x.documentName.split('.')[0],
						extension: x.documentName.split('.')[1],
						file: null,
						uploadedOn: x.uploadedOn,
						uploadedBy: x.uploadedBy,
						comment: x.comment,
					};
				}),
			},
		};
	}),
	on(TaskApiAction.getNumberOfDaysSuccess, (state, action): TaskState => {
		return {
			...state,
			numberOfDays: action.numberOfDays,
		};
	}),
	on(TaskApiAction.getMilestoneSuccess, (state, action): TaskState => {
		return {
			...state,
			milestone: action.milestone,
		};
	}),
	on(TaskApiAction.getDocumentsForCheckListSuccess, (state, action): TaskState => {
		return {
			...state,
			documentsForCheckList: action.documents.map((x) => {
				return {
					id: null,
					nameWithExtension: x.name,
					docmanId: Number(x.id),
					tableId: 0,
					documentTypeTranslation: x.type,
					documentType: x.type,
					name: x.name.split('.')[0],
					extension: x.name.split('.')[1],
					file: null,
					uploadedOn: x.uploadedOn,
					uploadedBy: x.uploadedBy,
					comment: x.comment,
				};
			}),
		};
	}),
	on(TaskApiAction.getCurrentAbsenceSuccess, (state, action): TaskState => {
		return {
			...state,
			currentAbsence: action.currentAbsence,
		};
	}),
	on(TaskPageAction.clearTask, (state): TaskState => {
		return {
			...state,
			task: null,
		};
	}),
	on(TaskPageAction.clearNote, (state): TaskState => {
		return {
			...state,
			note: null,
		};
	}),
	on(TaskPageAction.isDoneMilestoneButtonClicked, (state, action): TaskState => {
		return {
			...state,
			isDoneMilestoneButtonClicked: action.isClicked,
		};
	})
);
