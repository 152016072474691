import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MilestoneSettingsModelFactory {
	create(milestoneSettings: any): any {
		return {
			id: milestoneSettings.id,
			day: milestoneSettings.day,
			pkProInsured: milestoneSettings.pkProInsured,
			swicaInsured: milestoneSettings.swicaInsured,
			forCMUser: milestoneSettings.forCMUser,
			forLDUser: milestoneSettings.forLDUser,
			forCaseManagementActive: milestoneSettings.forCaseManagementActive,
			important: milestoneSettings.important === 'yes',
			forAbsenceTypeSickness: milestoneSettings.forAbsenceTypeSickness === 'yes',
			forAbsenceTypeAccident: milestoneSettings.forAbsenceTypeAccident === 'yes',
			forAbsenceTypePregnancy: milestoneSettings.forAbsenceTypePregnancy === 'yes',
			sicknessTypeMentalIllness: milestoneSettings.sicknessTypeMentalIllness,
			sicknessTypeBackProblem: milestoneSettings.sicknessTypeBackProblem,
			sicknessTypeUnknown: milestoneSettings.sicknessTypeUnknown,
			sicknessTypeOther: milestoneSettings.sicknessTypeOther,
			accidentTypeWork: milestoneSettings.accidentTypeWork,
			accidentTypeFreeTime: milestoneSettings.accidentTypeFreeTime,
			note: {
				code: '',
				de: milestoneSettings.noteDe,
				en: milestoneSettings.noteEn,
				fr: milestoneSettings.noteFr,
				it: milestoneSettings.noteIt,
				new: '',
			},
			title: {
				code: '',
				de: milestoneSettings.titleDe,
				en: milestoneSettings.titleEn,
				fr: milestoneSettings.titleFr,
				it: milestoneSettings.titleIt,
				new: '',
			},
			cmStatus: {
				code: '',
				de: milestoneSettings.cmStatusesDe,
				en: milestoneSettings.cmStatusesEn,
				fr: milestoneSettings.cmStatusesFr,
				it: milestoneSettings.cmStatusesIt,
				new: '',
			},
			hrStatus: {
				code: '',
				de: milestoneSettings.hrStatusesDe,
				en: milestoneSettings.hrStatusesEn,
				fr: milestoneSettings.hrStatusesFr,
				it: milestoneSettings.hrStatusesIt,
				new: '',
			},
			documentTypes: milestoneSettings.documentTypes.map((x) => x.value),
		};
	}
}
