import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CanDeactivateFormComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { FormBuilder, Validators } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { IntegrationPageAction, IntegrationSelect } from '../../state/action';
import { CompanyGroup } from '../../model/company-group';
import { ValidationService } from '../../../shared/service/validation.service';
import { ErrorSelect } from '../../../state/error/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './company-group-details.component.html',
})
export class CompanyGroupDetailsComponent extends CanDeactivateFormComponent implements OnInit, OnDestroy {
	companyColumns: DatatableColumn[];
	companies$: Observable<TableData>;
	companyGroup$: Observable<CompanyGroup>;
	isExisting: boolean;
	filterConfig: FilterConfig;

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			if (params.id) {
				this.store.dispatch(IntegrationPageAction.getCompanyGroup({ id: params.id }));
			}
		});
		this.filterConfig = this.createFilterConfig();
		this.companyGroup$ = this.store.select(IntegrationSelect.getCompanyGroup);
		this.companies$ = this.store.select(IntegrationSelect.getCompanyGroupCompanies);

		this.store.select(IntegrationSelect.getCompanyGroupId).subscribe((id) => (this.isExisting = id != null));
		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));

		this.companyColumns = this.createColumns();

		this.formGroup = this.formBuilder.group({
			id: ['', null],
			name: ['', Validators.required],
		});

		this.companyGroup$
			.pipe(
				filter((companyGroup) => companyGroup?.id !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((companyGroup) => this.displayCompanyGroup(companyGroup));
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.store.dispatch(IntegrationPageAction.clearCompanyGroup());
	}

	displayCompanyGroup = (companyGroup: CompanyGroup): void => {
		this.formGroup.reset();
		this.formGroup.patchValue(companyGroup);
	};

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.getRawValue() as CompanyGroup;
		this.store.dispatch(IntegrationPageAction.saveCompanyGroup({ data }));
	};

	createColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('name', 'name'),
			ColumnBuilder.create('neoId', 'neoId'),
			ColumnBuilder.create('easyTempId', 'easyTempId'),
			ColumnBuilder.create('contractType', 'contractType'),
		];
	}

	createFilterConfig(): FilterConfig {
		return {
			showFilter: false,
			filterValues: {},
		};
	}
}
