import { createAction, props } from '@ngrx/store';
import { TableData } from 'nc-datatable';
import { CompanyResponse } from '../../../absence/model/response/company.response';
import { Employee } from '../../../shared/model/employee';
import { SaveResponse } from 'nc-utils';

export const filterCompaniesSuccess = createAction('[Integration] Get companies success', props<{ companies: TableData }>());
export const filterMedicalInstitutionsSuccess = createAction(
	'[Integration] Get medical institutions success',
	props<{ medicalInstitutions: TableData }>()
);
export const getCompanySuccess = createAction('[Integration] Get company success', props<{ response: CompanyResponse }>());
export const getEmployeesSuccess = createAction(
	'[Integration] Get employees for company success',
	props<{ employees: { [key: string]: string }[] }>()
);
export const getEmployeeSuccess = createAction('[Integration] Get employee success', props<{ employee: Employee }>());
export const saveCompanySuccess = createAction('[Integration] Save company success', props<{ response: SaveResponse }>());
export const updateCompanySuccess = createAction('[Integration] Update company success', props<{ response: SaveResponse }>());
export const getCompanyGroupsSuccess = createAction(
	'[Integration] Get company groups success',
	props<{ companyGroups: { [key: string]: string }[] }>()
);
export const getCompanyGroupSuccess = createAction('[Integration] Get company group success', props<{ companyGroup: any }>());
export const saveCompanyGroupSuccess = createAction('[Integration] Save company group success', props<{ response: SaveResponse }>());
export const getAvailableSuccess = createAction('[Integration] Operation available success', props<{ records: { [key: string]: string }[] }>());
export const getAssignedSuccess = createAction('[Integration] Operation assigned success', props<{ records: { [key: string]: string }[] }>());
export const assignOrRemoveSuccess = createAction('[Integration] Operation assign or remove success');
