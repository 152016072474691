import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../../shared/model/company';
import { SaveResponse } from 'nc-utils';
import { Column, FilterRequest, Order, Search, TableData } from 'nc-datatable';
import { SyncResponse } from '../../shared/model/sync-response';
import { Employee } from '../../shared/model/employee';
import { CompanyResponse } from '../../absence/model/response/company.response';
import { CompanyGroup } from '../model/company-group';
import { CompanyGroupModelFactory } from './company-group-model.factory';
import { ChangeFrontendStyleModel } from '../model/change-frontend-style.model';
import { CompanyModelFactory } from './company-model.factory';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class IntegrationService {
	constructor(
		private httpClient: HttpClient,
		private companyGroupFactory: CompanyGroupModelFactory,
		private companyFactory: CompanyModelFactory,
		private environmentLoader: EnvironmentLoader
	) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly FILTER_COMPANIES_URL = `${this.API_URL}/companies/filter-list`;
	private readonly FILTER_MEDICAL_INSTITUTIONS_URL = `${this.API_URL}/integration/medical-institutions`;
	private readonly INTEGRATION_URL = `${this.API_URL}/integration`;
	private readonly COMPANY_URL = `${this.API_URL}/companies`;
	private readonly EMPLOYEE_URL = `${this.API_URL}/employees/`;
	private readonly GET_EMPLOYEES_URL = `${this.API_URL}/employees/company/`;
	private readonly COMPANY_GROUPS_URL = `${this.API_URL}/company/groups`;

	/**
	 * Filter companies
	 */
	filterCompanies(start: number, length: number, order: Order[], columns: Column[], globalSearch: Search): Observable<TableData> {
		const data = { start, length, order, columns, search: globalSearch } as FilterRequest;
		return this.httpClient.post<TableData>(this.FILTER_COMPANIES_URL, data);
	}

	/**
	 * Filter medical institutions
	 */
	filterMedicalInstitutions(start: number, length: number, order: Order[], columns: Column[], globalSearch: Search): Observable<TableData> {
		const data = { start, length, order, columns, search: globalSearch } as FilterRequest;
		return this.httpClient.post<TableData>(this.FILTER_MEDICAL_INSTITUTIONS_URL, data);
	}

	/**
	 * Save company
	 * @param company Model of Company
	 */
	save(company: Company): Observable<SaveResponse> {
		return this.httpClient.post<SaveResponse>(`${this.INTEGRATION_URL}/companies`, company);
	}

	/**
	 * Integrate Company data from EasyTemp and NEO
	 * @param companyIds list of company ids that needs to be refreshed
	 * @param byModificationDate is refresh by modification date
	 */
	refreshCompany(companyIds: [number], byModificationDate: boolean): Observable<SyncResponse> {
		const data = { companyIds, isByLastModificationDate: byModificationDate };
		return this.httpClient.post<SyncResponse>(`${this.INTEGRATION_URL}/sync-companies`, data);
	}

	/**
	 * Integrate Medical institutions data
	 * @param term search term
	 * @param searchType search term
	 * @param partnerType search term
	 * @param providerType search term
	 */
	refreshMedicalInstitutions(term: string, searchType: string, partnerType: string, providerType: string): Observable<SyncResponse> {
		const data = { term, searchType, partnerType, providerType };
		return this.httpClient.post<SyncResponse>(`${this.INTEGRATION_URL}/sync-medical-institutions`, data);
	}

	/**
	 * Get list of employees for company
	 * @param companyId Unique company identifier
	 */
	getEmployees(companyId: number): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(`${this.GET_EMPLOYEES_URL}${companyId}`);
	}

	/**
	 * Gets employee details.
	 * @param employeeId Unique employee identifier
	 */
	getEmployee(employeeId: number): Observable<Employee> {
		return this.httpClient.get<Employee>(this.EMPLOYEE_URL + employeeId);
	}

	/**
	 * Gets company details.
	 * @param companyId Unique company identifier
	 */
	getCompany(companyId: number): Observable<CompanyResponse> {
		return this.httpClient.get<CompanyResponse>(this.COMPANY_URL + '/' + companyId);
	}

	/**
	 * Update company changes
	 * @param company Model of Company
	 */
	update(company: Company): Observable<SaveResponse> {
		return this.httpClient.put<SaveResponse>(this.COMPANY_URL, company);
	}

	/**
	 * Get all company groups
	 */
	getAllCompanyGroups(): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(this.COMPANY_GROUPS_URL);
	}

	/**
	 * Gets company group details.
	 * @param id Unique company group identifier
	 */
	getCompanyGroup(id: number): Observable<any> {
		return this.httpClient.get<any>(this.COMPANY_GROUPS_URL + '/' + id);
	}

	/**
	 * Save company group
	 * @param data Model of CompanyGroup
	 */
	saveCompanyGroup(data: CompanyGroup): Observable<SaveResponse> {
		const companyGroup = this.companyGroupFactory.create(data);
		return this.httpClient.put<SaveResponse>(this.COMPANY_GROUPS_URL, companyGroup);
	}

	/**
	 * Delete company group
	 * @param id Unique identifier for company group
	 */
	deleteCompanyGroup(id: number): Observable<any> {
		return this.httpClient.delete<any>(this.COMPANY_GROUPS_URL + '/' + id);
	}

	/**
	 * All operation related to change frontend.
	 */
	changeFrontendOperations(model: ChangeFrontendStyleModel): Observable<TableData> {
		const data = this.companyFactory.createChangeFrontendStyleRequest(model);
		return this.httpClient.post<TableData>(`${this.COMPANY_URL}/change-fronted-style`, data);
	}
}
