import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { EnumUtils } from 'nc-utils';
import { StatisticCodeEnumeration } from 'src/app/kle/model/enums/statistic-code.enum';

@Component({
	selector: 'ea-manual-addition-form',
	templateUrl: './manual-addition-form.component.html',
})
export class ManualAdditionFormComponent {
	@Input() formGroup: FormGroup;

	statisticCodes$ = EnumUtils.toObservableItems(StatisticCodeEnumeration);
}
