import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AbsenceContextModel } from '../../model/absence-context.model';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'nc-utils';
import { TaskPageAction, TaskSelect } from '../../../task/state/action';
import { SettingsPageAction } from '../../../state/settings/action';
import { EmployeeDetailsComponent } from '../../../integration/component/employee-details/employee-details.component';
import { BaseComponent } from 'nc-shared';
import { AbsencePageAction, AbsenceSelect } from '../../../absence/state/action';
import { IncidentStatus } from '../../../kle/model/incident-status';
import { KleSelect } from '../../../kle/state/action';
import { map } from 'rxjs/operators';

@Component({
	selector: 'ea-header-info',
	templateUrl: './header-info.component.html',
	styleUrls: ['./header-info.component.scss'],
})
export class HeaderInfoComponent extends BaseComponent implements OnInit {
	@Input() showCompany = false;
	@Input() showCode = false;
	@Input() showAbsenceCodeLabel = false;
	@Input() showAbsenceCode = true;
	@Input() showInsuredPerson = true;
	@Input() showDays = true;
	@Input() showLinks = true;
	@Input() code?: string;
	@Input() showStatus = false;

	latestState$: Observable<IncidentStatus>;

	currentAbsence$: Observable<AbsenceContextModel>;

	constructor(private store: Store, private route: ActivatedRoute, private dialogService: DialogService) {
		super();
	}

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			if (params.code) {
				this.store.dispatch(TaskPageAction.getCurrentAbsence({ code: params.code }));
				this.currentAbsence$ = this.store.select(TaskSelect.getCurrentAbsence);
			} else if (params.identifier) {
				this.store.dispatch(AbsencePageAction.getAbsenceAnnex({ identifier: params.identifier, isShareInformation: this.showCode }));
				this.currentAbsence$ = this.store.select(AbsenceSelect.getAbsenceContextModel);
			}
		});

		this.latestState$ = this.store
			.select(KleSelect.getLatestState)
			.pipe(map((incidentStatus) => (incidentStatus?.status ? new IncidentStatus(incidentStatus) : null)));
	}

	companyLink = (companyId: number): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `company/${companyId}` }));
	};

	absenceLink = (code: string): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${code}` }));
	};

	employeeLink = (insuredPersonId: number): void => {
		const dialogConfig = { data: { insuredPersonId } };
		this.dialogService.open(EmployeeDetailsComponent, dialogConfig);
	};
}
