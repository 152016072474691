import { EnumItem } from 'nc-utils';

export const IncidentStatusEnumeration = {
	PERFORMING_ENTITLEMENT: new EnumItem('PERFORMING_ENTITLEMENT', 'performingEntitlement'),
	REGISTRATION_RECEIVED: new EnumItem('REGISTRATION_RECEIVED', 'registrationReceived'),
	CHECKING_DECLARATION: new EnumItem('CHECKING_DECLARATION', 'checkingDeclaration'),
	VERIFYING_CLAIM: new EnumItem('VERIFYING_CLAIM', 'verifyingClaim'),
	COMMUNICATION_STOPPED: new EnumItem('COMMUNICATION_STOPPED', 'communicationStopped'),
	REACTIVATED: new EnumItem('REACTIVATED', 'reactivated'),
};
