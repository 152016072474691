import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent, Option } from 'nc-shared';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { EnumUtils } from 'nc-utils';
import { BodyPartTypeEnum } from '../../../model/enums/body-part-type.enum';
import { BodyOrientedPartTypeEnum } from '../../../model/enums/body-oriented-part-type.enum';
import { TypeOfInjuryEnum } from '../../../model/enums/type-of-injury.enum';
import { PositionPartTypeEnum } from '../../../model/enums/position-part-type.enum';
import { filter } from 'rxjs/operators';
import { TranslateEnumsUtils } from '../../../../shared/utils/translate-enums.utils';

@Component({
	selector: 'ea-injury-form',
	templateUrl: './injury-form.component.html',
	styleUrls: ['./injury-form.component.scss'],
})
export class InjuryFormComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;

	bodyPart$: Observable<Option[]>;
	positionType$: Observable<Option[]>;
	typeOfInjury$: Observable<Option[]>;

	unorientedBodyPart: boolean = true;

	constructor(private translateEnums: TranslateEnumsUtils) {
		super();
	}

	ngOnInit(): void {
		const bodyPartInjury = this.translateEnums.getTranslatedValues(BodyPartTypeEnum);
		const bodyOrientedPartInjury = this.translateEnums.getTranslatedValues(BodyOrientedPartTypeEnum);
		this.positionType$ = EnumUtils.toObservableItems(PositionPartTypeEnum);
		this.typeOfInjury$ = of(this.translateEnums.getTranslatedValues(TypeOfInjuryEnum));
		this.bodyPart$ = of(bodyPartInjury.concat(bodyOrientedPartInjury));

		this.fillBodyPart();
		this.bodyPartValueChanges();
	}

	fillBodyPart() {
		if (this.formGroup.controls.part.value) {
			this.formGroup.controls.bodyPart.patchValue(this.formGroup.controls.part.value);
			this.formGroup.controls.bodyPart.updateValueAndValidity();
			this.unorientedBodyPart = false;
		} else if (this.formGroup.controls.unassignedPart.value) {
			this.formGroup.controls.bodyPart.patchValue(this.formGroup.controls.unassignedPart.value);
		}
	}

	bodyPartValueChanges(): void {
		this.formGroup.controls.bodyPart.valueChanges.pipe(filter((value) => value != null)).subscribe((value) => {
			if (value in BodyPartTypeEnum) {
				this.unorientedBodyPart = true;
				this.formGroup.controls.unassignedPart.patchValue(value);
				this.formGroup.controls.part.patchValue(null);
				this.formGroup.controls.position.patchValue(null);
			} else {
				this.unorientedBodyPart = false;
				this.formGroup.controls.part.patchValue(value);
				this.formGroup.controls.unassignedPart.patchValue(null);
			}
		});
	}

	showTypeAndOtherTypeMandatoryError() {
		return this.formGroup.errors?.typeAndOtherTypeOfInjuryError;
	}
}
