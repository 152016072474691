import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogService, DialogType } from 'nc-utils';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorAction } from '../../state/error/action';
import { AccessManagementApiAction, AccessManagementPageAction, AccessManagementSelect } from './action';
import { AccessManagementService } from '../service/access-management.service';
import { DeleteConfirmationDialogComponent } from './../component/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Store } from '@ngrx/store';
import { SettingsPageAction } from '../../state/settings/action';

@Injectable()
export class AccessManagementEffect {
	constructor(
		private actions$: Actions,
		private accessManagementService: AccessManagementService,
		private dialogService: DialogService,
		private store: Store
	) {}

	saveAuthorityEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessManagementPageAction.saveAuthority),
			mergeMap((action) => this.accessManagementService.saveAuthority(action.authority)),
			map((response) =>
				response.success ? AccessManagementApiAction.saveAuthoritySuccess() : ErrorAction.setErrors({ errors: response.errors })
			)
		);
	});

	saveAuthoritySuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessManagementApiAction.saveAuthoritySuccess),
			withLatestFrom(this.store.select(AccessManagementSelect.getUrlPath)),
			map(([action, urlPath]) => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.navigate({ url: `/${urlPath}` });
			})
		);
	});

	getAuthoritiesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessManagementPageAction.getAuthorities),
			switchMap((action) => this.accessManagementService.getAuthorities(action.authorityType)),
			map((result) => AccessManagementApiAction.getAuthoritiesSuccess({ authorities: result }))
		);
	});

	getAuthorityEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessManagementPageAction.getAuthority),
			switchMap((action) => this.accessManagementService.getAuthority(action.authorityId, action.authorityType)),
			map((response) => AccessManagementApiAction.getAuthoritySuccess({ authority: response }))
		);
	});

	openConfirmDeleteDialogEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(AccessManagementPageAction.openDeleteConfirmationDialog),
				switchMap((action) => {
					const dialogConfig = { disableClose: true, data: { authorityId: action.authorityId, authorityType: action.authorityType } };
					return this.dialogService.open(DeleteConfirmationDialogComponent, dialogConfig).afterClosed();
				}),
				tap((dialogResult) => {
					if (dialogResult.success) {
						this.store.dispatch(
							AccessManagementPageAction.deleteAuthority({
								authorityId: dialogResult.authorityId,
								authorityType: dialogResult.authorityType,
							})
						);
					}
				})
			);
		},
		{ dispatch: false }
	);

	deleteAuthorityEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccessManagementPageAction.deleteAuthority),
			switchMap((action) => this.accessManagementService.deleteAuthority(action.authorityId, action.authorityType)),
			withLatestFrom(this.store.select(AccessManagementSelect.getCurrentAuthorityType)),
			map(([response, authorityType]) => {
				return response.success
					? AccessManagementPageAction.getAuthorities({ authorityType })
					: ErrorAction.submitFailed({ errors: response.errors });
			})
		);
	});
}
