export enum KleFormGroupEnum {
	AGREEMENT = 'agreement',
	SPECIAL_COVERAGE = 'specialCoverage',
	OTHER_EMPLOYERS = 'otherEmployers',
	PROCESS_CONTROL = 'processControl',
	TAKE_WORK_UP_FULLY = 'takeWorkUpFully',
	ADDITIONS_PERSON = 'additionsPerson',
	INCAPACITIES_TO_WORK = 'incapacity',
	SHORT_TIME_WORK = 'shortTimeWork',
	SPECIAL_CODE = 'specialCode',
	PAYMENT = 'payment',
	TREATMENTS = 'treatments',
	ACCIDENT_DESCRIPTION = 'accidentDescription',
	ANNUAL_SALARY = 'annualSalary',
	ABSENCE_STORY = 'absenceStory',
	DIALOG_MESSAGE = 'dialogMessage',
	INCIDENT_CONTEXT = 'incidentContext',
	PERSON_MUTATED = 'personMutated',
	ATTACHMENT_STORY = 'attachmentStory',
	MANUAL_ADDITIONS = 'manualAdditions',
}
