import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { StatisticPageAction } from '../../state/action';
import { Option } from 'nc-shared';
import { DialogConfigurationUtils, DialogService, DialogType, EnumUtils } from 'nc-utils';
import { AbsenceTypeEnumeration } from '../../../shared/enumeration/absence-type.enumeration';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { ExportExtensionEnumeration } from '../../../shared/enumeration/export-extension.enumeration';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';
import { NcInformationDialogComponent } from 'nc-information-dialog';

@Component({
	templateUrl: './word-statistic.component.html',
	styleUrls: ['./word-statistic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WordStatisticComponent implements OnInit {
	formGroup: FormGroup;

	flagOptions: Option[];
	absenceTypeOptions: Option[];

	constructor(private store: Store, formBuilder: FormBuilder, private dialogService: DialogService) {
		this.formGroup = formBuilder.group({
			dateFrom: [''],
			dateTo: [''],
			type: ['OVERALL'],
			insuranceType: ['PKPRO'],
			company: [''],
			person: [''],
			absenceActivity: ['ALL'],
			isShowCmData: ['no'],
			isCaseManagementSuccessful: ['no'],
			selectedTypes: [[AbsenceTypeEnumeration.ACCIDENT.code, AbsenceTypeEnumeration.SICKNESS.code, AbsenceTypeEnumeration.PREGNANCY.code]],
		});

		this.flagOptions = EnumUtils.toItems(FlagEnumeration);
		this.absenceTypeOptions = EnumUtils.toItems(AbsenceTypeEnumeration);
	}

	ngOnInit(): void {}

	onExport = (): void => {
		this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());
		const data = this.formGroup.getRawValue();
		if (!this.formGroup.invalid) {
			this.store.dispatch(StatisticPageAction.exportToFile({ searchFilter: data, exportType: ExportExtensionEnumeration.WORD }));
		} else {
			this.dialogService.close(DialogType.PROCESSING);
			this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('checkAllData', 'info'));
		}
	};
}
