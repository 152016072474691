import { AuthorityTypeEnumeration } from './enumeration/authority-type.enumeration';
import { AccessTypeEnumeration } from './enumeration/access-type.enumeration';
import { OperationTypeEnumeration } from './enumeration/operation-type.enumeration';

export class AuthorityOperationModel {
	id: number;
	ids?: Array<number>;
	authority: AuthorityTypeEnumeration;
	access?: AccessTypeEnumeration;
	operation: OperationTypeEnumeration;
}
