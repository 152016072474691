<div fxFill fxLayout="column">
	<div id="user-container" [formGroup]="formGroup" *ngIf="hasViewAllUsersNotificationPermission">
		<nc-select
			id="user"
			searchTextId="user-search"
			label="user"
			formControlName="user"
			[formGroup]="formGroup"
			[comparisonFunction]="comparisonFunction"
			[data$]="users$"
		>
		</nc-select>
	</div>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="notifications-container">
				<nc-datatable [rowClick]="onRowClick" [columns]="notificationsColumns" [data$]="notifications$" [filterConfig]="filterConfig">
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-notifications-footer [displayButton]="loggedInUser?.type === selectedUser?.type" [selectedUser]="selectedUser"> </ea-notifications-footer>
</div>
