import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { EnumUtils, FormUtils } from 'nc-utils';
import { LanguageEnumeration } from '../../../../shared/enumeration/language.enumeration';
import { AbsenceSelect } from '../../../state/action';
import { MatDialogRef } from '@angular/material/dialog';
import { isReportDateFilled } from '../../../validator/generate-document-dialog.validator';
import { AppConstants } from '../../../../app.constants';

@Component({
	templateUrl: './generate-document-dialog.component.html',
	styleUrls: ['./generate-document-dialog.component.scss'],
})
export class GenerateDocumentDialogComponent {
	formGroup: FormGroup;
	languageOptions: Option[];
	documentTypes$: Observable<Option[]>;

	constructor(store: Store, formBuilder: FormBuilder, public dialogRef: MatDialogRef<GenerateDocumentDialogComponent>) {
		this.languageOptions = EnumUtils.toItems(LanguageEnumeration);
		this.documentTypes$ = store.select(AbsenceSelect.getGenerateDocumentTypes);

		this.formGroup = formBuilder.group(
			{
				language: ['en'],
				documentType: ['', Validators.required],
				reportDate: [null],
			},
			{
				validators: [isReportDateFilled()],
			}
		);
	}

	isReportDateVisible(): boolean {
		return this.formGroup.get('documentType').value === 'POWER_OF_ATTORNEY_AND_REMINDER_MEDICAL_REPORT';
	}

	generate(): any {
		if (this.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		this.dialogRef.close({ success: true, data: this.formGroup.getRawValue() });
	}
}
