import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccidentDescription } from '../../model/accident-description';
import { CanDeactivateFormComponent } from 'nc-shared';
import { BroadcastEventType } from '../../../shared/enumeration/broadcast-event-type.enumeration';
import { takeUntil } from 'rxjs';
import { EventQueueService } from '../../../shared/service/event-queue.service';
import { Store } from '@ngrx/store';
import { AbsencePageAction } from '../../../absence/state/action';
import { RequestFactoryService } from '../../service/request-factory.service';
import { ValidationService } from '../../../shared/service/validation.service';
import { ErrorSelect } from '../../../state/error/action';
import { UserSelect } from '../../../user/state/action';
import { PermissionEnumeration } from '../../../shared/enumeration/permission.enumeration';

@Component({
	templateUrl: './accident-description.component.html',
})
export class AccidentDescriptionComponent extends CanDeactivateFormComponent implements OnInit {
	absenceCode: string;
	formGroup: FormGroup;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private eventQueue: EventQueueService,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef,
		private requestFactory: RequestFactoryService
	) {
		super();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({});
		this.route.params.subscribe((params) => {
			this.absenceCode = params.code;
			this.store.dispatch(AbsencePageAction.setAbsenceCode({ code: this.absenceCode }));
		});

		this.eventQueue
			.on(BroadcastEventType.ON_SAVE)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.onSubmit());

		this.store.select(ErrorSelect.getErrors).subscribe((errors) => {
			this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef);
		});
	}

	dispatchSubmitAction = (): void => {
		const data = this.formGroup.controls.accidentDescription.value as AccidentDescription;
		this.store.dispatch(AbsencePageAction.saveAccidentDescription({ data: this.requestFactory.getAccidentDescriptionValue(data) }));
	};
}
