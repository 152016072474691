import { ChangeDetectorRef, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorDetails } from 'nc-utils';

@Injectable({
	providedIn: 'root',
})
export class ValidationService {
	constructor() {}

	/**
	 * Displays serverside errors on form
	 * @param errors map of errors where key is name of field
	 * @param formGroup form group
	 * @param changeDetectorRef change detector it can be injected only into component so it is passed as function parameter
	 */
	displayErrors = (
		errors: {
			[key: string]: ErrorDetails;
		},
		formGroup: FormGroup,
		changeDetectorRef: ChangeDetectorRef
	) => {
		if (errors) {
			Object.keys(errors).forEach((key) => {
				const control = formGroup.get(key);
				if (control) {
					control.setErrors({ [errors[key].code]: null });
					control.markAsTouched();
					changeDetectorRef?.markForCheck();
				}
				const controls = Object.values(formGroup.controls);
				controls.filter((c) => c instanceof FormGroup).forEach((group) => this.displayErrors(errors, group as FormGroup, changeDetectorRef));
			});
		}
	};
}
