import { createAction, props } from '@ngrx/store';
import { UserModel } from '../../model/user.model';

export const getAllUsers = createAction('[User administration] Get all users');
export const getUser = createAction('[User administration] Get user', props<{ id: number }>());
export const generatePassword = createAction('[User administration] Generate password');
export const activateUser = createAction('[User administration] User activate', props<{ id: number }>());
export const deactivateUser = createAction('[User administration] User deactivate]', props<{ id: number }>());
export const deleteUser = createAction('[User administration] Delete user', props<{ id: number }>());
export const saveUser = createAction('[User administration] Save user]', props<{ user: UserModel }>());
export const openDeleteConfirmationDialog = createAction('[User administration] Open delete confirmation dialog', props<{ id: number }>());
export const clearUser = createAction('[User administration] Clear user');
export const copyCompanies = createAction('[User administration] Copy companies', props<{ copiedCompanies: string[] }>());
