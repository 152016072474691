import * as ApplicationState from '../../state/app.state';
import { MilestoneSettingsModel } from '../model/milestone-settings';
import { TableData } from 'nc-datatable';

export interface MilestoneSettingsState {
	milestoneSettings: TableData;
	documentsCheckList: TableData;
	currentMilestoneSettings: MilestoneSettingsModel;
}

export interface State extends ApplicationState.State {
	milestone_settings: MilestoneSettingsState;
}

export const MILESTONE_SETTINGS_INITIAL_STATE: MilestoneSettingsState = {
	milestoneSettings: { records: [] },
	documentsCheckList: { records: [] },
	currentMilestoneSettings: null,
};
