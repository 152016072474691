import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccessAssignmentEffect } from './state/access-assignment.effect';
import { ACCESS_ASSIGNMENT_FEATURE_STATE_NAME } from './state/action/access-assignment.select';
import { accessAssignmentReducer } from './state/access-assignment.reducer';
import { AccessAssignmentComponent } from './component/access-assignment.component';
import { NcLinkedTablesModule } from 'nc-linked-tables';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([AccessAssignmentEffect]),
		StoreModule.forFeature(ACCESS_ASSIGNMENT_FEATURE_STATE_NAME, accessAssignmentReducer),
		NcLinkedTablesModule,
	],
	declarations: [AccessAssignmentComponent],
	exports: [AccessAssignmentComponent],
})
export class AccessAssignmentModule {}
