import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { KleSelect } from '../../state/action';

@Component({
	selector: 'ea-cover-daily-allowance',
	templateUrl: './covered-daily-allowance.component.html',
	styleUrls: ['./covered-daily-allowance.component.scss'],
})
export class CoveredDailyAllowanceComponent extends BaseComponent implements OnInit {
	@Input() absenceCode: string;

	data$: Observable<TableData>;
	filterConfig: FilterConfig;
	columns: Array<DatatableColumn>;

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.filterConfig = {
			showGlobalFilter: true,
			filterValues: {},
		};
		this.columns = this.getColumns();
		this.data$ = this.store.select(KleSelect.getCoveredDailyAllowances);
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('storyId'),
			ColumnBuilder.create('allowance', 'allowance'),
			ColumnBuilder.create('validAsOf', 'validAsOf'),
			ColumnBuilder.create('description', 'description'),
			ColumnBuilder.createDate('createdOn', 'creationDate'),
		];
	}
}
