<div id="cross-channel-link-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<form [formGroup]="crossChannelLinkFormGroup" fxFill fxLayout="column">
		<div fxLayout="row" fxLayoutGap="10px">
			<nc-input label="key" id="key" formControlName="key" [isReadonly]="isReadOnly" [formGroup]="crossChannelLinkFormGroup"> </nc-input>
			<nc-input label="password" id="password" formControlName="password" [isReadonly]="isReadOnly" [formGroup]="crossChannelLinkFormGroup">
			</nc-input>
		</div>
		<div fxLayout="row" fxLayoutGap="10px">
			<nc-input label="url" id="url" formControlName="url" [isReadonly]="isReadOnly" [formGroup]="crossChannelLinkFormGroup"> </nc-input>
			<nc-input
				label="webService"
				id="web-service"
				formControlName="webService"
				[isReadonly]="isReadOnly"
				[formGroup]="crossChannelLinkFormGroup"
			>
			</nc-input>
		</div>
		<nc-textarea
			label="linkDescription"
			id="link-description"
			formControlName="linkDescription"
			[isReadonly]="isReadOnly"
			[formGroup]="crossChannelLinkFormGroup"
		>
		</nc-textarea>
		<div fxLayout="row" fxLayoutGap="10px">
			<nc-datepicker
				id="expiry-date"
				[formGroup]="crossChannelLinkFormGroup"
				formControlName="expiryDate"
				label="expiryDate"
				[isReadonly]="isReadOnly"
			></nc-datepicker>
			<nc-datepicker
				id="creation-date"
				[formGroup]="crossChannelLinkFormGroup"
				formControlName="creationDate"
				label="creationDate"
				[isReadonly]="isReadOnly"
			></nc-datepicker>
		</div>
	</form>
	<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end">
		<nc-button id="close" label="close" [mat-dialog-close]="false"></nc-button>
	</div>
</div>
