import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KlePageAction, KleSelect } from '../../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { DialogMessageService } from '../../../service/dialog-message/dialog-message.service';
import { DialogMessage } from '../../../model/dialog-message';
import { ReasonForAbsenceMessageRequestFactory } from '../../../service/dialog-message/reason-for-absence-message-request.factory';
import { DialogMessageTypeEnum } from '../../../model/enums/dialog-message-type.enum';

@Component({
	templateUrl: './reason-for-absence-form.component.html',
	styleUrls: ['./reason-for-absence-form.component.scss'],
})
export class ReasonForAbsenceFormComponent extends BaseComponent implements OnInit {
	dialogMessage: DialogMessage;

	reasonForAbsenceMessageGroup = this.formBuilder.group({
		title: '',
		info: '',
		dialogMessageType: DialogMessageTypeEnum.REASON_FOR_ABSENCE.code,
		companyCaseId: this.data.absenceCode,
		previous: this.data.storyId,
		disease: '',
		accident: '',
		maternity: '',
		militaryService: '',
		vacation: '',
		unpaidLeave: '',
		leavingCompany: '',
		activityWithAnotherEmployer: '',
		otherReason: '',
		description: '',
		creator: '',
	});

	constructor(
		private dialogMessageService: DialogMessageService,
		private formBuilder: FormBuilder,
		private store: Store,
		private requestFactory: ReasonForAbsenceMessageRequestFactory,
		@Inject(MAT_DIALOG_DATA) public data: { absenceCode: string; storyId: string; formGroup: FormGroup; creator: string }
	) {
		super();
	}

	ngOnInit(): void {
		if (this.data.storyId != null) {
			this.store.dispatch(KlePageAction.getDialogMessage({ absenceCode: this.data.absenceCode, storyId: this.data.storyId }));
		}

		this.store
			.select(KleSelect.getDialogMessage)
			.pipe(
				takeUntil(this.destroy$),
				filter((message) => message != null)
			)
			.subscribe((message) => {
				this.dialogMessage = message;
				this.dialogMessageService.populateReasonForAbsenceMessageForm(
					this.reasonForAbsenceMessageGroup,
					message,
					message.reasonForAbsenceMessage
				);
			});
	}

	submit(): void {
		const context = this.data.formGroup.controls.incidentContext.value;
		const reasonForAbsenceData = this.reasonForAbsenceMessageGroup.getRawValue();

		const dialogMessageData = this.requestFactory.create(reasonForAbsenceData, this.dialogMessage);

		const request = { incidentContext: context, dialogMessage: dialogMessageData };
		this.store.dispatch(KlePageAction.synchronizeIncident({ request }));
	}
}
