import { createReducer, on } from '@ngrx/store';
import { NotificationsApiAction, NotificationsPageAction } from './action';
import { NOTIFICATIONS_INITIAL_STATE, NotificationsState } from './notifications.state';

export const notificationsReducer = createReducer<NotificationsState>(
	NOTIFICATIONS_INITIAL_STATE,
	on(NotificationsApiAction.getNotificationsSuccess, (state, action): NotificationsState => {
		return {
			...state,
			notifications: { records: action.records },
		};
	}),
	on(NotificationsApiAction.getUsersSuccess, (state, action): NotificationsState => {
		return {
			...state,
			users: action.users.map((x) => {
				return {
					value: { id: x.id, type: x.type },
					text: x.name + ' (' + x.notificationNumber + ')',
				};
			}),
		};
	}),
	on(NotificationsPageAction.setSelectedUser, (state, action): NotificationsState => {
		return {
			...state,
			selectedUser: action.user,
		};
	})
);
