import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ExcelStatisticComponent } from './component/excel-statistic/excel-statistic.component';
import { WordStatisticComponent } from './component/word-statistic/word-statistic.component';
import { StatisticFilterComponent } from './component/statistic-filter/statistic-filter.component';
import { StatisticFooterComponent } from './component/statistic-footer/statistic-footer.component';
import { StoreModule } from '@ngrx/store';
import { STATISTIC_FEATURE_STATE_NAME } from './state/action/statistic.select';
import { statisticReducer } from './state/statistic.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StatisticEffect } from './state/statistic.effect';
import { ChartComponent } from '../shared/component/chart/chart.component';

@NgModule({
	imports: [SharedModule, EffectsModule.forFeature([StatisticEffect]), StoreModule.forFeature(STATISTIC_FEATURE_STATE_NAME, statisticReducer)],
	declarations: [ChartComponent, WordStatisticComponent, ExcelStatisticComponent, StatisticFooterComponent, StatisticFilterComponent],
	exports: [WordStatisticComponent, ExcelStatisticComponent, ChartComponent],
})
export class StatisticModule {}
