<nc-confirmation-dialog titleLabel="synchronizationReport" buttonLabel="ok">
	<mat-tab-group mat-align-tabs="center">
		<mat-tab fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<mat-icon color="primary" *ngIf="easyTempData.success">check_circle</mat-icon>
				<mat-icon color="warn" *ngIf="!easyTempData.success">cancel</mat-icon>
				{{ easyTempData.application }}
			</ng-template>
			<ea-integration-response-dialog-tab [data]="easyTempData" [tableData$]="easyTempTableData$"></ea-integration-response-dialog-tab>
		</mat-tab>
		<mat-tab *ngIf="response.results.length > 1" fxLayout="column" fxLayoutGap="10px">
			<ng-template mat-tab-label>
				<mat-icon color="primary" *ngIf="neoData.success">check_circle</mat-icon>
				<mat-icon color="warn" *ngIf="!neoData.success">cancel</mat-icon>
				{{ neoData.application }}
			</ng-template>
			<ea-integration-response-dialog-tab [data]="neoData" [tableData$]="neoTableData$"></ea-integration-response-dialog-tab>
		</mat-tab>
	</mat-tab-group>
</nc-confirmation-dialog>
