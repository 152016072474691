import { Component, OnInit } from '@angular/core';
import { UserSelect } from '../../../user/state/action';
import { Store } from '@ngrx/store';
import { MenuItem } from '../../model/menu-item';
import { MenuItems } from '../../model/sidebar-config';
import { UserState } from '../../../user/state/user.state';
import { TellcoConstants } from '../../environment-constants/tellco-constants';
import { EcareConstants } from '../../environment-constants/ecare-constants';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	selector: 'ea-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	fullName: string;
	filteredMenuItems: MenuItem[];
	user: UserState;
	activeProfile: string;
	logo = '';
	currentTheme: string;

	constructor(private store: Store, private environmentLoader: EnvironmentLoader) {}

	ngOnInit(): void {
		const environment = this.environmentLoader.getEnvironment();
		this.currentTheme = environment.theme;
		this.store.select(UserSelect.getFullName).subscribe((result) => (this.fullName = result));
		this.store.select(UserSelect.getUser).subscribe((result) => (this.user = result));
		this.store
			.select(UserSelect.getPermissions)
			.subscribe((permissions) => (this.filteredMenuItems = MenuItems.filter((menuItem) => menuItem.isVisible(permissions))));
		this.activeProfile = environment?.name;
		this.logo = this.currentTheme === 'tellco-theme' ? TellcoConstants.PK_PRO_USER_URI : EcareConstants.LOGO_URI;
	}
}
