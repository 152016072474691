import * as ApplicationState from '../../state/app.state';
import { TableData } from 'nc-datatable';

export interface AdministrationState {
	companies: TableData;
}

export interface State extends ApplicationState.State {
	administration: AdministrationState;
}

export const ADMINISTRATION_STATE: AdministrationState = {
	companies: { records: [] },
};
