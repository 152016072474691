<form [formGroup]="accidentDescriptionGroup" fxFill fxLayout="column">
	<div fxLayout="column" fxLayoutGap="30px">
		<nc-button
			id="fetch-accident-description-from-absence"
			*ngIf="isHr && absenceCode"
			label="updateFromBranch"
			(click)="fetchFromAbsence()"
		></nc-button>
		<nc-toggle-buttons
			label="accidentDescriptionOptions"
			[formGroup]="accidentDescriptionGroup"
			[options]="accidentDescriptionOptionsEnum"
			formControlName="accidentDescriptionOptions"
		>
		</nc-toggle-buttons>
		<div *ngIf="accidentDescriptionGroup.controls.accidentDescriptionOptions.value === 'ACCIDENT'" fxLayout="column" fxLayoutGap="20px">
			<nc-toggle-buttons
				label="classOfInsuranceType"
				[formGroup]="accidentDescriptionGroup"
				[options]="classOfInsuranceTypeEnum"
				formControlName="classOfInsuranceType"
			>
			</nc-toggle-buttons>
			<div *ngIf="accidentDescriptionGroup.controls.classOfInsuranceType.value === 'ACCIDENT_AT_WORK'">
				<nc-input
					id="involved-object"
					label="involvedObject"
					[formGroup]="accidentDescriptionGroup"
					formControlName="involvedObject"
					fxFlex="60"
				></nc-input>
			</div>
			<div *ngIf="accidentDescriptionGroup.controls.classOfInsuranceType.value === 'ACCIDENT_AT_LEISURE'" fxLayout="row" fxLayoutGap="20px">
				<nc-datepicker id="last-working-day" [formGroup]="accidentDescriptionGroup" formControlName="lastWorkingDay" label="lastWorkingDay">
				</nc-datepicker>
				<nc-select
					id="activity-at-time-of-accident"
					searchTextId="activity-at-time-of-accident"
					searchButtonId="activity-at-time-of-accident-search-clear"
					clearIconId="activity-at-time-of-accident-clear"
					[formGroup]="accidentDescriptionGroup"
					[data$]="activityAtTimeOfAccidentEnum$"
					placeholder="activityAtTheTimeOfAccident"
					label="activityAtTheTimeOfAccident"
					formControlName="activityAtTheTimeOfAccident"
					[translate]="true"
					fxFlex="40"
				>
				</nc-select>
			</div>
		</div>
		<div *ngIf="accidentDescriptionGroup.controls.accidentDescriptionOptions.value === 'CASE_OF_DEATH'">
			<div fxLayout="column" fxLayoutGap="20px">
				<div fxLayout="row" fxLayoutGap="20px">
					<nc-datepicker
						id="date-of-death"
						[formGroup]="accidentDescriptionGroup"
						formControlName="dateOfDeath"
						label="dateOfDeath"
						[ngClass]="'margin-top-15'"
					>
					</nc-datepicker>
					<nc-toggle-buttons
						label="classOfInsuranceType"
						[formGroup]="accidentDescriptionGroup"
						[options]="classOfInsuranceTypeEnum"
						formControlName="classOfInsuranceTypeDateOfDeath"
					>
					</nc-toggle-buttons>
				</div>
				<div *ngIf="accidentDescriptionGroup.controls.classOfInsuranceTypeDateOfDeath.value === 'ACCIDENT_AT_WORK'">
					<nc-input
						id="involved-object-case-of-death"
						label="involvedObject"
						[formGroup]="accidentDescriptionGroup"
						formControlName="involvedObjectDateOfDeath"
						fxFlex="60"
					></nc-input>
				</div>
				<div
					*ngIf="accidentDescriptionGroup.controls.classOfInsuranceTypeDateOfDeath.value === 'ACCIDENT_AT_LEISURE'"
					fxLayout="row"
					fxLayoutGap="20px"
				>
					<nc-datepicker
						id="last-working-day-case-of-death"
						[formGroup]="accidentDescriptionGroup"
						formControlName="lastWorkingDayDateOfDeath"
						label="lastWorkingDay"
					>
					</nc-datepicker>
					<nc-select
						id="activity-at-time-of-accident-case-of-death"
						searchTextId="activity-at-time-of-accident-case-of-death"
						searchButtonId="activity-at-time-of-accident-case-of-death-search-clear"
						clearIconId="activity-at-time-of-accident-case-of-death-clear"
						[formGroup]="accidentDescriptionGroup"
						[data$]="activityAtTimeOfAccidentEnum$"
						placeholder="activityAtTheTimeOfAccident"
						label="activityAtTheTimeOfAccident"
						formControlName="activityAtTheTimeOfAccidentDateOfDeath"
						[translate]="true"
					>
					</nc-select>
				</div>
			</div>
		</div>
		<div *ngIf="accidentDescriptionGroup.controls.accidentDescriptionOptions.value === 'RELAPSE'">
			<ea-container title="storyRelapseType" fxFlex="40">
				<div fxLayout="row" fxLayoutGap="20px" [ngClass]="'margin-top-10'">
					<nc-input
						id="insurance-case"
						[formGroup]="accidentDescriptionGroup"
						formControlName="insuranceCaseId"
						label="insuranceCaseId"
					></nc-input>
					<nc-datepicker id="relapse-date" [formGroup]="accidentDescriptionGroup" formControlName="relapseDate" label="relapseDate">
					</nc-datepicker>
				</div>
			</ea-container>
		</div>
		<ea-container title="storyIncident" fxFlex="70">
			<div fxLayout="row" fxLayoutGap="20px" [ngClass]="'margin-top-10'">
				<nc-date-time-picker
					*ngIf="!accidentDescriptionGroup.controls.approximate.value"
					id="exact-date-time"
					[formGroup]="accidentDescriptionGroup"
					formControlName="exactDateTime"
					label="storyExactDate"
				>
				</nc-date-time-picker>
				<nc-datepicker
					*ngIf="!accidentDescriptionGroup.controls.exactDateTime.value"
					id="approximate-date"
					[formGroup]="accidentDescriptionGroup"
					formControlName="approximate"
					label="storyApproxDate"
				>
				</nc-datepicker>
			</div>
			<div *ngIf="showExactAproxDateTimeError()" class="error-container">
				{{ 'exactApproxDateTimeError' | translate }}
			</div>

			<div></div>
			<div fxFlex="70">
				<nc-input id="incident-scene" [formGroup]="accidentDescriptionGroup" formControlName="incidentScene" label="incidentScene"></nc-input>
			</div>
			<div fxFlex="70">
				<nc-input
					id="incident-description"
					[formGroup]="accidentDescriptionGroup"
					formControlName="incidentDescription"
					label="incidentDescription"
				></nc-input>
			</div>
			<div fxLayout="row" fxLayoutGap="20px">
				<nc-toggle-buttons
					label="policeReportExist"
					[formGroup]="accidentDescriptionGroup"
					[options]="yesNoUnknownEnum"
					formControlName="policeReportExists"
				>
				</nc-toggle-buttons>
				<nc-toggle-buttons
					label="existInvolvedPersons"
					[formGroup]="accidentDescriptionGroup"
					[options]="yesNoUnknownEnum"
					formControlName="existInvolvedPersons"
				>
				</nc-toggle-buttons>
			</div>
		</ea-container>
		<ea-container title="injuredPartOfBody" fxFlex="100">
			<ng-template #tableHeader>
				<div class="button-add-container" fxLayout="row wrap">
					<nc-button id="add" icon="add" [disabled]="isBranchHr" [onClick]="openDialog"></nc-button>
				</div>
			</ng-template>
			<nc-datatable
				id="injured-part-of-body"
				[tableHeader]="tableHeader"
				[columns]="getColumns()"
				[data$]="data$"
				[filterConfig]="filterConfig"
				[rowClick]="editBodyPart"
			></nc-datatable>
			<div *ngIf="getErrorMessage() as errorLabel" class="error-container">
				<ng-container>
					{{ errorLabel.label | translate : errorLabel.parameters }}
				</ng-container>
			</div>
		</ea-container>
	</div>
</form>
