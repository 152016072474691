<div *ngIf="data.success">
	<div fxLayout="row" fxLayoutAlign="space-between">
		<div class="positive-color">
			<nc-label [id]="'number-of-success'" [value]="'synchronization.numberOfSuccess'"></nc-label>
			{{ data.numberOfSucceeded }}
		</div>
		<div class="negative-color">
			<nc-label [id]="'number-of-failed'" [value]="'synchronization.numberOfFailed'"></nc-label>
			{{ data.numberOfFailed }}
		</div>
	</div>
	<nc-datatable [id]="'integration'" [columns]="reportColumns" [data$]="tableData$" [filterConfig]="filterConfig"> </nc-datatable>
</div>
<div *ngIf="!data.success">
	<nc-label [id]="'message-code'" [value]="data.messageCode"></nc-label>
</div>
