import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NotificationsService } from '../service/notifications.service';
import { NotificationsApiAction, NotificationsPageAction } from './action';
import { UserSelect } from '../../user/state/action';
import { Store } from '@ngrx/store';

@Injectable()
export class NotificationsEffect {
	constructor(private store: Store, private actions$: Actions, private notificationsService: NotificationsService) {}

	getUsersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(NotificationsPageAction.getUsers),
			switchMap(() => this.notificationsService.getUsers()),
			map((response) => NotificationsApiAction.getUsersSuccess({ users: response.options }))
		);
	});

	getNotificationsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(NotificationsPageAction.getNotifications),
			switchMap((action) => this.notificationsService.getNotifications(action.userId)),
			map((result) => NotificationsApiAction.getNotificationsSuccess({ records: result }))
		);
	});

	deleteNotificationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(NotificationsPageAction.deleteNotification),
			switchMap((action) => this.notificationsService.deleteNotification(action.notificationId)),
			map(() => NotificationsApiAction.deleteNotificationSuccess())
		);
	});

	markAllAsReadEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(NotificationsPageAction.markAllAsRead),
			switchMap((action) => this.notificationsService.markAllAsRead(action.userId)),
			withLatestFrom(this.store.select(UserSelect.getUserIdAndType)),
			switchMap(([, user]) => {
				return [NotificationsPageAction.getUsers(), NotificationsPageAction.setSelectedUser({ user })];
			})
		);
	});
}
