import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { UserApiAction, UserPageAction, UserSelect } from './action';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from '../service/authenticationService';
import { DialogConfigurationUtils, DialogService, DialogType } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { PermissionEnumeration } from '../../shared/enumeration/permission.enumeration';
import { SettingsPageAction, SettingsSelect } from '../../state/settings/action';
import { UserService } from '../service/user.service';
import { ErrorAction } from '../../state/error/action';
import { UserTypeEnumeration } from '../../shared/enumeration/user-type.enumeration';
import { createUserInitialState } from './user.state';
import { CompaniesSelectFactory } from '../../shared/utils/companies-select-factory';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable()
export class UserEffect {
	constructor(
		private router: Router,
		private actions$: Actions,
		private store: Store<State>,
		private userService: UserService,
		private dialogService: DialogService,
		private translateService: TranslateService,
		private companiesSelectFactory: CompaniesSelectFactory,
		private authenticationService: AuthenticationService,
		private environmentLoader: EnvironmentLoader
	) {}

	loginSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.loginSubmit),
			switchMap((credentials) => this.authenticationService.login(credentials)),
			switchMap((result) =>
				result.success
					? [UserApiAction.loginSubmitSuccess({ result }), SettingsPageAction.setLanguage({ language: result.language })]
					: [UserApiAction.loginSubmitFailed({ error: result.error })]
			)
		);
	});

	loginSubmitSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserApiAction.loginSubmitSuccess),
			withLatestFrom(this.store.select(SettingsSelect.getUIPreferences)),
			mergeMap(([action, uiPreferences]) => {
				const actions = [];
				if (uiPreferences[action.result.username] == null) {
					const userPreferences = createUserInitialState(action.result.id);
					actions.push(
						SettingsPageAction.setLocalStorageInitialData({
							username: action.result.username,
							userPreferences,
						})
					);
				}

				const user = action.result;

				const isHr = user.userType.toLowerCase() === UserTypeEnumeration.HR;
				if (isHr) {
					window.location.href = this.environmentLoader.getEnvironment().HR_UI_URL;
					UserPageAction.logout();
					return [];
				}

				const hasToChangePassword = user.permissions.includes(PermissionEnumeration.FORCING_PASSWORD_CHANGE);
				const menuItem = user.userType.toUpperCase() === UserTypeEnumeration.ADMIN.toUpperCase() ? '/user-administration' : '/dashboard';

				const destination = hasToChangePassword ? UserPageAction.resetPassword() : SettingsPageAction.openMenuItem({ menuItem });

				this.dialogService.close(DialogType.PROCESSING);

				user.has2Fa ? actions.push(UserPageAction.verifyLogin()) : actions.push(destination);

				return actions;
			})
		);
	});

	verifyLoginEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserPageAction.verifyLogin),
				tap(() => this.router.navigateByUrl('/verification'))
			);
		},
		{ dispatch: false }
	);

	resetPasswordEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserPageAction.resetPassword),
				tap(() => this.router.navigateByUrl('/reset/password'))
			);
		},
		{ dispatch: false }
	);

	loginSubmitFailedEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.loginSubmitFailed),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(action.error));
				})
			);
		},
		{ dispatch: false }
	);

	forgotPasswordSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.forgotPasswordSubmit),
			switchMap((action) => this.authenticationService.forgotPassword(action.username)),
			map((result) =>
				result.success ? UserApiAction.forgotPasswordSubmitSuccess({ result }) : ErrorAction.submitFailed({ errors: result.errors })
			)
		);
	});

	forgotPasswordSubmitSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.forgotPasswordSubmitSuccess),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					this.router.navigateByUrl('/forgot/password/verify');
				})
			);
		},
		{ dispatch: false }
	);

	verifyForgotPasswordSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.verifyForgotPasswordSubmit),
			withLatestFrom(this.store.select(UserSelect.getForgotPasswordToken)),
			mergeMap(([action, forgotPasswordToken]) => this.authenticationService.verifyForgotPassword(forgotPasswordToken, action.code)),
			map((result) =>
				result.success ? UserApiAction.verifyForgotPasswordSubmitSuccess() : ErrorAction.submitFailed({ errors: result.errors })
			)
		);
	});

	verifyForgotPasswordSubmitSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserApiAction.verifyForgotPasswordSubmitSuccess),
				switchMap(() => {
					const config = DialogConfigurationUtils.info('resetPasswordSuccessMessage', 'resetPasswordSuccessTitle');
					this.dialogService.close(DialogType.PROCESSING);
					return this.dialogService.open(NcInformationDialogComponent, config).afterClosed();
				}),
				tap(() => this.router.navigateByUrl('/'))
			);
		},
		{ dispatch: false }
	);

	verifyLoginSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.verifyLoginSubmit),
			withLatestFrom(this.store.select(UserSelect.getUsername)),
			mergeMap(([action, username]) => this.authenticationService.verifyLogin(username, action.code)),
			map((result) => (result.success ? UserApiAction.verifyLoginSubmitSuccess() : ErrorAction.submitFailed({ errors: result.errors })))
		);
	});

	verifyLoginSubmitSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserApiAction.verifyLoginSubmitSuccess),
			withLatestFrom(this.store.select(UserSelect.getPermissions)),
			map(([action, permissions]) => {
				const hasToChangePassword = permissions.includes(PermissionEnumeration.FORCING_PASSWORD_CHANGE);
				this.dialogService.close(DialogType.PROCESSING);

				return hasToChangePassword ? UserPageAction.resetPassword() : SettingsPageAction.openMenuItem({ menuItem: '/dashboard' });
			})
		);
	});

	resendSubmitEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserPageAction.resend),
				withLatestFrom(this.store.select(UserSelect.getUsername)),
				switchMap(([action, username]) => this.authenticationService.resend(username))
			);
		},
		{ dispatch: false }
	);

	checkLogoutEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(UserPageAction.checkLogout),
				tap(() => {
					this.store.dispatch(UserPageAction.setLogoutClicked({ logoutClicked: true }));
					this.router.navigateByUrl('/');
				})
			);
		},
		{ dispatch: false }
	);

	setLogoutClickedEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.setLogoutClicked),
			withLatestFrom(this.store.select(UserSelect.getIsFormPage)),
			filter(([action, isFormPage]) => !isFormPage),
			map(() => UserPageAction.logout())
		);
	});

	isLogoutAllowedEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.allowLogout),
			filter((action) => action.allowLogout),
			map(() => UserPageAction.logout())
		);
	});

	resetPasswordSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.resetPasswordSubmit),
			withLatestFrom(this.store.select(UserSelect.getToken)),
			mergeMap(([action, token]) => this.authenticationService.resetPassword(action.password, action.repeatedPassword, token)),
			map((result) => (result.success ? UserApiAction.resetPasswordSubmitSuccess() : ErrorAction.submitFailed({ errors: result.errors })))
		);
	});

	resetPasswordSubmitSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserApiAction.resetPasswordSubmitSuccess),
			map(() => {
				this.dialogService.close(DialogType.PROCESSING);
				return SettingsPageAction.openMenuItem({ menuItem: '/dashboard' });
			})
		);
	});

	getPersonalDataEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserApiAction.getPersonalData),
			withLatestFrom(this.store.select(UserSelect.getUsername)),
			mergeMap(([action, username]) => this.userService.getPersonalDetails(username)),
			map((personalData) => UserApiAction.getPersonalDataSuccess({ personalData }))
		);
	});

	personalDataSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.personalDataSubmit),
			mergeMap((personalData) => this.userService.savePersonalData(personalData)),
			map((result) => (result.success ? UserApiAction.personalDataSubmitSuccess() : ErrorAction.submitFailed({ errors: result.errors })))
		);
	});

	personalDataSubmitSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserApiAction.personalDataSubmitSuccess),
			withLatestFrom(this.store.select(UserSelect.getLanguage), this.store.select(UserSelect.getUserType)),
			switchMap(([action, language, userType]) => {
				let url;
				url = userType === 'ADMIN' ? '/user-administration' : '/dashboard';
				this.dialogService.close(DialogType.PROCESSING);
				this.translateService.getTranslation(language);
				return [SettingsPageAction.setLanguage({ language }), SettingsPageAction.openMenuItem({ menuItem: url })];
			})
		);
	});

	changePasswordSubmitEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.changePasswordSubmit),
			switchMap((action) => this.userService.changePassword(action.oldPassword, action.newPassword, action.confirmationPassword)),
			map((result) => (result.success ? UserApiAction.changePasswordSubmitSuccess() : ErrorAction.setErrors({ errors: result.errors })))
		);
	});

	changePasswordSubmitSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserApiAction.changePasswordSubmitSuccess),
			switchMap(() => {
				this.dialogService.close(DialogType.PROCESSING);
				const dialogConfig = DialogConfigurationUtils.info('changePasswordSuccessMessage', 'changePassword');

				return this.dialogService.open(NcInformationDialogComponent, dialogConfig).afterClosed();
			}),
			map(() => SettingsPageAction.openMenuItem({ menuItem: '/dashboard' }))
		);
	});

	refreshTokenEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserPageAction.refreshToken),
			withLatestFrom(this.store.select(UserSelect.getRefreshToken)),
			switchMap(([action, refreshToken]) => this.authenticationService.refreshToken(refreshToken)),
			map((response) => {
				return UserApiAction.refreshTokenSuccess({ refreshTokenResponse: response });
			})
		);
	});
}
