<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content>
			<ea-statistic-filter [formGroup]="formGroup"></ea-statistic-filter>
		</nc-form-content>
		<ng-container *ngIf="tableData$ | async as tableData">
			<ng-container *ngIf="!isMobile">
				<mat-card *ngIf="tableData.records.length > 0">
					<mat-card-content>
						<nc-datatable
							[id]="'excel'"
							[data$]="tableData$"
							[filterConfig]="filterConfig"
							[isTotalVisible]="isTotalVisible"
							[columns]="columns$ | async"
						>
						</nc-datatable>
					</mat-card-content>
				</mat-card>
				<div *ngIf="tableData.records.length > 0" fxLayout="column" fxLayoutGap="10px" class="margin-top-10">
					<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around" fxLayoutGap="10px">
						<ea-chart
							fxFlex="33"
							[type]="chartType.PIE"
							chartTitle="overall"
							[data]="overallChartData$ | async"
							[colors]="chartColors.overall"
						>
						</ea-chart>
						<ea-chart
							fxFlex="33"
							[type]="chartType.PIE"
							chartTitle="cmPieChartTitle"
							[data]="totalCmChartData$ | async"
							[colors]="chartColors.totalCm"
						>
						</ea-chart>
						<ea-chart
							fxFlex="33"
							[type]="chartType.BAR"
							chartTitle="sicknessBarChartTitle"
							[showLegend]="false"
							[data]="averageAbsenceDurationPerIllnessChartData$ | async"
							[colors]="chartColors.averageAbsenceDurationPerIllness"
						>
						</ea-chart>
					</div>
					<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around" fxLayoutGap="10px">
						<ea-chart
							fxFlex="33"
							[type]="chartType.PIE"
							chartTitle="cmSuccessfulPieChartTitle"
							[data]="reasonsForSuccessfulCmChartData$ | async"
							[colors]="chartColors.reasonsForSuccessfulCm"
						>
						</ea-chart>
						<ea-chart
							fxFlex="33"
							[type]="chartType.PIE"
							chartTitle="cmFailedPieChartTitle"
							[data]="reasonsForNotSuccessfulCmChartData$ | async"
							[colors]="chartColors.reasonsForNotSuccessfulCm"
						>
						</ea-chart>
						<ea-chart
							fxFlex="33"
							[type]="chartType.BAR"
							chartTitle="accidentBarChartTitle"
							[showLegend]="false"
							[data]="averageAbsenceDurationPerAccidentChartData$ | async"
							[colors]="chartColors.averageAbsenceDurationPerAccident"
						>
						</ea-chart>
					</div>
				</div>
			</ng-container>
			<div *ngIf="isMobile">
				<mat-tab-group mat-align-tabs="center" *ngIf="tableData.records.length > 0">
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon [matTooltip]="'charts' | translate" matTooltipPosition="right">pie_chart</mat-icon>
						</ng-template>
						<div fxLayout="column" fxLayoutGap="10px" class="margin-top-10">
							<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
								<ea-chart
									[type]="chartType.PIE"
									chartTitle="overall"
									[data]="overallChartData$ | async"
									[colors]="chartColors.overall"
								>
								</ea-chart>
								<ea-chart
									[type]="chartType.PIE"
									chartTitle="cmPieChartTitle"
									[data]="totalCmChartData$ | async"
									[colors]="chartColors.totalCm"
								>
								</ea-chart>
								<ea-chart
									[type]="chartType.PIE"
									chartTitle="cmSuccessfulPieChartTitle"
									[data]="reasonsForSuccessfulCmChartData$ | async"
									[colors]="chartColors.reasonsForSuccessfulCm"
								>
								</ea-chart>
								<ea-chart
									[type]="chartType.PIE"
									chartTitle="cmFailedPieChartTitle"
									[data]="reasonsForNotSuccessfulCmChartData$ | async"
									[colors]="chartColors.reasonsForNotSuccessfulCm"
								>
								</ea-chart>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
								<ea-chart
									[type]="chartType.BAR"
									[showLegend]="false"
									chartTitle="sicknessBarChartTitle"
									[data]="averageAbsenceDurationPerIllnessChartData$ | async"
									[colors]="chartColors.averageAbsenceDurationPerIllness"
								>
								</ea-chart>
								<ea-chart
									[type]="chartType.BAR"
									[showLegend]="false"
									chartTitle="accidentBarChartTitle"
									[data]="averageAbsenceDurationPerAccidentChartData$ | async"
									[colors]="chartColors.averageAbsenceDurationPerAccident"
								>
								</ea-chart>
							</div>
						</div>
					</mat-tab>
					<mat-tab>
						<mat-card>
							<ng-template mat-tab-label>
								<mat-icon [matTooltip]="'table' | translate" matTooltipPosition="right">table_view</mat-icon>
							</ng-template>
							<mat-card-content>
								<nc-datatable
									[id]="'excel-statistic'"
									[data$]="tableData$"
									[filterConfig]="filterConfig"
									[isTotalVisible]="isTotalVisible"
									[columns]="columns$ | async"
								>
								</nc-datatable>
							</mat-card-content>
						</mat-card>
					</mat-tab>
				</mat-tab-group>
			</div>
		</ng-container>
	</nc-main-content>
	<ea-statistic-footer [isSearchVisible]="true" [onSearch]="onSearch" [onExport]="onExport" [formGroup]="formGroup"></ea-statistic-footer>
</div>
