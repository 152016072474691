import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccordionCheckboxMap } from '../../model/accordion-checkbox-map';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { RequestFactoryService } from '../../service/request-factory.service';
import { KlePageAction, KleSelect } from '../../state/action';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { combineLatest, map, Observable } from 'rxjs';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Incident } from '../../model/incident';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { AccordionCheckbox } from '../../model/accordion-checkbox';
import { UserSelect } from '../../../user/state/action';
import { PermissionEnumeration } from '../../../shared/enumeration/permission.enumeration';
import { Absence } from '../../../absence/model/absence';
import { AbsenceSelect } from '../../../absence/state/action';
import { AbsenceStatusEnumeration } from '../../../absence/model/enumeration/absence-status.enumeration';
import { BroadcastEventType } from '../../../shared/enumeration/broadcast-event-type.enumeration';
import { EventQueueService } from '../../../shared/service/event-queue.service';
import { StoryRecordEnum } from '../../model/enums/story-record.enum';
import { AwaitStoryParts } from '../../model/process-control-response';

@Component({
	templateUrl: './kle-details.component.html',
	styleUrls: ['./kle-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KleDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
	absence$: Observable<Absence>;
	formGroup: FormGroup;
	formGroupForSync: FormGroup;
	formGroupIncidentContext: FormGroup;
	absenceCode: string;
	incident: Incident;
	notificationColumns: DatatableColumn[];
	notifications$: Observable<TableData>;
	filterConfig: FilterConfig;
	accordionCheckboxMap = new AccordionCheckboxMap();
	awaitColumns: Array<DatatableColumn>;
	awaitStories$: Observable<TableData>;
	isCoverageYes = false;
	hasReadOnlyAccess = true;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private store: Store,
		private requestFactory: RequestFactoryService,
		private cdRef: ChangeDetectorRef,
		private eventQueue: EventQueueService
	) {
		super();
	}

	ngOnInit(): void {
		this.absence$ = this.store.select(AbsenceSelect.getAbsence);

		this.route.params.subscribe((params) => {
			this.absenceCode = params.code;
			this.store.dispatch(KlePageAction.getIncident({ absenceCode: params.code }));
		});

		this.formGroup = this.formBuilder.group({
			incidentContext: this.formBuilder.group({
				absenceCode: [''],
				insuranceId: [''],
				incidentId: [''],
			}),
		});

		this.formGroupForSync = this.formBuilder.group({});
		this.formGroupForSync.addControl(KleFormGroupEnum.INCIDENT_CONTEXT, this.formGroup.controls.incidentContext);

		this.formGroupIncidentContext = this.formBuilder.group({});
		this.formGroupIncidentContext.addControl(KleFormGroupEnum.INCIDENT_CONTEXT, this.formGroup.controls.incidentContext);

		combineLatest([this.store.select(UserSelect.getPermissions).pipe(takeUntil(this.destroy$)), this.absence$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([permissions, absence]) => {
				const isBranchHr = permissions.includes(PermissionEnumeration.BRANCH_HR);
				const isArchived = absence.status === AbsenceStatusEnumeration.ARCHIVED.code;
				this.hasReadOnlyAccess = isBranchHr || isArchived;
			});

		this.store
			.select(KleSelect.getIncident)
			.pipe(takeUntil(this.destroy$))
			.subscribe((incident: Incident) => {
				this.incident = incident;
				this.isCoverageYes = incident?.latestState?.coverage === 'YES';
				this.formGroup.markAsPristine();
				this.formGroup.get(KleFormGroupEnum.INCIDENT_CONTEXT).patchValue({
					incidentId: incident?.incidentCaseId,
					absenceCode: incident?.companyCaseId,
					insuranceId: incident?.insuranceCaseId,
				});

				this.accordionCheckboxMap.reset();
				incident?.awaitStory.forEach((awaitStory) => {
					this.setStoryAwaited(StoryRecordEnum[awaitStory.name].label);
				});
			});

		this.notifications$ = this.store.select(KleSelect.getNotifications);
		this.awaitStories$ = this.store.select(KleSelect.getAwaitStories).pipe(map((awaitStory) => ({ records: this.mapAwaitStory(awaitStory) })));

		this.notificationColumns = [
			ColumnBuilder.create('type', 'synchronization.table.type'),
			ColumnBuilder.create('descriptionCode', 'code'),
			ColumnBuilder.create('description', 'message'),
		];

		this.filterConfig = {
			showGlobalFilter: true,
		};

		this.awaitColumns = this.getAwaitColumns();

		this.eventQueue
			.on(BroadcastEventType.SYNCHRONIZE_INCIDENT_SUCCESS)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				window.location.reload();
			});
	}

	mapAwaitStory(model: AwaitStoryParts[]): any {
		const awaitStory = [];
		if (model?.length) {
			model.forEach((item) => {
				awaitStory.push({ name: StoryRecordEnum[item.name].label, date: item.date });
			});
		}
		return awaitStory;
	}

	ngAfterViewInit() {
		if (this.hasReadOnlyAccess) {
			this.formGroup.disable();
			this.cdRef.detectChanges();
		}
	}

	handleCheckboxValueChange(isFormControlChecked: boolean, accordionCheckbox: AccordionCheckbox): void {
		accordionCheckbox.checked = isFormControlChecked;
		if (isFormControlChecked) {
			this.formGroupForSync.addControl(accordionCheckbox.formControlName, this.formGroup.controls[accordionCheckbox.formControlName]);
		} else {
			this.formGroupForSync.removeControl(accordionCheckbox.formControlName);
		}
	}

	isAccordionInvalid(accordionCheckbox: AccordionCheckbox): boolean {
		return accordionCheckbox.checked && this.formGroupForSync.controls[accordionCheckbox.formControlName].status === 'INVALID';
	}

	isAccordionDirty(accordionCheckbox: AccordionCheckbox): boolean {
		return !accordionCheckbox.checked && this.formGroup.controls[accordionCheckbox.formControlName]?.dirty;
	}

	submit(): void {
		this.store.dispatch(
			KlePageAction.synchronizeIncident({
				request: this.requestFactory.generateSyncIncidentRequest(this.formGroupForSync.getRawValue()),
			})
		);
	}

	ngOnDestroy(): void {
		this.store.dispatch(KlePageAction.restartKleState());
	}

	sync(): void {
		this.store.dispatch(KlePageAction.synchronizeIncident({ request: this.formGroupIncidentContext.getRawValue() }));
	}

	getAwaitColumns(): Array<DatatableColumn> {
		return [ColumnBuilder.create('name', 'name'), ColumnBuilder.create('date', 'expirationDate')];
	}

	firstLetterToLowerCase(value: string): string {
		return value.charAt(0).toLowerCase() + value.slice(1);
	}

	generateReport(): void {
		this.store.dispatch(KlePageAction.generateAccidentCertificate({ absenceCode: this.absenceCode }));
	}

	setStoryAwaited(storyName: string): void {
		this.accordionCheckboxMap[this.firstLetterToLowerCase(storyName)].awaited = true;
	}

	generatePharmacyReport() {
		this.store.dispatch(KlePageAction.generatePharmacyCertificate({ absenceCode: this.absenceCode }));
	}
}
