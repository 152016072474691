import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationsComponent } from './component/notifications.component';
import { NotificationsEffect } from './state/notifications.effect';
import { NOTIFICATIONS_FEATURE_STATE_NAME } from './state/action/notifications.select';
import { notificationsReducer } from './state/notifications.reducer';
import { NotificationsFooterComponent } from './component/footer/notifications-footer.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([NotificationsEffect]),
		StoreModule.forFeature(NOTIFICATIONS_FEATURE_STATE_NAME, notificationsReducer),
	],
	declarations: [NotificationsComponent, NotificationsFooterComponent],
	exports: [NotificationsComponent],
})
export class NotificationsModule {}
