import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { DatatableColumn } from 'nc-datatable';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	selector: 'ea-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent extends BaseComponent implements OnInit {
	@Input() documentsFormArray: FormArray;
	@Input() isReadonly = false;
	@Input() additionalColumns: DatatableColumn[];
	@Input() hidePreview = false;
	@Input() hideMultiDownload = false;
	@Input() hideDelete = false;
	@Input() title = 'documents';
	@Input() id: string;
	@Input() buttonId: string;
	@Input() downloadButtonId: string;
	documentTypes$: Observable<Option[]>;

	private readonly DOCUMENT_TYPES_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/document-types/user`;

	constructor(private store: Store, private http: HttpClient, private environmentLoader: EnvironmentLoader) {
		super();
	}

	ngOnInit(): void {
		this.documentTypes$ = this.store.select(SettingsSelect.getLanguage).pipe(
			takeUntil(this.destroy$),
			switchMap(() => this.http.get<Array<any>>(this.DOCUMENT_TYPES_URL)),
			map((documentTypes) => documentTypes.map((x) => ({ text: x.name, value: x.code } as Option))),
			shareReplay(1)
		);

		this.store.select(SettingsSelect.getDeleteDocumentId).subscribe((documentId) => {
			if (documentId !== null) {
				const index = this.documentsFormArray.getRawValue().findIndex((x) => x.id === documentId);
				this.documentsFormArray.removeAt(index);
			}
		});
	}

	onDownload = (rowData): void => {
		this.store.dispatch(SettingsPageAction.downloadDocument({ id: rowData.docmanId }));
	};

	onPreview = (rowData): void => {
		const encodedDocumentName = encodeURIComponent(rowData.nameWithExtension).replace(/['()*]/g, function (c) {
			return '%' + c.charCodeAt(0).toString(16);
		});
		const encodedUrl = `document/${encodedDocumentName}/${rowData.docmanId}`;
		this.store.dispatch(SettingsPageAction.navigate({ url: encodedUrl }));
	};

	onDownloadSelected = (documentIds: number[]): void => {
		this.store.dispatch(SettingsPageAction.downloadMultipleDocuments({ documentIds }));
	};

	onDelete = (rowData): void => {
		this.store.dispatch(SettingsPageAction.openDeleteDocumentConfirmationDialog({ document: rowData }));
	};
}
