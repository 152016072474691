import { createAction, props } from '@ngrx/store';
import { Option } from 'nc-shared';
import { SelectModel } from '../../../shared/model/select.model';
import { ChartData } from '../../../shared/model/chart/chart-data';
import { StackedChartData } from '../../../shared/model/chart/stacked-chart-data';
import { TableData } from 'nc-datatable';

export const openActiveDashboardSuccess = createAction(
	'[Dashboard] Open dashboard page success',
	props<{ numberOfDays: Option[]; responsibleUsers: SelectModel }>()
);

export const openArchivedDashboardSuccess = createAction(
	'[Dashboard] Open archived absences page success',
	props<{ responsibleUsers: SelectModel }>()
);

export const getTasksSuccess = createAction('[Dashboard] Get tasks success', props<{ tasks: { [key: string]: string }[] }>());

export const getMilestonesSuccess = createAction('[Dashboard] Get milestones success', props<{ milestones: { [key: string]: string }[] }>());

export const getActiveAbsencesSuccess = createAction(
	'[Dashboard] Get active absences success',
	props<{ activeCases: { [key: string]: string }[] }>()
);

export const getArchivedAbsencesSuccess = createAction(
	'[Dashboard] Get archived absences success',
	props<{ archivedCases: { [key: string]: string }[] }>()
);

export const getHrActiveAbsenceSuccess = createAction('[Dashboard] Get HR active absence success', props<{ hrActiveAbsence: TableData }>());
export const getCasesToBeApprovedSuccess = createAction(
	'[Dashboard] Get HR absences to be approved success',
	props<{ casesToBeApproved: TableData }>()
);
export const getAwaitStoriesSuccess = createAction('[Dashboard] Get await stories success', props<{ awaitStories: TableData }>());

export const getHrStatisticSuccess = createAction(
	'[Dashboard] Get HR statistic success',
	props<{ charts: { pieChartData: Array<ChartData>; barChartData: Array<StackedChartData> } }>()
);

export const getHrCompaniesSuccess = createAction('[Dashboard] Get HR companies success', props<{ companies: Option[] }>());

export const getHrArchivedCasesSuccess = createAction('[Dashboard] Get HR archived cases success', props<{ archivedCases: TableData }>());
