import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Column, ColumnBuilder, DatatableColumn, Order, Search, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { IntegrationPageAction, IntegrationSelect } from '../../state/action';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';
import { EnumUtils } from 'nc-utils';
import { ContractTypeEnumeration } from '../../../user/model/contract-type.enumeration';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../../user/state/action';

@Component({
	templateUrl: './company.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
	companyColumns: DatatableColumn[];
	companies$: Observable<TableData>;
	yesNo$: Observable<Option[]>;
	contractType$: Observable<Option[]>;
	filterConfig: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	NAME_FILTER = 'nameFilter';
	EASY_TEMP_ID_FILTER = 'easyTempIdFilter';
	NEO_ID_FILTER = 'neoIdFilter';
	NEO_ENVIRONMENT_FILTER = 'neoEnvironmentFilter';
	UID_FILTER = 'uidFilter';
	IS_KLE_ENABLED_FILTER = 'isKleEnabledFilter';
	IS_SWICA_INSURED_FILTER = 'isSwicaInsuredFilter';
	IS_PK_PRO_INSURED_FILTER = 'isPkProInsuredFilter';
	EXTERNALLY_UPDATED_ON_FILTER = 'externallyUpdatedOnFilter';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.loggedUserSubscription$ = this.store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});

		this.yesNo$ = EnumUtils.toObservableItems(FlagEnumeration);
		this.contractType$ = EnumUtils.toObservableItems(ContractTypeEnumeration);
		this.companyColumns = this.createColumns();
		this.companies$ = this.store.select(IntegrationSelect.getCompanies);

		this.store.select(SettingsSelect.getCompanyUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});
		this.filterConfig = this.createFilterConfig();
	}

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `integration/companies/${rowData.id}` }));
	};

	filter = (start: number, length: number, order: Order[], columns: Column[], globalSearch: Search): void => {
		this.store.dispatch(IntegrationPageAction.filterCompanies({ start, length, order, columns, globalSearch }));
	};

	createColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('name', 'name', true),
			ColumnBuilder.createNumber('easyTempId', 'easyTempId', true),
			ColumnBuilder.createNumber('neoId', 'neoId', true),
			ColumnBuilder.create('neoEnvironment', 'neoEnvironment', true),
			ColumnBuilder.create('uid', 'uid', true),
			ColumnBuilder.createSelect('isPkProInsured', 'isPkProInsured', FlagEnumeration),
			ColumnBuilder.createSelect('isSwicaInsured', 'isSwicaInsured', FlagEnumeration),
			ColumnBuilder.createSelect('isKleEnabled', 'isKleEnabled', FlagEnumeration),
			ColumnBuilder.create('contractType', 'contractType'),
			ColumnBuilder.create('frontendStyle', 'defaultFrontend'),
			ColumnBuilder.createDate('externallyUpdatedOn', 'lastChangeDate', true),
		];
	}

	createFilterConfig(): FilterConfig {
		return {
			showFilter: true,
			showGlobalFilter: false,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
			filterValues: {
				nameFilter: this.userPreferences[this.NAME_FILTER],
				easyTempIdFilter: this.userPreferences[this.EASY_TEMP_ID_FILTER],
				neoIdFilter: this.userPreferences[this.NEO_ID_FILTER],
				neoEnvironmentFilter: this.userPreferences[this.NEO_ENVIRONMENT_FILTER],
				uidFilter: this.userPreferences[this.UID_FILTER],
				isKleEnabledFilter: this.userPreferences[this.IS_KLE_ENABLED_FILTER],
				isSwicaInsuredFilter: this.userPreferences[this.IS_SWICA_INSURED_FILTER],
				isPkProInsuredFilter: this.userPreferences[this.IS_PK_PRO_INSURED_FILTER],
				externallyUpdatedOnFilter: this.userPreferences[this.EXTERNALLY_UPDATED_ON_FILTER],
			},
		};
	}

	ngOnDestroy(): void {
		const filterObject = {
			nameFilter: this.filterConfig.columnFiltersFormGroup.get(this.NAME_FILTER).value,
			easyTempIdFilter: this.filterConfig.columnFiltersFormGroup.get(this.EASY_TEMP_ID_FILTER).value,
			neoIdFilter: this.filterConfig.columnFiltersFormGroup.get(this.NEO_ID_FILTER).value,
			neoEnvironmentFilter: this.filterConfig.columnFiltersFormGroup.get(this.NEO_ENVIRONMENT_FILTER).value,
			uidFilter: this.filterConfig.columnFiltersFormGroup.get(this.UID_FILTER).value,
			isKleEnabledFilter: this.filterConfig.columnFiltersFormGroup.get(this.IS_KLE_ENABLED_FILTER).value,
			isSwicaInsuredFilter: this.filterConfig.columnFiltersFormGroup.get(this.IS_SWICA_INSURED_FILTER).value,
			isPkProInsuredFilter: this.filterConfig.columnFiltersFormGroup.get(this.IS_PK_PRO_INSURED_FILTER).value,
			externallyUpdatedOnFilter: this.filterConfig.columnFiltersFormGroup.get(this.EXTERNALLY_UPDATED_ON_FILTER).value,
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(
			SettingsPageAction.setCompanyTableFilter({
				companyFilters: filterObject,
				loggedUser: this.loggedUser,
			})
		);
	}
}
