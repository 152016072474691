import { Injectable } from '@angular/core';
import { FormUtils } from 'nc-utils';
import { ChangeFrontendStyleModel } from '../model/change-frontend-style.model';
import { toBoolean } from '../../shared/enumeration/flag.enumeration';

@Injectable({
	providedIn: 'root',
})
export class CompanyModelFactory {
	createExisting(company: any): any {
		return {
			id: company.id,
			name: company.name,
			uid: company.uid,
			easyTempId: company.easyTempId !== null ? company.easyTempId : 0,
			neoId: company.neoId !== null ? company.neoId : 0,
			neoEnvironment: company.neoEnvironment,
			neoGroupId: company.neoGroupId,
			isMaster: toBoolean(company.isMaster),
			contractType: company.contractType,
			companyGroupId: company.companyGroupId,
			defaultAssigneeId: company.defaultAssigneeId,
			defaultAssigneeName: company.defaultAssigneeName,
			ownerFirstName: company.ownerFirstName,
			ownerLastName: company.ownerLastName,
			city: company.city,
			street: company.street,
			postbox: company.postbox,
			zip: company.zip,
			country: company.country,
			canton: company.canton,
			isSwicaInsured: toBoolean(company.isSwicaInsured),
			isPkProInsured: toBoolean(company.isPkProInsured),
			isKleEnabled: toBoolean(company.isKleEnabled),
			frontendStyle: company.frontendStyle,
		};
	}

	createNew(company: any): any {
		return {
			id: company.id,
			easyTempId: company.easyTempId || 0,
			neoId: company.neoId || 0,
			neoEnvironment: company.neoEnvironment,
			contractType: company.contractType,
			isSwicaInsured: toBoolean(company.isSwicaInsured),
			isPkProInsured: toBoolean(company.isPkProInsured),
			isKleEnabled: toBoolean(company.isKleEnabled),
			frontendStyle: company.frontendStyle,
		};
	}

	/**
	 * Create model for request.
	 * @param model
	 */
	createChangeFrontendStyleRequest(model: ChangeFrontendStyleModel): FormData {
		return FormUtils.objectToFormData({
			model: {
				ids: model.ids,
				type: model.type,
				operation: model.operation,
			},
		});
	}
}
