import { EnumItem } from 'nc-utils';

export const DialogMessageTypeEnum = {
	SIMPLE_MESSAGE: new EnumItem('SIMPLE_MESSAGE', 'simpleMessage'),
	TASK_WITH_DEADLINE: new EnumItem('TASK_WITH_DEADLINE', 'taskWithDeadline'),
	TASK_WITH_CONFIRMATION: new EnumItem('TASK_WITH_CONFIRMATION', 'taskWithConfirmation'),
	CONTACT_PERSON: new EnumItem('CONTACT_PERSON', 'contactPerson'),
	WORKPLACE_DESCRIPTION: new EnumItem('WORKPLACE_DESCRIPTION', 'workPlaceDescription'),
	REASON_FOR_ABSENCE: new EnumItem('REASON_FOR_ABSENCE', 'reasonForAbsence'),
	FREE_DIALOG_MESSAGE: new EnumItem('FREE_DIALOG_MESSAGE', 'freeMessage'),
};
