import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Employee } from '../../../shared/model/employee';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntegrationPageAction, IntegrationSelect } from '../../state/action';

@Component({
	templateUrl: './employee-details.component.html',
	styleUrls: ['./employee-details.component.scss'],
})
export class EmployeeDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
	employee$: Observable<Employee>;
	employeeId: number;

	constructor(private store: Store, @Inject(MAT_DIALOG_DATA) data) {
		super();
		this.employeeId = data?.insuredPersonId;
		this.store.dispatch(IntegrationPageAction.getEmployee({ employeeId: this.employeeId }));
		this.employee$ = this.store.select(IntegrationSelect.getEmployee);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.store.dispatch(IntegrationPageAction.clearEmployeeData());
		this.employeeId = null;
	}
}
