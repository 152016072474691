import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { MilestoneSettingsPageAction, MilestoneSettingsSelect } from '../../state/action';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../../user/state/action';

@Component({
	templateUrl: './milestone-settings.component.html',
	styleUrls: ['./milestone-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneSettingsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	milestoneSettings$: Observable<TableData>;
	milestoneSettingsColumns: DatatableColumn[];
	filterConfig: FilterConfig;
	formGroup: FormGroup;
	globalFormGroup: FormGroup;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	GLOBAL_FILTER = 'globalFilter';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store) {
		super();
		this.loggedUserSubscription$ = store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});

		this.store.dispatch(MilestoneSettingsPageAction.getAllMilestoneSettings());
	}

	ngOnInit(): void {
		this.store.select(SettingsSelect.getMilestoneUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});
		this.milestoneSettingsColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('day', 'day'),
			ColumnBuilder.create('title', 'title'),
			ColumnBuilder.create('important', 'important'),
			ColumnBuilder.create('pkProInsured', 'pkPro'),
			ColumnBuilder.create('swicaInsured', 'swica'),
			ColumnBuilder.create('forAbsenceTypeSickness', 'forAbsenceTypeSickness'),
			ColumnBuilder.create('forAbsenceTypeAccident', 'forAbsenceTypeAccident'),
			ColumnBuilder.create('forAbsenceTypePregnancy', 'forAbsenceTypePregnancy'),
			ColumnBuilder.create('forCMUser', 'forCMUser'),
			ColumnBuilder.create('forLDUser', 'forLDUser'),
			ColumnBuilder.create('forCaseManagementActive', 'forCaseManagementActive'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];

		this.milestoneSettings$ = this.store.select(MilestoneSettingsSelect.getMilestoneSettings);

		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
			filterValues: {
				globalFilter: this.userPreferences[this.GLOBAL_FILTER],
			},
		};
	}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `milestone/settings/${rowData.id}` }));
	};

	delete = (rowData): void => {
		this.store.dispatch(MilestoneSettingsPageAction.openDeleteConfirmationDialog({ id: rowData.id }));
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			globalFilter: this.filterConfig.globalFilterFormGroup.get(this.GLOBAL_FILTER).value,
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(SettingsPageAction.setMilestoneTableFilter({ milestoneFilters: filterObject, loggedUser: this.loggedUser }));
	}
}
