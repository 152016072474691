import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LabelManagementDetails } from '../model/label-management-details';
import { RequestModelFactory } from './request-model.factory';
import { ActionResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { ResponseModel } from '../../shared/model/response.model';
import { TableData } from 'nc-datatable/lib/model/table-data';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class LabelManagementService {
	private readonly LABELS_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/labels`;

	constructor(private httpClient: HttpClient, private factory: RequestModelFactory, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets labels
	 */
	getLabels(): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<TableData>(this.LABELS_URL).pipe(map((response) => response.records));
	}

	/**
	 * Save label
	 * @param contract Label model from form data
	 */
	saveLabel(contract: LabelManagementDetails): Observable<ActionResponse> {
		const formData = this.factory.create(contract);
		return this.httpClient.put<ActionResponse>(this.LABELS_URL, formData);
	}

	/**
	 * Gets label
	 * @param code label code
	 */
	getLabel(code: string): Observable<ResponseModel<LabelManagementDetails>> {
		return this.httpClient.get<ResponseModel<LabelManagementDetails>>(`${this.LABELS_URL}/${code}/translations`);
	}
}
