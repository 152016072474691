<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div class="footer-navigation" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap.lt-md="5px" fxLayoutAlign="space-between">
		<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="5px">
			<nc-button
				[isRaised]="false"
				id="share-information"
				(click)="navigateToShareInformation()"
				*ngIf="!isReadOnly"
				label="shareInformation"
			></nc-button>
			<nc-button
				[isRaised]="false"
				id="accident-description"
				*ngIf="hasDeclareIncidentPermission && isHrOrBranchHr && isAccident && !isReadOnly"
				(click)="navigateToAccidentDescription()"
				label="accidentDescription"
			>
			</nc-button>
			<nc-button
				[isRaised]="false"
				id="treatments"
				*ngIf="hasDeclareIncidentPermission && isHrOrBranchHr && isAccident && !isReadOnly"
				(click)="navigateToTreatments()"
				label="treatments"
			>
			</nc-button>
			<nc-button
				id="kle"
				*ngIf="hasDeclareIncidentPermission && isIncidentDeclared && isAccident && !isBranchHr"
				(click)="navigateToKle()"
				[isRaised]="false"
				label="kle"
			>
			</nc-button>
		</div>
		<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="5px">
			<nc-submit-button
				*ngIf="isBranchHr && isDraftStatus"
				id="delete-draft"
				(buttonClick)="deleteDraft()"
				dialogLabel="processing"
				[formGroup]="formGroup"
				buttonLabel="deleteDraft"
			>
			</nc-submit-button>
			<nc-submit-button
				*ngIf="isBranchHr && !isReadOnly"
				id="save-and-send"
				(buttonClick)="saveAndSend()"
				dialogLabel="processing"
				[formGroup]="formGroup"
				buttonLabel="saveAndSend"
			>
			</nc-submit-button>
			<nc-button
				id="declare-incident"
				(click)="declareIncident()"
				*ngIf="
					hasDeclareIncidentPermission &&
					!isIncidentDeclared &&
					isAccident &&
					!isBranchHr &&
					formGroup.controls.status.value !== 'PENDING_APPROVAL' &&
					!isReadOnly
				"
				[formGroup]="formGroup"
				label="declareIncident"
			>
			</nc-button>
			<nc-submit-button
				*ngIf="!isBranchHr && formGroup.controls.status.value == 'PENDING_APPROVAL'"
				id="reject"
				(buttonClick)="reject()"
				dialogLabel="processing"
				[formGroup]="formGroup"
				buttonLabel="reject"
			>
			</nc-submit-button>
			<nc-button id="archive" label="archive" [onClick]="onArchive" [formGroup]="formGroup" *ngIf="isOpen() && companyDetailsCheck()">
			</nc-button>
			<nc-submit-button
				*ngIf="(isBranchHr && isDraft()) || (!isBranchHr && !isArchived)"
				id="save"
				(buttonClick)="onSubmit()"
				dialogLabel="processing"
				[formGroup]="formGroup"
				buttonLabel="{{ isBranchHr ? 'saveDraft' : 'save' }}"
			>
			</nc-submit-button>
			<nc-button id="activate" *ngIf="isArchived" [onClick]="onActivate" [formGroup]="formGroup" label="activate"></nc-button>
		</div>
	</div>
</div>
