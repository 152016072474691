import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AbsenceSelect } from '../../../state/action';
import { Company } from '../../../../shared/model/company';

@Component({
	templateUrl: './company-dialog.component.html',
	styleUrls: ['./company-dialog.component.scss'],
})
export class CompanyDialogComponent implements OnInit {
	company$: Observable<Company>;
	permissions: string[];

	constructor(private store: Store) {}

	ngOnInit(): void {
		this.company$ = this.store.select(AbsenceSelect.getCompany);
	}
}
