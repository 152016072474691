import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatatableColumn, SelectConfig, TableData } from 'nc-datatable';
import { AbsenceTableColumns } from '../../../model/absence-table-columns';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { MergeAbsenceRequest } from '../../../model/request/merge-absence-request';
import { AbsencePageAction } from '../../../state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './merge-absence-dialog.component.html',
	styleUrls: ['./merge-absence-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeAbsenceDialogComponent {
	formGroup: FormGroup;
	code: string;
	mergeAbsence$: Observable<TableData>;
	mergeAbsenceColumns: DatatableColumn[];
	selectConfig: SelectConfig;
	filterConfig: FilterConfig;

	constructor(@Inject(MAT_DIALOG_DATA) data, private store: Store, formBuilder: FormBuilder) {
		this.code = data.code;
		this.mergeAbsence$ = of({ records: data.otherAbsences });
		this.mergeAbsenceColumns = AbsenceTableColumns.MERGE_ABSENCE_COLUMNS;

		this.formGroup = formBuilder.group({
			absenceCode: this.code,
			mergedAbsenceCode: null,
			reason: null,
		});

		this.selectConfig = {
			isSingleSelect: true,
			isFirstColumn: true,
			identifierColumn: 'code',
			formArray: formBuilder.array([]),
		};

		this.filterConfig = {
			filterValues: {},
		};
	}

	onMerge = () => {
		this.formGroup.get('mergedAbsenceCode').setValue(this.selectConfig.formArray.value[0].value);
		const data = this.formGroup.getRawValue() as MergeAbsenceRequest;
		this.store.dispatch(AbsencePageAction.mergeAbsence({ mergeAbsenceRequest: data }));
	};
}
