import { Option } from 'nc-shared';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TranslateEnumsUtils {
	constructor(private translateService: TranslateService) {}

	getTranslatedValues(enumeration: any): Option[] {
		let returnValue: Option[] = [];

		for (const key in enumeration) {
			returnValue.push({ value: enumeration[key].code, text: this.translateService.instant(enumeration[key].label) });
		}

		return returnValue;
	}
}
