<div id="archive-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div mat-dialog-title>
		<mat-icon class="dialog-title-icon">info</mat-icon>
		<span class="dialog-title-text">{{ 'archive' | translate }}</span>
	</div>
	<mat-dialog-content id="archive-dialog-content" [formGroup]="formGroup" fxLayout="column" fxLayoutGap="10px">
		<div>{{ 'selectCaseArchivingReason' | translate }}</div>
		<nc-select
			[id]="'archiving-reason'"
			[searchTextId]="'archiving-reason-search'"
			[searchButtonId]="'archiving-reason-search-clear'"
			[clearIconId]="'archiving-reason-clear'"
			[formGroup]="formGroup"
			formControlName="reason"
			label="selectArchivingReason"
			[data$]="reasons$"
			[translate]="true"
			[required]="true"
		>
		</nc-select>
		<nc-textarea
			[id]="'comment'"
			[formGroup]="formGroup"
			formControlName="comment"
			label="comment"
			[maxLength]="140"
			[required]="formGroup.get('reason').value === 'OTHER_ARCHIVE_REASON'"
		>
		</nc-textarea>
	</mat-dialog-content>
	<mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
			<nc-button [id]="'close'" label="close" [mat-dialog-close]="{ success: false }"></nc-button>
			<nc-button [id]="'archive'" label="archive" (click)="submit()"></nc-button>
		</div>
	</mat-dialog-actions>
</div>
