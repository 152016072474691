import { ErrorDetails } from 'nc-utils/lib/model/error-details';

export class GetResultResponse {
	success: boolean;
	errors: {
		[key: string]: ErrorDetails;
	};
	password: string;
	expectedAvailability: string;

	constructor(success: boolean, errors: any, password: string, expectedAvailability: string) {
		this.success = success;
		this.errors = errors;
		this.password = password;
		this.expectedAvailability = expectedAvailability;
	}
}
