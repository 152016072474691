import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Settlement } from '../../model/settlement/settlement';
import { KlePageAction, KleSelect } from '../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';

@Component({
	selector: 'ea-settlement',
	templateUrl: './settlement.component.html',
	styleUrls: ['./settlement.component.scss'],
})
export class SettlementComponent extends BaseComponent implements OnInit {
	@Input()
	absenceCode: string;
	@Input()
	readOnly = false;

	data$: Observable<TableData>;
	settlement$: Observable<Settlement>;
	currentSettlement: string;

	filterConfig: FilterConfig = {
		showFilter: false,
		filterValues: {
			showFilter: false,
			showGlobalFilter: true,
		},
	};

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.data$ = this.store.select(KleSelect.getSettlements);
		this.settlement$ = this.store.select(KleSelect.getSettlement);

		this.settlement$
			.pipe(
				filter((settlement) => settlement != null),
				takeUntil(this.destroy$)
			)
			.subscribe((settlement) => {
				this.currentSettlement = settlement.generalInformation.id;
			});
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('id', 'settlementId'),
			ColumnBuilder.createDate('settlementDate', 'settlementDate'),
			ColumnBuilder.create('sendStatus', 'sentToEasyTemp'),
		];
	}

	sendSettlements() {
		this.store.dispatch(KlePageAction.sendSettlements({ absenceCode: this.absenceCode }));
	}

	preview = (rowData): void => {
		this.store.dispatch(KlePageAction.getSettlement({ absenceCode: this.absenceCode, settlementId: rowData.id }));
	};

	generatePDF(): void {
		this.store.dispatch(KlePageAction.generatePDF({ absenceCode: this.absenceCode, settlementId: this.currentSettlement }));
	}
}
