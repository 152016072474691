<div [formGroup]="taskWithDeadlineGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div fxLayout="column">
		<nc-input id="title" label="title" [formGroup]="taskWithDeadlineGroup" formControlName="title" [isReadonly]="true"></nc-input>
	</div>
	<div fxLayout="column">
		<nc-textarea
			class="text-wrapped"
			id="message"
			label="synchronization.table.Message"
			[formGroup]="taskWithDeadlineGroup"
			formControlName="message"
			[isReadonly]="true"
		>
		</nc-textarea>
	</div>
	<div fxLayout="row" fxLayoutAlign="end">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
	</div>
</div>
