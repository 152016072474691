import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CaseManagementDetails } from '../model/case-management-details';

@Injectable({
	providedIn: 'root',
})
export class CaseManagmentDetailsFactory {
	create = (formGroup: FormGroup): CaseManagementDetails => {
		return {
			uvgInsurer: formGroup.get('caseManagementKTGInsurer').value,
			ktgInsurer: formGroup.get('caseManagementUVGInsurer').value,
			office: {
				name: formGroup.get('caseManagementBranchOffice').value,
				contactPerson: formGroup.get('caseManagementBranchOfficeContactPerson').value,
				phoneNumber: formGroup.get('caseManagementBranchOfficePhoneNumber').value,
			},
			reports: {
				startedOn: formGroup.get('caseManagementStartedOnDate').value,
				interventionStatus: formGroup.get('caseManagementInterventionStatus').value,
				integrationReport: formGroup.get('caseManagementIntegrationReport').value,
				assessment: {
					date: formGroup.get('caseManagementAssessmentByExpertOnDate').value,
					status: formGroup.get('caseManagementAssessmentReport').value,
				},
				reassessment: {
					date: formGroup.get('caseManagementReassessmentByExpertOnDate').value,
					status: formGroup.get('caseManagementReassessmentReport').value,
				},
			},
			conclusionDetails: {
				endedOn: formGroup.get('caseManagementEndedOnDate').value,
				sustainabilityCheckOn: formGroup.get('caseManagementSustainabilityCheckedOnDate').value,
				accomplishment: formGroup.get('caseManagementAccomplishment').value,
				conclusion: formGroup.get('caseManagementSuccessfulnessType').value,
			},
		};
	};
}
