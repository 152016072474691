import { createReducer, on } from '@ngrx/store';
import { ERROR_INITIAL_STATE, ErrorState } from './error.state';
import { ErrorAction } from './action';

export const errorReducer = createReducer<ErrorState>(
	ERROR_INITIAL_STATE,
	on(ErrorAction.setErrors, (state, action): ErrorState => {
		return {
			message: '',
			errors: action.errors,
		};
	}),
	on(ErrorAction.clearErrors, (): ErrorState => ERROR_INITIAL_STATE)
);
