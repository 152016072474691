import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from 'src/app/shared/form-validation';
import { OK, REQUIRED } from 'src/app/shared/utils/form-validation.utils';

const isOtherInsuranceRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const otherInsuranceControll = control.get('otherInsurance');
		const otherInsurancePredefinedControll = control.get('otherInsurancePredefined');
		const noOtherInsuranceControll = control.get('noOtherInsurance');

		if (!noOtherInsuranceControll.value || otherInsuranceControll.value || otherInsurancePredefinedControll.value) {
			FormValidation.removeError(otherInsuranceControll, REQUIRED);
			FormValidation.removeError(otherInsurancePredefinedControll, REQUIRED);
		} else {
			FormValidation.addError(otherInsuranceControll, REQUIRED);
			FormValidation.addError(otherInsurancePredefinedControll, REQUIRED);
		}

		return OK;
	};
};
export { isOtherInsuranceRequired };
