<h2 mat-dialog-title>{{ isEdit ? ('editIncapacityTitle' | translate) : ('newIncapacityTitle' | translate) }}</h2>
<mat-dialog-content class="mat-typography">
	<ea-incapacity-form
		[formGroup]="data.formGroup"
		[showCommentField]="showCommentField"
		[isKleEnabled]="isKleEnabled"
		[isAccident]="isAccident"
		[isLast]="isLast"
		[existsTakeWorkUpFullyAlready]="existsTakeWorkUpFullyAlready"
	></ea-incapacity-form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px">
	<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
	<nc-button id="add" label="{{ isEdit ? 'change' : 'add' }}" (click)="submit()"></nc-button>
</mat-dialog-actions>
