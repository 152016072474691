<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<ea-container>
					<div fxLayout="column" fxLayoutGap="10px">
						<nc-input id="name" [formGroup]="formGroup" formControlName="name" label="name" [isReadonly]="true"></nc-input>
						<nc-input id="uid" [formGroup]="formGroup" formControlName="uid" label="uid" [isReadonly]="true"></nc-input>
						<nc-input
							id="easy-temp"
							[formGroup]="formGroup"
							formControlName="easyTempId"
							label="easyTempMandantId"
							[isReadonly]="true"
						></nc-input>
						<nc-input id="neo-id" [formGroup]="formGroup" formControlName="neoId" label="neoId" isReadonly="true"></nc-input>
						<nc-select
							id="neo-environment"
							[searchTextId]="'neo-environment-search'"
							[isReadonly]="isHrUser"
							[searchButtonId]="'neo-environment-search-clear'"
							[clearIconId]="'neo-environment-clear'"
							[formGroup]="formGroup"
							formControlName="neoEnvironment"
							label="neoEnvironment"
							*ngIf="formGroup.controls.neoId.value"
							[data$]="neoEnvironment$"
						></nc-select>
						<nc-input
							id="neo-group-id"
							[formGroup]="formGroup"
							formControlName="neoGroupId"
							label="neoGroupId"
							[isReadonly]="true"
						></nc-input>
						<nc-toggle-buttons [formGroup]="formGroup" formControlName="isMaster" label="isMaster" [options]="yesNo" [isReadonly]="true">
						</nc-toggle-buttons>
						<nc-toggle-buttons
							[formGroup]="formGroup"
							formControlName="contractType"
							label="employerActivity"
							[isReadonly]="true"
							[options]="contractType"
						></nc-toggle-buttons>
						<nc-select
							id="company-group"
							[searchTextId]="'company-group-search'"
							[isReadonly]="isHrUser"
							[searchButtonId]="'company-group-search-clear'"
							[clearIconId]="'company-group-clear'"
							[formGroup]="formGroup"
							formControlName="companyGroupId"
							label="companyGroup"
							[data$]="companyGroups$"
						></nc-select>
						<nc-select
							[formGroup]="formGroup"
							formControlName="frontendStyle"
							label="defaultFrontend"
							[isReadonly]="isHrUser"
							[data$]="frontendStyleOptions$"
							[translate]="true"
						></nc-select>
					</div>
				</ea-container>
				<ea-container>
					<nc-select
						id="responsible-user-id"
						[searchTextId]="'responsible-user-id-search'"
						[isReadonly]="isHrUser"
						[searchButtonId]="'responsible-user-id-search-clear'"
						[clearIconId]="'responsible-user-id-clear'"
						[formGroup]="formGroup"
						formControlName="defaultAssigneeId"
						label="userResponsible"
						[removeSelected]="true"
						[data$]="responsibleUsers$"
					></nc-select>
				</ea-container>
				<ea-container>
					<nc-input
						id="owner-first-name"
						[formGroup]="formGroup"
						formControlName="ownerFirstName"
						label="ownerFirstName"
						[isReadonly]="true"
					></nc-input>
					<nc-input
						id="owner-last-name"
						[formGroup]="formGroup"
						formControlName="ownerLastName"
						label="ownerLastName"
						[isReadonly]="true"
					></nc-input>
				</ea-container>
				<ea-container>
					<nc-input id="city" [formGroup]="formGroup" formControlName="city" label="city" [isReadonly]="true"></nc-input>
					<nc-input id="street" [formGroup]="formGroup" formControlName="street" label="street" [isReadonly]="true"></nc-input>
					<nc-input id="postbox" [formGroup]="formGroup" formControlName="postbox" label="postbox" [isReadonly]="true"></nc-input>
					<nc-input id="zip" [formGroup]="formGroup" formControlName="zip" label="zip" [isReadonly]="true"></nc-input>
					<nc-input id="county" [formGroup]="formGroup" formControlName="country" label="country" [isReadonly]="true"></nc-input>
					<nc-input id="canton" [formGroup]="formGroup" formControlName="canton" label="canton" [isReadonly]="true"></nc-input>
					<nc-textarea
						id="companyWorkplaces"
						[formGroup]="formGroup"
						formControlName="companyWorkplaces"
						label="branch"
						[isReadonly]="true"
					>
					</nc-textarea>
				</ea-container>
				<ea-container>
					<nc-toggle-buttons
						[formGroup]="formGroup"
						formControlName="isSwicaInsured"
						label="isSwicaInsured"
						[isReadonly]="isReadOnly"
						[options]="yesNo"
					></nc-toggle-buttons>
					<nc-toggle-buttons
						[formGroup]="formGroup"
						formControlName="isPkProInsured"
						label="isPkProInsured"
						[isReadonly]="isReadOnly"
						[options]="yesNo"
					></nc-toggle-buttons>
					<nc-toggle-buttons
						[formGroup]="formGroup"
						formControlName="isKleEnabled"
						label="isKleEnabled"
						[isReadonly]="!isAdmin"
						[options]="yesNo"
					></nc-toggle-buttons>
				</ea-container>
				<ea-container [title]="'employees'">
					<nc-datatable
						id="employees"
						class="employees-table"
						[rowClick]="onRowClick"
						[columns]="employeeColumns"
						[data$]="employees$"
						[filterConfig]="filterConfig"
					>
					</nc-datatable>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-company-details-footer
		[formGroup]="formGroup"
		[onSave]="onSubmit"
		[isHrUser]="isHrUser"
		[showRefreshButton]="formGroup.controls.neoId.value > 0 || formGroup.controls.easyTempId.value > 0"
		[showRegisterOrganizationButton]="formGroup.controls.easyTempId.value > 0 && formGroup.controls.isKleEnabled.value === yesNoEnum.yes"
		[onRefresh]="onRefresh"
	></ea-company-details-footer>
</form>
