import { Component, Input, OnInit } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { DialogService } from 'nc-utils';
import { BaseComponent } from 'nc-shared';
import { RepaymentDialogComponent } from './repayment-dialog/repayment-dialog.component';
import { KleSelect } from '../../state/action';

@Component({
	selector: 'ea-repayment',
	templateUrl: './repayment.component.html',
})
export class RepaymentComponent extends BaseComponent implements OnInit {
	@Input() absenceCode: string;

	filterConfig: FilterConfig;
	columns: Array<DatatableColumn>;
	data$: Observable<TableData>;

	constructor(private store: Store, private dialogService: DialogService) {
		super();
	}

	ngOnInit(): void {
		this.columns = this.createColumns();
		this.filterConfig = {
			showGlobalFilter: true,
			filterValues: {},
		};

		this.data$ = this.store.select(KleSelect.getRepayments);
	}

	createColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.createHidden('storyId'),
			ColumnBuilder.create('id', 'repaymentId'),
			ColumnBuilder.create('settlementId', 'settlementId'),
			ColumnBuilder.createDate('expiryDate', 'expiryDate'),
			ColumnBuilder.createDate('createdOn', 'createdOn'),
		];
	}

	openDialog = (rowData: TableData): void => {
		const data = {
			rowData,
			absenceCode: this.absenceCode,
		};

		this.dialogService.open(RepaymentDialogComponent, { data });
	};
}
