import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CompanyGroupModelFactory {
	create(companyGroup: any): any {
		return {
			id: companyGroup.id ?? 0,
			name: companyGroup.name,
		};
	}
}
