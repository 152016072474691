import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AbsenceState } from '../absence.state';

export const ABSENCE_FEATURE_STATE_NAME = 'absence';
export const ABSENCE_FEATURE_STATE = createFeatureSelector<AbsenceState>(ABSENCE_FEATURE_STATE_NAME);

export const getEmployee = createSelector(ABSENCE_FEATURE_STATE, (state) => state.employee);
export const getEmployees = createSelector(ABSENCE_FEATURE_STATE, (state) => state.employees);
export const getCompany = createSelector(ABSENCE_FEATURE_STATE, (state) => state.company);
export const getAbsence = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence);
export const getId = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.id);
export const getCode = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absenceCode);
export const getResponsibleUsers = createSelector(ABSENCE_FEATURE_STATE, (state) => state.responsibleUsers);
export const getMergedAbsence = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.mergedCases);
export const getDocumentTypes = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.documentTypes);
export const getFetchDocumentTypes = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.fetchDocumentTypes);
export const getGenerateDocumentTypes = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.generateDocumentTypes);
export const getLinkedAbsence = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.linkedAbsences);
export const getResponsibleLDUsers = createSelector(ABSENCE_FEATURE_STATE, (state) => state.responsibleLDUsers);
export const getShareInformation = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absence.shareInformation);
export const getHistory = createSelector(ABSENCE_FEATURE_STATE, (state) => state.history);
export const getReassignAbsenceEmployees = createSelector(ABSENCE_FEATURE_STATE, (state) => state.reassignAbsenceEmployees);
export const getAbsenceContextModel = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absenceContextModel);
export const getAbsenceAnnex = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absenceAnnex);
export const getDocumentsDetails = createSelector(ABSENCE_FEATURE_STATE, (state) => state.documentsDetails);
export const getIsAbsenceSavedSuccessfully = createSelector(ABSENCE_FEATURE_STATE, (state) => state.absenceSavedSuccessfully);
export const getIncidentContext = createSelector(ABSENCE_FEATURE_STATE, (state) => state.incidentContext);
