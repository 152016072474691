<ea-unauthenticated-layout [formGroup]="formGroup">
	<ea-unauthenticated-form-container>
		<div class="text">{{ 'enterUsername' | translate }}</div>
		<nc-input
			[id]="'username'"
			ncAutofocus
			[shouldAutofocus]="true"
			[formGroup]="formGroup"
			formControlName="username"
			label="username"
		></nc-input>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="column" fxLayoutAlign="space-around">
				<a routerLink="/" class="link">{{ 'backToLogin' | translate }}</a>
			</div>
			<nc-submit-button
				[id]="'reset-password'"
				[formGroup]="formGroup"
				buttonLabel="resetPassword"
				(buttonClick)="onSubmit()"
				dialogLabel="processing"
			></nc-submit-button>
		</div>
	</ea-unauthenticated-form-container>
</ea-unauthenticated-layout>
