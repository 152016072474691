import { createReducer, on } from '@ngrx/store';
import { IntegrationApiAction, IntegrationPageAction } from './action';
import { INTEGRATION_STATE, IntegrationState } from './integration.state';
import { GenderEnumeration } from '../../shared/enumeration/gender-enumeration';
import { MaritalStatusEnumeration } from '../../shared/enumeration/marital-status.enumeration';
import { LanguageEnumeration } from '../../shared/enumeration/language.enumeration';

export const integrationReducer = createReducer<IntegrationState>(
	INTEGRATION_STATE,
	on(IntegrationApiAction.filterCompaniesSuccess, (state, action): IntegrationState => {
		return {
			...state,
			companies: action.companies,
		};
	}),
	on(IntegrationApiAction.filterMedicalInstitutionsSuccess, (state, action): IntegrationState => {
		return {
			...state,
			medicalInstitutions: action.medicalInstitutions,
		};
	}),
	on(IntegrationApiAction.getCompanySuccess, (state, action): IntegrationState => {
		return {
			...state,
			company: action.response.company,
			companyGroupOptions: action.response.companyGroups,
			responsibleUsers: action.response.users,
		};
	}),
	on(IntegrationApiAction.getEmployeesSuccess, (state, action): IntegrationState => {
		return {
			...state,
			employees: { records: action.employees },
		};
	}),
	on(IntegrationApiAction.getEmployeeSuccess, (state, action): IntegrationState => {
		return {
			...state,
			employee: {
				...action.employee,
				gender: GenderEnumeration[action.employee.gender],
				civilStatus: MaritalStatusEnumeration[action.employee.civilStatus],
				language: LanguageEnumeration[action.employee.language],
			},
		};
	}),
	on(IntegrationApiAction.getCompanyGroupsSuccess, (state, action): IntegrationState => {
		return {
			...state,
			companyGroups: { records: action.companyGroups },
		};
	}),
	on(IntegrationApiAction.getCompanyGroupSuccess, (state, action): IntegrationState => {
		return {
			...state,
			companyGroup: {
				...action.companyGroup,
				assignedCompanies: {
					records: action.companyGroup.assignedCompanies,
				},
			},
		};
	}),
	on(IntegrationPageAction.clearCompanyGroup, (state, action): IntegrationState => {
		return {
			...state,
			companyGroup: INTEGRATION_STATE.companyGroup,
		};
	}),
	on(IntegrationPageAction.clearEmployeeData, (state, action): IntegrationState => {
		return {
			...state,
			employee: INTEGRATION_STATE.employee,
		};
	}),
	on(IntegrationApiAction.getAssignedSuccess, (state, action): IntegrationState => {
		return {
			...state,
			companiesAssigned: { records: action.records },
		};
	}),
	on(IntegrationApiAction.getAvailableSuccess, (state, action): IntegrationState => {
		return {
			...state,
			companiesAvailable: { records: action.records },
		};
	}),
	on(IntegrationPageAction.setFrontendStyle, (state, action): IntegrationState => {
		return {
			...state,
			frontendStyle: action.frontendStyle,
		};
	}),
	on(IntegrationPageAction.clearAssignedAndAvailableData, (state, action): IntegrationState => {
		return {
			...state,
			companiesAssigned: { records: [] },
			companiesAvailable: { records: [] },
		};
	})
);
