import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';
import { Moment } from 'moment';

const OK = null;
const COMMENT_DOCUMENT_REQUIRED = { absenceAnnexDocumentsCommentRequired: true };
const VALID_FROM_GREATER_THAN_VALID_TO = { invalidValidFromValidToRange: true };

/**
 * Checks if valid from is greater than valid to
 */
const validFromGreaterThanValidTo = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const validFromControl = control.get('validFrom');
		const validToControl = control.get('validTo');
		const validFrom = validFromControl.value as Moment;
		const validTo = validToControl.value as Moment;

		if (validFromControl.value && validToControl.value && validFrom > validTo) {
			FormValidation.addError(validFromControl, VALID_FROM_GREATER_THAN_VALID_TO);
		} else {
			FormValidation.removeError(validFromControl, VALID_FROM_GREATER_THAN_VALID_TO);
		}

		return OK;
	};
};

export { validFromGreaterThanValidTo };
