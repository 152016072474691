import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccessAssignmentState } from '../access-assignment.state';

export const ACCESS_ASSIGNMENT_FEATURE_STATE_NAME = 'access_assignment';
const ACCESS_ASSIGNMENT_FEATURE_STATE = createFeatureSelector<AccessAssignmentState>(ACCESS_ASSIGNMENT_FEATURE_STATE_NAME);

export const getSelectOptions = createSelector(ACCESS_ASSIGNMENT_FEATURE_STATE, (state) => state.selectOptions);
export const getEntityId = createSelector(ACCESS_ASSIGNMENT_FEATURE_STATE, (state) => state.entityId);
export const getEntityType = createSelector(ACCESS_ASSIGNMENT_FEATURE_STATE, (state) => state.entityType);
export const getAuthorityType = createSelector(ACCESS_ASSIGNMENT_FEATURE_STATE, (state) => state.authorityType);
export const getAccessType = createSelector(ACCESS_ASSIGNMENT_FEATURE_STATE, (state) => state.accessType);
export const getAccessAssignmentAssigned = createSelector(ACCESS_ASSIGNMENT_FEATURE_STATE, (state) => state.accessAssignmentAssigned);
export const getAccessAssignmentAvailable = createSelector(ACCESS_ASSIGNMENT_FEATURE_STATE, (state) => state.accessAssignmentAvailable);
