import { createAction, props } from '@ngrx/store';
import { Company } from '../../../shared/model/company';
import { Column, Order, Search } from 'nc-datatable';
import { CompanyGroup } from '../../model/company-group';
import { ChangeFrontendStyleModel } from '../../model/change-frontend-style.model';
import { FrontendStyleEnumeration } from '../../model/enumeration/frontend-style.enumeration';

export const saveCompany = createAction('[Integration] Save company', props<{ company: Company }>());
export const updateCompany = createAction('[Integration] Update company', props<{ company: Company }>());
export const filterCompanies = createAction(
	'[Integration] Filter companies',
	props<{ start: number; length: number; order: Order[]; columns: Column[]; globalSearch: Search }>()
);
export const filterMedicalInstitutions = createAction(
	'[Integration] Filter medical institutions',
	props<{ start: number; length: number; order: Order[]; columns: Column[]; globalSearch: Search }>()
);
export const getCompany = createAction('[Integration] Get company', props<{ companyId: number }>());
export const getEmployees = createAction('[Integration] Get employees for company', props<{ companyId: number }>());
export const getEmployee = createAction('[Integration] Get employee', props<{ employeeId: number }>());
export const refreshCompany = createAction('[Integration] Refresh company', props<{ companyIds: any; byModificationDate: boolean }>());
export const refreshMedicalInstitutions = createAction(
	'[Integration] Refresh medical institutions',
	props<{ term: string; searchType: string; partnerType: string; providerType: string }>()
);
export const getCompanyGroups = createAction('[Integration] Get company groups');
export const getCompanyGroup = createAction('[Integration] Get company group', props<{ id: number }>());
export const saveCompanyGroup = createAction('[Integration] Save company group', props<{ data: CompanyGroup }>());
export const deleteCompanyGroup = createAction('[Integration] Delete company group', props<{ id: number }>());
export const openDeleteConfirmationDialog = createAction('[Integration] Open delete confirmation dialog', props<{ id: number }>());
export const clearCompanyGroup = createAction('[Integration] Clear company group');
export const clearEmployeeData = createAction('[Integration] Clear data from selected user');
export const getAvailable = createAction('[Integration] Operation available', props<{ requestModel: ChangeFrontendStyleModel }>());
export const getAssigned = createAction('[Integration] Operation assigned', props<{ requestModel: ChangeFrontendStyleModel }>());
export const assignOrRemove = createAction('[Integration] Operation assign or remove', props<{ requestModel: ChangeFrontendStyleModel }>());
export const clearAssignedAndAvailableData = createAction('[Integration] Clear data');
export const setFrontendStyle = createAction('[Integration] Set frontend style', props<{ frontendStyle: FrontendStyleEnumeration }>());
