<div fxFill fxLayout="column">
	<nc-main-content>
		<form [formGroup]="formGroup">
			<nc-select
				[id]="'entity'"
				[searchTextId]="'entity-search'"
				[searchButtonId]="'entity-search-clear'"
				[clearIconId]="'entity-clear'"
				[formGroup]="formGroup"
				[data$]="selectOptions$"
				placeholder="defaultSelectPleaceholder"
				[label]="label"
				formControlName="entityId"
			>
			</nc-select>
		</form>
		<nc-form-content [center]="false">
			<div>
				<nc-linked-tables [configuration]="linkedTableConfig" [lowerTableId]="'assigned'" [upperTableId]="'available'"> </nc-linked-tables>
			</div>
		</nc-form-content>
	</nc-main-content>
</div>
