<h2 *ngIf="(receivedStories$ | async)?.records?.length || (sentStories$ | async)?.records?.length">
	<nc-label id="overview" value="overview"></nc-label>
</h2>
<div class="divider-accordions" *ngIf="(receivedStories$ | async)?.records?.length || (sentStories$ | async)?.records?.length"></div>
<div fxLayout="column" fxLayoutGap="15px" [ngClass]="'containter-margin-table'">
	<div fxLayout="row" fxLayoutGap="20px">
		<ea-container *ngIf="(receivedStories$ | async)?.records?.length" title="receivedStories" fxFlex="50">
			<nc-datatable
				id="receivedStoriesId"
				[data$]="receivedStories$"
				[columns]="tableColumns"
				[filterConfig]="filterConfig"
				[ngClass]="'margin-top-15'"
			></nc-datatable>
		</ea-container>
		<ea-container *ngIf="(sentStories$ | async)?.records?.length" title="sentStories" fxFlex="50">
			<nc-datatable
				id="sentStoriesId"
				[data$]="sentStories$"
				[columns]="tableColumns"
				[filterConfig]="filterConfig"
				[ngClass]="'margin-top-15'"
			></nc-datatable>
		</ea-container>
	</div>
</div>
<div class="divider-accordions" *ngIf="(receivedStories$ | async)?.records?.length || (sentStories$ | async)?.records?.length"></div>
