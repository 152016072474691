import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { IntegrationPageAction } from '../../state/action';
import { SearchTypeEnumeration } from '../../model/enumeration/search-type.enumeration';
import { PartnerTypeEnumeration } from '../../model/enumeration/partner-type.enumeration';
import { ProviderTypeEnumeration } from '../../model/enumeration/provider-type.enumeration';

@Component({
	selector: 'ea-medical-institutions-footer',
	templateUrl: './medical-institutions-footer.component.html',
	styleUrls: ['./medical-institutions-footer.component.scss'],
})
export class MedicalInstitutionsFooterComponent {
	constructor(private location: Location, private store: Store) {}

	goBack = (): void => {
		this.location.back();
	};

	refresh = (): void => {
		this.store.dispatch(
			IntegrationPageAction.refreshMedicalInstitutions({
				term: null,
				searchType: SearchTypeEnumeration.ALL,
				partnerType: PartnerTypeEnumeration.ALL,
				providerType: ProviderTypeEnumeration.GET_BY_LAST_CHANGE_DATE,
			})
		);
	};

	refreshAll = (): void => {
		this.store.dispatch(
			IntegrationPageAction.refreshMedicalInstitutions({
				term: null,
				searchType: SearchTypeEnumeration.ALL,
				partnerType: PartnerTypeEnumeration.ALL,
				providerType: ProviderTypeEnumeration.GET_ALL,
			})
		);
	};
}
