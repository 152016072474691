<div [formGroup]="simpleMessageGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div fxLayout="column">
		<nc-input id="title" label="title" [formGroup]="simpleMessageGroup" formControlName="title"></nc-input>
	</div>
	<div fxLayout="column">
		<nc-textarea
			class="text-wrapped"
			id="message"
			label="synchronization.table.Message"
			[formGroup]="simpleMessageGroup"
			formControlName="message"
			[required]="true"
		>
		</nc-textarea>
	</div>
	<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-button *ngIf="data.storyId && isSwissdec && isReadOnly" id="replay-dialog-message" label="replay" (click)="replay()"></nc-button>
		<nc-submit-button
			*ngIf="!data.storyId || !isReadOnly"
			id="send-dialog-message"
			buttonLabel="send"
			(buttonClick)="submit()"
			[formGroup]="simpleMessageGroup"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</div>
