import * as ApplicationState from '../../state/app.state';
import { Option } from 'nc-shared';
import { TableData } from 'nc-datatable';

export interface DashboardState {
	selectedUser: string;

	numberOfDays: Option[];
	responsibleUsers: Option[];
	hrCompanies: Option[];

	tasks: TableData;
	milestones: TableData;
	activeCases: TableData;
	archivedCases: TableData;
	hrActiveAbsence: TableData;
	casesToBeApproved: TableData;
	awaitStories: TableData;
	hrStatistic: any;
	pieChartData: Array<any>;
	barChartData: Array<any>;
}

export interface State extends ApplicationState.State {
	dashboard: DashboardState;
}

export const DASHBOARD_INITIAL_STATE: DashboardState = {
	tasks: { records: [] },
	milestones: { records: [] },
	activeCases: { records: [] },
	hrCompanies: [],
	numberOfDays: [],
	selectedUser: '',
	archivedCases: { records: [] },
	responsibleUsers: [],
	hrActiveAbsence: { records: [] },
	casesToBeApproved: { records: [] },
	awaitStories: { records: [] },
	hrStatistic: null,
	pieChartData: [],
	barChartData: [],
};
