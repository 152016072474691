import { createAction, props } from '@ngrx/store';
import { Option } from 'nc-shared';

export const getSelectOptionsSuccess = createAction('[Access assignment API] Initialize user select success', props<{ selectOptions: Option[] }>());
export const getAvailableSuccess = createAction(
	'[Access assignment API] Operation available success',
	props<{ records: { [key: string]: string }[] }>()
);
export const getAvailableForDocsSuccess = createAction(
	'[Access assignment API] Operation available for docs success',
	props<{ records: { [key: string]: string }[] }>()
);
export const getAssignedSuccess = createAction(
	'[Access assignment API] Operation assigned success',
	props<{ records: { [key: string]: string }[] }>()
);
export const getAssignedForDocsSuccess = createAction(
	'[Access assignment API] Operation assigned for docs success',
	props<{ records: { [key: string]: string }[] }>()
);
export const assignOrRemoveSuccess = createAction('[Access assignment API] Operation assign or remove success');
export const assignOrRemoveForDocsSuccess = createAction(
	'[Access assignment API] Operation assign or remove for docs success',
	props<{ response: any }>()
);
