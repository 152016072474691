import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { StringsUtils } from 'nc-utils';
import { FormValidation } from '../../shared/form-validation';
import { OK, REQUIRED } from '../../shared/utils/form-validation.utils';
import { ClassOfInsuranceTypeEnumItems } from '../model/enums/class-of-insurance-type.enum';
import { Moment, now } from 'moment';
import { AccidentDescriptionOptionsEnumItems } from '../model/enums/accident-description-options.enum';
import { BodyOrientedPartTypeEnum } from '../model/enums/body-oriented-part-type.enum';

const DATE_OF_DEATH_ERROR = { dateOfDeathError: true };
const LAST_WORKING_DAY_ERROR = { lastWorkingDayError: true };
const INJURIES_MANDATORY = { injuriesMandatory: true };
const RELAPSE_DATE_ERROR = { relapseDateError: true };
const ACCIDENT_DATE_IN_FUTURE = { accidentDateInFuture: true };
const RELAPSE_DATE_IN_FUTURE = { relapseDateInFuture: true };

/**
 * Checks if added comment or at least one document.
 */
export const partOfBodyOrUnassignedPartOfBodyMandatory = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const bodyPart = control.get('bodyPart');

		if (StringsUtils.isEmpty(bodyPart.value)) {
			FormValidation.addError(bodyPart, REQUIRED);
		} else {
			FormValidation.removeError(bodyPart, REQUIRED);
		}

		return OK;
	};
};

export const positionValidator = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const bodyPartControl = control.get('bodyPart');
		const positionControl = control.get('position');

		if (
			StringsUtils.isNotEmpty(bodyPartControl.value) &&
			StringsUtils.isEmpty(positionControl.value) &&
			bodyPartControl.value in BodyOrientedPartTypeEnum
		) {
			FormValidation.addError(positionControl, REQUIRED);
		} else {
			FormValidation.removeError(positionControl, REQUIRED);
		}

		return OK;
	};
};

export const otherTypeOfInjuryValidator = (control: FormGroup) => {
	const typeOfInjuryControl = control.get('typeOfInjury');
	const otherTypeOfInjuryControl = control.get('otherTypeOfInjury');

	if (StringsUtils.isEmpty(typeOfInjuryControl.value) && StringsUtils.isEmpty(otherTypeOfInjuryControl.value)) {
		return { typeAndOtherTypeOfInjuryError: true };
	}

	return null;
};

export const exactApproxDateTimeValidator = (control: FormGroup) => {
	if (!control.value.exactDateTime && !control.value.approximate) {
		return { exactApproxDateTimeError: true };
	}
	return null;
};

export const leisureAccidentValidator = (control: FormGroup) => {
	if (control.value.classOfInsuranceType === ClassOfInsuranceTypeEnumItems.ACCIDENT_AT_LEISURE.code) {
		control.controls.activityAtTheTimeOfAccident.addValidators(Validators.required);
		control.controls.lastWorkingDay.addValidators(Validators.required);
	} else {
		control.controls.activityAtTheTimeOfAccident.removeValidators(Validators.required);
		control.controls.lastWorkingDay.removeValidators(Validators.required);
	}
};

export const dateOfDeathValidator = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const dateOfDeath = control.value.dateOfDeath as Moment;
		const lastWorkingDayDateOfDeath = control.value.lastWorkingDayDateOfDeath as Moment;
		const approximate = control.value.approximate as Moment;
		const exactDateTime = control.value.exactDateTime as Moment;

		const isCaseOfDeath = control.value.accidentDescriptionOptions === AccidentDescriptionOptionsEnumItems.CASE_OF_DEATH.code;
		const isCaseOfDeathBeforeAccidentDate = (approximate && dateOfDeath < approximate) || (exactDateTime && dateOfDeath < exactDateTime);
		const isAccidentAtLeisure = control.value.classOfInsuranceTypeDateOfDeath === ClassOfInsuranceTypeEnumItems.ACCIDENT_AT_LEISURE.code;
		const isLastWorkingDayAfterAccidentDate =
			(approximate && lastWorkingDayDateOfDeath > approximate) || (exactDateTime && lastWorkingDayDateOfDeath > exactDateTime);

		if (isCaseOfDeath && !dateOfDeath) {
			FormValidation.addError(control.get('dateOfDeath'), REQUIRED);
		} else {
			FormValidation.removeError(control.get('dateOfDeath'), REQUIRED);
		}

		if (isCaseOfDeath && dateOfDeath && isCaseOfDeathBeforeAccidentDate) {
			FormValidation.addError(control.get('dateOfDeath'), DATE_OF_DEATH_ERROR);
		} else {
			FormValidation.removeError(control.get('dateOfDeath'), DATE_OF_DEATH_ERROR);
		}

		if (isCaseOfDeath && isAccidentAtLeisure && !lastWorkingDayDateOfDeath) {
			FormValidation.addError(control.get('lastWorkingDayDateOfDeath'), REQUIRED);
		} else {
			FormValidation.removeError(control.get('lastWorkingDayDateOfDeath'), REQUIRED);
		}

		if (isAccidentAtLeisure && isCaseOfDeath && lastWorkingDayDateOfDeath && isLastWorkingDayAfterAccidentDate) {
			FormValidation.addError(control.get('lastWorkingDayDateOfDeath'), LAST_WORKING_DAY_ERROR);
		} else {
			FormValidation.removeError(control.get('lastWorkingDayDateOfDeath'), LAST_WORKING_DAY_ERROR);
		}

		return OK;
	};
};

export const accidentDateValidator = (): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		const approximateDate = control.value.approximate as Moment;
		const exactDate = control.value.exactDateTime as Moment;

		if (approximateDate && approximateDate.isAfter(now())) {
			FormValidation.addError(control.get('approximate'), ACCIDENT_DATE_IN_FUTURE);
		} else {
			FormValidation.removeError(control.get('approximate'), ACCIDENT_DATE_IN_FUTURE);
		}

		if (exactDate && exactDate.isAfter(now())) {
			FormValidation.addError(control.get('exactDateTime'), ACCIDENT_DATE_IN_FUTURE);
		} else {
			FormValidation.removeError(control.get('exactDateTime'), ACCIDENT_DATE_IN_FUTURE);
		}

		return OK;
	};
};

export const lastWorkingDayValidator = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const lastWorkingDay = control.value.lastWorkingDay as Moment;
		const approximate = control.value.approximate as Moment;
		const exactDateTime = control.value.exactDateTime as Moment;

		const isAccident = control.value.accidentDescriptionOptions === AccidentDescriptionOptionsEnumItems.ACCIDENT.code;
		const isAccidentAtLeisure = control.value.classOfInsuranceType === ClassOfInsuranceTypeEnumItems.ACCIDENT_AT_LEISURE.code;
		const isLastWorkingDayAfterAccidentDate = (approximate && lastWorkingDay > approximate) || (exactDateTime && lastWorkingDay > exactDateTime);

		if (isAccident && isAccidentAtLeisure && lastWorkingDay && isLastWorkingDayAfterAccidentDate) {
			FormValidation.addError(control.get('lastWorkingDay'), LAST_WORKING_DAY_ERROR);
		} else {
			FormValidation.removeError(control.get('lastWorkingDay'), LAST_WORKING_DAY_ERROR);
		}

		return OK;
	};
};

export const relapseDateValidator = (): ValidatorFn => {
	return (control: AbstractControl): ValidatorFn | null => {
		const approximate = control.value.approximate as Moment;
		const exactDateTime = control.value.exactDateTime as Moment;
		const relapseDate = control.value.relapseDate as Moment;
		const relapseDateControl = control.get('relapseDate');

		const isRelapse = control.value.accidentDescriptionOptions === AccidentDescriptionOptionsEnumItems.RELAPSE.code;
		const isRelapseDateBeforeAccidentDate = (approximate && relapseDate < approximate) || (exactDateTime && relapseDate < exactDateTime);

		if (isRelapse && relapseDate && isRelapseDateBeforeAccidentDate) {
			FormValidation.addError(control.get('relapseDate'), RELAPSE_DATE_ERROR);
		} else {
			FormValidation.removeError(control.get('relapseDate'), RELAPSE_DATE_ERROR);
		}

		if (isRelapse && !relapseDate) {
			FormValidation.addError(relapseDateControl, REQUIRED);
		} else {
			FormValidation.removeError(relapseDateControl, REQUIRED);
		}

		if (isRelapse && relapseDate && relapseDate.isAfter(now())) {
			FormValidation.addError(relapseDateControl, RELAPSE_DATE_IN_FUTURE);
		} else {
			FormValidation.removeError(relapseDateControl, RELAPSE_DATE_IN_FUTURE);
		}

		return OK;
	};
};

export const injuriesValidator = (): ValidatorFn => {
	return (formGroup: FormGroup): ValidatorFn | null => {
		const injuries = formGroup.controls.injuries as FormArray;

		if (injuries.length === 0) {
			FormValidation.addError(injuries, INJURIES_MANDATORY);
		} else {
			FormValidation.removeError(injuries, INJURIES_MANDATORY);
		}

		return OK;
	};
};
