import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelect } from '../state/action';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogConfigurationUtils, DialogService } from 'nc-utils';
import { UnauthorizedAccessDialogComponent } from '../../shared/component/dialog/unauthorized-access-dialog/unauthorized-access-dialog.component';
import { State } from '../../state/app.state';
import { map } from 'rxjs/operators';
import { UserTypeEnumeration } from '../../shared/enumeration/user-type.enumeration';

@Injectable({
	providedIn: 'root',
})
export class AnexGuard implements CanActivate {
	isHr: boolean;

	constructor(private store: Store<State>, private router: Router, private dialogService: DialogService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(UserSelect.getUserType).pipe(
			map((value) => {
				if (value === UserTypeEnumeration.HR.toUpperCase()) {
					this.isHr = true;
				} else {
					this.isHr = false;
					const details = DialogConfigurationUtils.error('unauthorizedUser');
					this.dialogService
						.open(UnauthorizedAccessDialogComponent, details)
						.afterClosed()
						.subscribe(() => this.router.navigateByUrl('/'));
				}
				return this.isHr;
			})
		);
	}
}
