import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Employee } from '../../shared/model/employee';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { MaritalStatusEnumeration } from '../../shared/enumeration/marital-status.enumeration';
import { LanguageEnumeration } from '../../shared/enumeration/language.enumeration';
import { GenderEnumeration } from '../../shared/enumeration/gender-enumeration';
import { Option } from 'nc-shared';
import { SelectModel } from '../../shared/model/select.model';
import { Absence } from '../model/absence';
import { ActionResponse, dateToString, parseDate, SaveResponse } from 'nc-utils';
import { CompanyResponse } from '../model/response/company.response';
import { RequestModelFactory } from './request-model.factory';
import { AbsenceResponse } from '../model/response/absence.response';
import { SyncResponse } from '../../shared/model/sync-response';
import { GenerateDocumentRequest } from '../model/request/generate-document-request';
import { Moment } from 'moment';
import { MergeAbsenceRequest } from '../model/request/merge-absence-request';
import { UnmergeAbsenceRequest } from '../model/request/unmerge-absence-request';
import { AbsenceContextModel } from '../../shared/model/absence-context.model';
import { AbsenceAnnexResponse } from '../model/response/absence-annex.response';
import { AbsenceAnnex } from '../model/absence-annex';
import { DeclareIncidentResponse } from '../../kle/model/response/declare-incident.response';
import { IncidentContext } from '../../kle/model/incident-context';
import { ResponseModel } from '../../shared/model/response.model';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { AccidentDescription } from '../../kle/model/accident-description';
import { AccidentDescriptionResponse } from '../../kle/model/response/accident-description.response';
import { Treatments } from '../../kle/model/treatments';
import { SearchService } from '../../shared/service/search.service';

@Injectable({
	providedIn: 'root',
})
export class AbsenceService {
	constructor(
		private httpClient: HttpClient,
		private requestModelFactory: RequestModelFactory,
		private environmentLoader: EnvironmentLoader,
		private searchService: SearchService
	) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;

	private readonly FETCH_DOCUMENTS = `${this.API_URL}/documents/fetch/`;
	private readonly GENERATE_DOCUMENT = `${this.API_URL}/documents/generate`;
	private readonly SUBMIT_URL = `${this.API_URL}/absences`;
	private readonly DELETE_URL = `${this.API_URL}/absences/delete-draft/`;
	private readonly GET_ABSENCE_URL = `${this.API_URL}/absences/`;
	private readonly GET_COMPANY_URL = `${this.API_URL}/companies/`;
	private readonly GET_EMPLOYEE_URL = `${this.API_URL}/employees/`;
	private readonly ARCHIVE_URL = `${this.API_URL}/absences/archive`;
	private readonly GET_RESPONSIBLE_USERS_URL = `${this.API_URL}/users/options/`;
	private readonly ACTIVATE_URL = `${this.API_URL}/absences/{absenceId}/activate`;
	private readonly CAN_ARCHIVE_URL = `${this.API_URL}/absences/{absenceId}/can-archive`;
	private readonly GET_MEDICAL_DIAGNOSIS_URL = `${this.API_URL}/absences/medical-diagnosis/`;
	private readonly SHARE_INFORMATION_URL = `${this.API_URL}/absences/share-information`;
	private readonly ABSENCE_ANNEX_URL = `${this.API_URL}/absences/annex`;
	private readonly KLE_URL = `${this.API_URL}/kle/declare-incident`;
	private readonly GET_INCIDENT_CONTEXT_URL = `${this.API_URL}/kle/declare-incident/{absenceCode}/context`;
	private readonly ACCIDENT_DESCRIPTION = `${this.API_URL}/kle/{absenceCode}/accident-description`;
	private readonly TREATMENTS = `${this.API_URL}/kle/{absenceCode}/treatments`;
	private readonly GET_PERSONS_URL = `${this.API_URL}/search/employee/`;

	/**
	 * Gets employee details.
	 * @param employeeId Unique employee identifier
	 */
	getEmployee(employeeId: string): Observable<Employee> {
		return this.httpClient.get<Employee>(this.GET_EMPLOYEE_URL + employeeId).pipe(
			map((employee) => {
				return {
					...employee,
					civilStatus: MaritalStatusEnumeration[employee.civilStatus],
					language: LanguageEnumeration[employee.language],
					gender: GenderEnumeration[employee.gender],
					country: 'country_' + employee.country,
				};
			})
		);
	}

	/**
	 * Gets company details.
	 * @param companyId Unique company identifier
	 */
	getCompany(companyId: string): Observable<CompanyResponse> {
		return this.httpClient.get<CompanyResponse>(this.GET_COMPANY_URL + companyId);
	}

	/**
	 * Gets responsible ld users for select.
	 */
	getResponsibleLDUsers(): Observable<Option[]> {
		return this.httpClient.get<SelectModel>(this.GET_RESPONSIBLE_USERS_URL + 'LD').pipe(map((response) => response.options));
	}

	/**
	 * Submits form data
	 * @param absence form data
	 * @param forceNew should the absence be saved by force
	 */
	submit(absence: Absence, forceNew?: boolean): Observable<SaveResponse> {
		const data = this.requestModelFactory.create(absence, forceNew);
		return this.httpClient.post<SaveResponse>(this.SUBMIT_URL, data);
	}

	/**
	 * Delete draft absence
	 * @param code form data
	 */
	deleteDraft(code: string): Observable<any> {
		return this.httpClient.delete<any>(this.DELETE_URL + code);
	}
	/**
	 * Submit mobiliar data sheet mutation
	 * @param code absence code
	 */
	mobiliarDataSheet(code: string): Observable<ActionResponse> {
		return this.httpClient.post<ActionResponse>(`${this.SUBMIT_URL}/${code}/mobiliar-mutation`, null);
	}

	/**
	 * Gets absence
	 * @param code absence code
	 */
	getAbsence(code: string): Observable<AbsenceResponse> {
		return this.httpClient.get<AbsenceResponse>(this.GET_ABSENCE_URL + code);
	}

	/**
	 * Gets medical diagnosis option
	 * @param code medical diagnosis code
	 */
	getMedicalDiagnosis(code: string): Observable<Option> {
		return this.httpClient.get<any>(this.GET_MEDICAL_DIAGNOSIS_URL + code).pipe(
			map((option) => {
				return {
					...option,
					value: option.code,
					text: `${option.code} - ${option.text}`,
				};
			})
		);
	}

	/**
	 * Checks if absence can be archived
	 * @param id unique absence identifier
	 */
	canArchive(id: number): Observable<ActionResponse> {
		const url = this.CAN_ARCHIVE_URL.replace('{absenceId}', id.toString());
		return this.httpClient.post<ActionResponse>(url, null);
	}

	/**
	 * Activates absence
	 * @param id unique absence identifier
	 */
	activate(id: number): Observable<ActionResponse> {
		const url = this.ACTIVATE_URL.replace('{absenceId}', id.toString());
		return this.httpClient.post<ActionResponse>(url, null);
	}

	/**
	 * Archives absence
	 * @param id unique absence identifier
	 * @param comment archive comment
	 * @param reason archive reason
	 */
	archive(id: number, comment: string, reason: string): Observable<ActionResponse> {
		return this.httpClient.post<ActionResponse>(this.ARCHIVE_URL, { id, comment, reason });
	}

	/**
	 * Fetchs document from neo
	 * @param code absence code
	 * @param documentType type of document
	 */
	fetchDocuments(code: string, documentType: string): Observable<SyncResponse> {
		const url = `${this.FETCH_DOCUMENTS}${code}/${documentType}`;
		return this.httpClient.get<SyncResponse>(url);
	}

	/**
	 * Generates document
	 * @param code absence code
	 * @param type document type
	 * @param language document language
	 * @param reportDate report date for some document types
	 */
	generateDocument(code: string, type: string, language: string, reportDate: Moment): Observable<SaveResponse> {
		const data = { code, type, language, reportDate: dateToString(reportDate) } as GenerateDocumentRequest;
		return this.httpClient.post<SaveResponse>(this.GENERATE_DOCUMENT, data);
	}

	/**
	 * Gets history for absence
	 * @param code absence code
	 */
	getHistory(code: string): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(`${this.SUBMIT_URL}/${code}/history`);
	}

	/**
	 * Resend confirmation email
	 *  @param code absence code
	 */
	resendConfirmationEmail(code: string): Observable<ActionResponse> {
		return this.httpClient.post<ActionResponse>(`${this.SUBMIT_URL}/${code}/resend-confirmation-email`, null);
	}

	/**
	 * Resend annex confirmation email
	 *  @param code absence code
	 */
	resendAnnexConfirmationEmail(code: string): Observable<ActionResponse> {
		const url = `${this.SUBMIT_URL}/annex/${code}/resend-confirmation-email`;
		return this.httpClient.post<ActionResponse>(url, null);
	}

	/**
	 * Gets employees for reassign absence
	 * @param code absence code
	 * @param showAll show All users or not
	 */
	getReassignAbsenceEmployees(code: string, showAll: boolean): Observable<{ [key: string]: string }[]> {
		const url = `${this.GET_EMPLOYEE_URL}case-reassign/${code}/${showAll}`;
		return this.httpClient.get<{ [key: string]: string }[]>(url);
	}

	/**
	 * Reassign absence submit
	 * @param code absence code
	 * @param employeeId reassign to checked employee Id
	 */
	reassignAbsenceSubmit(code: string, employeeId: number): Observable<ActionResponse> {
		return this.httpClient.post<ActionResponse>(`${this.SUBMIT_URL}/${code}/reassign/${employeeId}`, null);
	}

	/**
	 * Merge absence
	 * @param mergeAbsenceRequest merge absence model from form data
	 */
	mergeAbsence(mergeAbsenceRequest: MergeAbsenceRequest): Observable<ActionResponse> {
		return this.httpClient.post<ActionResponse>(`${this.SUBMIT_URL}/merge`, mergeAbsenceRequest);
	}

	/**
	 * Unmerge absence
	 * @param code absence code that will be unmerged
	 */
	unmergeAbsence(code: string): Observable<ActionResponse> {
		const unmergeAbsenceRequest = new UnmergeAbsenceRequest(code);
		return this.httpClient.post<ActionResponse>(`${this.SUBMIT_URL}/unmerge`, unmergeAbsenceRequest);
	}

	/**
	 * Get absence which is selected
	 * @param code Unique identifier for absence
	 */
	getCurrentAbsence(code: string): Observable<AbsenceContextModel> {
		return this.httpClient.get<AbsenceContextModel>(`${this.GET_ABSENCE_URL}${code}/context`);
	}

	/**
	 * Get Absence Annex
	 * @param identifier Unique identifier for Absence Annex
	 * @param isShareInformation is share information type
	 */
	getAbsenceAnnex(identifier: string, isShareInformation: boolean): Observable<AbsenceAnnexResponse> {
		const url = isShareInformation ? `${this.SHARE_INFORMATION_URL}/${identifier}` : `${this.ABSENCE_ANNEX_URL}/${identifier}`;
		return this.httpClient.get<AbsenceAnnexResponse>(url);
	}

	/**
	 * Save Absence annex
	 * @param data Form data
	 * @param isShareInformation True value if Share Information, false if Closed or Prolongation
	 */
	saveAbsenceAnnex(data: AbsenceAnnex, isShareInformation: boolean): Observable<SaveResponse> {
		if (isShareInformation) {
			const shareInformation = this.requestModelFactory.createShareInformationData(data);
			return this.httpClient.post<SaveResponse>(this.SHARE_INFORMATION_URL, shareInformation);
		} else {
			const annex = this.requestModelFactory.createAbsenceAnnexData(data);
			return this.httpClient.post<SaveResponse>(this.ABSENCE_ANNEX_URL, annex);
		}
	}

	declareIncident(
		absenceCode: string,
		sendWithoutContract: boolean,
		incident: any,
		relapse: any
	): Observable<ResponseModel<DeclareIncidentResponse>> {
		return this.httpClient.post<ResponseModel<DeclareIncidentResponse>>(this.KLE_URL, {
			absenceCode,
			sendWithoutContract,
			incident,
			relapse,
		});
	}

	/**
	 * Gets incident context
	 */
	getIncidentContext(absenceCode: string): Observable<IncidentContext> {
		const url = this.GET_INCIDENT_CONTEXT_URL.replace('{absenceCode}', absenceCode);
		return this.httpClient.get<ResponseModel<IncidentContext>>(url).pipe(map((x) => x.value));
	}

	/**
	 * Gets accident description
	 */
	getAccidentDescription(absenceCode: string): Observable<AccidentDescription> {
		const url = this.ACCIDENT_DESCRIPTION.replace('{absenceCode}', absenceCode);
		return this.httpClient.get<ResponseModel<AccidentDescriptionResponse>>(url).pipe(
			filter((response) => response.value != null),
			map((response): AccidentDescription => {
				return {
					...response.value,
					exactDateTime: parseDate(response.value.exactDateTime, 'YYYY-MM-DDTHH:mm:ss'),
					approximate: parseDate(response.value.approximate),
					dateOfDeath: parseDate(response.value.dateOfDeath),
					relapseDate: parseDate(response.value.relapseDate),
					lastWorkingDay: parseDate(response.value.lastWorkingDay),
					lastWorkingDayDateOfDeath: parseDate(response.value.lastWorkingDayDateOfDeath),
				};
			})
		);
	}

	/**
	 * Saves accident description
	 * @param data accident description form data
	 */
	saveAccidentDescription(data: AccidentDescription): Observable<SaveResponse> {
		const url = this.ACCIDENT_DESCRIPTION.replace('{absenceCode}', data.absenceCode);
		return this.httpClient.post<SaveResponse>(url, data);
	}

	/**
	 * Gets treatments
	 * @param absenceCode absence code
	 */
	getTreatments(absenceCode: string): Observable<Treatments> {
		const url = this.TREATMENTS.replace('{absenceCode}', absenceCode);
		return this.httpClient.get<ResponseModel<Treatments>>(url).pipe(map((response) => response.value));
	}

	/**
	 * Saves treatments
	 * @param data treatments form data
	 */
	saveTreatments(data: Treatments): Observable<SaveResponse> {
		const url = this.TREATMENTS.replace('{absenceCode}', data.absenceCode);
		return this.httpClient.post<SaveResponse>(url, data);
	}

	searchEmployees(companyId: string): Observable<Option[]> {
		return this.httpClient
			.post<any>(this.GET_PERSONS_URL + companyId, {})
			.pipe(map((result) => result.records.map(this.searchService.mapOption)));
	}

	filterItems(term: string, employees$: Observable<Option[]>): Observable<Option[]> {
		let employeesRes: Option[] = [];
		employees$.pipe(filter((employees) => employees != null)).subscribe((employees) => {
			employeesRes = employees.filter((item) => item.text.toLowerCase().includes(term.toLowerCase()));
		});

		return of(employeesRes);
	}
}
