<ea-container [title]="'incapacities'" cssClass="margin-top-15 {{ isInvalid() ? 'error-border' : '' }}">
	<ng-template #tableHeader>
		<div class="button-add-container" fxLayout="row wrap" *ngIf="!isReadonly">
			<nc-button [id]="'add'" icon="add" [onClick]="openDialog"></nc-button>
		</div>
	</ng-template>
	<nc-datatable
		[id]="'incapacity'"
		[rowClick]="isReadonly ? null : edit"
		[tableHeader]="tableHeader"
		[columns]="getColumns()"
		[data$]="data$"
		[filterConfig]="filterConfig"
	></nc-datatable>
	<div *ngIf="isInvalid()" class="error-container">
		<ng-container *ngIf="getErrorMessage() as errorLabel">
			{{ errorLabel.label | translate : errorLabel.parameters }}
		</ng-container>
	</div>
</ea-container>
