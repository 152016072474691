import { Component, OnInit } from '@angular/core';
import { TellcoConstants } from '../../../environment-constants/tellco-constants';
import { EcareConstants } from '../../../environment-constants/ecare-constants';
import { EnvironmentLoader } from '../../../../core/config/environment-loader';

@Component({
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
	logo: string;

	constructor(private environmentLoader: EnvironmentLoader) {}

	ngOnInit(): void {
		this.logo = this.environmentLoader.getEnvironment().theme === 'tellco-theme' ? TellcoConstants.PK_PRO_USER_URI : EcareConstants.LOGO_URI;
	}
}
