import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';
import { StringsUtils } from 'nc-utils';

const OK = null;
const MANDATORY_ABSENCE_TYPE = { mandatoryAbsenceType: true };
const DAY_MUST_BE_GREATER_THAN_ZERO = { dayMustBeGreaterThanZero: true };
const NOTE_AT_LEAST_ONE_LANGUAGE = { noteAtLeastOneLanguage: true };
const TITLE_AT_LEAST_ONE_LANGUAGE = { titleAtLeastOneLanguage: true };

const mandatoryAbsenceType = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const isForAbsenceTypeAccident = control.get('forAbsenceTypeAccident');
		const isForAbsenceTypePregnancy = control.get('forAbsenceTypePregnancy');
		const isForAbsenceTypeSickness = control.get('forAbsenceTypeSickness');
		if (isForAbsenceTypeAccident && isForAbsenceTypePregnancy && isForAbsenceTypeSickness) {
			if (isForAbsenceTypeAccident.value === 'no' && isForAbsenceTypePregnancy.value === 'no' && isForAbsenceTypeSickness.value === 'no') {
				FormValidation.addError(isForAbsenceTypeAccident, MANDATORY_ABSENCE_TYPE);
				FormValidation.addError(isForAbsenceTypePregnancy, MANDATORY_ABSENCE_TYPE);
				FormValidation.addError(isForAbsenceTypeSickness, MANDATORY_ABSENCE_TYPE);
			} else {
				FormValidation.removeError(isForAbsenceTypeAccident, MANDATORY_ABSENCE_TYPE);
				FormValidation.removeError(isForAbsenceTypePregnancy, MANDATORY_ABSENCE_TYPE);
				FormValidation.removeError(isForAbsenceTypeSickness, MANDATORY_ABSENCE_TYPE);
			}
		}
		return OK;
	};
};

const dayGreaterThanZero = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const day = control.get('day');

		if (day && day.value <= 0) {
			FormValidation.addError(day, DAY_MUST_BE_GREATER_THAN_ZERO);
		} else {
			FormValidation.removeError(day, MANDATORY_ABSENCE_TYPE);
		}
		return OK;
	};
};

const noteAtLeastOneLanguage = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const noteDe = control.get('noteDe');
		const noteEn = control.get('noteEn');
		const noteFr = control.get('noteFr');
		const noteIt = control.get('noteIt');

		validateLabel(noteDe, noteEn, noteFr, noteIt, NOTE_AT_LEAST_ONE_LANGUAGE);
		return OK;
	};
};

const titleAtLeastOneLanguage = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const titleDe = control.get('titleDe');
		const titleEn = control.get('titleEn');
		const titleFr = control.get('titleFr');
		const titleIt = control.get('titleIt');

		validateLabel(titleDe, titleEn, titleFr, titleIt, TITLE_AT_LEAST_ONE_LANGUAGE);
		return OK;
	};
};

const validateLabel = (
	deControl: AbstractControl,
	enControl: AbstractControl,
	frControl: AbstractControl,
	itControl: AbstractControl,
	errorType: { [key: string]: any }
): void => {
	if (
		StringsUtils.isEmpty(deControl.value) &&
		StringsUtils.isEmpty(enControl.value) &&
		StringsUtils.isEmpty(frControl.value) &&
		StringsUtils.isEmpty(itControl.value)
	) {
		FormValidation.addError(deControl, errorType);
		FormValidation.addError(enControl, errorType);
		FormValidation.addError(frControl, errorType);
		FormValidation.addError(itControl, errorType);
	} else {
		FormValidation.removeError(enControl, errorType);
		FormValidation.removeError(frControl, errorType);
		FormValidation.removeError(itControl, errorType);
		FormValidation.removeError(deControl, errorType);
	}
};

export { mandatoryAbsenceType, dayGreaterThanZero, noteAtLeastOneLanguage, titleAtLeastOneLanguage };
