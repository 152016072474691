import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { SettingsPageAction } from '../../../state/settings/action';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TaskPageAction } from '../../state/action';

@Component({
	selector: 'ea-task-footer',
	templateUrl: './task-footer.component.html',
	styleUrls: ['./task-footer.component.scss'],
})
export class TaskFooterComponent {
	code: any;
	@Input() isTask = false;
	@Input() showEditButton = false;
	@Input() isNote = false;
	@Input() onSave: () => void;
	@Input() editNote: () => void;
	@Input() saveButtonLabel = 'save';
	@Input() formGroup: FormGroup;

	constructor(private location: Location, private store: Store, private route: ActivatedRoute) {
		this.route.params.subscribe((params) => {
			if (params.code) {
				this.code = params.code;
			}
		});
	}

	goBack = (): void => {
		this.location.back();
	};

	history = (): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${this.code}/history` }));
	};

	addTask = (): void => {
		this.store.dispatch(TaskPageAction.clearTask());
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${this.code}/tasks/new` }));
	};

	addNote = (): void => {
		this.store.dispatch(TaskPageAction.clearNote());
		this.store.dispatch(SettingsPageAction.navigate({ url: `absence/${this.code}/note/new` }));
	};

	tasks = (): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${this.code}/tasks` }));
	};
}
