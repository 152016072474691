import { Absence } from '../model/absence';
import * as ApplicationState from '../../state/app.state';
import { Company } from '../../shared/model/company';
import { Employee } from '../../shared/model/employee';
import { Option } from 'nc-shared';
import { TableData } from 'nc-datatable';
import { AbsenceContextModel } from '../../shared/model/absence-context.model';
import { AbsenceAnnex } from '../model/absence-annex';
import { FrontendStyleEnumeration } from '../../integration/model/enumeration/frontend-style.enumeration';
import { AbsenceTypeEnumeration } from '../../shared/enumeration/absence-type.enumeration';
import { AbsenceAccidentSubTypeEnumeration } from '../model/enumeration/absence-accident-sub-type.enumeration';
import { IncidentContext } from '../../kle/model/incident-context';

export interface AbsenceState {
	absence: Absence;
	incidentContext: IncidentContext;
	company: Company;
	employee: Employee;
	responsibleUsers: Option[];
	responsibleLDUsers: Option[];
	history: TableData;
	reassignAbsenceEmployees: TableData;
	absenceContextModel: AbsenceContextModel;
	absenceAnnex: AbsenceAnnex;
	absenceCode: string;
	documentsDetails: TableData;
	absenceSavedSuccessfully: boolean;
	employees: Option[];
}

export interface State extends ApplicationState.State {
	absence: AbsenceState;
}

export const ABSENCE_INITIAL_STATE: AbsenceState = {
	absenceSavedSuccessfully: false,
	employee: {
		id: null,
		neoId: '',
		neoStatus: '',
		neoCategoryId: '',
		neoCategoryName: '',
		easyTempId: '',
		easyTempStatus: '',
		municipalityId: '',
		socialSecurityNumber: '',
		lastName: '',
		firstName: '',
		gender: '',
		nationality: '',
		birthDate: null,
		language: '',
		numberOfChildren: '',
		neoDivorceDate: '',
		neoMarriageDate: '',
		civilStatus: '',
		easyTempCivilStatusValidFrom: '',
		zarZemis: '',
		sourceTax: '',
		email: '',
		homePhone: '',
		mobilePhone: '',
		zip: '',
		city: '',
		street: '',
		postbox: '',
		locality: '',
		country: '',
		residenceCanton: '',
		residenceCategory: '',
		addressComplementaryLine: '',
		pkProInsuranceExitDate: null,
		pkProInsuranceEntryDate: null,
		externallyUpdatedOnNeo: '',
		externallyUpdatedOnEasyTemp: '',
	},
	company: {
		id: 0,
		name: '',
		canton: '',
		city: '',
		country: '',
		zip: '',
		addressComplementaryLine: '',
		contractType: '',
		defaultAssigneeId: 0,
		defaultAssigneeName: '',
		easyTempId: 0,
		easyTempIdForDatabase: 0,
		externallyUpdatedOn: null,
		neoId: 0,
		hrnumber: '',
		isNeoMaster: false,
		street: '',
		isSwicaInsured: false,
		isKleEnabled: false,
		isParent: false,
		locality: '',
		postbox: '',
		companyWorkplaces: '',
		isPkProInsured: false,
		nameComplementaryLine1: '',
		nameComplementaryLine2: '',
		neoEnvironment: '',
		neoGroupId: 0,
		new: false,
		neoIdForDatabase: 0,
		ownerFirstName: '',
		uid: '',
		ownerLastName: '',
		frontendStyle: FrontendStyleEnumeration.ECARE,
	},
	absence: {
		id: 0,
		code: '',
		status: '',
		refEmployee: null,
		companyId: null,

		numberOfChildren: 0,
		hasChildrenUnder18OrInEducationUpTo25: 'no',

		hasReturnedToWork: 'no',
		numberOfPossibleWorkHours: 0,

		pregnancyDueDate: null,
		absenceType: AbsenceTypeEnumeration.ACCIDENT.code,
		absenceSubType: AbsenceAccidentSubTypeEnumeration.WORK.code,
		categoryType: 'REGULAR',
		comment: '',

		hasDailySicknessBenefitsInsurance: 'no',
		dailySicknessBenefitsInsuranceCompany: '',
		dailySicknessBenefitsInsuranceSinisterReference: '',

		hasAccidentInsurance: 'no',
		accidentInsuranceCompany: '',
		accidentInsuranceSinisterReference: '',

		employmentStartDate: null,
		employmentEndDate: null,
		employmentPercentage: '',
		employmentAnnualSalary: '',

		firstDeploymentDate: null,
		firstDeploymentRegistration: 'REGISTRATION_FROM_1ST_DAY',
		firstDeploymentSubject: 'SUBJECT_TO_DUTY_OF_MAINTENANCE',
		lastDeploymentDate: null,

		medicalDiagnosisCode: '',
		medicalDiagnosisAdditionalCode: '',

		responsibleUserId: null,
		responsibleUserName: '',
		responsibleLDUserId: null,
		responsibleLDUserName: '',

		isSentToCaseManagementCompany: 'no',
		sentToCaseManagementCompanyDate: null,
		isClosedByCaseManagementCompany: 'no',
		closedByCaseManagementCompanyDate: null,
		isAbortedByCaseManagementCompany: 'no',
		abortedByCaseManagementCompanyDate: null,

		isPkproInsured: 'no',
		isSwicaInsured: 'no',

		documents: [],
		incapacities: [],
		accidentDescription: null,
		treatments: null,

		archived: false,
		merged: false,
		isValidToPopulated: false,
		isCaseManagementActive: 'no',
		caseManagementDetails: null,
		codeRelapse: '',
		contactPerson: null,
		allowedDocumentTypes: [],
		archivedComment: null,
		archivedReason: null,
		closed: false,
		companyEasyTempId: 0,
		companyNeoId: 0,
		createdBy: null,
		createdOn: null,
		documentTypes: [],
		fetchDocumentTypes: [],
		generateDocumentTypes: [],
		hasEditPermission: false,
		hasRiskAssessment: false,
		hasSavedConfirmationEmail: false,
		medicalDiagnosisAdditionalValue: null,
		medicalDiagnosisValue: null,
		milestoneStatuses: [],
		personalStatus: null,
		readonly: false,
		showMobiliarDataSheet: false,
		userResponsibleReadonly: false,
		temporaryContract: false,
		userResponsibleLDReadonly: false,
		userType: null,
		validToPopulated: false,
		activeCases: [],
		linkedAbsences: { records: [] },
		mergedCases: { records: [] },
		shareInformation: { records: [] },
		isRecoverageActive: 'no',
		deleted: false,
		forcedNew: false,
		caseWasOpen: false,
	},
	incidentContext: null,
	responsibleUsers: [],
	responsibleLDUsers: [],
	history: { records: [] },
	reassignAbsenceEmployees: { records: [] },
	absenceContextModel: null,
	absenceAnnex: null,
	absenceCode: null,
	documentsDetails: { records: [] },
	employees: [],
};
