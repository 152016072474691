import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatatableColumn, TableData } from 'nc-datatable';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { ReportPageAction, ReportSelect } from '../state/action';
import { Observable } from 'rxjs';
import { EnumUtils } from 'nc-utils';
import { ReportTypeEnumeration } from '../model/report-type.enumeration';
import { Router } from '@angular/router';
import { TableColumns } from '../model/table-columns';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportComponent extends BaseComponent implements OnInit, OnDestroy {
	formGroup: FormGroup;
	reportColumns: DatatableColumn[];
	type: Option[];
	isActivityLog: Boolean;
	report$: Observable<TableData>;
	filterConfig: FilterConfig;

	constructor(private store: Store, private router: Router, private formBuilder: FormBuilder) {
		super();
		this.formGroup = formBuilder.group({
			start: [null, []],
			to: [null, []],
			type: ['PUBLIC', []],
		});
		this.isActivityLog = this.router.url.includes('activity-log');
		this.report$ = this.store.select(ReportSelect.getReport);
		this.filterConfig = {
			showGlobalFilter: false,
			filterValues: {},
		};
	}

	ngOnInit(): void {
		this.type = EnumUtils.toItems(ReportTypeEnumeration);
		this.reportColumns = this.isActivityLog ? TableColumns.ACTIVITY_LOG_COLUMNS : TableColumns.REPORT_COLUMNS;
	}

	showReport = (): void => {
		this.store.dispatch(ReportPageAction.getReport({ reportRequest: this.formGroup.getRawValue(), isActivityLog: this.isActivityLog }));
	};

	onExport = (): void => {
		this.store.dispatch(ReportPageAction.exportToFile({ reportRequest: this.formGroup.getRawValue(), isActivityLog: this.isActivityLog }));
	};

	ngOnDestroy() {
		super.ngOnDestroy();
		this.store.dispatch(ReportPageAction.clearData());
	}
}
