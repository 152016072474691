export enum AbsenceArchiveReasonEnumeration {
	ABLE_TO_WORK = 'ableToWork',
	DIED = 'died',
	CHILD_BORN = 'childBorn',
	NO_COVER = 'noCover',
	DISABILITY_CASE = 'disabilityCase',
	BENEFITS_EXHAUSTED = 'benefitsExhausted',
	SWITCH_TO_ILLNESS = 'switchToIllness',
	SWITCH_TO_ACCIDENT = 'switchToAccident',
	POWER_OF_ATTORNEY_NOT_ANSWERED = 'powerOfAttorneyNotAnswered',
	DOCUMENT_NOT_RECEIVED = 'documentNotReceived',
	WAITING_PERIOD_NOT_REACHED = 'waitingPeriodNotReached',
	NOT_SUBJECT_TO_PAYMENT = 'notSubjectToPayment',
	NOT_INSURED_BY_TELLCO = 'notInsuredByTellco',
	CM_NOT_WANTED = 'cmNotWanted',
	CM_CLOSED = 'cmClosed',
	NO_GUIDANCE_POTENTIAL = 'noGuidancePotential',
	OTHER_ARCHIVE_REASON = 'otherArchiveReason',
}
