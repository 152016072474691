import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { LdResponsiblePageAction, LdResponsibleSelect } from '../state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { SettingsPageAction, SettingsSelect } from '../../state/settings/action';
import { UserSelect } from '../../user/state/action';

@Component({
	templateUrl: './ld-responsible.component.html',
	styleUrls: ['./ld-responsible.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LdResponsibleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	ldResponsibles$: Observable<TableData>;
	ldResponsibleColumns: DatatableColumn[];
	filterConfig: FilterConfig;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	GLOBAL_FILTER = 'globalFilter';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store, private router: Router) {
		super();
		this.loggedUserSubscription$ = store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});

		this.store.dispatch(LdResponsiblePageAction.getLdResponisbles());
		this.ldResponsibles$ = this.store.select(LdResponsibleSelect.getLdResponisbles);
	}

	ngOnInit(): void {
		this.store.select(SettingsSelect.getLdResponsibleUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});
		this.ldResponsibleColumns = [
			ColumnBuilder.create('name', 'name'),
			ColumnBuilder.create('letter', 'letter'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];
		this.filterConfig = {
			filterValues: {
				globalFilter: this.userPreferences[this.GLOBAL_FILTER],
			},
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
		};
	}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/ld-responsibles/${rowData.id}` }));
	};

	delete = (rowData): void => {
		this.store.dispatch(LdResponsiblePageAction.openDeleteConfirmationDialog({ id: rowData.id }));
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			globalFilter: this.filterConfig.globalFilterFormGroup.get(this.GLOBAL_FILTER).value,
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(SettingsPageAction.setLdResponsibleTableFilter({ ldResponsibleFilters: filterObject, loggedUser: this.loggedUser }));
	}
}
