<div class="unavailable-screen">
	<div class="unavailable-center">
		<img src="{{ logo }}" class="splash-screen-image responsive" />
		<div *ngIf="selectedLanguage" class="unavailable-width center">
			{{ unavailableMessage | translate }}
		</div>
		<div class="unavailable-width center">
			<nc-button [id]="'retry'" [onClick]="onRetry" label="{{ buttonLabel }}"></nc-button>
		</div>
	</div>
</div>
