<div [formGroup]="additionsPersonGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div fxLayout="row">
		<nc-submit-button id="fetch-additions-person" buttonLabel="fetch" (click)="fetch()" dialogLabel="processing"></nc-submit-button>
	</div>
	<div fxLayout="row" fxLayoutGap="15px">
		<nc-input
			id="jobTitle"
			label="jobTitle"
			[formGroup]="additionsPersonGroup"
			formControlName="jobTitle"
			[required]="true"
			fxFlex="100"
			isReadonly="true"
		></nc-input>
	</div>
	<div fxLayout="row" fxLayoutGap="15px">
		<nc-select
			id="positionType"
			label="positionType"
			[formGroup]="additionsPersonGroup"
			[data$]="positionTypeItems"
			formControlName="positionType"
			isReadonly="true"
		>
		</nc-select>
		<nc-select
			id="employmentContractType"
			label="employmentContractType"
			[formGroup]="additionsPersonGroup"
			[data$]="employmentContractTypeItems"
			formControlName="employmentContractType"
			isReadonly="true"
			[required]="true"
		>
		</nc-select>
	</div>
	<div fxLayout="row" fxLayoutGap="15px">
		<nc-datepicker
			id="validAsOf"
			label="validAsOf"
			[formGroup]="additionsPersonGroup"
			formControlName="validAsOf"
			required="true"
			isReadonly="true"
		></nc-datepicker>
		<nc-datepicker
			id="contractFixedUntil"
			label="contractFixedUntil"
			[formGroup]="additionsPersonGroup"
			formControlName="contractFixedUntil"
		></nc-datepicker>
		<nc-datepicker
			id="contractDissolveFrom"
			label="contractDissolveFrom"
			[formGroup]="additionsPersonGroup"
			formControlName="contractDissolveFrom"
			isReadonly="true"
		></nc-datepicker>
	</div>
	<div fxLayout="row" fxLayoutGap="15px">
		<nc-input
			id="taxAtSourceCanton"
			[formGroup]="additionsPersonGroup"
			formControlName="taxAtSourceCanton"
			label="taxAtSourceCanton"
			isReadonly="true"
		>
		</nc-input>
		<nc-input
			type="number"
			id="taxAtSourceMunicipalityId"
			[formGroup]="additionsPersonGroup"
			formControlName="taxAtSourceMunicipalityId"
			label="taxAtSourceMunicipalityId"
			isReadonly="true"
		>
		</nc-input>
		<nc-input id="categoryOpen" [formGroup]="additionsPersonGroup" formControlName="categoryOpen" label="categoryOpen"></nc-input>
		<nc-input
			[mask]="{ mask: '*0*' }"
			id="taxAtSourceCode"
			[formGroup]="additionsPersonGroup"
			formControlName="taxAtSourceCode"
			label="taxAtSourceCode"
			isReadonly="true"
		>
		</nc-input>
		<nc-select
			[data$]="categoryPredefineTypeEnum"
			id="categoryPredefined"
			[formGroup]="additionsPersonGroup"
			formControlName="categoryPredefined"
			label="categoryPredefined"
			[translate]="true"
			[removeSelected]="true"
			fxFlex="30"
		>
		</nc-select>
	</div>
	<div *ngIf="isInvalid()" class="error-container">
		<ng-container *ngIf="getErrorMessage() as errorLabel">
			{{ errorLabel.label | translate : errorLabel.parameters }}
		</ng-container>
	</div>
</div>
