<form [formGroup]="formGroup" fxFill fxLayout="column">
	<div *ngIf="!isHrUser" id="responsible-user-container">
		<nc-select
			[id]="'responsible-user'"
			[searchTextId]="'responsible-user-search'"
			[searchButtonId]="'responsible-user-search-clear'"
			[clearIconId]="'responsible-user-clear'"
			label="responsibleUser"
			formControlName="responsibleUser"
			[formGroup]="formGroup"
			[data$]="responsibleUsers$"
		>
		</nc-select>
	</div>
	<div *ngIf="isHrUser && companiesIds.length > 1" id="companies-ids-container">
		<nc-select
			[id]="'companies'"
			[searchTextId]="'companies-search'"
			[searchButtonId]="'companies-search-clear'"
			[clearIconId]="'companies-clear'"
			label="companies"
			formControlName="companies"
			[multiple]="true"
			[formGroup]="formGroup"
			[data$]="companies$"
		>
		</nc-select>
	</div>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="archived-cases-container">
				<nc-datatable
					[id]="'archived-cases'"
					[rowClick]="onRowClick"
					[columns]="archivedAbsencesColumns"
					[data$]="archivedAbsences$"
					[filterConfig]="filterConfig"
				>
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
</form>
