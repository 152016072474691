import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentTypeTranslation } from '../model/document-type-translation';
import { SaveResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class DocumentTypesService {
	private readonly DOCUMENT_TYPES_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/document-types`;

	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets document types
	 */
	getDocumentTypes(): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(this.DOCUMENT_TYPES_URL);
	}

	/**
	 * Save document types
	 * @param documentType Model from form data
	 */
	saveDocumentType(documentType: any): Observable<SaveResponse> {
		return this.httpClient.put<SaveResponse>(this.DOCUMENT_TYPES_URL, documentType);
	}

	/**
	 * Gets document type
	 * @param code document type code
	 */
	getDocumentType(code: string): Observable<DocumentTypeTranslation[]> {
		return this.httpClient.get<DocumentTypeTranslation[]>(`${this.DOCUMENT_TYPES_URL + '/' + code}`);
	}
}
