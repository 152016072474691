import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseComponent, LabelWithParameters, Option } from 'nc-shared';
import { PositionTypeEnumeration } from '../../model/position-type.enumeration';
import { EmploymentContractTypeEnumeration } from '../../model/employment-contract-type.enumeration';
import { EnumUtils, ErrorDetails } from 'nc-utils';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { CategoryPredefinedTypeEnum } from '../../model/enums/category-predefined-type.enum';
import { KlePageAction, KleSelect } from '../../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { categoryValidator, employmentContractMandatoryFields } from '../../validator/additions-person.validator';

@Component({
	selector: 'ea-additions-person',
	templateUrl: './additions-person.component.html',
	styleUrls: ['./additions-person.component.scss'],
})
export class AdditionsPersonComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	categoryPredefineTypeEnum: Observable<Option[]>;
	positionTypeItems: Observable<Option[]>;
	employmentContractTypeItems: Observable<Option[]>;
	errors: ErrorDetails;

	additionsPersonGroup = this.formBuilder.group(
		{
			positionType: [''],
			employmentContractType: ['', Validators.required],
			validAsOf: [null],
			jobTitle: ['', Validators.required],
			contractFixedUntil: [null],
			contractDissolveFrom: [null],
			taxAtSourceCanton: [''],
			taxAtSourceMunicipalityId: [null],
			categoryOpen: [''],
			taxAtSourceCode: [''],
			categoryPredefined: [''],
		},
		{
			validators: [categoryValidator, employmentContractMandatoryFields],
		}
	);

	constructor(private formBuilder: FormBuilder, private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.categoryPredefineTypeEnum = EnumUtils.toObservableItems(CategoryPredefinedTypeEnum);
		this.positionTypeItems = EnumUtils.toObservableItems(PositionTypeEnumeration);
		this.employmentContractTypeItems = EnumUtils.toObservableItems(EmploymentContractTypeEnumeration);

		this.store
			.select(KleSelect.getEmploymentContract)
			.pipe(
				filter((additionsPerson) => additionsPerson != null),
				takeUntil(this.destroy$)
			)
			.subscribe((employmentContract) => this.additionsPersonGroup.patchValue(employmentContract));

		this.formGroup.addControl(KleFormGroupEnum.ADDITIONS_PERSON, this.additionsPersonGroup);
	}

	fetch(): void {
		this.store.dispatch(KlePageAction.getAdditionsPerson({ absenceCode: this.formGroup.value.incidentContext.absenceCode }));
	}

	isInvalid(): boolean {
		let isInvalid = false;
		const errors = this.additionsPersonGroup.errors;
		if (errors) {
			const errorKeys = Object.keys(errors);
			isInvalid = errorKeys.length > 0;
		}

		return isInvalid;
	}

	getErrorMessage(): LabelWithParameters {
		if (!this.isInvalid()) {
			return null;
		}

		let errorLabel: LabelWithParameters = null;
		const errors = this.additionsPersonGroup.errors;

		if (errors) {
			const key = Object.keys(errors)[0];
			const params = errors[key];

			const error = this.additionsPersonGroup[key];
			if (error) {
				const label = error.label;
				const value = error.value(params);

				errorLabel = { label, parameters: value };
			} else {
				errorLabel = { label: key, parameters: params };
			}
		}
		return errorLabel;
	}

	ngOnDestroy() {
		this.additionsPersonGroup.reset();
		this.store.dispatch(KlePageAction.clearAdditionsPerson());
	}
}
