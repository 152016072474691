import { createReducer, on } from '@ngrx/store';
import { ACCESS_ASSIGNMENT_STATE, AccessAssignmentState } from './access-assignment.state';
import { AccessAssignmentApiAction, AccessAssignmentPageAction } from './action';

export const accessAssignmentReducer = createReducer<AccessAssignmentState>(
	ACCESS_ASSIGNMENT_STATE,
	on(AccessAssignmentApiAction.getSelectOptionsSuccess, (state, action): AccessAssignmentState => {
		return {
			...state,
			selectOptions: action.selectOptions,
		};
	}),
	on(AccessAssignmentApiAction.getAvailableSuccess, (state, action): AccessAssignmentState => {
		return {
			...state,
			accessAssignmentAvailable: { records: action.records },
		};
	}),
	on(AccessAssignmentApiAction.getAvailableForDocsSuccess, (state, action): AccessAssignmentState => {
		return {
			...state,
			accessAssignmentAvailable: { records: action.records },
		};
	}),
	on(AccessAssignmentApiAction.getAssignedSuccess, (state, action): AccessAssignmentState => {
		return {
			...state,
			accessAssignmentAssigned: { records: action.records },
		};
	}),
	on(AccessAssignmentApiAction.getAssignedForDocsSuccess, (state, action): AccessAssignmentState => {
		return {
			...state,
			accessAssignmentAssigned: { records: action.records },
		};
	}),
	on(AccessAssignmentPageAction.setCurrentEntityId, (state, action): AccessAssignmentState => {
		return {
			...state,
			entityId: action.entityId,
		};
	}),
	on(AccessAssignmentPageAction.setCurrentAuthorityType, (state, action): AccessAssignmentState => {
		return {
			...state,
			authorityType: action.authorityType,
		};
	}),
	on(AccessAssignmentPageAction.setCurrentEntityType, (state, action): AccessAssignmentState => {
		return {
			...state,
			entityType: action.entityType,
		};
	}),
	on(AccessAssignmentPageAction.setCurrentAccessType, (state, action): AccessAssignmentState => {
		return {
			...state,
			accessType: action.accessType,
		};
	}),
	on(AccessAssignmentPageAction.clearData, (): AccessAssignmentState => ACCESS_ASSIGNMENT_STATE)
);
