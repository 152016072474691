import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TreatmentDialogComponent } from '../component/treatments/treatment-dialog/treatment-dialog.component';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'nc-utils';
import { Observable } from 'rxjs';
import { Treatment } from '../model/treatment';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({ providedIn: 'root' })
export class TreatmentsService {
	constructor(
		private httpClient: HttpClient,
		private formBuilder: FormBuilder,
		private dialogService: DialogService,
		private environmentLoader: EnvironmentLoader
	) {}

	private readonly GET_MEDICAL_INSTITUTION = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/integration/medical-institutions/`;

	/**
	 * Get medical institution data
	 */
	getMedicalInstitution(id: number): Observable<Treatment> {
		return this.httpClient.get<Treatment>(this.GET_MEDICAL_INSTITUTION + id);
	}

	/**
	 * Opens dialog for creation or edit of treatments
	 * @param model treatments model if exists or else null
	 * @param formArray treatments form array
	 * @param formGroupParent parent formgroup for stories
	 */
	openDialog(model: Treatment, formArray: FormArray, formGroupParent: FormGroup): void {
		const formGroup = this.getFormGroup(formArray, model);

		const dialogConfig = {
			width: '700px',
			data: { formGroup },
		};

		const dialogRef = this.dialogService.open(TreatmentDialogComponent, dialogConfig);

		dialogRef
			.afterClosed()
			.pipe(filter((result) => result?.success))
			.subscribe(() => {
				formGroupParent.markAsDirty();
				if (!model) {
					formArray.push(formGroup);
				} else {
					const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
					formArray.removeAt(index);
					formArray.insert(index, formGroup);
				}
			});
	}

	/**
	 * Gets form group for formArray
	 */
	private getFormGroup = (formArray: FormArray, model: Treatment): FormGroup => {
		let formGroup: FormGroup;

		if (model) {
			const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
			const existing = formArray.at(index) as FormGroup;
			formGroup = this.createExistingFormGroup(existing.getRawValue());
		} else {
			const tableId = formArray.length === 0 ? 0 : formArray.at(formArray.length - 1).get('tableId').value + 1;
			formGroup = this.createNewFormGroup(tableId);
		}

		return formGroup;
	};

	/**
	 * Creates new form group for treatments
	 * @param treatment with already exist value for form
	 */
	public createExistingFormGroup = (treatment: Treatment) => {
		return this.formBuilder.group({
			id: [treatment.id],
			institutionId: [treatment.institutionId],
			treatmentId: [null],
			zsrNumber: [treatment.zsrNumber],
			uidBfs: [treatment.uidBfs],
			tableId: [treatment.tableId],
			glnNumber: [treatment.glnNumber],
			treatmentInstitution: [treatment.treatmentInstitution, [Validators.required]],
			complementaryLine: [treatment.complementaryLine],
			street: [treatment.street],
			postbox: [treatment.postbox],
			locality: [treatment.locality],
			zipCode: [treatment.zipCode, [Validators.required]],
			city: [treatment.city, [Validators.required]],
			country: [treatment.country],
			emailAddress: [treatment.emailAddress, Validators.email],
			phoneNumber: [treatment.phoneNumber],
			mobilePhoneNumber: [treatment.mobilePhoneNumber],
		});
	};

	/**
	 * Creates new form group for treatments
	 * @param tableId table id value for new form group
	 */
	private createNewFormGroup = (tableId: number): FormGroup => {
		return this.formBuilder.group({
			id: [null],
			treatmentId: [null],
			zsrNumber: [null],
			uidBfs: [null],
			tableId: [tableId],
			glnNumber: [null],
			treatmentInstitution: [null, [Validators.required]],
			complementaryLine: [null],
			street: [null],
			postbox: [null],
			locality: [null],
			zipCode: [null, [Validators.required]],
			city: [null, [Validators.required]],
			country: [null],
			emailAddress: [null, Validators.email],
			phoneNumber: [null],
			mobilePhoneNumber: [null],
		});
	};
}
