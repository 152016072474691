<form fxLayout="column" fxFill [formGroup]="formGroup">
	<ea-header-info></ea-header-info>
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<nc-input
					id="insured-person"
					[formGroup]="formGroup"
					formControlName="insuredPersonName"
					label="insuredPerson"
					[isReadonly]="true"
				></nc-input>
				<nc-input
					id="reference-number"
					[formGroup]="formGroup"
					formControlName="absenceCode"
					label="referenceNumber"
					[isReadonly]="true"
				></nc-input>
				<ng-container *ngIf="formGroup.controls['type'].value === 'PROLONGATION'">
					<nc-datepicker [id]="'valid-from'" [formGroup]="formGroup" formControlName="validFrom" label="validFrom"></nc-datepicker>
					<nc-datepicker [id]="'valid-to'" [formGroup]="formGroup" formControlName="validTo" label="validTo"></nc-datepicker>
					<nc-input
						id="incapacity-to-work"
						[formGroup]="formGroup"
						formControlName="percentage"
						label="incapacityToWork"
						[mask]="{ mask: '000' }"
					></nc-input>
				</ng-container>
				<nc-textarea
					id="”comment”"
					[formGroup]="formGroup"
					formControlName="comment"
					label="comment"
					[maxLength]="300"
					[ngClass]="{
						invalid: isCommentFilled() && formGroup.controls.comment.touched
					}"
				></nc-textarea>
				<ea-documents
					id="new-absence-document"
					[buttonId]="'open-dialog'"
					[downloadButtonId]="'download-documents-button'"
					[documentsFormArray]="documents"
					[hidePreview]="true"
					[hideMultiDownload]="true"
				></ea-documents>
			</div>
			<div *ngIf="showExactValidationError()" class="error-container">
				{{ 'mandatory.absence.annexContent.comment' | translate }}
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
