import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccessManagementState } from '../acess-management.state';

export const ACCESS_MANAGEMENT_FEATURE_STATE_NAME = 'access_management';
const FEATURE_STATE = createFeatureSelector<AccessManagementState>(ACCESS_MANAGEMENT_FEATURE_STATE_NAME);

export const getAuthorities = createSelector(FEATURE_STATE, (state) => state.authorities);
export const getAuthority = createSelector(FEATURE_STATE, (state) => state.authority);
export const getCurrentAuthorityType = createSelector(FEATURE_STATE, (state) => state.currentAuthorityType);
export const getUrlPath = createSelector(FEATURE_STATE, (state) => state.urlPath);
