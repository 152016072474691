import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PingResponse } from '../model/response/ping.response';
import { InteroperabilityResponse } from '../model/response/interoperability-response';
import { InteroperabilityRequest } from '../model/request/interoperability-request';
import { DeclareIncidentTestRequest } from '../model/request/declare-incident-test-request';
import { ActionResponse, parseDate } from 'nc-utils';
import { PaymentResponse } from '../model/response/payment.response';
import { SynchronizeIncidentRequest } from '../model/request/synchronize-incident-request';
import { AnnualSalary } from '../model/annual-salary';
import { TableData } from 'nc-datatable';
import { Settlement } from '../model/settlement/settlement';
import { CrossChannelLink, CrossChannelLinkDisplayData } from '../model/cross-channel-link';
import { filter, map } from 'rxjs/operators';
import { ResponseModel } from '../../shared/model/response.model';
import { RegisterOrganization } from '../model/register-organization';
import { KleServiceEnum } from '../model/enums/kle-service.enum';
import { GetResultResponse } from '../model/response/get-result-response';
import { PersonMutated, PersonMutatedServerData } from '../model/person-mutated';
import { SynchronizeIncidentResponse } from '../model/response/synchronize-incident.response';
import { AdditionsPerson } from '../model/additions-person';
import { Repayment } from '../model/repayment';
import { DialogMessage } from '../model/dialog-message';
import { IncidentResponse } from '../model/response/incident.response';
import { AnnualSalaryResponse } from '../model/response/annual-salary-response';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { TranslateService } from '@ngx-translate/core';
import { DeclareIncidentResponse } from '../model/response/declare-incident.response';
import { CheckActionHistory } from '../model/check-action-history';

@Injectable({ providedIn: 'root' })
export class KleService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader, private translateService: TranslateService) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly PING_URL = `${this.API_URL}/kle/test/ping/`;
	private readonly INTEROPERABILITY_URL = `${this.API_URL}/kle/test/checkInteroperability`;
	private readonly DECLARE_INCIDENT_URL = `${this.API_URL}/kle/test/declareIncidentTest`;
	private readonly SYNCHRONIZE_INCIDENT_URL = `${this.API_URL}/kle/synchronize-incident`;
	private readonly GET_PAYMENTS_URL = `${this.API_URL}/kle/payments`;
	private readonly GET_ANNUAL_SALARY_URL = `${this.API_URL}/kle/annual-salary`;
	private readonly ABSENCE_CODE_PLACEHOLDER = '{absenceCode}';

	private readonly GET_INCIDENT_URL = `${this.API_URL}/kle/incident/`;
	private readonly GET_DIALOG_MESSAGES = `${this.API_URL}/kle/synchronize-incident/{absenceCode}/dialog-message`;
	private readonly GET_CROSS_CHANNEL_LINK_DATA = `${this.API_URL}/kle/synchronize-incident/{absenceCode}/cross-channel-link/{storyId}`;
	private readonly GET_REPAYMENTS = `${this.API_URL}/kle/synchronize-incident/{absenceCode}/repayments`;
	private readonly GET_CERTIFICATE_REQUEST_ID = `${this.API_URL}/kle/organization-authentication/get-crid/{companyId}`;

	private readonly GET_SETTLEMENT = `${this.API_URL}/kle/synchronize-incident/{absenceCode}/settlements`;
	private readonly SEND_SETTLEMENTS = `${this.API_URL}/kle/{absenceCode}/send-settlements`;
	private readonly GENERATE_PDF = `${this.API_URL}/kle/{absenceCode}/settlements`;
	private readonly GET_COMPANIES = `${this.API_URL}/kle/organization-authentication/companies/{companyIds}`;
	private readonly GET_FETCH_COMPANY_DATA = `${this.API_URL}/kle/organization-authentication/company-fetch/{companyId}`;
	private readonly REGISTER_ORGANIZATION_URL = `${this.API_URL}/kle/organization-authentication/register-organization/{companyId}`;
	private readonly GET_PERSON_MUTATED = `${this.API_URL}/kle/{absenceCode}/person-mutated`;
	private readonly GET_RESULT = `${this.API_URL}/kle/organization-authentication/{companyId}/get-result`;
	private readonly SIGN_CERTIFICATE_URL = `${this.API_URL}/kle/organization-authentication/sign-certificate`;
	private readonly RENEW_CERTIFICATE_URL = `${this.API_URL}/kle/organization-authentication/renew-certificate`;
	private readonly GER_ADDITIONS_PERSON = `${this.API_URL}/kle/additions-person/`;
	private readonly GENERATE_ACCIDENT_CERTIFICATE = `${this.API_URL}/kle/{absenceCode}/generate-accident-certificate`;
	private readonly GENERATE_PHARMACY_CERTIFICATE = `${this.API_URL}/kle/{absenceCode}/generate-pharmacy-certificate`;
	private readonly CHECK_ACTION_HISTORY = `${this.API_URL}/kle/{absenceCode}/check-actionHistory`;
	private readonly GET_EMPLOYEE_CONTRACT_DOCUMENT = `${this.API_URL}/kle/{absenceCode}/get-employee-contract-document`;
	private readonly GET_EMPLOYEE_TIMETABLE_DOCUMENT = `${this.API_URL}/kle/{absenceCode}/get-employee-timetable-document`;

	/**
	 * Sends ping request
	 */
	ping(serviceType: KleServiceEnum): Observable<PingResponse> {
		return this.httpClient.get<ResponseModel<PingResponse>>(this.PING_URL + serviceType).pipe(map((x) => x.value));
	}

	/**
	 * Check interoperability with SwissDeck.
	 * @param request Submit form value - second operand.
	 */
	checkInteroperability(request: InteroperabilityRequest): Observable<InteroperabilityResponse> {
		return this.httpClient.post<ResponseModel<InteroperabilityResponse>>(this.INTEROPERABILITY_URL, request).pipe(map((x) => x.value));
	}

	/**
	 * Declare incident test
	 */
	declareIncidentTest(request: DeclareIncidentTestRequest): Observable<DeclareIncidentResponse> {
		return this.httpClient.post<ResponseModel<DeclareIncidentResponse>>(this.DECLARE_INCIDENT_URL, request).pipe(map((x) => x.value));
	}

	/**
	 * Gets payment data
	 */
	getPayment(absenceCode: string): Observable<PaymentResponse> {
		const url = this.GET_PAYMENTS_URL + '/' + absenceCode;
		return this.httpClient.get<ResponseModel<PaymentResponse>>(url).pipe(map((x) => x.value));
	}

	/**
	 * Gets annual salary data
	 */
	getAnnualSalary(absenceCode: string, absenceStartDate: string): Observable<AnnualSalary> {
		const url = this.GET_ANNUAL_SALARY_URL + '/' + absenceCode + '/' + absenceStartDate;
		return this.httpClient.get<ResponseModel<AnnualSalaryResponse>>(url).pipe(
			map((res) => {
				return {
					...res.value,
					accidentStartDate: parseDate(absenceStartDate),
					contractValidAsOf: parseDate(res.value.contractValidAsOf),
				};
			})
		);
	}

	/**
	 * Submit payment data
	 */
	synchronizeIncident(request: SynchronizeIncidentRequest): Observable<ResponseModel<SynchronizeIncidentResponse>> {
		return this.httpClient.post<ResponseModel<SynchronizeIncidentResponse>>(this.SYNCHRONIZE_INCIDENT_URL, request);
	}

	/**
	 * Gets incident data for kle details
	 */
	getIncident(absenceCode: string): Observable<IncidentResponse> {
		const url = this.GET_INCIDENT_URL + absenceCode;
		return this.httpClient.get<ResponseModel<ResponseModel<IncidentResponse>>>(url).pipe(
			map((x) => {
				return {
					settlements: x.value.value.settlements.forEach((settlement) => {
						settlement.sendStatus =
							this.translateService.instant(settlement.sendStatus) + ' ' + (settlement.sentOn ? settlement.sentOn : '');
					}),
					...x.value.value,
				};
			})
		);
	}

	/**
	 * Get additions person for selected employee
	 * @param absenceCode - selected absence code
	 */
	getAdditionsPerson(absenceCode: string): Observable<AdditionsPerson> {
		const url = this.GER_ADDITIONS_PERSON + absenceCode;
		return this.httpClient.get<ResponseModel<AdditionsPerson>>(url).pipe(map((x) => x.value));
	}

	/**
	 * Get one dialog message based on storyID
	 * @param absenceCode unique identifier for absence
	 * @param storyId unique identifier for story which contains dialog message
	 */
	getDialogMessage(absenceCode: string, storyId: string): Observable<DialogMessage> {
		const url = this.GET_DIALOG_MESSAGES.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode) + '/' + storyId;
		return this.httpClient.get<ResponseModel<DialogMessage>>(url).pipe(map((x) => x.value));
	}

	/**
	 * Get settlement details
	 * @param absenceCode Unique identifier for absence
	 * @param settlementId Unique identifier for settlement
	 */
	getSettlement(absenceCode: string, settlementId: string): Observable<Settlement> {
		const url = this.GET_SETTLEMENT.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode) + '/' + settlementId;
		return this.httpClient.get<ResponseModel<Settlement>>(url).pipe(map((x) => x.value));
	}

	/**
	 * Send settlements to EasyTemp
	 * @param absenceCode Unique identifier for absence
	 */
	sendSettlements(absenceCode: string): Observable<ResponseModel<any>> {
		const url = this.SEND_SETTLEMENTS.replace('{absenceCode}', absenceCode);
		return this.httpClient.get<ResponseModel<string>>(url);
	}

	/**
	 * Generate pdf of selected settlement
	 * @param absenceCode
	 * @param settlementId
	 */
	generatePDF(absenceCode: string, settlementId: string): Observable<HttpResponse<Blob>> {
		const url = this.GENERATE_PDF.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode) + '/' + settlementId + '/generate';
		return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
	}

	/**
	 * Get data for selected cross channel link
	 * @param absenceCode
	 * @param storyId
	 */
	getCrossChannelLink(absenceCode: string, storyId: string): Observable<CrossChannelLinkDisplayData> {
		const url = this.GET_CROSS_CHANNEL_LINK_DATA.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode).replace('{storyId}', storyId);
		return this.httpClient.get<ResponseModel<ResponseModel<CrossChannelLink>>>(url).pipe(
			map((data) => {
				return {
					...data.value.value,
					creationDate: parseDate(data.value.value.creationDate),
					expiryDate: parseDate(data.value.value.expiryDate),
				};
			})
		);
	}

	/**
	 * Gets repayments
	 */
	getRepayment(absenceCode: string, storyId: string): Observable<Repayment> {
		const url = this.GET_REPAYMENTS.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode);
		return this.httpClient.get<ResponseModel<ResponseModel<Repayment>>>(`${url}/${storyId}`).pipe(map((x) => x.value.value));
	}

	/**
	 * Get kle absences for selected absence
	 * @param absenceCode
	 */
	getAbsences(absenceCode: string): any {
		return [];
	}

	/**
	 * Get companies for Hr user
	 * @param companyIds List of ids
	 */
	getCompanies(companyIds: number[]): Observable<TableData> {
		const url = this.GET_COMPANIES.replace('{companyIds}', companyIds.toString());
		return this.httpClient.get<TableData>(url);
	}

	/**
	 * Fetch data from EasyTemp
	 * @param companyId Unique company identifier
	 */
	fetchRegisterOrganizationData(companyId: number): Observable<RegisterOrganization> {
		const url = this.GET_FETCH_COMPANY_DATA.replace('{companyId}', companyId.toString());
		return this.httpClient
			.get<ResponseModel<RegisterOrganization>>(url)
			.pipe(map((response: ResponseModel<RegisterOrganization>) => response.value));
	}

	/**
	 * Send data for registration
	 * @param companyId Unique company identifier
	 */
	registerOrganization(companyId: number): Observable<ActionResponse> {
		const url = this.REGISTER_ORGANIZATION_URL.replace('{companyId}', companyId.toString());
		return this.httpClient.post<ActionResponse>(url, null);
	}

	/**
	 * Get person mutated data
	 * @param absenceCode
	 */
	getPersonMutated(absenceCode: string): Observable<PersonMutated> {
		const url = this.GET_PERSON_MUTATED.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode);
		return this.httpClient.get<PersonMutatedServerData>(url).pipe(
			map((value) => {
				return {
					...value.personMutated,
					validAsOf: parseDate(value.personMutated.validAsOf),
					withdrawalDate: parseDate(value.personMutated.withdrawalDate),
				};
			})
		);
	}

	/**
	 * Get certificate request ID from Kle database
	 * @param companyId Company unique identifier
	 */
	getCertificateRequestId(companyId: number): Observable<string> {
		const url = this.GET_CERTIFICATE_REQUEST_ID.replace('{companyId}', companyId.toString());
		return this.httpClient.get<string>(url);
	}

	/**
	 * Get result for company registration
	 * @param companyId Company unique identifier
	 */
	getResult(companyId: number): Observable<GetResultResponse> {
		const url = this.GET_RESULT.replace('{companyId}', companyId.toString());
		return this.httpClient.post<ResponseModel<GetResultResponse>>(url, null).pipe(
			map((response: ResponseModel<GetResultResponse>) => {
				return new GetResultResponse(response.success, response.errors, response.value?.password, response.value?.expectedAvailability);
			})
		);
	}

	/**
	 * Sent SUA sign certificate request
	 * @param companyId Company unique identifier
	 * @param password Password from registration
	 */
	signCertificate(companyId: number, password: string): Observable<ResponseModel<any>> {
		return this.httpClient.post<ResponseModel<any>>(this.SIGN_CERTIFICATE_URL, { companyId, password });
	}

	/**
	 * Sends SUA renew certificate request
	 * @param companyId Company unique identifier
	 */
	renewCertificate(companyId: number): Observable<ResponseModel<any>> {
		return this.httpClient.post<ResponseModel<any>>(this.RENEW_CERTIFICATE_URL, { companyId });
	}

	/**
	 * User generate accident certificate after declare incident
	 * @param absenceCode unique Absence identifier
	 */
	generateAccidentCertificate(absenceCode: string): Observable<HttpResponse<Blob>> {
		const url = this.GENERATE_ACCIDENT_CERTIFICATE.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode);
		return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
	}

	/**
	 * User generate pharmacy certificate after declare incident
	 * @param absenceCode unique Absence identifier
	 */
	generatePharmacyCertificate(absenceCode: string): Observable<HttpResponse<Blob>> {
		const url = this.GENERATE_PHARMACY_CERTIFICATE.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode);
		return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
	}

	checkActionHistory(absenceCode: string): Observable<CheckActionHistory> {
		const url = this.CHECK_ACTION_HISTORY.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode);
		return this.httpClient.get<ResponseModel<CheckActionHistory>>(url).pipe(
			filter((response) => response.value != null),
			map((response): CheckActionHistory => {
				return {
					...response.value,
					timetable: response.value.timetable,
					employmentContract: response.value.employmentContract,
				};
			})
		);
	}

	getEmployeeContractDocument(absenceCode: string) {
		const url = this.GET_EMPLOYEE_CONTRACT_DOCUMENT.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode);
		return this.httpClient.post(url, null);
	}

	getEmployeeTimetableDocument(absenceCode: string): Observable<ActionResponse> {
		const url = this.GET_EMPLOYEE_TIMETABLE_DOCUMENT.replace(this.ABSENCE_CODE_PLACEHOLDER, absenceCode);
		return this.httpClient.post<ActionResponse>(url, null);
	}
}
