import { ChartData } from '../../shared/model/chart/chart-data';
import { StackedChartData } from '../../shared/model/chart/stacked-chart-data';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DashboardChartDataTransform {
	public transform = (statistic: any) => {
		return {
			pieChartData: [
				new ChartData('activeWithoutProlongations', statistic.total.active),
				new ChartData('activeWithProlongations', statistic.total.closed),
				new ChartData('archived', statistic.total.archived),
			],
			barChartData: [
				this.createStackedData('january', statistic.last12Months[1]),
				this.createStackedData('february', statistic.last12Months[2]),
				this.createStackedData('march', statistic.last12Months[3]),
				this.createStackedData('april', statistic.last12Months[4]),
				this.createStackedData('may', statistic.last12Months[5]),
				this.createStackedData('june', statistic.last12Months[6]),
				this.createStackedData('july', statistic.last12Months[7]),
				this.createStackedData('august', statistic.last12Months[8]),
				this.createStackedData('september', statistic.last12Months[9]),
				this.createStackedData('october', statistic.last12Months[10]),
				this.createStackedData('november', statistic.last12Months[11]),
				this.createStackedData('december', statistic.last12Months[12]),
			],
		};
	};

	private createStackedData(name: string, data): StackedChartData {
		return new StackedChartData(name, [
			{ name: 'active', value: data.active },
			{ name: 'archived', value: data.archived },
		]);
	}
}
