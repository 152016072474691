<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<nc-accordion>
				<nc-accordion-item title="registerOrganization">
					<div fxLayout="row">
						<ea-container fxFlex="50" title="contactPerson">
							<div fxLayout="column" fxLayoutGap="10px">
								<nc-input
									id="contactName"
									[formGroup]="formGroup"
									formControlName="contactName"
									label="contactName"
									isReadonly="true"
								></nc-input>
								<nc-input
									id="emailAddress"
									[formGroup]="formGroup"
									formControlName="emailAddress"
									label="emailAddress"
									isReadonly="true"
								></nc-input>
								<nc-input
									id="phoneNumber"
									[formGroup]="formGroup"
									formControlName="phoneNumber"
									label="phoneNumber"
									isReadonly="true"
								></nc-input>
								<nc-input
									id="mobilePhoneNumber"
									[formGroup]="formGroup"
									formControlName="mobilePhoneNumber"
									label="mobilePhoneNumber"
									isReadonly="true"
								></nc-input>
							</div>
						</ea-container>
						<ea-container fxFlex="50" title="company">
							<div fxLayout="column" fxLayoutGap="10px">
								<nc-input
									id="companyName"
									[formGroup]="formGroup"
									formControlName="companyName"
									label="companyName"
									isReadonly="true"
								></nc-input>
								<nc-input
									id="complementaryLine"
									[formGroup]="formGroup"
									formControlName="complementaryLine"
									label="complementaryLine"
									isReadonly="true"
								>
								</nc-input>
								<nc-input id="uid" [formGroup]="formGroup" formControlName="uid" label="uid" isReadonly="true"></nc-input>
							</div>
						</ea-container>
					</div>
					<div fxLayout="row">
						<ea-container fxFlex="50" title="insurance">
							<div fxLayout="column" fxLayoutGap="10px">
								<nc-input
									id="insuranceId"
									[formGroup]="formGroup"
									formControlName="insuranceId"
									label="insuranceId"
									isReadonly="true"
								></nc-input>
								<nc-input
									id="insuranceCompanyName"
									[formGroup]="formGroup"
									formControlName="insuranceCompanyName"
									label="insuranceCompanyName"
									isReadonly="true"
								>
								</nc-input>
								<nc-input
									id="customerIdentity"
									[formGroup]="formGroup"
									formControlName="customerIdentity"
									label="customerIdentity"
									isReadonly="true"
								>
								</nc-input>
								<nc-input
									id="contractIdentity"
									[formGroup]="formGroup"
									formControlName="contractIdentity"
									label="contractIdentity"
									isReadonly="true"
								>
								</nc-input>
							</div>
						</ea-container>
					</div>
					<div fxLayout="row" fxLayoutGap="5px">
						<nc-submit-button id="fetch-data" buttonLabel="fetch" (click)="fetch()" dialogLabel="processing"></nc-submit-button>

						<nc-submit-button
							id="register-company"
							buttonLabel="register"
							(click)="submit()"
							[formGroup]="formGroup"
							dialogLabel="processing"
						>
						</nc-submit-button>
					</div>
				</nc-accordion-item>
				<nc-accordion-item title="getResult">
					<div fxLayout="row">
						<nc-submit-button id="get-result" buttonLabel="getResult" (click)="getResult()" dialogLabel="processing"></nc-submit-button>
					</div>
				</nc-accordion-item>
				<nc-accordion-item title="signCertificate">
					<ea-sign-certificate [companyId]="this.companyId"></ea-sign-certificate>
				</nc-accordion-item>
				<nc-accordion-item title="checkInteroperability">
					<ea-interoperability [companyId]="this.companyId"></ea-interoperability>
				</nc-accordion-item>
				<nc-accordion-item title="renewCertificate">
					<div fxLayout="row">
						<nc-submit-button
							id="renew-certificate"
							buttonLabel="renewCertificate"
							(buttonClick)="renewCertificate()"
							[formGroup]="formGroup"
							dialogLabel="processing"
						>
						</nc-submit-button>
					</div>
				</nc-accordion-item>
			</nc-accordion>
		</nc-form-content>
	</nc-main-content>
</form>
