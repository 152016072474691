import { Injectable } from '@angular/core';
import { UserCredentials } from '../model/user-credentials';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoginResult } from '../model/login-result';
import { ForgotPasswordResult } from '../model/forgot-password-result';
import { ActionResponse } from 'nc-utils';
import { RefreshTokenResponse } from '../model/refresh-token-response';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly LOGIN_URL = `${this.API_URL}/login`;
	private readonly RESEND_URL = `${this.API_URL}/login/2fa-resend`;
	private readonly VERIFY_LOGIN_URL = `${this.API_URL}/login/2fa-verify`;
	private readonly FORGOT_PASSWORD_URL = `${this.API_URL}/password/forgot`;
	private readonly RESET_PASSWORD_URL = `${this.API_URL}/password/reset`;
	private readonly VERIFY_FORGOT_PASSWORD_URL = `${this.API_URL}/password/generate-new`;
	private readonly REFRESH_TOKEN_URL = `${this.API_URL}/refresh-token`;

	/**
	 * Performs login
	 * @param credentials user credentials
	 */
	login(credentials: UserCredentials): Observable<LoginResult> {
		return this.httpClient.post<LoginResult>(this.LOGIN_URL, credentials);
	}

	/**
	 * Submits forgot password request
	 * @param username name of user
	 */
	forgotPassword(username: string): Observable<ForgotPasswordResult> {
		const request = { model: { username } };
		return this.httpClient.post<ForgotPasswordResult>(this.FORGOT_PASSWORD_URL, request);
	}

	/**
	 * Submits verify forgot password
	 * @param token token generated for forgot password request
	 * @param verificationCode code for verification
	 */
	verifyForgotPassword(token: string, verificationCode: string): Observable<ActionResponse> {
		const request = { model: { token, verificationCode } };
		return this.httpClient.post<ActionResponse>(this.VERIFY_FORGOT_PASSWORD_URL, request);
	}

	/**
	 * 2 step verification for login
	 * @param username name of user
	 * @param code verification code
	 */
	verifyLogin(username: string, code: string): Observable<ActionResponse> {
		const request = { username, code };
		return this.httpClient.post<ActionResponse>(this.VERIFY_LOGIN_URL, request);
	}

	/**
	 * Submits resend of verification code
	 * @param username name of user
	 */
	resend(username: string): Observable<ActionResponse> {
		const request = { username };
		return this.httpClient.post<ActionResponse>(this.RESEND_URL, request);
	}

	/**
	 * Submits password reset request
	 * @param password new password
	 * @param repeatedPassword repeated new password
	 * @param token token
	 */
	resetPassword(password: string, repeatedPassword: string, token: string): Observable<ActionResponse> {
		const request = { password, repeatedPassword, token };
		return this.httpClient.post<ActionResponse>(this.RESET_PASSWORD_URL, request);
	}

	refreshToken(token: string): Observable<RefreshTokenResponse> {
		return this.httpClient.post<RefreshTokenResponse>(this.REFRESH_TOKEN_URL, {
			refreshToken: `Bearer ${token}`,
		});
	}
}
