import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NoteModel } from '../model/note.model';
import { TaskModel } from '../model/task.mode';
import { SaveResponse } from 'nc-utils';
import { MilestoneModel } from '../model/milestone.model';
import { AbsenceContextModel } from '../../shared/model/absence-context.model';
import { SaveRequestFactory } from './save-request.factory';
import { map } from 'rxjs/operators';
import { Option } from 'nc-shared';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { TableData } from 'nc-datatable/lib/model/table-data';

@Injectable({
	providedIn: 'root',
})
export class TaskService {
	constructor(private httpClient: HttpClient, private saveRequestFactory: SaveRequestFactory, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly GET_TASK_URL = `${this.API_URL}/tasks`;
	private readonly GET_MILESTONE_URL = `${this.API_URL}/milestones/`;
	private readonly GET_NOTE_URL = `${this.API_URL}/notes`;
	private readonly GET_NUMBER_OF_DAYS_URL = `${this.API_URL}/dashboard/number-of-days/`;
	private readonly GET_ABSENCE_URL = `${this.API_URL}/absences/`;

	/**
	 * Get open tasks for case
	 * @param code Unique identifier for case
	 */
	getOpenTasks(code: string): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<TableData>(`${this.GET_TASK_URL}/case/${code}/open`).pipe(map((response) => response.records));
	}

	/**
	 * Get done tasks for case
	 * @param code Unique identifier for case
	 */
	getDoneTasks(code: string): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<TableData>(`${this.GET_TASK_URL}/case/${code}/done`).pipe(map((response) => response.records));
	}

	/**
	 * Get open milestones for case
	 * @param code Unique identifier for case
	 * @param numberOfDays List of number of days
	 */
	getOpenMilestones(code: string, numberOfDays: number[]): Observable<{ [key: string]: string }[]> {
		const url = `${this.GET_MILESTONE_URL}case/${code}/${numberOfDays}/open`;
		return this.httpClient.get<{ [key: string]: string }[]>(url);
	}

	/**
	 * Get done milestones for case
	 * @param code Unique identifier for case
	 * @param numberOfDays List of number of days
	 */
	getDoneMilestones(code: string, numberOfDays: number[]): Observable<{ [key: string]: string }[]> {
		const url = `${this.GET_MILESTONE_URL}case/${code}/${numberOfDays}/done`;
		return this.httpClient.get<{ [key: string]: string }[]>(url);
	}

	/**
	 * Get notes for case
	 * @param code Unique identifier for case
	 */
	getNotes(code: string): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(`${this.GET_NOTE_URL}/case/${code}`);
	}

	/**
	 * Get note
	 * @param id Unique identifier for note
	 */
	getNote(id: number): Observable<NoteModel> {
		return this.httpClient.get<NoteModel>(this.GET_NOTE_URL + '/' + id);
	}

	/**
	 * Get task
	 * @param id Unique identifier for task
	 */
	getTask(id: number): Observable<TaskModel> {
		return this.httpClient.get<TaskModel>(this.GET_TASK_URL + '/' + id);
	}

	/**
	 * Get absence which is selected
	 * @param code Unique identifier for absence
	 */
	getCurrentAbsence(code: string): Observable<AbsenceContextModel> {
		return this.httpClient.get<AbsenceContextModel>(`${this.GET_ABSENCE_URL}${code}/context`);
	}

	/**
	 * Save note
	 * @param data Form value for note
	 */
	saveNote(data: NoteModel): Observable<SaveResponse> {
		const request = this.saveRequestFactory.createNote(data);
		return this.httpClient.post<SaveResponse>(this.GET_NOTE_URL, request);
	}

	/**
	 * Save task
	 * @param data Form value for task
	 */
	saveTask(data: TaskModel): Observable<SaveResponse> {
		const request = this.saveRequestFactory.createTask(data);
		return this.httpClient.post<SaveResponse>(this.GET_TASK_URL, request);
	}

	/**
	 * Gets all number of days for select
	 */
	getNumberOfDays(): Observable<Option[]> {
		const url = this.GET_NUMBER_OF_DAYS_URL + false;
		return this.httpClient.get<number[]>(url).pipe(
			map((list) =>
				list.map((num) => {
					return { value: num, text: num.toString() };
				})
			)
		);
	}

	/**
	 * Get done milestone
	 * @param id Unique identifier for milestone
	 */
	getDoneMilestone(id: number): Observable<MilestoneModel> {
		return this.httpClient.get<MilestoneModel>(this.GET_MILESTONE_URL + id);
	}

	/**
	 * Get open milestone
	 * @param milestoneSettingsId Unique identifier for milestoneSettgins
	 * @param absenceCode Unique identifier for absence
	 */
	getOpenMilestone(milestoneSettingsId: number, absenceCode: string): Observable<MilestoneModel> {
		const url = `${this.GET_MILESTONE_URL}settings/${milestoneSettingsId}/${absenceCode}`;
		return this.httpClient.get<MilestoneModel>(url);
	}

	/**
	 * Get done milestone
	 * @param milestone Milestone model
	 */
	doneMilestone(milestone: MilestoneModel): Observable<SaveResponse> {
		const model = this.saveRequestFactory.createDoneMilestoneModel(milestone);
		return this.httpClient.post<SaveResponse>(`${this.GET_MILESTONE_URL}done`, model);
	}

	/**
	 * Call function for undone milestone
	 * @param id Unique identifier for milestone
	 */
	undoneMilestone(id: number): Observable<any> {
		return this.httpClient.delete<any>(`${this.GET_MILESTONE_URL}${id}/undone`);
	}

	/**
	 *  Get documents for milestone
	 * @param absenceId Unique identifier for Absence
	 * @param settingsId Unique identifier for MilestoneSettings
	 */
	getDocumentsForCheckList(absenceId: number, settingsId: number): Observable<{ [key: string]: string }[]> {
		const url = `${this.GET_MILESTONE_URL}${absenceId}/${settingsId}/documents`;
		return this.httpClient.get<{ [key: string]: string }[]>(url);
	}
}
