import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Option } from 'nc-shared';
import { SelectModel } from '../../shared/model/select.model';
import { map } from 'rxjs/operators';
import { AuthorityOperationModel } from '../model/authority-operation.model';
import { AuthorityResponseModel } from '../model/authority-response.model';
import { RequestModelFactory } from '../model/request-model.factory';
import { DocumentTypeModel } from '../model/document-type.model';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class AccessAssignmentService {
	constructor(private httpClient: HttpClient, private requestModelFactory: RequestModelFactory, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly AUTHORITY_URL = `${this.API_URL}/authorities/operation`;
	private readonly DOCS_URL = `${this.API_URL}/document-types/access`;
	private readonly ROLES_URL = `${this.API_URL}/authorities/options/`;

	/**
	 * Gets select options.
	 */
	getSelectOptions(url: string): Observable<Option[]> {
		return this.httpClient.get<SelectModel>(this.ROLES_URL + url).pipe(map((response) => response.options));
	}

	/**
	 * Operation method for getting, adding and removing authorities
	 * @param authorityOperation authority operation model
	 */
	operation(authorityOperation: AuthorityOperationModel): Observable<AuthorityResponseModel> {
		const data = this.requestModelFactory.create(authorityOperation);
		return this.httpClient.post<AuthorityResponseModel>(this.AUTHORITY_URL, data);
	}

	/**
	 * Operation method for getting, adding and removing authorities for docs
	 * @param documentTypeModel document type model
	 */
	operationForDocs(documentTypeModel: DocumentTypeModel): Observable<AuthorityResponseModel> {
		return this.httpClient.post<AuthorityResponseModel>(this.DOCS_URL, documentTypeModel);
	}
}
