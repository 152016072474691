import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseComponent, Option } from 'nc-shared';
import { FormArray, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { DialogService, EnumUtils } from 'nc-utils';
import { ActiveAbsence } from '../../../../model/active-absence';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { AbsencePageAction, AbsenceSelect } from '../../../../state/action';
import { AbsenceTableColumns } from '../../../../model/absence-table-columns';
import { EmployeeDetailsComponent } from '../../../../../integration/component/employee-details/employee-details.component';
import { Absence } from '../../../../model/absence';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EmploymentSubjectEnumeration } from '../../../../model/enumeration/employment-subject.enumeration';
import { EmploymentRegistrationEnumeration } from '../../../../model/enumeration/employment-registration.enumeration';
import { SettingsPageAction } from '../../../../../state/settings/action';
import { FlagEnumeration } from '../../../../../shared/enumeration/flag.enumeration';
import { AbsenceTypeEnumeration } from '../../../../../shared/enumeration/absence-type.enumeration';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Company } from '../../../../../shared/model/company';
import { ExistingAbsenceService } from '../../../../service/existing-absence.service';
import { Employee } from '../../../../../shared/model/employee';
import { AbsenceDeclarationTypeEnumeration } from '../../../../model/enumeration/absence-declaration-type.enumeration';
import { UserSelect } from '../../../../../user/state/action';
import { PermissionEnumeration } from '../../../../../shared/enumeration/permission.enumeration';
import { AbsenceStatusEnumeration } from '../../../../model/enumeration/absence-status.enumeration';

@Component({
	selector: 'ea-hr-absence',
	templateUrl: './hr-absence.component.html',
	styleUrls: ['./hr-absence.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HrAbsenceComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	absence$: Observable<Absence>;
	company$: Observable<Company>;
	employee$: Observable<Employee>;

	otherAbsences: ActiveAbsence[];
	additionalColumns: DatatableColumn[];
	incapacities: FormArray;
	documents: FormArray;
	linkedAbsence$: Observable<TableData>;
	shareInformation$: Observable<TableData>;
	columns = AbsenceTableColumns;
	absenceCode: string;
	companyId: number;
	yesNo$: Observable<Option[]>;
	shareInformationFilterConfig: FilterConfig;
	linkedRequestFilterConfig: FilterConfig;
	employeeId: number;
	isReadOnly: boolean;
	isKleEnabled = false;
	isBranchHr: boolean = false;
	absenceState: string;
	caseWasOpen = false;

	employmentSubject: Option[];
	absenceTypeOptions: Option[];
	employmentRegistration: Option[];

	EMPLOYEE_ID = 'employeeId';
	ABSENCE_DECLARATION_TYPE = AbsenceDeclarationTypeEnumeration;
	ABSENCE_TYPE_ENUMERATION = AbsenceTypeEnumeration;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private dialogService: DialogService,
		private existingAbsenceService: ExistingAbsenceService
	) {
		super();
		this.company$ = this.store.select(AbsenceSelect.getCompany);
		this.employee$ = this.store.select(AbsenceSelect.getEmployee);

		this.store
			.select(UserSelect.getPermissions)
			.pipe(takeUntil(this.destroy$))
			.subscribe((permissions) => {
				this.isBranchHr = permissions.includes(PermissionEnumeration.BRANCH_HR);
			});

		this.route.params.subscribe((params) => {
			this.absenceCode = params.code;
			this.store.dispatch(AbsencePageAction.fetchAbsence({ code: params.code }));
		});

		this.shareInformationFilterConfig = {
			showFilter: false,
			filterValues: {},
		};

		this.linkedRequestFilterConfig = {
			showFilter: false,
			filterValues: {},
		};
	}

	ngOnInit(): void {
		this.incapacities = this.formGroup.controls.incapacities as FormArray;
		this.documents = this.formGroup.controls.documents as FormArray;

		this.yesNo$ = EnumUtils.toObservableItems(FlagEnumeration);
		this.absenceTypeOptions = EnumUtils.toItems(AbsenceTypeEnumeration);
		this.employmentSubject = EnumUtils.toItems(EmploymentSubjectEnumeration);
		this.employmentRegistration = EnumUtils.toItems(EmploymentRegistrationEnumeration);

		this.linkedAbsence$ = this.store.select(AbsenceSelect.getLinkedAbsence);
		this.absence$ = this.store.select(AbsenceSelect.getAbsence);
		this.shareInformation$ = this.store.select(AbsenceSelect.getShareInformation);
		this.additionalColumns = [
			ColumnBuilder.create('uploadedBy', 'uploadedBy'),
			ColumnBuilder.create('comment', 'comment'),
			ColumnBuilder.createDate('uploadedOn', 'uploadedOn'),
		];

		this.absence$
			.pipe(
				filter((absence) => absence.id > 0),
				withLatestFrom(this.employee$, this.company$),
				takeUntil(this.destroy$)
			)
			.subscribe(([absence, employee, company]) => {
				this.isKleEnabled = company?.isKleEnabled;
				this.companyId = absence.companyId;
				this.otherAbsences = absence.activeCases;
				this.absenceState = absence.status;
				this.employeeId = absence[this.EMPLOYEE_ID];
				this.caseWasOpen = absence.caseWasOpen;
				this.existingAbsenceService.initializeForm(this.formGroup, absence, employee, company);
			});

		combineLatest([
			this.formGroup.controls.isArchived.valueChanges,
			this.formGroup.controls.isClosed.valueChanges,
			this.formGroup.controls.status.valueChanges,
		])
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				([isArchived, isClosed, status]) =>
					(this.isReadOnly = isArchived || isClosed || (status == AbsenceStatusEnumeration.PENDING_APPROVAL.code && this.isBranchHr))
			);
	}

	employeeDetails(): void {
		const dialogConfig = { data: { insuredPersonId: this.employeeId } };
		this.dialogService.open(EmployeeDetailsComponent, dialogConfig);
	}

	messageClicked = (rowData) => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/annex/${rowData.messageType.toLowerCase()}/${rowData.code}` }));
	};

	shareInformation(rowData): void {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/share-information/${rowData.id}` }));
	}
}
