import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { SettingsPageAction } from '../../../state/settings/action';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'ea-company-details-footer',
	templateUrl: './company-details-footer.component.html',
	styleUrls: ['./company-details-footer.component.scss'],
})
export class CompanyDetailsFooterComponent {
	@Input() formGroup: FormGroup;
	@Input() onSave: () => void;
	@Input() onBack: () => void;
	@Input() onRefresh: () => void;
	@Input() showRefreshButton = false;
	@Input() isHrUser = false;
	@Input() showRegisterOrganizationButton = false;

	companyId: number;

	constructor(private location: Location, private store: Store, private route: ActivatedRoute) {
		this.onBack = this.onBack ?? this.back;
		this.route.params.subscribe((params) => {
			this.companyId = params.id;
		});
	}

	private back(): void {
		this.location.back();
	}

	onRegisterOrganization(): void {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/kle/register-organization/${this.companyId}` }));
	}
}
