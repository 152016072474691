import { createReducer, on } from '@ngrx/store';
import { MILESTONE_SETTINGS_INITIAL_STATE, MilestoneSettingsState } from './milestone-settings.state';
import { MilestoneSettingsApiAction, MilestoneSettingsPageAction } from './action';

export const milestoneSettingsReducer = createReducer<MilestoneSettingsState>(
	MILESTONE_SETTINGS_INITIAL_STATE,
	on(MilestoneSettingsApiAction.getAllMilestoneSettingsSuccess, (state, action): MilestoneSettingsState => {
		return {
			...state,
			milestoneSettings: { records: action.milestoneSettings },
		};
	}),
	on(MilestoneSettingsApiAction.getSingleMilestoneSettingsSuccess, (state, action): MilestoneSettingsState => {
		return {
			...state,
			currentMilestoneSettings: action.milestoneSettings,
		};
	}),
	on(MilestoneSettingsApiAction.getDocumentsCheckListSuccess, (state, action): MilestoneSettingsState => {
		return {
			...state,
			documentsCheckList: { records: action.documentsCheckList },
		};
	}),
	on(MilestoneSettingsPageAction.clearCurrentMilestoneSetting, (state): MilestoneSettingsState => {
		return {
			...state,
			currentMilestoneSettings: null,
		};
	})
);
