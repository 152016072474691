import { EnumItem } from 'nc-utils';

export enum ClassOfInsuranceTypeEnum {
	ACCIDENT_AT_WORK = 'accidentAtWork',
	ACCIDENT_AT_LEISURE = 'accidentAtLeisure',
}

export const ClassOfInsuranceTypeEnumItems = {
	ACCIDENT_AT_WORK: new EnumItem('ACCIDENT_AT_WORK', 'accidentAtWork'),
	ACCIDENT_AT_LEISURE: new EnumItem('ACCIDENT_AT_LEISURE', 'accidentAtWork'),
};
