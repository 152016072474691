import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Option } from 'nc-shared';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { Observable } from 'rxjs';
import { ApiDateFormat, EnumUtils, parseDate } from 'nc-utils';
import { ExpectedIncapacityToWorkEnum } from '../../model/enums/expected-incapacity-to-work.enum';
import { Moment } from 'moment/moment';
import { filter } from 'rxjs/operators';
import { Absence } from '../../../absence/model/absence';
import { AbsenceSelect } from '../../../absence/state/action';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { OK, ONE_FIELD_IS_REQUIRED } from '../../../shared/utils/form-validation.utils';

@Component({
	selector: 'ea-take-work-up-fully',
	templateUrl: './take-work-up-fully.component.html',
	styleUrls: ['./take-work-up-fully.component.scss'],
})
export class TakeWorkUpFullyComponent implements OnInit {
	@Input() formGroup: FormGroup;

	absence$: Observable<Absence>;
	incapacities: FormArray;
	lastIncapacityDate: Moment | null = null;

	incapacityToWorkEnum$: Observable<Option[]>;
	takeWorkUpFullyFormGroup = this.formBuilder.group(
		{
			takeWorkUpFullyDate: [null as Moment | null, [this.takeWorkUpFullyDateValidator()]],
			expectedLastDayOfIncapacityToWork: [null as Moment | null],
			expectedIncapacityToWork: [null],
			noIncapacityForWork: false,
		},
		{
			validators: this.takeWorkUpFullyValidator(),
		}
	);

	private takeWorkUpFullyDateValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;
			if (!value || !this.lastIncapacityDate || moment(value).diff(moment(this.lastIncapacityDate), 'days') === 1) {
				return null;
			} else {
				return { takeWorkUpFullyDateOneDayAfterValidTo: true };
			}
		};
	}

	takeWorkUpFullyValidator(): ValidatorFn {
		return (): ValidationErrors | null => {
			const takeWorkUpFullyDateControl = this.takeWorkUpFullyFormGroup?.controls.takeWorkUpFullyDate;
			const expectedLastDayOfIncapacityToWorkControl = this.takeWorkUpFullyFormGroup?.controls.expectedLastDayOfIncapacityToWork;
			const expectedIncapacityToWorkControl = this.takeWorkUpFullyFormGroup?.controls.expectedIncapacityToWork;
			const noIncapacityForWorkControl = this.takeWorkUpFullyFormGroup?.controls.noIncapacityForWork;
			if (
				!takeWorkUpFullyDateControl?.value &&
				!expectedLastDayOfIncapacityToWorkControl?.value &&
				!expectedIncapacityToWorkControl?.value &&
				!noIncapacityForWorkControl?.value
			) {
				return ONE_FIELD_IS_REQUIRED;
			}

			return OK;
		};
	}

	constructor(private store: Store, private formBuilder: FormBuilder) {
		this.absence$ = this.store.select(AbsenceSelect.getAbsence);
	}

	ngOnInit(): void {
		this.incapacityToWorkEnum$ = EnumUtils.toObservableItems(ExpectedIncapacityToWorkEnum);
		this.formGroup.addControl(KleFormGroupEnum.TAKE_WORK_UP_FULLY, this.takeWorkUpFullyFormGroup);
		this.absence$.pipe(filter((absence) => absence.id > 0)).subscribe((absence) => {
			const incapacity = absence.incapacities.at(-1);
			this.lastIncapacityDate = parseDate(incapacity?.validTo.toString());
			const takeWorkUpFullyDate = parseDate(incapacity?.takeWorkUpFullyDate?.toString(), ApiDateFormat);
			this.takeWorkUpFullyFormGroup.controls.takeWorkUpFullyDate.setValue(takeWorkUpFullyDate);
		});
	}
}
