<ea-container cssClass="margin-top-15">
	<div *ngIf="data$ | async as data">
		<nc-submit-button
			id="send-settlements"
			*ngIf="!readOnly && data.records.length > 0"
			buttonLabel="sendToEasyTemp"
			(click)="sendSettlements()"
			dialogLabel="processing"
		></nc-submit-button>
	</div>
	<nc-datatable id="table-settlements" [rowClick]="preview" [columns]="getColumns()" [data$]="data$" [filterConfig]="filterConfig"></nc-datatable>
</ea-container>
<ea-container *ngIf="settlement$ | async as settlement">
	<div fxLayout="row">
		<div fxFlex="40">
			<p>{{ settlement.incidentData.companyName }} {{ settlement.incidentData.postBox }} {{ settlement.incidentData.city }}</p>
			<table>
				<tr>
					<td>{{ 'settlement.contractId' | translate }}</td>
					<td>{{ settlement.incidentData.contractId }}</td>
				</tr>
				<tr>
					<td>{{ 'settlement.incidentCaseId' | translate }}:</td>
					<td>{{ settlement.incidentData.incidentCaseId }}</td>
				</tr>
				<tr>
					<td>{{ 'settlement.incidentDate' | translate }}:</td>
					<td>{{ settlement.incidentData.incidentDate }}</td>
				</tr>
			</table>
		</div>
		<div fxFlex="30">
			<p>{{ settlement.employeeData.firstName }} {{ settlement.employeeData.lastName }} {{ settlement.employeeData.dateOfBirth }}</p>
			<p>{{ settlement.employeeData.workStation }}</p>
			<p>{{ 'settlement.coverage' | translate }} {{ settlement.employeeData.coverage }}</p>
			<p>{{ 'settlement.annualEarnings' | translate }} {{ settlement.employeeData.annualEarnings }}</p>
			<p>{{ 'settlement.coveredDailyAllowance' | translate }} {{ settlement.employeeData.coveredDailyAllowance }}</p>
		</div>
		<div fxFlex="30">
			<p>{{ settlement.institutionAddress?.institutionName }}</p>
			<p>{{ settlement.institutionAddress?.city }}</p>
			<p>{{ settlement.institutionAddress?.street }}</p>
			<p>{{ settlement.institutionAddress?.postbox }}</p>
			<p>{{ settlement.institutionAddress?.zipCode }}</p>
		</div>
	</div>
	<div fxLayout="row">
		<div fxFlex="20" class="general-info">
			<table>
				<tr>
					<td>{{ 'settlement.id' | translate }}</td>
					<td>{{ settlement.generalInformation.id }}</td>
				</tr>
				<tr>
					<td>{{ 'settlement.date' | translate }}</td>
					<td>{{ settlement.generalInformation.settlementDate }}</td>
				</tr>
			</table>
		</div>
		<div fxFlex="50"></div>
		<div fxFlex="30">
			<p>{{ 'settlement.contactReference' | translate }}</p>
			<p>{{ 'settlement.contactName' | translate }} {{ settlement.contactInsurance.name }}</p>
			<p>{{ 'settlement.contactPhone' | translate }} {{ settlement.contactInsurance.phoneNumber }}</p>
			<p>{{ 'settlement.contactEmail' | translate }} {{ settlement.contactInsurance.emailAddress }}</p>
		</div>
	</div>
	<div fxLayout="row">
		<div>
			<table class="indemnifications-table" aria-describedby="indemnifications-table">
				<tr>
					<th>{{ 'settlement.indemnification' | translate }}</th>
					<th>{{ 'settlement.percentage' | translate }}</th>
					<th>{{ 'settlement.indemnificationId' | translate }}</th>
					<th>{{ 'settlement.incapacityToWork' | translate }}</th>
					<th>{{ 'settlement.from' | translate }}</th>
					<th>{{ 'settlement.to' | translate }}</th>
					<th>{{ 'settlement.quantityOfDays' | translate }}</th>
					<th>{{ 'settlement.waitingDays' | translate }}</th>
					<th>{{ 'settlement.coveringDays' | translate }}</th>
					<th>{{ 'settlement.effectiveDailyAllowance' | translate }}</th>
					<th>{{ 'settlement.amountBeforeReduction' | translate }}</th>
					<th>{{ 'settlement.reduction' | translate }}</th>
					<th>{{ 'settlement.reductionInstitutionFavour' | translate }}</th>
					<th>{{ 'settlement.reductionCompanyFavour' | translate }}</th>
				</tr>
				<ng-container *ngFor="let indemnification of settlement.indemnifications">
					<tr>
						<td>{{ indemnification.productCategory }}</td>
						<td></td>
						<td>{{ indemnification.id }}</td>
						<td>{{ indemnification.incapacityToWork }}</td>
						<td>{{ indemnification.from }}</td>
						<td>{{ indemnification.until }}</td>
						<td>{{ indemnification.quantityOfDays }}</td>
						<td>{{ indemnification.waitingDays }}</td>
						<td>{{ indemnification.coveringDays }}</td>
						<td>{{ indemnification.effectiveDailyAllowance }}</td>
						<td>{{ indemnification.amountBeforeReduction }}</td>
						<td>{{ indemnification.reduction }}</td>
						<td>{{ indemnification.favourInstitution }}</td>
						<td>{{ indemnification.favourCompany }}</td>
					</tr>
				</ng-container>
			</table>
		</div>
	</div>
	<div fxLayout="row" class="total-balance-part">
		<div fxFlex="95">
			<strong>{{ 'settlement.totalBalance' | translate }}</strong>
		</div>
		<div>
			<strong>{{
				settlement.generalInformation.favourCompany
					? settlement.generalInformation.favourCompany
					: '-' + settlement.generalInformation.favourInstitution
			}}</strong>
		</div>
	</div>
	<ng-container *ngFor="let comment of settlement.comments">
		<p>{{ comment }}</p>
	</ng-container>
	<div fxLayout="row" fxLayoutAlign="end">
		<nc-submit-button id="generate-pdf" buttonLabel="Generate PDF" (click)="generatePDF()" dialogLabel="processing"></nc-submit-button>
	</div>
</ea-container>
