<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="archived-cases-container">
				<nc-datatable
					[id]="'archived-cases'"
					[rowClick]="onRowClick"
					[columns]="labelColumns"
					[data$]="labels$"
					[filterConfig]="filterConfig"
				>
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
</div>
