export class DocumentAttachment {
	id: number;
	name: string;
	tableId: number;
	docmanId: number;
	nameWithExtension: string;
	extension: string;
	documentTypeTranslation: string;
	documentType: string;
	documentId: number;
	file: File;
	uploadedOn: string;
	uploadedBy: string;
	comment: string;
	contentType: string;
}
