<form [formGroup]="formGroup" fxFill fxLayout="column">
	<div id="responsible-user-container">
		<nc-select
			[id]="'responsible-user'"
			[searchTextId]="'responsible-user-search'"
			[searchButtonId]="'responsible-user-search-clear'"
			[clearIconId]="'responsible-user-clear'"
			label="responsibleUser"
			formControlName="responsibleUser"
			[formGroup]="formGroup"
			[data$]="responsibleUsers$"
		>
		</nc-select>
	</div>
	<nc-main-content>
		<nc-form-content [center]="false">
			<ng-container *ngIf="!isMobile">
				<div fxLayout="column" fxLayoutGap="15px">
					<div fxLayout="row wrap" fxLayoutAlign="center">
						<nc-toggle-buttons
							*ngIf="!isMobile"
							[multiple]="true"
							[options]="numberOfDays$ | async"
							[formGroup]="formGroup"
							formControlName="numberOfDays"
						>
						</nc-toggle-buttons>
					</div>
					<ea-container [title]="'milestones'" id="milestones-container">
						<nc-datatable
							[id]="'milestones'"
							[rowClick]="onMilestoneRowClick"
							[columns]="milestonesColumns"
							[data$]="milestones$"
							[filterConfig]="filterConfigMilestoneTable"
						>
						</nc-datatable>
					</ea-container>
					<ea-container [title]="'openTasks'" id="tasks-container">
						<nc-datatable
							[id]="'tasks'"
							[rowClick]="onTaskRowClick"
							[columns]="tasksColumns"
							[data$]="tasks$"
							[filterConfig]="filterConfigTasksTable"
						>
						</nc-datatable>
					</ea-container>
					<ea-container [title]="'activeCases'" id="active-absences-container">
						<nc-datatable
							[id]="'active-cases'"
							[rowClick]="onAbsenceRowClick"
							[columns]="activeAbsencesColumns"
							[data$]="activeAbsences$"
							[filterConfig]="filterConfigAbsenceTable"
						>
						</nc-datatable>
					</ea-container>
				</div>
			</ng-container>
			<ng-container *ngIf="isMobile">
				<mat-tab-group mat-align-tabs="center">
					<mat-tab fxLayout="column" fxLayoutGap="10px">
						<ng-template mat-tab-label>
							<mat-icon [matTooltip]="'milestones' | translate" matTooltipPosition="right">flag</mat-icon>
						</ng-template>
						<div class="multi-select">
							<nc-select
								[id]="'number-of-days'"
								[searchTextId]="'number-of-days-search'"
								[searchButtonId]="'number-of-days-search-clear'"
								[clearIconId]="'number-of-days-clear'"
								*ngIf="isMobile"
								label="numberOfDays"
								formControlName="numberOfDays"
								[formGroup]="formGroup"
								[multiple]="true"
								[data$]="numberOfDays$"
							>
							</nc-select>
						</div>
						<nc-datatable
							[id]="'milestone'"
							[rowClick]="onMilestoneRowClick"
							[columns]="milestonesColumns"
							[data$]="milestones$"
							[filterConfig]="filterConfigMilestoneTable"
						>
						</nc-datatable>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon [matTooltip]="'openTasks' | translate" matTooltipPosition="right">task_alt</mat-icon>
						</ng-template>
						<nc-datatable
							[id]="'tasks'"
							[rowClick]="onTaskRowClick"
							[columns]="tasksColumns"
							[data$]="tasks$"
							[filterConfig]="filterConfigTasksTable"
						>
						</nc-datatable>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon [matTooltip]="'activeCases' | translate" matTooltipPosition="right">summarize</mat-icon>
						</ng-template>
						<nc-datatable
							[id]="'active-cases'"
							[rowClick]="onAbsenceRowClick"
							[columns]="activeAbsencesColumns"
							[data$]="activeAbsences$"
							[filterConfig]="filterConfigAbsenceTable"
						>
						</nc-datatable>
					</mat-tab>
				</mat-tab-group>
			</ng-container>
		</nc-form-content>
	</nc-main-content>
</form>
