import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TellcoConstants } from '../../environment-constants/tellco-constants';
import { EcareConstants } from '../../environment-constants/ecare-constants';
import { EnvironmentLoader } from '../../../core/config/environment-loader';

@Component({
	templateUrl: './version.component.html',
	styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
	appVersion = '';
	logo: string;

	constructor(private location: Location, private environmentLoader: EnvironmentLoader) {}

	ngOnInit(): void {
		const environment = this.environmentLoader.getEnvironment();
		this.logo = environment.theme === 'tellco-theme' ? TellcoConstants.PK_PRO_USER_URI : EcareConstants.LOGO_URI;
		this.appVersion = require('../../../../../package.json').version;
	}

	onBack = (): void => {
		this.location.back();
	};
}
