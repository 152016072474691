import { EnumItem } from 'nc-utils';

export const TypeOfInjuryEnum = {
	BITE_01: new EnumItem('BITE_01', 'bite01'),
	FRACTURE_02: new EnumItem('FRACTURE_02', 'fracture02'),
	INFLAMMATION_03: new EnumItem('INFLAMMATION_03', 'inflammation03'),
	CONTUSION_04: new EnumItem('CONTUSION_04', 'contusion04'),
	BRUISE_05: new EnumItem('BRUISE_05', 'bruise05'),
	CHAP_06: new EnumItem('CHAP_06', 'chap06'),
	CUT_07: new EnumItem('CUT_07', 'cut07'),
	SHOT_08: new EnumItem('SHOT_08', 'shot08'),
	SWELLING_09: new EnumItem('SWELLING_09', 'swelling09'),
	GRAZE_10: new EnumItem('GRAZE_10', 'graze10'),
	COMPRESSION_SPINE_11: new EnumItem('COMPRESSION_SPINE_11', 'compressionSpine11'),
	STING_12: new EnumItem('STING_12', 'sting12'),
	TEAR_FISSURE_13: new EnumItem('TEAR_FISSURE_13', 'tearFissure13'),
	BURN_14: new EnumItem('BURN_14', 'burn14'),
	SPRAIN_TWIST_15: new EnumItem('SPRAIN_TWIST_15', 'sprainTwist15'),
	POISONING_16: new EnumItem('POISONING_16', 'poisoning16'),
	CAUSTICITY_17: new EnumItem('CAUSTICITY_17', 'causticity17'),
	PULLED_MUSCLE_LIGAMENT_18: new EnumItem('PULLED_MUSCLE_LIGAMENT_18', 'pulledMuscleLigament18'),
	FOREIGN_OBJECT_20: new EnumItem('FOREIGN_OBJECT_20', 'foreignObject20'),
	DISLOCATION_21: new EnumItem('DISLOCATION_21', 'dislocation21'),
};
