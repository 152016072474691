import { Component, Input } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Observable } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';
import { BaseComponent } from 'nc-shared';

import { AnnualSalaryService } from 'src/app/kle/service/annual-salary.service';
import { KleKindOfSalary } from 'src/app/kle/model/kle-kind-of-salary.model';

@Component({
	selector: 'ea-manual-additions-table',
	templateUrl: './manual-additions-table.component.html',
})
export class ManualAdditionsTableComponent extends BaseComponent {
	@Input() formGroup: FormGroup;

	@Input()
	formArray: FormArray;

	@Input()
	manualAdditions$: Observable<TableData>;
	@Input() isBranchHr: boolean;

	filterConfig: FilterConfig = {
		showGlobalFilter: false,
		filterValues: {},
	};

	constructor(private annualSalaryService: AnnualSalaryService) {
		super();
	}

	getTotalAmount(): number {
		return this.formArray.value.reduce((sum: number, manualAddition: KleKindOfSalary) => {
			return sum + +manualAddition.amount;
		}, 0);
	}

	openDialog = (): void => {
		this.annualSalaryService.openDialog(null, this.formArray, this.formGroup);
	};

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('amount', 'amount'),
			ColumnBuilder.create('kindOfSalary', 'kindOfSalary'),
			ColumnBuilder.create('statisticCode', 'statisticCode'),
			ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }),
		];
	}

	edit = (model: KleKindOfSalary): void => {
		this.annualSalaryService.openDialog(model, this.formArray, this.formGroup);
	};

	private delete = (model: any) => {
		const index = this.formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
		this.formArray.removeAt(index);
		this.formGroup.markAsDirty();
	};
}
