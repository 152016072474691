import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'nc-shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { KleSelect } from '../../state/action';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ea-special-code',
	templateUrl: './special-code.component.html',
})
export class SpecialCodeComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;

	specialCodeGroup = this.formBuilder.group({
		code: [null as string, Validators.required],
	});

	constructor(private store: Store, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.formGroup.addControl(KleFormGroupEnum.SPECIAL_CODE, this.specialCodeGroup);
		this.store
			.select(KleSelect.getSpecialCode)
			.pipe(takeUntil(this.destroy$))
			.subscribe((specialCode) => this.specialCodeGroup.patchValue(specialCode));
	}
}
