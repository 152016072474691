<div [id]="idText + label" class="menu-item {{ (activeMenuItem$ | async) === url ? 'active' : '' }}" (click)="onClick()" fxLayout="row wrap">
	<div fxLayout="column" fxLayoutAlign="center" class="menu-icon">
		<mat-icon *ngIf="icon !== 'notifications' || numberOfNotifications === '0'">
			{{ icon }}
		</mat-icon>
		<mat-icon
			*ngIf="icon === 'notifications' && numberOfNotifications !== '0'"
			matBadgePosition="above before"
			matBadgeSize="small"
			matBadge="{{ numberOfNotifications }}"
			matBadgeColor="warn"
		>
			{{ icon }}
		</mat-icon>
	</div>
	<div fxLayout="column" fxLayoutAlign="center" class="text">{{ label | translate }}</div>
	<mat-icon *ngIf="infoTooltip" class="info" [matTooltip]="infoTooltip | translate" matTooltipPosition="right">info</mat-icon>
</div>
