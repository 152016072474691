<div fxLayout="column" fxLayoutGap="15px" [formGroup]="paymentGroup">
	<div fxLayout="row" fxLayoutGap="10px">
		<nc-submit-button
			id="get-payments-for-company"
			buttonLabel="getPaymentsForCompany"
			(buttonClick)="getPaymentForCompany()"
			dialogLabel="processing"
			[ngClass]="'margin-bottom-15'"
		></nc-submit-button>
		<nc-submit-button
			id="get-payments-for-person"
			buttonLabel="getPaymentsForPerson"
			(buttonClick)="getPaymentForPerson()"
			dialogLabel="processing"
			[ngClass]="'margin-bottom-15'"
		></nc-submit-button>
	</div>
	<div fxLayout="row" fxLayoutGap="20px">
		<ea-container title="company" fxFlex="50">
			<nc-input
				label="accountOwner"
				formControlName="companyAccountOwner"
				[formGroup]="paymentGroup"
				[isReadonly]="true"
				[ngClass]="'margin-top-10'"
			></nc-input>
			<nc-input label="iban" formControlName="companyIban" [formGroup]="paymentGroup" [isReadonly]="true"></nc-input>
			<nc-input
				label="financialInstitution"
				formControlName="companyFinancialInstitution"
				[formGroup]="paymentGroup"
				[isReadonly]="true"
			></nc-input>
		</ea-container>
		<ea-container title="employee" fxFlex="50">
			<nc-input
				label="accountOwner"
				formControlName="employeeAccountOwner"
				[formGroup]="paymentGroup"
				[isReadonly]="true"
				[ngClass]="'margin-top-10'"
			></nc-input>
			<nc-input label="iban" formControlName="employeeIban" [formGroup]="paymentGroup" [isReadonly]="true"></nc-input>
			<nc-input
				label="financialInstitution"
				formControlName="employeeFinancialInstitution"
				[formGroup]="paymentGroup"
				[isReadonly]="true"
			></nc-input>
			<nc-datepicker [formGroup]="paymentGroup" formControlName="validAsOf" label="validAsOf"></nc-datepicker>
		</ea-container>
	</div>
</div>
