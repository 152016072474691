import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import { UserPageAction } from '../../state/action';

@Component({
	selector: 'ea-login-verification',
	templateUrl: './login-verification.component.html',
	styleUrls: ['./login-verification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginVerificationComponent implements OnInit {
	formGroup: FormGroup;

	constructor(private store: Store<State>, private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group({
			code: ['', [Validators.required]],
		});
	}

	onBack = (): void => {
		this.store.dispatch(UserPageAction.checkLogout());
	};

	onSubmit = (): void => {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(UserPageAction.verifyLoginSubmit(data));
	};

	onResend = (): void => {
		this.store.dispatch(UserPageAction.resend());
		this.formGroup.patchValue({ code: '' });
		this.formGroup.reset();
	};
}
