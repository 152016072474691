import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { UserApiAction, UserSelect } from '../../user/state/action';
import { catchError, filter, first, mergeMap, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../../user/service/authenticationService';

/**
 * Each http request goes through this interceptor.
 * On every request we attach token.
 */
@Injectable()
export class RequestHttpInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private store: Store<State>, private authenticationService: AuthenticationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return request.url.includes('refresh-token')
			? this.store.select(UserSelect.getRefreshToken).pipe(
					first(),
					mergeMap((token) => next.handle(this.createHeaders(request, token)))
			  )
			: this.store.select(UserSelect.getAccessToken).pipe(
					first(),
					mergeMap((token) =>
						next.handle(this.createHeaders(request, token)).pipe(
							catchError((error) => {
								if (error instanceof HttpErrorResponse && error.status === 401) {
									return this.handle401Error(request, next);
								}
								return throwError(() => error);
							})
						)
					)
			  );
	}

	/**
	 * If refresh token not expire ask for new access token, otherwise send user to login page
	 */
	handle401Error = (request: HttpRequest<any>, next: HttpHandler) => {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			let refToken = '';
			this.store.select(UserSelect.getRefreshToken).subscribe((token) => (refToken = token));
			return this.authenticationService.refreshToken(refToken).pipe(
				switchMap((response) => {
					if (response) {
						this.store.dispatch(
							UserApiAction.refreshTokenSuccess({
								refreshTokenResponse: { accessToken: response.accessToken, refreshToken: response.refreshToken },
							})
						);
						this.isRefreshing = false;
						this.refreshTokenSubject.next(response.accessToken);
						return next.handle(this.createHeaders(request, response.accessToken));
					}
				}),
				catchError((err) => {
					this.isRefreshing = false;
					return throwError(() => err);
				})
			);
		}
		return this.refreshTokenSubject.pipe(
			filter((token) => token !== null),
			take(1),
			switchMap((token) => next.handle(this.createHeaders(request, token)))
		);
	};

	/**
	 * Adds token and necessary header options for HTTP request
	 * @param request Http request to be modified
	 * @param token Authorization token
	 */
	private createHeaders(request: HttpRequest<any>, token: string): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json',
			},
		});
	}
}
