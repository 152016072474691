import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { throwError } from 'rxjs';
import { dateTimeToString, DialogConfigurationUtils, DialogService, EnumUtils, FormUtils } from 'nc-utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConstants } from '../../../../app.constants';
import { AbsencePageAction } from '../../../state/action';
import { BaseComponent, Option } from 'nc-shared';
import { DeclareTypeEnumeration } from '../../../../kle/model/declare-type.enumeration';
import { Store } from '@ngrx/store';
import { AbsenceFormService } from '../../../service/absence-form.service';
import { NcProcessingDialogComponent } from 'nc-processing-dialog';
import {
	accidentDateValidator,
	exactApproxDateValidator,
	incapacityToWorkValidFromDateValidatorApproximate,
	incapacityToWorkValidFromDateValidatorExact,
	insuranceCodeValidator,
	relapseDateValidator,
} from './incident-declaration.validator';
import { AbsenceService } from '../../../service/absence.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Moment } from 'moment';
import { AccidentDescriptionOptionsEnumItems } from '../../../../kle/model/enums/accident-description-options.enum';

@Component({
	templateUrl: './incident-declaration-dialog.component.html',
})
export class IncidentDeclarationDialogComponent extends BaseComponent implements OnInit {
	formGroup = this.formBuilder.group(
		{
			type: DeclareTypeEnumeration.INCIDENT.code,
			relapseDate: null as Moment,
			exactDate: [null as Moment, incapacityToWorkValidFromDateValidatorExact(this.data.firstIncapacityValidFrom?.add(23, 'hours'))],
			approximateDate: [null as Moment, incapacityToWorkValidFromDateValidatorApproximate(this.data.firstIncapacityValidFrom)],
			clarification: false,
			sendWithoutContract: false,
			insuranceCaseId: null as string,
		},
		{
			validators: [relapseDateValidator(), exactApproxDateValidator(), insuranceCodeValidator(), accidentDateValidator()],
		}
	);

	types: Option[];

	declareEnum = DeclareTypeEnumeration;

	constructor(
		public dialogRef: MatDialogRef<any>,
		private dialogService: DialogService,
		private formBuilder: FormBuilder,
		private absenceService: AbsenceService,
		private absenceFormService: AbsenceFormService,
		@Inject(MAT_DIALOG_DATA)
		public data: { absenceCode: string; firstIncapacityValidFrom: Moment },
		private store: Store
	) {
		super();
	}

	ngOnInit() {
		this.types = EnumUtils.toItems(DeclareTypeEnumeration);
		this.absenceService
			.getAccidentDescription(this.data.absenceCode)
			.pipe(
				filter((accidentDescription) => accidentDescription != null),
				takeUntil(this.destroy$)
			)
			.subscribe((accidentDescription) =>
				this.formGroup.patchValue({
					approximateDate: accidentDescription.approximate,
					exactDate: accidentDescription.exactDateTime,
					insuranceCaseId: accidentDescription.insuranceCaseId ? accidentDescription.insuranceCaseId : null,
					relapseDate: accidentDescription.relapseDate,
					type:
						accidentDescription.accidentDescriptionOptions === AccidentDescriptionOptionsEnumItems.RELAPSE.code
							? DeclareTypeEnumeration.RELAPSE.code
							: DeclareTypeEnumeration.INCIDENT.code,
				})
			);
	}

	submit = () => {
		if (this.formGroup.invalid) {
			FormUtils.validateAllFormFields(this.formGroup);
			return throwError(() => new Error(AppConstants.FORM_NOT_VALID));
		}

		let incident = null;
		let relapse = null;
		if (this.formGroup.controls.type.value === DeclareTypeEnumeration.INCIDENT.code) {
			incident = {
				insuranceCaseID: this.formGroup.controls.insuranceCaseId.value,
				incidentDate: this.mapIncidentData(),
			};
		} else {
			relapse = {
				relapseDate: dateTimeToString(this.formGroup.controls.relapseDate.value),
				insuranceCaseID: this.formGroup.controls.insuranceCaseId.value,
				incidentDate: this.mapIncidentData(),
			};
		}

		this.dialogService.open(NcProcessingDialogComponent, DialogConfigurationUtils.processing());

		this.store.dispatch(
			AbsencePageAction.declareIncident({
				absenceCode: this.data.absenceCode,
				sendWithoutContract: this.formGroup.controls.sendWithoutContract.value,
				incident,
				relapse,
			})
		);

		this.dialogRef.close({ success: true, data: this.formGroup.getRawValue() });
	};

	mapIncidentData(): any {
		return {
			exact: dateTimeToString(this.formGroup.controls.exactDate.value),
			approximate: dateTimeToString(this.formGroup.controls.approximateDate.value),
			clarification: this.formGroup.controls.clarification.value,
		};
	}

	showExactAproximateDateError() {
		return (
			this.formGroup.errors?.exactApproxDateError &&
			(this.formGroup.controls.approximateDate.touched || this.formGroup.controls.exactDate.touched)
		);
	}
	isDateErrorMessagesLong() {
		return (
			(this.formGroup.controls.exactDate.errors?.matDatetimePickerMax && this.formGroup.controls.exactDate.touched) ||
			(this.formGroup.controls.approximateDate.errors?.matDatepickerMax && this.formGroup.controls.approximateDate.touched)
		);
	}
}
