<form [formGroup]="data.formGroup" fxFill fxLayout="column">
	<h1>
		<nc-label id="absence" value="absence"></nc-label>
	</h1>
	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
		<nc-datepicker id="from" [formGroup]="data.formGroup" formControlName="from" label="from"></nc-datepicker>
	</div>
	<nc-input id="hours" [formGroup]="data.formGroup" formControlName="hours" label="hours"></nc-input>
	<mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-submit-button
			id="save"
			(buttonClick)="submit()"
			[buttonLabel]="btnLabel"
			dialogLabel="processing"
			[formGroup]="data.formGroup"
		></nc-submit-button>
	</mat-dialog-actions>
</form>
