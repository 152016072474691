import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { map, Observable } from 'rxjs';
import { KleSelect } from '../../state/action';
import { ReceivedSentStory } from '../../model/received-sent-story';
import { StoryRecordEnum } from '../../model/enums/story-record.enum';

@Component({
	selector: 'ea-received-sent-stories',
	templateUrl: './received-sent-stories.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedSentStoriesComponent implements OnInit {
	receivedStories$: Observable<TableData>;
	sentStories$: Observable<TableData>;

	tableColumns: DatatableColumn[];
	filterConfig: FilterConfig;

	constructor(private store: Store) {}

	ngOnInit(): void {
		this.tableColumns = this.getColumns();
		this.filterConfig = this.createFilterConfig();

		this.receivedStories$ = this.store.select(KleSelect.getIncidentResponses).pipe(map((response) => ({ records: this.mapResponse(response) })));
		this.sentStories$ = this.store.select(KleSelect.getIncidentRequests).pipe(map((response) => ({ records: this.mapResponse(response) })));
	}

	getColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('type', 'synchronization.table.type'),
			ColumnBuilder.create('id', 'id'),
			ColumnBuilder.createDate('createdOn', 'dateTime'),
		];
	}

	createFilterConfig(): FilterConfig {
		return {
			showFilter: false,
			filterValues: {
				showFilter: false,
				showGlobalFilter: true,
			},
		};
	}

	mapResponse(response: any): any {
		const responseStories = [];
		if (response?.length) {
			response.forEach((item) => {
				let story = new ReceivedSentStory();
				story.createdOn = item.createdOn;
				story.id = item.id;
				story.type = StoryRecordEnum[item.type].label;
				responseStories.push(story);
			});
		}
		return responseStories;
	}
}
