import { ChartColor } from '../../shared/model/chart/chart-color';

export class StatisticChartColors {
	public static overall: ChartColor = new ChartColor(['#2c7250', '#4b84ba', '#8e8e8e']);
	public static totalCm: ChartColor = new ChartColor(['#2c7250', '#4b84ba']);

	public static reasonsForSuccessfulCm: ChartColor = new ChartColor(['#2c7250', '#4b84ba', '#8e8e8e']);

	public static reasonsForNotSuccessfulCm: ChartColor = new ChartColor(['#2c7250', '#4b84ba', '#8e8e8e']);

	public static averageAbsenceDurationPerIllness: ChartColor = new ChartColor(['#2c7250', '#2c7250', '#2c7250', '#2c7250', '#2c7250', '#2c7250']);

	public static averageAbsenceDurationPerAccident: ChartColor = new ChartColor(['#2c7250', '#2c7250']);
}
