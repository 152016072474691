import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, SelectConfig, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AbsencePageAction, AbsenceSelect } from '../../state/action';
import { takeUntil } from 'rxjs/operators';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';

@Component({
	templateUrl: './reassign-absence.component.html',
	styleUrls: ['./reassign-absence.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReassignAbsenceComponent extends BaseComponent {
	formGroup: FormGroup;
	reassignAbsenceEmployees$: Observable<TableData>;
	reassignAbsenceEmployeesColumns: DatatableColumn[];
	code: string;
	selectConfig: SelectConfig;
	filterConfig: FilterConfig;

	constructor(private store: Store, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
		super();
		this.formGroup = formBuilder.group({
			showAll: [false],
		});

		this.route.params.subscribe((params) => {
			if (params.code) {
				this.code = params.code;
				this.store.dispatch(
					AbsencePageAction.getReassignAbsenceEmployees({
						code: params.code,
						showAll: this.formGroup.get('showAll').value,
					})
				);
			}
		});

		this.selectConfig = {
			isSingleSelect: true,
			isFirstColumn: true,
			identifierColumn: 'id',
			formArray: formBuilder.array([]),
		};

		this.reassignAbsenceEmployeesColumns = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.create('firstName', 'firstName'),
			ColumnBuilder.create('lastName', 'lastName'),
			ColumnBuilder.create('neoId', 'neoId'),
			ColumnBuilder.createDate('birthDate', 'birthDate'),
			ColumnBuilder.create('socialSecurityNumber', 'socialSecurityNumber'),
			ColumnBuilder.createDate('pkProInsuranceEntryDate', 'pkProInsuranceEntryDate'),
			ColumnBuilder.createDate('pkProInsuranceExitDate', 'pkProInsuranceExitDate'),
		];

		this.reassignAbsenceEmployees$ = this.store.select(AbsenceSelect.getReassignAbsenceEmployees);
		this.filterConfig = {
			filterValues: {},
		};

		this.formGroup
			.get('showAll')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((showAll) => {
				this.selectConfig.formArray = formBuilder.array([]);
				const params = { code: this.code, showAll };
				this.store.dispatch(AbsencePageAction.getReassignAbsenceEmployees(params));
			});
	}
}
