<form [formGroup]="data.formGroup" fxFill fxLayout="column">
	<h1>
		<nc-label id="attachment" value="attachment"></nc-label>
	</h1>
	<div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="10px">
		<nc-input id="name" [formGroup]="data.formGroup" formControlName="name" label="name" [isReadonly]="true"></nc-input>
		<nc-select
			id="select-type"
			searchButtonId="select-search-button"
			searchTextId="select-search-text"
			clearIconId="clear-icon"
			[formGroup]="data.formGroup"
			formControlName="contentType"
			label="contentType"
			[data$]="documentType$"
			[translate]="true"
		></nc-select>
	</div>
	<mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-submit-button (buttonClick)="submit()" buttonLabel="save" dialogLabel="processing"></nc-submit-button>
	</mat-dialog-actions>
</form>
