import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';

const OK = null;
const PASSWORDS_NOT_EQUAL = { passwordsNotEqual: true };

/**
 * Checks if passwords are equal on reset password form
 */
const arePasswordsEqual = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const passwordControl = control.get('password');
		const repeatedPasswordControl = control.get('repeatedPassword');

		if (passwordControl.value !== repeatedPasswordControl.value) {
			FormValidation.addError(repeatedPasswordControl, PASSWORDS_NOT_EQUAL);
		} else {
			FormValidation.removeError(repeatedPasswordControl, PASSWORDS_NOT_EQUAL);
		}

		return OK;
	};
};

export { arePasswordsEqual };
