import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../../shared/service/search.service';
import { DialogService, EnumUtils, StringsUtils } from 'nc-utils';
import { BaseComponent, Option } from 'nc-shared';
import { AbsencePageAction, AbsenceSelect } from '../../../../state/action';
import { AbsenceDeclarationTypeEnumeration } from '../../../../model/enumeration/absence-declaration-type.enumeration';
import { Company } from '../../../../../shared/model/company';
import { UserSelect } from '../../../../../user/state/action';
import { ErrorSelect } from '../../../../../state/error/action';
import { ValidationService } from '../../../../../shared/service/validation.service';
import { Employee } from '../../../../../shared/model/employee';
import { EmployeeDetailsComponent } from '../../../../../integration/component/employee-details/employee-details.component';
import { CompaniesSelectFactory } from '../../../../../shared/utils/companies-select-factory';
import { AbsenceTypeEnumeration } from '../../../../../shared/enumeration/absence-type.enumeration';
import { PermissionEnumeration } from '../../../../../shared/enumeration/permission.enumeration';
import { AccordionCheckboxMap } from '../../../../../kle/model/accordion-checkbox-map';
import { AccordionCheckbox } from '../../../../../kle/model/accordion-checkbox';
import { AbsenceService } from '../../../../service/absence.service';

@Component({
	templateUrl: './hr-new-absence.component.html',
	styleUrls: ['./hr-new-absence.component.scss'],
	selector: 'ea-hr-new-absence',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HrNewAbsenceComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input() formGroup: FormGroup;

	permissions: string[];

	company$: Observable<Company>;
	employee$: Observable<Employee>;
	employees$: Observable<Option[]>;

	declarationTypeOptions: Option[];
	isMultipleCompanies: boolean;
	companies$: Observable<Option[]>;

	documents: FormArray;
	incapacities: FormArray;
	treatments: FormArray;
	accidentDescription: FormArray;
	company: FormControl;
	employee: FormControl;
	declarationType: FormControl;
	employeeId: number;
	isSwica = false;
	isKleEnabled = false;
	isBranchHrUser: boolean;

	accordionCheckboxMap = new AccordionCheckboxMap();
	treatmentsFormGroup: FormGroup;
	accidentDescriptionFormGroup: FormGroup;
	ABSENCE_DECLARATION_TYPE = AbsenceDeclarationTypeEnumeration;
	ABSENCE_TYPE_ENUMERATION = AbsenceTypeEnumeration;

	isBranchHr: boolean = false;
	hasDeclareIncidentPermission: boolean = false;
	isAccident: boolean;

	constructor(
		private store: Store,
		private searchService: SearchService,
		private formBuilder: FormBuilder,
		private dialogService: DialogService,
		private changeDetectorRef: ChangeDetectorRef,
		private companiesSelectFactory: CompaniesSelectFactory,
		private validationService: ValidationService,
		private absenceService: AbsenceService
	) {
		super();
		this.company$ = this.store.select(AbsenceSelect.getCompany);
		this.employee$ = this.store.select(AbsenceSelect.getEmployee);
		this.employees$ = this.store.select(AbsenceSelect.getEmployees);

		this.declarationTypeOptions = EnumUtils.toItems(AbsenceDeclarationTypeEnumeration);

		this.store.select(UserSelect.getPermissions).subscribe((permissions) => {
			this.permissions = permissions;
			this.isBranchHrUser = permissions.includes(PermissionEnumeration.BRANCH_HR);
		});
		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));

		this.companies$ = this.store
			.select(UserSelect.getCompanies)
			.pipe(map((companies) => this.companiesSelectFactory.createCompanySelectModel(companies)));
	}

	ngOnInit(): void {
		this.documents = this.formGroup.get('documents') as FormArray;
		this.incapacities = this.formGroup.get('incapacities') as FormArray;
		this.treatments = this.formGroup.get('treatments') as FormArray;
		this.accidentDescription = this.formGroup.get('accidentDescription') as FormArray;
		this.company = this.formGroup.get('companyId') as FormControl;
		this.employee = this.formGroup.get('refEmployee') as FormControl;
		this.declarationType = this.formGroup.get('categoryType') as FormControl;

		this.treatmentsFormGroup = this.formBuilder.group({});
		this.accidentDescriptionFormGroup = this.formBuilder.group({});

		this.employee.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (StringsUtils.isNotEmpty(id)) {
				this.store.dispatch(AbsencePageAction.fetchEmployee({ id }));
				this.employeeId = id;
				this.formGroup.updateValueAndValidity();
			}
		});

		this.companies$.subscribe((companies) => {
			this.isMultipleCompanies = companies.length > 1;

			if (companies.length === 1) {
				this.formGroup.get('companyId').patchValue(companies[0].value);
				this.store.dispatch(AbsencePageAction.fetchCompany({ id: companies[0].value }));
			}
		});

		this.company.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((id) => {
			if (id !== null) {
				this.store.dispatch(AbsencePageAction.fetchCompany({ id }));
				this.store.dispatch(AbsencePageAction.fetchEmployees({ companyId: id }));
			} else {
				this.store.dispatch(AbsencePageAction.clearEmployees());
			}
			this.employee.patchValue(null);
		});

		const hasSicknessInsurer = this.formGroup.get('hasDailySicknessBenefitsInsurance');
		const sicknessCompany = this.formGroup.get('dailySicknessBenefitsInsuranceCompany');

		this.company$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
			this.isSwica = company.isSwicaInsured;
			this.isKleEnabled = company.isKleEnabled;
			this.formGroup.controls.isKleEnabled.patchValue(this.isKleEnabled);
			this.store.dispatch(AbsencePageAction.fetchEmployees({ companyId: company.id.toString() }));
		});

		hasSicknessInsurer.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((answer) => {
			if (answer === 'no') {
				sicknessCompany.patchValue('');
			} else {
				if (this.isSwica) {
					sicknessCompany.patchValue('SWICA');
				}
			}
		});

		this.store
			.select(UserSelect.getPermissions)
			.pipe(takeUntil(this.destroy$))
			.subscribe((permissions) => {
				this.hasDeclareIncidentPermission = permissions.includes(PermissionEnumeration.DECLARE_INCIDENT);
				this.isBranchHr = permissions.includes(PermissionEnumeration.BRANCH_HR);
			});
	}

	ngOnDestroy() {
		this.store.dispatch(AbsencePageAction.clearEmployees());
	}

	openEmployee(): void {
		const dialogConfig = { data: { insuredPersonId: this.employeeId } };
		this.dialogService.open(EmployeeDetailsComponent, dialogConfig);
	}

	handleCheckboxValueChange(isFormControlChecked: boolean, accordionCheckbox: AccordionCheckbox, currentFormGroup: FormGroup): void {
		accordionCheckbox.checked = isFormControlChecked;
		if (isFormControlChecked) {
			this.formGroup.addControl(accordionCheckbox.formControlName, currentFormGroup.controls[accordionCheckbox.formControlName]);
		} else {
			this.formGroup.removeControl(accordionCheckbox.formControlName);
		}
	}

	isAccordionInvalid(accordionCheckbox: AccordionCheckbox, nameOfAccordion: string): boolean {
		if (nameOfAccordion === 'accidentDescription') {
			return accordionCheckbox.checked && this.accidentDescriptionFormGroup.controls[accordionCheckbox.formControlName].status === 'INVALID';
		} else {
			return accordionCheckbox.checked && this.treatmentsFormGroup.controls[accordionCheckbox.formControlName].status === 'INVALID';
		}
	}

	isAccordionDirty(accordionCheckbox: AccordionCheckbox, nameOfAccordion: string): boolean {
		if (nameOfAccordion === 'accidentDescription') {
			return !accordionCheckbox.checked && this.accidentDescriptionFormGroup.controls[accordionCheckbox.formControlName]?.dirty;
		} else {
			return !accordionCheckbox.checked && this.treatmentsFormGroup.controls[accordionCheckbox.formControlName]?.dirty;
		}
	}

	filterItems = (term) => {
		if (term.length > 1) {
			return this.absenceService.filterItems(term, this.employees$);
		}
	};
}
