<div id="fetch-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div mat-dialog-title>
		<span class="dialog-title-text">{{ 'generateDocument' | translate }}</span>
	</div>
	<div [formGroup]="formGroup" id="content" fxLayout="column" fxLayoutGap="10px" mat-dialog-content>
		<nc-toggle-buttons [formGroup]="formGroup" label="language" formControlName="language" [options]="languageOptions"> </nc-toggle-buttons>
		<nc-select
			[id]="'document-type'"
			[searchTextId]="'document-type-search'"
			[searchButtonId]="'document-type-search-clear'"
			[clearIconId]="'document-type-clear'"
			label="documentType"
			[formGroup]="formGroup"
			[data$]="documentTypes$"
			placeholder="defaultSelectPleaceholder"
			[translate]="true"
			formControlName="documentType"
		>
		</nc-select>
		<nc-datepicker [id]="'report-date'" *ngIf="isReportDateVisible()" [formGroup]="formGroup" label="reportDate" formControlName="reportDate">
		</nc-datepicker>
	</div>
	<div mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="10px">
			<nc-button [id]="'cancel'" label="cancel" [mat-dialog-close]="false"></nc-button>
			<nc-button [id]="'generate'" label="generate" (click)="generate()"></nc-button>
		</div>
	</div>
</div>
