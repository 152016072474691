import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSelect } from '../state/action';
import { map } from 'rxjs/operators';
import { PermissionEnumeration } from '../../shared/enumeration/permission.enumeration';

@Injectable({
	providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
	constructor(private store: Store, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(UserSelect.getPermissions).pipe(
			map((permissions) => {
				const hasResetPasswordPermission = permissions.includes(PermissionEnumeration.FORCING_PASSWORD_CHANGE);

				if (!hasResetPasswordPermission) {
					this.router.navigateByUrl('/');
				}

				return hasResetPasswordPermission;
			})
		);
	}
}
