import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../service/error-handler.service';

@Injectable()
export class ErrorResponseHttpInterceptor implements HttpInterceptor {
	constructor(private handler: ErrorHandlerService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((response: HttpErrorResponse) => {
				this.handler.processHttpError(response);
				return throwError(() => response);
			})
		);
	}
}
