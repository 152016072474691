import { HttpResponse } from '@angular/common/http';

export class FileUtils {
	/**
	 * Performs download of file from response
	 * @param response response containing file
	 */
	public static download(response: HttpResponse<Blob>): void {
		const fileName = response.headers.get('content-disposition').split('=')[1];

		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(response.body);
		link.download = decodeURIComponent(fileName.replace(/\+/g, ' '));
		link.click();
		link.remove();
	}

	public static getExtension(documentName: string): string {
		const lastDotIndex = documentName.lastIndexOf('.');
		return lastDotIndex !== -1 ? documentName.slice(lastDotIndex + 1) : '';
	}
}
