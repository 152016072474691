<ea-container [title]="title" cssClass="margin-top-15" *ngIf="!isReadonly || documentsFormArray !== null">
	<nc-documents
		[id]="id"
		[buttonId]="buttonId"
		[downloadButtonId]="downloadButtonId"
		fxFlex="100"
		[formArray]="documentsFormArray"
		[documentTypes$]="documentTypes$"
		[isReadonly]="isReadonly"
		[additionalColumns]="additionalColumns"
		[onDownload]="onDownload"
		[onPreview]="onPreview"
		[onDownloadSelected]="onDownloadSelected"
		[onDelete]="onDelete"
		[hideMultiDownload]="hideMultiDownload"
		[hidePreview]="hidePreview"
		[hideDelete]="hideDelete"
	>
	</nc-documents>
</ea-container>
