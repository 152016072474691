import { createReducer, on } from '@ngrx/store';
import { SETTINGS_INITIAL_STATE, SettingsState } from './settings.state';
import { SettingsApiAction, SettingsPageAction } from './action';
import { DashboardPageAction } from '../../dashboard/state/action';

export const settingsReducer = createReducer<SettingsState>(
	SETTINGS_INITIAL_STATE,
	on(SettingsPageAction.setLanguage, (state, action): SettingsState => {
		return {
			...state,
			language: action.language,
		};
	}),
	on(SettingsPageAction.setReady, (state, action): SettingsState => {
		return {
			...state,
			ready: action.ready,
		};
	}),
	on(SettingsPageAction.openMenuItem, (state, action): SettingsState => {
		return {
			...state,
			activeMenuItem: '',
		};
	}),
	on(SettingsPageAction.setMenuItem, (state, action): SettingsState => {
		return {
			...state,
			activeMenuItem: action.menuItem,
		};
	}),
	on(SettingsPageAction.setSelectedNumberOfDays, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					dashboard: {
						...state.UIPreferences[action.loggedUser].dashboard,
						selectedNumberOfDays: action.selectedNumberOfDays,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.navigate, (state, action): SettingsState => {
		return {
			...state,
			activeMenuItem: '',
		};
	}),
	on(SettingsApiAction.downloadDocumentSuccess, (state, action): SettingsState => {
		return {
			...state,
			document: action.document,
		};
	}),
	on(SettingsPageAction.setDashboardTableFilters, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					dashboard: {
						...state.UIPreferences[action.loggedUser].dashboard,
						milestoneFilter: action.dashboardFilters.milestoneFilter,
						tasksFilter: action.dashboardFilters.tasksFilter,
						activeCasesFilter: action.dashboardFilters.activeCasesFilter,
						selectedCompanies: action.dashboardFilters.selectedCompanies,
						sortByColumnMilestone: action.dashboardFilters.sortByColumnMilestone,
						orderMilestone: action.dashboardFilters.orderMilestone,
						sortByColumnTasks: action.dashboardFilters.sortByColumnTasks,
						orderTasks: action.dashboardFilters.orderTasks,
						sortByColumnAbsence: action.dashboardFilters.sortByColumnAbsence,
						orderAbsence: action.dashboardFilters.orderAbsence,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setLocalStorageInitialData, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.username]: action.userPreferences,
			},
		};
	}),
	on(DashboardPageAction.saveSelectedUser, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					dashboard: {
						...state.UIPreferences[action.loggedUser].dashboard,
						selectedUser: action.selectedUser,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setArchivedCasesValues, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.username]: {
					...state.UIPreferences[action.username],
					archivedCases: {
						...state.UIPreferences[action.username].archivedCases,
						responsibleUser: action.data.responsibleUser,
						caseFilter: action.data.caseFilter,
						selectedCompanies: action.data.selectedCompanies,
						sortByColumn: action.data.sortByColumn,
						order: action.data.order,
					},
				},
			},
		};
	}),
	on(SettingsApiAction.deleteDocumentSuccess, (state, action): SettingsState => {
		return {
			...state,
			deleteDocumentId: action.documentId,
		};
	}),

	on(SettingsPageAction.setUserAdministrationTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					userAdministration: {
						...state.UIPreferences[action.loggedUser].userAdministration,
						neoIdFilter: action.userAdministrationFilters.neoIdFilter,
						roleFilter: action.userAdministrationFilters.roleFilter,
						nameFilter: action.userAdministrationFilters.nameFilter,
						companyNameFilter: action.userAdministrationFilters.companyNameFilter,
						usernameFilter: action.userAdministrationFilters.usernameFilter,
						emailFilter: action.userAdministrationFilters.emailFilter,
						activeFilter: action.userAdministrationFilters.activeFilter,
						sortByColumn: action.userAdministrationFilters.sortByColumn,
						order: action.userAdministrationFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setAdministrationTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					administration: {
						...state.UIPreferences[action.loggedUser].administration,
						nameFilter: action.administrationFilters.nameFilter,
						defaultAssigneeFilter: action.administrationFilters.defaultAssigneeFilter,
						sortByColumn: action.administrationFilters.sortByColumn,
						order: action.administrationFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setMilestoneTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					milestone: {
						...state.UIPreferences[action.loggedUser].milestone,
						globalFilter: action.milestoneFilters.globalFilter,
						sortByColumn: action.milestoneFilters.sortByColumn,
						order: action.milestoneFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setLabelManagementTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					labelManagement: {
						...state.UIPreferences[action.loggedUser].labelManagement,
						globalFilter: action.labelManagementFilters.globalFilter,
						sortByColumn: action.labelManagementFilters.sortByColumn,
						order: action.labelManagementFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setLdResponsibleTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					ldResponsible: {
						...state.UIPreferences[action.loggedUser].ldResponsible,
						globalFilter: action.ldResponsibleFilters.globalFilter,
						sortByColumn: action.ldResponsibleFilters.sortByColumn,
						order: action.ldResponsibleFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setDocumentTypesTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					documentTypes: {
						...state.UIPreferences[action.loggedUser].documentTypes,
						globalFilter: action.documentTypesFilters.globalFilter,
						sortByColumn: action.documentTypesFilters.sortByColumn,
						order: action.documentTypesFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setCompanyTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					company: {
						...state.UIPreferences[action.loggedUser].company,
						nameFilter: action.companyFilters.nameFilter,
						easyTempIdFilter: action.companyFilters.easyTempIdFilter,
						neoIdFilter: action.companyFilters.neoIdFilter,
						neoEnvironmentFilter: action.companyFilters.neoEnvironmentFilter,
						uidFilter: action.companyFilters.uidFilter,
						isKleEnabledFilter: action.companyFilters.isKleEnabledFilter,
						isSwicaInsuredFilter: action.companyFilters.isSwicaInsuredFilter,
						isPkProInsuredFilter: action.companyFilters.isPkProInsuredFilter,
						externallyUpdatedOnFilter: action.companyFilters.externallyUpdatedOnFilter,
						sortByColumn: action.companyFilters.sortByColumn,
						order: action.companyFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setMedicalInstitutionsTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					medicalInstitutions: {
						...state.UIPreferences[action.loggedUser].medicalInstitutions,
						globalLocationNumberFilter: action.medicalInstitutionsFilters.globalLocationNumberFilter,
						doctorFirstNameFilter: action.medicalInstitutionsFilters.doctorFirstNameFilter,
						doctorLastNameFilter: action.medicalInstitutionsFilters.doctorLastNameFilter,
						institutionNameFilter: action.medicalInstitutionsFilters.institutionNameFilter,
						additionalInstitutionNameFilter: action.medicalInstitutionsFilters.additionalInstitutionNameFilter,
						streetFilter: action.medicalInstitutionsFilters.streetFilter,
						cityFilter: action.medicalInstitutionsFilters.cityFilter,
						zipFilter: action.medicalInstitutionsFilters.zipFilter,
						countryFilter: action.medicalInstitutionsFilters.countryFilter,
						lastChangeDateFilter: action.medicalInstitutionsFilters.lastChangeDateFilter,
						sortByColumn: action.medicalInstitutionsFilters.sortByColumn,
						order: action.medicalInstitutionsFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsApiAction.deleteDocumentSuccess, (state, action): SettingsState => {
		return {
			...state,
			deleteDocumentId: action.documentId,
		};
	}),
	on(SettingsPageAction.setUserAdministrationTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					userAdministration: {
						...state.UIPreferences[action.loggedUser].userAdministration,
						neoIdFilter: action.userAdministrationFilters.neoIdFilter,
						roleFilter: action.userAdministrationFilters.roleFilter,
						nameFilter: action.userAdministrationFilters.nameFilter,
						companyNameFilter: action.userAdministrationFilters.companyNameFilter,
						usernameFilter: action.userAdministrationFilters.usernameFilter,
						emailFilter: action.userAdministrationFilters.emailFilter,
						activeFilter: action.userAdministrationFilters.activeFilter,
						sortByColumn: action.userAdministrationFilters.sortByColumn,
						order: action.userAdministrationFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setAdministrationTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					administration: {
						...state.UIPreferences[action.loggedUser].administration,
						nameFilter: action.administrationFilters.nameFilter,
						defaultAssigneeFilter: action.administrationFilters.defaultAssigneeFilter,
						sortByColumn: action.administrationFilters.sortByColumn,
						order: action.administrationFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setMilestoneTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					milestone: {
						...state.UIPreferences[action.loggedUser].milestone,
						globalFilter: action.milestoneFilters.globalFilter,
						sortByColumn: action.milestoneFilters.sortByColumn,
						order: action.milestoneFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setLabelManagementTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					labelManagement: {
						...state.UIPreferences[action.loggedUser].labelManagement,
						globalFilter: action.labelManagementFilters.globalFilter,
						sortByColumn: action.labelManagementFilters.sortByColumn,
						order: action.labelManagementFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setLdResponsibleTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					ldResponsible: {
						...state.UIPreferences[action.loggedUser].ldResponsible,
						globalFilter: action.ldResponsibleFilters.globalFilter,
						sortByColumn: action.ldResponsibleFilters.sortByColumn,
						order: action.ldResponsibleFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setDocumentTypesTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					documentTypes: {
						...state.UIPreferences[action.loggedUser].documentTypes,
						globalFilter: action.documentTypesFilters.globalFilter,
						sortByColumn: action.documentTypesFilters.sortByColumn,
						order: action.documentTypesFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setCompanyTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					company: {
						...state.UIPreferences[action.loggedUser].company,
						nameFilter: action.companyFilters.nameFilter,
						easyTempIdFilter: action.companyFilters.easyTempIdFilter,
						neoIdFilter: action.companyFilters.neoIdFilter,
						neoEnvironmentFilter: action.companyFilters.neoEnvironmentFilter,
						uidFilter: action.companyFilters.uidFilter,
						isKleEnabledFilter: action.companyFilters.isKleEnabledFilter,
						isPkProInsuredFilter: action.companyFilters.isPkProInsuredFilter,
						isSwicaInsuredFilter: action.companyFilters.isSwicaInsuredFilter,
						externallyUpdatedOnFilter: action.companyFilters.externallyUpdatedOnFilter,
						sortByColumn: action.companyFilters.sortByColumn,
						order: action.companyFilters.order,
					},
				},
			},
		};
	}),
	on(SettingsPageAction.setMedicalInstitutionsTableFilter, (state, action): SettingsState => {
		return {
			...state,
			UIPreferences: {
				...state.UIPreferences,
				[action.loggedUser]: {
					...state.UIPreferences[action.loggedUser],
					medicalInstitutions: {
						...state.UIPreferences[action.loggedUser].medicalInstitutions,
						globalLocationNumberFilter: action.medicalInstitutionsFilters.globalLocationNumberFilter,
						doctorFirstNameFilter: action.medicalInstitutionsFilters.doctorFirstNameFilter,
						doctorLastNameFilter: action.medicalInstitutionsFilters.doctorLastNameFilter,
						institutionNameFilter: action.medicalInstitutionsFilters.institutionNameFilter,
						additionalInstitutionNameFilter: action.medicalInstitutionsFilters.additionalInstitutionNameFilter,
						streetFilter: action.medicalInstitutionsFilters.streetFilter,
						cityFilter: action.medicalInstitutionsFilters.cityFilter,
						zipFilter: action.medicalInstitutionsFilters.zipFilter,
						countryFilter: action.medicalInstitutionsFilters.countryFilter,
						lastChangeDateFilter: action.medicalInstitutionsFilters.lastChangeDateFilter,
						sortByColumn: action.medicalInstitutionsFilters.sortByColumn,
						order: action.medicalInstitutionsFilters.order,
					},
				},
			},
		};
	})
);
