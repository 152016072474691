import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumUtils } from 'nc-utils';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ErrorSelect } from '../../../state/error/action';
import { ValidationService } from '../../../shared/service/validation.service';
import { NoteModel } from '../../model/note.model';
import { TaskPageAction, TaskSelect } from '../../state/action';
import { NcDocumentsService } from 'nc-documents';
import { NoteTypeEnumeration } from '../../model/enumeration/note-type.enumeration';
import { TranslateService } from '@ngx-translate/core';
import { ColumnBuilder, DatatableColumn } from 'nc-datatable';
import { EncodingService } from '../../../shared/service/encoding.service';

@Component({
	templateUrl: './note-form.component.html',
	styleUrls: ['./note-form.component.scss'],
})
export class NoteFormComponent extends CanDeactivateFormComponent implements OnInit {
	documents: FormArray;
	yesNo: Option[];
	note$: Observable<NoteModel>;
	noteType$: Observable<Option[]>;
	isReadOnly = true;
	additionalColumns: DatatableColumn[];

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef,
		private documentService: NcDocumentsService,
		private encodingService: EncodingService
	) {
		super();

		this.formGroup = this.formBuilder.group({
			id: [0, null],
			absenceId: ['', null],
			text: ['', Validators.required],
			type: ['', Validators.required],
			typeValue: [''],
			replicateForActiveAbsencesSet: [FlagEnumeration.no, null],
			documents: this.formBuilder.array([]),
		});

		this.route.params.subscribe((params) => {
			if (params.id) {
				this.store.dispatch(TaskPageAction.getNote({ noteId: params.id }));
			} else {
				this.isReadOnly = false;
				this.store
					.select(TaskSelect.getCurrentAbsence)
					.subscribe((currentAbsence) => this.formGroup.get('absenceId').patchValue(currentAbsence?.absenceId));
			}
		});

		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));
	}

	ngOnInit(): void {
		this.noteType$ = EnumUtils.toObservableItems(NoteTypeEnumeration);
		this.yesNo = EnumUtils.toItems(FlagEnumeration);
		this.note$ = this.store.select(TaskSelect.getNote);
		this.documents = this.formGroup.get('documents') as FormArray;
		this.note$
			.pipe(
				filter((note) => note !== null && note.id !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((note) => this.displayNote(note));

		this.additionalColumns = [ColumnBuilder.create('uploadedBy', 'uploadedBy'), ColumnBuilder.create('uploadedOn', 'uploadedOn')];
	}

	dispatchSubmitAction = (): void => {
		const note = this.formGroup.getRawValue();
		note.documents = this.documentService.getDocuments(this.formGroup);
		note.documents = this.encodingService.encodeDocuments(note.documents);
		this.store.dispatch(TaskPageAction.saveNote({ note }));
	};

	editNote = (): void => {
		this.isReadOnly = false;
		this.formGroup.get('text').enable();
	};

	displayNote = (note: NoteModel): void => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			id: note.id,
			absenceId: note.absenceId,
			text: note.text,
			type: note.type,
			typeValue: this.translateService.instant(NoteTypeEnumeration[note.type]),
			replicateForActiveAbsencesSet: note.replicateForActiveAbsencesSet ? FlagEnumeration.yes : FlagEnumeration.no,
		});
		const documents = this.formGroup.get('documents') as FormArray;
		this.documentService.populateData(documents, note.documents);
	};
}
