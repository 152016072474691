import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { UserSelect } from '../state/action';
import { map } from 'rxjs/operators';
import { SettingsPageAction } from '../../state/settings/action';

@Injectable({
	providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
	constructor(private router: Router, private store: Store<State>) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.store.select(UserSelect.isAuthenticated).pipe(
			map((authenticated) => {
				if (!authenticated) {
					this.store.dispatch(SettingsPageAction.navigate({ url: '/' }));
				}

				return authenticated;
			})
		);
	}
}
