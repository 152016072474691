import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { SettingsPageAction } from '../../../state/settings/action';

@Component({
	selector: 'ea-authority-footer',
	templateUrl: './authority-footer.component.html',
	styleUrls: ['./authority-footer.component.scss'],
})
export class AuthorityFooterComponent {
	@Input() type: string;

	constructor(private location: Location, private store: Store) {}

	goBack = (): void => {
		this.location.back();
	};

	create = (): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/${this.type}/new` }));
	};
}
