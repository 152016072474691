import { ColumnBuilder, DatatableColumn } from 'nc-datatable';

export class AbsenceTableColumns {
	public static SHARE_INFORMATION_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.createHidden('id'),
		ColumnBuilder.create('createdBy', 'createdBy'),
		ColumnBuilder.create('comment', 'comment'),
		ColumnBuilder.createDate('createdOn', 'createdOn'),
		ColumnBuilder.create('hasDocuments', 'hasDocuments'),
	];

	public static LINKED_ABSENCE_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('messageType', 'messageType'),
		ColumnBuilder.create('code', 'codeMessage'),
		ColumnBuilder.createDate('validFrom', 'validFrom'),
		ColumnBuilder.createDate('validTo', 'validTo'),
		ColumnBuilder.create('percentage', 'percentage'),
		ColumnBuilder.create('comment', 'comment'),
		ColumnBuilder.create('createdBy', 'createdBy'),
		ColumnBuilder.createDate('createdOn', 'createdOn'),
	];

	public static MERGE_ABSENCE_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('code', 'code'),
		ColumnBuilder.create('type', 'reason'),
		ColumnBuilder.createDate('validFrom', 'validFrom'),
		ColumnBuilder.create('createdBy', 'createdBy'),
		ColumnBuilder.createDate('createdOn', 'createdOn'),
	];
}
