<div *ngIf="formData && data.formGroup">
	<div>
		<nc-form-generator [form]="data.formGroup" [formName]="formName" [formData]="formData"></nc-form-generator>
	</div>

	<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-submit-button
			id="send-dialog-message"
			buttonLabel="replay"
			(click)="submit()"
			[formGroup]="data.formGroup.controls[formName]"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</div>
