<div fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div id="roles-container">
				<nc-datatable
					[id]="'authorities'"
					[rowClick]="onRowClick"
					[columns]="authorityColumns"
					[data$]="authorities$"
					[filterConfig]="filterConfig"
				>
				</nc-datatable>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-authority-footer [type]="urlPath"></ea-authority-footer>
</div>
