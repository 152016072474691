import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthorityTypeEnumeration } from '../model/enumeration/authority-type.enumeration';
import { Router } from '@angular/router';
import { BaseComponent, Option } from 'nc-shared';
import { DocumentTypeEntityAccessEnumeration } from '../model/enumeration/document-type-entity-access.enumeration';
import { AccessTypeEnumeration } from '../model/enumeration/access-type.enumeration';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LinkedTableConfig } from 'nc-linked-tables/lib/model/linked-table-config';
import { AccessAssignmentPageAction, AccessAssignmentSelect } from '../state/action';
import { OperationTypeEnumeration } from '../model/enumeration/operation-type.enumeration';
import { takeUntil } from 'rxjs/operators';
import { AuthorityOperationModel } from '../model/authority-operation.model';
import { DocumentTypeModel } from '../model/document-type.model';

@Component({
	templateUrl: './access-assignment.component.html',
	styleUrls: ['./access-assignment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessAssignmentComponent extends BaseComponent implements OnInit {
	accessAssignmentAvailable$: Observable<TableData>;
	accessAssignmentAssigned$: Observable<TableData>;
	selectOptions$: Observable<Option[]>;
	authorityColumns: DatatableColumn[];
	authorityType: AuthorityTypeEnumeration;
	documentTypeAccess: DocumentTypeEntityAccessEnumeration;
	accessType: AccessTypeEnumeration;
	formGroup: FormGroup;
	linkedTableConfig: LinkedTableConfig;
	label: string;

	constructor(private store: Store, private router: Router, formBuilder: FormBuilder) {
		super();
		this.selectOptions$ = this.store.select(AccessAssignmentSelect.getSelectOptions);
		this.setAuthorityType();

		this.formGroup = formBuilder.group({
			entityId: 0,
		});

		this.formGroup
			.get('entityId')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				this.searchChanged();
				this.store.dispatch(AccessAssignmentPageAction.setCurrentEntityId({ entityId: value }));
			});

		this.authorityColumns = [ColumnBuilder.createHidden('id'), ColumnBuilder.create('code', 'code'), ColumnBuilder.create('name', 'description')];
	}

	ngOnInit(): void {
		this.accessAssignmentAssigned$ = this.store.select(AccessAssignmentSelect.getAccessAssignmentAssigned);
		this.accessAssignmentAvailable$ = this.store.select(AccessAssignmentSelect.getAccessAssignmentAvailable);

		this.linkedTableConfig = {
			columns: this.authorityColumns,
			upperTableTitle: 'available',
			lowerTableTitle: 'assigned',
			upperTableData$: this.accessAssignmentAvailable$,
			lowerTableData$: this.accessAssignmentAssigned$,
			transferToUpper: this.transferToUpper,
			transferToLower: this.transferToLower,
			identifierColumn: 'id',
		};
	}

	transferToLower = (ids: []): void => {
		this.transfer(ids, OperationTypeEnumeration.ASSIGN);
	};

	transferToUpper = (ids: []): void => {
		this.transfer(ids, OperationTypeEnumeration.REMOVE);
	};

	transfer = (ids: [], operation: OperationTypeEnumeration): void => {
		if (this.authorityType !== AuthorityTypeEnumeration.DOCS) {
			const authorityOperation = this.createAuthorityOperation(operation, ids);
			this.store.dispatch(AccessAssignmentPageAction.assignOrRemove({ authorityOperation }));
		} else {
			const documentTypeModel = this.createDocumentTypeModel(operation, ids);
			this.store.dispatch(AccessAssignmentPageAction.assignOrRemoveForDocs({ documentTypeModel }));
		}
	};

	searchChanged = (): void => {
		if (this.formGroup.get('entityId').value) {
			this.refreshData();
		} else {
			this.store.dispatch(AccessAssignmentPageAction.clearData());
		}
	};

	refreshData = (): void => {
		if (this.authorityType !== AuthorityTypeEnumeration.DOCS) {
			const assignedOperation = this.createAuthorityOperation(OperationTypeEnumeration.GET_ASSIGNED);
			const availableOperation = this.createAuthorityOperation(OperationTypeEnumeration.GET_AVAILABLE);
			this.store.dispatch(AccessAssignmentPageAction.getAssigned({ authorityOperation: assignedOperation }));
			this.store.dispatch(AccessAssignmentPageAction.getAvailable({ authorityOperation: availableOperation }));
		} else {
			const assignedDocument = this.createDocumentTypeModel(OperationTypeEnumeration.GET_ASSIGNED);
			const availableDocument = this.createDocumentTypeModel(OperationTypeEnumeration.GET_AVAILABLE);
			this.store.dispatch(AccessAssignmentPageAction.getAssignedForDocs({ documentTypeModel: assignedDocument }));
			this.store.dispatch(AccessAssignmentPageAction.getAvailableForDocs({ documentTypeModel: availableDocument }));
		}
	};

	createAuthorityOperation(operation: OperationTypeEnumeration, ids?: []): AuthorityOperationModel {
		return {
			id: this.formGroup.get('entityId').value,
			authority: this.authorityType,
			operation,
			access: this.accessType,
			ids,
		} as AuthorityOperationModel;
	}

	createDocumentTypeModel(operation: OperationTypeEnumeration, ids?: []): DocumentTypeModel {
		return {
			id: this.formGroup.get('entityId').value,
			entityType: this.documentTypeAccess,
			operation,
			accessType: this.accessType,
			codes: ids,
		} as DocumentTypeModel;
	}

	setAuthorityType() {
		this.store.dispatch(AccessAssignmentPageAction.clearData());
		this.label = 'user';
		let url = 'user';
		switch (this.router.url) {
			case '/user/roles':
				this.authorityType = AuthorityTypeEnumeration.USER_ROLE;
				break;
			case '/user/role-groups':
				this.authorityType = AuthorityTypeEnumeration.USER_ROLE_GROUP;
				break;
			case '/role/permissions':
				this.authorityType = AuthorityTypeEnumeration.ROLE_PERMISSION;
				url = 'role';
				this.label = 'role';
				break;
			case '/role-group/roles':
				this.authorityType = AuthorityTypeEnumeration.ROLE_ROLE_GROUP;
				this.label = 'roleGroup';
				url = 'role-group';
				break;
			case '/user/allow-permissions':
				this.authorityType = AuthorityTypeEnumeration.USER_PERMISSION;
				this.accessType = AccessTypeEnumeration.ALLOW;
				break;
			case '/user/deny-permissions':
				this.authorityType = AuthorityTypeEnumeration.USER_PERMISSION;
				this.accessType = AccessTypeEnumeration.DENY;
				break;
			case '/user/allow-document-types':
				this.authorityType = AuthorityTypeEnumeration.DOCS;
				this.accessType = AccessTypeEnumeration.ALLOW;
				this.documentTypeAccess = DocumentTypeEntityAccessEnumeration.USER_ACCESS;
				break;
			case '/user/deny-document-types':
				this.authorityType = AuthorityTypeEnumeration.DOCS;
				this.accessType = AccessTypeEnumeration.DENY;
				this.documentTypeAccess = DocumentTypeEntityAccessEnumeration.USER_ACCESS;
				break;
			case '/role/document-types':
				url = 'role';
				this.label = 'role';
				this.authorityType = AuthorityTypeEnumeration.DOCS;
				this.documentTypeAccess = DocumentTypeEntityAccessEnumeration.ROLE_ACCESS;
				break;
		}
		this.store.dispatch(AccessAssignmentPageAction.setCurrentAuthorityType({ authorityType: this.authorityType }));
		this.store.dispatch(AccessAssignmentPageAction.setCurrentEntityType({ entityType: this.documentTypeAccess }));
		this.store.dispatch(AccessAssignmentPageAction.setCurrentAccessType({ accessType: this.accessType }));
		this.store.dispatch(AccessAssignmentPageAction.getSelectOptions({ url }));
	}
}
