import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent, Option } from 'nc-shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumUtils } from 'nc-utils';
import { booleanToFlagEnumeration, FlagEnumeration, toBoolean } from '../../../shared/enumeration/flag.enumeration';
import { KlePageAction, KleSelect } from '../../state/action';
import { Store } from '@ngrx/store';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'ea-other-employers',
	templateUrl: './other-employers.component.html',
})
export class OtherEmployersComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;

	otherEmployersGroup = this.formBuilder.group({
		value: [FlagEnumeration.yes],
	});

	haveOtherEmployersOptions: Option[];

	constructor(private formBuilder: FormBuilder, private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.haveOtherEmployersOptions = EnumUtils.toItems(FlagEnumeration);
		this.formGroup.addControl(KleFormGroupEnum.OTHER_EMPLOYERS, this.otherEmployersGroup);

		this.store
			.select(KleSelect.getOtherEmployers)
			.pipe(takeUntil(this.destroy$))
			.subscribe((otherEmployers) => this.otherEmployersGroup.controls.value.patchValue(booleanToFlagEnumeration(otherEmployers?.value)));
	}

	submit(): void {
		const data = this.formGroup.getRawValue();
		this.store.dispatch(
			KlePageAction.synchronizeIncident({
				request: {
					incidentContext: data.incidentContext,
					otherEmployers: { value: toBoolean(this.otherEmployersGroup.controls.value.value) },
				},
			})
		);
	}
}
