/**
 * Global state for the application.
 */
import { UserTypeEnumeration } from '../../shared/enumeration/user-type.enumeration';
import { UserStatusEnumeration } from '../../shared/enumeration/user-status.enumeration';
import { FlagEnumeration } from '../../shared/enumeration/flag.enumeration';

export interface SettingsState {
	/**
	 * Selected languages of the client.
	 */
	language: string;
	/**
	 * Flag which indicates that application is loaded and ready to use.
	 */
	ready: boolean;
	/**
	 * Name of the active menu item
	 */
	activeMenuItem: string;
	/**
	 * Document for preview
	 */
	document: Blob;
	/**
	 * Current deleteDocumentId which need to be removed from document table preview.
	 */
	deleteDocumentId: number;
	/**
	 * User info for local storage
	 */

	UIPreferences: { [key: string]: UserPreferences };
}

export const SETTINGS_INITIAL_STATE = {
	language: 'de',
	ready: false,
	activeMenuItem: '',
	selectedNumberOfDays: [],
	document: null,
	deleteDocumentId: null,
	UIPreferences: {},
};

/**
 * For saving data to local storage for each user
 */
class UserPreferences {
	dashboard: {
		milestoneFilter: string;
		tasksFilter: string;
		selectedNumberOfDays: number[];
		selectedUser: string;
		activeCasesFilter: string;
		selectedCompanies: number[];
		sortByColumnMilestone: string;
		orderMilestone: string;
		sortByColumnTasks: string;
		orderTasks: string;
		sortByColumnAbsence: string;
		orderAbsence: string;
	};
	archivedCases: {
		responsibleUser: string;
		caseFilter: string;
		selectedCompanies: number[];
		sortByColumn: string;
		order: string;
	};
	userAdministration: {
		neoIdFilter: string;
		roleFilter: UserTypeEnumeration;
		nameFilter: string;
		companyNameFilter: string;
		usernameFilter: string;
		emailFilter: string;
		activeFilter: UserStatusEnumeration;
		sortByColumn: string;
		order: string;
	};
	administration: {
		nameFilter: string;
		defaultAssigneeFilter: string;
		sortByColumn: string;
		order: string;
	};
	milestone: {
		globalFilter: string;
		sortByColumn: string;
		order: string;
	};
	documentTypes: {
		globalFilter: string;
		sortByColumn: string;
		order: string;
	};
	ldResponsible: {
		globalFilter: string;
		sortByColumn: string;
		order: string;
	};
	labelManagement: {
		globalFilter: string;
		sortByColumn: string;
		order: string;
	};
	company: {
		nameFilter: string;
		easyTempIdFilter: string;
		neoIdFilter: string;
		neoEnvironmentFilter: string;
		isKleEnabledFilter: FlagEnumeration;
		isSwicaInsuredFilter: FlagEnumeration;
		isPkProInsuredFilter: FlagEnumeration;
		uidFilter: string;
		externallyUpdatedOnFilter: string;
		sortByColumn: string;
		order: string;
	};
	medicalInstitutions: {
		globalLocationNumberFilter: string;
		doctorFirstNameFilter: string;
		doctorLastNameFilter: string;
		institutionNameFilter: string;
		additionalInstitutionNameFilter: string;
		streetFilter: string;
		cityFilter: string;
		zipFilter: string;
		countryFilter: string;
		lastChangeDateFilter: string;
		sortByColumn: string;
		order: string;
	};
}

export const USER_PREFERENCES_INITIAL_STATE = {
	dashboard: {
		milestoneFilter: '',
		tasksFilter: '',
		selectedNumberOfDays: [],
		selectedUser: '',
		activeCasesFilter: '',
		selectedCompanies: null,
		sortByColumnMilestone: '',
		orderMilestone: '',
		sortByColumnTasks: '',
		orderTasks: '',
		sortByColumnAbsence: '',
		orderAbsence: '',
	},
	archivedCases: {
		responsibleUser: '',
		caseFilter: '',
		selectedCompanies: null,
		sortByColumn: '',
		order: '',
	},
	userAdministration: {
		neoIdFilter: '',
		roleFilter: null,
		nameFilter: '',
		companyNameFilter: '',
		usernameFilter: '',
		emailFilter: '',
		activeFilter: null,
		sortByColumn: '',
		order: '',
	},
	administration: {
		nameFilter: '',
		defaultAssigneeFilter: '',
		sortByColumn: '',
		order: '',
	},
	milestone: {
		globalFilter: '',
		sortByColumn: '',
		order: '',
	},
	labelManagement: {
		globalFilter: '',
		sortByColumn: '',
		order: '',
	},
	ldResponsible: {
		globalFilter: '',
		sortByColumn: '',
		order: '',
	},
	documentTypes: {
		globalFilter: '',
		sortByColumn: '',
		order: '',
	},
	company: {
		nameFilter: '',
		easyTempIdFilter: '',
		neoIdFilter: '',
		neoEnvironmentFilter: '',
		isKleEnabledFilter: '',
		isSwicaInsuredFilter: '',
		isPkProInsuredFilter: '',
		uidFilter: '',
		externallyUpdatedOnFilter: null,
		sortByColumn: '',
		order: '',
	},
	medicalInstitutions: {
		globalLocationNumberFilter: '',
		doctorFirstNameFilter: '',
		doctorLastNameFilter: '',
		institutionNameFilter: '',
		additionalInstitutionNameFilter: '',
		streetFilter: '',
		cityFilter: '',
		zipFilter: '',
		countryFilter: '',
		lastChangeDateFilter: null,
		sortByColumn: '',
		order: '',
	},
};
