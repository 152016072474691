<div class="footer-content" fxLayout="column" fxLayoutAlign="center">
	<div class="footer-navigation" fxLayout="row wrap" fxLayoutAlign="space-between">
		<nc-button *ngIf="displayBackButton" [id]="'back'" (click)="onBack()" [isRaised]="false" [label]="backButtonLabel"></nc-button>
		<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="5px">
			<nc-submit-button
				*ngIf="displaySaveAndSendButton"
				id="saveAndSend"
				(buttonClick)="saveAndSend()"
				dialogLabel="processing"
				[formGroup]="formGroup"
				[buttonLabel]="saveAndSendButtonLabel"
			>
			</nc-submit-button>
			<nc-submit-button
				*ngIf="displaySaveButton"
				id="save"
				(buttonClick)="save()"
				dialogLabel="processing"
				[formGroup]="formGroup"
				[buttonLabel]="saveButtonLabel"
			>
			</nc-submit-button>
		</div>
	</div>
</div>
