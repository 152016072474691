<div [formGroup]="confirmationMessageGroup" fxLayout="column" fxLayoutGap="15px" autocomplete="off">
	<div fxLayout="column">
		<nc-input id="title" label="title" [formGroup]="confirmationMessageGroup" formControlName="title" [isReadonly]="true"></nc-input>
	</div>
	<div fxLayout="column">
		<nc-textarea
			class="text-wrapped"
			id="message"
			label="synchronization.table.Message"
			[formGroup]="confirmationMessageGroup"
			formControlName="message"
			[isReadonly]="true"
		>
		</nc-textarea>
	</div>
	<div fxLayout="column">
		<nc-checkbox
			id="confirm-button"
			[formGroup]="confirmationMessageGroup"
			formControlName="answerValue"
			label="{{ dialogMessage?.confirmationMessage.label }}"
		>
		</nc-checkbox>
	</div>
	<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px">
		<nc-button id="cancel" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
		<nc-submit-button
			id="send-dialog-message"
			buttonLabel="replay"
			(buttonClick)="submit()"
			[formGroup]="confirmationMessageGroup"
			dialogLabel="processing"
		>
		</nc-submit-button>
	</div>
</div>
