import { ColumnBuilder, DatatableColumn } from 'nc-datatable';

export class TableColumns {
	public static REPORT_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('companyName', 'company'),
		ColumnBuilder.create('employeeName', 'person'),
		ColumnBuilder.create('referenceId', 'referenceId'),
		ColumnBuilder.create('createdOn', 'createdOn'),
		ColumnBuilder.create('reason', 'reason'),
		ColumnBuilder.create('additionalInfo', 'additionalInfo'),
	];

	public static ACTIVITY_LOG_COLUMNS: DatatableColumn[] = [
		ColumnBuilder.create('started', 'started'),
		ColumnBuilder.create('ended', 'ended'),
		ColumnBuilder.create('status', 'status'),
		ColumnBuilder.create('duration', 'durationMilliseconds'),
		ColumnBuilder.create('user', 'user'),
		ColumnBuilder.create('ipAddress', 'ipAddress'),
		ColumnBuilder.create('device', 'device'),
		ColumnBuilder.create('browser', 'browser'),
		ColumnBuilder.create('event', 'event'),
		ColumnBuilder.create('parameters', 'parameters'),
		ColumnBuilder.create('validation', 'validation'),
		ColumnBuilder.create('exception', 'exception'),
	];
}
