<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<nc-input [id]="'id'" [formGroup]="formGroup" formControlName="id" label="id" isReadonly="true"></nc-input>
				<nc-input [id]="'code'" [formGroup]="formGroup" formControlName="code" label="code"></nc-input>
				<nc-input [id]="'name'" [formGroup]="formGroup" formControlName="name" label="description"></nc-input>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
