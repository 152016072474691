import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DashboardApiAction, DashboardPageAction } from './action';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { DashboardService } from '../service/dashboard.service';
import { forkJoin } from 'rxjs';
import { DashboardChartDataTransform } from '../model/dashboard-chart-data-transform.service';
import { CompaniesSelectFactory } from '../../shared/utils/companies-select-factory';
import { CompaniesSelectService } from '../../user-administration/service/companies-select.service';

@Injectable()
export class DashboardEffect {
	constructor(
		private actions$: Actions,
		private chartDataTransform: DashboardChartDataTransform,
		private companiesSelectFactory: CompaniesSelectFactory,
		private companiesSelectService: CompaniesSelectService,
		private dashboardService: DashboardService
	) {}

	openActiveDashboardEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.openActiveDashboard),
			mergeMap(() => forkJoin([this.dashboardService.getNumberOfDays(), this.dashboardService.getResponsibleUsers()])),
			map(([numberOfDays, responsibleUsers]) => {
				const numberOfDaysOptions = numberOfDays.map((item) => {
					return { value: item, text: item.toString() };
				});

				return DashboardApiAction.openActiveDashboardSuccess({ numberOfDays: numberOfDaysOptions, responsibleUsers });
			})
		);
	});

	openArchivedDashboardEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.openArchivedDashboard),
			switchMap(() => this.dashboardService.getResponsibleUsers()),
			map((responsibleUsers) => DashboardApiAction.openArchivedDashboardSuccess({ responsibleUsers }))
		);
	});

	getTasksEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getTasks),
			switchMap((action) => this.dashboardService.getTasks(action.selectedUser)),
			map((result) => DashboardApiAction.getTasksSuccess({ tasks: result }))
		);
	});

	getMilestonesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getMilestones),
			switchMap((action) => this.dashboardService.getMilestones(action.selectedUser, action.numberOfDays)),
			map((result) => DashboardApiAction.getMilestonesSuccess({ milestones: result }))
		);
	});

	getActiveCasesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getActiveAbsences),
			switchMap((action) => this.dashboardService.getActiveCases(action.selectedUser)),
			map((result) => DashboardApiAction.getActiveAbsencesSuccess({ activeCases: result }))
		);
	});

	getArchivedCasesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getArchivedAbsences),
			switchMap((action) => this.dashboardService.getArchivedCases(action.selectedUser)),
			map((result) => DashboardApiAction.getArchivedAbsencesSuccess({ archivedCases: result }))
		);
	});

	getHrActiveAbsenceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getHrActiveAbsence),
			switchMap((action) => this.dashboardService.getHrActiveAbsence(action.companyIds)),
			map((result) => DashboardApiAction.getHrActiveAbsenceSuccess({ hrActiveAbsence: result }))
		);
	});

	getHrLatestAbsencesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getCasesToBeApproved),
			switchMap((action) => this.dashboardService.getCasesToBeApproved(action.companyIds)),
			map((result) => DashboardApiAction.getCasesToBeApprovedSuccess({ casesToBeApproved: result }))
		);
	});

	getAwaitStoriesEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getAwaitStories),
			switchMap((action) => this.dashboardService.getAwaitStories(action.companyIds)),
			map((result) => DashboardApiAction.getAwaitStoriesSuccess({ awaitStories: result }))
		);
	});

	getHrStatisticEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getHrStatistic),
			switchMap((action) => this.dashboardService.getHrStatistic(action.companyIds)),
			map((result) => {
				const data = this.chartDataTransform.transform(result);
				return DashboardApiAction.getHrStatisticSuccess({ charts: data });
			})
		);
	});

	getHrCompaniesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getHrCompanies),
			mergeMap((action) => this.companiesSelectService.getCompanyOptions(action.companyIds)),
			map((result) => DashboardApiAction.getHrCompaniesSuccess({ companies: result }))
		);
	});

	getHrArchivedCases = createEffect(() => {
		return this.actions$.pipe(
			ofType(DashboardPageAction.getHrArchivedCases),
			switchMap((action) => this.dashboardService.getHrArchivedCases(action.companyIds)),
			map((result) => DashboardApiAction.getHrArchivedCasesSuccess({ archivedCases: result }))
		);
	});
}
