<div id="fetch-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<div mat-dialog-title>
		<span class="dialog-title-text">{{ 'fetchDocument' | translate }}</span>
	</div>
	<div [formGroup]="formGroup" id="content" fxLayout="column" fxLayoutGap="10px" mat-dialog-content>
		<div>{{ 'fetchDocumentNote' | translate }}</div>
		<nc-select
			[id]="'document-type'"
			[searchTextId]="'document-type'"
			[searchButtonId]="'document-type'"
			[clearIconId]="'document-type'"
			label="documentType"
			[formGroup]="formGroup"
			[data$]="documentTypes$"
			[translate]="true"
			formControlName="documentType"
		>
		</nc-select>
	</div>
	<div mat-dialog-actions fxLayout="column" fxLayoutAlign="center">
		<div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="10px">
			<nc-button [id]="'cancel'" label="cancel" [mat-dialog-close]="{ success: false }"></nc-button>
			<nc-button [id]="'fetch'" label="fetch" (click)="fetch()"></nc-button>
		</div>
	</div>
</div>
