import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { State } from './app.state';
import { localStorageSync } from 'ngrx-store-localstorage';
import { userReducer } from '../user/state/user.reducer';
import { errorReducer } from './error/error.reducer';
import { dashboardReducer } from '../dashboard/state/dashboard.reducer';
import { settingsReducer } from './settings/settings.reducer';

export const appReducers: ActionReducerMap<State> = {
	user: userReducer,
	error: errorReducer,
	settings: settingsReducer,
	dashboard: dashboardReducer,
};

function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
	return localStorageSync({
		keys: [
			{
				settings: ['activeMenuItem', 'UIPreferences', 'language'],
			},
			{
				user: [
					'id',
					'token',
					'accessToken',
					'refreshToken',
					'email',
					'username',
					'firstName',
					'lastName',
					'companies',
					'permissions',
					'isAuthenticated',
					'forgotPasswordToken',
					'milestoneFilter',
					'userType',
					'companyFilterOptions',
				],
			},
		],
		rehydrate: true,
	})(reducer);
}

export const appMetaReducers: Array<MetaReducer<State, any>> = [localStorageSyncReducer];
