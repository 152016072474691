import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SettingsPageAction } from '../../../../state/settings/action';
import { FormGroup } from '@angular/forms';
import { AbsencePageAction, AbsenceSelect } from '../../../state/action';
import { AbsenceFormService } from '../../../service/absence-form.service';
import { UserSelect } from '../../../../user/state/action';
import { AbsenceTypeEnumeration } from '../../../../shared/enumeration/absence-type.enumeration';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'nc-shared';
import { AppEvent } from 'src/app/shared/model/app-event.model';
import { EventQueueService } from 'src/app/shared/service/event-queue.service';
import { BroadcastEventType } from '../../../../shared/enumeration/broadcast-event-type.enumeration';
import { IncidentDeclarationDialogComponent } from '../../dialog/incident-declaration/incident-declaration-dialog.component';
import { DialogService, parseDate } from 'nc-utils';
import { PermissionEnumeration } from '../../../../shared/enumeration/permission.enumeration';
import { AbsenceStatusEnumeration } from '../../../model/enumeration/absence-status.enumeration';
import { AbsenceService } from '../../../service/absence.service';
import { Company } from '../../../../shared/model/company';
import * as moment from 'moment';
import { Absence } from '../../../model/absence';

@Component({
	selector: 'ea-hr-absence-footer',
	templateUrl: './hr-absence-footer.component.html',
	styleUrls: ['./hr-absence-footer.component.scss'],
})
export class HrAbsenceFooterComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() code: string;
	@Input() absence: Absence;
	@Input() isReadOnly = false;
	@Input() company: Company;

	hasDeclareIncidentPermission = false;
	isIncidentDeclared = false;
	isBranchHr = false;
	isAccident: boolean;
	isDraftStatus = false;
	isArchived = false;
	isHrOrBranchHr = false;

	constructor(
		private store: Store,
		private absenceFormService: AbsenceFormService,
		private eventQueue: EventQueueService,
		private dialogService: DialogService,
		private changeDetectorRef: ChangeDetectorRef,
		private absenceService: AbsenceService
	) {
		super();
	}

	ngOnInit(): void {
		this.formGroup.controls.absenceType.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => (this.isAccident = value === AbsenceTypeEnumeration.ACCIDENT.code));

		this.formGroup.controls.status.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.isDraftStatus = value === AbsenceStatusEnumeration.DRAFT.code;
			this.isArchived = value === AbsenceStatusEnumeration.ARCHIVED.code;
		});

		this.store.dispatch(AbsencePageAction.getIncidentContext({ absenceCode: this.code }));

		this.store
			.select(UserSelect.getPermissions)
			.pipe(takeUntil(this.destroy$))
			.subscribe((permissions) => {
				this.hasDeclareIncidentPermission = permissions.includes(PermissionEnumeration.DECLARE_INCIDENT);
				this.isBranchHr = permissions.includes(PermissionEnumeration.BRANCH_HR);
				this.isHrOrBranchHr = permissions.includes(PermissionEnumeration.HR) || this.isBranchHr;
			});

		this.store
			.select(AbsenceSelect.getIncidentContext)
			.pipe(takeUntil(this.destroy$))
			.subscribe((context) => {
				this.isIncidentDeclared = context?.incidentId != null;
				this.changeDetectorRef.detectChanges();
			});
	}

	navigateToShareInformation(): void {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${this.code}/share-information` }));
	}

	navigateToAccidentDescription(): void {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/kle/${this.code}/accident-description` }));
	}

	navigateToTreatments(): void {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/kle/${this.code}/treatments` }));
	}

	navigateToKle(): void {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${this.code}/kle` }));
	}

	declareIncident(): void {
		const dialogConfig = {
			width: '700px',
			data: {
				absenceCode: this.code,
				isAccident: true,
				firstIncapacityValidFrom: parseDate(this.absence.incapacities[0]?.validFrom.toString()),
			},
		};
		this.dialogService.open(IncidentDeclarationDialogComponent, dialogConfig);
	}

	onActivate = (): void => {
		this.absenceFormService.checkValidityAndDispatchAction(this.formGroup, AbsencePageAction.shouldActivate());
	};

	onSubmit(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.ON_SAVE));
	}

	saveAndSend(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.ON_SAVE_AND_SEND));
	}

	deleteDraft(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.DELETE_DRAFT));
	}

	reject(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.REJECT));
	}

	close(): void {
		this.store.dispatch(SettingsPageAction.navigate({ url: `/absence/${this.code}/closed` }));
	}

	onArchive = (): void => {
		this.absenceFormService.checkValidityAndDispatchAction(this.formGroup, AbsencePageAction.canArchive({ id: this.formGroup.value.id }));
	};
	isOpen(): boolean {
		return this.formGroup.value.status === AbsenceStatusEnumeration.OPEN.code;
	}

	isDraft(): boolean {
		return this.formGroup.value.status === AbsenceStatusEnumeration.DRAFT.code;
	}

	companyDetailsCheck(): boolean {
		return !this.company.isSwicaInsured && !this.company.isPkProInsured && this.company.isKleEnabled;
	}
}
