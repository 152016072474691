import * as ApplicationState from '../../state/app.state';
import { TableData } from 'nc-datatable';
import { Option } from 'nc-shared';
import { AuthorityTypeEnumeration } from '../model/enumeration/authority-type.enumeration';
import { DocumentTypeEntityAccessEnumeration } from '../model/enumeration/document-type-entity-access.enumeration';
import { AccessTypeEnumeration } from '../model/enumeration/access-type.enumeration';

export interface AccessAssignmentState {
	accessAssignmentAvailable: TableData;
	accessAssignmentAssigned: TableData;
	selectOptions: Option[];
	entityId: number;
	authorityType: AuthorityTypeEnumeration;
	entityType: DocumentTypeEntityAccessEnumeration;
	accessType: AccessTypeEnumeration;
}

export interface State extends ApplicationState.State {
	accessAssignment: AccessAssignmentState;
}

export const ACCESS_ASSIGNMENT_STATE: AccessAssignmentState = {
	accessAssignmentAvailable: { records: [] },
	accessAssignmentAssigned: { records: [] },
	selectOptions: [],
	entityId: null,
	authorityType: null,
	entityType: null,
	accessType: null,
};
