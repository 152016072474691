<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="20px">
				<ea-container>
					<div fxLayout="column" fxLayoutGap="10px">
						<nc-input id="easy-temp-id" [formGroup]="formGroup" formControlName="easyTempId" label="easyTempId"></nc-input>
						<nc-input id="neo-id" [formGroup]="formGroup" formControlName="neoId" label="neoId"></nc-input>
						<nc-toggle-buttons
							[formGroup]="formGroup"
							formControlName="contractType"
							label="employerActivity"
							[options]="contractType"
						></nc-toggle-buttons>
						<nc-select
							[formGroup]="formGroup"
							formControlName="frontendStyle"
							label="salectDefaultFrontend"
							[data$]="frontendStyleOption$"
							[translate]="true"
						></nc-select>
						<nc-toggle-buttons
							[formGroup]="formGroup"
							formControlName="isSwicaInsured"
							label="isSwicaInsured"
							[options]="yesNo"
						></nc-toggle-buttons>
						<nc-toggle-buttons
							[formGroup]="formGroup"
							formControlName="isPkProInsured"
							label="isPkProInsured"
							[options]="yesNo"
						></nc-toggle-buttons>
						<nc-toggle-buttons
							[formGroup]="formGroup"
							formControlName="isKleEnabled"
							label="isKleEnabled"
							[options]="yesNo"
							[isReadonly]="!isAdmin"
						></nc-toggle-buttons>
					</div>
				</ea-container>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
