import { createReducer, on } from '@ngrx/store';
import { USER_ADMINISTRATION_INITIAL_STATE, UserAdministrationState } from './user-administration.state';
import { UserAdministrationApiAction, UserAdministrationPageAction } from './action';

export const userAdministrationReducer = createReducer<UserAdministrationState>(
	USER_ADMINISTRATION_INITIAL_STATE,
	on(UserAdministrationApiAction.getAllUsersSuccess, (state, action): UserAdministrationState => {
		return {
			...state,
			users: { records: action.users },
		};
	}),
	on(UserAdministrationApiAction.getUserSuccess, (state, action): UserAdministrationState => {
		return {
			...state,
			user: action.user,
		};
	}),
	on(UserAdministrationApiAction.generatePasswordSuccess, (state, action): UserAdministrationState => {
		return {
			...state,
			generatedPassword: action.password.password,
		};
	}),
	on(UserAdministrationPageAction.clearUser, (state): UserAdministrationState => {
		return {
			...state,
			user: null,
		};
	}),
	on(UserAdministrationPageAction.copyCompanies, (state, action): UserAdministrationState => {
		return {
			...state,
			copiedCompanies: action.copiedCompanies,
		};
	})
);
