<form [formGroup]="formGroup" fxFill fxLayout="column">
	<nc-main-content>
		<nc-form-content [center]="false">
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
					<div fxLayout="column" fxLayoutGap="10px" fxFlex="50">
						<div fxLayout="row wrap" fxLayoutGap="5px">
							<nc-quick-search
								[textInputId]="'company'"
								[resetButtonId]="'company-reset'"
								[undoButtonId]="'company-undo'"
								[formGroup]="formGroup"
								placeholder="defaultSelectPleaceholder"
								formControlName="companyId"
								[required]="true"
								fxFlex="94"
								[fetchAll]="searchCompanies"
								label="company"
							>
							</nc-quick-search>
							<ng-container *ngIf="company.value">
								<mat-icon class="more-details-action" (click)="openCompany()">info</mat-icon>
							</ng-container>
						</div>
						<div fxLayout="row wrap" fxLayoutGap="5px">
							<nc-quick-search
								[textInputId]="'employee'"
								[resetButtonId]="'employee-reset'"
								[undoButtonId]="'employee-undo'"
								fxFlex="94"
								*ngIf="isCompanyPopulated()"
								[formGroup]="formGroup"
								[required]="true"
								formControlName="refEmployee"
								[fetchAll]="filterItems"
								placeholder="defaultSelectPleaceholder"
								label="employee"
							>
								<ng-template let-item>
									<span class="mat-option-title">{{ item.text | split : ';' : 0 }}</span
									><br />
									<span class="mat-option-footer">{{ item.text | split : ';' : 1 }}</span>
								</ng-template>
							</nc-quick-search>
							<ng-container *ngIf="employee.value && company.value">
								<mat-icon class="more-details-action" (click)="openEmployee()">info</mat-icon>
							</ng-container>
						</div>
						<ng-container *ngIf="employee.value">
							<ea-insurance-period fxLayoutGap="5px" [employee]="employee$ | async"></ea-insurance-period>
						</ng-container>
						<nc-toggle-buttons
							[formGroup]="formGroup"
							[options]="declarationTypeOptions"
							label="absenceDeclaration"
							formControlName="categoryType"
						>
						</nc-toggle-buttons>

						<ea-absence-type [formGroup]="formGroup"></ea-absence-type>

						<nc-textarea [id]="'comment'" [formGroup]="formGroup" formControlName="comment" label="comment" maxLength="300"></nc-textarea>
						<nc-quick-search
							[textInputId]="'medical-diagnosis'"
							[resetButtonId]="'medical-diagnosis-reset'"
							[undoButtonId]="'medical-diagnosis-undo'"
							[formGroup]="formGroup"
							label="medicalDiagnosis"
							formControlName="medicalDiagnosisCode"
							[fetchAll]="searchMedicalDiagnosis"
						>
						</nc-quick-search>

						<nc-quick-search
							[textInputId]="'medical-diagnosis-additional'"
							[resetButtonId]="'medical-diagnosis-additional-reset'"
							[undoButtonId]="'medical-diagnosis-additional-undo'"
							[formGroup]="formGroup"
							label="medicalDiagnosisAdditional"
							formControlName="medicalDiagnosisAdditionalCode"
							[fetchAll]="searchMedicalDiagnosis"
						>
						</nc-quick-search>
						<nc-select
							[id]="'responsible-user'"
							[searchTextId]="'responsible-user-search'"
							[searchButtonId]="'responsible-user-search-clear'"
							[clearIconId]="'responsible-user-clear'"
							*ngIf="permissions.includes('CM_CASE_ASSIGN')"
							[formGroup]="formGroup"
							[data$]="responsibleUsers$"
							placeholder="defaultSelectPleaceholder"
							label="responsibleUser"
							[removeSelected]="true"
							formControlName="responsibleUserId"
						>
						</nc-select>

						<nc-select
							[id]="'responsible-ld-user'"
							[searchTextId]="'responsible-ld-user-search'"
							[searchButtonId]="'responsible-ld-user-search-clear'"
							[clearIconId]="'responsible-ld-user-clear'"
							*ngIf="permissions.includes('LD_CASE_ASSIGN')"
							[formGroup]="formGroup"
							[data$]="responsibleLDUsers$"
							placeholder="defaultSelectPleaceholder"
							label="responsibleLDUser"
							[removeSelected]="true"
							formControlName="responsibleLDUserId"
						>
						</nc-select>

						<div class="case-management-flags" fxLayout="column" fxLayoutGap="10px">
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="isSentToCaseManagementCompany"
								label="sentToCaseManagementCompany"
								[options]="flagOptions"
							>
								<nc-datepicker
									[id]="'sent-to-case-management'"
									[formGroup]="formGroup"
									*ngIf="formGroup.get('isSentToCaseManagementCompany').value === 'yes'"
									formControlName="sentToCaseManagementCompanyDate"
								>
								</nc-datepicker>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="isClosedByCaseManagementCompany"
								label="closedByCaseManagementCompany"
								[options]="flagOptions"
							>
								<nc-datepicker
									[id]="'closed-by-case-management'"
									[formGroup]="formGroup"
									*ngIf="formGroup.get('isClosedByCaseManagementCompany').value === 'yes'"
									formControlName="closedByCaseManagementCompanyDate"
								>
								</nc-datepicker>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="isAbortedByCaseManagementCompany"
								label="abortedByCaseManagementCompany"
								[options]="flagOptions"
							>
								<nc-datepicker
									[id]="'aborted-by-case-management'"
									[formGroup]="formGroup"
									*ngIf="formGroup.get('isAbortedByCaseManagementCompany').value === 'yes'"
									formControlName="abortedByCaseManagementCompanyDate"
								>
								</nc-datepicker>
							</nc-toggle-buttons>
						</div>
						<div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="10px">
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="isSwicaInsured"
								label="isSwicaInsured"
								[options]="flagOptions"
							>
							</nc-toggle-buttons>
							<nc-toggle-buttons
								[formGroup]="formGroup"
								formControlName="isPkproInsured"
								label="isPkProInsured"
								[options]="flagOptions"
							>
							</nc-toggle-buttons>
						</div>
					</div>
					<div *ngIf="declarationType.value === ABSENCE_DECLARATION_TYPE.PREMIUM.code" fxLayout="column" fxLayoutGap="10px" fxFlex="50">
						<ea-premium-details [formGroup]="formGroup" [company]="company$ | async"></ea-premium-details>
					</div>
				</div>
				<ea-incapacity-table [formArray]="incapacities"></ea-incapacity-table>
				<ea-documents
					[id]="'cm-new-absence-document'"
					[buttonId]="'open-dialog'"
					[downloadButtonId]="'download-documents-button'"
					[documentsFormArray]="documents"
					[hideMultiDownload]="true"
					[hidePreview]="true"
				></ea-documents>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup"></ea-footer>
</form>
