import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from '../settings.state';

export const SETTINGS_FEATURE_STATE_NAME = 'settings';
const FEATURE_STATE = createFeatureSelector<SettingsState>(SETTINGS_FEATURE_STATE_NAME);

export const isReady = createSelector(FEATURE_STATE, (state) => state.ready);
export const getLanguage = createSelector(FEATURE_STATE, (state) => state.language);
export const getDocument = createSelector(FEATURE_STATE, (state) => state.document);
export const getActiveMenuItem = createSelector(FEATURE_STATE, (state) => state.activeMenuItem);
export const getDashboardUserPreferences = (username: string) => createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].dashboard);
export const getUIPreferences = createSelector(FEATURE_STATE, (state) => state.UIPreferences);
export const getArchivedCasesLocalStorageData = (username: string) =>
	createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].archivedCases);
export const getDeleteDocumentId = createSelector(FEATURE_STATE, (state) => state.deleteDocumentId);

export const getUserAdministrationUserPreferences = (username: string) =>
	createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].userAdministration);
export const getAdministrationUserPreferences = (username: string) =>
	createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].administration);
export const getMilestoneUserPreferences = (username: string) => createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].milestone);
export const getLabelManagementUserPreferences = (username: string) =>
	createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].labelManagement);
export const getLdResponsibleUserPreferences = (username: string) =>
	createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].ldResponsible);
export const getDocumentTypesUserPreferences = (username: string) =>
	createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].documentTypes);
export const getCompanyUserPreferences = (username: string) => createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].company);
export const getMedicalInstitutionsUserPreferences = (username: string) =>
	createSelector(FEATURE_STATE, (state) => state.UIPreferences[username].medicalInstitutions);
