import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActionResponse, SaveResponse } from 'nc-utils';
import { Option } from 'nc-shared';
import { SelectModel } from '../../shared/model/select.model';
import { map } from 'rxjs/operators';
import { LdResponsibleModel } from '../model/ld-responsible.model';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { ResponseModel } from '../../shared/model/response.model';
import { TableData } from 'nc-datatable/lib/model/table-data';

@Injectable({
	providedIn: 'root',
})
export class LdResponsibleService {
	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly LD_RESPONSIBLE_URL = `${this.API_URL}/ldresponsibles`;
	private readonly GET_RESPONSIBLE_USERS_URL = `${this.API_URL}/users/options/`;

	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets ldresponsibles
	 */
	getLdResponsibles(): Observable<{ [key: number]: string }[]> {
		return this.httpClient.get<TableData>(this.LD_RESPONSIBLE_URL).pipe(map((response) => response.records));
	}

	/**
	 * Gets ldresponsible
	 * @param id ldresponsible id
	 */
	getLdResponsible(id: number): Observable<LdResponsibleModel> {
		return this.httpClient.get<ResponseModel<LdResponsibleModel>>(`${this.LD_RESPONSIBLE_URL + '/' + id}`).pipe(map((x) => x.value));
	}

	/**
	 * Gets responsible ld users for select.
	 */
	getResponsibleLdUsers(): Observable<Option[]> {
		const url = this.GET_RESPONSIBLE_USERS_URL + 'LD';
		return this.httpClient.get<SelectModel>(url).pipe(map((response) => response.options));
	}

	/**
	 * Delete ldResponsible
	 * @param id Unique identifier for responsible
	 */
	deleteLdResponsible(id: number): Observable<any> {
		return this.httpClient.request('delete', this.LD_RESPONSIBLE_URL, { body: [id] });
	}

	/**
	 * Save ldResponsible
	 * @param ldResponsible LdResponsible model from form data
	 */
	saveLdResponsible(ldResponsible: LdResponsibleModel): Observable<ActionResponse> {
		return this.httpClient.put<ActionResponse>(this.LD_RESPONSIBLE_URL, ldResponsible);
	}
}
